import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { INewsCategoryItemModel } from './NewsCategoryModel';
import { IEnterpriseModel } from '../Enterprises/EnterpriseModel';
import { CDate } from '../../utils/CDate';
import { DefaultFilterType, FileType, prepareFiles } from '../common';
import { BACKEND_DATE_FORMAT } from '../../config/system';

export interface LocalNewsFilterType {
  category: number[];
  enterprise: number[];
  date_from?: string;
  date_to?: string;
  holding: 1 | 0;
}

export type NewsFilterType = DefaultFilterType & {
  sort?: string;
  category?: number[];
  enterprise?: number[];
  date_from?: string;
  date_to?: string;
  holding?: 1 | 0;
}

export interface ISimpleNewsItemModel {
  id: number;
  title: string;
  short_description: string;
  created_at: Date; // timestamp
  main_image: FileType;
  categories: INewsCategoryItemModel[];
  enterprises: IEnterpriseModel[];
  is_liked: boolean;
  like_count: number;
}

export class SimpleNewsItemModel implements ISimpleNewsItemModel {
  categories: { id: number; name: string }[];
  enterprises: IEnterpriseModel[];
  id: number;
  main_image: FileType;
  short_description: string;
  title: string;
  created_at: Date;
  is_liked: boolean;
  like_count: number;

  constructor(params: any = {}) {
    this.categories = params.categories;
    this.enterprises = params.enterprises;
    this.id = params.id;
    this.main_image = prepareFiles(params.main_image);
    this.short_description = params.short_description;
    this.title = params.title;
    /** привести к дате, распарсить */
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.is_liked = params.is_liked;
    this.like_count = params.like_count || 0;
  }
}


export interface INewsModel extends IWithPages {
  data: ISimpleNewsItemModel[];
}

export class NewsModel extends WithPagesModel implements INewsModel {
  data: ISimpleNewsItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SimpleNewsItemModel);
  }
}
