import { dataAction, dataErrorAction, dataSuccessAction } from '../../store/actions';
import {
  AUTH_LOGOUT_FAIL,
  AUTH_LOGOUT_OK,
  AUTH_LOGOUT_START,
  AUTH_SIGNIN_FAIL,
  AUTH_SIGNIN_OK,
  AUTH_SIGNIN_START,
} from './constants';
import { AuthSignInType } from '../../models/Auth/interfaces';


export const authSignInStartAction = (data: AuthSignInType) => dataAction(AUTH_SIGNIN_START)({ data });
export const authSignInOkAction = (signIn = true) => dataSuccessAction(AUTH_SIGNIN_OK)({ signIn });
export const authSignInFailAction = dataErrorAction(AUTH_SIGNIN_FAIL);


export const authLogoutStartAction = (data: { ntf_token?: string }) => dataAction(AUTH_LOGOUT_START)({ data });
export const authLogoutOkAction = dataSuccessAction(AUTH_LOGOUT_OK);
export const authLogoutFailAction = dataErrorAction(AUTH_LOGOUT_FAIL);
