import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Header } from 'components/Main/Header';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GuideIcon } from 'assets/img/guide.svg';
import { globalOpenAppGuide } from 'store/actions';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { filteringMenuItemsByRights } from 'utils/filteringMenuItemsByRights';
import { ICurrentUserModel } from 'models/User/CurrentUserModel';
import { clientNav } from 'nav';
import { store } from 'index';


type LocalLinkProps = {
  icon: React.ReactElement;
  label: string | React.ReactElement;
  path: string;
  guideStep?: string;
}

const LocalLink = ({
  path, icon, label, guideStep,
}: LocalLinkProps) => (
  <Link to={path} className={`list-element add ${guideStep}`}>
    <div className="list-element_icon">
      {icon}
    </div>
    <div className="list-element_content">
      <div className="line">{label}</div>
    </div>
    <div className="list-element_direction follow" />
  </Link>
);

export const AddMenu = memo(() => {
  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const { t } = useTranslation();

  const menuList = useMemo(() => filteringMenuItemsByRights(clientNav, currentUser)
    .filter((menuItem) => !menuItem.isDisplayedOnMobileMenu), [currentUser]);

  const handleOpenAppGuide = () => {
    store.dispatch(globalOpenAppGuide(true));
  };

  return (
    <>
      <Header className="d-none-custom" />
      <div className="addmenu">
        <section className="form d-flex">
          <form className="form-with-btn" action="">
            <div className="form-wrapper_content">
              <div className="wrapper">
                {
                  menuList && (
                    menuList.map((menuItem) => (
                      <LocalLink
                        key={menuItem.name}
                        icon={<menuItem.icon />}
                        label={t(menuItem.name)}
                        path={menuItem.url}
                        guideStep={menuItem.guideStep}
                      />
                    ))
                  )
                }
                <button
                  type="button"
                  className="list-element step-1 list-element__button add"
                  onClick={handleOpenAppGuide}
                >
                  <div className="list-element_icon">
                    <GuideIcon />
                  </div>
                  <div className="list-element_content">
                    <div className="line">{t('guide.menu')}</div>
                  </div>
                  <div className="list-element_direction follow" />
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
});
