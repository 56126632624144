import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import {
  SYSTEM_GET_USER_ROLES_FAIL,
  SYSTEM_GET_USER_ROLES_START,
  SYSTEM_GET_USERS_LIST_FAIL,
  SYSTEM_GET_USERS_LIST_OK,
  SYSTEM_GET_USERS_LIST_START,
} from './constants';
import { ISystemUserListModel, SystemUsersFilterType } from '../../../models/Users/interfaces';


export const systemGetUsersListStartAction = (filter: SystemUsersFilterType = {}) => dataAction(
  SYSTEM_GET_USERS_LIST_START,
)({ filter });
export const systemGetUsersListOkAction = (users: ISystemUserListModel) => dataSuccessAction(
  SYSTEM_GET_USERS_LIST_OK,
)({ users });
export const systemGetUsersListFailAction = dataErrorAction(SYSTEM_GET_USERS_LIST_FAIL);


export const systemGetUserAndRolesStartAction = (userId: number) => dataAction(
  SYSTEM_GET_USER_ROLES_START,
)({ userId });
export const systemGetUserAndRolesFailAction = dataErrorAction(SYSTEM_GET_USER_ROLES_FAIL);
