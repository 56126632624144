import { IWithPages, WithPagesModel } from '../WithPagesModel';


export interface IEducationModel {
  id: number;
  name: string;
}

export class EducationModel implements IEducationModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}


export interface IEducationsModel extends IWithPages {
  data: IEducationModel[];
}

export class EducationsModel extends WithPagesModel implements IEducationsModel {
  data: IEducationModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, EducationModel);
  }
}
