import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { INotificationModel } from '../../models/Notification/Notifications';
import { UiBox } from '../common/UI/UiBox';
import { NotificationItem } from './NotificationItem';
import { SubmitLoaderButton } from '../common/SubmitLoaderButton';
import { NotificationLink } from '../common/NotificationLink';


type Props = {
  model: INotificationModel;
  readLoading: boolean;
  setReadVal: (val: boolean) => void;
}

export const NotificationOneComponent = ({ model, readLoading, setReadVal }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-header">
        <Link to="/notifications" className="back" />
        {t('notifications.one')}
      </div>
      <UiBox className="notifone">
        <section className="form form-with-btn">
          <NotificationItem model={model} />

          <div className="form-wrapper_btn">
            <div className="buttons-group buttons-group-responsive">
              {model.notify_text.link && (
                <NotificationLink
                  color="success"
                  link={model.notify_text.link}
                  onClick={() => {
                    if (!model.is_read) {
                      setReadVal(true);
                    }
                  }}
                />
              )}

              <SubmitLoaderButton
                onClick={() => setReadVal(!model.is_read)}
                loading={readLoading}
                label={model.is_read ? t('notifications.set_unread') : t('notifications.set_read')}
              />
            </div>
          </div>

        </section>
      </UiBox>
    </>
  );
};

NotificationOneComponent.whyDidYouRender = true;
