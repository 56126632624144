import { FileType, prepareFiles } from '../common';
import { INewsCategoryItemModel } from './NewsCategoryModel';
import { IEnterpriseModel } from '../Enterprises/EnterpriseModel';
import { CDate } from '../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../config/system';

export interface INewsItemExternalResource {
  readonly url: string;
  readonly name: string;
}

/** полная модель новости */
export interface INewsItemModel {
  readonly id: number;
  readonly title: string;
  readonly short_description: string;
  readonly content: string;
  readonly is_active: boolean;
  readonly main_image: FileType;
  readonly images: FileType[];
  readonly ref_instagram: string;
  readonly ref_twitter: string;
  readonly ref_vk: string;
  readonly ref_ok: string;
  readonly ref_facebook: string;
  readonly ref_other_name: string;
  readonly ref_other: string;
  readonly ref_video: string;
  readonly ref_gallery: string;
  readonly created_at: Date;
  readonly categories: INewsCategoryItemModel[];
  readonly enterprises: IEnterpriseModel[];
  is_liked: boolean;
  like_count: number;

  /** список соцсетей */
  readonly socials: INewsItemExternalResource[];
  /** список сторонних ресурсов */
  readonly thirdPartyResources: INewsItemExternalResource[];
}

export class NewsItemModel implements INewsItemModel {
  readonly categories: INewsCategoryItemModel[];
  readonly content: string;
  readonly created_at: Date;
  readonly enterprises: IEnterpriseModel[];
  readonly id: number;
  readonly images: FileType[];
  readonly is_active: boolean;
  readonly main_image: FileType;
  readonly ref_facebook: string;
  readonly ref_gallery: string;
  readonly ref_instagram: string;
  readonly ref_ok: string;
  readonly ref_other: string;
  readonly ref_other_name: string;
  readonly ref_twitter: string;
  readonly ref_video: string;
  readonly ref_vk: string;
  readonly short_description: string;
  readonly title: string;
  is_liked: boolean;
  like_count: number;

  constructor(params: any = {}) {
    this.categories = params.categories;
    this.content = params.content;
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.enterprises = params.enterprises;
    this.id = params.id;
    this.images = params.images.map(prepareFiles);
    this.main_image = prepareFiles(params.main_image);
    this.is_active = params.is_active;
    this.ref_facebook = params.ref_facebook;
    this.ref_gallery = params.ref_gallery;
    this.ref_instagram = params.ref_instagram;
    this.ref_ok = params.ref_ok;
    this.ref_other = params.ref_other;
    this.ref_other_name = params.ref_other_name;
    this.ref_twitter = params.ref_twitter;
    this.ref_video = params.ref_video;
    this.ref_vk = params.ref_vk;
    this.short_description = params.short_description;
    this.title = params.title;
    this.is_liked = params.is_liked;
    this.like_count = params.like_count || 0;
  }

  get socials() {
    return [
      { url: this.ref_vk, name: 'vk' },
      { url: this.ref_facebook, name: 'fb' },
      { url: this.ref_instagram, name: 'inst' },
      { url: this.ref_ok, name: 'ok' },
      { url: this.ref_twitter, name: 'twitter' },
    ].filter((social) => social.url.length);
  }

  get thirdPartyResources() {
    return [
      {
        url: this.ref_other, name: this.ref_other_name,
      },
    ].filter((thirdPartyResource) => thirdPartyResource.url.length);
  }
}
