import React from 'react';
import { useForm } from 'react-hook-form';

import { UserAccessEnum } from 'models/User/CurrentUserModel';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSelect } from 'components/common/UI/Select';
import { UiSystemInput } from 'components/common/UI/Input';
import { IEditUSerSettings, ISystemUserModel } from 'models/User/interfaces';
import { useSelector } from 'react-redux';
import { hasRight } from 'store/currentUser/selectors';

type Props = {
  user: ISystemUserModel;
  onSave: (data: IEditUSerSettings) => void;
}

type FormValues = {
  showPhone: any;
  assigned_for_all_enterprises: boolean;
  isActive: boolean;
  isRegister: boolean;
  readonly userdata: string;
}

const showPhoneOptions = [
  {
    label: 'Да',
    value: '1',
    isShowPhone: true,
  },
  {
    label: 'Нет',
    value: '0',
    isShowPhone: false,
  },
];

export const SystemUserSettingsComponent = ({ user, onSave }: Props) => {
  const hasEditRight = useSelector(hasRight(UserAccessEnum.w_user_settings));
  const {
    register, handleSubmit, errors, setValue,
    formState: { isSubmitting, isValid }, getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      userdata: `ID: ${user.id}, ФИО: "${user.fio}"`,
      showPhone: showPhoneOptions.find((option) => user.show_phone === option.isShowPhone),
      assigned_for_all_enterprises: user.assigned_for_all_enterprises,
      isActive: user.isActive,
      isRegister: user.isRegister,
    },
  });

  const onSubmit = (data: FormValues) => onSave(
    {
      show_phone: data.showPhone.isShowPhone,
      assigned_for_all_enterprises: data.assigned_for_all_enterprises,
      is_active: data.isActive,
    },
  );

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={0}
      showButtons={hasEditRight}
    >
      <h3>Настройки пользователя</h3>

      <UiSystemInput
        name="userdata"
        errors={errors}
        register={register}
        label="Пользователь"
        disabled
      />

      <UiSelect
        defaultValue={getValues('showPhone')}
        register={register}
        errors={errors}
        name="showPhone"
        label="Отображать контактный номер?"
        setValue={setValue}
        options={showPhoneOptions}
      />

      <UiSystemCheckbox
        name="assigned_for_all_enterprises"
        register={register}
        label="Назначать на заявки любых организаций"
      />

      <UiSystemCheckbox
        name="isActive"
        register={register}
        label="Активный"
      />

      <UiSystemCheckbox
        name="isRegister"
        register={register}
        label="Регистрация завершена"
        isDisabled
      />

    </UiSystemForm>
  );
};
