import React from 'react';
import { Link } from 'react-router-dom';

import { PartnersCommonListComponent, PartnersItemInfoComponent } from 'components/Partners/common';
import { IPartnerCategoryListModel } from 'models/Partners/PartnerCategoryListModel';


type Props = {
  model: IPartnerCategoryListModel;
}

export const PartnersCategoriesListComponent = ({ model }: Props) => (
  <PartnersCommonListComponent title="Категории">
    {model.map(({ id, logo, name }) => (
      <PartnersItemInfoComponent key={id} logo={logo} title={name} tag="link" to={`/partners-category/${id}`} />
    ))}
    <div className="py-2">
      <Link className="btn btn-outline-light btn-block" to="/partners-all">Все партнёры</Link>
    </div>
  </PartnersCommonListComponent>
);
