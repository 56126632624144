import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UiBox } from '../common/UI/UiBox';

const contentStyle = {
  display: 'flex',
};

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <UiBox contentStyle={contentStyle}>
      <section className="error-page">
        <div className="error-wrapper">
          <div className="error-code">404</div>
          <div className="error-title">{t('404.title')}</div>
          <div className="error-text">{t('404.text')}</div>
          <Link className="btn btn-default" to="/">{t('404.toMain')}</Link>
        </div>
      </section>
    </UiBox>
  );
};

export default Page404;


type Props = {
  message: string;
}
/** для ненайденных сущностей, просто передать строку с текстом типа 'не найдено' */
export const EntityNotFound = ({ message }: Props) => (
  <div className="box">
    <section className="form">
      <h3 className="text-center">{message}</h3>
    </section>
  </div>
);
