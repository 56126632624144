import { SystemPartnerCategoryFormModel } from 'models/Partners/SystemPartnerCategoryModel';
import {
  ISystemPartnersCategoryListItemModel,
  SystemPartnersCategoryListItemModel,
} from 'models/Partners/SystemPartnerCategoryListModel';
import {
  SystemPartnersFormModel,
  SystemPartnersFormType,
  SystemPartnersModelType,
} from 'models/Partners/SystemPartnerFormModel';
import { baseApiFn } from 'utils/baseApiFn';
import { getAllListData } from 'utils/getAllListData';
import {
  httpDelete, httpGet, httpPost, httpPut,
} from 'utils/http';

/** Удалить категорию партнеров */
export const systemDeletePartnerCategory = async (id: number) => baseApiFn<true>(
  httpDelete(`/system/partner/category/${id}`),
);

/** Получить категорию партнеров */
export const systemGetPartnerCategoryById = async (id: number) => baseApiFn<ISystemPartnersCategoryListItemModel>(
  () => httpGet(`/system/partner/category/${id}`)
    .then((_) => new SystemPartnersCategoryListItemModel(_)),
);

/** Обновить категорию партнеров */
export const systemUpdatePartnerCategory = async (id: number, model: SystemPartnerCategoryFormModel) => baseApiFn<true>(
  httpPut(`/system/partner/category/${id}`, model),
);

/** Создать категорию партнеров */
export const systemCreatePartnerCategory = async (model: SystemPartnerCategoryFormModel) => baseApiFn<true>(
  httpPost('/system/partner/category', model),
);

/** Получить все категории партнеров */
export const systemGetAllPartnerCategories = async () => baseApiFn<ISystemPartnersCategoryListItemModel[]>(
  getAllListData('/public/partner/category', SystemPartnersCategoryListItemModel),
);


/** Создать партнера */
export const systemCreatePartner = async (model: SystemPartnersFormType) => baseApiFn<true>(
  httpPost('/system/partner', model),
);

/** Обновить партнера */
export const systemUpdatePartner = async (id: number, model: SystemPartnersFormType) => baseApiFn<true>(
  httpPut(`/system/partner/${id}`, model),
);

/** Удалить партнера */
export const systemDeletePartner = async (id: number) => baseApiFn<true>(
  httpDelete(`/system/partner/${id}`),
);

/** Получить партнера */
export const systemGetPartnerById = async (id: number) => baseApiFn<SystemPartnersModelType>(
  () => httpGet(`/system/partner/${id}`)
    .then((_) => new SystemPartnersFormModel(_)),
);
