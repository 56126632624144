import React from 'react';
import Slider from 'react-slick';

import { FileType } from 'models/common';


type Props = {
  data: FileType[];
}

export const UiSimpleMultiSlider = ({ data }: Props) => (data.length > 0 ? (
  <Slider dots infinite arrows={false}>
    {data.map((img: FileType) => (
      <img key={img.name} src={img.fullUrl} alt={img.name} />
    ))}
  </Slider>
) : null);
