import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../../../components/common/Table';
import { useDataPage } from '../../../../utils/hooks/useDataPage';
import { SystemJobDescriptionsFilter } from './Filter';
import {
  IJobDescriptionsItemModel,
  IJobDescriptionsModel,
  JobDescriptionsFilterType,
} from '../../../../models/JobDescription/JobDescription';
import { IEnterpriseModel } from '../../../../models/Enterprises/EnterpriseModel';
import { ISystemUnitModel, UnitsFilterType } from '../../../../models/References/UnitsModel';
import { IPositionsItemModel, PositionsFilterType } from '../../../../models/References/Positions';

type Props = {
  getData: (filter?: JobDescriptionsFilterType) => void;
  onDelete: (item: IJobDescriptionsItemModel) => void;
  isLoading: boolean;
  data: IJobDescriptionsModel;
  enterprises: IEnterpriseModel[];
  units: ISystemUnitModel[];
  positions: IPositionsItemModel[];
  getPositions: (filter?: PositionsFilterType) => void;
  getUnits: (filter?: UnitsFilterType) => void;
  positionsIsLoading: boolean;
  unitsIsLoading: boolean;
}

export const JobDescriptionsComponent = ({
  getData,
  onDelete,
  isLoading,
  data,
  enterprises,
  units,
  positions,
  getPositions,
  getUnits,
  positionsIsLoading,
  unitsIsLoading,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const handleDelete = (item: IJobDescriptionsItemModel) => () => onDelete(item);

  return (
    <>
      <h3>Список должностных инструкций</h3>

      <SystemJobDescriptionsFilter
        onSubmit={onSubmitFilter}
        onReset={onResetFilter}
        enterprises={enterprises}
        units={units}
        positions={positions}
        getPositions={getPositions}
        getUnits={getUnits}
        positionsIsLoading={positionsIsLoading}
        unitsIsLoading={unitsIsLoading}
      />

      <CustomTable
        sizePerPage={data.limit}
        setSizePerPage={[setLimit, setPage]}
        amountOfPages={data.amountOfPages}
        currentPage={data.page}
        onChangePage={setPage}
        isLoading={isLoading}
        customButtons={(
          <Button
            tag={Link}
            color="primary"
            to="jobdescription/create"
          >
            Создать должностную инструкцию
          </Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: t('jobDescriptions.name') },
          { name: t('common.enterprise') },
          { name: t('common.unit') },
          { name: t('jobDescriptions.position') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((description) => (
          <tr key={description.id}>
            <td>
              <Link to={`jobdescription/${description.id}`}>{description.id}</Link>
            </td>
            <td>
              <Link to={`jobdescription/${description.id}`}>{description.name}</Link>
            </td>
            <td>
              {description.position.unit.enterprise.name}
            </td>
            <td>
              {description.position.unit.name}
            </td>
            <td>
              {description.position.name}
            </td>
            <td>
              <Button type="button" color="danger" onClick={handleDelete(description)}>
                {t('common.form.delete')}
              </Button>
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
