import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18next from 'i18next';
import { emptyOption, emptyOptionValue, UiSelect } from '../../../../components/common/UI/Select';
import { UiToolbar } from '../../../../components/common/Toolbar';
import { ReportHousingFilterType } from '../../../../models/Reports/interfaces';
import { UiSystemInput } from '../../../../components/common/UI/Input';
import { hasUnemptyValues } from '../../../../utils/objects';


const selectOptions = () => [
  { label: i18next.t('common.yes'), value: 1 },
  { label: i18next.t('common.no'), value: -1 },
];


type Props = {
  onSubmit: (data: ReportHousingFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  from_sds: { label: string; value: number };
  area_from: string;
  area_to: string;
}

export const SystemReportHousingFilter = ({ onSubmit, onReset }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, setValue, reset, watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      from_sds: emptyOption(),
    },
  });

  const onLocalSubmit = (data: FormValues) => {
    const preparedFilterData = {
      from_sds: data.from_sds.value === emptyOptionValue ? undefined : data.from_sds.value === 1,
      area_from: data.area_from.length ? +data.area_from : undefined,
      area_to: data.area_to.length ? +data.area_to : undefined,
    };

    if (hasUnemptyValues(preparedFilterData)) {
      onSubmit(preparedFilterData);
    }
  };

  const onLocalReset = () => {
    reset();
    onReset();
  };

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="from_sds"
            value={watch('from_sds')}
            options={selectOptions()}
            label={t('reports.housing.from_sds')}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('reports.housing.area_from')}
            name="area_from"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('reports.housing.area_to')}
            name="area_to"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
