import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

function loadLocales(url: string, options: any, callback: any) {
  try {
    import(`../locales/${url}.json`).then((result) => {
      callback(result.default, { status: '200' });
    });
  } catch (e) {
    callback(null, { status: '404' });
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // lng: "ru",
    fallbackLng: 'ru',
    // debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '{{lng}}',
      addPath: 'locales/add/{{lng}}/{{ns}}',
      allowMultiLoading: false,
      parse: (data: any) => data,
      crossDomain: true,
      withCredentials: true,
      overrideMimeType: false,
      customHeaders: {
        authorization: 'foo',
      },
      ajax: loadLocales,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'lang',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    },
  }); /** (err, t) => {
    console.error(err, t);
  } */

export default i18n;
