import { put, takeLatest } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import { SYSTEM_GET_USER_ROLES_START, SYSTEM_GET_USERS_LIST_START } from './constants';
import {
  systemGetUserAndRolesFailAction,
  systemGetUsersListFailAction,
  systemGetUsersListOkAction,
  systemGetUsersListStartAction,
} from './actions';
import { systemGlobalGetRolesStartAction } from '../../store/actions';
import { SystemUserListModel } from '../../../models/Users/SystemUserListModel';


function* systemGetUsersSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/user/list', filter);

    yield put(systemGetUsersListOkAction(new SystemUserListModel(response)));
  }, systemGetUsersListFailAction);
}

function* systemGetUserAndRolesSaga() {
  yield baseSaga(function* () {
    yield put(systemGlobalGetRolesStartAction({}));

    yield put(systemGetUsersListStartAction());
  }, systemGetUserAndRolesFailAction);
}


export function* systemUsersSagas() {
  yield takeLatest(SYSTEM_GET_USERS_LIST_START, systemGetUsersSaga);
  yield takeLatest(SYSTEM_GET_USER_ROLES_START, systemGetUserAndRolesSaga);
}
