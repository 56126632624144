import React, { useEffect, useRef } from 'react';
import { HttpErrors } from '../http';

/**
 * хук для промисов
 *
 * -  решение проблемы Warning: Can't perform a React state update on an unmounted component.
 *    This is a no-op, but it indicates a memory leak in your application.
 *    To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
 * -  отдает состояние выполнения массивом [значение, ошибка, состояние загрузки]
 * -  необходимо передавать функцию с промисом, чтобы промис не выполнялся каждый раз.
 *    эта функция будет вызвана при первом старте и при смене зависимостей
 *
 * @example
 * const { id } = useParams(); // ожидается число
 * const numberId = toNumber(id);
 * const [newsModel, error, isLoading] = usePromise<INewsItemModel>(() => getSmth(numberId), !isNaN(numberId));
 *
 * пример использования обработки ошибки:
 * useEffect(() => {
    if (error instanceof Error) {
      // ... обработка ошибка
    }
  }, [error]);
 *
 *
 * @example
 * корректная отписка при размонтировании компонента
 *
 * const [, , onUnmount] = usePromise(...);
 * // eslint-disable-next-line
 * useEffect(() => onUnmount, []);
 *
 *
 * @param apiFunc - функция с промисом
 * @param doRequest - условие для проверки, при false запрос не будет отправлен и error заполнится ошибкой
 * @param dependencies - опциональный массив зависимостей
 */
type ThisType = <T>(
  apiFunc: () => Promise<T | Error>,
  doRequest: boolean,
  dependencies?: any[]
) => [null | T, boolean, () => void, null | HttpErrors];

type State = { value: any; error: null | Error; isPending: boolean };

export const usePromise: ThisType = (apiFunc, doRequest, dependencies = []) => {
  const ref = useRef<any>(null);
  const [state, setState] = React.useState<State>({ value: null, error: null, isPending: true });

  let mounted = true;
  const onUnmount = () => {
    mounted = false;
  };

  /** при смене зависимостей - делать запрос заново */
  useEffect(() => {
    ref.current = null;
    // eslint-disable-next-line
  }, dependencies);

  useEffect(() => {
    /** запустить промис только один раз, при первом вызове хука */
    if (ref.current === null) {
      if (doRequest) {
        /** начало нового цикла загрузки */
        setState({ value: null, error: null, isPending: true });

        ref.current = apiFunc()
          .then((modelOrError: unknown) => {
            if (mounted) {
              if (modelOrError instanceof Error) {
                setState({ value: null, error: modelOrError, isPending: false });
              } else {
                setState({ value: modelOrError, error: null, isPending: false });
              }
            }
          });
      } else {
        ref.current = '404';
        setState({ value: null, error: new Error('404'), isPending: false });
      }
    }
  }, [apiFunc, doRequest, mounted]);

  const { value, error, isPending } = state;
  return [value, isPending, onUnmount, error];
};
