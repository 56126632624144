import { baseApiFn } from '../../utils/baseApiFn';
import { ISystemReferralProgramModel } from '../../models/ReferralProgram/interfaces';
import { httpGet } from '../../utils/http';
import { SystemReferralProgramModel } from '../../models/ReferralProgram/SystemReferralProgramModel';

export const getSystemReferralItem = async (id: number) => baseApiFn<ISystemReferralProgramModel>(async () => {
  const response = await httpGet(`system/referral/code/${id}`);

  return new SystemReferralProgramModel(response);
});
