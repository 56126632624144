import React from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { customHistory } from 'customHistory';
import { HttpErrors } from '../../../../utils/http';
import { handleErrors } from '../../../../utils/errors';
import { ICurrentUserModel, UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import { UiSystemInput } from '../../../../components/common/UI/Input';
import { ISystemNewsCategoryModel } from '../../../../models/References/NewsCategoriesModel';
import { createSystemNewsCategory, updateSystemNewsCategory } from '../../../api/references/newsCategories';
import { Loader } from '../../../../components/common/UI/Loaders';
import { UiSystemForm } from '../../../../components/common/UI/Form';

const saveForm = async (createMode: boolean, data: FormValues, item: ISystemNewsCategoryModel) => {
  const trueOrError: true | HttpErrors = (createMode) ?
    await createSystemNewsCategory(data) :
    await updateSystemNewsCategory(item.id, data);

  handleErrors(
    trueOrError,
    'save',
    () => {
      toast.success(createMode ? 'Категория успешно создана' : 'Категория успешно обновлена');
      customHistory.push('/system/references/newscategory');
    },
  );
};

type Props = {
  item: ISystemNewsCategoryModel;
  isLoading: boolean;
}

type FormValues = {
  name: string;
}

export const SystemNewsCategoryFormComponent = ({ item, isLoading }: Props) => {
  const createMode = customHistory.location.pathname === '/system/references/newscategory/create';

  const {
    register, handleSubmit, errors, formState: { isValid, isSubmitting, dirtyFields },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: item.name,
    },
  });

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wReferenceNewsCategory);

  const onSubmit = (data: FormValues) => (canUpdate ? saveForm(createMode, data, item) : Promise.reject());

  return isLoading ? <Loader /> : (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
    >
      {
        (item.id === undefined && !createMode) ? <h3>Категория не найдена</h3> : (
          <>
            <h3>{createMode ? 'Создание' : 'Редактирование'} категории новостей</h3>
            <UiSystemInput
              errors={errors}
              register={register}
              name="name"
              label="Название категории"
              required
            />
          </>
        )}
    </UiSystemForm>
  );
};
