import { projectBaseUrl } from 'utils/env';
import {
  httpAll, HttpErrors, httpGet, httpPost,
} from 'utils/http';
import { FileType } from 'models/common';

/** класс для загрузки файлов на сервер */
export class UploadFiles {
  files: File[];

  constructor(files: File[]) {
    this.files = files;
  }

  /** загрузка файлов */
  async upload(): Promise<{ file_name: string; token: string }[] | HttpErrors> {
    const pfiles = this.files.map((file) => {
      const formData = new FormData();
      formData.append('file', file);

      return httpPost<string>('/upload', formData, {
        'Content-Type': 'multipart/form-data',
      });
    });

    try {
      return await httpAll(pfiles);
    } catch (error: any) {
      return error;
    }
  }
}


/** класс для получения файлов с сервера */
export class LoadFiles {
  fileTypes: FileType[];

  constructor(fileTypes: FileType[]) {
    this.fileTypes = fileTypes;
  }

  async load(): Promise<File[] | false> {
    const prepareLoadFiles = this.fileTypes
      .map((fileType) => httpGet<Blob>(`${projectBaseUrl}${fileType.url}`, {}, {
        responseType: 'blob',
      }));

    try {
      const filesLoaded: Array<Blob | HttpErrors> = await Promise.all(prepareLoadFiles);

      return filesLoaded
        .map((blob: Blob | HttpErrors, i) => new File([blob instanceof Blob ? blob : ''], this.fileTypes[i].name));
    } catch (e) {
      return false;
    }
  }
}
