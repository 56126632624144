import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { CustomTable } from 'components/common/Table';
import {
  IApplicationTypeListFilter, IConstructApplicationCategoryListItemModel,
  IConstructApplicationTypeListItemModel,
  IConstructApplicationTypeListModel,
} from 'models/ConstructApplication/interfaces';
import { useDataPage } from 'utils/hooks/useDataPage';
import { useSelector } from 'react-redux';
import { SystemApplicationTypeListFilter } from './Filter';
import { usePromise } from '../../../../utils/hooks/usePromise';
import {
  getConstructApplicationCategoryAll,
} from '../../../api/construct-application';
import { IEnterpriseModel } from '../../../../models/Enterprises/EnterpriseModel';
import { systemEnterprisesSelector } from '../../../store/selectors';
import { SimpleSelectOptionType } from '../../../../utils/convertModelToOptions';
import { handleErrors } from '../../../../utils/errors';
import { getUsersOptions } from '../../../../api/Users';
import { emptyOptionValue } from '../../../../components/common/UI/Select';

type Props = {
  data: IConstructApplicationTypeListModel;
  isLoading: boolean;
  getData: (filter: IApplicationTypeListFilter) => void;
  canCreate: boolean;
  canDelete: boolean;
  onDelete: (item: IConstructApplicationTypeListItemModel) => void;
  onChangeActive: (item: IConstructApplicationTypeListItemModel) => () => void;
}

export const ConstructApplicationTypeListComponent = ({
  data, isLoading, getData, canCreate, canDelete, onDelete, onChangeActive,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const enterpriseList: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const [categoriesList] = usePromise<IConstructApplicationCategoryListItemModel[]>(
    getConstructApplicationCategoryAll,
    true,
  );

  const [usersOptions, setUsersOptions] = useState<SimpleSelectOptionType<number>[]>([]);

  const getUsers = useCallback(async (enterpriseId: number) => {
    handleErrors(
      await getUsersOptions({
        enterprise: enterpriseId === emptyOptionValue ? undefined : [enterpriseId],
      }),
      'get',
      setUsersOptions,
    );
  }, []);

  return (
    <>
      <h3>{t('system.construct_application.type.list')}</h3>

      <SystemApplicationTypeListFilter
        onSubmit={onSubmitFilter}
        onReset={onResetFilter}
        enterpriseList={enterpriseList}
        categoriesList={categoriesList || []}
        getUsers={getUsers}
        usersOptions={usersOptions}
      />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: 'ID' },
          { name: t('system.construct_application.type.name') },
          { name: t('system.construct_application.type.enterprise') },
          { name: t('system.construct_application.type.assignUser') },
          { name: t('system.construct_application.type.category') },
          { name: t('system.construct_application.type.is_active') },
          { name: t('common.table.actions') },
        ]}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="type/create">
            {t('system.construct_application.type.create')}
          </Button>
        )}
      >
        {data.data.map((item: IConstructApplicationTypeListItemModel) => (
          <tr key={item.id}>
            <td>
              <Link to={`type/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`type/${item.id}`}>{item.name}</Link>
            </td>
            <td>
              {item.enterprise ? item.enterprise.name : t('common.allEnterprises')}
            </td>
            <td>
              {item.assignUsers.map((assignUser) => (
                <p key={assignUser.id}>{assignUser.fio}</p>
              ))}
            </td>
            <td>
              {item.category?.name}
            </td>
            <td>
              {t(`common.${item.is_active ? 'yes' : 'no'}`)}
            </td>
            <td>
              <Button
                color={item.is_active ? 'primary' : 'success'}
                className="w-100 mb-4"
                onClick={onChangeActive(item)}
              >
                {t(`system.construct_application.type.active.${item.is_active ? 'disable' : 'enable'}`)}
              </Button>
              {canDelete && (
                <Button type="button" color="danger" className="w-100" onClick={() => onDelete(item)}>
                  {t('common.form.delete')}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
