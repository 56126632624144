import { AnyAction } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { baseSaga } from '../../store/baseSaga';
import { httpPost, httpGet, httpDelete } from '../../utils/http';
import {
  getBusStopListFailAction,
  getBusStopListOkAction,
  setBusStopFavoriteFailAction,
  setBusStopFavoriteOkAction, removeBusStopFavoriteFailAction, removeBusStopFavoriteOkAction,
} from './actions';
import { BusStopsModel } from '../../models/BusStops/BusStops';
import { GET_BUS_STOP_LIST_START, SET_BUS_STOP_FAVORITE_START, REMOVE_BUS_STOP_FAVORITE_START } from './constants';

function* getBusStopListSaga({ payload: { params, append } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/transport/bus-stop', params);

    yield put(getBusStopListOkAction(new BusStopsModel(response), append));
  }, getBusStopListFailAction);
}

function* setBusStopFavoriteSaga({ payload: { id } }: AnyAction) {
  yield baseSaga(function* () {
    yield httpPost(`/public/transport/bus-stop/${id}/favorite`);

    yield put(setBusStopFavoriteOkAction(id));
  }, setBusStopFavoriteFailAction);
}

function* removeBusStopFavoriteSaga({ payload: { id } }: AnyAction) {
  yield baseSaga(function* () {
    yield httpDelete(`/public/transport/bus-stop/${id}/favorite`);

    yield put(removeBusStopFavoriteOkAction(id));
  }, removeBusStopFavoriteFailAction);
}

export function* busStopsSaga() {
  yield takeLatest(GET_BUS_STOP_LIST_START, getBusStopListSaga);
  yield takeLatest(SET_BUS_STOP_FAVORITE_START, setBusStopFavoriteSaga);
  yield takeLatest(REMOVE_BUS_STOP_FAVORITE_START, removeBusStopFavoriteSaga);
}
