import { dataAction, dataErrorAction, dataSuccessAction } from 'store/actions';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import * as CONSTANTS from './constants';

export const getEnterprisesListAction = dataAction(CONSTANTS.ENTERPRISES_GET_LIST_START);
export const getEnterprisesListOkAction = (enterprises: IEnterpriseModel[]) => dataSuccessAction(
  CONSTANTS.ENTERPRISES_GET_LIST_OK,
)({ enterprises });
export const getEnterprisesListFailAction = dataErrorAction(CONSTANTS.ENTERPRISES_GET_LIST_FAIL);

export const setMainEnterpriseAction = dataAction(CONSTANTS.SET_MAIN_ENTERPRISE);
export const setMainEnterpriseOkAction = dataAction(CONSTANTS.SET_MAIN_ENTERPRISE_OK);
export const setMainEnterpriseFailAction = dataAction(CONSTANTS.SET_MAIN_ENTERPRISE_FAIL);
