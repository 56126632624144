import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button, Card, Col, Collapse, FormGroup, Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { Link } from 'react-router-dom';
import { UiSystemForm } from '../../../components/common/UI/Form';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { UiDatePicker } from '../../../components/common/Dates/UiDatePicker';
import { convertModelToOptions, SimpleSelectOptionType } from '../../../utils/convertModelToOptions';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { UiSelect } from '../../../components/common/UI/Select';
import { UiSystemCheckbox } from '../../../components/common/UI/Checkbox';
import { UiFileUploaderSystem } from '../../../components/common/FileUploader';
import { defaultImgExtensions } from '../../../config/system';
import { IEventFormModel } from '../../../models/Events/IEventFormModel';
import { CDate } from '../../../utils/CDate';
import { UploadFiles } from '../../../models/UploadFiles/UploadFiles';
import { ISystemEvent } from '../../../models/Events/SystemEvent';
import { ISystemEventPollResults } from '../../../models/Events/SystemEventPollResult';
import { Loader } from '../../../components/common/UI/Loaders';
import { PollResultItem } from './PollResultItem';


type Props = {
  model: ISystemEvent;
  enterprises: IEnterpriseModel[];
  onSave: (id: number | null, model: IEventFormModel) => void;
  getPollResults: () => void;
  pollResultsModel: ISystemEventPollResults | null;
  pollResultsModelLoading: boolean;
  linkToExport: string;
  canUpdate: boolean;
}

type FormValues = {
  name: string;
  date_start: Date | null;
  place: string;
  contacts: string;
  image: null | File;
  description: string;
  ask_question: boolean;
  send_notify: boolean;
  enterprises: SimpleSelectOptionType[];
}


export const SystemEventsFormComponent = ({
  model, enterprises, onSave, getPollResults, pollResultsModel, pollResultsModelLoading, linkToExport, canUpdate,
}: Props) => {
  const { t } = useTranslation();

  const optionsEnterprises = convertModelToOptions(enterprises);

  const {
    register, handleSubmit, watch, errors, setValue,
    formState: { isSubmitting, dirtyFields, isValid }, getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
      date_start: model.date_start,
      place: model.place,
      contacts: model.contacts,
      description: model.description,
      ask_question: model.ask_question,
      send_notify: model.send_notify,
      enterprises: (() => {
        /** собрать ид предприятий и по ним взять значения селекта */
        const ids = model.enterprises
          .map((item: { id: number; name: string }) => item.id);
        return optionsEnterprises
          .filter((item) => ids.includes(item.value));
      })(),
    },
  });

  const createMode = !model.id;

  const [open, setOpen] = useState(false);

  /** отслеживать открытие подробностей голосования */
  useEffect(() => {
    if (open) {
      getPollResults();
    }
  }, [open, getPollResults]);

  useEffect(() => {
    /** регистрация полей, управляемых вручную */
    register('image');
  }, [register]);

  const onSubmit = async (data: FormValues) => {
    if (!canUpdate) {
      return;
    }

    const modelToSave: IEventFormModel = {
      ask_question: data.ask_question,
      date_start: CDate.format(data.date_start ? data.date_start : new Date(), 'dd.MM.yyyy HH:mm'),
      enterprises: data.enterprises.map((ent: SimpleSelectOptionType<number>) => ent.value),
      name: data.name,
      place: data.place,
      send_notify: data.send_notify,
      description: data.description.length ? data.description : undefined,
      contacts: data.contacts.length ? data.contacts : undefined,
    };

    /** разобраться с image */
    if (data.image) {
      if (data.image.name !== model?.image.name) {
        /** загрузить и обновить */
        const main_image = await new UploadFiles([data.image]).upload();

        if (main_image instanceof Error) {
          toast.error(i18next.t('common.form.errors.save'));
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        modelToSave.image = main_image[0].file_name;
      } else {
        modelToSave.image = model?.image.name;
      }
    } else {
      delete modelToSave.image;
    }

    onSave(model?.id || null, modelToSave);
  };

  const askQuestionInput = (
    <UiSystemCheckbox
      name="ask_question"
      register={register}
      label={t('event.ask_question')}
    />
  );

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
      showButtons={canUpdate}
    >
      <h3>{t(createMode ? 'event.form.create' : 'event.form.update')}</h3>

      <UiSystemInput
        name="name"
        errors={errors}
        register={register}
        label={t('event.name')}
        required
      />

      <UiDatePicker
        watch={watch}
        name="date_start"
        setValue={setValue}
        register={register}
        label={t('event.date_start')}
        showTimeSelect
        required
      />

      <UiSelect
        defaultValue={getValues('enterprises')}
        errors={errors}
        register={register}
        name="enterprises"
        label={t('event.enterprises')}
        setValue={setValue}
        options={optionsEnterprises}
        allowEmptyValue
        multiple
      />

      <UiSystemCheckbox
        name="send_notify"
        register={register}
        label={t('event.send_notify')}
      />

      {/** логика про голосование */}
      {(createMode || !model.ask_question) ? askQuestionInput : (
        <Card body>
          {askQuestionInput}
          <h3 className="text-center mb-0 mt-n3">{t('event.result_poll.title')}</h3>
          <Row className="mb-n3">
            <Col>
              <h3 className="text-center">
                {t('event.event.vote.true')}: {model.result_poll.yes}
              </h3>
            </Col>
            <Col>
              <h3 className="text-center">
                {t('event.event.vote.false')}: {model.result_poll.no}
              </h3>
            </Col>
          </Row>

          {/** нет голосовавших - не выводить блок получения подробностей голосов */}
          {(model.result_poll.yes + model.result_poll.no > 0) && (
            <div className="mt-3">
              <div className="d-flex justify-content-between">
                <Button color="primary" className="collapse-btn w-50" onClick={() => setOpen(!open)}>
                  Подробнее
                </Button>
                <Button
                  tag={Link}
                  to={linkToExport}
                  target="_blank"
                  color="primary"
                  className="w-50 ml-4"
                >
                  Выгрузка результатов опроса
                </Button>
              </div>
              <Collapse isOpen={open}>
                {pollResultsModelLoading ?
                  <Loader /> : (
                    <div className="d-flex justify-content-between">
                      {pollResultsModel ? (
                        <>
                          <div className="w-50">
                            {pollResultsModel
                              .filter((m) => m.result)
                              .map((m) => <PollResultItem key={`${m.fio}${m.date_poll}`} model={m} />)}
                          </div>
                          <div className="w-50 ml-4">
                            {pollResultsModel
                              .filter((m) => !m.result)
                              .map((m) => <PollResultItem key={`${m.fio}${m.date_poll}`} model={m} />)}
                          </div>
                        </>
                      ) : <h3>Нет данных</h3>}
                    </div>
                  )}
              </Collapse>
            </div>
          )}
        </Card>
      )}

      <UiSystemInput
        name="place"
        errors={errors}
        register={register}
        label={t('event.place')}
        required
      />

      <UiSystemInput
        type="textarea"
        name="description"
        errors={errors}
        register={register}
        label={t('event.description')}
      />

      <FormGroup>
        <strong>
          {t('event.image')}
        </strong>
        <UiFileUploaderSystem
          initFileTypes={model ? [model.image] : []}
          maxFilesCount={1}
          onChange={(files: File[]) => setValue('image', files[0])}
          accept={defaultImgExtensions}
        />
      </FormGroup>

      <UiSystemInput
        type="textarea"
        name="contacts"
        errors={errors}
        register={register}
        label={t('event.contacts')}
      />

    </UiSystemForm>
  );
};
