import { httpGet, httpPut } from '../../utils/http';

import {
  ISystemEnterprisesModel, SystemEnterprisesModel, SystemUserEnterprisesModel,
} from '../../models/References/EnterprisesModel';
import { baseApiFn } from '../../utils/baseApiFn';

export const getSystemUserEnterprises = async (id: number) => baseApiFn<ISystemEnterprisesModel>(async () => {
  const response = await httpGet(`/system/notifications/user-enterprises/${id}`);
  return new SystemUserEnterprisesModel(response);
});

export const getSystemUserEnterprisesUpdate =
  async (id: number, data: Record<string, string[]>) => baseApiFn<ISystemEnterprisesModel>(
    async () => {
      const response = await httpPut(`/system/notifications/user-enterprises/${id}`, data);
      return new SystemEnterprisesModel(response);
    },
  );
