import { CDate } from '../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../config/system';
import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { DefaultFilterType } from '../common';


export type IPollsFilterType = {
  title?: string;
  was_passed?: '0' | '1';
} & DefaultFilterType;


export interface IPollsItemModel {
  readonly id: number;
  readonly title: string;
  readonly created_at: Date;
  readonly status: number;
  readonly is_passed: boolean;
}

export class PollsItemModel implements IPollsItemModel {
  created_at: Date;
  id: number;
  is_passed: boolean;
  status: number;
  title: string;

  constructor(params: any = {}) {
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.id = params.id;
    this.is_passed = params.is_passed;
    this.status = params.status;
    this.title = params.title;
  }
}


export interface IPollsModel extends IWithPages {
  readonly data: IPollsItemModel[];
}

export class PollsModel extends WithPagesModel implements IPollsModel {
  data: IPollsItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, PollsItemModel);
  }
}
