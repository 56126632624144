import { takeLatest, put, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';

import { customHistory } from 'customHistory';
import { httpPost } from 'utils/http';
import { baseSaga } from 'store/baseSaga';
import { getCurrentUserSaga } from 'store/currentUser/sagas';
import {
  authLogoutFailAction, authLogoutOkAction, authSignInFailAction, authSignInOkAction,
} from './actions';
import { AUTH_LOGOUT_START, AUTH_SIGNIN_START } from './constants';

function* authSignInSaga({ payload: { data } }: AnyAction) {
  const { location: { pathname: currentPathName, prevPathname } } = customHistory;
  /** есть сохраненный начальный путь - кинуть на него, без проверок. иначе - на главную */
  const redirectTo = { pathname: currentPathName === prevPathname ? '/' : prevPathname };

  yield baseSaga(function* () {
    const response = yield httpPost('/auth/signin', data);

    yield put(authSignInOkAction());

    if (!response.mainEnterprise) {
      yield call(customHistory.push, { pathname: '/enterprise-selection' });
    }

    /** получить полные данные пользователя. модель */
    yield call(getCurrentUserSaga);

    yield call(customHistory.push, redirectTo);
  }, function* (e) {
    /** уже вошел, сообщить об этом + переадресация */
    if (e.response?.status === 403) {
      yield call(customHistory.push, { pathname: '/' });
    }

    if (e.message) {
      toast.error(e.message);
    }

    yield put(authSignInFailAction(e));
  });
}

function* authLogoutSaga({ payload: { data } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpPost('/auth/logout', data);

    if (response) { // ответ - true
      yield put(authLogoutOkAction());

      yield call(customHistory.push, { pathname: '/login' });
    }
  }, authLogoutFailAction);
}


export function* authSagas() {
  yield takeLatest(AUTH_SIGNIN_START, authSignInSaga);
  yield takeLatest(AUTH_LOGOUT_START, authLogoutSaga);
}
