import { AnyAction } from 'redux';

import { onFail, onLoad, onLoading } from 'utils/reducer';
import { ISystemRoutesListModel, SystemRoutesListModel } from 'models/Transport/SystemRouteModel';
import {
  SYSTEM_TRANSPORT_GET_ROUTES_LIST_FAIL,
  SYSTEM_TRANSPORT_GET_ROUTES_LIST_OK,
  SYSTEM_TRANSPORT_GET_ROUTES_LIST_START,
} from './constants';

export type systemTransportReducerType = {
  routesList: ISystemRoutesListModel;
  routesListIsLoading: boolean;
}

const initialState: systemTransportReducerType = {
  routesList: new SystemRoutesListModel(),
  routesListIsLoading: true,
};

export const storeName = 'transport';

export const systemTransportReducer = {
  [storeName]: (state: systemTransportReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_TRANSPORT_GET_ROUTES_LIST_START:
        return onLoading(state, 'routesList');
      case SYSTEM_TRANSPORT_GET_ROUTES_LIST_OK:
        return onLoad(state, payload, 'routesList');
      case SYSTEM_TRANSPORT_GET_ROUTES_LIST_FAIL:
        return onFail(state, 'routesList');

      default:
        return state;
    }
  },
};
