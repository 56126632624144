import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { SYSTEM_GET_EVENTS_START } from './constants';
import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import { systemGetEventsFailAction, systemGetEventsOkAction } from './actions';
import { SystemListEventsModel } from '../../../models/Events/SystemListEvents';


function* systemEventsGetListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/my-event', filter);

    yield put(systemGetEventsOkAction(new SystemListEventsModel(response)));
  }, systemGetEventsFailAction);
}


export function* systemEventsSagas() {
  yield takeLatest(SYSTEM_GET_EVENTS_START, systemEventsGetListSaga);
}
