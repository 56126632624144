import { ApplicationsListModel } from 'models/Application/ApplicationListModel';
import { IAllApplicationFilter, IApplicationsListModel } from 'models/Application/interfaces';
import { baseApiFn } from 'utils/baseApiFn';
import { httpGet } from 'utils/http';


/** Получить все заявки */
export const systemGetAllApplicationsList = async (filter: IAllApplicationFilter) => baseApiFn<IApplicationsListModel>(
  async () => {
    const response = await httpGet('/system/application/list-all-assign', filter);
    return new ApplicationsListModel(response);
  },
);
