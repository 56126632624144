import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import {
  SYSTEM_GET_ASSIGN_APPLICATIONS_FAIL,
  SYSTEM_GET_ASSIGN_APPLICATIONS_OK,
  SYSTEM_GET_ASSIGN_APPLICATIONS_START,
  SYSTEM_GET_REFERENCES_APPLICATION_FAIL,
  SYSTEM_GET_REFERENCES_APPLICATION_OK,
  SYSTEM_GET_REFERENCES_APPLICATION_START,
} from './constants';
import { IApplicationsListModel } from '../../../models/Application/interfaces';
import { IApplicationStatusesModel } from '../../../models/Application/Status/interfaces';
import { IApplicationTypesModel } from '../../../models/Application/Type/interfaces';
import { ApplicationsListModel } from '../../../models/Application/ApplicationListModel';


export type systemApplicationReducerType = {
  assignApplications: IApplicationsListModel;
  assignApplicationsIsLoading: boolean;

  statuses: IApplicationStatusesModel;
  statusesIsLoading: boolean;

  types: IApplicationTypesModel;
  typesIsLoading: boolean;
}

const initialState: systemApplicationReducerType = {
  assignApplications: new ApplicationsListModel(),
  assignApplicationsIsLoading: true,

  statuses: [],
  statusesIsLoading: true,

  types: [],
  typesIsLoading: true,
};

export const storeName = 'application';

export const systemApplicationReducer = {
  [storeName]: (state: systemApplicationReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_ASSIGN_APPLICATIONS_START:
        return onLoading(state, 'assignApplications');
      case SYSTEM_GET_ASSIGN_APPLICATIONS_OK:
        return onLoad(state, payload, 'assignApplications');
      case SYSTEM_GET_ASSIGN_APPLICATIONS_FAIL:
        return onFail(state, 'assignApplications');

      case SYSTEM_GET_REFERENCES_APPLICATION_START: {
        return {
          ...state,
          statuses: [],
          statusesIsLoading: true,
          types: [],
          typesIsLoading: true,
        };
      }
      case SYSTEM_GET_REFERENCES_APPLICATION_OK: {
        return {
          ...state,
          statuses: payload.statuses,
          statusesIsLoading: false,
          types: payload.types,
          typesIsLoading: false,
        };
      }
      case SYSTEM_GET_REFERENCES_APPLICATION_FAIL: {
        return {
          ...state,
          statuses: [],
          statusesIsLoading: false,
          types: [],
          typesIsLoading: false,
        };
      }

      default:
        return state;
    }
  },
};
