import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DefaultFilterType } from '../../../models/common';
import { systemGetPollsStartAction } from './actions';
import { SystemPollsComponent } from '../../components/Polls/SystemPolls';
import { ISystemPollsModel } from '../../../models/Polls/SystemPollsModel';
import { systemPollsIsLoadingSelector, systemPollsSelector } from './selectors';
import { systemPollsStatusesSelector } from '../../store/selectors';
import { IPollsStatusModel } from '../../../models/Polls/PollsStatusesModel';


type Props = {
  all?: boolean; // выводить ли все опросы
  report?: boolean; // использовать ли как компонент отчетов
}

export const SystemPolls = ({ all = false, report = false }: Props) => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemGetPollsStartAction(filter, all));
  }, [dispatch, all]);

  const data: ISystemPollsModel = useSelector(systemPollsSelector);
  const isLoading: boolean = useSelector(systemPollsIsLoadingSelector);

  const pollStatuses: IPollsStatusModel[] = useSelector(systemPollsStatusesSelector);

  return (
    <SystemPollsComponent
      getData={getData}
      data={data}
      isLoading={isLoading}
      allPolls={all}
      pollStatuses={pollStatuses}
      report={report}
    />
  );
};
