import { DefaultFilterType } from 'models/common';
import { dataAction, dataErrorAction } from 'store/actions';
import { IBonusProgramEventHistoryListModel } from 'models/BonusProgram/EventHistory/interfaces';
import {
  GET_EVENT_HISTORY_LIST_FAIL,
  GET_EVENT_HISTORY_LIST_OK,
  GET_EVENT_HISTORY_LIST_START,
} from 'containers/BonusProgram/constants';

export const getBonusProgramEventHistoryListStartAction = (filter: DefaultFilterType) => dataAction(
  GET_EVENT_HISTORY_LIST_START,
)({ filter });
export const getBonusProgramEventHistoryListOkAction = (
  eventHistoryList: IBonusProgramEventHistoryListModel,
) => dataAction(
  GET_EVENT_HISTORY_LIST_OK,
)({ eventHistoryList });
export const getBonusProgramEventHistoryListFailAction = dataErrorAction(GET_EVENT_HISTORY_LIST_FAIL);
