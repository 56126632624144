import React from 'react';
import { useParams } from 'react-router-dom';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';

import { ParamTypes } from 'models/common';
import { ISystemBusStopModel, SystemBusStopModel } from '../../../../models/References/BusStopsModel';
import { getSystemRefBusStop } from '../../../api/references/busStops';
import { SystemBusStopFormComponent } from '../../../components/References/BusStops/SystemBusStopsForm';
import { usePromise } from '../../../../utils/hooks/usePromise';
import { Loader } from '../../../../components/common/UI/Loaders';


export const SystemBusStopForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [model, modelLoading] = usePromise<ISystemBusStopModel>(
    () => getSystemRefBusStop(numberId), !isNaN(numberId), [numberId],
  );

  if (modelLoading) {
    return <Loader />;
  }
  return <SystemBusStopFormComponent item={model || new SystemBusStopModel({})} isLoading={modelLoading} />;
};
