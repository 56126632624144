import { IWithPages, WithPagesModel } from '../WithPagesModel';

export type BusStopsParamsType = {
  'per-page'?: number;
  page?: number;
  sort?: string;
  name?: string;
  favorite?: 0 | 1;
}

export interface IBusStopsItem {
  id: number;
  name: string;
  favorite: boolean;
  times: string[];
}

export class BusStopsItemModel implements IBusStopsItem {
  id: number;
  name: string;
  favorite: boolean;
  times: string[];

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
    this.favorite = params.favorite;
    this.times = params.times;
  }
}

export interface IBusStopsModel extends IWithPages {
  data: IBusStopsItem[];
}

export class BusStopsModel extends WithPagesModel implements IBusStopsModel {
  data: IBusStopsItem[];

  constructor(params: any = {}) {
    super(params);
    this.data = this.initializeData(params.data, BusStopsItemModel);
  }
}
