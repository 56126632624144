import { createSelector } from 'reselect';
import { returnParams } from 'store/selectors';
import { RootReducer } from 'store/reducers';
import { storeName } from 'systemModule/containers/BonusProgram/reducers';

const getPrizeList = (state: RootReducer) => state.system[storeName].prizeList;
const getPrizeListLoading = (state: RootReducer) => state.system[storeName].prizeListIsLoading;

const getTagList = (state: RootReducer) => state.system[storeName].tagList;
const getTagListLoading = (state: RootReducer) => state.system[storeName].tagListIsLoading;

const getEventHistoryList = (state: RootReducer) => state.system[storeName].eventHistoryList;
const getEventHistoryListLoading = (state: RootReducer) => state.system[storeName].eventHistoryListIsLoading;

const getEventList = (state: RootReducer) => state.system[storeName].eventList;
const getEventListLoading = (state: RootReducer) => state.system[storeName].eventListIsLoading;

export const systemBonusProgramPrizeListSelector = createSelector([getPrizeList], returnParams);
export const systemBonusProgramPrizeListLoadingSelector = createSelector([getPrizeListLoading], returnParams);

export const systemBonusProgramTagListSelector = createSelector([getTagList], returnParams);
export const systemBonusProgramTagListLoadingSelector = createSelector([getTagListLoading], returnParams);

export const systemBonusProgramEventHistoryListSelector = createSelector([getEventHistoryList], returnParams);
export const systemBonusProgramEventHistoryListLoadingSelector = createSelector([
  getEventHistoryListLoading], returnParams);

export const systemBonusProgramEventListSelector = createSelector([getEventList], returnParams);
export const systemBonusProgramEventListLoadingSelector = createSelector([getEventListLoading], returnParams);
