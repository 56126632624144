import { maxValueToGetAllDataWithPagination } from 'config/system';
import { DefaultFilterType } from 'models/common';
import {
  ConstructApplicationCategoryListItemModel,
} from 'models/ConstructApplication/ConstructApplicationCategoryListModel';
import {
  ConstructApplicationTemplateListItemModel, ConstructApplicationTypeModel,
} from 'models/ConstructApplication/ConstructApplicationTemplateListModel';
import { ConstructApplicationTypeListModel } from 'models/ConstructApplication/ConstructApplicationTypeListModel';
import {
  ConstructApplicationTemplateFormType,
  ConstructApplicationTypeFormType, ConstructApplicationTypeModelAvailableType, ConstructApplicationTypeSaveFieldsType,
  IConstructApplicationCategoryListItemModel,
  IConstructApplicationTemplateListItemModel,
  IConstructApplicationTypeListModel,
  IConstructApplicationTypeModel,
} from 'models/ConstructApplication/interfaces';
import { baseApiFn } from 'utils/baseApiFn';
import {
  httpDelete, httpGet, httpPost, httpPut,
} from 'utils/http';


/** Получить категорию заявок */
export const getConstructApplicationCategoryById = async (
  id: number,
) => baseApiFn<IConstructApplicationCategoryListItemModel>(
  async () => {
    const response = await httpGet(`/system/construct-application/category/${id}`);
    return new ConstructApplicationCategoryListItemModel(response);
  },
);

/** Получить все категории заявок */
export const getConstructApplicationCategoryAll = async () => baseApiFn<IConstructApplicationCategoryListItemModel[]>(
  async () => {
    const data: IConstructApplicationCategoryListItemModel[] = [];
    let response;
    let page = 1;

    // todo setTimeout or Promise in loop
    do {
      // eslint-disable-next-line no-await-in-loop
      response = await httpGet('/system/construct-application/category', {
        'per-page': maxValueToGetAllDataWithPagination,
        page,
      });

      page += 1;
      const models = response.data.map((model: unknown) => new ConstructApplicationCategoryListItemModel(model));

      data.push(...models);
    } while (response.page < response.amountOfPages);

    return data;
  },
);

/** Редактировать категорию заявок */
export const updateConstructApplicationCategory = async (
  model: IConstructApplicationCategoryListItemModel,
) => baseApiFn<true>(httpPut(`/system/construct-application/category/${model.id}`, model));

/** Создать категорию заявок */
export const createConstructApplicationCategory = async (
  model: IConstructApplicationCategoryListItemModel,
) => baseApiFn<true>(
  httpPost('/system/construct-application/category', model),
);

/** Удалить категорию заявок */
export const deleteConstructApplicationCategory = async (id: number) => baseApiFn<true>(
  httpDelete(`/system/construct-application/category/${id}`),
);

/** ------ шаблоны заявок ------ */

/** Получить все шаблоны заявок */
export const getConstructApplicationTemplateAll = async () => baseApiFn<IConstructApplicationTemplateListItemModel[]>(
  async () => {
    const data: IConstructApplicationTemplateListItemModel[] = [];
    let response;
    let page = 1;

    // todo setTimeout or Promise in loop
    do {
      // eslint-disable-next-line no-await-in-loop
      response = await httpGet('/system/construct-application/template', {
        'per-page': maxValueToGetAllDataWithPagination,
        page,
      });

      page += 1;
      const models = response.data.map((model: unknown) => new ConstructApplicationTemplateListItemModel(model));

      data.push(...models);
    } while (response.page < response.amountOfPages);

    return data;
  },
);

/** Получить шаблон заявок */
export const getConstructApplicationTemplateById = async (
  id: number,
) => baseApiFn<IConstructApplicationTemplateListItemModel>(
  async () => {
    const data = await httpGet(`/system/construct-application/template/${id}`);
    return new ConstructApplicationTemplateListItemModel(data);
  },
);

/** Редактировать шаблон заявок */
export const updateConstructApplicationTemplate = async (
  model: ConstructApplicationTemplateFormType,
) => baseApiFn<true>(
  httpPut(`/system/construct-application/template/${model.id}`, model),
);

/** Создать шаблон заявок */
export const createConstructApplicationTemplate = async (
  model: ConstructApplicationTemplateFormType,
) => baseApiFn<true>(httpPost('/system/construct-application/template', model));

/** Удалить шаблон заявки */
export const deleteConstructApplicationTemplate = async (id: number) => baseApiFn<true>(
  httpDelete(`/system/construct-application/template/${id}`),
);

/** ------ заявки ------ */

/** Создать тип заявки */
export const createConstructApplicationType = async (
  model: ConstructApplicationTypeFormType,
) => baseApiFn<number>(async () => {
  const response = await httpPost('/system/construct-application/type', model);

  return response.id;
});

/** Редактировать тип заявки */
export const updateConstructApplicationType = async (
  model: ConstructApplicationTypeFormType,
) => baseApiFn<true>(httpPut(`/system/construct-application/type/${model.id}`, model));

/** Обновление дополнительных полей типа заявки */
export const updateConstructApplicationFields = async (
  id: number, model: ConstructApplicationTypeSaveFieldsType[],
) => baseApiFn<IConstructApplicationTypeModel>(async () => {
  const response = await httpPut(`/system/construct-application/type/${id}/fields`, model);
  return new ConstructApplicationTypeModel(response);
});

/** Обновление условий доступности типа заявки */
export const updateConstructApplicationAvailable = async (
  id: number, model: ConstructApplicationTypeModelAvailableType,
) => baseApiFn<true>(httpPut(`/system/construct-application/type/${id}/available`, model));

/** Получить список типов заявок */
export const getConstructApplicationTypeList = async (
  filter: DefaultFilterType,
) => baseApiFn<IConstructApplicationTypeListModel>(
  async () => {
    const response = await httpGet('/system/construct-application/type', filter);
    return new ConstructApplicationTypeListModel(response);
  },
);

/** Получить тип заявки */
export const getConstructApplicationTypeById = async (
  id: number,
) => baseApiFn<IConstructApplicationTypeModel>(
  async () => {
    const response = await httpGet(`/system/construct-application/type/${id}`);
    return new ConstructApplicationTypeModel(response);
  },
);

/** Удалить тип заявки */
export const deleteConstructApplicationType = async (id: number) => baseApiFn<true>(
  httpDelete(`/system/construct-application/type/${id}`),
);

/** Активация типа заявки */
export const enableConstructApplicationType = async (id: number) => baseApiFn<true>(
  httpPut(`/system/construct-application/type/${id}/enable`),
);

/** Деактивация типа заявки */
export const disableConstructApplicationType = async (id: number) => baseApiFn<true>(
  httpPut(`/system/construct-application/type/${id}/disable`),
);
