import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { IUserRightModel, UserRightModel } from '../User/UserRightsModel';


export type UpdateRbacRoleType = {
  description: string;
  permissions: string[];
}

export type CreateRbacRoleType = UpdateRbacRoleType & {
  name: string;
}

export type TrbacUserRights = {
  roles: string[];
  permissions?: string[];
}


export interface IRolesModel extends IWithPages {
  data: IUserRightModel[];
}

export class RolesModel extends WithPagesModel implements IRolesModel {
  data: IUserRightModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, UserRightModel);
  }
}
