import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UiSystemInput } from '../../../../components/common/UI/Input';
import { UiToolbar } from '../../../../components/common/Toolbar';
import { emptyOption, UiSelect } from '../../../../components/common/UI/Select';
import { convertModelToOptions, SimpleSelectOptionType } from '../../../../utils/convertModelToOptions';
import { JobDescriptionsFilterType } from '../../../../models/JobDescription/JobDescription';
import { IEnterpriseModel } from '../../../../models/Enterprises/EnterpriseModel';
import { ISystemUnitModel, UnitsFilterType } from '../../../../models/References/UnitsModel';
import { IPositionsItemModel, PositionsFilterType } from '../../../../models/References/Positions';


type Props = {
  onSubmit: (filter: JobDescriptionsFilterType) => void;
  onReset: () => void;
  enterprises: IEnterpriseModel[];
  units: ISystemUnitModel[];
  positions: IPositionsItemModel[];
  getPositions: (filter?: PositionsFilterType) => void;
  getUnits: (filter?: UnitsFilterType) => void;
  positionsIsLoading: boolean;
  unitsIsLoading: boolean;
}

type FormValues = {
  name: string;
  unit: SimpleSelectOptionType<number>;
  enterprise: SimpleSelectOptionType<number>;
  position: SimpleSelectOptionType<number>;
}

export const SystemJobDescriptionsFilter = ({
  onSubmit,
  onReset,
  enterprises,
  units,
  positions,
  getPositions,
  getUnits,
  positionsIsLoading,
  unitsIsLoading,
}: Props) => {
  const {
    register, handleSubmit, reset, watch, setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise: emptyOption(),
      unit: emptyOption(),
      position: emptyOption(),
    },
  });

  const { t } = useTranslation();

  const onLocalSubmit = useCallback(({
    name, position, unit, enterprise,
  }: FormValues) => {
    onSubmit({
      name,
      position_id: position.value > -1 ? position.value : undefined,
      unit_id: unit.value > -1 ? unit.value : undefined,
      enterprise_id: enterprise.value > -1 ? enterprise.value : undefined,
    });
  }, [onSubmit]);

  const enterpriseWatch = watch('enterprise');

  const unitWatch = watch('unit');
  const unitList = enterpriseWatch.value > -1 ? units : [];

  const positionList = enterpriseWatch.value > -1 && unitWatch.value > -1 ? positions : [];

  useEffect(() => {
    const [enterpriseId, unitId] = [enterpriseWatch.value, unitWatch.value];
    if (enterpriseId > -1 && unitId > -1) {
      getPositions({
        enterprise_id: enterpriseId.toString(),
        unit_id: unitId.toString(),
      });
    }
  }, [getPositions, enterpriseWatch, unitWatch]);

  useEffect(() => {
    const enterpriseId = enterpriseWatch.value;
    if (enterpriseId > -1) {
      getUnits({
        enterprise_id: enterpriseId,
      });
    }
  }, [getUnits, enterpriseWatch]);

  const onLocalReset = useCallback(() => {
    reset();
    onReset();
  }, [onReset, reset]);

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row>
        <Col xs="12" sm="6" md="4">
          <UiSystemInput
            label="Название инструкции"
            name="name"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="4">
          <UiSelect
            label={t('common.enterprise')}
            register={register}
            setValue={setValue}
            name="enterprise"
            options={convertModelToOptions(enterprises)}
            allowEmptyValue
            value={enterpriseWatch}
          />
        </Col>
        <Col xs="12" sm="6" md="4">
          <UiSelect
            label={t('common.units')}
            register={register}
            setValue={setValue}
            name="unit"
            options={convertModelToOptions(unitList)}
            allowEmptyValue
            value={unitWatch}
            isLoading={unitsIsLoading}
          />
        </Col>
        <Col xs="12" sm="6" md="4">
          <UiSelect
            label={t('jobDescriptions.position')}
            register={register}
            setValue={setValue}
            name="position"
            options={convertModelToOptions(positionList)}
            allowEmptyValue
            value={watch('position')}
            isLoading={positionsIsLoading}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
