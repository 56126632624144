import { IWithPages, WithPagesModel } from '../WithPagesModel';

export interface ISystemSportModel {
  id: number;
  name: string;
}

export class SystemSportModel implements ISystemSportModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}


export interface ISystemSportsModel extends IWithPages {
  data: ISystemSportModel[];
}

export class SystemSportsModel extends WithPagesModel implements ISystemSportsModel {
  data: ISystemSportModel[];

  constructor(params: any = {}) {
    super(params);
    this.data = this.initializeData(params.data, SystemSportModel);
  }
}
