import { AnyAction } from 'redux';
import cloneDeep from 'lodash/cloneDeep';

import { INotificationsModel, NotificationsModel } from '../../models/Notification/Notifications';
import {
  NOTIFICATIONS_GET_LIST_FAIL,
  NOTIFICATIONS_GET_LIST_OK,
  NOTIFICATIONS_GET_LIST_START,
  NOTIFICATIONS_GET_UNREAD_INIT_FAIL,
  NOTIFICATIONS_GET_UNREAD_INIT_OK,
  NOTIFICATIONS_GET_UNREAD_INIT_START, NOTIFICATIONS_ON_ALL_READ,
  NOTIFICATIONS_ON_NEW_NOTIFICATION, NOTIFICATIONS_ON_READ_NOTIFICATION_FAIL, NOTIFICATIONS_ON_READ_NOTIFICATION_OK,
} from './constants';
import { onFail, onLoad, onLoading } from '../../utils/reducer';
import { ISocketNotification } from '../../models/Notification/SocketNotification';


export type notificationsReducerType = {
  notifications: INotificationsModel;
  notificationsIsLoading: boolean;

  unreadInitNotifications: ISocketNotification[];
  unreadInitNotificationsIsLoading: boolean;

  /** последнее прочитанное (удаленное, закрытое) уведомление */
  lastReadNotification: ISocketNotification | null;
}

const initialState: notificationsReducerType = {
  notifications: new NotificationsModel(),
  notificationsIsLoading: true,

  unreadInitNotifications: [],
  unreadInitNotificationsIsLoading: true,

  lastReadNotification: null,
};

export const storeName = 'notifications';

export const notificationsReducer = {
  [storeName]: (state: notificationsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case NOTIFICATIONS_GET_LIST_START:
        return onLoading(state, 'notifications');
      case NOTIFICATIONS_GET_LIST_OK: {
        if (payload.append) {
          /** т.к. бесконечная прокрута, то новости добавлять в текущий массив */
          const stateNotificationsData = cloneDeep(state.notifications.data);

          const stateNotifications = {
            ...payload.notifications,
            data: stateNotificationsData.concat(payload.notifications.data),
          };

          return {
            ...state,
            notifications: stateNotifications,
            notificationsIsLoading: false,
          };
        }
        return onLoad(state, payload, 'notifications');
      }
      case NOTIFICATIONS_GET_LIST_FAIL:
        return onFail(state, 'notifications');

      case NOTIFICATIONS_GET_UNREAD_INIT_START:
        return onLoading(state, 'unreadInitNotifications');
      case NOTIFICATIONS_GET_UNREAD_INIT_OK:
        return onLoad(state, payload, 'unreadInitNotifications');
      case NOTIFICATIONS_GET_UNREAD_INIT_FAIL:
        return onFail(state, 'unreadInitNotifications');

      case NOTIFICATIONS_ON_NEW_NOTIFICATION: {
        const stateNotificationsData = cloneDeep(state.unreadInitNotifications);
        return {
          ...state,
          unreadInitNotifications: [payload.notification].concat(stateNotificationsData),
        };
      }
      case NOTIFICATIONS_ON_READ_NOTIFICATION_OK:
      case NOTIFICATIONS_ON_READ_NOTIFICATION_FAIL: {
        const stateNotificationsData = state.unreadInitNotifications
          .filter((n) => n.id !== payload.notification.id);
        return {
          ...state,
          unreadInitNotifications: stateNotificationsData,
          lastReadNotification: payload.notification,
        };
      }

      case NOTIFICATIONS_ON_ALL_READ:
        return {
          ...state,
          notifications: {
            ...state.notifications,
            data: state.notifications.data.map((n) => {
              // eslint-disable-next-line no-param-reassign
              n.is_read = true;
              return n;
            }),
          },
        };

      default:
        return state;
    }
  },
};
