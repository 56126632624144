import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import { IPageAgreementModel } from 'models/References/PageAgreement';
import { UiBox } from 'components/common/UI/UiBox';


type Props = {
  model: IPageAgreementModel | null;
  loading: boolean;
}

export const UserAgreementComponent = ({ model, loading }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-header user-agreement-header">
        <h3 className="m-0">
          {get(model, 'name', 'Пользовательское соглашение')}
        </h3>
      </div>
      <div className="page-header user-agreement-header">
        <Link to="/register" className="back" />
        {t('common.back')}
      </div>
      <UiBox className="user-agreement">
        <section className="form">
          <div className="articles">
            <div className="articles-list_item open">
              <div className="articles-list_item__wrapper">
                {loading ? <Loader /> : (
                  <>
                    {model ? (
                      <>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div className="articles-list_item__desc" dangerouslySetInnerHTML={{ __html: model.text }} />
                      </>
                    ) : <EntityNotFound message={t('references.page.agreement.404')} />}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </UiBox>
    </>
  );
};
