import { CDate } from 'utils/CDate';
import { WithPagesModel } from '../WithPagesModel';
import { ISystemEducationListItemModel, ISystemEducationListModel } from './interfaces';
import { FileType, prepareFiles } from '../common';


export class SystemEducationListItemModel implements ISystemEducationListItemModel {
  contacts: string;
  date_start: Date;
  id: number;
  image: FileType;
  info: string;
  name: string;
  place: string;

  constructor(params: any = {}) {
    this.contacts = params.contacts;
    this.date_start = CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm');
    this.id = params.id;
    this.image = prepareFiles(params.image);
    this.info = params.info;
    this.name = params.name;
    this.place = params.place;
  }
}

export class SystemEducationListModel extends WithPagesModel implements ISystemEducationListModel {
  data: ISystemEducationListItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemEducationListItemModel);
  }
}
