import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { hasUnemptyValues } from 'utils/objects';
import { UiToolbar } from 'components/common/Toolbar';
import { ISystemBonusProgramEventFilter } from 'models/BonusProgram/Event/interfaces';
import { UiSystemInput } from 'components/common/UI/Input';

type Props = {
  onSubmit: (data: ISystemBonusProgramEventFilter) => void;
  onReset: () => void;
}

type FormValues = {
  eventName: string;
}

export const SystemBonusProgramEventListFilter = ({ onSubmit, onReset }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      eventName: '',
    },
  });

  const onLocalSubmit = (data: FormValues) => {
    const preparedFilterData = {
      title: data.eventName.length ? data.eventName : undefined,
    };

    if (hasUnemptyValues(preparedFilterData)) {
      onSubmit(preparedFilterData);
    }
  };

  const onLocalReset = () => {
    reset();
    onReset();
  };

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('system.bonusProgram.event.title.label')}
            name="eventName"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
