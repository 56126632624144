import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'index.scss';

import 'assets/styles/style.css';

import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { appLocales } from 'utils/appLocales';
import { RouteChecker } from 'RouteChecker';
import { GlobalLoader } from 'components/common/UI/Loaders';
import { customHistory } from 'customHistory';


export const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    registerLocale(i18n.language, i18n.language === 'ru' ? appLocales.ru : appLocales.en);
  }, [i18n.language]);

  return (
    <ErrorBoundary>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        transition={Zoom}
        draggable={false}
      />
      <GlobalLoader />
      <Router history={customHistory}>
        <RouteChecker />
      </Router>
    </ErrorBoundary>
  );
};
