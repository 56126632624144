import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { LoginComponent } from '../../components/Auth/Login';
import { authSignInStartAction } from './actions';
import { AuthSignInType } from '../../models/Auth/interfaces';
import { getSignInIsLoadingSelector } from './selectors';
import { getIsCurrentUserExistsSelector } from '../../store/currentUser/selectors';


export const Login = () => {
  const isSubmitting = useSelector(getSignInIsLoadingSelector);
  const dispatch = useDispatch();
  const { search } = useLocation();

  const signin = useCallback((componentData: AuthSignInType) => {
    const findToken = /Ntf_token=([^&]+)/.exec(search);

    const data = {
      ...componentData,
      ntf_token: findToken ? findToken[1] : undefined,
    };

    dispatch(authSignInStartAction(data));
  }, [dispatch, search]);

  const currentUserExists: boolean = useSelector(getIsCurrentUserExistsSelector);

  if (currentUserExists) {
    return <Redirect to="/" />;
  }
  return <LoginComponent signin={signin} isSubmitting={isSubmitting} />;
};
