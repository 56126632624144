import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { systemGetEventsStartAction } from './actions';
import { SystemEventsComponent } from '../../components/Events/SystemEvents';
import { ISystemListEventFilterType, ISystemListEvents } from '../../../models/Events/SystemListEvents';
import { systemEventsIsLoadingSelector, systemEventsSelector } from './selectors';


export const SystemEvents = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: ISystemListEventFilterType) => {
    dispatch(systemGetEventsStartAction(filter));
  }, [dispatch]);

  const data: ISystemListEvents = useSelector(systemEventsSelector);
  const isLoading: boolean = useSelector(systemEventsIsLoadingSelector);

  return <SystemEventsComponent data={data} isLoading={isLoading} getData={getData} />;
};
