import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { ParamTypes } from 'models/common';
import { customHistory } from 'customHistory';
import { createSystemEducation, systemGetEducationByid, updateSystemEducation } from 'systemModule/api/education';
import { SystemEducationFormComponent } from '../../components/Education/SystemEducationForm';
import { usePromise } from '../../../utils/hooks/usePromise';
import { ISystemEducationModel, SystemEducationFormType } from '../../../models/Education/interfaces';
import { Loader } from '../../../components/common/UI/Loaders';
import { SystemEducationModel } from '../../../models/Education/SystemEducationModel';
import { handleErrors } from '../../../utils/errors';
import { ICurrentUserModel, UserAccessEnum } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';


export const SystemEducationForm = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [model, modelLoading] = usePromise<ISystemEducationModel>(
    () => systemGetEducationByid(numberId),
    !isNaN(numberId),
    [numberId],
  );

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wEducation);

  const onSave = useCallback(async (dataToSave: SystemEducationFormType) => {
    const trueOrError = numberId ?
      await updateSystemEducation(numberId, dataToSave) :
      await createSystemEducation(dataToSave);

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t(numberId ? 'education.updated' : 'education.created'));
        customHistory.push('/system/education');
      },
    );
  }, [numberId, t]);

  if (modelLoading) {
    return <Loader />;
  }
  return (
    <SystemEducationFormComponent
      model={model || new SystemEducationModel()}
      onSave={onSave}
      canUpdate={canUpdate}
    />
  );
};
