import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SystemRolesComponent } from '../../components/Roles/SystemRoles';
import { systemGlobalGetRolesStartAction } from '../../store/actions';
import { systemRbacRolesIsLoadingSelector, systemRbacRolesSelector } from '../../store/selectors';
import { IRolesModel } from '../../../models/Roles/RolesModel';
import { DefaultFilterType } from '../../../models/common';


export const SystemRoles = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemGlobalGetRolesStartAction(filter));
  }, [dispatch]);

  const data: IRolesModel = useSelector(systemRbacRolesSelector);
  const isLoading: boolean = useSelector(systemRbacRolesIsLoadingSelector);

  return (
    <SystemRolesComponent getData={getData} data={data} isLoading={isLoading} />
  );
};
