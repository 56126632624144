import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  systemBonusProgramTagListLoadingSelector,
  systemBonusProgramTagListSelector,
} from 'systemModule/containers/BonusProgram/selectors';
import { handleErrors } from 'utils/errors';
import { deleteSystemBonusProgramTagItem } from 'systemModule/api/bonusProgram';
import { UserAccessEnum } from 'models/User/CurrentUserModel';
import {
  ISystemBonusProgramTagFilter,
  ISystemBonusProgramTagItemModel,
  ISystemBonusProgramTagListModel,
} from 'models/BonusProgram/Tag/interfaces';
import { getParamsFromPagesModel } from 'models/WithPagesModel';
import { systemRefGetTagListStartAction } from 'systemModule/containers/BonusProgram/actions';
import {
  SystemBonusProgramTagListComponent,
} from 'systemModule/components/References/BonusProgram/SystemBonusProgramTagList';

export const SystemBonusProgramTagList = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getData = useCallback((filter: ISystemBonusProgramTagFilter) => {
    dispatch(systemRefGetTagListStartAction(filter));
  }, [dispatch]);

  const data: ISystemBonusProgramTagListModel = useSelector(systemBonusProgramTagListSelector);
  const dataIsLoading: boolean = useSelector(systemBonusProgramTagListLoadingSelector);
  const canDeleteTag = useSelector(getCurrentUserSelector).hasPermission(UserAccessEnum.dBonusProgramTag);

  const deleteTag = useCallback(({ id, title }: ISystemBonusProgramTagItemModel) => async () => {
    const confirmOptions = { entity: 'тег', entityName: title };

    // eslint-disable-next-line no-restricted-globals
    if (canDeleteTag && confirm(t('common.form.delete.question', confirmOptions))) {
      handleErrors(
        await deleteSystemBonusProgramTagItem(id),
        'delete',
        () => {
          toast.success(t('system.bonusProgram.deleted.tag'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  }, [canDeleteTag, t, getData, data]);

  return (
    <SystemBonusProgramTagListComponent
      data={data}
      getData={getData}
      dataIsLoading={dataIsLoading}
      canDeleteTag={canDeleteTag}
      deleteTag={deleteTag}
    />
  );
};
