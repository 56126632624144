import React, { memo } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ValidationOptions } from 'react-hook-form';

type Props = {
  register: (s: ValidationOptions) => any;
  name: string;

  className?: string;
  defaultValue?: any;
  wrapFormGroup?: boolean;
  label?: any;
  id?: string;
  required?: boolean; // просто ради звездочки
  value?: string; // если отличается от boolean - указывать явно. по-умолчанию true/false
  isDisabled?: boolean;
}

export const UiCheckboxComponent = ({
  label,
  id,
  name,
  register,
  wrapFormGroup,
  value,
  required = false,
  defaultValue,
  className,
  isDisabled,
}: Props) => {
  const currentId = `${id}_${name}`;

  const formgroupClassName = ['form-check'];
  if (className) {
    formgroupClassName.push(className);
  }

  const localValidation: ValidationOptions = {};
  if (required) {
    localValidation.required = 'Обязательное поле';
  }

  const simpleCheckBox = (
    <div className={formgroupClassName.filter((cName) => cName)
      .join(' ')}
    >
      <Input
        type="checkbox"
        innerRef={register(localValidation)}
        name={name}
        id={currentId}
        value={value}
        defaultValue={defaultValue}
        disabled={isDisabled}
      />
      {label && (
        <Label check className="form-check-label" htmlFor={currentId}>
          {label}{required && <span className="required" />}
        </Label>
      )}
    </div>
  );

  return wrapFormGroup ? <FormGroup>{simpleCheckBox}</FormGroup> : simpleCheckBox;
};

/** для публичной части */
export const UiCheckbox = memo((props: Props) => <UiCheckboxComponent {...props} />);

/** для админской части */
export const UiSystemCheckbox = memo((props: Props) => <UiCheckboxComponent {...props} wrapFormGroup />);
