import { ISystemUserModel } from 'models/User/interfaces';
import { IUserListUnit } from 'models/Users/interfaces';


export class SystemUserModel implements ISystemUserModel {
  fio: string;
  id: number;
  phone: string;
  show_phone: boolean;
  isActive: boolean;
  isRegister: boolean;
  roles: { description: string; name: string }[];
  units: IUserListUnit;
  assigned_for_all_enterprises: boolean;
  sdsCoin: number;

  constructor(params: any = {}) {
    this.fio = params.fio;
    this.id = params.id;
    this.phone = params.phone;
    this.show_phone = params.show_phone;
    this.isActive = params.is_active;
    this.isRegister = params.is_register;
    this.roles = params.roles;
    this.units = params.units;
    this.assigned_for_all_enterprises = params.assigned_for_all_enterprises;
    this.sdsCoin = params.sdscoin;
  }
}
