import { AnyAction } from 'redux';
import { INewsModel, NewsModel } from '../../../models/News/NewsModel';
import { SYSTEM_GET_NEWS_FAIL, SYSTEM_GET_NEWS_OK, SYSTEM_GET_NEWS_START } from './constants';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';


export type systemNewsReducerType = {
  news: INewsModel;
  newsIsLoading: boolean;
}

const initialState: systemNewsReducerType = {
  news: new NewsModel(),
  newsIsLoading: true,
};

export const storeName = 'news';

export const systemNewsReducer = {
  [storeName]: (state: systemNewsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_NEWS_START:
        return onLoading(state, 'news');
      case SYSTEM_GET_NEWS_OK:
        return onLoad(state, payload, 'news');
      case SYSTEM_GET_NEWS_FAIL:
        return onFail(state, 'news');

      default:
        return state;
    }
  },
};
