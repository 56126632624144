/**
 * уведомление из админки
 */
export interface ISystemNotification {
  subject: string;
  text: string;
  internal_link?: string;
  is_blocked?: boolean;
  date_blocked?: string;
  /**
   * Если список пустой или не указан, то отправяются сообщения всем пользователям
   *
   * Если указан, то в каждом элементе проверяется подмассив units.
   * Если он не указан или пустой, то отправлются всем пользователям выбранного предприятия.
   * Если не пустой, то отправляются пользователям, которые состоям в этих подразделениях.
   */
  enterprises?: {
    id: number;
    units?: number[];
  }[];
}

export class SystemNotificationModel implements ISystemNotification {
  subject: string;
  text: string;
  date_blocked?: string;
  enterprises?: { id: number; units?: number[] }[];
  internal_link?: string;
  is_blocked?: boolean;

  constructor(params: ISystemNotification) {
    this.subject = params.subject;
    this.text = params.text;
    this.date_blocked = params.date_blocked ?? undefined;
    this.enterprises = params.enterprises && params.enterprises.length ? params.enterprises : undefined;
    this.internal_link = params.internal_link || undefined;
    this.is_blocked = params.is_blocked ?? undefined;
  }
}
