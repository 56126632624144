/** внешние функции для общения с другими приложениями (мобильная обертка) */
import { notificationsGetInitStartAction } from 'containers/Notifications/actions';
import { store } from 'index';

declare global {
  interface Window {
    getNotificationsByIds: (ids?: number[] | number | string | null) => void
    invokeCSharpAction?: (url: string) => void;
  }
}

/** открытие внешней ссылки для мобильного приложения или открытие в браузере */
export const onInnerLinkOpen = (url: string): void => {
  try {
    if (window.invokeCSharpAction) {
      window.invokeCSharpAction(url);
    } else {
      window.open(url);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error opening file', e);
  }
};

/** подписка на вызов уведомлений по ид */
export const subscribeOnGetNotificationsByIds = (): void => {
  /**
   * внешняя функция для запроса уведомлений, которую будет дергать внешний код мобильной обертки
   *
   * @param ids {array | number} - список id для запроса
   */
  window.getNotificationsByIds = (ids) => {
    if (ids === undefined || ids === null) {
      return;
    }
    const paramIds = Array.isArray(ids) ? ids.map((id) => +id) : [+ids];
    store.dispatch(notificationsGetInitStartAction(paramIds));
  };
};
