import React from 'react';
import { Col } from 'reactstrap';
import { SystemPollStatisticsAnswers } from './SystemPollStatisticsAnswers';
import { SystemPollPublicAnswers } from './SystemPollPublicAnswers';
import { ISystemPollModelResult } from '../../../../models/Polls/SystemPollModel';


type Props = {
  results: ISystemPollModelResult;
  is_anonymous: boolean;
  answerTitle: string;
  questionIndex: number;
}

export const SystemPollAnswers = ({
  results, is_anonymous, answerTitle, questionIndex,
}: Props) => {
  /** надо искать по совпадению названия вопроса, а то разные ситуации могут быть и индекс ответа не истина */
  const foundStatistic = results.statistics.questions.find((q) => q.title === answerTitle);

  return (
    <>
      {/** всегда для публичных опросов и для анонимных, у которых нет статистики и есть ответы(текст, даты) */}
      {(!is_anonymous || (is_anonymous && !foundStatistic && results.users.length > 0)) && (
        <Col xs={12} md={4} lg={4}>
          <SystemPollPublicAnswers
            answersInfo={results.users.map((u) => ({
              username: u.username,
              answers: u.answers[questionIndex],
            }))}
            is_anonymous={is_anonymous}
          />
        </Col>
      )}

      {foundStatistic && (
        <Col xs={12} md={3} lg={3}>
          <SystemPollStatisticsAnswers statisticsInfo={foundStatistic.answers} />
        </Col>
      )}
    </>
  );
};
