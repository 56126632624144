import { DefaultFilterType } from '../common';
import { WithPagesModel } from '../WithPagesModel';

export type SportFilterType = DefaultFilterType & {
  sort?: string;
  name?: string;
}

export interface ISportItemModel {
  id: number;
  name: string;
}

export class SportItemModel implements ISportItemModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}

export interface ISportModel {
  data: ISportItemModel[];
}

export class SportModel extends WithPagesModel implements ISportModel {
  data: SportItemModel[];

  constructor(params: any = {}) {
    super(params);
    this.data = this.initializeData(params.data, SportItemModel);
  }
}
