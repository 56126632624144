import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../../utils/reducer';
import { ISystemUnitsModel, SystemUnitsModel } from '../../../../models/References/UnitsModel';
import {
  SYSTEM_REFERENCES_GET_UNITS_FAIL,
  SYSTEM_REFERENCES_GET_UNITS_OK,
  SYSTEM_REFERENCES_GET_UNITS_START,
} from './constants';

export type systemUnitsReducerType = {
  units: ISystemUnitsModel;
  unitsIsLoading: boolean;
}

const initialState: systemUnitsReducerType = {
  units: new SystemUnitsModel(),
  unitsIsLoading: true,
};

export const storeName = 'units';

export const systemUnitsReducer = {
  [storeName]: (state: systemUnitsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_REFERENCES_GET_UNITS_START:
        return onLoading(state, 'units');
      case SYSTEM_REFERENCES_GET_UNITS_OK:
        return onLoad(state, payload, 'units');
      case SYSTEM_REFERENCES_GET_UNITS_FAIL:
        return onFail(state, 'units');

      default:
        return state;
    }
  },
};
