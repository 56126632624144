import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { systemGlobalUnitsSelector } from '../../store/selectors';
import { SystemNotificationComponent } from '../../components/Notification/SystemNotification';
import { ISystemNotification } from '../../../models/Notification/SystemNotification';
import { handleErrors } from '../../../utils/errors';
import { createNotification } from '../../api/notifications';
import { ISystemUnitModel } from '../../../models/References/UnitsModel';
import { getSystemUserEnterprises } from '../../api/userEnterprises';
import { ISystemEnterpriseModel } from '../../../models/References/EnterprisesModel';
import { Loader } from '../../../components/common/UI/Loaders';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';


export const SystemNotification = () => {
  const { t } = useTranslation();

  const units: ISystemUnitModel[] = useSelector(systemGlobalUnitsSelector);

  const [loading, setLoading] = useState(false);
  const [userEnterprises, setUserEnterprises] = useState<ISystemEnterpriseModel[]>();

  const currentUser = useSelector(getCurrentUserSelector);

  useEffect(() => {
    setLoading(true);
    getSystemUserEnterprises(currentUser.id).then((userEnterprises) => {
      if (!(userEnterprises instanceof Error)) {
        setUserEnterprises(userEnterprises.data);
      }
      setLoading(false);
    });
  }, [currentUser]);

  const onSubmit = async (
    model: ISystemNotification,
    reset: (values: Record<string, any>) => void,
    values: Record<string, any>,
  ) => {
    setLoading(true);

    handleErrors(
      await createNotification(model),
      (e) => {
        toast.error(e.message);
        setLoading(false);
      },
      () => {
        toast.success(t('system.notification.created'));
        reset(values);
        setLoading(false);
      },
    );
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <SystemNotificationComponent
      enterprises={userEnterprises}
      units={units}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};
