import { ISystemPrivilegeItem } from './interfaces';
import { PrivilegeItem } from './PrivilegeItem';
import { CDate } from '../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../config/system';


export class SystemPrivilegeItem extends PrivilegeItem implements ISystemPrivilegeItem {
  created_at: Date;
  enterprises: { contact: string; documents: string; id: number; name: string; requirement: string }[];

  constructor(params: any = {}) {
    super(params);

    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.enterprises = params.enterprises || [];
  }
}
