export interface IPageAgreementModel {
  name: string;
  text: string;
}


export class PageAgreementModel implements IPageAgreementModel {
  name: string;
  text: string;

  constructor(params: any = {}) {
    this.name = params.name;
    this.text = params.text;
  }
}
