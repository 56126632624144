import React, { useCallback, useState } from 'react';
import { appAvailableResponsible } from '../../../api/application';
// eslint-disable-next-line max-len
import { SystemApplicationResponsibleListComponent } from '../../components/Application/SystemApplicationResponsibleList';
// eslint-disable-next-line max-len
import { ApplicationResponsibleListModel } from '../../../models/Application/Responsible/ApplicationResponsibleListModel';
import { IApplicationResponsibleFilterType } from '../../../models/Application/Responsible/interfaces';


type Props = {
  onSelect: (id: number) => void;
  assignId?: number;
  applicationId?: number;
}

/** Список доступных ответственных за выполнение заявок */
export const SystemApplicationResponsibleList = ({ onSelect, assignId, applicationId }: Props) => {
  const [data, setData] = useState(new ApplicationResponsibleListModel());
  const [isLoading, setisLoading] = useState(true);

  const getData = useCallback((filter: IApplicationResponsibleFilterType) => {
    setisLoading(true);
    appAvailableResponsible(filter, applicationId)
      .then((modelOrError) => {
        if (!(modelOrError instanceof Error)) {
          setData(modelOrError);
        }
        setisLoading(false);
      });
  }, [applicationId]);

  return (
    <SystemApplicationResponsibleListComponent
      data={data}
      isLoading={isLoading}
      getData={getData}
      onSelect={onSelect}
      assignId={assignId}
    />
  );
};
