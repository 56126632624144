import { dataAction, dataSuccessAction, dataErrorAction } from '../../store/actions';
import {
  GET_BUS_STOP_TIMETABLE_LIST_FAIL,
  GET_BUS_STOP_TIMETABLE_LIST_OK,
  GET_BUS_STOP_TIMETABLE_LIST_START,
} from './constants';
import {
  BusStopTimetableItemModel,
  BusStopTimetableParamsType,
} from '../../models/BusStopTimetable/BusStopTimetable';

export const getBusStopTimetableListStartAction = (
  id: number, params?: BusStopTimetableParamsType,
) => dataAction(
  GET_BUS_STOP_TIMETABLE_LIST_START,
)({ id, params });
export const getBusStopTimetableListOkAction = (
  busStopTimetable: BusStopTimetableItemModel,
) => dataSuccessAction(
  GET_BUS_STOP_TIMETABLE_LIST_OK,
)({ busStopTimetable });
export const getBusStopTimetableListFailAction = dataErrorAction(GET_BUS_STOP_TIMETABLE_LIST_FAIL);
