import { IInsuranceModel } from '../Insurances/InsurancesModel';
import { ICurrentUserSport } from './interfaces';

export interface ICurrentUserProfileModel {
  avatar: {
    name: string;
    url: string;
  };
  oms_series: string;
  oms_number: string;
  emergency_number: {
    phone: string;
    title: string;
  };
  is_housing_from_sds: boolean;
  housing_area: number;
  hobby: string[];
  fio: string;
  userSports: ICurrentUserSport[];
  insurances: IInsuranceModel[];
  sdsCoin: number;
}

export class CurrentUserProfileModel implements ICurrentUserProfileModel {
  avatar: {
    name: string;
    url: string;
  };
  oms_series: string;
  oms_number: string;
  emergency_number: {
    phone: string;
    title: string;
  };
  is_housing_from_sds: boolean;
  housing_area: number;
  hobby: string[];
  fio: string;
  userSports: ICurrentUserSport[];
  insurances: IInsuranceModel[];
  sdsCoin: number;

  constructor(params: any = {}) {
    this.avatar = params.avatar;
    this.oms_series = params.oms_series;
    this.oms_number = params.oms_number;
    /** если null (нет данных), надо обеспечить пустые значения */
    this.emergency_number = params.emergency_number || {
      phone: '',
      title: '',
    };
    this.is_housing_from_sds = typeof params.is_housing_from_sds === 'boolean' ? params.is_housing_from_sds : false;
    this.housing_area = params.housing_area || 0;
    this.hobby = params.hobby || [''];
    this.fio = params.fio;
    this.userSports = params.userSports || [];
    this.insurances = params.insurances || [];
    this.sdsCoin = params.sdscoin;
  }
}
