import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoIcon } from '../../assets/img/logo.svg';

type Props = {
  showSlogan?: boolean; // на внутренних страницах не отображается
  className?: string;
}

/** класс enter здесь, а не в body */
export const Header = memo(({ showSlogan = false, className = '' }: Props) => {
  const headerClassName = className || 'd-none d-block-custom';
  return (
    <header className={showSlogan ? 'enter' : headerClassName}>
      <div className="container">
        <div className="text-center">
          <Link to="/" className="logo">
            <LogoIcon />
          </Link>
          {showSlogan && <div className="slogan">Строим. Добываем. Создаём.</div>}
        </div>
      </div>
    </header>
  );
});
