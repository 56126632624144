import {
  all, put, call, takeLatest, select,
} from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { httpGet } from 'utils/http';
import { NewsCategoryItemModel } from 'models/News/NewsCategoryModel';
import { maxValueToGetAllDataWithPagination } from 'config/system';
import { EnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { RolesModel } from 'models/Roles/RolesModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { PollsQuestionTypesItemModel } from 'models/Polls/PollsQuestionTypesModel';
import { PollsStatusModel } from 'models/Polls/PollsStatusesModel';
import { SystemUnitModel } from 'models/References/UnitsModel';
import { DepartmentItemModel } from 'models/References/DepartmentModel';
import { baseSaga } from 'store/baseSaga';
import { globalLoaderHideAction, globalLoaderShowAction } from 'store/actions';
import { SYSTEM_GET_ROLES_START } from './constants';
import {
  systemGetEnterprisesFailAction,
  systemGetEnterprisesOkAction,
  systemGetNewsCategoryFailAction,
  systemGetNewsCategoryOkAction,
  systemGetPermissionsFailAction,
  systemGetPermissionsOkAction,
  systemGetPollsQuestionTypesFailAction,
  systemGetPollsQuestionTypesOkAction,
  systemGetPollsStatusesFailAction,
  systemGetPollsStatusesOkAction,
  systemGlobalGetUnitsFailAction,
  systemGlobalGetUnitsOkAction,
  systemGlobalDataLoadedAction,
  systemGlobalGetRolesFailAction,
  systemGlobalGetRolesOkAction,
  systemGlobalGetDepartmentsOkAction,
  systemGlobalGetDepartmentsFailAction,
  systemGetTagsOkAction, systemGetTagsFailAction,
} from './actions';
import { SystemBonusProgramTagItemModel } from '../../models/BonusProgram/Tag/SystemBonusProgramTagModel';


/** рубрики новостей */
function* getNewsCategory() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/news/category', {
      'per-page': maxValueToGetAllDataWithPagination,
      sort: 'id',
    });

    const model = response.data.map((item: unknown) => new NewsCategoryItemModel(item));

    yield put(systemGetNewsCategoryOkAction(model));
  }, systemGetNewsCategoryFailAction);
}

/** список прав пользователя */
function* getRbacPermissions() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/rbac/permission', {
      'per-page': maxValueToGetAllDataWithPagination,
    });

    yield put(systemGetPermissionsOkAction(response.data));
  }, systemGetPermissionsFailAction);
}

/** все предприятия */
function* getEnterprises() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/references/enterprise', {
      'per-page': maxValueToGetAllDataWithPagination,
      sort: 'id',
    });

    const model = response.data.map((item: unknown) => new EnterpriseModel(item));

    yield put(systemGetEnterprisesOkAction(model));
  }, systemGetEnterprisesFailAction);
}

/** все теги */
function* getTags() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/bonus-program/tag', {
      'per-page': maxValueToGetAllDataWithPagination,
      sort: 'id',
    });

    const model = response.data.map((item: unknown) => new SystemBonusProgramTagItemModel(item));
    yield put(systemGetTagsOkAction(model));
  }, systemGetTagsFailAction);
}

/** все подразделения */
function* getUnits() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/references/unit', {
      'per-page': maxValueToGetAllDataWithPagination,
    });

    const model = response.data.map((item: unknown) => new SystemUnitModel(item));

    yield put(systemGlobalGetUnitsOkAction(model));
  }, systemGlobalGetUnitsFailAction);
}

/** все департаменты */
function* getDepartments() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/references/department', {
      'per-page': maxValueToGetAllDataWithPagination,
    });

    const model = response.data.map((item: unknown) => new DepartmentItemModel(item));

    yield put(systemGlobalGetDepartmentsOkAction(model));
  }, systemGlobalGetDepartmentsFailAction);
}

/** список типов вопросов */
function* getPollQuestionTypesSaga() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/poll/question-types-list');

    const model = response.map((item: unknown) => new PollsQuestionTypesItemModel(item));

    yield put(systemGetPollsQuestionTypesOkAction(model));
  }, systemGetPollsQuestionTypesFailAction);
}

/** список статусов опроса */
function* getPollStatusesSaga() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/poll/poll-statuses-list');

    const model = response.map((item: unknown) => new PollsStatusModel(item));

    yield put(systemGetPollsStatusesOkAction(model));
  }, systemGetPollsStatusesFailAction);
}


/** генератор для получения общей инфы для всей админки */
export function* systemStart() {
  yield baseSaga(function* () {
    const sagasToLoad = [];

    yield put(globalLoaderShowAction());

    const user: ICurrentUserModel = yield select(getCurrentUserSelector);

    /** ниже идут условно загружаемые данные, в зависимости от прав пользователя */

    /** нет права, загрузить когда авторизован */
    if (user.id) {
      sagasToLoad.push(getEnterprises());
      sagasToLoad.push(getUnits());
      sagasToLoad.push(getDepartments());
      sagasToLoad.push(getTags());
    }

    if (user.hasPermission(UserAccessEnum.crudRbac)) {
      sagasToLoad.push(getRbacPermissions());
    }

    if (user.hasPermission(UserAccessEnum.rReferenceNewsCategory)) {
      sagasToLoad.push(getNewsCategory());
    }

    if (user.hasPermission(UserAccessEnum.crudPolls) || user.hasPermission(UserAccessEnum.adminCrudPolls)) {
      sagasToLoad.push(getPollQuestionTypesSaga());
      sagasToLoad.push(getPollStatusesSaga());
    }

    yield all(sagasToLoad);

    yield put(systemGlobalDataLoadedAction());

    yield put(globalLoaderHideAction());
  }, globalLoaderHideAction);
}


/** роли пользователей */
function* systemGetRolesSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/rbac/role', filter);

    yield put(systemGlobalGetRolesOkAction(new RolesModel(response)));
  }, systemGlobalGetRolesFailAction);
}


export function* systemGlobalSaga() {
  yield takeLatest(SYSTEM_GET_ROLES_START, systemGetRolesSaga);

  yield call(systemStart);
}
