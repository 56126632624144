import { httpGet } from 'utils/http';
import {
  ISystemUnitModel,
  ISystemUnitsModel,
  SystemUnitModel,
  SystemUnitsModel,
  UnitsFilterType,
} from 'models/References/UnitsModel';
import { baseApiFn } from 'utils/baseApiFn';

export const getSystemUnit = async (id: number) => baseApiFn<ISystemUnitModel>(async () => {
  const response = await httpGet(`system/references/unit/${id}`);
  return new SystemUnitModel(response);
});

export const getSystemUnits = async (filter?: UnitsFilterType) => baseApiFn<ISystemUnitsModel>(
  async () => {
    const response = await httpGet('system/references/unit', filter);
    return new SystemUnitsModel(response);
  },
);
