import { EducationListModel } from 'models/Education/EducationListModel';
import { EducationModel } from 'models/Education/EducationModel';
import { EducationListFtilerType, IEducationListModel, IEducationModel } from 'models/Education/interfaces';
import { baseApiFn } from 'utils/baseApiFn';
import { httpGet } from 'utils/http';


/** получить список обучений */
export const getEducationList = async (filter?: EducationListFtilerType) => baseApiFn<IEducationListModel>(async () => {
  const response = await httpGet('/public/education', filter);
  return new EducationListModel(response);
});

/** получить обучение */
export const getEducationById = async (id: number) => baseApiFn<IEducationModel>(async () => {
  const response = await httpGet(`/public/education/${id}`);
  return new EducationModel(response);
});
