import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';

import { UiSelect } from 'components/common/UI/Select';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';


type Props = {
  onSubmit: (d: number) => void;
  defaultValue?: number;
  assignedUsersOptions: SimpleSelectOptionType<number>[];
}

type FormValues = {
  value: SimpleSelectOptionType<number> | null;
}

export const PartAssignUserForm = ({
  onSubmit, defaultValue, assignedUsersOptions,
}: Props) => {
  const {
    handleSubmit, formState, register, watch, setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      value: (() => {
        if (defaultValue) {
          const found = assignedUsersOptions.find((u) => u.value === defaultValue);
          if (found) {
            return found;
          }
        }
        return null;
      })(),
    },
  });

  const onSubmitLocal = useCallback((data: FormValues) => {
    if (data.value) {
      onSubmit(data.value.value);
    }
  }, [onSubmit]);

  return (
    <form className="form-with-btn" onSubmit={handleSubmit(onSubmitLocal)}>
      <div className="form-wrapper_content">
        <div className="form-group">
          <UiSelect
            name="value"
            value={watch('value')}
            options={assignedUsersOptions}
            register={register}
            setValue={setValue}
            allowEmptyValue
            isClearable
          />
        </div>
      </div>
      <div className="form-wrapper_btn">
        <div className="buttons-group buttons-group-responsive">
          <Button
            color="primary"
            type="submit"
            disabled={!formState.isValid}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </form>
  );
};
