import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SystemBusStopsComponent } from '../../../components/References/BusStops/SystemBusStops';
import { systemRefGetBusStopsStartAction } from './actions';
import { IBusStopsModel } from '../../../../models/BusStops/BusStops';
import { systemBusStopsIsLoadingSelector, systemBusStopsSelector } from './selectors';
import { UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import { deleteSystemRefBusStop } from '../../../api/references/busStops';
import { BusStopsFilterType, ISystemBusStopModel } from '../../../../models/References/BusStopsModel';
import { handleErrors } from '../../../../utils/errors';

export const SystemBusStops = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getData = useCallback((filter: BusStopsFilterType) => {
    dispatch(systemRefGetBusStopsStartAction(filter));
  }, [dispatch]);

  const busStops: IBusStopsModel = useSelector(systemBusStopsSelector);
  const busStopsIsLoading: boolean = useSelector(systemBusStopsIsLoadingSelector);

  const canDelete = useSelector(getCurrentUserSelector).hasPermission(UserAccessEnum.dReferenceInsurance);

  const onDelete = async (item: ISystemBusStopModel) => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: 'остановку', entityName: item.name }))) {
      handleErrors(
        await deleteSystemRefBusStop(item.id),
        'delete',
        () => {
          toast.success('Удаление прошло успешно');
          getData({});
        },
      );
    }
  };

  return (
    <SystemBusStopsComponent
      busStops={busStops}
      busStopsIsLoading={busStopsIsLoading}
      getData={getData}
      onDelete={onDelete}
    />
  );
};
