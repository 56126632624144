import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { SYSTEM_GET_NEWS_START } from './constants';
import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import { systemGetNewsFailAction, systemGetNewsOkAction } from './actions';
import { NewsModel } from '../../../models/News/NewsModel';


function* systemNewsGetListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/news', filter);

    yield put(systemGetNewsOkAction(new NewsModel(response)));
  }, systemGetNewsFailAction);
}


export function* systemNewsSagas() {
  yield takeLatest(SYSTEM_GET_NEWS_START, systemNewsGetListSaga);
}
