import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getParamsFromPagesModel } from 'models/WithPagesModel';
import { DefaultFilterType } from 'models/common';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { systemDeletePartner } from 'systemModule/api/partners';
import { SystemPartnersListComponent } from 'systemModule/components/Partners/list';
import {
  systemGetPartnersListStartAction,
} from 'systemModule/containers/Partners/actions';
import {
  systemPartnerListLoadingSelector,
  systemPartnerListSelector,
} from 'systemModule/containers/Partners/selectors';
import { ISystemPartnerListItemModel, ISystemPartnerListModel } from 'models/Partners/SystemPartnerListModel';
import { handleErrors } from 'utils/errors';


export const SystemPartnersList = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemGetPartnersListStartAction(filter));
  }, [dispatch]);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cPartner);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dPartner);

  const data: ISystemPartnerListModel = useSelector(systemPartnerListSelector);
  const isLoading: boolean = useSelector(systemPartnerListLoadingSelector);

  const onDelete = async ({ id, title }: ISystemPartnerListItemModel) => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: 'партнера', entityName: title }))) {
      handleErrors(
        await systemDeletePartner(id),
        'delete',
        () => {
          toast.success(t('system.partners.deleted'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  };

  return (
    <SystemPartnersListComponent
      canCreate={canCreate}
      canDelete={canDelete}
      data={data}
      isLoading={isLoading}
      getData={getData}
      onDelete={onDelete}
    />
  );
};
