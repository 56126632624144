import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ListElement } from 'components/common/ListElement/ListElement';
import { UiBox } from 'components/common/UI/UiBox';
import { IEducationModel } from 'models/Education/interfaces';
import { RoutesPaths } from 'routes';
import { CDate } from 'utils/CDate';


type Props = {
  model: IEducationModel;
}

const TextRow = (
  model: IEducationModel, t: TFunction,
) => (
  { name }: { name: keyof IEducationModel },
) => ((model[name]) ?
  (
    <ListElement text={t(`education.${name}`)} isTitle>
      <div className="item ws-pw">{model[name]}</div>
    </ListElement>
  ) : null);

export const EducationItemComponent = ({ model }: Props) => {
  const { t } = useTranslation();

  const ConditionalTextRow = TextRow(model, t);

  return (
    <>
      <div className="page-header">
        <Link className="back" to={RoutesPaths.education} />
        {t('education.menu')}
      </div>

      <UiBox className="education">
        <section className="form">
          <div className="articles">
            <div className="articles-list_item open">
              <div className="articles-list_item__wrapper">

                <div className="articles-list_item__box">
                  {model.image.fullUrl && (
                    <div className="articles-list_item__img">
                      <img src={model.image.fullUrl} alt={model.image.name} />
                    </div>
                  )}
                </div>

                <ConditionalTextRow name="name" />
                <ListElement text={t('education.date_start')} isTitle>
                  <div className="item">{CDate.format(model.date_start, 'dd.MM.yyyy/HH:mm')}</div>
                </ListElement>
                <ConditionalTextRow name="schedule" />
                <ConditionalTextRow name="place" />
                <ConditionalTextRow name="contacts" />
                <ConditionalTextRow name="info" />
              </div>
            </div>
          </div>
        </section>
      </UiBox>
    </>
  );
};
