import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../../utils/reducer';
import {
  ISystemNewsCategoriesModel,
  SystemNewsCategoriesModel,
} from '../../../../models/References/NewsCategoriesModel';
import {
  SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_FAIL,
  SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_OK,
  SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_START,
} from './constants';

export type systemNewsCategoriesReducerType = {
  newsCategories: ISystemNewsCategoriesModel;
  newsCategoriesIsLoading: boolean;
}

const initialState: systemNewsCategoriesReducerType = {
  newsCategories: new SystemNewsCategoriesModel(),
  newsCategoriesIsLoading: true,
};

export const storeName = 'newsCategories';

export const systemNewsCategoriesReducer = {
  [storeName]: (state: systemNewsCategoriesReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_START:
        return onLoading(state, 'newsCategories');
      case SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_OK:
        return onLoad(state, payload, 'newsCategories');
      case SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_FAIL:
        return onFail(state, 'newsCategories');

      default:
        return state;
    }
  },
};
