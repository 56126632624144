import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { NotificationsComponent } from '../../components/Notifications/Notifications';
import { notificationsGetListStartAction, onAllNotificationsReadAction } from './actions';
import { INotificationsModel, NotificationsFilterType } from '../../models/Notification/Notifications';
import { getNotificationsIsLoadingSelector, getNotificationsSelector } from './selectors';
import { setAllNotificationsRead } from '../../api/notification';
import { handleErrors } from '../../utils/errors';


export const Notifications = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [readAllLoading, setReadAllLoading] = useState(false);

  const model: INotificationsModel = useSelector(getNotificationsSelector);
  const modelLoading: boolean = useSelector(getNotificationsIsLoadingSelector);

  const hasMoreData: boolean = model.amountOfPages > model.page;

  const getData = useCallback((filter?: NotificationsFilterType, append = false) => {
    dispatch(notificationsGetListStartAction(append, filter));
  }, [dispatch]);

  const onReadAll = useCallback(async () => {
    setReadAllLoading(true);

    handleErrors(
      await setAllNotificationsRead(),
      'save',
      () => {
        dispatch(onAllNotificationsReadAction());
        toast.success(t('notifications.all_read'));
      },
      () => setReadAllLoading(false),
    );
  }, [dispatch, t]);

  return (
    <NotificationsComponent
      getData={getData}
      hasMoreData={hasMoreData}
      model={model}
      modelLoading={modelLoading}
      onReadAll={onReadAll}
      readAllLoading={readAllLoading}
    />
  );
};

Notifications.whyDidYouRender = true;
