import { FileType, prepareFiles } from '../common';
import { EnterpriseModel, IEnterpriseModel } from '../Enterprises/EnterpriseModel';
import { CDate } from '../../utils/CDate';


export interface ISystemEvent {
  readonly id: number;
  readonly name: string;
  readonly date_start: Date | null;
  readonly description: string;
  readonly place: string;
  readonly contacts: string;
  readonly image: FileType;
  readonly send_notify: boolean;
  readonly ask_question: boolean;
  readonly enterprises: IEnterpriseModel[];
  readonly author: {
    id: number;
    fio: string;
  };
  readonly result_poll: {
    yes: number;
    no: number;
  };
}

export class SystemEventModel implements ISystemEvent {
  readonly ask_question: boolean;
  readonly author: { id: number; fio: string };
  readonly contacts: string;
  readonly date_start: Date | null;
  readonly description: string;
  readonly enterprises: IEnterpriseModel[];
  readonly id: number;
  readonly image: FileType;
  readonly name: string;
  readonly place: string;
  readonly result_poll: { yes: number; no: number };
  readonly send_notify: boolean;

  constructor(params: any = {}) {
    this.author = params.author || '';
    this.contacts = params.contacts || '';
    this.date_start = params.date_start ? CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm') : null;
    this.description = params.description || '';
    this.enterprises = Array.isArray(params.enterprises) ?
      params.enterprises.map((item: unknown) => new EnterpriseModel(item)) : [];
    this.id = params.id;
    this.image = prepareFiles(params.image);
    this.name = params.name || '';
    this.place = params.place || '';
    this.result_poll = params.result_poll || {};
    this.ask_question = params.ask_question || false;
    this.send_notify = params.send_notify || false;
  }
}
