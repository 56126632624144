import { baseApiFn } from '../utils/baseApiFn';
import { httpGet, httpPut } from '../utils/http';
import { TrbacUserRights } from '../models/Roles/RolesModel';
import { IPermission } from '../models/Roles/Permissions';


/** назначение прав и ролей сотруднику */
export const saveUserRbac = async (id: number, data: TrbacUserRights) => baseApiFn<Record<string, IPermission>>(
  httpPut(`/system/rbac/user-rights/${id}`, data),
);

/** Права и роли сотрудника */
export const getUserRbac = async (id: number) => baseApiFn<Record<string, IPermission>>(
  httpGet(`/system/rbac/user-rights/${id}`),
);
