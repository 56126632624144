import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_START } from './constants';
import { baseSaga } from '../../../../store/baseSaga';
import { systemRefGetJobDescriptionsFailAction, systemRefGetJobDescriptionsOkAction } from './actions';
import { httpGet } from '../../../../utils/http';

function* systemGetJobDescriptionsSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/job-description', filter);

    yield put(systemRefGetJobDescriptionsOkAction(response));
  }, systemRefGetJobDescriptionsFailAction);
}

export function* systemJobDescriptionsSagas() {
  yield takeLatest(SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_START, systemGetJobDescriptionsSaga);
}
