import { DefaultFilterType, FileType, prepareFiles } from '../common';
import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { IPositionsItemModel } from '../References/Positions';

export type JobDescriptionsFilterType = DefaultFilterType & {
  sort?: string;
  name?: string;
  enterprise_id?: number;
  unit_id?: number;
  position_id?: number;
}

export interface IJobDescriptionsItemModel {
  id: number;
  name: string;
  file: FileType;
  position: IPositionsItemModel;
}

export class JobDescriptionsItemModel implements IJobDescriptionsItemModel {
  id: number;
  name: string;
  file: FileType;
  position: IPositionsItemModel;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
    this.file = prepareFiles(params.file);
    this.position = params.position;
  }
}

export interface IJobDescriptionsModel extends IWithPages {
  data: IJobDescriptionsItemModel[];
}

export class JobDescriptionsModel extends WithPagesModel implements IJobDescriptionsModel {
  data: IJobDescriptionsItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, JobDescriptionsItemModel);
  }
}
