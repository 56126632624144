import { IPrivilegeList, IPrivilegeListItem } from './interfaces';
import { WithPagesModel } from '../WithPagesModel';
import { PrivilegeListItem } from './PrivilegeListItem';


export class PrivilegeList extends WithPagesModel implements IPrivilegeList {
  data: IPrivilegeListItem[];

  constructor(params: any = {}) {
    super(params);
    this.data = this.initializeData(params.data, PrivilegeListItem);
  }
}
