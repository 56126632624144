import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { ISystemSportModel } from '../../../../models/References/SportModel';
import { Loader } from '../../../../components/common/UI/Loaders';

type Props = {
  item: ISystemSportModel;
  isLoading: boolean;
}

export const SystemSportItemPageComponent = ({ item, isLoading }: Props) => (
  <Card>
    <CardBody>
      {isLoading ? <Loader /> : (
        <h3>{item.id === undefined ? 'Вид спорта не найден' : item.name}</h3>
      )}
    </CardBody>
  </Card>
);
