import { createSelector } from 'reselect';
import { RootReducer } from '../../../../store/reducers';
import { storeName } from './reducers';
import { returnParams } from '../../../../store/selectors';

const getNewsCategories = (state: RootReducer) => state.system[storeName].newsCategories;
const getNewsCategoriesIsLoading = (state: RootReducer) => state.system[storeName].newsCategoriesIsLoading;
const getNewsCategoryById = (id: number) => (state: RootReducer) => state.system[storeName].newsCategories.data
  .find((newsCategory) => newsCategory.id === id);

export const systemNewsCategoriesSelector = createSelector([getNewsCategories], returnParams);
export const systemNewsCategoriesIsLoadingSelector = createSelector([getNewsCategoriesIsLoading], returnParams);
export const systemNewsCategoryByIdSelector = createSelector([getNewsCategoryById], returnParams);
