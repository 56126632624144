import React, { useState } from 'react';
import {
  Button, Card, Col, Collapse, Form, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';


type Props = {
  onSubmitForm: () => void;
  onResetForm: () => void;
  children: React.ReactNode | React.ReactNode[];

  initialOpen?: boolean;
  useFormTag?: boolean; // false для случаев вложенности фильтра в форму
}

export const UiToolbar = ({
  onSubmitForm, onResetForm, children, initialOpen = false, useFormTag = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const { t } = useTranslation();

  const onReset = () => onResetForm();

  return (
    <Card body>
      <Row>
        <Col xs="12" className="d-flex justify-content-end">
          <Button color="primary" className="collapse-btn" onClick={() => setIsOpen(!isOpen)}>
            {t('common.toolbar.filter_btn_text')}
          </Button>
        </Col>
        <Col xs="12">
          <Collapse isOpen={isOpen}>
            {useFormTag ? (
              <Form onSubmit={onSubmitForm} onReset={onReset} autoComplete="off">
                {children}
                <Row>
                  <Col xs="12" className="d-flex justify-content-end mr-3">
                    <Button type="reset">
                      {t('common.form.reset')}
                    </Button>
                    <Button type="submit" color="primary" className="ml-3">
                      {t('common.form.find')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              <>
                {children}
                <Row>
                  <Col xs="12" className="d-flex justify-content-end mr-3">
                    <Button onClick={onReset}>
                      {t('common.form.reset')}
                    </Button>
                    <Button color="primary" className="ml-3" onClick={onSubmitForm}>
                      {t('common.form.find')}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Collapse>
        </Col>
      </Row>
    </Card>
  );
};
