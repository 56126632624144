import { AnyAction } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { baseSaga } from '../../../../store/baseSaga';
import { httpGet } from '../../../../utils/http';
import { systemRefGetEnterprisesFailAction, systemRefGetEnterprisesOkAction } from './actions';
import { SystemEnterprisesModel } from '../../../../models/References/EnterprisesModel';
import { SYSTEM_REFERENCES_GET_ENTERPRISES_START } from './constants';

function* systemGetEnterprisesSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/references/enterprise', { sort: 'id', ...filter });

    yield put(systemRefGetEnterprisesOkAction(new SystemEnterprisesModel(response)));
  }, systemRefGetEnterprisesFailAction);
}


export function* systemEnterprisesSagas() {
  yield takeLatest(SYSTEM_REFERENCES_GET_ENTERPRISES_START, systemGetEnterprisesSaga);
}
