import { SystemPartnerListModel } from 'models/Partners/SystemPartnerListModel';
import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from 'store/baseSaga';
import {
  systemGetPartnersCategoryListFailAction,
  systemGetPartnersCategoryListOkAction, systemGetPartnersListFailAction, systemGetPartnersListOkAction,
} from 'systemModule/containers/Partners/actions';
import {
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_START,
  SYSTEM_GET_PARTNERS_LIST_START,
} from 'systemModule/containers/Partners/constants';
import { httpGet } from 'utils/http';
import { SystemPartnersCategoryListModel } from 'models/Partners/SystemPartnerCategoryListModel';


function* getCategoryListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/partner/category', filter);

    yield put(systemGetPartnersCategoryListOkAction(new SystemPartnersCategoryListModel(response)));
  }, systemGetPartnersCategoryListFailAction);
}

function* getListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/partner', filter);

    yield put(systemGetPartnersListOkAction(new SystemPartnerListModel(response)));
  }, systemGetPartnersListFailAction);
}


export function* systemPartnersSagas() {
  yield takeLatest(SYSTEM_GET_PARTNERS_CATEGORY_LIST_START, getCategoryListSaga);
  yield takeLatest(SYSTEM_GET_PARTNERS_LIST_START, getListSaga);
}
