import get from 'lodash/get';
import { AVATAR } from 'config/system';
import { projectBaseUrl } from 'utils/env';

import { IBonusProgramTagItemModel } from 'models/BonusProgram/Tag/interfaces';
import { IEnterpriseModel } from '../Enterprises/EnterpriseModel';
import { CurrentUserProfileModel, ICurrentUserProfileModel } from './UserProfileModel';
import { IUserPermission, IUserRightsModel } from './UserRightsModel';
import { CurrentUserSdsInfoModel, ICurrentUserSdsInfoModel } from './UserSdsInfoModel';
import { ICurrentUserUserUnits } from './interfaces';


export enum UserAccessEnum {
  systemModule = 'r_site', // Доступ в админку
  usersView = 'r_user', // Просмотр пользователей
  crudRbac = 'crud_rbac', // Управление ролями и правами

  crudPolls = 'crud_polls', // (CRUD) Управление опросами
  adminCrudPolls = 'admin_crud_polls', // (CRUD) Суперпользователь по опросам

  cNews = 'c_news', // (C) Создание новостей
  rNews = 'r_news', // (R) Просмотр новостей
  wNews = 'w_news', // (U) Редактирование новостей
  dNews = 'd_news', // (D) Удаление новостей

  cReferenceInsurance = 'c_reference_insurance', // (C) Создание страховых программ
  rReferenceInsurance = 'r_reference_insurance', // (R) Просмотр справочника страховых программ
  wReferenceInsurance = 'w_reference_insurance', // (U) Редактирование страховых программ
  dReferenceInsurance = 'd_reference_insurance', // (D) Удаление страховых программ

  cReferenceBusStop = 'c_reference_bus_stop', // Создание справочника автобусных остановок
  rReferenceBusStop = 'r_reference_bus_stop', // Просмотр справочника автобусных остановок
  wReferenceBusStop = 'w_reference_bus_stop', // Редактирование справочника автобусных остановок
  dReferenceBusStop = 'd_reference_bus_stop', // Удаление справочника автобусных остановок

  cTransport = 'c_transport', // Создание расписания служебного транспорта
  rTransport = 'r_transport', // Просмотр расписания служебного транспорта
  wTransport = 'w_transport', // Редактирование расписания служебного транспорта
  dTransport = 'd_transport', // Удаление расписания служебного транспорта

  cReferenceNewsCategory = 'c_news_category', // Создание справочника категорий новостей
  rReferenceNewsCategory = 'r_news_category', // Просмотр справочника категорий новостей
  wReferenceNewsCategory = 'w_news_category', // Редактирование справочника категорий новостей
  dReferenceNewsCategory = 'd_news_category', // Удаление справочника категорий новостей

  cReferenceJobDescriptions = 'c_job_description', // Создание должностной инструкции
  rReferenceJobDescriptions = 'r_job_description', // Просмотр должностной инструкции
  wReferenceJobDescriptions = 'w_job_description', // Редактирование должностной инструкции
  dReferenceJobDescriptions = 'd_job_description', // Удаление должностной инструкции

  cEvents = 'c_events', // Создание событий, мероприятий
  rEvents = 'r_events', // Просмотр событий, мероприятий
  wEvents = 'w_events', // Редактирование событий, мероприятий
  dEvents = 'd_events', // Удаление событий, мероприятий

  cMedical = 'c_medical', // Создание медицинских осмотров
  rMedical = 'r_medical', // Просмотр медицинских осмотров
  wMedical = 'w_medical', // Редактирование медицинских осмотров
  dMedical = 'd_medical', // Удаление медицинских осмотров

  cEducation = 'c_education', // Создание обучения сотрудников
  rEducation = 'r_education', // Просмотр обучения сотрудников
  wEducation = 'w_education', // Редактирование обучения сотрудников
  dEducation = 'd_education', // Удаление обучения сотрудников

  rNotifyTemplate = 'r_notify_template', // Просмотр шаблонов уведомлений
  wNotifyTemplate = 'w_notify_template', // Редактирование шаблонов уведомлений

  cPrivileges = 'c_privileges', // Создание льгот
  rPrivileges = 'r_privileges', // Просмотр льгот
  wPrivileges = 'w_privileges', // Редактирование льгот
  dPrivileges = 'd_privileges', // Удаление льгот

  cPartnerCategory = 'c_partner_category', // Создание категорий партнеров
  rPartnerCategory = 'r_partner_category', // Просмотр категорий партнеров
  wPartnerCategory = 'w_partner_category', // Редактирование категорий партнеров
  dPartnerCategory = 'd_partner_category', // Удаление категорий партнеров

  cPartner = 'c_partner', // Создание партнеров
  rPartner = 'r_partner', // Просмотр партнеров
  wPartner = 'w_partner', // Редактирование партнеров
  dPartner = 'd_partner', // Удаление партнеров

  cApplication = 'c_application', // Создание моделей конструктора заявок
  rApplication = 'r_application', // Просмотр моделей конструктора заявок
  wApplication = 'w_application', // Редактирование моделей конструктора заявок
  dApplication = 'd_application', // Удаление моделей конструктора заявок

  rReports = 'r_reports', // Просмотр отчетов

  cPages = 'c_pages', // Создание текстовых страниц
  rPages = 'r_pages', // Просмотр текстовых страниц
  wPages = 'w_pages', // Редактирование текстовых страниц
  dPages = 'd_pages', // Удаление текстовых страниц

  rSettingsEnterprise = 'r_settings_enterprise', // Просмотр настроек предприятий
  wSettingsEnterprise = 'w_settings_enterprise', // Редактирование настроек предприятий

  wUserPhoneDisplay = 'w_user_show_phone', // Редактирование отображения контактного номер

  adminApplication = 'admin_application', // Суперпользователь по заявкам

  rReferral = 'r_referral', // Просмотр реферальной программы

  cBonusProgramPrize = 'c_bonus_program_prize', // Создание призов бонусной программы
  rBonusProgramPrize = 'r_bonus_program_prize', // Просмотр призов бонусной программы
  wBonusProgramPrize = 'w_bonus_program_prize', // Редактирование призов бонусной программы
  dBonusProgramPrize = 'd_bonus_program_prize', // Удаление призов бонусной программы

  cBonusProgramOrder = 'c_bonus_program_order', // Создание заказов бонусной программы
  rBonusProgramOrder = 'r_bonus_program_order', // Просмотр заказов бонусной программы
  dBonusProgramOrder = 'd_bonus_program_order', // Удаление заказов бонусной программы

  rBonusProgramEvent = 'r_bonus_program_event', // Просмотр событий бонусной программы
  wBonusProgramEvent = 'w_bonus_program_event', // Редактирование событий бонусной программы

  rBonusProgramEventHistory = 'r_bonus_program_event_history', // Просмотр истории событий бонусной программы

  cBonusProgramTag = 'c_bonus_program_tag', // Создание тегов бонусной программы
  rBonusProgramTag = 'r_bonus_program_tag', // Просмотр тегов бонусной программы
  wBonusProgramTag = 'w_bonus_program_tag', // Редактирование тегов бонусной программы
  dBonusProgramTag = 'd_bonus_program_tag', // Удаление тегов бонусной программы

  rBonusProgramUserTag = 'r_bonus_program_user_tag', // Просмотр тегов бонусной программы пользователей
  wBonusProgramUserTag = 'w_bonus_program_user_tag', // Назначение тегов бонусной программы пользователям

  rAppSectionApplications = 'r_app_section_applications',
  rAppSectionPartners = 'r_app_section_partners',
  rAppSectionContacts = 'r_app_section_contacts',
  rAppSectionBusStops = 'r_app_section_bus_stops',
  rAppSectionProfile = 'r_app_section_profile',
  rAppSectionJobDescriptions = 'r_app_section_job_descriptions',
  rAppSectionMedical = 'r_app_section_medical',
  rAppSectionEducation = 'r_app_section_education',

  w_user_settings = 'w_user_settings', // Редактирование настроек пользователя

  r_notify_user_enterprises = 'r_notify_user_enterprises', // Просмотр предприятий пользователя для уведомлений
  w_notify_user_enterprises = 'w_notify_user_enterprises' // Установка предприятий пользователя для уведомлений
}


export interface ICurrentUserModel {
  id: number;
  fio: string;
  phone: string;
  email: string;
  enterprises: IEnterpriseModel[];
  userUnits: ICurrentUserUserUnits;
  mainEnterprise: IEnterpriseModel | null;
  mobile_guide_is_completed: boolean;
  is_verified: boolean;
  isActive: boolean;
  referral_code: string;
  profile: ICurrentUserProfileModel;
  sdsInfo: ICurrentUserSdsInfoModel;
  show_phone: boolean;
  rights: IUserRightsModel;
  hasPermission: (permission: string) => boolean;
  hasRole: (role: string) => boolean;
  readonly avatarUrl: string;
  bonusProgramTags: IBonusProgramTagItemModel[];
}

export class CurrentUserModel implements ICurrentUserModel {
  id: number;
  fio: string;
  phone: string;
  email: string;
  enterprises: IEnterpriseModel[];
  userUnits: ICurrentUserUserUnits;
  mainEnterprise: IEnterpriseModel | null;
  mobile_guide_is_completed: boolean;
  is_verified: boolean;
  isActive: boolean;
  referral_code: string;
  profile: ICurrentUserProfileModel;
  sdsInfo: ICurrentUserSdsInfoModel;
  show_phone: boolean;
  rights: IUserRightsModel;
  bonusProgramTags: IBonusProgramTagItemModel[];

  constructor(params: any = {}, rights: IUserRightsModel = []) {
    this.id = params.id;
    this.fio = params.fio;
    this.phone = params.phone;
    this.email = params.email;
    this.enterprises = params.enterprises;
    this.userUnits = params.userUnits;
    this.mainEnterprise = params.mainEnterprise;
    this.mobile_guide_is_completed = params.mobile_guide_is_completed;
    this.is_verified = params.is_verified;
    this.isActive = params.is_active;
    this.referral_code = params.referral_code;
    this.profile = new CurrentUserProfileModel({
      ...params.profile,
      userSports: params.userSports,
      insurances: params.insurances,
    });
    this.sdsInfo = new CurrentUserSdsInfoModel(params.sdsInfo);
    this.show_phone = params.show_phone;
    this.rights = rights;
    this.bonusProgramTags = params.bonusProgramTags;
  }

  /**
   * проверка на наличие конкретного разрешения у пользователя
   * для удобства создан UserAccessEnum с (часто)используемыми константами
   */
  hasPermission(permissionName: string): boolean {
    return this.rights
      .findIndex((p) => p.permissions
        .findIndex((permission: IUserPermission) => permission.name === permissionName) !== -1) !== -1;
  }

  hasRole(roleName: string) {
    return this.rights.findIndex((r) => r.name === roleName) !== -1;
  }

  get avatarUrl(): string {
    if (get(this, 'profile.avatar.name', '')) {
      return `${projectBaseUrl}${this.profile.avatar.url}`;
    }
    // пока заглушка
    return AVATAR;
  }
}
