import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import get from 'lodash/get';

import { ParamTypes } from 'models/common';
import {
  ConstructApplicationTypeAvailable,
} from 'systemModule/containers/ConstructApplication/Type/ConstructApplicationTypeAvailable';
import {
  ConstructApplicationTypeFields,
} from 'systemModule/containers/ConstructApplication/Type/ConstructApplicationTypeFields';
import {
  ConstructApplicationTypeMain,
} from 'systemModule/containers/ConstructApplication/Type/ConstructApplicationTypeMain';
import { getSearchFilterParams, setSearchParam } from 'utils/urlParams';


const toggleTab = (toggle: (tab: TabsType) => void) => (tabName: TabsType) => {
  toggle(tabName);
  setSearchParam({ tab: tabName });
};

type TabsType = 'main' | 'fields' | 'available';


export const ConstructApplicationPage = () => {
  const [activeTab, setActiveTab] = useState<TabsType>(() => get(getSearchFilterParams(), 'tab', 'main'));

  const onTabChange = toggleTab((tab: TabsType) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  });

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === 'main' ? 'active' : undefined}
            onClick={() => onTabChange('main')}
          >
            Основные настройки
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'fields' ? 'active' : undefined}
            onClick={() => onTabChange('fields')}
            disabled={isNaN(numberId)}
          >
            Настройки формы для пользователя
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'available' ? 'active' : undefined}
            onClick={() => onTabChange('available')}
            disabled={isNaN(numberId)}
          >
            Настройка доступности для пользователя
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="main">
          {activeTab === 'main' && <ConstructApplicationTypeMain />}
        </TabPane>
        <TabPane tabId="fields">
          {activeTab === 'fields' && <ConstructApplicationTypeFields />}
        </TabPane>
        <TabPane tabId="available">
          {activeTab === 'available' && <ConstructApplicationTypeAvailable />}
        </TabPane>
      </TabContent>
    </>
  );
};
