import React from 'react';
import { ReactComponent as LikeIcon } from 'assets/img/like-custom.svg';

type Props = {
  liked: boolean;
  onLikeClick: () => void;
  short?: boolean;
}

export const NewsLikeComponent = ({ liked, onLikeClick, short }: Props) => (
  <div role="presentation" className={`news-like ${liked ? 'active' : ''}`} onClick={onLikeClick}>
    {!short && <span>Мне нравится</span>}
    <LikeIcon />
  </div>
);
