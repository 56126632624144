import React, { memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ValidationOptions } from 'react-hook-form';

import { CalendarContainer } from './CalendarContainer';


type Props = {
  watch: (s: string) => any;
  name: string;
  setValue: (s: string, v: any, b: boolean) => void;
  register: (s: any, v: any) => void;

  className?: string; // класс для formgroup
  label?: string;
  required?: boolean;
  showTimeSelect?: boolean; // показывать ли время
  showMonthYearPicker?: boolean;
  wrapperClassName?: string;
  minDate?: Date; // минимальная дата выбора. раньше не получится выбрать
  maxDate?: Date; // максимальная дата выбора. позже не получится выбрать
  minTime?: Date; // минимальная дата с часами и минутами для выбора
  maxTime?: Date; // максимальная дата с часами и минутами для выбора
  placeholderText?: string;

  highlightDates?: Date[]; // список подсвеченных дат
  inline?: boolean; // календарь без инпута

  isDisabled?: boolean;
}

const UiDatePickerComponent = ({
  watch, name, setValue, label, register, minDate, maxDate, placeholderText, className = '',
  required = false, showTimeSelect = false, showMonthYearPicker = false, wrapperClassName = '', minTime, maxTime,
  highlightDates = [], inline = false, isDisabled,
}: Props) => {
  const { t, i18n } = useTranslation();

  const [active, setActive] = useState(false);

  const id = Math.random().toString();

  const formgroupClassName = [className];
  if (active || watch(name)) {
    formgroupClassName.push('value');
  }

  const localValidation: ValidationOptions = {};
  if (required) {
    localValidation.required = 'Обязательное поле';
    formgroupClassName.push('required');
  }

  useEffect(() => {
    register(name, localValidation);
    // eslint-disable-next-line
  }, [register, name]);

  let calendarClassName = '';
  let dateFormat = 'dd.MM.yyyy';

  if (showTimeSelect) {
    dateFormat = 'dd.MM.yyyy HH:mm';
    calendarClassName = 'withtimes';
  }
  if (showMonthYearPicker) {
    dateFormat = 'MM.yyyy';
    calendarClassName = 'onlyMonthYear';
  }

  return (
    <FormGroup className={formgroupClassName.filter((cName) => cName).join(' ')}>
      {label && (
        <Label className="d-block" htmlFor={id}>
          {label}
        </Label>
      )}
      <DatePicker
        calendarClassName={calendarClassName}
        id={id}
        minDate={minDate}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        className="form-control height-initial"
        selected={watch(name)}
        onChange={(d: Date) => setValue(name, d, true)}
        dateFormat={dateFormat}
        locale={i18n.language}
        popperContainer={CalendarContainer}
        placeholderText={placeholderText || label}
        required={required}
        showTimeSelect={showTimeSelect}
        showMonthYearPicker={showMonthYearPicker}
        timeCaption={t('common.datepicker.time')}
        wrapperClassName={wrapperClassName}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        highlightDates={highlightDates}
        inline={inline}
        disabled={isDisabled}
      />
    </FormGroup>
  );
};

export const UiDatePicker = memo(UiDatePickerComponent);
