import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  text: string;
}

export const ApplicationGuideStepContent = ({
  title, text,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t(title)}</h3>
      <p>{t(text)}</p>
    </div>
  );
};
