import React from 'react';


type Props = {
  href: string;
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  type?: string;
}

/**
 * внешняя ссылка
 */
export const UiOuterLink = ({
  href, children, className, type,
}: Props) => (
  <a className={className} href={href} rel="noopener nofollow noreferrer" target="_blank" type={type}>{children}</a>
);
