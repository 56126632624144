import { useCallback, useEffect, useState } from 'react';


export const useDataPage = (initpage: number, initlimit: number, getData: any) => {
  const [filterData, setFilterData] = useState({});
  const [page, setPage] = useState(initpage);
  const [limit, setLimit] = useState(initlimit);

  useEffect(() => {
    getData({ ...filterData, 'per-page': limit, page });
  }, [getData, filterData, limit, page]);

  const onSubmitFilter = useCallback((fd: Record<string, any>) => {
    setFilterData(fd);
    setPage(1);
  }, []);

  const onResetFilter = useCallback(() => {
    setFilterData({});
    setPage(1);
  }, []);

  const reloadData = useCallback(() => {
    getData({ ...filterData, 'per-page': limit, page });
  }, [getData, filterData, limit, page]);

  return {
    onSubmitFilter,
    onResetFilter,
    reloadData,
    setFilterData,
    page,
    limit,
    setLimit,
    setPage,
  };
};
