import { IWithPages, WithPagesModel } from '../WithPagesModel';


export interface ISystemNotificationTemplateListModel {
  readonly id: number;
  readonly action: {code: string; name: string};
  readonly type: {code: string; name: string};
}

export class SystemNotificationTemplateListModel implements ISystemNotificationTemplateListModel {
  readonly id: number;
  readonly action: { code: string; name: string };
  readonly type: {code: string; name: string};

  constructor(params: any = {}) {
    this.id = params.id;
    this.action = params.action;
    this.type = params.type;
  }
}


export interface ISystemNotificationTemplatesListModel extends IWithPages {
  readonly data: ISystemNotificationTemplateListModel[];
}

export class SystemNotificationTemplatesListModel extends WithPagesModel
  implements ISystemNotificationTemplatesListModel {
  readonly data: ISystemNotificationTemplateListModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemNotificationTemplateListModel);
  }
}
