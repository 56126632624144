import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FilterIcon } from '../../assets/img/filter.svg';
import { UiModal } from '../common/UI/Modal';
import { UiRadioGroup } from '../common/UI/RadioGroup';
import { NotificationsFilterType } from '../../models/Notification/Notifications';


type PropsFilter = {
  onSubmit: (data: NotificationsFilterType) => void;
  filterData: NotificationsFilterType;
}

type FormValues = {
  is_read: '-1' | '0' | '1';
}

const FilterForm = ({ filterData, onSubmit }: PropsFilter) => {
  const {
    register, handleSubmit, reset,
  } = useForm<FormValues>({
    defaultValues: {
      is_read: filterData.is_read || '-1',
    },
  });

  const onSearch = useCallback((data: FormValues) => {
    onSubmit({
      is_read: data.is_read === '-1' ? undefined : data.is_read,
    });
  }, [onSubmit]);

  const { t } = useTranslation();

  return (
    <form autoComplete="off" className="modal-content" onSubmit={handleSubmit(onSearch)}>
      <div className="form-with-btn">
        <div className="form-wrapper_content">
          <UiRadioGroup
            name="is_read"
            options={[
              { value: '-1', label: t('notifications.filter.all') },
              { value: '1', label: t('notifications.filter.only_read') },
              { value: '0', label: t('notifications.filter.only_unread') },
            ]}
            register={register}
          />
        </div>

        <div className="form-wrapper_btn">
          <div className="buttons-group buttons-group-responsive">
            <Button color="primary" type="submit">
              {t('common.filter.apply')}
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => reset({ is_read: '-1' })}
              className="btn--clear-filter"
            >
              {t('common.form.clear_filter')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};


type Props = {
  onChange: (data: NotificationsFilterType) => void;
  filterData: NotificationsFilterType;
}

export const NotificationsFilter = ({ onChange, filterData }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const onSubmit = useCallback((data: NotificationsFilterType) => {
    onChange(data);
    setIsOpen(false);
  }, [onChange]);

  return (
    <div className="d-flex justify-content-end">
      <span className="filter" role="presentation" onClick={() => setIsOpen(true)}>
        <FilterIcon />
      </span>
      <UiModal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} headerContent={t('notifications.filter.title')}>
        <FilterForm filterData={filterData} onSubmit={onSubmit} />
      </UiModal>
    </div>
  );
};
