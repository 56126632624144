import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PollsComponent } from '../../components/Polls/Polls';
import { pollsGetListStartAction } from './actions';
import { getPollsIsLoadingSelector, getPollsSelector } from './selectors';
import { IPollsFilterType, IPollsModel } from '../../models/Polls/PollsModel';


export const Polls = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter?: IPollsFilterType, append = false) => {
    dispatch(pollsGetListStartAction(append, filter));
  }, [dispatch]);

  const model: IPollsModel = useSelector(getPollsSelector);
  const modelLoading: boolean = useSelector(getPollsIsLoadingSelector);

  return (
    <PollsComponent
      getData={getData}
      hasMoreData={model.amountOfPages > model.page}
      model={model}
      modelLoading={modelLoading}
    />
  );
};
