import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../../../components/common/Table';
import { EnterprisesFilterType, ISystemEnterprisesModel } from '../../../../models/References/EnterprisesModel';
import { useDataPage } from '../../../../utils/hooks/useDataPage';
import { SystemEnterprisesFilter } from './Filter';

type Props = {
  enterprises: ISystemEnterprisesModel;
  enterprisesIsLoading: boolean;
  getData: (filter: EnterprisesFilterType) => void;
}

export const SystemEnterprisesComponent = ({ enterprises, enterprisesIsLoading, getData }: Props) => {
  const {
    setLimit, setPage, onSubmitFilter, onResetFilter,
  } = useDataPage(enterprises.page, enterprises.limit, getData);

  return (
    <div>
      <Card>
        <CardBody>
          <h3>Список предприятий</h3>

          <SystemEnterprisesFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

          <CustomTable
            onChangePage={setPage}
            currentPage={enterprises.page}
            sizePerPage={enterprises.limit}
            amountOfPages={enterprises.amountOfPages}
            setSizePerPage={[setLimit, setPage]}
            isLoading={enterprisesIsLoading}
            tableHeaders={[
              { name: 'ID' },
              { name: 'Название предприятия' },
            ]}
          >
            {enterprises.data.map((item) => (
              <tr key={item.id}>
                <td>
                  <Link to={`enterprise/${item.id}`}>{item.id}</Link>
                </td>
                <td>
                  <Link to={`enterprise/${item.id}`}>{item.name}</Link>
                </td>
              </tr>
            ))}
          </CustomTable>
        </CardBody>
      </Card>
    </div>
  );
};
