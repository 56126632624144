import { ISystemReferralProgramModel } from './interfaces';
import { IShortUser } from '../User/interfaces';

export class SystemReferralProgramModel implements ISystemReferralProgramModel {
  id: number;
  code: string;
  user: IShortUser;
  activations: {
    id: number;
    user: IShortUser;
  }[];

  constructor(params: any = {}) {
    this.id = params.id;
    this.code = params.code;
    this.user = params.user;
    this.activations = params.activations;
  }
}
