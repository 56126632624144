import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ApplicationCreateSickComponent } from '../../components/Applications/ApplicationCreateSick';
import { createAppSick } from '../../api/application';
import { ApplicationSickRequestType } from '../../models/Application/Sick/interfaces';
import { handleErrors } from '../../utils/errors';

export const ApplicationCreateSick = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const createApp = useCallback(async (data: ApplicationSickRequestType) => {
    setIsLoading(true);
    const modelOrError = await createAppSick(data);

    handleErrors(
      modelOrError,
      'save',
      () => {
        if (!(modelOrError instanceof Error)) {
          toast.success(t('application.sick.created'));
          history.push('/applications');
        } else {
          setIsLoading(false);
        }
      },
    );
  }, [history, t]);

  return (
    <ApplicationCreateSickComponent
      createApp={createApp}
      isLoading={isLoading}
    />
  );
};
