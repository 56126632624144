import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import { FileType } from 'models/common';
import { UiBox } from 'components/common/UI/UiBox';
import { IPartnersListModel } from 'models/Partners/PartnersListModel';


type PartnersSliderBlockComponentProps = {
  title: string;
  list: { id: number; logo: FileType | null; topStr: string; bottomStr?: string }[];
  to: string;

  itemTo?: string;
  categoryType?: boolean;
  clearfix?: boolean;
}

export const PartnersSliderBlockComponent = (
  {
    title, list, to, categoryType = false, clearfix = true, itemTo = '/partners',
  }: PartnersSliderBlockComponentProps,
) => (list.length === 0 ? null : (
  <>
    <div className="float-right">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link className="text-primary" to={to}><b>Смотреть все</b></Link>
    </div>
    <h3>{title}</h3>
    <Slider className="discounts-slider" dots infinite arrows={false}>
      {list.map(
        ({
          id, logo, topStr, bottomStr,
        }) => (
          <Link
            key={id}
            className={`slide${logo ? '' : ` ${categoryType ? 'cat' : 'partner'}-no-img`}`}
            to={`${itemTo}/${id}`}
          >
            {logo ? <img key={logo.name} src={logo.fullUrl} alt={logo.name} /> : <div />}
            <div className="slide-info">
              <div className={categoryType ? 'slide-cat' : 'slide-title'}>{topStr}</div>
              {bottomStr && <div className="slide-desc">{bottomStr}</div>}
            </div>
          </Link>
        ),
      )}
    </Slider>
    {clearfix && <div className="clearfix" />}
  </>
));


type PartnersItemInfoComponentProps = {
  logo: FileType | null;
  title: string;

  description?: string;
  tag?: 'link';
  to?: any;
  single?: boolean; // просмотр партнера
}
export const PartnersItemInfoComponent = ({
  logo, title, description, tag, to, single,
}: PartnersItemInfoComponentProps) => {
  const content = (
    <>
      {(logo && !single) && <img className="paralax_logo" src={logo.fullUrl} alt="" />}
      <div className="collapsible-item-info">
        <div className="collapsible-item-title">{title}</div>
        {description && <div className="collapsible-item-desc">{description}</div>}
      </div>
    </>
  );
  const className = `collapsible-item${logo ? '' : ' partner-no-img'}`;

  if (tag === 'link') {
    return (
      <Link className={className} to={to}>
        {content}
      </Link>
    );
  }
  return (
    <div className={className} style={logo ? { backgroundImage: `url(${logo.fullUrl})` } : undefined}>
      {content}
    </div>
  );
};


type PartnersCommonListComponentProps = {
  title: string;
  children: React.ReactNode;
  className?: string;
}
export const PartnersCommonListComponent = (
  { title, children, className = 'collapsible' }: PartnersCommonListComponentProps,
) => {
  const [classn, setClassn] = useState('minimized');

  const history = useHistory();

  const toBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/partners');
    }
  };

  return (
    <>
      <div className="page-header">
        <span role="presentation" className="back cursor-pointer" onClick={toBack} />
        {title}
      </div>

      <UiBox
        className="partners"
        onScrollStart={({ scrollYBlocked, scrollYPossible }) => {
          /** При скролле вниз карточки разворачиваются. При возвращении к первой карточке - они сворачиваются. */
          setClassn(!scrollYBlocked && scrollYPossible ? '' : 'minimized');
        }}
        onScrollStop={({ scrollTop }) => {
          setClassn(scrollTop > 5 ? '' : 'minimized');
        }}
      >
        <section className="form collapse-wrapper">
          <h3>{title}</h3>
          <div className={`discounts-list${className ? ` ${className}` : ''}${classn ? ` ${classn}` : ''}`}>
            {children}
          </div>
        </section>
      </UiBox>
    </>
  );
};


type PartnersSortedListComponentProps = {
  data: { group: number | string; values: IPartnersListModel}[]
}
export const PartnersSortedListComponent = (
  { data } : PartnersSortedListComponentProps,
) => (
  <>
    {data.map(({ group, values }) => (
      <Fragment key={group}>
        <h3>{group}</h3>
        {values.map(({
          id, logo, company_name, title,
        }) => (
          <PartnersItemInfoComponent
            key={id}
            logo={logo}
            title={company_name}
            description={title}
            tag="link"
            to={`/partners/${id}`}
          />
        ))}
      </Fragment>
    ))
    }
  </>
);
