import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EnterpriseSelectionComponent } from '../../components/Enterprises/EnterpriseSelectionComponent';
import { setMainEnterpriseAction } from './actions';
import { getCurrentUserAction } from '../../store/currentUser/actions';
import { CurrentUserModel } from '../../models/User/CurrentUserModel';
import { getCurrentUserIsLoadingSelector, getCurrentUserSelector } from '../../store/currentUser/selectors';

export const EnterpriseSelection = () => {
  const dispatch = useDispatch();

  const currentUser: CurrentUserModel = useSelector(getCurrentUserSelector);
  const isLoading: boolean = useSelector(getCurrentUserIsLoadingSelector);

  useEffect(() => {
    if (!currentUser.id) {
      dispatch(getCurrentUserAction());
    }
  }, [dispatch, currentUser]);

  const setMainEnterprise = useCallback((id: number) => {
    dispatch(setMainEnterpriseAction(id));
  }, [dispatch]);

  return (
    <EnterpriseSelectionComponent
      enterprises={currentUser.enterprises}
      isLoading={isLoading}
      onSubmit={setMainEnterprise}
    />
  );
};
