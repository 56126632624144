import { ICurrentUserModel } from '../../models/User/CurrentUserModel';
import { ICurrentUserEditData } from '../../models/User/interfaces';
import { dataAction, dataErrorAction, dataSuccessAction } from '../actions';
import * as CONSTANTS from './constants';


export const getCurrentUserAction = dataAction(CONSTANTS.GET_CURRENT_USER);
export const getCurrentUserOkAction = (currentUser: ICurrentUserModel) => dataSuccessAction(
  CONSTANTS.GET_CURRENT_USER_OK,
)({ currentUser });
export const getCurrentUserFailAction = dataErrorAction(CONSTANTS.GET_CURRENT_USER_FAIL);


export const editCurrentUserActionStartAction = (data: ICurrentUserEditData) => dataAction(
  CONSTANTS.EDIT_CURRENT_USER_START,
)({ data });
export const editCurrentUserActionOkAction = (currentUser: ICurrentUserModel) => dataSuccessAction(
  CONSTANTS.EDIT_CURRENT_USER_OK,
)({ currentUser });
export const editCurrentUserActionFailAction = dataErrorAction(CONSTANTS.EDIT_CURRENT_USER_FAIL);

export const updateCurrentUserStartAction = dataAction(CONSTANTS.CURRENT_USER_UPDATE_START);
export const updateCurrentUserOKAction = (currentUser: ICurrentUserModel) => dataSuccessAction(
  CONSTANTS.CURRENT_USER_UPDATE_OK,
)({ currentUser });
export const updateCurrentUserFailAction = dataErrorAction(CONSTANTS.CURRENT_USER_UPDATE_FAIL);

export const updateCurrentUserAction = (currentUserToUpdate: ICurrentUserModel) => dataAction(
  CONSTANTS.CURRENT_USER_UPDATE,
)({ currentUserToUpdate });

export const clearCurrentUserDataAction = dataAction(CONSTANTS.CURRENT_USER_CLEAR_DATA);
