import { DefaultFilterType } from '../common';
import { IWithPages, WithPagesModel } from '../WithPagesModel';


export type IInsuranceModelForm = {
  name: string;
}

export interface InsuranceFilterType extends DefaultFilterType {
  sort?: string;
  name?: string;
}

export interface IInsuranceModel {
  id: number;
  name: string;
}

export class InsuranceModel implements IInsuranceModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}


export interface IInsurancesModel extends IWithPages {
  data: IInsuranceModel[];
}

export class InsurancesModel extends WithPagesModel implements IInsurancesModel {
  data: IInsuranceModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, InsuranceModel);
  }
}
