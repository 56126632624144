import { IApplicationListModel, IApplicationsListModel } from './interfaces';
import { CDate } from '../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../config/system';
import { WithPagesModel } from '../WithPagesModel';
import { IShortUser } from '../User/interfaces';


export class ApplicationListModel implements IApplicationListModel {
  assign: IShortUser;
  author: IShortUser;
  created_at: Date;
  enterprise: string;
  id: number;
  status: { id: number; name: string };
  type: { id: number; name: string };
  updated_at: Date;

  constructor(params: any = {}) {
    this.assign = params.assign;
    this.author = params.author;
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.enterprise = params.enterprise;
    this.id = params.id;
    this.status = params.status;
    this.type = params.type;
    this.updated_at = CDate.parse(params.updated_at, BACKEND_DATE_FORMAT);
  }
}


export class ApplicationsListModel extends WithPagesModel implements IApplicationsListModel {
  data: IApplicationListModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, ApplicationListModel);
  }
}
