import { FileType, prepareFiles } from 'models/common';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import {
  ISystemPartnersCategoryListItemModel,
} from 'models/Partners/SystemPartnerCategoryListModel';
import { IWithPages, WithPagesModel } from 'models/WithPagesModel';


export interface ISystemPartnerListItemModel {
  readonly id: number;
  readonly title: string;
  readonly company_name: string;
  readonly is_active: boolean;
  readonly logo: FileType;
  readonly categories: ISystemPartnersCategoryListItemModel[];
  readonly enterprises: IEnterpriseModel[];
  readonly created_at: string;
}

export interface ISystemPartnerListModel extends IWithPages {
  data: ISystemPartnerListItemModel[];
}

export class SystemPartnerListItemModel implements ISystemPartnerListItemModel {
  readonly categories: ISystemPartnersCategoryListItemModel[];
  readonly company_name: string;
  readonly created_at: string;
  readonly enterprises: IEnterpriseModel[];
  readonly id: number;
  readonly is_active: boolean;
  readonly logo: FileType;
  readonly title: string;

  constructor(params: any = {}) {
    this.categories = params.categories;
    this.company_name = params.company_name;
    this.created_at = params.created_at;
    this.enterprises = params.enterprises;
    this.id = params.id;
    this.is_active = params.is_active;
    this.logo = prepareFiles(params.logo);
    this.title = params.title;
  }
}


export class SystemPartnerListModel extends WithPagesModel implements ISystemPartnerListModel {
  data: ISystemPartnerListItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemPartnerListItemModel);
  }
}
