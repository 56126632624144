import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { systemRefGetInsuranceStartAction } from '../actions';
import { systemInsurancesIsLoadingSelector, systemInsurancesSelector } from '../selectors';
import { IInsurancesModel, InsuranceFilterType } from '../../../../models/Insurances/InsurancesModel';
import { SystemRefInsuranceComponent } from '../../../components/References/Insurance/SystemRefInsurance';


export const SystemRefInsurance = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: InsuranceFilterType) => {
    dispatch(systemRefGetInsuranceStartAction(filter));
  }, [dispatch]);

  const data: IInsurancesModel = useSelector(systemInsurancesSelector);
  const isLoading: boolean = useSelector(systemInsurancesIsLoadingSelector);

  return (
    <SystemRefInsuranceComponent getData={getData} data={data} isLoading={isLoading} />
  );
};
