import React, { useCallback, useEffect, useState } from 'react';
import isNaN from 'lodash/isNaN';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';

import { ParamTypes } from 'models/common';
import { customHistory } from 'customHistory';
import { SystemFeedbackResponsibleFormComponent } from '../../components/Feedback/SystemFeedbackResponsibleForm';
import { systemEnterprisesSelector } from '../../store/selectors';
import { getDepartments } from '../../api/references/departments';
import { IUserListUnit } from '../../../models/Users/interfaces';
import { getUserList } from '../../../api/Users';
import {
  IApplicationResponsibleFormModel,
  IApplicationResponsibleModel,
} from '../../../models/Application/Responsible/interfaces';
import { appResponsibleById, createAppResponsible, updateAppResponsible } from '../../../api/application';
import { Loader } from '../../../components/common/UI/Loaders';
import { handleErrors } from '../../../utils/errors';
import { ApplicationResponsibleModel } from '../../../models/Application/Responsible/ApplicationResponsibleListModel';
import { IDepartmentItemModel } from '../../../models/References/DepartmentModel';
import { SimpleSelectOptionType } from '../../../utils/convertModelToOptions';
import { IUserListModel } from '../../../models/Users/UsersModel';
import { usePromise } from '../../../utils/hooks/usePromise';

export const SystemFeedbackResponsibleForm = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const enterprises = useSelector(systemEnterprisesSelector);

  const [users, setUsers] = useState<SimpleSelectOptionType[]>([]);
  const [usersIsLoading, setUsersIsLoading] = useState(true);

  const [departments, setDepartments] = useState<IDepartmentItemModel[]>([]);
  const [departmentsIsLoading, setDepartmentsIsLoading] = useState(true);

  const getUsers = useCallback(async () => {
    setUsersIsLoading(true);

    handleErrors(
      await getUserList({ is_register: true }),
      'get',
      (modelOrError) => {
        const newUsers = modelOrError.data.reduce((accModel: SimpleSelectOptionType[], model: IUserListModel) => {
          const models = model.units.reduce((acc: SimpleSelectOptionType[], val: IUserListUnit) => {
            acc.push(...val.users.map((item) => ({
              label: `${item.fio} (${val.name}, ${model.name})`,
              value: item.id,
            })));
            return acc;
          }, []);

          accModel.push(...models);
          return accModel;
        }, []);

        setUsers(newUsers);
      },
    );

    setUsersIsLoading(false);
  }, []);

  const onSave = useCallback(async (modelId: number | null, modelToSave: IApplicationResponsibleFormModel) => {
    const trueOrError = modelId ?
      await updateAppResponsible(modelId, modelToSave) :
      await createAppResponsible(modelToSave);

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t(modelId ? 'feedback.updated' : 'feedback.created'));
        customHistory.push('/system/feedback-responsible');
      },
    );
  }, [t]);

  const fetchDepartments = useCallback(async () => {
    setDepartmentsIsLoading(true);

    handleErrors(
      await getDepartments(),
      'get',
      (modelOrError) => setDepartments(modelOrError.data),
    );

    setDepartmentsIsLoading(false);
  }, []);

  const [model, isLoading, onUnmount] = usePromise<IApplicationResponsibleModel>(
    () => appResponsibleById(numberId),
    !isNaN(numberId),
  );
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  useEffect(() => {
    fetchDepartments();
    getUsers();
  }, [fetchDepartments, getUsers]);

  if (isLoading || usersIsLoading || departmentsIsLoading) {
    return <Loader />;
  }

  return (
    <SystemFeedbackResponsibleFormComponent
      enterprises={enterprises}
      departments={departments}
      users={users}
      model={model || new ApplicationResponsibleModel()}
      onSave={onSave}
    />
  );
};
