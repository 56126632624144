import { store } from 'index';
import { toast } from 'react-toastify';
import { globalSetOnlineAction } from 'store/actions';

/**
 * подписка на события офллайн и онлайн
 * + диспатч в стор
 */
export const subscribeOnOffline = (): void => {
  window.addEventListener('online', () => {
    store.dispatch(globalSetOnlineAction(true));
    toast.dismiss();
    toast.success('Вы снова в онлайне');
  });
  window.addEventListener('offline', () => {
    store.dispatch(globalSetOnlineAction(false));
    toast.warn('Вы перешли в режим "оффлайн". Некоторые функции могут не работать', { autoClose: 50000 });
  });
};
