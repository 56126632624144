import React from 'react';
import { Link } from 'react-router-dom';
import { BusStopSearch } from './Search';
import { BusStopItem } from './BusStopItem';
import { IBusStopsModel, BusStopsParamsType } from '../../models/BusStops/BusStops';
import { InfiniteScrollbar } from '../common/InfiniteScrollbar';
import { Loader } from '../common/UI/Loaders';
import { Header } from '../Main/Header';

type Props = {
  isFavoriteTab?: boolean;
  busStopsIsLoading: boolean;
  busStops: IBusStopsModel;
  toggleFavoriteBusStop: (id: number, isFavorite: boolean) => void;
  getData: (append: boolean, params?: BusStopsParamsType) => void;
  hasMoreData: boolean;
}

export const BusStopComponent = ({
  isFavoriteTab = false,
  toggleFavoriteBusStop,
  busStops,
  busStopsIsLoading,
  getData,
  hasMoreData,
}: Props) => {
  const onLoadMoreData = (page: number) => {
    getData(true, {
      page,
    });
  };

  return (
    <>
      <Header className="d-none-custom" />
      <div className="box">
        <InfiniteScrollbar
          currentPage={busStops.page}
          hasMoreData={hasMoreData && !busStopsIsLoading}
          onLoadData={onLoadMoreData}
        >
          <section className="form">
            <div className="search-box page">
              <BusStopSearch submit={getData} />

              <div className="tabs">
                <Link className={`item${isFavoriteTab ? '' : ' active'}`} to="/bus-stops">
                  Все остановки
                </Link>
                <Link className={`item${isFavoriteTab ? ' active' : ''}`} to="/bus-stops?tab=favorite">
                  Избранное
                </Link>
              </div>

              {busStopsIsLoading ?
                <Loader className="mt-3" /> : (
                  <>
                    {(!busStopsIsLoading && busStops.data.length === 0) &&
                    <h3 className="text-center">Нет остановок</h3>}
                    <div>
                      {busStops.data.map((item) => (
                        <BusStopItem
                          key={item.id}
                          item={item}
                          toggleFavoriteBusStop={toggleFavoriteBusStop}
                          busStopsIsLoading={busStopsIsLoading}
                        />
                      ))}
                    </div>
                  </>
                )
              }
            </div>
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};
