import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hasUnemptyValues } from 'utils/objects';
import { systemTagsSelector } from 'systemModule/store/selectors';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiToolbar } from 'components/common/Toolbar';
import { UiSelect } from 'components/common/UI/Select';
import { SystemUsersFilterType } from 'models/Users/interfaces';
import { ISystemBonusProgramTagItemModel } from 'models/BonusProgram/Tag/interfaces';
import { convertModelToOptions, SimpleSelectOptionType } from 'utils/convertModelToOptions';


type Props = {
  onSubmit: (data: SystemUsersFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  name: string;
  phone: string;
  tags: {label: string, value: number}[];
}

export const SystemBonusProgramUsersFilter = ({ onSubmit, onReset }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, reset, errors, setValue, watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      phone: '',
      tags: [],
    },
  });

  const tags: ISystemBonusProgramTagItemModel[] = useSelector(systemTagsSelector);
  const tagsOptions = convertModelToOptions(tags);

  const onLocalSubmit = (data: FormValues) => {
    const preparedFilterData = {
      name: data.name.length ? data.name : undefined,
      phone: data.phone.length ? data.phone : undefined,
      bonus_program_tag: data.tags.length ?
        data.tags.map((tag: SimpleSelectOptionType<number>) => tag.value) :
        undefined,
    };

    if (hasUnemptyValues(preparedFilterData)) {
      onSubmit(preparedFilterData);
    }
  };

  const onLocalReset = () => {
    reset();
    onReset();
  };

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('common.fullname')}
            name="name"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('common.phone')}
            name="phone"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="tags"
            errors={errors}
            options={tagsOptions}
            value={watch('tags')}
            label={t('common.tags')}
            multiple
            allowEmptyValue
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
