import React, { useCallback, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { format, sub } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Row,
} from 'reactstrap';

import {
  DatesFormValues, defaultEmptyHolidayDates,
  defaultEmptyHolidayField,
  HolidayFormValues,
  MAX_TRANSPORT_FIELDS_AMOUNT, prepareRemoveHolidaysField,
} from 'systemModule/components/Transport/common';
import { UiSystemInput } from 'components/common/UI/Input';
import { ReactComponent as TrashIcon } from 'assets/img/trash.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus-square.svg';
import { BACKEND_DATE_ONLY_FORMAT } from 'config/system';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';
import { CheckboxOfCancel } from 'systemModule/components/Transport/CheckboxOfCancel';


type Props = {
  blockIndex: number;
  errors: FieldErrors<any>;
  register: (s: any) => any;
  unregister: (s: any) => any;
  triggerValidation: () => any;
  fields: HolidayFormValues[];
  getValues: (s?: any) => any;
  setValue: (s: string, v: any, sv?: boolean) => any;
  dates: DatesFormValues[];
}


export function ScheduleHolidays({
  fields, blockIndex, errors, register, unregister,
  getValues, setValue, triggerValidation, dates,
}: Props) {
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState<Date>(new Date());
  const haveHolidaySchedule = getValues(`blocks[${blockIndex}].haveHolidaySchedule`);
  const formattedDate: string = format(date, BACKEND_DATE_ONLY_FORMAT);


  /** управление полями */
  const appendField = useCallback(() => {
    const localBlocks = getValues({ nest: true }).blocks;

    // Добавить дату в форму
    if (!dates.find((item) => item.date === formattedDate)) {
      const lastIndex = dates.length;
      Object.entries(defaultEmptyHolidayDates(formattedDate)).forEach(([key, value]) => {
        register(`blocks[${blockIndex}].holidaysDates[${lastIndex}].${key}`);
        setValue(`blocks[${blockIndex}].holidaysDates[${lastIndex}].${key}`, value);
      });
    }

    /** зарегистрировать 1 пустое поле */
    const newFieldIndex = localBlocks[blockIndex].holidaysFields?.length || 0;
    Object.entries(defaultEmptyHolidayField(formattedDate)).forEach(([key, value]) => {
      register(`blocks[${blockIndex}].holidaysFields[${newFieldIndex}].${key}`);
      setValue(`blocks[${blockIndex}].holidaysFields[${newFieldIndex}].${key}`, value);
    });
  }, [register, setValue, getValues, formattedDate, blockIndex, dates]);

  const removeField = useCallback((fieldIndex: number) => {
    unregister(prepareRemoveHolidaysField(blockIndex, fieldIndex));
    triggerValidation();
  }, [unregister, blockIndex, triggerValidation]);
  /** end управление полями */

  return (
    <div className="mt-5">
      <UiSystemCheckbox
        name={`blocks[${blockIndex}].haveHolidaySchedule`}
        register={register}
        label={t('system.transport.route.holiday')}
      />
      {haveHolidaySchedule && (
        <Row>
          <Col xs={12} md={6} xl={3}>
            <DatePicker
              highlightDates={dates.map((item) => new Date(item.date))}
              className="form-control height-initial"
              selected={date}
              onChange={(newDate: Date) => setDate(newDate)}
              dateFormat="dd.MM.yyyy"
              locale={i18n.language}
              inline
              // Колендарь глючит с min датой на сегодня поэтому отнимаем 1 день.
              minDate={sub(new Date(), { days: 1 })}
            />
          </Col>
          <Col xs={12} md={6} xl={9}>
            <CheckboxOfCancel
              fields={fields}
              blockIndex={blockIndex}
              dates={dates}
              formattedDate={formattedDate}
              getValues={getValues}
              setValue={setValue}
              triggerValidation={triggerValidation}
              register={register}
              unregister={unregister}
            />

            <Row>
              {fields
                .map((field, fieldIndex) => (
                  <Col
                    key={`holiday_field${field.id}`}
                    className={
                      field.date !== formattedDate ? 'd-none' : undefined
                    }
                    xs={12}
                    md={6}
                    xl={4}
                  >
                    <Row>
                      <Col xs={9}>
                        <UiSystemInput
                          register={register}
                          name={`blocks[${blockIndex}].holidaysFields[${fieldIndex}].time`}
                          errors={errors}
                          type="time"
                          required
                        />
                      </Col>
                      <Col xs={3} className="pl-0">
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => removeField(fieldIndex)}
                          className="construct-application-type-field--btn mr-2"
                        >
                          <TrashIcon />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                ))
              }
            </Row>
            {(fields.filter((f) => f).length < MAX_TRANSPORT_FIELDS_AMOUNT) && (
              <Row>
                <Col>
                  <Button
                    type="button"
                    color="success"
                    onClick={appendField}
                    className="construct-application-type-field--btn"
                  >
                    <PlusIcon />
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}
