import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import {
  SystemBonusProgramPrizeItemModel,
} from 'models/BonusProgram/Prize/SystemBonusProgramPrizeModel';
import {
  createSystemBonusProgramPrizeItem,
  getSystemBonusProgramPrizeItemById,
  updateSystemBonusProgramPrizeItem,
} from 'systemModule/api/bonusProgram';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';
import { Loader } from 'components/common/UI/Loaders';
import {
  SystemBonusProgramPrizeItemFormComponent,
} from 'systemModule/components/BonusProgram/BonusProgramPrizes/SystemBonusProgramPrizeItemForm';
import {
  ISystemBonusProgramPrizeItemFormModel,
  ISystemBonusProgramPrizeItemModel,
} from 'models/BonusProgram/Prize/interfaces';

export const SystemBonusProgramPrizeItemForm = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const history = useHistory();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreatePrize = currentUser.hasPermission(UserAccessEnum.cBonusProgramPrize);
  const canUpdatePrize = currentUser.hasPermission(UserAccessEnum.wBonusProgramPrize);

  const onSave = (async (modelId: number | null, modelToSave: ISystemBonusProgramPrizeItemFormModel) => {
    const trueOrError = modelId ?
      await updateSystemBonusProgramPrizeItem(modelId, modelToSave) :
      await createSystemBonusProgramPrizeItem(modelToSave);

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t(modelId ? 'system.bonusProgram.updated.prize' : 'system.bonusProgram.created.prize'));
        history.push('/system/bonus-program/prizes');
      },
    );
  });

  const [model, modelIsLoading] = usePromise<ISystemBonusProgramPrizeItemModel>(
    () => getSystemBonusProgramPrizeItemById(numberId),
    !isNaN(numberId),
  );

  if (modelIsLoading) {
    return <Loader />;
  }

  return (
    <SystemBonusProgramPrizeItemFormComponent
      model={model || new SystemBonusProgramPrizeItemModel()}
      onSave={onSave}
      canUpdatePrize={canUpdatePrize}
      canCreatePrize={canCreatePrize}
    />
  );
};
