import { DefaultFilterType, FileType } from 'models/common';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { IWithPages } from 'models/WithPagesModel';

/** категории */

export interface IConstructApplicationCategoryListItemModel {
  readonly id: number;
  readonly name: string;
  readonly can_delete: boolean;

  readonly isNew: boolean;
}

export interface IConstructApplicationCategoryListModel extends IWithPages {
  readonly data: IConstructApplicationCategoryListItemModel[];
}

/** шаблоны */

export type IApplicationTemplateListFilter = DefaultFilterType & {
  name?: string;
  enterprise_id?: number;
}

export interface IConstructApplicationTemplateListItemModel {
  readonly id: number;
  readonly name: string;
  readonly can_delete: boolean;
  readonly enterprise: IEnterpriseModel;
  readonly file: FileType | null;

  readonly isNew: boolean;
}

export interface IConstructApplicationTemplateListModel extends IWithPages {
  readonly data: IConstructApplicationTemplateListItemModel[];
}

export type ConstructApplicationTemplateFormType = {
  readonly id: number;
  readonly name: string;
  readonly file: string;
  readonly enterprise_id: number;
}
export type ConstructApplicationTemplateFormRawFileType = {
  readonly id: number;
  readonly name: string;
  readonly file: File;
  readonly enterprise_id: number;
  readonly isNew: boolean;
}

/** сами заявки */

/** создание. шаг 1 */
export type ConstructApplicationTypeFormType = {
  readonly id: number;
  readonly name: string;
  readonly category_id: number | null;
  readonly enterprise_id: number | null; // если не указать, то будет для всех предприятий
  readonly assign_user_ids: number[] | null;
  readonly deadline: number | null;
  readonly need_approval: boolean;
  readonly original_place: string | null;
  readonly schedule: string | null;
  readonly send_email: boolean;
  readonly isNew: boolean;
}

/** форма пользователя. шаг 2 */
export type ConstructApplicationTypeSaveFieldsType = {
  readonly name: string;
  readonly has_template: boolean;
  readonly template_id: number | null;
  file: string | null;
  readonly fields: {
    name: string;
    type: string;
    required: boolean;
    template_mark: string;
    list?: string[];
  }[];
}

/** форма для передачи в контейнер. без сохраненных файлов */
export type ConstructApplicationTypeFormFieldsType = Omit<ConstructApplicationTypeSaveFieldsType, 'file'>
  & { file: File | null | string };

export type IApplicationTypeListFilter = DefaultFilterType & {
  name?: string;
  enterprise_id?: number;
  assign_user_id?: number;
  category_id?: number;
  is_active?: boolean;
}

export interface IConstructApplicationTypeListItemModel {
  readonly id: number;
  readonly name: string;
  readonly category: {
    id: number;
    name: string;
    can_delete: boolean;
  } | null;
  readonly enterprise: IEnterpriseModel | null;
  readonly assignUsers: {
    id: number;
    fio: string;
    phone: string;
    unit: string;
    post: string;
  }[];
  readonly is_active: boolean;
}

export interface IConstructApplicationTypeListModel extends IWithPages {
  readonly data: IConstructApplicationTypeListItemModel[];
}

export type ConstructApplicationTypeModelAdditionalFieldType = {
  readonly block_id: number;
  readonly name: string;
  readonly has_template: boolean;
  readonly template_id: number | null;
  readonly file: FileType;
  readonly fields: {
    uid: string;
    name: string;
    type: string;
    required: boolean;
    list?: string[];
    template_mark: string;
  }[];
}
export type ConstructApplicationTypeModelAdditionalFieldsType = ConstructApplicationTypeModelAdditionalFieldType[];

export type ConstructApplicationTypeModelAvailableType = {
  readonly privilege_id: number | null;
  readonly enable_all: boolean;
  readonly enable_part_time: boolean;
  readonly enable_married: boolean;
  readonly enable_many_children: boolean;
  readonly enable_yong: boolean;
  readonly enable_president: boolean;
  readonly enable_invite: boolean;
  readonly enable_contract: boolean;
  readonly enable_invalid_children: boolean;
  readonly enable_children_count: number | null;
  readonly enable_children_age: number | null;
}

export interface IConstructApplicationTypeModel extends IConstructApplicationTypeListItemModel {
  readonly deadline: number;
  readonly need_approval: boolean;
  readonly original_place: string;
  readonly schedule: string;
  readonly send_email: boolean;
  readonly created_at: string;
  readonly updated_at: string;
  readonly availability: ConstructApplicationTypeModelAvailableType;
  readonly additionalFields: ConstructApplicationTypeModelAdditionalFieldsType;
  readonly isNew: boolean;
}


export enum ApplicationConstructTypes {
  string = 'string',
  number = 'number',
  select = 'list',
  datetime = 'datetime',
  checkbox = 'boolean',
  file = 'file',
}
