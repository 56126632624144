import { createSelector } from 'reselect';
import { returnParams } from 'store/selectors';
import { RootReducer } from 'store/reducers';
import { storeName } from './reducers';


const getCategoryList = (state: RootReducer) => state.system[storeName].categoryList;
const getCategoryListIsLoading = (state: RootReducer) => state.system[storeName].categoryListIsLoading;

const getTemplateList = (state: RootReducer) => state.system[storeName].templateList;
const getTemplateListIsLoading = (state: RootReducer) => state.system[storeName].templateListIsLoading;


export const systemConstructApplicationCategoryListSelector = createSelector([getCategoryList], returnParams);
export const systemConstructApplicationCategoryListIsLoadingSelector = createSelector(
  [getCategoryListIsLoading], returnParams,
);

export const systemConstructApplicationTemplateListSelector = createSelector([getTemplateList], returnParams);
export const systemConstructApplicationTemplateListIsLoadingSelector = createSelector(
  [getTemplateListIsLoading], returnParams,
);
