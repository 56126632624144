import React from 'react';
import { useDataPage } from 'utils/hooks/useDataPage';
import { CustomTable } from 'components/common/Table';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ISystemBonusProgramTagFilter,
  ISystemBonusProgramTagItemModel,
  ISystemBonusProgramTagListModel,
} from 'models/BonusProgram/Tag/interfaces';
import { SystemBonusProgramTagListFilter } from 'systemModule/components/References/BonusProgram/TagFilter';

type Props = {
  data: ISystemBonusProgramTagListModel;
  getData: (filter: ISystemBonusProgramTagFilter) => void;
  dataIsLoading: boolean;
  canDeleteTag: boolean;
  deleteTag: (model: ISystemBonusProgramTagItemModel) => () => void;
}

export const SystemBonusProgramTagListComponent = ({
  data, getData, dataIsLoading, canDeleteTag, deleteTag,
}: Props) => {
  const { t } = useTranslation();

  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  return (
    <>
      <h3>Список тегов бонусной программы</h3>

      <SystemBonusProgramTagListFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        sizePerPage={data.limit}
        setSizePerPage={[setLimit, setPage]}
        amountOfPages={data.amountOfPages}
        currentPage={data.page}
        onChangePage={setPage}
        isLoading={dataIsLoading}
        customButtons={(
          <Button
            tag={Link}
            color="primary"
            to="bonus-program-tags/create"
          >
            Создать тег
          </Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: t('system.bonusProgram.tag.title.label') },
          { name: t('common.table.actions') },
        ]}
      >
        {
          data.data.map((tagItem) => (
            <tr key={tagItem.id}>
              <td>
                <Link to={`bonus-program-tags/${tagItem.id}`}>{tagItem.id}</Link>
              </td>
              <td>
                <Link to={`bonus-program-tags/${tagItem.id}`}>{tagItem.title}</Link>
              </td>
              <td>
                {
                  canDeleteTag && (
                    <Button
                      className="w-100 mb-4"
                      color="danger"
                      onClick={deleteTag(tagItem)}
                    >
                      {t('common.form.delete')}
                    </Button>
                  )
                }
                <Button
                  className="w-100"
                  color="primary"
                  tag={Link}
                  to={`bonus-program-tags/${tagItem.id}`}
                >
                  Подробнее
                </Button>
              </td>
            </tr>
          ))
        }
      </CustomTable>
    </>
  );
};
