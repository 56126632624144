import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from '../../../store/baseSaga';
import { systemGetMedicalFailAction, systemGetMedicalOkAction } from './actions';
import { SYSTEM_GET_MEDICAL_START } from './constants';
import { httpGet } from '../../../utils/http';
import { SystemMedicalModel } from '../../../models/Medical/SystemMedicalModel';

export function* systemGetMedicalListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/medical', filter);

    yield put(systemGetMedicalOkAction(new SystemMedicalModel(response)));
  }, systemGetMedicalFailAction);
}

export function* systemMedicalSagas() {
  yield takeLatest(SYSTEM_GET_MEDICAL_START, systemGetMedicalListSaga);
}
