import React, { useCallback, useState } from 'react';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ParamTypes } from 'models/common';
import { maxValueToGetAllDataWithPagination } from 'config/system';
import { systemEnterprisesSelector } from 'systemModule/store/selectors';
import { JobDescriptionsItemModel } from 'models/JobDescription/JobDescription';
import { handleErrors } from 'utils/errors';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { ISystemUnitModel, SystemUnitsModel, UnitsFilterType } from 'models/References/UnitsModel';
import { IPositionsItemModel, PositionsFilterType, PositionsModel } from 'models/References/Positions';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { getJobDescriptionById } from '../../../api/references/jobDescriptions';
import { JobDescriptionsFormComponent } from '../../../components/References/JobDescriptions/JobDescriptionsForm';
import { getSystemPositions } from '../../../api/references/positions';
import { getSystemUnits } from '../../../api/references/unit';
import { usePromise } from '../../../../utils/hooks/usePromise';
import { Loader } from '../../../../components/common/UI/Loaders';

export const SystemJobDescriptionForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  // eslint-disable-next-line max-len
  const [jobDescription, jobDescriptionLoading] = usePromise<JobDescriptionsItemModel>(() => getJobDescriptionById(numberId), !isNaN(numberId));

  const [units, setUnits] = useState<ISystemUnitModel[]>([]);
  const [positions, setPositions] = useState<IPositionsItemModel[]>([]);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wReferenceJobDescriptions);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cReferenceJobDescriptions);

  /** использовать все доступные предпрятия */
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const getPositions = useCallback(async (filter?: PositionsFilterType) => {
    const modelOrErrors = await getSystemPositions({ ...filter, 'per-page': maxValueToGetAllDataWithPagination });
    handleErrors(
      modelOrErrors,
      'get',
      () => {
        if (modelOrErrors instanceof PositionsModel) {
          setPositions(modelOrErrors.data);
        }
      },
    );
  }, []);

  const getUnits = useCallback(async (filter?: UnitsFilterType) => {
    const modelOrErrors = await getSystemUnits({ ...filter, 'per-page': maxValueToGetAllDataWithPagination });
    handleErrors(
      modelOrErrors,
      'get',
      () => {
        if (modelOrErrors instanceof SystemUnitsModel) {
          setUnits(modelOrErrors.data);
        }
      },
    );
  }, []);

  if (jobDescriptionLoading) {
    return <Loader />;
  }

  return (
    <JobDescriptionsFormComponent
      units={units}
      enterprises={enterprises}
      positions={positions}
      jobDescription={jobDescription}
      canUpdate={canUpdate}
      canCreate={canCreate}
      getPositions={getPositions}
      getUnits={getUnits}
    />
  );
};
