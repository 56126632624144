import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FileUploaderBase, FileUploaderBaseProps, FileUploaderBaseRenderProps } from './FileUploaderBase';
import { ReactComponent as FileTextIcon } from '../../../assets/img/file-text.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/img/trash.svg';
import { ReactComponent as AddFileIcon } from '../../../assets/img/plus-square.svg';


type UiFileUploaderType = FileUploaderBaseProps & {
  disableUpload?: boolean; // блокировать загрузку файлов или скрывать кнопку
};

/** загрузчик файлов для публичной части */
export const UiFileUploader = ({ disableUpload, ...props }: UiFileUploaderType) => (
  <FileUploaderBase
    {...props}
    render={({
      myFiles, removeFile, getRootProps, getInputProps, isSingleFile, accept, maxSizeFile, maxFilesCount,
    }: FileUploaderBaseRenderProps) => (
      <>
        {
          myFiles.map((f: File) => (
            <div className="list-element" key={f.name}>
              <div className="list-element_icon">
                <FileTextIcon />
              </div>
              <div className="list-element_content">
                <div className="line info">
                  <div className="item">{f.name}</div>
                </div>
              </div>
              <div className="list-element_icon remove">
                <span role="presentation" style={{ cursor: 'pointer' }} onClick={removeFile(f)}>
                  <RemoveIcon />
                </span>
              </div>
            </div>
          ))
        }
        {(disableUpload || myFiles.length >= maxFilesCount) ? null : (
          <>
            <div {...getRootProps({ className: 'list-element add' })} style={{ cursor: 'pointer' }}>
              <div className="list-element_icon">
                <AddFileIcon />
              </div>
              <div className="list-element_content">
                <div className="line info">
                  <input {...getInputProps()} />
                  <div className="item">
                    Прикрепить {isSingleFile ? 'файл' : 'файлы'}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 font-sm">
              Доступные форматы: {accept.join(', ')}<br />
              Максимальный размер {!isSingleFile && 'каждого '}файла: {maxSizeFile}MB.<br />
              {(!isSingleFile && props.maxFilesCount) && `Максимально: ${props.maxFilesCount} файлов.`}
            </div>
          </>
        )}
      </>
    )}
  />
);


/** загрузчик файлов для админки */
export const UiFileUploaderSystem = ({ ...props }: FileUploaderBaseProps) => (
  <FileUploaderBase
    {...props}
    render={({
      myFiles, removeFile, getRootProps, getInputProps, isSingleFile, accept, maxSizeFile, maxFilesCount,
    }: FileUploaderBaseRenderProps) => (
      <Row>
        <Col xs="12">
          <div {...getRootProps({ className: 'dropzone mb-3' })}>
            <input {...getInputProps()} />
            <Button type="button" color="primary" className="w-100" disabled={myFiles.length === maxFilesCount}>
              Прикрепить {isSingleFile ? 'файл' : 'файлы'}
            </Button>
          </div>
          {myFiles.length > 0 && (
            <div>
              <ul>
                {myFiles.map((f: File) => (
                  <li key={f.name}>
                    {props.withPreview && (
                      <div className="upload-file-preview">
                        <img src={URL.createObjectURL(f)} alt="" />
                      </div>
                    )}
                    {f.name}&nbsp;
                    <i role="presentation" className="fa fa-times text-danger cursor-pointer" onClick={removeFile(f)} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Col>
        <Col xs="12">
          Доступные форматы: {accept.join(', ')}<br />
          Максимальный размер {!isSingleFile && 'каждого '}файла: {maxSizeFile}MB.<br />
          {(!isSingleFile && props.maxFilesCount) && `Максимально: ${props.maxFilesCount} файлов.`}
        </Col>
      </Row>
    )}
  />
);
