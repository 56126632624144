import React, { useEffect } from 'react';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ParamTypes } from 'models/common';
import { EntityNotFound } from 'components/Errors/404';
import { usePromise } from 'utils/hooks/usePromise';
import { getPartnerById } from 'api/partner';
import { Loader } from 'components/common/UI/Loaders';
import { PartnersItemComponent } from 'components/Partners/item';


export const PartnersItem = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const [model, modelLoading, onUnmount] = usePromise(() => getPartnerById(numberId), !isNaN(numberId), [numberId]);
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  if (modelLoading) {
    return <Loader />;
  }
  if (isNaN(numberId) || model === null || model instanceof Error) {
    return <EntityNotFound message={t('partners.item.404')} />;
  }
  return (
    <PartnersItemComponent model={model} />
  );
};
