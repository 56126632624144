import { RootReducer } from 'store/reducers';
import { storeName } from './reducers';

export const systemPartnerCategoryListSelector = (state: RootReducer) => state.system[storeName].categoryList;
export const systemPartnerCategoryListLoadingSelector = (
  state: RootReducer,
) => state.system[storeName].categoryListIsLoading;

export const systemPartnerListSelector = (state: RootReducer) => state.system[storeName].partnersList;
export const systemPartnerListLoadingSelector = (state: RootReducer) => state.system[storeName].partnersListLoading;
