import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CustomTable } from '../../../../components/common/Table';
import { useDataPage } from '../../../../utils/hooks/useDataPage';
import { ICurrentUserModel, UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import {
  ISystemNewsCategoriesModel, ISystemNewsCategoryFilterType,
  ISystemNewsCategoryModel,
} from '../../../../models/References/NewsCategoriesModel';
import { SystemNewsCategoriesFilter } from './Filter';


type Props = {
  newsCategories: ISystemNewsCategoriesModel;
  newsCategoriesIsLoading: boolean;
  getData: (filter: ISystemNewsCategoryFilterType) => void;
  onDelete: (item: ISystemNewsCategoryModel) => void;
}

export const SystemNewsCategoriesComponent = ({
  newsCategories, newsCategoriesIsLoading, getData, onDelete,
}: Props) => {
  const {
    setLimit, setPage, onSubmitFilter, onResetFilter,
  } = useDataPage(newsCategories.page, newsCategories.limit, getData);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cReferenceNewsCategory);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dReferenceNewsCategory);

  return (
    <div>
      <Card>
        <CardBody>
          <h3>Список категорий новостей</h3>

          <SystemNewsCategoriesFilter onReset={onResetFilter} onSubmit={onSubmitFilter} />

          <CustomTable
            onChangePage={setPage}
            currentPage={newsCategories.page}
            sizePerPage={newsCategories.limit}
            amountOfPages={newsCategories.amountOfPages}
            setSizePerPage={[setLimit, setPage]}
            isLoading={newsCategoriesIsLoading}
            customButtons={canCreate && (
              <Button tag={Link} color="primary" to="newscategory/create">Создать категорию</Button>
            )}
            tableHeaders={[
              { name: 'ID' },
              { name: 'Название категории' },
              { name: 'Действия' },
            ]}
          >
            {newsCategories.data.map((item) => (
              <tr key={item.id}>
                <td>
                  <Link to={`newscategory/${item.id}`}>{item.id}</Link>
                </td>
                <td>
                  <Link to={`newscategory/${item.id}`}>{item.name}</Link>
                </td>
                <td>
                  {canDelete && (
                    <Button type="button" color="danger" onClick={() => onDelete(item)}>
                      {t('common.form.delete')}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </CustomTable>
        </CardBody>
      </Card>
    </div>
  );
};
