import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card, CardBody, CardHeader, Col, FormGroup, Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import { customHistory } from 'customHistory';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { UiSystemCheckbox } from '../../../components/common/UI/Checkbox';
import { INewsCategoryItemModel } from '../../../models/News/NewsCategoryModel';
import { convertModelToOptions, SelectOptionType } from '../../../utils/convertModelToOptions';
import { defaultImgExtensions } from '../../../config/system';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { UiSelect } from '../../../components/common/UI/Select';
import { UploadFiles } from '../../../models/UploadFiles/UploadFiles';
import { INewsCreate } from '../../../models/News/NewsCreate';
import { clearEmptyValues } from '../../../utils/objects';
import { INewsItemModel } from '../../../models/News/NewsItemModel';
import { createNews, updateNews } from '../../api/news';
import { ICurrentUserModel, UserAccessEnum } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';
import { UiFileUploaderSystem } from '../../../components/common/FileUploader';
import { UiSystemForm } from '../../../components/common/UI/Form';
import { UiWISWYGeditor } from '../../../components/common/UiWISWYGeditor';


/** utils */
/** сохранение новости */
const saveCreateNews = async (model: INewsCreate): Promise<boolean> => {
  const booleanOrError = await createNews(model);

  if (booleanOrError instanceof Error) {
    toast.error(i18next.t('common.form.errors.save'));
    return false;
  }
  toast.success(i18next.t('news.created'));
  customHistory.push('/system/news');
  return true;
};

/** редактирование новости */
const saveUpdateNews = async (id: number, model: INewsCreate): Promise<boolean> => {
  const booleanOrError = await updateNews(id, model);

  if (booleanOrError instanceof Error) {
    toast.error(i18next.t('common.form.errors.save'));
    return false;
  }
  toast.success(i18next.t('news.updated'));
  customHistory.push('/system/news');
  return true;
};


/** получить дефолтные значения формы. если есть данные модели, то взять из нее (редактирование) */
const getDefaultValuesForm = (
  categoryOptions: SelectOptionType[], enterpriseOptions: SelectOptionType[], newsModel?: INewsItemModel,
): FormValues => {
  if (newsModel) {
    /** если нет значения, значит выбран холдинг */
    const enterprisesFromModel = convertModelToOptions(newsModel.enterprises);
    return {
      ...newsModel,
      main_image: null,
      images: [],
      categories: convertModelToOptions(newsModel.categories),
      enterprises: enterprisesFromModel.length ? enterprisesFromModel : [enterpriseOptions[0]],
      send_notify: false,
    };
  }
  /** начальные значения при создании новости */
  return {
    title: '',
    short_description: '',
    content: '',
    main_image: null,
    images: [],
    is_active: true,
    categories: [categoryOptions[0]],
    enterprises: [enterpriseOptions[0]],
    ref_instagram: '',
    ref_twitter: '',
    ref_vk: '',
    ref_ok: '',
    ref_facebook: '',
    ref_other_name: '',
    ref_other: '',
    ref_video: '',
    ref_gallery: '',
    send_notify: false,
  };
};
/** end utils */


type FormValues = {
  title: string;
  short_description: string;
  content: string;
  main_image: null | File;
  images: File[];
  is_active: boolean;
  categories: SelectOptionType[];
  enterprises: SelectOptionType | SelectOptionType[];
  ref_instagram: string;
  ref_twitter: string;
  ref_vk: string;
  ref_ok: string;
  ref_facebook: string;
  ref_other_name: string;
  ref_other: string;
  ref_video: string;
  ref_gallery: string;
  send_notify: boolean;
}

type Props = {
  categories: INewsCategoryItemModel[];
  enterprises: IEnterpriseModel[];
  newsModel?: INewsItemModel;
}


export const SystemNewsFormComponent = ({ categories, enterprises, newsModel }: Props) => {
  const categoryOptions = convertModelToOptions(categories);
  const enterpriseOptions = convertModelToOptions(enterprises);

  /** холдинг = 0, = пустой массив enterprises при сохранении */
  enterpriseOptions.unshift({ label: 'Холдинг', value: 0 });

  const {
    register, handleSubmit, watch, errors, setValue, formState: { isValid, isSubmitting, dirtyFields }, getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: getDefaultValuesForm(categoryOptions, enterpriseOptions, newsModel),
  });

  useEffect(() => {
    /** регистрация полей, управляемых вручную */
    register('main_image');
    register('images');
  }, [register]);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wNews);

  const onSubmit = async (data: FormValues) => {
    if (!canUpdate) {
      return;
    }
    const newsToSave: INewsCreate = {
      ...clearEmptyValues(data) as INewsCreate,
      categories: data.categories.map((category) => category.value),
      enterprises: Array.isArray(data.enterprises) ?
        data.enterprises.map((enterprise) => enterprise.value).filter((enterprise) => enterprise) :
        [data.enterprises.value],
    };

    /** разобраться с main_image */
    if (data.main_image) {
      if (data.main_image.name !== newsModel?.main_image.name) {
        /** загрузить и обновить */
        const main_image = await new UploadFiles([data.main_image]).upload();

        if (main_image instanceof Error) {
          toast.error(i18next.t('common.form.errors.save'));
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        newsToSave.main_image = main_image[0].file_name;
      } else {
        newsToSave.main_image = newsModel?.main_image.name;
      }
    } else {
      delete newsToSave.main_image;
    }

    /** разобраться с images */
    if (data.images) {
      /** найти только новые файлы + проверка файлов в существущей модели */
      const imagesToLoad = data.images ? data.images
        .filter(
          (image) => (newsModel ? newsModel.images.findIndex((img) => img.name === image.name) === -1 : true),
        ) : [];

      /** есть файлы для загрузки - загрузить и вставить ссылки на них */
      if (imagesToLoad.length) {
        const images = await new UploadFiles(data.images).upload();

        if (images instanceof Error) {
          toast.error(i18next.t('common.form.errors.save'));
          return;
        }
        newsToSave.images = images.map((f) => f.file_name);
      } else if (newsModel) {
        /** файлы не тронуты - взять из формы (не из модели, могут быть изменения в файлах) */
        newsToSave.images = data.images.map((img) => img.name);
      }
    } else {
      /** нет в форме - удалить на всякий случай. может они были и удалены */
      delete newsToSave.images;
    }
    /** в конце проверить - если пустой массив, то удалить его */
    if (!newsToSave.images?.length) {
      delete newsToSave.images;
    }

    if (newsModel) {
      /** редактирование */
      await saveUpdateNews(newsModel?.id, newsToSave);
    } else {
      /** создание */
      await saveCreateNews(newsToSave);
    }
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={!newsModel}
    >
      <h3>{newsModel ? 'Редактирование' : 'Создание'} новости</h3>

      <UiSystemInput
        errors={errors}
        register={register}
        name="title"
        label="Заголовок"
        required
      />

      <UiSystemInput
        errors={errors}
        register={register}
        name="short_description"
        label="Краткое описание"
        type="textarea"
        required
      />

      <FormGroup>
        <strong>
          Полное описание
        </strong>
        <UiWISWYGeditor register={register} watch={watch} setValue={setValue} name="content" />
      </FormGroup>

      <UiSelect
        defaultValue={getValues('categories')}
        errors={errors}
        register={register}
        name="categories"
        label="Рубрики"
        setValue={setValue}
        options={categoryOptions}
        multiple
        required
      />

      <UiSystemCheckbox
        register={register}
        name="is_active"
        label="Отображение новости в портале *"
      />

      <UiSystemCheckbox
        register={register}
        name="send_notify"
        label="Отправить оповещение"
      />

      {/** Дата и время новости - раз текущие, то выбор не нужен? */}

      <UiSelect
        defaultValue={getValues('enterprises')}
        errors={errors}
        register={register}
        name="enterprises"
        label="Предприятие"
        setValue={setValue}
        options={enterpriseOptions}
        multiple
      />

      <FormGroup>
        <strong>
          Главное изображение новости
        </strong>
        <UiFileUploaderSystem
          initFileTypes={newsModel ? [newsModel.main_image] : []}
          maxFilesCount={1}
          onChange={(files: File[]) => setValue('main_image', files[0])}
          accept={defaultImgExtensions}
        />
      </FormGroup>

      <FormGroup>
        <strong>
          Изображения новости
        </strong>
        <UiFileUploaderSystem
          initFileTypes={newsModel ? newsModel.images : []}
          onChange={(files: File[]) => setValue('images', files)}
          accept={defaultImgExtensions}
          maxFilesCount={5}
        />
      </FormGroup>

      <Card>
        <CardHeader>
          Ссылка на новость в социальных сетях
        </CardHeader>
        <CardBody>
          {[
            { name: 'ref_instagram', label: 'Instagram' },
            { name: 'ref_twitter', label: 'Twitter' },
            { name: 'ref_vk', label: 'Вконтакте' },
            { name: 'ref_ok', label: 'Одноклассники' },
            { name: 'ref_facebook', label: 'Facebook' },
          ].map((refItem) => (
            <UiSystemInput
              key={refItem.name}
              errors={errors}
              register={register}
              name={refItem.name}
              label={refItem.label}
            />
          ))}
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          Ссылка на сторонний ресурс
        </CardHeader>
        <CardBody>
          <Row form>
            <Col xs={6}>
              <UiSystemInput
                errors={errors}
                register={register}
                name="ref_other_name"
                label="Название ресурса"
                maxLength={255}
              />
            </Col>
            <Col xs={6}>
              <UiSystemInput
                errors={errors}
                register={register}
                name="ref_other"
                label="Ссылка"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <UiSystemInput
        errors={errors}
        register={register}
        name="ref_video"
        label="Ссылка на видео (YouTube)"
      />

      <UiSystemInput
        errors={errors}
        register={register}
        name="ref_gallery"
        label="Ссылка на фото-обзор"
      />
    </UiSystemForm>
  );
};
