import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import i18next from 'i18next';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { IApplicationResponsibleFilterType } from '../../../models/Application/Responsible/interfaces';
import { IDepartmentItemModel } from '../../../models/References/DepartmentModel';
import { IUserList } from '../../../models/Users/interfaces';
import { UsersFilterType } from '../../../models/Users/UsersModel';
import { convertModelToOptions, SimpleSelectOptionType } from '../../../utils/convertModelToOptions';
import { emptyOption, UiSelect } from '../../../components/common/UI/Select';
import { UiToolbar } from '../../../components/common/Toolbar';
import { getSettingResponsibleTypeSelect } from '../../../models/Application/Responsible/enums';

type Props = {
  enterprises: IEnterpriseModel[];
  departments: IDepartmentItemModel[];
  users: IUserList[];
  onSubmit: (data: IApplicationResponsibleFilterType) => void;
  onReset: () => void;
  getUsers: (filter: UsersFilterType) => void;
}

type FormValues = {
  enterprise: SimpleSelectOptionType<number>;
  user: SimpleSelectOptionType<number>;
  department: SimpleSelectOptionType<number>;
  type: SimpleSelectOptionType<string>;
}

export const SystemFeedbackResponsibleFilter = ({
  enterprises,
  departments,
  users,
  onSubmit,
  onReset,
  getUsers,
}: Props) => {
  const {
    register, handleSubmit, watch, setValue, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise: emptyOption(),
      user: emptyOption(),
      department: emptyOption(),
      type: { label: i18next.t('ui.select.select_placeholder'), value: '' },
    },
  });

  const { t } = useTranslation();

  const enterpriseWatch = watch().enterprise;
  const userList = enterpriseWatch.value > -1 ? users.map((value) => ({
    label: value.fio,
    value: value.id,
  })) : [];

  useEffect(() => {
    const enterpriseId = enterpriseWatch.value;
    if (enterpriseId > -1) {
      getUsers({ enterprise: [enterpriseId] });
    }
    // eslint-disable-next-line
  }, [enterpriseWatch]);

  const onLocalSubmit = useCallback(({
    enterprise, department, user, type,
  }: FormValues) => {
    onSubmit({
      enterprise_id: enterprise.value > -1 ? enterprise.value : undefined,
      department_id: department.value > -1 ? department.value : undefined,
      user_id: user.value > -1 ? user.value : undefined,
      type: type.value.length > 0 ? type.value : undefined,
    });
  }, [onSubmit]);

  const onLocalReset = useCallback(() => {
    reset();
    onReset();
  }, [onReset, reset]);

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSelect
            label={t('common.type')}
            register={register}
            setValue={setValue}
            name="type"
            options={getSettingResponsibleTypeSelect()}
            allowEmptyValue
            value={watch().type}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            label={t('common.enterprise')}
            register={register}
            setValue={setValue}
            name="enterprise"
            options={convertModelToOptions(enterprises)}
            allowEmptyValue
            value={enterpriseWatch}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            label={t('common.department')}
            register={register}
            setValue={setValue}
            name="department"
            options={convertModelToOptions(departments)}
            allowEmptyValue
            value={watch().department}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            label={t('common.user')}
            register={register}
            setValue={setValue}
            name="user"
            options={userList}
            allowEmptyValue
            value={watch().user}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
