import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';


const getNews = (state: RootReducer) => state.system[storeName].news;
const getNewsIsLoading = (state: RootReducer) => state.system[storeName].newsIsLoading;


export const systemNewsSelector = createSelector([getNews], returnParams);
export const systemNewsIsLoadingSelector = createSelector([getNewsIsLoading], returnParams);
