import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';

import { UiSystemInput } from '../../../../components/common/UI/Input';
import { UiToolbar } from '../../../../components/common/Toolbar';
import { SportFilterType } from '../../../../models/Sport/SportModel';


type Props = {
  onSubmit: (filter: SportFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  name: string;
}

export const SystemSportsFilter = ({ onSubmit, onReset }: Props) => {
  const { register, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });

  return (
    <UiToolbar onSubmitForm={handleSubmit(onSubmit)} onResetForm={onReset}>
      <Row>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label="Название спорта"
            name="name"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
