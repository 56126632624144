import { UserAccessEnum } from 'models/User/CurrentUserModel';
import { createSelector } from 'reselect';
import { RootReducer } from '../reducers';
import { storeName } from './reducer';
import { returnParams } from '../selectors';

const getCurrentUser = (state: RootReducer) => state[storeName].currentUser;
const getCurrentUserIsLoading = (state: RootReducer) => state[storeName].currentUserIsLoading;
const getCurrentUserProfile = (state: RootReducer) => state[storeName].currentUser.profile;
const getCurrentUserProfileIsLoading = (state: RootReducer) => state[storeName].currentUserProfileIsLoading;
const getCurrentUserUpdateIsLoading = (state: RootReducer) => state[storeName].currentUserUpdateIsLoading;
const getCurrentUserRights = (state: RootReducer) => state[storeName].currentUser.rights;

export const getCurrentUserRightsSelector = createSelector(
  [getCurrentUserRights],
  (right) => right
    .map((right) => right.permissions
      .map((permission) => permission.name))
    .flat(),
);

export const hasRight = (right: UserAccessEnum) => (state: RootReducer): boolean =>
  // eslint-disable-next-line implicit-arrow-linebreak
  !!getCurrentUserRights(state)?.[0]?.permissions?.find((item) => item.name === right);

export const getIsCurrentUserExists = (state: RootReducer): boolean => !!(state[storeName].currentUser.id &&
  !state[storeName].currentUserIsLoading);

export const getCurrentUserSelector = createSelector(
  [getCurrentUser],
  returnParams,
);
export const getCurrentUserIsLoadingSelector = createSelector([getCurrentUserIsLoading], returnParams);
export const getCurrentUserProfileSelector = createSelector([getCurrentUserProfile], returnParams);
export const getCurrentUserProfileIsLoadingSelector = createSelector([getCurrentUserProfileIsLoading], returnParams);
export const getCurrentUserUpdateIsLoadingSelector = createSelector([getCurrentUserUpdateIsLoading], returnParams);

export const getCurrentUserEnterpriseIdsSelector = createSelector(
  [getCurrentUser],
  (user) => user.enterprises.map((item) => item.id),
);
export const getCurrentUserEnterprisesSelector = createSelector(
  [getCurrentUser],
  (user) => user.enterprises,
);
export const getCurrentUserUnitsSelector = createSelector(
  [getCurrentUser],
  (user) => user.userUnits,
);

/** возвращает boolean в зависимости от того, авторизован ли пользователь (данные о нем корректно получены) */
export const getIsCurrentUserExistsSelector = createSelector([getIsCurrentUserExists], returnParams);
