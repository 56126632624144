import {
  SYSTEM_REFERENCES_GET_BUS_STOPS_FAIL,
  SYSTEM_REFERENCES_GET_BUS_STOPS_OK,
  SYSTEM_REFERENCES_GET_BUS_STOPS_START,
} from './constants';
import { SystemBusStopsModel } from '../../../../models/References/BusStopsModel';
import { dataAction, dataErrorAction, dataSuccessAction } from '../../../../store/actions';

export const systemRefGetBusStopsStartAction = (filter = {}) => dataAction(
  SYSTEM_REFERENCES_GET_BUS_STOPS_START,
)({ filter });
export const systemRefGetBusStopsOkAction = (busStops: SystemBusStopsModel) => dataSuccessAction(
  SYSTEM_REFERENCES_GET_BUS_STOPS_OK,
)({ busStops });
export const systemRefGetBusStopsFailAction = dataErrorAction(SYSTEM_REFERENCES_GET_BUS_STOPS_FAIL);
