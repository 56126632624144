import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { reducers } from '../store/reducers';
import { rootSagas } from '../store/sagas';


/** динамически инжектить саги */
function createSagaInjector(runSaga: any, rootSaga: any) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = (key: any) => injectedSagas.has(key);

  const injectSaga = (key: any, saga: any) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return;

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    // TODO удаление саги
    injectedSagas.set(key, task);
  };

  // Inject the root saga as it a staticlly loaded file,
  injectSaga('root', rootSaga);

  return injectSaga;
}

/** теперь стор имеет немного другой тип */
export type CustomStoreType = Store & {injectSaga: (moduleName: string, saga: () => Generator) => void};


export const configureStore = (history: any) => {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      history,
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.error(err);
    },
  });

  const composeEnhancers = (...args: any[]): any => {
    const compose = composeWithDevTools({
      name: 'sds-devtools',
      trace: true,
    });

    return compose(...args);
  };

  const enhancer = composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
    ),
  );

  const store: CustomStoreType = createStore(reducers, enhancer);

  store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSagas);

  return store;
};
