import { createSelector } from 'reselect';
import { RootReducer } from '../../../../store/reducers';
import { storeName } from './reducer';
import { returnParams } from '../../../../store/selectors';

const getJobDescriptions = (state: RootReducer) => state.system[storeName].jobDescriptions;
const getJobDescriptionsIsLoading = (state: RootReducer) => state.system[storeName].jobDescriptionsIsLoading;
const getJobDescriptionsItemById = (id: number) => (state: RootReducer) => state.system[storeName].jobDescriptions.data
  .find((description) => description.id === id);

export const getJobDescriptionsSelector = createSelector([getJobDescriptions], returnParams);
export const getJobDescriptionsIsLoadingSelector = createSelector([getJobDescriptionsIsLoading], returnParams);
export const getJobDescriptionsItemSelector = (id: number) => createSelector(
  [getJobDescriptionsItemById(id)],
  returnParams,
);
