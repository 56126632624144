import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { UiSystemInput } from 'components/common/UI/Input';
import { UiToolbar } from 'components/common/Toolbar';
import { emptyOption, emptyOptionValue, UiSelect } from 'components/common/UI/Select';
import { IApplicationStatusesModel } from 'models/Application/Status/interfaces';
import { convertModelToOptions, SelectOptionType, SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { IAllApplicationFilter } from 'models/Application/interfaces';
import { IApplicationTypesModel } from 'models/Application/Type/interfaces';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { useSystemUsers } from 'utils/hooks/useSystemUsers';
import {
  systemApplicationStatusesSelector,
  systemApplicationTypesSelector,
} from '../../containers/Application/selectors';
import { systemEnterprisesSelector } from '../../store/selectors';


type Props = {
  onSubmit: (data: IAllApplicationFilter) => void;
  onReset: () => void;

  showAssign?: boolean; // показывать ли фильтр по ответственному
}

type FormValues = {
  enterprise_id: SelectOptionType<number>[];
  status_id: SelectOptionType<number>;
  type_id: SelectOptionType<number>;
  search: string;
  assign_id: SelectOptionType<number> | null;
  colleague: SelectOptionType<number> | null;
}

const showApplicationsAssignedToColleaguesOptions = [
  {
    label: 'Да',
    value: '1',
  },
  {
    label: 'Нет',
    value: '0',
  },
];

const MIN_CHARACTERS = 3;

export const SystemAssignApplicationsFilter = ({ onSubmit, onReset, showAssign = false }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, setValue, errors, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise_id: [],
      status_id: emptyOption(),
      type_id: emptyOption(),
      search: '',
      assign_id: null,
      colleague: null,
    },
  });

  const selectedEnterprise = watch('enterprise_id');

  const statuses: IApplicationStatusesModel = useSelector(systemApplicationStatusesSelector);
  const types: IApplicationTypesModel = useSelector(systemApplicationTypesSelector);
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const { getData: getUsers, loading: usersLoading, options: usersOptions } = useSystemUsers();
  const debouncedLoadUsers = useCallback(
    () => debounce((name: string) => {
      if (name.length >= MIN_CHARACTERS) {
        getUsers({ name });
      }
    }, 700),
    [getUsers],
  );

  const onLocalSubmit = (data: FormValues) => onSubmit({
    enterprise_id: data.enterprise_id.map((ent: SimpleSelectOptionType<number>) => ent.value),
    status_id: data.status_id.value === emptyOptionValue ? undefined : data.status_id.value,
    type_id: data.type_id.value === emptyOptionValue ? undefined : data.type_id.value,
    search: data.search.length ? data.search : undefined,
    assign_id: data.assign_id ? data.assign_id.value : undefined,
    colleague: data.colleague ? data.colleague.value : undefined,
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const statusOptions = convertModelToOptions(statuses);
  const typesOptions = convertModelToOptions(types);
  const enterprisesOptions = convertModelToOptions(enterprises);

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="enterprise_id"
            errors={errors}
            options={enterprisesOptions}
            value={selectedEnterprise}
            label={t('applications.filter.enterprises')}
            multiple
            allowEmptyValue
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="status_id"
            errors={errors}
            options={statusOptions}
            value={watch('status_id')}
            label={t('applications.filter.status')}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="type_id"
            errors={errors}
            options={typesOptions}
            value={watch('type_id')}
            label={t('applications.filter.type')}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('applications.filter.search')}
            name="search"
            register={register}
          />
        </Col>
        {showAssign && (
          <>
            <Col xs="12" sm="6" md="3">
              <UiSelect
                register={register}
                setValue={setValue}
                name="assign_id"
                errors={errors}
                options={usersOptions}
                value={watch('assign_id')}
                label={t('application.assign')}
                allowEmptyValue
                isLoading={usersLoading}
                loadOptions={debouncedLoadUsers()}
                noOptionsMessage={
                  ({ inputValue }) => (
                    inputValue.length < MIN_CHARACTERS ?
                      t('common.select.enterCharacters') :
                      t('common.select.no_options')
                  )
                }
              />
            </Col>
            <Col xs="12" sm="6" md="3">
              <UiSelect
                register={register}
                setValue={setValue}
                name="colleague"
                errors={errors}
                allowEmptyValue
                options={showApplicationsAssignedToColleaguesOptions}
                value={watch('colleague')}
                label={t('application.filter.colleague')}
              />
            </Col>
          </>
        )}
      </Row>
    </UiToolbar>
  );
};
