import { AnyAction } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import { IBusStopsModel, BusStopsModel } from '../../models/BusStops/BusStops';
import {
  REMOVE_BUS_STOP_FAVORITE_FAIL,
  REMOVE_BUS_STOP_FAVORITE_OK,
  REMOVE_BUS_STOP_FAVORITE_START,
  SET_BUS_STOP_FAVORITE_FAIL,
  SET_BUS_STOP_FAVORITE_OK,
  SET_BUS_STOP_FAVORITE_START,
  GET_BUS_STOP_LIST_FAIL,
  GET_BUS_STOP_LIST_OK,
  GET_BUS_STOP_LIST_START,
} from './constants';
import { onLoading, onFail, onLoad } from '../../utils/reducer';

export type BusStopsReducerType = {
  busStops: IBusStopsModel;
  busStopsIsLoading: boolean;
}

const initialState: BusStopsReducerType = {
  busStops: new BusStopsModel(),
  busStopsIsLoading: true,
};

export const storeName = 'busStops';

export const busStopsReducer = {
  [storeName]: (state: BusStopsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case GET_BUS_STOP_LIST_START:
        return onLoading(state, 'busStops');
      case GET_BUS_STOP_LIST_OK: {
        if (payload.append) {
          const stateBusStopsData = cloneDeep(state.busStops.data);

          const stateBusStops = {
            ...payload.busStops,
            data: stateBusStopsData.concat(payload.busStops.data),
          };

          return {
            ...state,
            busStops: stateBusStops,
            busStopsIsLoading: false,
          };
        }
        return onLoad(state, payload, 'busStops');
      }
      case GET_BUS_STOP_LIST_FAIL:
        return onFail(state, 'busStops');

      case SET_BUS_STOP_FAVORITE_START:
        return onLoading(state, 'busStops');
      case SET_BUS_STOP_FAVORITE_OK: {
        const stateBusStopsData = cloneDeep(state.busStops.data);
        const indexBusStopItem = stateBusStopsData.findIndex((item) => item.id === payload.id);
        if (indexBusStopItem > -1) {
          stateBusStopsData[indexBusStopItem].favorite = true;
        }

        const stateBusStops = {
          ...payload.busStops,
          data: stateBusStopsData,
        };

        return {
          ...state,
          busStops: stateBusStops,
          busStopsIsLoading: false,
        };
      }
      case SET_BUS_STOP_FAVORITE_FAIL:
        return onFail(state, 'busStops');

      case REMOVE_BUS_STOP_FAVORITE_START:
        return onLoading(state, 'busStops');
      case REMOVE_BUS_STOP_FAVORITE_OK: {
        const stateBusStopsData = cloneDeep(state.busStops.data);
        const indexBusStopItem = stateBusStopsData.findIndex((item) => item.id === payload.id);
        stateBusStopsData[indexBusStopItem].favorite = false;

        const stateBusStops = {
          ...payload.busStops,
          data: stateBusStopsData,
        };

        return {
          ...state,
          busStops: stateBusStops,
          busStopsIsLoading: false,
        };
      }
      case REMOVE_BUS_STOP_FAVORITE_FAIL:
        return onFail(state, 'busStops');
      default:
        return state;
    }
  },
};
