import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import {
  IApplicationTemplateListFilter,
} from '../../../models/ConstructApplication/interfaces';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { convertModelToOptions, SelectOptionType } from '../../../utils/convertModelToOptions';
import { UiToolbar } from '../../../components/common/Toolbar';
import { UiSelect } from '../../../components/common/UI/Select';
import { UiSystemInput } from '../../../components/common/UI/Input';

type Props = {
  onSubmit: (data: IApplicationTemplateListFilter) => void;
  onReset: () => void;
  enterpriseList: IEnterpriseModel[];
}

type FormValues = {
  name: string;
  enterprise_id: SelectOptionType<number> | null;
}

export const SystemApplicationTemplateListFilter = ({ onSubmit, onReset, enterpriseList }: Props) => {
  const { t } = useTranslation();

  const enterprisesOptions = useMemo(() => [
    ...convertModelToOptions(enterpriseList),
  ], [enterpriseList]);

  const {
    register, handleSubmit, setValue, errors, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      enterprise_id: null,
    },
  });

  const onLocalSubmit = (data: FormValues) => onSubmit({
    name: data.name.length ? data.name : undefined,
    enterprise_id: data.enterprise_id?.value,
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('system.construct_application.template.name')}
            name="name"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="enterprise_id"
            errors={errors}
            options={enterprisesOptions}
            value={watch('enterprise_id')}
            label={t('system.construct_application.type.enterprise')}
            allowEmptyValue
            isClearable
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
