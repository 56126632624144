import React from 'react';
import { ReactComponent as BusIcon } from '../../assets/img/bus.svg';

type Props = {
  route: {
    id: number;
    name: string;
    times: string[];
  };
}

export const BusStopTimetableItem = ({ route }: Props) => (
  <div className="list-element">
    <div className="list-element_icon">
      <BusIcon />
    </div>
    <div className="list-element_content">
      <div className="line title">{route.name}</div>
      <div className="line info left">
        {route.times.length ?
          route.times.map((time) => <div key={time} className="item">{time}</div>) :
          <div className="item">В этот день транспорта нет</div>
        }
      </div>
    </div>
  </div>
);
