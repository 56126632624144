import { put, takeLatest } from 'redux-saga/effects';
import { GLOBAL_GET_UNREAD_INFO_START } from './constants';
import { baseSaga } from './baseSaga';
import { httpGet } from '../utils/http';
import { globalGetUnreadInfoFailAction, globalGetUnreadInfoOkAction } from './actions';


/** кол-во всяких непрочитанностей. уведомления, опросы */
export function* globalGetUnreadInfoSaga() {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/notifications/unread-notify-polls');

    yield put(globalGetUnreadInfoOkAction(response));
  }, globalGetUnreadInfoFailAction);
}


export function* globalSagas() {
  yield takeLatest(GLOBAL_GET_UNREAD_INFO_START, globalGetUnreadInfoSaga);
}
