import { baseApiFn } from 'utils/baseApiFn';
import { EnterpriseSettingsModel, IEnterpriseSettingsModel } from 'models/Settings/EnterpriseSettingsModel';
import { httpGet, httpPut } from 'utils/http';

// Настройка предприятия
export const getSystemEnterpriseSettings = async (id: number, code?: string) => baseApiFn<IEnterpriseSettingsModel>(
  async () => {
    const response = await httpGet(`/system/setting/enterprise?enterprise_id=${id}&code=${code}`);
    return new EnterpriseSettingsModel(response?.data?.[0]);
  },
);

// Обновление настройки предприятия
export const updateSystemEnterpriseSettings = async (id: number, value: number) => baseApiFn<IEnterpriseSettingsModel>(
  async () => {
    const response = await httpPut(`/system/setting/enterprise/${id}`, { value });
    return new EnterpriseSettingsModel(response);
  },
);
