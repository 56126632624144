import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import { isProductionBuild } from 'utils/env';


export const YandexMetrika = () => (isProductionBuild ? (
  <YMInitializer
    accounts={[67606045]}
    options={{
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    }}
    version="2"
  />
) : null);
