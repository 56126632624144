import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IPrivilegeList, IPrivilegeListItem, PrivilegeListFilterType } from '../../../models/Privilege/interfaces';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { ICurrentUserModel, UserAccessEnum } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';
import { CustomTable } from '../../../components/common/Table';
import { handleErrors } from '../../../utils/errors';
import { deleteSystemPrivilegeItem } from '../../../api/privilege';


type Props = {
  data: IPrivilegeList;
  isLoading: boolean;
  getData: (filter: PrivilegeListFilterType) => void;
}

export const SystemPrivilegesListComponent = ({
  data,
  isLoading,
  getData,
}: Props) => {
  const {
    setLimit, setPage,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cPrivileges);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dPrivileges);

  const onDelete = ({ id, name }: IPrivilegeListItem) => async () => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(
      t('common.form.delete.question', { entity: 'льготу', entityName: name }),
    )) {
      handleErrors(
        await deleteSystemPrivilegeItem(id),
        'delete',
        () => {
          toast.success(t('system.privilege.deleted'));
          getData({});
        },
      );
    }
  };

  return (
    <>
      <h3>Список льгот</h3>

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="priveleges/create">Создать льготу</Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: t('privilege.item.name') },
          { name: t('privilege.item.enterprises') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item: IPrivilegeListItem) => (
          <tr key={item.id}>
            <td>
              <Link to={`priveleges/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`priveleges/${item.id}`}>{item.name}</Link>
            </td>
            <td>
              {item.enterprisesNames}
            </td>
            <td>
              {canDelete && (
                <Button type="button" color="danger" onClick={onDelete(item)}>
                  {t('common.form.delete')}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
