import React, { useCallback } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DateRange } from '../../../components/common/Dates/DateRange';
import { UiToolbar } from '../../../components/common/Toolbar';
import { ISystemMedicalFilterType } from '../../../models/Medical/SystemMedicalModel';
import { CDate } from '../../../utils/CDate';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { ISystemUnitModel } from '../../../models/References/UnitsModel';
import { emptyOption, UiSelect } from '../../../components/common/UI/Select';
import { convertModelToOptions, SimpleSelectOptionType } from '../../../utils/convertModelToOptions';

type Props = {
  onSubmit: (data: ISystemMedicalFilterType) => void;
  onReset: () => void;
  enterpriseList: IEnterpriseModel[];
  unitList: ISystemUnitModel[];
}

type FormValues = {
  date_from: Date | null;
  date_to: Date | null;
  enterprise: SimpleSelectOptionType<number>;
  units: SimpleSelectOptionType[];
}

const rangeNames = ['date_from', 'date_to'];

export const SystemMedicalFilter = ({
  onSubmit, onReset, enterpriseList, unitList,
}: Props) => {
  const {
    register, handleSubmit, watch, setValue, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      date_from: null,
      date_to: null,
      enterprise: emptyOption(),
      units: [],
    },
  });

  const { t } = useTranslation();

  const onLocalSubmit = useCallback(({
    date_from, date_to, enterprise, units,
  }: FormValues) => {
    onSubmit({
      date_from: date_from ? CDate.format(date_from, 'yyyy-MM-dd') : undefined,
      date_to: date_to ? CDate.format(date_to, 'yyyy-MM-dd') : undefined,
      enterprise_id: enterprise.value > -1 ? enterprise.value : undefined,
      units_ids: units ? units.map((unit: SimpleSelectOptionType<number>) => unit.value) : undefined,
    });
  }, [onSubmit]);

  const onLocalReset = useCallback(() => {
    reset();
    onReset();
  }, [onReset, reset]);

  const enterpriseWatch = watch('enterprise');
  const filteredUnitList = enterpriseWatch ?
    unitList.filter((unit) => enterpriseWatch.value === unit.enterprise.id) :
    [];

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset} initialOpen>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="4">
          <DateRange
            names={rangeNames}
            label={t('medical.dates')}
            register={register}
            watch={watch}
            setValue={setValue}
            className="system-formgroup"
            system
          />
        </Col>
        <Col xs="12" sm="6" md="4">
          <UiSelect
            label={t('common.enterprise')}
            register={register}
            setValue={setValue}
            name="enterprise"
            options={convertModelToOptions(enterpriseList)}
            allowEmptyValue
            value={enterpriseWatch}
          />
        </Col>
        <Col xs="12" sm="6" md="4">
          <UiSelect
            label={t('common.units')}
            register={register}
            setValue={setValue}
            name="units"
            options={convertModelToOptions(filteredUnitList)}
            multiple
            allowEmptyValue
            value={watch().units}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
