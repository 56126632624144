import React, { memo } from 'react';
import { Link, useHistory } from 'react-router-dom';

type Props = {
  icon: React.ReactElement;
  label: string | React.ReactElement;
  path: string;
  countEvents?: number;
  className?: string;
  exact?: boolean;
  guideStep?: string;
}

/**
 * пункты меню очень похожи. вынесены в один компонент
 */
export const MenuItem = memo(({
  icon, label, path, className = '', countEvents = 0, exact = true, guideStep,
}: Props) => {
  const { location: { pathname } } = useHistory();
  const classNames = ['item'];
  if (exact ? pathname === path : pathname.startsWith(path)) {
    classNames.push('active');
  }
  if (className) {
    classNames.push(className);
  }
  if (guideStep) {
    classNames.push(guideStep);
  }

  return (
    <Link to={path} className={classNames.join(' ')}>
      <i className="icon">
        {icon}
      </i>
      <span className="text">{label}</span>
      {countEvents > 0 && <span className="counter">{countEvents}</span>}
    </Link>
  );
});
