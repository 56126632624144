import { emptyOptionValue } from 'components/common/UI/Select';
import { ConstructApplicationTypeFormFieldsType } from 'models/ConstructApplication/interfaces';
import { FormBlockType } from 'systemModule/components/ConstructApplication/Type/common';


export const prepareTypeFieldBlock = (block: FormBlockType): ConstructApplicationTypeFormFieldsType => ({
  name: block.name,
  has_template: typeof block.generate === 'string' ? block.generate === 'true' : block.generate,
  template_id: (!block.template_id || +block.template_id === emptyOptionValue) ? null : +block.template_id,
  file: block.file,
  fields: block.fields.filter((field) => field).map((field) => ({
    name: field.name,
    type: field.type,
    required: Boolean(field.required),
    template_mark: field.template_mark,
    list: field.list ? field.list.split(';').filter((val) => val.length) : undefined,
  })),
});
