import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { onInnerLinkOpen } from 'utils/outerFunctions';


type Props = {
  link: string;
  onClick: () => void;

  color?: 'primary' | 'success';
  className?: string;
}

/**
 * вывод ссылки уведомления
 * внешние ссылки сделать через обычный тег a, иначе Link попытается / добавить
 */
export const NotificationLink = memo(({
  link, onClick, color = 'primary', className,
}: Props) => {
  const { t } = useTranslation();

  /** является ли ссылка внутренней */
  const isInnerLink = /^http(s)?:/.exec(link) === null;

  /**
   * попытка вызвать открытие системного браузера(мобильное приложение) или открыть в новой вкладке
   */
  const onOuterLinkClick = () => {
    onClick();
    onInnerLinkOpen(link);
  };

  return (
    <Button
      tag={isInnerLink ? Link : undefined}
      color={color}
      className={className}
      to={link}
      onClick={isInnerLink ? onClick : onOuterLinkClick}
    >
      {t('notifications.link_text')}
    </Button>
  );
});
