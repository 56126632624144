import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { DefaultFilterType } from '../../../models/common';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { ISystemPollsItemModel, ISystemPollsModel } from '../../../models/Polls/SystemPollsModel';
import { CustomTable } from '../../../components/common/Table';
import { CDate } from '../../../utils/CDate';
import { IPollsStatusModel } from '../../../models/Polls/PollsStatusesModel';
import { SystemPollsFilter } from './Filter';
import { PollActions } from './PollActions';

type Props = {
  getData: (filter: DefaultFilterType) => void;
  data: ISystemPollsModel;
  isLoading: boolean;
  allPolls: boolean;
  pollStatuses: IPollsStatusModel[];
  report: boolean; // вывод как статистика
}

export const SystemPollsComponent = ({
  getData, data, isLoading, allPolls, pollStatuses, report,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter, reloadData,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const getStatus = (item: ISystemPollsItemModel): string => {
    const found = pollStatuses.find((status: IPollsStatusModel) => status.key === item.status);
    return (found) ? found.value : '';
  };

  const tableHeaders = [
    { name: 'ID' },
    { name: t('poll.title') },
    { name: t('poll.created_at') },
    { name: t('poll.ended_at') },
    { name: t('poll.status') },
    { name: t('poll.voterCount') },
  ];
  if (!report) {
    tableHeaders.push({ name: t('common.table.actions') });
  }

  return (
    <>
      {report ? (
        <h3>{t('reports.polls')}</h3>
      ) : (
        <h3>{allPolls ? t('polls.all_polls') : t('polls.my_polls')}</h3>
      )}

      <SystemPollsFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={!report && (
          <Button tag={Link} color="primary" to="/system/polls/create">{t('poll.create')}</Button>
        )}
        tableHeaders={tableHeaders}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={`/system/polls/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`/system/polls/${item.id}`}>{item.title}</Link>
            </td>
            <td>
              {CDate.format(item.created_at, 'dd MMMM yyyy')}
            </td>
            <td>
              {item.ended_at ? CDate.format(item.ended_at, 'dd MMMM yyyy') : ''}
            </td>
            <td>
              {getStatus(item)}
            </td>
            <td>
              {item.voterCount}
            </td>
            {!report && (
              <td>
                <div className="space-between-items poll-table-actions">
                  <PollActions model={item} voterCount={item.voterCount} onPollActionCompleted={reloadData} />
                </div>
              </td>
            )}
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
