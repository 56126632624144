import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import { useTranslation } from 'react-i18next';
import isNaN from 'lodash/isNaN';
import invariant from 'invariant';

import { ParamTypes } from 'models/common';
import { usePromise } from '../../../utils/hooks/usePromise';
import { getApplicationByType } from '../../../api/application';
import { projectBaseUrl } from '../../../utils/env';
import { Loader } from '../../../components/common/UI/Loaders';
import { ApplicationModel } from '../../../models/Application/ApplicationModel';
import { EntityNotFound } from '../../../components/Errors/404';

export const SystemParticularApplication = ({ children, thisSectionName }: any) => {
  const { id, typeId } = useParams<ParamTypes>();
  const numberId = toNumber(id);
  const numberTypeId = toNumber(typeId);

  const { t } = useTranslation();

  const [model, modelLoading] = usePromise(
    () => getApplicationByType(numberId, numberTypeId),
    !isNaN(numberId) && !isNaN(numberTypeId),
    [numberId, numberTypeId],
  );

  /** url для скачивания документа блока заявки */
  const downloadDocumentUrl = useCallback((blockId: number): string => {
    invariant(!isNaN(numberId), 'Ид заявки должен быть задан');

    if (!isNaN(numberId)) {
      return `${projectBaseUrl}/api/public/application/construct/${numberId}/${blockId}`;
    }
    return '';
  }, [numberId]);


  if (modelLoading) {
    return <Loader />;
  }
  if (model instanceof ApplicationModel) {
    return <>{React.cloneElement(children, { model, downloadDocumentUrl, thisSectionName })}</>;
  }
  return <EntityNotFound message={t('applications.404')} />;
};
