export interface ISystemNotificationTemplateModel {
  readonly id: number;
  readonly action: {code: string; name: string};
  readonly type: {code: string; name: string};
  readonly subject: string;
  readonly text: string;
  readonly internal_link: string;
}


export class SystemNotificationTemplateModel implements ISystemNotificationTemplateModel {
  readonly id: number;
  readonly action: { code: string; name: string };
  readonly internal_link: string;
  readonly subject: string;
  readonly text: string;
  readonly type: { code: string; name: string };

  constructor(params: any = {}) {
    this.id = params.id;
    this.action = params.action;
    this.internal_link = params.internal_link;
    this.subject = params.subject;
    this.text = params.text;
    this.type = params.type;
  }
}
