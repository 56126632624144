import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { INewsModel, NewsFilterType } from 'models/News/NewsModel';
import { systemGetNewsStartAction } from './actions';
import { systemNewsIsLoadingSelector, systemNewsSelector } from './selectors';
import { SystemNewsComponent } from '../../components/News/SystemNews';


export const SystemNews = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: NewsFilterType) => {
    dispatch(systemGetNewsStartAction(filter));
  }, [dispatch]);

  const data: INewsModel = useSelector(systemNewsSelector);
  const isLoading: boolean = useSelector(systemNewsIsLoadingSelector);

  return <SystemNewsComponent data={data} isLoading={isLoading} getData={getData} />;
};
