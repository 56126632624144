import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'reactstrap';

import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { emptyOption, UiSelect } from 'components/common/UI/Select';
import { UiFileUploaderSystem } from 'components/common/FileUploader';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import {
  ConstructApplicationTemplateFormRawFileType,
  IConstructApplicationTemplateListItemModel,
} from 'models/ConstructApplication/interfaces';
import { checkReplace, findValue } from 'utils/common';
import { convertModelToOptions, SimpleSelectOptionType } from 'utils/convertModelToOptions';


const allowedFileTypes = ['docx'];


type Props = {
  model: IConstructApplicationTemplateListItemModel;
  canUpdate: boolean;
  loading: boolean;
  enterprises: IEnterpriseModel[];
  onSave: (m: ConstructApplicationTemplateFormRawFileType) => void;
}

type FormValues = {
  name: string;
  file: File | null;
  enterprise_id: SimpleSelectOptionType;
}

export const ConstructApplicationTemplateFormComponent = ({
  loading, model, canUpdate, enterprises, onSave,
}: Props) => {
  const { t } = useTranslation();

  const createMode = model.isNew;
  const optionsEnterprises = convertModelToOptions(enterprises);

  const {
    register, handleSubmit, errors, formState: { isSubmitting, dirtyFields, isValid }, setValue, getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
      enterprise_id: checkReplace(findValue(optionsEnterprises, model.enterprise.id), emptyOption()),
    },
  });

  /** регистрация полей, управляемых вручную */
  useEffect(() => register('file'), [register]);

  const onSubmit = (data: FormValues) => {
    if (canUpdate && data.file) {
      onSave({
        id: model.id,
        name: data.name,
        file: data.file,
        enterprise_id: data.enterprise_id.value,
        isNew: model.isNew,
      });
    }
  };


  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid || !getValues('file')}
      loadingSubmit={isSubmitting || loading}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
      showButtons={canUpdate}
    >
      <h3>
        {t(`system.construct_application.template.form.${createMode ? 'create' : 'update'}`)}
      </h3>

      <UiSystemInput
        name="name"
        errors={errors}
        register={register}
        label={t('system.construct_application.template.name')}
        required
      />

      <UiSelect
        defaultValue={getValues('enterprise_id')}
        errors={errors}
        register={register}
        name="enterprise_id"
        label={t('system.construct_application.template.enterprise')}
        setValue={setValue}
        options={optionsEnterprises}
        allowEmptyValue
        required
      />

      <FormGroup>
        <strong>
          {t('system.construct_application.template.file')}
        </strong>
        <UiFileUploaderSystem
          initFileTypes={model.file ? [model.file] : []}
          maxFilesCount={1}
          onChange={(files: File[]) => setValue('file', files[0])}
          accept={allowedFileTypes}
        />
      </FormGroup>

    </UiSystemForm>
  );
};
