import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { deleteSystemEducation } from 'systemModule/api/education';
import { ISystemEducationListItemModel, ISystemEducationListModel } from '../../../models/Education/interfaces';
import { DefaultFilterType } from '../../../models/common';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { CDate } from '../../../utils/CDate';
import { CustomTable } from '../../../components/common/Table';
import { ICurrentUserModel, UserAccessEnum } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';
import { handleErrors } from '../../../utils/errors';
import { getParamsFromPagesModel } from '../../../models/WithPagesModel';


type Props = {
  data: ISystemEducationListModel;
  isLoading: boolean;
  getData: (filter: DefaultFilterType) => void;
}

export const SystemEducationsComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cEducation);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dEducation);

  const onDelete = ({ id, name }: ISystemEducationListItemModel) => async () => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: t('education.menu'), entityName: name }))) {
      handleErrors(
        await deleteSystemEducation(id),
        'delete',
        () => {
          toast.success(t('education.deleted'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  };

  return (
    <>
      <h3>
        {t('education.menu')}
      </h3>

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="education/create">{t('education.create')}</Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: t('education.name') },
          { name: t('education.date_start') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={`education/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`education/${item.id}`}>{item.name}</Link>
            </td>
            <td>
              {CDate.format(item.date_start, 'dd MMMM yyyy HH:mm')}
            </td>
            <td>
              {canDelete && (
                <Button
                  type="button"
                  color="danger"
                  className="w-100"
                  onClick={onDelete(item)}
                >
                  {t('common.form.delete')}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
