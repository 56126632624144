import React, { useEffect, useRef, useState } from 'react';

import { INewsModel, LocalNewsFilterType, NewsFilterType } from 'models/News/NewsModel';
import { INewsCategoryItemModel } from 'models/News/NewsCategoryModel';
import { EnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { TopMenu } from '../Main/TopMenu';
import { ReactComponent as FilterIcon } from '../../assets/img/filter.svg';
import { NewsItemComponent } from './NewsItem';
import { Loader } from '../common/UI/Loaders';
import { NewsFilter } from './Filter';
import { InfiniteScrollbar } from '../common/InfiniteScrollbar';
import { UiModal } from '../common/UI/Modal';


const getDefaultFilter = (
  categories: INewsCategoryItemModel[],
  userEnterprises: EnterpriseModel[],
): LocalNewsFilterType => ({
  category: categories.map((c) => c.id),
  enterprise: userEnterprises.map((e) => e.id),
  holding: 0,
});


type Props = {
  news: INewsModel;
  newsIsLoading: boolean;
  categories: INewsCategoryItemModel[];
  getData: (filter?: NewsFilterType, append?: boolean) => void;
  hasMoreData: boolean;
  userEnterprises: EnterpriseModel[];
  allEnterprises: EnterpriseModel[];
  clearLoad: boolean;
  changeLiked: (id: number, liked: boolean) => void;
  localStorageKey: string;
}

export const NewsComponent = ({
  news,
  newsIsLoading,
  categories,
  getData,
  hasMoreData,
  userEnterprises,
  allEnterprises,
  clearLoad,
  changeLiked,
  localStorageKey,
}: Props) => {
  const [openFilter, setOpenFilter] = useState(false);

  /** компонент фильтра будет сообщать об изменениях. и как стартанет тоже сообщит */
  const [filterData, setFilterData] = useState<LocalNewsFilterType>(getDefaultFilter(categories, userEnterprises));

  /** подгрузка данных при скролле */
  const onLoadMoreData = (page: number) => {
    getData({
      ...filterData,
      page,
      'per-page': news.limit,
    }, true);
  };

  /** начальная загрузка данных */
  useEffect(() => {
    getData({ ...filterData, 'per-page': news.limit });
  }, [filterData, getData, news.limit]);

  const scrollBarRef = useRef<any>();

  return (
    <>
      <UiModal isOpen={openFilter} toggle={() => setOpenFilter(false)} headerContent="Фильтр новостей">
        <NewsFilter
          filterData={filterData}
          toggle={() => setOpenFilter(false)}
          categories={categories}
          setFilterData={setFilterData}
          enterprises={allEnterprises}
          initialValues={() => getDefaultFilter(categories, userEnterprises)}
          currentEnterprise={userEnterprises}
          localStorageKey={localStorageKey}
        />
      </UiModal>

      <TopMenu />

      <div className="box news">
        <InfiniteScrollbar
          currentPage={news.page}
          hasMoreData={hasMoreData && !newsIsLoading}
          onLoadData={onLoadMoreData}
          getRef={scrollBarRef}
        >
          <section className="form">
            <div className="float-right">
              <span className="filter" role="presentation" onClick={() => setOpenFilter(true)}>
                <FilterIcon />
                <span className="changes">!</span>
              </span>
            </div>
            <h3>Новости холдинга</h3>
            <div className="news-feed">
              {(!newsIsLoading && news.data.length === 0) && <h3 className="text-center">Нет новостей</h3>}
              {!clearLoad && news.data.map((newsItem) => (
                <NewsItemComponent
                  key={newsItem.id}
                  item={newsItem}
                  onLikeClick={changeLiked}
                />
              ))}
              {newsIsLoading && <Loader className="mt-3" />}
            </div>
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};

NewsComponent.whyDidYouRender = true;
