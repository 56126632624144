import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IPrivilegeList, PrivilegeListFilterType } from '../../../models/Privilege/interfaces';
import { systemGetPrivilegesStartAction } from './actions';
import { systemPrivilegesListIsLoadingSelector, systemPrivilegesListSelector } from './selectors';
import { SystemPrivilegesListComponent } from '../../components/Priveleges/SystemPrivilegesList';


export const SystemPrivilegesList = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: PrivilegeListFilterType = {}) => {
    dispatch(systemGetPrivilegesStartAction(filter));
  }, [dispatch]);

  const data: IPrivilegeList = useSelector(systemPrivilegesListSelector);
  const isLoading: boolean = useSelector(systemPrivilegesListIsLoadingSelector);

  return <SystemPrivilegesListComponent data={data} isLoading={isLoading} getData={getData} />;
};
