import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ParamTypes } from 'models/common';
import { EventModel, IEventModel } from '../../models/Events/EventModel';
import { Loader } from '../../components/common/UI/Loaders';
import { EntityNotFound } from '../../components/Errors/404';
import { EventOneComponent } from '../../components/Events/EventOneComponent';
import { getEventByid, voteEventPoll } from '../../api/event';
import { handleErrors } from '../../utils/errors';
import { usePromise } from '../../utils/hooks/usePromise';


export const EventsOne = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  /** логика про голосование */
  const [loadingVote, setLoadingVote] = useState(false);
  const [showVoteBtns, setShowVoteBtns] = useState(true);
  const onVote = useCallback(async (val: boolean) => {
    setLoadingVote(true);

    handleErrors(
      await voteEventPoll(numberId, val),
      (e) => toast.error(e.message),
      () => {
        toast.success(t('event.event.vote.success'));
        setShowVoteBtns(false);
      },
      () => setLoadingVote(false),
    );
  }, [numberId, t]);

  /** запрос модели */
  const [model, loading] = usePromise<IEventModel>(() => getEventByid(numberId), !isNaN(numberId), [numberId]);

  if (loading) {
    return <Loader />;
  }
  if (model instanceof EventModel) {
    return <EventOneComponent model={model} onVote={onVote} loadingVote={loadingVote} showVoteBtns={showVoteBtns} />;
  }
  return <EntityNotFound message={t('event.404')} />;
};

EventsOne.whyDidYouRender = true;
