import { createSelector } from 'reselect';
import { storeName } from './reducer';
import { RootReducer } from '../../store/reducers';
import { returnParams } from '../../store/selectors';


const getPolls = (state: RootReducer) => state[storeName].polls;
const getPollsIsLoading = (state: RootReducer) => state[storeName].pollsIsLoading;

export const getPollsSelector = createSelector([getPolls], returnParams);
export const getPollsIsLoadingSelector = createSelector([getPollsIsLoading], returnParams);
