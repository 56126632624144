import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../../../components/common/Table';
import { useDataPage } from '../../../../utils/hooks/useDataPage';
import { ISystemUnitsModel, UnitsFilterType } from '../../../../models/References/UnitsModel';
import { SystemUnitsFilter } from './Filter';
import { ISystemEnterpriseModel } from '../../../../models/References/EnterprisesModel';

type Props = {
  units: ISystemUnitsModel;
  enterprises: ISystemEnterpriseModel[];
  unitsIsLoading: boolean;
  getData: (filter: UnitsFilterType) => void;
}

export const SystemUnitsComponent = ({
  units, unitsIsLoading, getData, enterprises,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(units.page, units.limit, getData);

  return (
    <div>
      <Card>
        <CardBody>
          <h3>Список подразделений</h3>

          <SystemUnitsFilter enterprises={enterprises} onSubmit={onSubmitFilter} onReset={onResetFilter} />

          <CustomTable
            onChangePage={setPage}
            currentPage={units.page}
            sizePerPage={units.limit}
            amountOfPages={units.amountOfPages}
            setSizePerPage={[setLimit, setPage]}
            isLoading={unitsIsLoading}
            tableHeaders={[
              { name: 'ID предприятия' },
              { name: 'Название предприятия' },
              { name: 'ID подразделения' },
              { name: 'Название подразделения' },
            ]}
          >
            {units.data.map((item) => (
              <tr key={item.id}>
                <td>
                  <Link to={`enterprise/${item.enterprise.id}`}>{item.enterprise.id}</Link>
                </td>
                <td>
                  <Link to={`enterprise/${item.enterprise.id}`}>{item.enterprise.name}</Link>
                </td>
                <td>
                  <Link to={`unit/${item.id}`}>{item.id}</Link>
                </td>
                <td>
                  <Link to={`unit/${item.id}`}>{item.name}</Link>
                </td>
              </tr>
            ))}
          </CustomTable>
        </CardBody>
      </Card>
    </div>
  );
};
