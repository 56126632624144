import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ISystemUserModel } from 'models/User/interfaces';
import {
  ISystemBonusProgramTagListModel,
  ISystemBonusProgramUserTagListModel,
  ISystemBonusProgramUserTagsFormModel,
} from 'models/BonusProgram/Tag/interfaces';
import { ISystemBonusProgramOrderFormModel } from 'models/BonusProgram/Order/interfaces';
import { ISystemBonusProgramPrizeListModel } from 'models/BonusProgram/Prize/interfaces';
import {
  ISystemBonusProgramEventHistoryFilter,
  ISystemBonusProgramEventHistoryListModel,
} from 'models/BonusProgram/EventHistory/interfaces';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiSelect } from 'components/common/UI/Select';
import { UiSystemForm } from 'components/common/UI/Form';
import { convertModelToOptions, SimpleSelectOptionType } from 'utils/convertModelToOptions';
import {
  SystemBonusProgramUserEventHistoryListComponent,
} from 'systemModule/components/BonusProgram/BonusProgramUsers/SystemBonusProgramUserEventHistoryList';
import {
  SystemBonusProgramUserPrizeListComponent,
} from 'systemModule/components/BonusProgram/BonusProgramUsers/SystemBonusProgramUserPrizeList';

type FormValues = {
  readonly userdata: string;
  readonly userPhone: string;
  readonly userCoinBalance: number;
  tags: SimpleSelectOptionType[];
}

type Props = {
  userInfo: ISystemUserModel;
  prizeList: ISystemBonusProgramPrizeListModel;
  givePrizeToUser: (model: ISystemBonusProgramOrderFormModel) => () => void;
  canGivePrizeToUser: boolean;
  bonusProgramTags: ISystemBonusProgramTagListModel;
  userTagList: ISystemBonusProgramUserTagListModel;
  onSaveUserTags: (userId: number, model: ISystemBonusProgramUserTagsFormModel) => void;
  canUpdateUserTags: boolean;
  bonusProgramEventHistoryData: ISystemBonusProgramEventHistoryListModel;
  isLoadingBonusProgramEventHistoryData: boolean;
  getBonusProgramEventHistoryData: (filter: ISystemBonusProgramEventHistoryFilter) => void;
  canShowEventHistory: boolean;
}

export const SystemBonusProgramUserComponent = ({
  userInfo,
  prizeList,
  givePrizeToUser,
  canGivePrizeToUser,
  bonusProgramTags,
  userTagList,
  onSaveUserTags,
  canUpdateUserTags,
  bonusProgramEventHistoryData,
  isLoadingBonusProgramEventHistoryData,
  getBonusProgramEventHistoryData,
  canShowEventHistory,
}: Props) => {
  const { t } = useTranslation();

  const optionsTags = convertModelToOptions(bonusProgramTags.data);

  const {
    register, handleSubmit, errors, getValues, setValue, formState: { isSubmitting, dirtyFields, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      userdata: `ID: ${userInfo.id}, ФИО: "${userInfo.fio}"`,
      userPhone: userInfo.phone,
      userCoinBalance: Number(userInfo.sdsCoin),
      tags: (() => {
        const ids = userTagList.data
          .map((tag) => tag.id);
        return optionsTags
          .filter((option) => ids.includes(option.value));
      })(),
    },
  });

  const onSubmit = async (data: FormValues) => {
    if (!canUpdateUserTags) {
      return;
    }

    const modelToSave: ISystemBonusProgramUserTagsFormModel = {
      tags: data.tags.map((tag: SimpleSelectOptionType<number>) => tag.value),
    };

    onSaveUserTags(userInfo.id, modelToSave);
  };

  return (
    <>
      <h3>Подробнее о пользователе</h3>

      <UiSystemInput
        name="userdata"
        errors={errors}
        register={register}
        label="Пользователь"
        disabled
      />

      <UiSystemInput
        name="userPhone"
        errors={errors}
        register={register}
        label="Телефон пользователя"
        disabled
      />

      <UiSystemInput
        name="userCoinBalance"
        errors={errors}
        register={register}
        label="Баланс в СДС-коинах"
        disabled
      />

      <UiSystemForm
        onSubmit={handleSubmit(onSubmit)}
        disabledSubmit={!isValid}
        loadingSubmit={isSubmitting}
        dirtyFieldsAmount={dirtyFields.size}
        useCustomButtons
      >
        <UiSelect
          defaultValue={getValues('tags')}
          errors={errors}
          register={register}
          name="tags"
          label="Теги пользователя"
          setValue={setValue}
          options={optionsTags}
          allowEmptyValue
          multiple
        />
        <Row>
          <Col>
            <div className="buttons-group justify-content-end buttons-group-responsive">
              <Button
                type="submit"
                color="primary"
              >
                Сохранить
              </Button>
            </div>
          </Col>
        </Row>
      </UiSystemForm>
      <SystemBonusProgramUserPrizeListComponent
        data={prizeList}
        canGivePrizeToUser={canGivePrizeToUser}
        givePrizeToUser={givePrizeToUser}
        userId={userInfo.id}
      />
      {canShowEventHistory && (
        <SystemBonusProgramUserEventHistoryListComponent
          data={bonusProgramEventHistoryData}
          dataIsLoading={isLoadingBonusProgramEventHistoryData}
          getData={getBonusProgramEventHistoryData}
        />
      )}
      <div className="buttons-group justify-content-start buttons-group-responsive">
        <Link to="/system/bonus-program/users" className="btn btn-secondary">
          {t('common.cancel')}
        </Link>
      </div>
    </>
  );
};
