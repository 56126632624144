import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { systemGetSportsStartAction } from './actions';
import { ISystemSportsModel } from '../../../../models/References/SportModel';
import { systemSportsIsLoadingSelector, systemSportsSelector } from './selectors';
import { SystemSportsComponent } from '../../../components/References/Sports/Sports';
import { SportFilterType } from '../../../../models/Sport/SportModel';

export const SystemSports = () => {
  const dispatch = useDispatch();
  const getData = useCallback((filter: SportFilterType) => {
    dispatch(systemGetSportsStartAction(filter));
  }, [dispatch]);

  const sports: ISystemSportsModel = useSelector(systemSportsSelector);
  const sportsIsLoading: boolean = useSelector(systemSportsIsLoadingSelector);

  return (
    <SystemSportsComponent sports={sports} sportsIsLoading={sportsIsLoading} getData={getData} />
  );
};
