import { CDate } from '../../utils/CDate';
import { NotificationBase } from './NotificationBase';


export interface ISocketNotification {
  readonly action_code: string;
  readonly is_blocked: boolean;
  readonly date_blocked: Date | null;
  readonly subject: string;
  readonly text: string;
  readonly internal_link: string;
  readonly id_entity: number | null;

  readonly id: number | null;
  readonly notify_text: { message: string; link: string | null };
}

export class SocketNotificationModel extends NotificationBase implements ISocketNotification {
  action_code: string;
  date_blocked: Date | null;
  id_entity: number | null;
  internal_link: string;
  is_blocked: boolean;
  subject: string;
  text: string;
  id: number | null;

  constructor(params: any = {}) {
    super(params);

    this.id = params.id || params.id_notify || null;
    this.action_code = params.action_code;
    this.date_blocked = typeof params.date_blocked === 'string' ?
      CDate.parse(params.date_blocked, 'yyyy-MM-dd HH:mm') : null;
    this.id_entity = params.id_entity;
    this.internal_link = params.internal_link;
    this.is_blocked = params.is_blocked;
    this.subject = params.subject || '';
    this.text = params.text || '';
  }

  get notify_text() {
    return this.notification_data(this.action_code);
  }
}
