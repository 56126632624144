import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../../../components/common/Table';
import { useDataPage } from '../../../../utils/hooks/useDataPage';
import { ISystemSportsModel } from '../../../../models/References/SportModel';
import { SystemSportsFilter } from './Filter';
import { SportFilterType } from '../../../../models/Sport/SportModel';

type Props = {
  sports: ISystemSportsModel;
  sportsIsLoading: boolean;
  getData: (filter: SportFilterType) => void;
}

export const SystemSportsComponent = ({ sports, sportsIsLoading, getData }: Props) => {
  const {
    setLimit, setPage, onSubmitFilter, onResetFilter,
  } = useDataPage(sports.page, sports.limit, getData);

  return (
    <div>
      <Card>
        <CardBody>
          <h3>Список видов спорта</h3>

          <SystemSportsFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

          <CustomTable
            onChangePage={setPage}
            currentPage={sports.page}
            sizePerPage={sports.limit}
            amountOfPages={sports.amountOfPages}
            setSizePerPage={[setLimit, setPage]}
            isLoading={sportsIsLoading}
            tableHeaders={[
              { name: 'ID вида спорта' },
              { name: 'Название вида спорта' },
            ]}
          >
            {sports.data.map((item) => (
              <tr key={item.id}>
                <td>
                  <Link to={`unit/${item.id}`}>{item.id}</Link>
                </td>
                <td>
                  <Link to={`unit/${item.id}`}>{item.name}</Link>
                </td>
              </tr>
            ))}
          </CustomTable>
        </CardBody>
      </Card>
    </div>
  );
};
