import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { ApplicationSickRequestType } from 'models/Application/Sick/interfaces';
import { UiBox } from '../common/UI/UiBox';
import { DateRange } from '../common/Dates/DateRange';
import { SubmitLoaderButton } from '../common/SubmitLoaderButton';
import { ListElementLink } from '../common/ListElement/ListElementLink';

interface LocationState {
  dateEnd: null;
  dateStart: null;
  model: { id: number, fio: string };
}

type Props = {
  createApp: (data: ApplicationSickRequestType) => void;
  isLoading: boolean;
}

type FormValues = {
  date_start: Date | null;
  date_end: Date | null;
}

const rangeNames = ['date_start', 'date_end'];

export const ApplicationCreateSickComponent = ({ createApp, isLoading }: Props) => {
  const {
    state = {
      dateEnd: null,
      dateStart: null,
      model: { id: 0, fio: '' },
    }, pathname,
  } = useLocation<LocationState>();

  const {
    register, watch, setValue, handleSubmit,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      date_end: state.dateEnd,
      date_start: state.dateStart,
    },
  });

  const { t } = useTranslation();

  const responsible = useMemo(() => ((state && state.model) ? state.model : { id: 0, fio: '' }), [state]);

  const isValid = responsible.id && watch('date_start') && watch('date_end');

  const localSubmit = useCallback(({ date_start, date_end }: FormValues) => {
    if (!date_start || !date_end || !responsible.id) {
      return;
    }
    createApp({
      user: responsible.id,
      date_start: date_start.toLocaleString(),
      date_end: date_end.toLocaleString(),
    });
  }, [responsible, createApp]);

  const dateStartWatch = watch('date_start');
  const dateEndWatch = watch('date_end');

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/applications/create" />
        {t('application.sick.title')}
      </div>

      <UiBox className="applications">
        <section className="form">
          <form className="form-with-btn" onSubmit={handleSubmit(localSubmit)}>
            <ListElementLink
              text={t('application.assign')}
              to={{
                pathname: '/contacts-all',
                state: { from: pathname, dateStart: dateStartWatch, dateEnd: dateEndWatch },
              }}
              isFollow
              isTitle
              className="mb-3"
            >
              <div className="item">{responsible.fio}</div>
            </ListElementLink>

            <DateRange
              names={rangeNames}
              label="Период больничного (укажите дату начала и предполагаемую дату закрытия больничного)"
              register={register}
              watch={watch}
              setValue={setValue}
              className="system-formgroup"
              minDate={new Date()}
            />

            <div className="form-wrapper_btn">
              <div className="buttons-group buttons-group-responsive">
                <SubmitLoaderButton
                  disabled={!isValid}
                  loading={isLoading}
                  label={t('applications.btn.create')}
                />
              </div>
            </div>
          </form>
        </section>
      </UiBox>
    </>
  );
};

ApplicationCreateSickComponent.whyDidYouRender = true;
