import {
  IConstructApplicationTypeListItemModel, IConstructApplicationTypeListModel,
} from 'models/ConstructApplication/interfaces';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { WithPagesModel } from 'models/WithPagesModel';


export class ConstructApplicationTypeListItemModel implements IConstructApplicationTypeListItemModel {
  readonly assignUsers: { id: number; fio: string; phone: string; unit: string; post: string }[];
  readonly category: { id: number; name: string; can_delete: boolean } | null;
  readonly enterprise: IEnterpriseModel | null;
  readonly id: number;
  readonly is_active: boolean;
  readonly name: string;

  constructor(params: any = {}) {
    this.assignUsers = params.assignUsers;
    this.category = params.category;
    this.enterprise = params.enterprise;
    this.id = params.id;
    this.is_active = params.is_active;
    this.name = params.name;
  }
}

export class ConstructApplicationTypeListModel extends WithPagesModel
  implements IConstructApplicationTypeListModel {
  readonly data: IConstructApplicationTypeListItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, ConstructApplicationTypeListItemModel);
  }
}
