import { FileType, prepareFilesOrNull } from 'models/common';


export interface PartnerCategoryListItemModel {
  icon: string | null;
  id: number;
  logo: FileType | null;
  name: string;
  priority: number;
}

export type IPartnerCategoryListModel = PartnerCategoryListItemModel[];

export class PartnerCategoryListItemModel implements PartnerCategoryListItemModel {
  icon: string | null;
  id: number;
  logo: FileType | null;
  name: string;
  priority: number;

  constructor(params: any = {}) {
    this.icon = params.icon || null;
    this.id = params.id;
    this.logo = prepareFilesOrNull(params.logo);
    this.name = params.name;
    this.priority = params.priority;
  }
}
