import React, { useState } from 'react';
import { Button, Card, Collapse } from 'reactstrap';


type Props = {
  statisticsInfo: {
    title: string;
    amount: number;
    percent: number;
  }[];
}

export const SystemPollStatisticsAnswers = ({ statisticsInfo }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card body className="system-answers-card">
      <Button color="primary" className="collapse-btn" onClick={() => setIsOpen(!isOpen)}>
        Посмотреть статистику
      </Button>
      <Collapse isOpen={isOpen}>
        <div className="mt-3" />
        {statisticsInfo.map((statisticInfo) => (
          <p key={`${statisticInfo.title}_${statisticInfo.percent}`}>
            <strong>{statisticInfo.title}:</strong> {statisticInfo.percent}%
          </p>
        ))}
      </Collapse>
    </Card>
  );
};
