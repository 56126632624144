import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CustomTable } from 'components/common/Table';
import { ISimpleNewsItemModel, INewsModel, NewsFilterType } from 'models/News/NewsModel';
import { useDataPage } from 'utils/hooks/useDataPage';
import { CDate } from 'utils/CDate';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { getParamsFromPagesModel } from 'models/WithPagesModel';
import { deleteNews } from '../../api/news';


type Props = {
  data: INewsModel;
  isLoading: boolean;
  getData: (filter: NewsFilterType) => void;
}

export const SystemNewsComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cNews);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dNews);

  const onDelete = ({ id, title }: ISimpleNewsItemModel) => () => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: 'новость', entityName: title }))) {
      deleteNews(id).then((booleanOrError) => {
        if (booleanOrError instanceof Error) {
          toast.error(t('common.form.errors.delete'));
        } else {
          toast.success(t('news.deleted'));
          getData(getParamsFromPagesModel(data));
        }
      });
    }
  };

  return (
    <>
      <h3>Список новостей</h3>
      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="news/create">Создать новость</Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: 'Заголовок' },
          { name: 'Создана' },
          { name: 'Предприятия' },
          { name: 'Категории' },
          { name: 'Лайки' },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((newsItem: ISimpleNewsItemModel) => (
          <tr key={newsItem.id}>
            <td>
              <Link to={`news/${newsItem.id}`}>{newsItem.id}</Link>
            </td>
            <td>
              <Link to={`news/${newsItem.id}`}>{newsItem.title}</Link>
            </td>
            <td>
              {CDate.format(newsItem.created_at, 'dd MMMM yyyy HH:mm')}
            </td>
            <td>
              {newsItem.enterprises.map((newsItemEnterprise) => newsItemEnterprise.name).join(', ')}
            </td>
            <td>
              {newsItem.categories.map((newsItemCategory) => newsItemCategory.name).join(', ')}
            </td>
            <td>{newsItem.like_count}</td>
            <td>
              {canDelete && (
                <Button type="button" color="danger" onClick={onDelete(newsItem)}>
                  {t('common.form.delete')}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
