import { OptionTypeBase } from 'react-select';

export interface SelectOptionType<T = any> extends OptionTypeBase {
  label: string;
  value: T;
}

/** базовый тип option select, без глубоких проверок типа */
export type SimpleSelectOptionType<T = any> = {
  label: string;
  value: T;
}

export const convertModelToOptions = (data: {name?: any; id: any; fio?: any, title?: any}[]): SelectOptionType[] => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((value) => ({
    label: value.name || value.fio || value.title,
    value: value.id,
  }));
};
