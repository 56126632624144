import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { JobDescriptionsComponent } from '../../../components/References/JobDescriptions/JobDescriptions';
import { handleErrors } from '../../../../utils/errors';
import { systemRefGetJobDescriptionsStartAction } from './actions';
import { getJobDescriptionsIsLoadingSelector, getJobDescriptionsSelector } from './selectors';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import { systemEnterprisesSelector } from '../../../store/selectors';
import {
  IJobDescriptionsItemModel,
  JobDescriptionsFilterType,
} from '../../../../models/JobDescription/JobDescription';
import { IEnterpriseModel } from '../../../../models/Enterprises/EnterpriseModel';
import { UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { ISystemUnitModel, UnitsFilterType } from '../../../../models/References/UnitsModel';
import { IPositionsItemModel, PositionsFilterType } from '../../../../models/References/Positions';
import { maxValueToGetAllDataWithPagination } from '../../../../config/system';
import { getSystemPositions } from '../../../api/references/positions';
import { getSystemUnits } from '../../../api/references/unit';
import { deleteJobDescription } from '../../../api/references/jobDescriptions';

export const SystemJobDescriptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getData = useCallback((filter?: JobDescriptionsFilterType) => {
    dispatch(systemRefGetJobDescriptionsStartAction(filter));
  }, [dispatch]);

  const [positions, setPositions] = useState<IPositionsItemModel[]>([]);
  const [positionsIsLoading, setPositionsIsLoading] = useState(false);

  const jobDescriptions = useSelector(getJobDescriptionsSelector);
  const jobDescriptionsIsLoading = useSelector(getJobDescriptionsIsLoadingSelector);

  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const [units, setUnits] = useState<ISystemUnitModel[]>([]);
  const [unitsIsLoading, setUnitsIsLoading] = useState(false);

  const canDelete = useSelector(getCurrentUserSelector).hasPermission(UserAccessEnum.dReferenceJobDescriptions);

  const onDelete = useCallback(async (item: IJobDescriptionsItemModel) => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(
      t('common.form.delete.question', { entity: 'должностную инструкцию', entityName: item.name }),
    )) {
      setIsLoading(true);
      handleErrors(
        await deleteJobDescription(item.id),
        'delete',
        () => {
          toast.success(t('jobDescriptions.deleted'));
          getData();
        },
      );
      setIsLoading(false);
    }
  }, [canDelete, getData, t]);

  const getPositions = useCallback(async (filter?: PositionsFilterType) => {
    setPositionsIsLoading(true);
    handleErrors(
      await getSystemPositions({
        ...filter,
        'per-page': maxValueToGetAllDataWithPagination,
      }),
      'get',
      (model) => setPositions(model.data),
      () => setPositionsIsLoading(false),
    );
  }, []);

  const getUnits = useCallback(async (filter?: UnitsFilterType) => {
    setUnitsIsLoading(true);
    handleErrors(
      await getSystemUnits({
        ...filter,
        'per-page': maxValueToGetAllDataWithPagination,
      }),
      'get',
      (model) => setUnits(model.data),
      () => setUnitsIsLoading(false),
    );
  }, []);

  return (
    <JobDescriptionsComponent
      getData={getData}
      onDelete={onDelete}
      data={jobDescriptions}
      isLoading={isLoading || jobDescriptionsIsLoading}
      enterprises={enterprises}
      units={units}
      positions={positions}
      getPositions={getPositions}
      getUnits={getUnits}
      positionsIsLoading={positionsIsLoading}
      unitsIsLoading={unitsIsLoading}
    />
  );
};
