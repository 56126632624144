export const ERROR_HTTP_PARAMETERS = 'ERROR_HTTP_PARAMETERS';
export const ERROR_NETWORK = 'ERROR_NETWORK';

export const GLOBAL_LOADER_SHOW = '[GLOBAL] loader [show]';
export const GLOBAL_LOADER_HIDE = '[GLOBAL] loader [hide]';

export const GLOBAL_GET_UNREAD_INFO_START = '[GLOBAL] get unread info [start]';
export const GLOBAL_GET_UNREAD_INFO_OK = '[GLOBAL] get unread info [ok]';
export const GLOBAL_GET_UNREAD_INFO_FAIL = '[GLOBAL] get unread info [fail]';

export const GLOBAL_SET_APP_VERSION = '[GLOBAL] set app version [mobile app]';

export const GLOBAL_SET_ONLINE = '[GLOBAL] set onLine status';

export const GLOBAL_SET_DISPLAYING_APP_GUIDE = '[GLOBAL] set displaying app guide';
