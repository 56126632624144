import { IDepartmentItemModel } from 'models/References/DepartmentModel';
import { dataAction, dataErrorAction } from '../../store/actions';
import {
  SYSTEM_GET_ENTERPRISES_FAIL,
  SYSTEM_GET_ENTERPRISES_OK,
  SYSTEM_GET_NEWS_CATEGORY_FAIL,
  SYSTEM_GET_NEWS_CATEGORY_OK,
  SYSTEM_GET_PERMISSIONS_FAIL,
  SYSTEM_GET_PERMISSIONS_OK,
  SYSTEM_GET_ROLES_FAIL,
  SYSTEM_GET_ROLES_OK,
  SYSTEM_GET_ROLES_START,
  SYSTEM_GLOBAL_GET_UNITS_FAIL,
  SYSTEM_GLOBAL_GET_UNITS_OK,
  SYSTEM_GLOBAL_DATA_LOADED,
  SYSTEM_POLLS_GET_QUESTION_TYPES_FAIL,
  SYSTEM_POLLS_GET_QUESTION_TYPES_OK,
  SYSTEM_POLLS_GET_STATUSES_FAIL,
  SYSTEM_POLLS_GET_STATUSES_OK,
  SYSTEM_GLOBAL_GET_DEPARTMENTS_OK, SYSTEM_GLOBAL_GET_DEPARTMENTS_FAIL, SYSTEM_GET_TAGS_FAIL, SYSTEM_GET_TAGS_OK,
} from './constants';
import { INewsCategoryItemModel } from '../../models/News/NewsCategoryModel';
import { IEnterpriseModel } from '../../models/Enterprises/EnterpriseModel';
import { IRolesModel } from '../../models/Roles/RolesModel';
import { DefaultFilterType } from '../../models/common';
import { IPermissions } from '../../models/Roles/Permissions';
import { IPollsQuestionTypesModel } from '../../models/Polls/PollsQuestionTypesModel';
import { IPollsStatusModel } from '../../models/Polls/PollsStatusesModel';
import { ISystemUnitModel } from '../../models/References/UnitsModel';
import { ISystemBonusProgramTagItemModel } from '../../models/BonusProgram/Tag/interfaces';


export const systemGlobalDataLoadedAction = dataAction(SYSTEM_GLOBAL_DATA_LOADED);


export const systemGetNewsCategoryOkAction = (newsCategories: INewsCategoryItemModel[]) => dataAction(
  SYSTEM_GET_NEWS_CATEGORY_OK,
)({ newsCategories });
export const systemGetNewsCategoryFailAction = dataErrorAction(SYSTEM_GET_NEWS_CATEGORY_FAIL);


export const systemGetEnterprisesOkAction = (enterprises: IEnterpriseModel[]) => dataAction(
  SYSTEM_GET_ENTERPRISES_OK,
)({ enterprises });
export const systemGetEnterprisesFailAction = dataErrorAction(SYSTEM_GET_ENTERPRISES_FAIL);

export const systemGetTagsOkAction = (tags: ISystemBonusProgramTagItemModel[]) => dataAction(
  SYSTEM_GET_TAGS_OK,
)({ tags });
export const systemGetTagsFailAction = dataErrorAction(SYSTEM_GET_TAGS_FAIL);

export const systemGlobalGetUnitsOkAction = (units: ISystemUnitModel[]) => dataAction(
  SYSTEM_GLOBAL_GET_UNITS_OK,
)({ units });
export const systemGlobalGetUnitsFailAction = dataErrorAction(SYSTEM_GLOBAL_GET_UNITS_FAIL);

export const systemGlobalGetDepartmentsOkAction = (departments: IDepartmentItemModel[]) => dataAction(
  SYSTEM_GLOBAL_GET_DEPARTMENTS_OK,
)({ departments });
export const systemGlobalGetDepartmentsFailAction = dataErrorAction(SYSTEM_GLOBAL_GET_DEPARTMENTS_FAIL);


export const systemGetPermissionsOkAction = (permissions: IPermissions) => dataAction(
  SYSTEM_GET_PERMISSIONS_OK,
)({ permissions });
export const systemGetPermissionsFailAction = dataErrorAction(SYSTEM_GET_PERMISSIONS_FAIL);


export const systemGlobalGetRolesStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_GET_ROLES_START,
)({ filter });
export const systemGlobalGetRolesOkAction = (roles: IRolesModel) => dataAction(
  SYSTEM_GET_ROLES_OK,
)({ roles });
export const systemGlobalGetRolesFailAction = dataErrorAction(SYSTEM_GET_ROLES_FAIL);


export const systemGetPollsQuestionTypesOkAction = (questionTypes: IPollsQuestionTypesModel) => dataAction(
  SYSTEM_POLLS_GET_QUESTION_TYPES_OK,
)({ questionTypes });
export const systemGetPollsQuestionTypesFailAction = dataErrorAction(SYSTEM_POLLS_GET_QUESTION_TYPES_FAIL);


export const systemGetPollsStatusesOkAction = (statuses: IPollsStatusModel[]) => dataAction(
  SYSTEM_POLLS_GET_STATUSES_OK,
)({ statuses });
export const systemGetPollsStatusesFailAction = dataErrorAction(SYSTEM_POLLS_GET_STATUSES_FAIL);
