import React from 'react';
import { useParams } from 'react-router-dom';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';

import { ParamTypes } from 'models/common';
import { ISystemNewsCategoryModel, SystemNewsCategoryModel } from '../../../../models/References/NewsCategoriesModel';
import { getSystemNewsCategory } from '../../../api/references/newsCategories';
import { SystemNewsCategoryFormComponent } from
  '../../../components/References/NewsCategories/SystemNewsCategoriesForm';
import { usePromise } from '../../../../utils/hooks/usePromise';
import { Loader } from '../../../../components/common/UI/Loaders';


export const SystemNewsCategoryForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [model, loading] = usePromise<ISystemNewsCategoryModel>(
    () => getSystemNewsCategory(numberId), !isNaN(numberId), [numberId],
  );

  if (loading) {
    return <Loader />;
  }
  return <SystemNewsCategoryFormComponent item={model || new SystemNewsCategoryModel()} isLoading={loading} />;
};
