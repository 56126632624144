import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { systemGetUnitsStartAction } from './actions';
import { systemUnitsIsLoadingSelector, systemUnitsSelector } from './selectors';
import { SystemUnitsComponent } from '../../../components/References/Units/Units';
import { ISystemUnitsModel, UnitsFilterType } from '../../../../models/References/UnitsModel';
import { IEnterpriseModel } from '../../../../models/Enterprises/EnterpriseModel';
import { systemEnterprisesSelector } from '../../../store/selectors';

export const SystemUnits = () => {
  const dispatch = useDispatch();
  const getData = useCallback((filter: UnitsFilterType) => {
    dispatch(systemGetUnitsStartAction(filter));
  }, [dispatch]);

  const units: ISystemUnitsModel = useSelector(systemUnitsSelector);
  const unitsIsLoading: boolean = useSelector(systemUnitsIsLoadingSelector);
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  return (
    <SystemUnitsComponent
      units={units}
      unitsIsLoading={unitsIsLoading}
      getData={getData}
      enterprises={enterprises}
    />
  );
};
