import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { SystemResponsibleFilter } from 'systemModule/components/Application/ResponsibleFilter';
import {
  IApplicationResponsibleFilterType,
  IApplicationResponsibleListModel,
} from 'models/Application/Responsible/interfaces';
import { useDataPage } from 'utils/hooks/useDataPage';
import { CustomTable } from 'components/common/Table';


type Props = {
  data: IApplicationResponsibleListModel;
  isLoading: boolean;
  getData: (filter: IApplicationResponsibleFilterType) => void;
  onSelect: (id: number) => void;
  assignId?: number;
}

/** Список ответственных за выполнение заявок */
export const SystemApplicationResponsibleListComponent = ({
  data, isLoading, getData, onSelect, assignId,
}: Props) => {
  const {
    setLimit, setPage, onSubmitFilter, onResetFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  return (
    <div className="mt-3">
      <h3>Новый ответственный</h3>

      <SystemResponsibleFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: t('ФИО') },
          { name: t('common.enterprise') },
          { name: t('common.department') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item) => {
          const currentUserAssigned = assignId === item.user.id;
          return (
            <tr key={Math.random()}>
              <td>
                {item.user.fio}
              </td>
              <td>
                {item.enterprise.name}
              </td>
              <td>
                {item.department.name}
              </td>
              <td>
                <Button color="primary" disabled={currentUserAssigned} onClick={() => onSelect(item.user.id)}>
                  {currentUserAssigned ? 'Выбран' : 'Выбрать'}
                </Button>
              </td>
            </tr>
          );
        })}
      </CustomTable>
    </div>
  );
};
