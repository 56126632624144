import React, { ReactNode, ReactNodeArray } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useHistory, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SubmitLoaderButton } from '../SubmitLoaderButton';


const DIRTY_FIELDS_AMOUNT = 4;


export type FormBaseRenderProps = {
  confirmReset: (e: React.MouseEvent) => void;
  disabledSubmit: boolean;
  loadingSubmit: boolean;
  cancelLabel: string;
  useCustomButtons?: boolean;
  additionalButtons?: ReactNode | ReactNodeArray;
  showButtons?: boolean;
}

export type FormBaseProps = {
  onSubmit: () => void;
  disabledSubmit: boolean; // блокировать ли кнопку сохранения
  loadingSubmit: boolean; // идет ли сохранение формы
  dirtyFieldsAmount: number; // кол-во измененных значений формы

  children?: React.ReactNode | React.ReactNodeArray;
  createMode?: boolean; // true если это форма создания. тогда не будет промпта при уходе со страницы
  useCustomButtons?: boolean; // true если не надо рендерить стандартные кнопки, а будут использоваться свои
  additionalButtons?: ReactNode | ReactNodeArray; // доп. кнопки к стандартным
  showButtons?: boolean; // false если не надо показывать кнопки действий. например форма только для просмотра
  className?: string; // доп. класс для формы
}

type Props = FormBaseProps & {
  render: (o: any) => any;
}

/**
 * базовая форма с кнопками и логикой по тз.
 */
export const UiFormBase = ({
  onSubmit,
  children,
  disabledSubmit,
  loadingSubmit,
  dirtyFieldsAmount,
  createMode = false,
  useCustomButtons = false,
  showButtons = true,
  render,
  additionalButtons,
  className,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  /**
   * на onReset формы ставить не очень. reset из useForm вызывает это событие
   */
  const confirmReset = () => {
    /** меньше 4 измененных полей - сразу назад. или если больше 4 с подтверждением пользователя */
    if (
      dirtyFieldsAmount < DIRTY_FIELDS_AMOUNT ||
      // eslint-disable-next-line no-restricted-globals
      (dirtyFieldsAmount >= DIRTY_FIELDS_AMOUNT && confirm(t('common.form.onreset')))
    ) {
      if (history.length > 2) {
        /** если есть куда назад - идти назад */
        history.goBack();
      } else {
        /** попробовать пойти на 1 слэш назад */
        history.push(history.location.pathname.replace(/\/\w+$/, ''));
      }
    }
  };

  const confirmSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    // eslint-disable-next-line no-restricted-globals
    if (confirm(t('common.form.onsubmit'))) {
      onSubmit();
    }
  };

  return (
    <form autoComplete="off" className={className} onSubmit={confirmSubmit}>
      {children}
      {render({
        confirmReset, disabledSubmit, loadingSubmit, useCustomButtons, showButtons, additionalButtons,
      })}

      <Prompt
        when={!createMode && dirtyFieldsAmount >= DIRTY_FIELDS_AMOUNT}
        message={t('common.form.onleave')}
      />
    </form>
  );
};


// Форма для админской части
export const UiSystemForm = ({ ...props }: FormBaseProps) => {
  const { t } = useTranslation();
  return (
    <UiFormBase
      {...props}
      render={({
        confirmReset, disabledSubmit, loadingSubmit, showButtons, useCustomButtons, additionalButtons,
      }: FormBaseRenderProps) => {
        if (useCustomButtons || !showButtons) {
          return null;
        }
        return (
          <Row>
            <Col xs="12" className="d-flex justify-content-end buttons-group buttons-group-responsive">
              {additionalButtons}
              <Button type="button" onClick={confirmReset}>{t('common.cancel')}</Button>
              <SubmitLoaderButton disabled={disabledSubmit} loading={loadingSubmit} />
            </Col>
          </Row>
        );
      }}
    />
  );
};


// Форма для клиентской части
export const UiForm = ({ ...props }: FormBaseProps) => {
  const { t } = useTranslation();
  return (
    <UiFormBase
      {...props}
      render={({
        confirmReset, disabledSubmit, loadingSubmit, useCustomButtons,
      }: FormBaseRenderProps) => {
        if (useCustomButtons) {
          return null;
        }
        return (
          <div className="form-wrapper_btn">
            <div className="buttons-group buttons-group-responsive">
              <SubmitLoaderButton disabled={disabledSubmit} loading={loadingSubmit} />
              <Button type="reset" className="btn btn-outline-light" onClick={confirmReset}>
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        );
      }}
    />
  );
};
