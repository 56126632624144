import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { InfiniteScrollbar } from '../common/InfiniteScrollbar';
import { UiInputSearch } from '../common/UI/InputSearch';
import { ReactComponent as FilterIcon } from '../../assets/img/filter.svg';
import { ApplicationsFilterComponent } from './Filter';
import { UiModal } from '../common/UI/Modal';
import { IApplicationStatusesModel } from '../../models/Application/Status/interfaces';
import { IApplicationTypesModel } from '../../models/Application/Type/interfaces';
import {
  IApplicationMyListFilter,
  IApplicationsMyListModel,
  LocalApplicationFilterType,
} from '../../models/Application/interfaces';
import { Loader } from '../common/UI/Loaders';
import { ApplicationItemComponent } from './ApplicationItem';
import { ReactComponent as FileTextIcon } from '../../assets/img/file-text.svg';
import { ReactComponent as FilePlusIcon } from '../../assets/img/file-plus.svg';
import { ListElementLink } from '../common/ListElement/ListElementLink';

const getDefaultFilter = (
  statuses: IApplicationStatusesModel,
  typesAll: IApplicationTypesModel,
): LocalApplicationFilterType => ({
  status_id: statuses.map((s) => s.id),
  type_id: typesAll.map((t) => t.id),
});

type Props = {
  statuses: IApplicationStatusesModel;
  typesAll: IApplicationTypesModel;
  data: IApplicationsMyListModel;
  isLoading: boolean;
  clearLoad: boolean;
  hasMoreData: boolean;
  getData: (filter?: IApplicationMyListFilter, append?: boolean) => void;
}

type FormValues = {
  search: string;
}

export const ApplicationsComponent = ({
  statuses,
  typesAll,
  data,
  isLoading,
  clearLoad,
  hasMoreData,
  getData,
}: Props) => {
  const { register, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { t } = useTranslation();

  const [openFilter, setOpenFilter] = useState(false);

  /** компонент фильтра будет сообщать об изменениях. и как стартанет тоже сообщит */
  const [filterData, setFilterData] = useState<LocalApplicationFilterType>(getDefaultFilter(statuses, typesAll));

  /** подгрузка данных при скролле */
  const onLoadMoreData = (page: number) => {
    getData({
      ...filterData,
      page,
      'per-page': data.limit,
    }, true);
  };

  const onSearch = useCallback(({ search }: FormValues) => {
    setFilterData((prevState: LocalApplicationFilterType) => ({ ...prevState, search }));
  }, []);

  /** начальная загрузка данных */
  useEffect(() => {
    getData({ ...filterData, 'per-page': data.limit });
  }, [filterData, getData, data.limit]);

  return (
    <>
      <UiModal isOpen={openFilter} toggle={() => setOpenFilter(false)} headerContent="Фильтр заявок">
        <ApplicationsFilterComponent
          toggle={() => setOpenFilter(false)}
          setFilterData={setFilterData}
          initialValues={() => getDefaultFilter(statuses, typesAll)}
          statuses={statuses}
          types={typesAll}
          filterData={filterData}
        />
      </UiModal>

      <div className="page-header">
        <Link className="back" to="/" />
        {t('applications.menu')}
      </div>

      <div className="box applications">
        <InfiniteScrollbar
          hasMoreData={hasMoreData && !isLoading}
          onLoadData={onLoadMoreData}
          currentPage={data.page}
        >
          <section className="form">
            <div className="d-flex">
              <UiInputSearch
                register={register}
                name="search"
                handleSubmit={handleSubmit(onSearch)}
              />
              <div className="ml-3">
                <span className="filter" role="presentation" onClick={() => setOpenFilter(true)}>
                  <FilterIcon />
                </span>
              </div>
            </div>

            <div className="mb-4 step-5">
              <ListElementLink to="/applications/create" text="Создать заявку" isFollow startIcon={<FilePlusIcon />} />
              <ListElementLink
                to="/applications/privilege"
                text="Положение о льготах"
                isFollow
                startIcon={<FileTextIcon />}
              />
            </div>

            <h3>Мои заявки</h3>
            {!clearLoad && data.data.map((item) => (
              <ApplicationItemComponent key={item.id} item={item} />
            ))}
            {(!isLoading && data.data.length === 0) && <h3 className="text-center">Нет заявок</h3>}
            {isLoading && <Loader className="mt-3" />}
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};

ApplicationsComponent.whyDidYouRender = true;
