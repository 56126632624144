import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ConstructApplicationCategoryListItemModel,
} from 'models/ConstructApplication/ConstructApplicationCategoryListModel';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import { IConstructApplicationCategoryListItemModel } from 'models/ConstructApplication/interfaces';


type Props = {
  model: IConstructApplicationCategoryListItemModel;
  canUpdate: boolean;
  onSave: (m: IConstructApplicationCategoryListItemModel) => void;
}

type FormValues = {
  name: string;
}

export const ConstructApplicationCategoryFormComponent = ({ model, canUpdate, onSave }: Props) => {
  const { t } = useTranslation();

  const createMode = model.isNew;

  const {
    register, handleSubmit, errors, formState: { isSubmitting, dirtyFields, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
    },
  });

  const onSubmit = (data: FormValues) => onSave(
    new ConstructApplicationCategoryListItemModel({ id: model.id, ...data }),
  );

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
      showButtons={canUpdate}
    >
      <h3>
        {t(`system.construct_application.category.form.${createMode ? 'create' : 'update'}`)}
      </h3>

      <UiSystemInput
        name="name"
        errors={errors}
        register={register}
        label={t('system.construct_application.category.name')}
        required
      />

    </UiSystemForm>
  );
};
