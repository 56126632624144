import { IApplicationResponsibleListModel, IApplicationResponsibleModel } from './interfaces';
import { IShortUser } from '../../User/interfaces';
import { WithPagesModel } from '../../WithPagesModel';
import { SettingResponsibleTypeEnum } from './enums';


export class ApplicationResponsibleModel implements IApplicationResponsibleModel {
  department: { id: number; name: string; created_at: string; updated_at: string };
  enterprise: { id: number; name: string };
  type: { code: SettingResponsibleTypeEnum; name: string };
  id: number;
  user: IShortUser;

  constructor(params: any = {}) {
    this.department = params.department || {};
    this.enterprise = params.enterprise || {};
    this.type = params.type || {};
    this.id = params.id || 0;
    this.user = params.user || {};
  }
}


export class ApplicationResponsibleListModel extends WithPagesModel implements IApplicationResponsibleListModel {
  data: IApplicationResponsibleModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, ApplicationResponsibleModel);
  }
}
