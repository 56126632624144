import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

import { ISocketNotification } from '../../models/Notification/SocketNotification';
import { NotificationLink } from '../common/NotificationLink';


type Props = {
  notifications: ISocketNotification[];
  onCloseNotification: (item: ISocketNotification, forceClose?: boolean) => void;
  lastReadNotification: ISocketNotification | null;
}

/** т.к. может быть довольно много и не предусмотрен скролл, то показывать только первые X оповещений */
const SHOW_ITEMS_AT_TIME = 5;

export const SocketNotificationComponent = ({ notifications, onCloseNotification, lastReadNotification }: Props) => {
  const { pathname } = useLocation();

  /**
   * если есть несколько блокирующих уведомлений, то при переходе на одно из них,
   * надо временно отключать все уведомления
   * после перехода на другую страницу - показывать снова
   *
   * сравнение текущего пути и пути уведомления
   */
  const inBlockingPage = lastReadNotification ?
    lastReadNotification.notify_text.link === pathname :
    false;

  /** начальные классы модального блока */
  const classNameModal = ['modal notifications-modal', 'd-block'];
  /** хоть 1 блокирующее оповещение - блокировать экран */
  if (notifications.find((item) => item.is_blocked)) {
    classNameModal.push('has-blocking');
  }

  /** подготовка списка для рендера */
  const renderList = notifications
    /** сортировка - блокирующие сверху */
    .sort((a, b) => {
      if (a.is_blocked === b.is_blocked) {
        return 0;
      }
      return a.is_blocked && !b.is_blocked ? -1 : 1;
    })
    /** сортировка - заканчивающиеся по дате раньше сверху */
    .sort((a, b) => {
      if (a.date_blocked && b.date_blocked) {
        return a.date_blocked < b.date_blocked ? -1 : 1;
      }
      return 0;
    })
    /**
     * отсеять заведомо прошедшие по времени оповещения.
     * даже если они пришли в методе, выводить только актуальные на текущий момент
     */
    .filter((n) => (n.date_blocked ? n.date_blocked >= new Date() : true))
    /** взять оповещения для рендера */
    .slice(0, SHOW_ITEMS_AT_TIME);

  const onClickLink = (notify: ISocketNotification) => () => {
    setTimeout(() => {
      onCloseNotification(notify, true);
    }, 0);
  };

  /** нет оповещений или временно не показывать - не рендерить вообще */
  if (!renderList.length || inBlockingPage) {
    return null;
  }
  return (
    <div className={classNameModal.join(' ')}>
      {renderList.map((notify: ISocketNotification) => {
        const { message, link } = notify.notify_text;

        return (
          <div
            role="presentation"
            className={`modal-dialog notification-modal${notify.is_blocked ? '' : ' cursor-pointer'}`}
            key={`${notify.id}`}
          >
            <div className="modal-content">
              <span className="d-flex align-items-center modal-body">
                <b className="text-black ws-pw wb-ba">{message}</b>
              </span>
              {link && (
                <NotificationLink link={link} onClick={onClickLink(notify)} className="w-50 align-self-center mb-3" />
              )}
              {/**
               на случай любых уведомлений без ссылки - просто кнопка закрыть для освобождения экрана
               */}
              {!link && (
                <Button
                  color="primary"
                  className="w-50 align-self-center mb-3"
                  onClick={() => onCloseNotification(notify, true)}
                >
                  Закрыть уведомление
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
