import React from 'react';

import { IReportInsuranceEnterprise } from 'models/Reports/interfaces';
import { CardBlock } from '../common/CardBlock';
import { CollapseCardBlock } from '../common/CollapseCardBlock';


type Props = {
  model: IReportInsuranceEnterprise;
}

export const SystemReportInsuranceEnterpriseComponent = ({ model }: Props) => (
  <>
    {model.items.map((item) => (
      <React.Fragment key={item.id}>
        <CollapseCardBlock name={item.name} count={item.user_count}>
          {item.insurances.map((insurance) => (
            <CardBlock key={insurance.id} name={insurance.name} count={insurance.user_count} />
          ))}
        </CollapseCardBlock>
      </React.Fragment>
    ))}
  </>
);
