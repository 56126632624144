import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';

import { UiInput } from '../common/UI/Input';
import { UiFileUploader } from '../common/FileUploader';
import { ListElementLink } from '../common/ListElement/ListElementLink';
import { Header } from '../Main/Header';
import { UiBox } from '../common/UI/UiBox';
import { SubmitLoaderButton } from '../common/SubmitLoaderButton';
import { FeedbackFormType } from '../../models/Application/Feedback/interfaces';
import { defaultFileExtensions } from '../../config/system';

type Props = {
  onSubmit: (data: FeedbackFormType) => void;
  isLoading: boolean;
}

type FormValues = {
  description: string;
  file: File;
}

export const FeedbackFormComponent = ({ onSubmit, isLoading }: Props) => {
  const {
    register, handleSubmit, watch, errors, setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      description: '',
    },
  });

  const { state = {}, pathname } = useLocation<any>();

  const recipient = (state && state.model) ? state.model : { id: 0, name: '', fio: '' };
  /** нужно точно знать тип - отдел или сотрудник */
  recipient.isDepartment = get(state, 'model.isDepartment', false);

  const isValid = recipient.id && watch('description');

  const onLocalSubmit = (data: FormValues) => onSubmit({
    ...data,
    id: recipient.id,
    isDepartment: recipient.isDepartment,
  });

  useEffect(() => {
    register('file');
  }, [register]);

  return (
    <>
      <Header className="d-none-custom" />

      <UiBox className="contacts">
        <section className="form d-flex">
          <form autoComplete="off" className="form-with-btn" onSubmit={handleSubmit(onLocalSubmit)}>
            <div className="form-wrapper_content">
              <div className="wrapper">
                <ListElementLink
                  text="К кому обратиться?"
                  isTitle
                  isFollow
                  to={{ pathname: '/contacts-all', search: '?additional=departments', state: { from: pathname } }}
                >
                  <div className="item">{recipient.name || recipient.fio}</div>
                </ListElementLink>
                <UiInput
                  register={register}
                  name="description"
                  errors={errors}
                  type="textarea"
                  className="item mb-1"
                  placeholder="Введите текст обращения"
                  value={watch('description')}
                  rows={2}
                  showLength
                  required
                  disabled={!recipient.id}
                />
                <UiFileUploader
                  onChange={(files: File[]) => setValue('file', files[0])}
                  accept={defaultFileExtensions}
                  maxFilesCount={1}
                  disableUpload={isLoading || !recipient.id}
                />
              </div>
              <div className="buttons-group buttons-group-responsive justify-content-center">
                <SubmitLoaderButton
                  disabled={!isValid}
                  loading={isLoading}
                  label="Отправить обращение"
                />
              </div>
            </div>
            <div className="form-wrapper_btn">
              <div className="buttons-group buttons-group-responsive">
                <Link
                  className="btn btn-outline-light"
                  to="/contacts-all"
                >
                  Все контакты
                </Link>
              </div>
            </div>
          </form>
        </section>
      </UiBox>
    </>
  );
};
