import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import {
  SYSTEM_REFERENCES_GET_INSURANCE_FAIL,
  SYSTEM_REFERENCES_GET_INSURANCE_OK,
  SYSTEM_REFERENCES_GET_INSURANCE_START,
} from './constants';
import { IInsurancesModel } from '../../../models/Insurances/InsurancesModel';


export const systemRefGetInsuranceStartAction = (filter = {}) => dataAction(
  SYSTEM_REFERENCES_GET_INSURANCE_START,
)({ filter });
export const systemRefGetInsuranceOkAction = (insurances: IInsurancesModel) => dataSuccessAction(
  SYSTEM_REFERENCES_GET_INSURANCE_OK,
)({ insurances });
export const systemRefGetInsuranceFailAction = dataErrorAction(SYSTEM_REFERENCES_GET_INSURANCE_FAIL);
