import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Button, Col, Collapse, Row,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { ApplicationStatusEnum } from 'models/Application/interfaces';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';
import {
  appAssign, appComplete, appProcess, appReject, appApproval,
} from 'api/application';
import { defaultFileExtensions } from 'config/system';
import { handleErrors } from 'utils/errors';

import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiFileUploaderSystem } from 'components/common/FileUploader';
import { SystemApplicationResponsibleList } from 'systemModule/containers/Application/SystemApplicationResponsibleList';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';


type Props = {
  status: number;
  id: number;
  thisSectionName: string;
  isAddedSdsCoin?: boolean;
  isAvailableAccrualsBonuses: boolean;
}

type FormValues = {
  action: AppActionsEnum;
  comment: string;
  files: File[];
  addSdsCoin: boolean;
}

enum AppActionsEnum {
  process, // Взять в работу
  reject, // Отклонить
  cancel, // Отменить
  complete, // Выполнить
  assign, // Перевести другому исполнителю
  approve, // Согласовать
}

type TButton = {
  label: string;
  value: number;
  status?: number;
}

const BUTTONS_APPROVAL: TButton[] = [
  { label: 'chief.approve', value: AppActionsEnum.approve },
  { label: 'chief.reject', value: AppActionsEnum.reject },
];

const BUTTONS_OTHER: TButton[] = [
  { label: 'work', value: AppActionsEnum.process, status: ApplicationStatusEnum.work },
  { label: 'reject', value: AppActionsEnum.reject },
  { label: 'complete', value: AppActionsEnum.complete },
];

export const ApplicationItemForm = ({
  status, id, thisSectionName, isAddedSdsCoin, isAvailableAccrualsBonuses,
}: Props) => {
  const {
    register, handleSubmit, setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      files: [],
      addSdsCoin: isAddedSdsCoin,
    },
  });

  /** регистрация управляемых полей */
  useEffect(() => {
    register('action');
    register('files');
  }, [register]);

  const { t } = useTranslation();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [assignId, setAssignId] = useState<number>(0);

  const setActionValue = (val: AppActionsEnum) => setValue('action', val);

  /**
   * т.к. много кнопок и для каждой своя валидация, то валидация происходит при сабмите
   */
  const onSubmit = useCallback(async (data: FormValues) => {
    if (!data.comment.length && (
      [AppActionsEnum.reject, AppActionsEnum.complete, AppActionsEnum.assign].includes(data.action)
    )) {
      toast.warn('Необходимо заполнить поле "Комментарий"');
      return;
    }

    /** проверка и подготовка файлов */
    let uploadedFiles: string[];
    if (data.files.length) {
      handleErrors(
        await new UploadFiles(data.files).upload(),
        'save',
        (files) => {
          uploadedFiles = files.map((f) => f.file_name);
        },
      );
    }

    /** подготовка вызова метода на основании данных формы */
    const apiFn = () => {
      switch (data.action) {
        case AppActionsEnum.process:
          return appProcess;
        case AppActionsEnum.complete:
          return appComplete;
        case AppActionsEnum.assign:
          return appAssign;
        case AppActionsEnum.approve:
          return appApproval;
        default:
          return appReject;
      }
    };

    const requiredData = () => {
      switch (data.action) {
        case AppActionsEnum.reject:
        case AppActionsEnum.complete:
          return {
            comment: data.comment.length ? data.comment : undefined,
            files: uploadedFiles,
            add_sdscoin: isAvailableAccrualsBonuses ? data.addSdsCoin : undefined,
          };
        case AppActionsEnum.assign:
          return {
            assign_id: assignId,
            comment: data.comment.length ? data.comment : undefined,
            files: uploadedFiles,
          };
        case AppActionsEnum.process:
          return {
            add_sdscoin: isAvailableAccrualsBonuses ? data.addSdsCoin : undefined,
          };
        default:
          return undefined;
      }
    };

    setLoading(true);
    handleErrors(
      await apiFn()(id,
        requiredData()),
      'save',
      () => {
        toast.success(t('applications.item.saved'));
        history.push(`/system/${thisSectionName}`);
      },
    );
  }, [history, t, assignId, id, thisSectionName, isAvailableAccrualsBonuses]);

  const buttonList = status === ApplicationStatusEnum.approval ? BUTTONS_APPROVAL : BUTTONS_OTHER;

  return (
    <>
      <UiSystemForm
        dirtyFieldsAmount={0}
        disabledSubmit={false}
        loadingSubmit={loading}
        onSubmit={handleSubmit(onSubmit)}
        useCustomButtons
      >
        <UiSystemInput register={register} name="comment" type="textarea" label="Комментарий" />

        <UiFileUploaderSystem
          accept={defaultFileExtensions}
          onChange={(files) => setValue('files', files)}
        />

        {isAvailableAccrualsBonuses && (
          <div className="mt-3">
            <UiSystemCheckbox
              register={register}
              name="addSdsCoin"
              label="Начислять бонусы по этой заявке"
            />
          </div>
        )}

        <Row>
          <Col xs="12">
            <div className="buttons-group justify-content-end buttons-group-responsive">
              <Link to={`/system/${thisSectionName}`} className="btn btn-secondary">
                {t('common.cancel')}
              </Link>
              <>
                {
                  buttonList.map((buttonItem) => (
                    <Button
                      key={buttonItem.label}
                      type="submit"
                      color="primary"
                      disabled={isOpen}
                      onClick={() => setActionValue(buttonItem.value)}
                      hidden={status === buttonItem.status}
                    >
                      {t(`applications.btn.${buttonItem.label}`)}
                    </Button>
                  ))
                }
                <Button
                  color="primary"
                  onClick={() => setIsOpen(!isOpen)}
                  hidden={status === ApplicationStatusEnum.approval}
                >
                  {t('applications.btn.prepare_assign')}
                </Button>
              </>
            </div>
          </Col>
        </Row>

        {/** выбор нового ответственного */}
        <Collapse isOpen={isOpen}>
          {isOpen && (
            <>
              <SystemApplicationResponsibleList
                onSelect={setAssignId}
                assignId={assignId}
                applicationId={id}
              />
              <div className="buttons-group justify-content-end buttons-group-responsive">
                <Button onClick={() => setIsOpen(false)}>
                  {t('common.form.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={assignId === undefined}
                  onClick={() => setActionValue(AppActionsEnum.assign)}
                >
                  {t('applications.btn.assign')}
                </Button>
              </div>
            </>
          )}
        </Collapse>
      </UiSystemForm>
    </>
  );
};
