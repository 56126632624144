import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { ConstructApplicationTypeListModel } from 'models/ConstructApplication/ConstructApplicationTypeListModel';
import { DefaultFilterType } from 'models/common';
import {
  IConstructApplicationTypeListItemModel,
  IConstructApplicationTypeListModel,
} from 'models/ConstructApplication/interfaces';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  deleteConstructApplicationType, disableConstructApplicationType, enableConstructApplicationType,
  getConstructApplicationTypeList,
} from 'systemModule/api/construct-application';
import {
  ConstructApplicationTypeListComponent,
} from 'systemModule/components/ConstructApplication/Type/ConstructApplicationTypeList';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';


export const ConstructApplicationTypeList = () => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<DefaultFilterType>({});

  const getData = useCallback(setFilter, [setFilter]);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cApplication);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dApplication);

  const [data, isLoading] = usePromise<IConstructApplicationTypeListModel>(
    () => getConstructApplicationTypeList(filter),
    true,
    [filter],
  );

  const onDelete = async ({ id, name }: IConstructApplicationTypeListItemModel) => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: 'тип заявки', entityName: name }))) {
      handleErrors(
        await deleteConstructApplicationType(id),
        'delete',
        () => {
          toast.success(t('system.construct_application.type.deleted'));
          getData(cloneDeep(filter));
        },
      );
    }
  };

  const onChangeActive = useCallback((item: IConstructApplicationTypeListItemModel) => async () => {
    const saveModel = item.is_active ? disableConstructApplicationType : enableConstructApplicationType;

    handleErrors(
      await saveModel(item.id),
      t('system.construct_application.type.change_status.fail'),
      () => {
        toast.success(t('system.construct_application.type.change_status.success'));
        getData(cloneDeep(filter));
      },
    );
  }, [filter, getData, t]);

  return (
    <ConstructApplicationTypeListComponent
      data={data || new ConstructApplicationTypeListModel()}
      isLoading={isLoading}
      getData={getData}
      canCreate={canCreate}
      canDelete={canDelete}
      onDelete={onDelete}
      onChangeActive={onChangeActive}
    />
  );
};
