import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  ISystemNotificationTemplatesListModel,
} from '../../../models/Notification/SystemNotificationTemplatesListModel';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { CustomTable } from '../../../components/common/Table';
import { DefaultFilterType } from '../../../models/common';


type Props = {
  data: ISystemNotificationTemplatesListModel;
  isLoading: boolean;
  getData: (filter: DefaultFilterType) => void;
}

export const SystemNotificationTemplatesComponent = ({ data, isLoading, getData }: Props) => {
  const { setLimit, setPage } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  return (
    <>
      <h3>
        {t('notifications.templates.menu')}
      </h3>

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: 'ID' },
          { name: t('notifications.templates.name') },
          { name: t('notifications.templates.type') },
        ]}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={`notifications-templates/${item.id}/${item.action.code}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`notifications-templates/${item.id}/${item.action.code}`}>{item.action.name}</Link>
            </td>
            <td>{item.type.name}</td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
