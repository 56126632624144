/**
 *  Класс для загрузки изображений в формате base64
 */
class UploadAdapter {
  constructor(loader: any, t: any) {
    this.loader = loader;
    this.t = t;
  }

  loader: any; // Экземпляр FileLoader для использования во время загрузки
  t: any; // Метод перевода локали
  reader: FileReader = new FileReader();

  upload() {
    return new Promise((resolve, reject) => {
      this.reader.onload = () => {
        resolve({ default: this.reader.result });
      };

      this.reader.onerror = (error) => {
        reject(error);
      };

      this.reader.onabort = () => {
        reject();
      };

      this.loader.file.then((file: File) => {
        this.reader.readAsDataURL(file);
      });
    });
  }

  /** Сортировка процесса загрузки нужно для CKEditor */
  abort() {
    if (this.reader) {
      this.reader.abort();
    }
  }
}

export default function Base64Upload(editor: any) {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => new UploadAdapter(loader, editor.t);
}
