import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import invariant from 'invariant';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { PollStatusEnum } from '../../../models/Polls/PollsStatusesModel';
import { ISystemPollModel } from '../../../models/Polls/SystemPollModel';
import { ISystemPollsItemModel } from '../../../models/Polls/SystemPollsModel';
import {
  deleteSystemPoll, finishSystemPoll, resumeSystemPoll, stopSystemPoll,
} from '../../api/poll';
import { handleErrors } from '../../../utils/errors';


type Props = {
  model: ISystemPollModel | ISystemPollsItemModel;
  voterCount: number;
  onPollActionCompleted?: () => void;
}

type TActionsPoll = 'delete' | 'stop' | 'resume' | 'finish';

export const PollActions = ({ model, voterCount, onPollActionCompleted }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const onPollAction = useCallback(async (type: TActionsPoll) => {
    if (!model.id) {
      return;
    }

    invariant(type, 'Неопределенный тип заявки');

    let pollTypeActionDone = 'finished';
    const actionFn = () => {
      if (type === 'delete') {
        pollTypeActionDone = 'deleted';
        return deleteSystemPoll;
      }
      if (type === 'stop') {
        pollTypeActionDone = 'stopped';
        return stopSystemPoll;
      }
      if (type === 'resume') {
        pollTypeActionDone = 'resume';
        return resumeSystemPoll;
      }
      return finishSystemPoll;
    };

    handleErrors(
      await actionFn()(model.id),
      'save',
      () => {
        toast.success(t(`poll.${pollTypeActionDone}`));
        if (onPollActionCompleted) {
          onPollActionCompleted();
          return;
        }
        if (history.length > 2) {
          history.goBack();
        } else {
          history.push('/system/polls/all');
        }
      },
    );
  }, [model.id, history, t, onPollActionCompleted]);

  /** подтверждение и выполнение действий с сущностью */
  const onActionConfirm = (action: TActionsPoll) => () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(t(`common.form.${action}.question`, { entity: t('poll.entity.name'), entityName: model.title }))) {
      onPollAction(action);
    }
  };

  return (
    <>
      {model.status === PollStatusEnum.active && (
        <Button type="button" color="warning" onClick={onActionConfirm('stop')}>
          {t('common.form.stop')}
        </Button>
      )}
      {model.status === PollStatusEnum.stopped && (
        <Button type="button" color="success" onClick={onActionConfirm('resume')}>
          {t('common.form.resume')}
        </Button>
      )}
      {model.status !== PollStatusEnum.finished && (
        <Button type="button" color="danger" onClick={onActionConfirm('finish')}>
          {t('common.form.finish')}
        </Button>
      )}
      {voterCount === 0 && (
        <Button type="button" color="danger" onClick={onActionConfirm('delete')}>
          {t('common.form.delete')}
        </Button>
      )}
    </>
  );
};
