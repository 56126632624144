import { baseApiFn } from '../../../utils/baseApiFn';
import {
  httpDelete, httpGet, httpPost, httpPut,
} from '../../../utils/http';
import {
  IJobDescriptionsItemModel,
  IJobDescriptionsModel, JobDescriptionsItemModel,
  JobDescriptionsModel,
} from '../../../models/JobDescription/JobDescription';
import { CreateJobDescriptionType } from '../../../models/JobDescription/interfaces';

export const getJobDescriptionById = async (id: number) => baseApiFn<IJobDescriptionsItemModel>(
  async () => {
    const response = await httpGet(`/system/job-description/${id}`);
    return new JobDescriptionsItemModel(response);
  },
);

export const createJobDescription = async (data: CreateJobDescriptionType) => baseApiFn<IJobDescriptionsModel>(
  async () => {
    const response = await httpPost('/system/job-description', data);
    return new JobDescriptionsModel(response);
  },
);

export const updateJobDescription =
  async (data: CreateJobDescriptionType, id: number) => baseApiFn<IJobDescriptionsModel>(async () => {
    const response = await httpPut(`/system/job-description/${id}`, data);
    return new JobDescriptionsModel(response);
  });

export const deleteJobDescription = async (id: number) => baseApiFn<true>(
  httpDelete(`/system/job-description/${id}`, { id }),
);
