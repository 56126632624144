import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { BusStopTimetableComponent } from '../../components/BusStopTimetable/BusStopTimetable';
import { getBusStopTimetableIsLoadingSelector, getBusStopTimetableSelector } from './selectors';
import { getBusStopTimetableListFailAction, getBusStopTimetableListStartAction } from './actions';
import { BusStopTimetableParamsType } from '../../models/BusStopTimetable/BusStopTimetable';

export const BusStopTimetable = () => {
  const dispatch = useDispatch();
  const busStopTimetable = useSelector(getBusStopTimetableSelector);
  const busStopTimetableIsLoading = useSelector(getBusStopTimetableIsLoadingSelector);
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const getData = useCallback((Id: number, params?: BusStopTimetableParamsType) => {
    if (isNaN(numberId)) {
      dispatch(getBusStopTimetableListFailAction());
    } else {
      dispatch(getBusStopTimetableListStartAction(Id, params));
    }
  }, [numberId, dispatch]);

  useEffect(() => {
    getData(numberId);
  }, [numberId, getData]);

  return (
    <BusStopTimetableComponent
      busStopTimetable={busStopTimetable}
      busStopTimetableIsLoading={busStopTimetableIsLoading}
    />
  );
};
