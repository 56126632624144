import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, Label,
} from 'reactstrap';

import {
  DatesFormValues, defaultEmptyHolidayDates,
  HolidayFormValues, prepareRemoveHolidaysDates,
} from 'systemModule/components/Transport/common';

export type Props = {
  blockIndex: number;
  triggerValidation: () => any;
  register: (s: any) => any;
  unregister: (s: any) => any;
  fields: HolidayFormValues[];
  getValues: (s?: any) => any;
  setValue: (s: string, v: any, sv?: boolean) => any;
  dates: DatesFormValues[];
  formattedDate: string;
}

export function CheckboxOfCancel({
  fields, blockIndex, unregister,
  getValues, setValue, triggerValidation, dates, formattedDate, register,
}: Props) {
  const { t } = useTranslation();
  const haveField =
    !!fields.filter((item) => item?.date === formattedDate).length;
  const haveDate = !!dates.find((item) => item?.date === formattedDate);

  const setHolidayIsToDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (!haveDate) {
        const lastIndex = dates.length;
        Object.entries(defaultEmptyHolidayDates(formattedDate)).forEach(([key, value]) => {
          register(`blocks[${blockIndex}].holidaysDates[${lastIndex}].${key}`);
          setValue(`blocks[${blockIndex}].holidaysDates[${lastIndex}].${key}`, value);
        });
      }
      return;
    }
    if (haveDate) {
      const { holidaysDates } = getValues({ nest: true }).blocks[blockIndex];
      const dateIndex = holidaysDates.findIndex((item: DatesFormValues) => item?.date === formattedDate);
      if (dateIndex !== -1) {
        unregister(prepareRemoveHolidaysDates(blockIndex, dateIndex));
        triggerValidation();
      }
    }
  };

  return (
    <div className="form-check mb-3">
      <Input
        type="checkbox"
        name={`check_${blockIndex}`}
        id={`check_${blockIndex}`}
        onChange={setHolidayIsToDay}
        checked={haveDate && !haveField}
        disabled={haveField}
      />
      <Label check className="form-check-label" htmlFor={`check_${blockIndex}`}>
        {t('system.transport.route.holiday.void')}
      </Label>
    </div>
  );
}
