import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Card, Col, FormGroup, Row,
} from 'reactstrap';

import { UiFileUploaderSystem } from 'components/common/FileUploader';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import { SystemPartnersFormValuesModelType, SystemPartnersModelType } from 'models/Partners/SystemPartnerFormModel';
import { SelectOptionType } from 'utils/convertModelToOptions';
import { UiSelectComponent } from 'components/common/UI/Select';
import { UiWISWYGeditor } from 'components/common/UiWISWYGeditor';
import { defaultImgExtensions } from 'config/system';


type Props = {
  canUpdate: boolean;
  model: SystemPartnersModelType;
  categoriesOptions: SelectOptionType[];
  enterprisesOptions: SelectOptionType[];
  onSave: (m: SystemPartnersFormValuesModelType) => void;
  saving: boolean;
}

type FormValues = SystemPartnersFormValuesModelType;

export const SystemPartnersFormComponent = ({
  model, canUpdate, categoriesOptions, enterprisesOptions, onSave, saving,
}: Props) => {
  const { t } = useTranslation();

  const createMode = model === null;

  const {
    register, handleSubmit, errors, formState: { isValid }, setValue, watch, control,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      title: model.title,
      company_name: model.company_name,
      is_active: model.is_active,
      is_recommended: model.is_recommended,
      description: model.description,
      ref_video: model.ref_video,
      enterprises: (() => {
        const ids = model.enterprises.map((_) => _.id);
        const found = enterprisesOptions.filter((_) => ids.includes(_.value));
        return found.length ? found : [enterprisesOptions[0]];
      })(),
      categories: (() => {
        const ids = model.categories.map((_) => _.id);
        return categoriesOptions.filter((_) => ids.includes(_.value));
      })(),
      ref_partner: model.ref_partner,
      ref_apply: model.ref_apply,
      contacts: model.contacts.length ? model.contacts : [{ value: '', title: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  /** регистрация полей, управляемых вручную */
  useEffect(() => {
    register('logo');
    register('images');
    register('barcode');
    register('qr_code');
    register('contacts_image');
  }, [register]);

  const onSubmit = (data: FormValues) => {
    const strOrNull = (str: string | null): string | null => (str && str.length ? str : null);
    const fileOrNull = (data: File | null): File | null => (data instanceof File ? data : null);

    onSave({
      id: model.id,
      title: data.title,
      company_name: strOrNull(data.company_name) || '',
      description: strOrNull(data.description) || '',
      is_active: data.is_active,
      is_recommended: data.is_recommended,
      logo: fileOrNull(data.logo),
      images: data.images,
      barcode: fileOrNull(data.barcode),
      qr_code: fileOrNull(data.qr_code),
      ref_video: data.ref_video || null,
      ref_partner: data.ref_partner || null,
      ref_apply: data.ref_apply || null,
      contacts: data.contacts.filter((_) => _.title && _.value),
      contacts_image: fileOrNull(data.contacts_image),
      categories: data.categories,
      enterprises: data.enterprises,
    });
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={saving}
      dirtyFieldsAmount={0} // хак, т.к. тут может быть 5 измененных полей(загружаемые файлы)
      createMode={createMode}
      showButtons={canUpdate}
    >
      <h3>
        {t(`system.partners.form.${createMode ? 'create' : 'update'}`)}
      </h3>

      <UiSystemInput
        name="title"
        errors={errors}
        register={register}
        label={t('system.partners.form.name')}
        required
      />

      <UiSystemInput
        name="company_name"
        errors={errors}
        register={register}
        label={t('system.partners.form.company_name')}
        required
      />

      <UiSelectComponent
        value={watch('enterprises')}
        name="enterprises"
        options={enterprisesOptions}
        register={register}
        setValue={setValue}
        errors={errors}
        label={t('system.partners.form.enterprises')}
        multiple
        required
      />

      <UiSelectComponent
        value={watch('categories')}
        name="categories"
        options={categoriesOptions}
        register={register}
        setValue={setValue}
        errors={errors}
        label={t('system.partners.form.categories')}
        multiple
        required
      />

      <FormGroup>
        <strong>
          {t('system.partners.form.logo')}
        </strong>
        <UiFileUploaderSystem
          accept={defaultImgExtensions}
          initFileTypes={model.logo ? [model.logo] : []}
          onChange={(files: File[]) => setValue('logo', files[0])}
          withPreview
        />
      </FormGroup>

      <UiSystemCheckbox
        name="is_active"
        register={register}
        label={t('system.partners.form.is_active')}
      />

      <UiSystemCheckbox
        name="is_recommended"
        register={register}
        label={t('system.partners.form.is_recommended')}
      />

      <FormGroup>
        <strong>
          {t('system.partners.form.description')}
        </strong>
        <UiWISWYGeditor register={register} watch={watch} setValue={setValue} name="description" />
      </FormGroup>

      <FormGroup>
        <strong>
          {t('system.partners.form.images')}
        </strong>
        <UiFileUploaderSystem
          accept={defaultImgExtensions}
          initFileTypes={model.images ? model.images : []}
          maxFilesCount={5}
          onChange={(files: File[]) => setValue('images', files)}
          withPreview
        />
      </FormGroup>

      <UiSystemInput
        name="ref_video"
        errors={errors}
        register={register}
        label={t('system.partners.form.ref_video')}
      />

      <FormGroup>
        <strong>
          {t('system.partners.form.barcode')}
        </strong>
        <UiFileUploaderSystem
          accept={defaultImgExtensions}
          initFileTypes={model.barcode ? [model.barcode] : []}
          onChange={(files: File[]) => setValue('barcode', files[0])}
          withPreview
        />
      </FormGroup>

      <FormGroup>
        <strong>
          {t('system.partners.form.qr_code')}
        </strong>
        <UiFileUploaderSystem
          accept={defaultImgExtensions}
          initFileTypes={model.qr_code ? [model.qr_code] : []}
          onChange={(files: File[]) => setValue('qr_code', files[0])}
          withPreview
        />
      </FormGroup>

      <UiSystemInput
        name="ref_partner"
        errors={errors}
        register={register}
        label={t('system.partners.form.ref_partner')}
      />

      <UiSystemInput
        name="ref_apply"
        errors={errors}
        register={register}
        label={t('system.partners.form.ref_apply')}
      />

      <FormGroup>
        <Card body>
          <strong>
            {t('system.partners.form.contacts')}
          </strong>
          <Row>
            <Col xs={5}>
              <strong>{t('system.partners.form.contacts.title')}</strong>
            </Col>
            <Col xs={5}>
              <strong>{t('system.partners.form.contacts.value')}</strong>
            </Col>
            <Col xs={2} />
          </Row>
          {fields.map((item, itemIndex) => (
            <Row key={item.id}>
              <Col xs={5}>
                <UiSystemInput
                  errors={errors}
                  register={register}
                  name={`contacts[${itemIndex}].title`}
                />
              </Col>
              <Col xs={5}>
                <UiSystemInput
                  errors={errors}
                  register={register}
                  name={`contacts[${itemIndex}].value`}
                />
              </Col>
              <Col xs={2}>
                {(canUpdate && fields.length > 1) && (
                  <Button type="button" color="danger" onClick={() => remove(itemIndex)}>
                    Удалить
                  </Button>
                )}
              </Col>
            </Row>
          ))}

          {(canUpdate && fields.length < 10) && (
            <Button
              type="button"
              color="success"
              onClick={() => append({
                title: '',
                value: '',
              })}
            >
              Добавить
            </Button>
          )}
        </Card>
      </FormGroup>

      <FormGroup>
        <strong>
          {t('system.partners.form.contacts_image')}
        </strong>
        <UiFileUploaderSystem
          accept={defaultImgExtensions}
          initFileTypes={model.contacts_image ? [model.contacts_image] : []}
          onChange={(files: File[]) => setValue('contacts_image', files[0])}
          withPreview
        />
      </FormGroup>
    </UiSystemForm>
  );
};
