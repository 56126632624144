import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { IBusStopScheduleForm, IRouteFormModel } from 'models/Transport/interfaces';
import { convertModelToOptions } from 'utils/convertModelToOptions';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiSelect } from 'components/common/UI/Select';
import { UiSystemForm } from 'components/common/UI/Form';
import { ISystemRouteDetailModel } from 'models/Transport/SystemRouteModel';
import { ISystemBusStopModel } from 'models/References/BusStopsModel';
import {
  FormValues,
  defaultEmptyBlock,
  prepareSchedule,
  prepareRemoveWeekDaysField,
  prepareRemoveHolidaysField,
  prepareRemoveHolidaysDates,
  defaultEmptyHolidayDates,
} from 'systemModule/components/Transport/common';
import { ScheduleBlock } from 'systemModule/components/Transport/SheduleBlock';


type Props = {
  busStops: ISystemBusStopModel[];
  enterpriseList: IEnterpriseModel[];
  model: ISystemRouteDetailModel;
  onSave: (model: IRouteFormModel, schedule: IBusStopScheduleForm[]) => void;
}

export const SystemTransportFormComponent = ({
  busStops,
  enterpriseList = [],
  model,
  onSave,
}: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, watch, errors, setValue, unregister,
    formState: { isSubmitting, dirtyFields, isValid }, getValues, triggerValidation,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
      enterprise: isEmpty(model.enterprise) ? null : convertModelToOptions([model.enterprise])[0],
      blocks: (() => {
        if (model.schedule.length) {
          return model.schedule.map((item) => ({
            id: Math.random(),
            busStop: convertModelToOptions([item])[0] || null,
            haveHolidaySchedule: !!item.holidayDates.length,
            fields: item.times,
            holidaysFields: item.holidayTimes,
            holidaysDates: item.holidayDates,
          }));
        }
        return [defaultEmptyBlock()];
      })(),
    },
  });

  const { blocks } = watch({ nest: true });

  const onSubmit = async ({
    enterprise, name, blocks: allBlocks,
  }: FormValues) => {
    const modelToSave: IRouteFormModel = {
      name,
      enterprise_id: enterprise?.value,
    };
    onSave(modelToSave, prepareSchedule(allBlocks));
  };

  useEffect(() => {
    /** зарегистрировать ручные поля блоков при старте */
    getValues({ nest: true }).blocks
      .forEach((existBlock, existBlockIndex) => {
        register(`blocks[${existBlockIndex}].id`);
        // Зарегистрировать поля дат
        if (existBlock.fields) {
          existBlock.fields.forEach((_, existFieldIndex) => {
            register(`blocks[${existBlockIndex}].fields[${existFieldIndex}].id`);
            register(`blocks[${existBlockIndex}].fields[${existFieldIndex}].day`);
          });
        }
        if (existBlock.holidaysFields) {
          existBlock.holidaysFields.forEach((_, existFieldIndex) => {
            register(`blocks[${existBlockIndex}].holidaysFields[${existFieldIndex}].id`);
            register(`blocks[${existBlockIndex}].holidaysFields[${existFieldIndex}].date`);
          });
        }
        // зарегистрировать праздничные даты, для календаря.
        const holidays = model.schedule[existBlockIndex]?.holidayDates || null;
        if (holidays) {
          holidays.forEach((item, existFieldIndex) => {
            Object.entries(defaultEmptyHolidayDates(item.date, item.id)).forEach(([key, value]) => {
              register(`blocks[${existBlockIndex}].holidaysDates[${existFieldIndex}].${key}`);
              setValue(`blocks[${existBlockIndex}].holidaysDates[${existFieldIndex}].${key}`, value);
            });
          });
        }
      });
  }, [register, getValues, setValue, model]);

  /** управление блоками */
  const appendBlock = useCallback(() => {
    const newBlockIndex = getValues({ nest: true }).blocks.length;

    /** зарегистрировать новый блок */
    Object.entries(defaultEmptyBlock()).forEach(([key, value]) => {
      if (!Array.isArray(value)) {
        register(`blocks[${newBlockIndex}].${key}`);
        setValue(`blocks[${newBlockIndex}].${key}`, value);
      }
    });
  }, [register, getValues, setValue]);

  const removeBlock = useCallback((blockId: number) => {
    const localBlocks = getValues({ nest: true }).blocks;
    const blockIndex = localBlocks.findIndex((b) => b?.id === blockId);

    /** разрегистрировать блок по ид и все его поля */
    if (blockIndex !== -1) {
      unregister([
        `blocks[${blockIndex}].id`,
        `blocks[${blockIndex}].busStop`,
        `blocks[${blockIndex}].haveHolidaySchedule`,
        `blocks[${blockIndex}].fields`,
        `blocks[${blockIndex}].holidaysFields`,
        `blocks[${blockIndex}].holidaysDates`,
      ]);

      const currentBlock = localBlocks[blockIndex];

      if (currentBlock.fields) {
        currentBlock.fields.forEach((_, fieldIndex) => unregister(
          prepareRemoveWeekDaysField(blockIndex, fieldIndex),
        ));
      }
      if (currentBlock.holidaysFields) {
        currentBlock.holidaysFields.forEach((_, fieldIndex) => unregister(
          prepareRemoveHolidaysField(blockIndex, fieldIndex),
        ));
      }
      if (currentBlock.holidaysDates) {
        currentBlock.holidaysDates.forEach((_, fieldIndex) => unregister(
          prepareRemoveHolidaysDates(blockIndex, fieldIndex),
        ));
      }
      triggerValidation();
    }
  }, [unregister, getValues, triggerValidation]);
  /** end управление блоками */

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode
    >
      <h3>{t(model.id ? 'system.transport.route.updating' : 'system.transport.route.creating')}</h3>

      <UiSelect
        defaultValue={getValues('enterprise')}
        errors={errors}
        register={register}
        name="enterprise"
        label={t('common.enterprise')}
        setValue={setValue}
        options={convertModelToOptions(enterpriseList)}
        required
      />

      <UiSystemInput
        name="name"
        errors={errors}
        required
        register={register}
        label={t('system.transport.route.name')}
      />

      {blocks.map((block, i) => (
        <ScheduleBlock
          key={`block${block.id}`}
          block={block}
          blockAmount={blocks.filter((v) => v).length}
          last={i === blocks.length - 1}
          setValue={setValue}
          blockIndex={i}
          getValues={getValues}
          errors={errors}
          register={register}
          unregister={unregister}
          busStops={busStops}
          triggerValidation={triggerValidation}
          appendBlock={appendBlock}
          removeBlock={removeBlock}
        />
      ))}

    </UiSystemForm>
  );
};
