import { AnyAction } from 'redux';
import cloneDeep from 'lodash/cloneDeep';

import { onFail, onLoad, onLoading } from '../../utils/reducer';
import { IPollsModel, PollsModel } from '../../models/Polls/PollsModel';
import {
  POLLS_GET_LIST_FAIL,
  POLLS_GET_LIST_OK,
  POLLS_GET_LIST_START,
} from './constants';


export type pollsReducerType = {
  polls: IPollsModel;
  pollsIsLoading: boolean;
}

const initialState: pollsReducerType = {
  polls: new PollsModel(),
  pollsIsLoading: true,
};

export const storeName = 'polls';

export const pollsReducer = {
  [storeName]: (state: pollsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case POLLS_GET_LIST_START:
        return onLoading(state, 'polls');
      case POLLS_GET_LIST_OK: {
        if (payload.append) {
          /** т.к. бесконечная прокрута, то добавлять в текущие данные */
          const stateData = cloneDeep(state.polls.data);

          const newStateData = {
            ...payload.polls,
            data: stateData.concat(payload.polls.data),
          };

          return {
            ...state,
            polls: newStateData,
            pollsIsLoading: false,
          };
        }
        return onLoad(state, payload, 'polls');
      }
      case POLLS_GET_LIST_FAIL:
        return onFail(state, 'polls');

      default:
        return state;
    }
  },
};
