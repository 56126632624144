export const GET_CURRENT_USER = '[CURRENT_USER]_GET_CURRENT_USER_[START]';
export const GET_CURRENT_USER_OK = '[CURRENT_USER]_GET_CURRENT_USER_[OK]';
export const GET_CURRENT_USER_FAIL = '[CURRENT_USER]_GET_CURRENT_USER_[FAIL]';

export const EDIT_CURRENT_USER_START = '[CURRENT_USER] edit current user [start]';
export const EDIT_CURRENT_USER_OK = '[CURRENT_USER] edit current user [ok]';
export const EDIT_CURRENT_USER_FAIL = '[CURRENT_USER] edit current user [fail]';

export const CURRENT_USER_UPDATE = '[CURRENT USER] update user info';

export const CURRENT_USER_UPDATE_START = '[CURRENT USER] update current user [start]';
export const CURRENT_USER_UPDATE_OK = '[CURRENT USER] update current user [ok]';
export const CURRENT_USER_UPDATE_FAIL = '[CURRENT USER] update current user [fail]';
export const CURRENT_USER_CLEAR_DATA = '[CURRENT USER] clear user data';
