import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UiBox } from 'components/common/UI/UiBox';
import { ListElement } from 'components/common/ListElement/ListElement';
import { IPageReferralProgramModel } from 'models/References/PageReferralProgramModel';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';

type Props = {
  referralCode: string;
  model: IPageReferralProgramModel | null;
  loading: boolean;
}

export const ReferralProgramComponent = ({ referralCode, model, loading }: Props) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      toast.success(t('referralProgram.copy_code.success'));
    }
  };

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/" />
        {t('referral.menu')}
      </div>

      <UiBox className="referral-program">
        <section className="form">
          <div className="mb-4">
            <ListElement
              text={referralCode}
              info="Ваш реферальный код"
              isTitle
              isPrimary
              isPointer
              onClick={copyToClipboard}
            />
            <input
              className="hidden-input"
              type="referralCode"
              defaultValue={referralCode}
              name="referralCode"
              id="referralCode"
              ref={inputRef}
            />
          </div>
          <div>
            {
              loading ? <Loader /> : (
                <>
                  {model ? (
                    <>
                      {/* eslint-disable-next-line react/no-danger */}
                      <div className="articles-list_item__desc" dangerouslySetInnerHTML={{ __html: model.text }} />
                    </>
                  ) : <EntityNotFound message={t('references.page.referralProgram.404')} />}
                </>
              )
            }
          </div>
        </section>
      </UiBox>
    </>
  );
};
