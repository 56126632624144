import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from 'utils/reducer';
import {
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_FAIL,
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_OK,
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_START, SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_FAIL,
  SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_OK,
  SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_START,
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_FAIL,
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_OK,
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_START,
} from 'systemModule/containers/BonusProgram/constants';
import {
  SYSTEM_REFERENCES_GET_TAG_LIST_FAIL,
  SYSTEM_REFERENCES_GET_TAG_LIST_OK,
  SYSTEM_REFERENCES_GET_TAG_LIST_START,
} from 'systemModule/containers/References/BonusProgram/constants';
import { ISystemBonusProgramPrizeListModel } from 'models/BonusProgram/Prize/interfaces';
import { SystemBonusProgramPrizeListModel } from 'models/BonusProgram/Prize/SystemBonusProgramPrizeModel';
import { ISystemBonusProgramTagListModel } from 'models/BonusProgram/Tag/interfaces';
import { SystemBonusProgramTagListModel } from 'models/BonusProgram/Tag/SystemBonusProgramTagModel';
import { ISystemBonusProgramEventHistoryListModel } from 'models/BonusProgram/EventHistory/interfaces';
import {
  SystemBonusProgramEventHistoryListModel,
} from 'models/BonusProgram/EventHistory/SystemBonusProgramEventHistoryModel';
import { SystemBonusProgramEventListModel } from 'models/BonusProgram/Event/SystemBonusProgramEventModel';
import { ISystemBonusProgramEventListModel } from 'models/BonusProgram/Event/interfaces';

export type systemBonusProgramReducerType = {
  prizeList: ISystemBonusProgramPrizeListModel;
  prizeListIsLoading: boolean;

  tagList: ISystemBonusProgramTagListModel;
  tagListIsLoading: boolean;

  eventHistoryList: ISystemBonusProgramEventHistoryListModel;
  eventHistoryListIsLoading: boolean;

  eventList: ISystemBonusProgramEventListModel,
  eventListIsLoading: boolean;
}

const initialState: systemBonusProgramReducerType = {
  prizeList: new SystemBonusProgramPrizeListModel(),
  prizeListIsLoading: true,

  tagList: new SystemBonusProgramTagListModel(),
  tagListIsLoading: true,

  eventHistoryList: new SystemBonusProgramEventHistoryListModel(),
  eventHistoryListIsLoading: true,

  eventList: new SystemBonusProgramEventListModel(),
  eventListIsLoading: true,
};

export const storeName = 'bonusProgram';

export const systemBonusProgramReducer = {
  [storeName]: (state: systemBonusProgramReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_START:
        return onLoading(state, 'prizeList');
      case SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_OK:
        return onLoad(state, payload, 'prizeList');
      case SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_FAIL:
        return onFail(state, 'prizeList');

      case SYSTEM_REFERENCES_GET_TAG_LIST_START:
        return onLoading(state, 'tagList');
      case SYSTEM_REFERENCES_GET_TAG_LIST_OK:
        return onLoad(state, payload, 'tagList');
      case SYSTEM_REFERENCES_GET_TAG_LIST_FAIL:
        return onFail(state, 'tagList');

      case SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_START:
        return onLoading(state, 'eventHistoryList');
      case SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_OK:
        return onLoad(state, payload, 'eventHistoryList');
      case SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_FAIL:
        return onFail(state, 'eventHistoryList');

      case SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_START:
        return onLoading(state, 'eventList');
      case SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_OK:
        return onLoad(state, payload, 'eventList');
      case SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_FAIL:
        return onFail(state, 'eventList');

      default:
        return state;
    }
  },
};
