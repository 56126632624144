import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import {
  put, takeLatest, call, select,
} from 'redux-saga/effects';
import { ICurrentUserProfileModel } from '../../models/User/UserProfileModel';

import { baseSaga } from '../baseSaga';
import * as CONSTANTS from './constants';
import { CurrentUserModel } from '../../models/User/CurrentUserModel';
import { httpGet, httpPut } from '../../utils/http';
import { UserRightModel } from '../../models/User/UserRightsModel';
import {
  editCurrentUserActionFailAction, editCurrentUserActionOkAction,
  getCurrentUserFailAction,
  getCurrentUserOkAction, updateCurrentUserFailAction, updateCurrentUserOKAction,
} from './actions';
import { getCurrentUserProfileSelector } from './selectors';

/**
 * Получить пользователя, если пользователь есть в сторе, то ничего не делать
 */
export function* getCurrentUserSaga() {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/user');

    /** права пользователя, получить сразу */
    const rights = yield httpGet('/public/rbac/user-rights');

    /** rights - некий массивоподобный объект. по ключам надо брать */
    const userRightsModel = Object
      .keys(rights)
      .map((key) => rights[key])
      .map((r) => new UserRightModel(r));

    yield put(getCurrentUserOkAction(new CurrentUserModel(response, userRightsModel)));
  }, getCurrentUserFailAction);
}

/** Для повторного запроса в разделе "Профиль" */
export function* updateCurrentUserSaga() {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/user');

    yield put(updateCurrentUserOKAction(response));
  }, updateCurrentUserFailAction);
}

export function* editCurrentUserSaga({ payload: { data } }: AnyAction) {
  yield baseSaga(function* () {
    const profile: ICurrentUserProfileModel = yield select(getCurrentUserProfileSelector);

    const response = yield httpPut('/public/user', {
      avatar: profile.avatar.name,
      oms_series: profile.oms_series,
      oms_number: profile.oms_number,
      is_housing_from_sds: profile.is_housing_from_sds,
      housing_area: profile.housing_area,
      hobby: profile.hobby,
      emergency_number: profile.emergency_number,
      insurances: profile.insurances.map((node) => node.id),
      sports: profile.userSports.map((node) => ({
        id: node.sport.id,
        progress: node.progress,
      })),
      ...data,
    });

    if (response) {
      toast.success('Данные успешно изменены');
      yield put(editCurrentUserActionOkAction(response));
    }
  }, editCurrentUserActionFailAction);
}

export function* currentUserSagas() {
  yield takeLatest(CONSTANTS.GET_CURRENT_USER, getCurrentUserSaga);
  yield takeLatest(CONSTANTS.EDIT_CURRENT_USER_START, editCurrentUserSaga);
  yield takeLatest(CONSTANTS.CURRENT_USER_UPDATE_START, updateCurrentUserSaga);

  yield call(getCurrentUserSaga);
}
