import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import {
  IApplicationTypeListFilter,
  IConstructApplicationCategoryListItemModel,
} from '../../../../models/ConstructApplication/interfaces';
import {
  convertModelToOptions,
  SimpleSelectOptionType,
} from '../../../../utils/convertModelToOptions';
import { IEnterpriseModel } from '../../../../models/Enterprises/EnterpriseModel';
import { UiToolbar } from '../../../../components/common/Toolbar';
import { UiSelect } from '../../../../components/common/UI/Select';
import { UiSystemInput } from '../../../../components/common/UI/Input';

type Props = {
  onSubmit: (data: IApplicationTypeListFilter) => void;
  onReset: () => void;
  categoriesList: IConstructApplicationCategoryListItemModel[];
  enterpriseList: IEnterpriseModel[];
  getUsers: (enterpriseId: number) => void;
  usersOptions: SimpleSelectOptionType<number>[];
}

const activeOptions = [
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
];

type FormValues = {
  name: string;
  enterprise_id: SimpleSelectOptionType<number> | null;
  assign_user_id: SimpleSelectOptionType<number> | null;
  category_id: SimpleSelectOptionType<number> | null;
  is_active: SimpleSelectOptionType<boolean> | null;
}

export const SystemApplicationTypeListFilter = ({
  onSubmit,
  onReset,
  categoriesList,
  enterpriseList,
  getUsers,
  usersOptions,
}: Props) => {
  const { t } = useTranslation();

  const enterprisesOptions = useMemo(() => [
    ...convertModelToOptions(enterpriseList),
  ], [enterpriseList]);

  const categoriesOptions = useMemo(() => [
    ...convertModelToOptions(categoriesList),
  ], [categoriesList]);

  const {
    register, handleSubmit, setValue, errors, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      enterprise_id: null,
      assign_user_id: null,
      category_id: null,
      is_active: null,
    },
  });

  const watchEnterpise = watch('enterprise_id');
  useEffect(() => {
    if (watchEnterpise) {
      getUsers(watchEnterpise.value);
    }
  }, [watchEnterpise, getUsers]);

  const onLocalSubmit = (data: FormValues) => onSubmit({
    name: data.name.length ? data.name : undefined,
    enterprise_id: data.enterprise_id?.value,
    assign_user_id: data.assign_user_id?.value,
    category_id: data.category_id?.value,
    is_active: data.is_active?.value,
  });
  const onLocalReset = () => {
    reset();
    onReset();
  };

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('system.construct_application.type.name')}
            name="name"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="enterprise_id"
            errors={errors}
            options={enterprisesOptions}
            value={watch('enterprise_id')}
            label={t('system.construct_application.type.enterprise')}
            allowEmptyValue
            isClearable
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            label={t('system.construct_application.type.assignUser')}
            register={register}
            setValue={setValue}
            name="assign_user_id"
            options={usersOptions}
            allowEmptyValue
            value={watch('assign_user_id')}
            isClearable
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            label={t('system.construct_application.type.category')}
            register={register}
            setValue={setValue}
            name="category_id"
            options={categoriesOptions}
            allowEmptyValue
            value={watch('category_id')}
            isClearable
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="is_active"
            errors={errors}
            options={activeOptions}
            value={watch('is_active')}
            label={t('system.construct_application.type.activity')}
            allowEmptyValue
            isClearable
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
