import React, { useState } from 'react';
import {
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationLink,
  Row,
} from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

import { defaultSizeByPageTable } from 'config/system';
import { SimpleTable } from './SimpleTable';


export interface TableHeaderInterface {
  name: string;
  width?: string;
}


type Props = {
  tableHeaders: Array<TableHeaderInterface>;
  showPerPageText?: string;
  sizePerPage: number;
  setSizePerPage: [(n: number) => void, (n: number) => void];
  amountOfPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  children: React.ReactNode[] | React.ReactNode;
  isLoading?: boolean;
  customButtons?: React.ReactNode;
}

// еще пошлифуется
export const CustomTable = ({
  tableHeaders, sizePerPage,
  setSizePerPage, amountOfPages, currentPage,
  onChangePage, children,
  showPerPageText = '',
  isLoading = false,
  customButtons,
}: Props) => {
  const [isOpenSpp, setIsOpenSpp] = useState(false);

  const { t } = useTranslation();

  const perPageText = showPerPageText || t('common.table.showPerPage');

  return (
    <div className="position-relative">
      <Row className="mb-3">
        <Col>
          {perPageText}
          <ButtonDropdown className="ml-1" isOpen={isOpenSpp} toggle={() => setIsOpenSpp(!isOpenSpp)}>
            <DropdownToggle caret color="secondary">
              {sizePerPage}
            </DropdownToggle>
            <DropdownMenu>
              {defaultSizeByPageTable.map((i: number) => (
                <DropdownItem
                  key={i}
                  onClick={() => {
                    setSizePerPage[0](i);
                    setSizePerPage[1](1);
                  }}
                >
                  {i}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        {customButtons && (
          <Col className="text-right">
            {customButtons}
          </Col>
        )}
      </Row>
      <SimpleTable
        tableHeaders={tableHeaders}
        isLoading={isLoading}
      >
        {children}
      </SimpleTable>
      {
        // не выводить, если только 1 страница
        (amountOfPages > 1) && (
          <Row>
            <Col className="d-flex justify-content-center">
              <Pagination>
                <ReactPaginate
                  previousLabel={currentPage === 1 ? null : <PaginationLink previous />}
                  nextLabel={amountOfPages === currentPage ? null : <PaginationLink next />}
                  breakLabel={<PaginationLink>...</PaginationLink>}
                  initialPage={currentPage - 1}
                  forcePage={currentPage - 1}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  pageCount={amountOfPages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={({ selected }: any) => onChangePage(selected + 1)}
                  containerClassName="pagination custom-pagination"
                  activeClassName="active"
                />
              </Pagination>
            </Col>
          </Row>
        )
      }
    </div>
  );
};
