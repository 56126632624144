import React from 'react';

import { IReportApplication } from '../../../../models/Reports/interfaces';
import { CollapseCardBlock } from '../common/CollapseCardBlock';
import { CardBlock } from '../common/CardBlock';

type Props = {
  model: IReportApplication;
}

export const SystemReportApplicationComponent = ({ model }: Props) => (
  <>
    {model.items.map((item) => (
      <React.Fragment key={item.id}>
        <CollapseCardBlock name={item.name} count={item.application_count}>
          {item.statuses.map((status) => (
            <CardBlock key={status.id} name={status.name} count={status.application_count} />
          ))}
        </CollapseCardBlock>
      </React.Fragment>
    ))}
  </>
);
