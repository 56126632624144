import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { IApplicationFilter, IApplicationsListModel } from '../../../models/Application/interfaces';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { CDate } from '../../../utils/CDate';
import { CustomTable } from '../../../components/common/Table';
import { SystemAssignApplicationsFilter } from './Filter';


type Props = {
  data: IApplicationsListModel;
  isLoading: boolean;
  getData: (filter: IApplicationFilter) => void;
}

export const SystemAssignApplicationsComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage, onSubmitFilter, onResetFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  return (
    <>
      <h3>
        {t('applications.assign.menu')}
      </h3>

      <SystemAssignApplicationsFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: t('Номер заявки') },
          { name: t('Тип заявки') },
          { name: t('Автор') },
          { name: t('Дата создания') },
          { name: t('Статус') },
          { name: t('Предприятие') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={`assign-applications/${item.id}/${item.type.id}`}>{item.id}</Link>
            </td>
            <td>
              {item.type.name}
            </td>
            <td>
              {item.author.fio}
            </td>
            <td>
              {CDate.format(item.created_at, 'dd MMMM yyyy')}
            </td>
            <td>
              {item.status.name}
            </td>
            <td>
              {item.enterprise}
            </td>
            <td className="text-center">
              <Button
                color="primary"
                className="w-100"
                tag={Link}
                to={`assign-applications/${item.id}/${item.type.id}`}
              >
                Подробнее
              </Button>
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
