import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IDepartmentItemModel } from 'models/References/DepartmentModel';
import { UiToolbar } from 'components/common/Toolbar';
import { UiSelect } from 'components/common/UI/Select';
import { convertModelToOptions, SelectOptionType } from 'utils/convertModelToOptions';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { IApplicationResponsibleFilterType } from 'models/Application/Responsible/interfaces';
import { hasUnemptyValues } from 'utils/objects';
import {
  systemEnterprisesSelector,
  systemGlobalDepartmentsSelector,
} from '../../store/selectors';


type Props = {
  onSubmit: (data: IApplicationResponsibleFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  enterprise_id: SelectOptionType | null;
  department_id: SelectOptionType | null;
}

export const SystemResponsibleFilter = ({ onSubmit, onReset }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, setValue, errors, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise_id: null,
      department_id: null,
    },
  });

  const departments: IDepartmentItemModel[] = useSelector(systemGlobalDepartmentsSelector);
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const onLocalSubmit = (data: FormValues) => {
    const preparedFilterData = {
      enterprise_id: data.enterprise_id ? data.enterprise_id.value : undefined,
      department_id: data.department_id ? data.department_id.value : undefined,
    };

    if (hasUnemptyValues(preparedFilterData)) {
      onSubmit(preparedFilterData);
    }
  };

  const onLocalReset = () => {
    reset();
    onReset();
  };

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset} useFormTag={false}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="enterprise_id"
            errors={errors}
            options={convertModelToOptions(enterprises)}
            value={watch('enterprise_id')}
            label={t('common.enterprise')}
            allowEmptyValue
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="department_id"
            errors={errors}
            options={convertModelToOptions(departments)}
            value={watch('department_id')}
            label={t('common.department')}
            allowEmptyValue
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
