import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FilterIcon } from '../../assets/img/filter.svg';
import { UiModal } from '../common/UI/Modal';
import { UiInputSearch } from '../common/UI/InputSearch';
import { IPollsFilterType } from '../../models/Polls/PollsModel';
import { UiRadioGroup } from '../common/UI/RadioGroup';


type PropsFilter = {
  onSubmit: (data: IPollsFilterType) => void;
  filterData: IPollsFilterType;
}

type FormValues = {
  was_passed: '-1' | '0' | '1';
}

const FilterForm = ({ filterData, onSubmit }: PropsFilter) => {
  const {
    register, handleSubmit, reset,
  } = useForm<FormValues>({
    defaultValues: {
      was_passed: filterData.was_passed || '-1',
    },
  });

  const onSearch = useCallback((data: FormValues) => {
    onSubmit({
      was_passed: data.was_passed === '-1' ? undefined : data.was_passed,
    });
  }, [onSubmit]);

  const { t } = useTranslation();

  return (
    <form autoComplete="off" className="modal-content" onSubmit={handleSubmit(onSearch)}>
      <div className="form-with-btn">
        <div className="form-wrapper_content">
          <UiRadioGroup
            name="was_passed"
            options={[
              { value: '-1', label: t('polls.passed.all') },
              { value: '1', label: t('polls.passed') },
              { value: '0', label: t('polls.not_passed') },
            ]}
            register={register}
          />
        </div>

        <div className="form-wrapper_btn">
          <div className="buttons-group buttons-group-responsive">
            <Button color="primary" type="submit">
              {t('common.filter.apply')}
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => reset({ was_passed: '-1' })}
              className="btn--clear-filter"
            >
              {t('common.form.clear_filter')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};


type Props = {
  onChange: (data: IPollsFilterType) => void;
  filterData: IPollsFilterType;
}

type FormValuesFilter = {
  title: string;
}

export const PollsFilter = ({ onChange, filterData }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<FormValuesFilter>({
    mode: 'onChange',
  });

  const onSearch = useCallback((data: FormValuesFilter) => onChange(data), [onChange]);

  const onSubmit = useCallback((data: IPollsFilterType) => {
    onChange(data);
    setIsOpen(false);
  }, [onChange]);

  return (
    <div className="d-flex justify-content-end">
      <UiInputSearch
        register={register}
        name="title"
        handleSubmit={handleSubmit(onSearch)}
      />
      <span className="filter ml-3" role="presentation" onClick={() => setIsOpen(true)}>
        <FilterIcon />
      </span>
      <UiModal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} headerContent={t('polls.filter.head')}>
        <FilterForm filterData={filterData} onSubmit={onSubmit} />
      </UiModal>
    </div>
  );
};
