import React from 'react';
import { useTranslation } from 'react-i18next';

import { IReportHousingList } from '../../../../models/Reports/interfaces';
import { DefaultFilterType } from '../../../../models/common';
import { useDataPage } from '../../../../utils/hooks/useDataPage';
import { CustomTable } from '../../../../components/common/Table';
import { SystemReportHousingFilter } from './Filter';


type Props = {
  data: IReportHousingList;
  isLoading: boolean;
  getData: (filter: DefaultFilterType) => void;
}

export const SystemReportHousingComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage, onSubmitFilter, onResetFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  return (
    <>
      <h3>{t('reports.housing.title')}</h3>
      <SystemReportHousingFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: 'ID' },
          { name: 'ФИО сотрудника' },
          { name: 'Площадь' },
          { name: 'Телефон сотрудника' },
          { name: t('reports.housing.from_sds') },
        ]}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              {item.id}
            </td>
            <td>
              {item.fio}
            </td>
            <td>
              {item.area}
            </td>
            <td>
              {item.phone}
            </td>
            <td>
              {item.from_sds ? t('common.yes') : t('common.no')}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
