import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IJobDescriptionsModel, JobDescriptionsFilterType } from 'models/JobDescription/JobDescription';
import { ISystemUnitModel } from 'models/References/UnitsModel';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { LocalJobDescriptionFilterType } from 'models/JobDescription/interfaces';
import { UiOuterLink } from 'components/common/UI/OuterLink';
import { ListElement } from '../common/ListElement/ListElement';
import { ReactComponent as DownloadIcon } from '../../assets/img/download.svg';
import { JobDescriptionFilter } from './JobDescriptionFilter';
import { UiInputSearch } from '../common/UI/InputSearch';
import { ReactComponent as FilterIcon } from '../../assets/img/filter.svg';
import { Loader } from '../common/UI/Loaders';
import { UiModal } from '../common/UI/Modal';
import { InfiniteScrollbar } from '../common/InfiniteScrollbar';

type Props = {
  isLoading: boolean;
  hasMoreData: boolean;
  clearLoad: boolean;
  data: IJobDescriptionsModel;
  getData: (filter?: JobDescriptionsFilterType, append?: boolean) => void;
  enterprises: IEnterpriseModel[];
  units: ISystemUnitModel[];
}

type FormValues = {
  search: string;
}

export const JobDescriptionsComponent = ({
  isLoading,
  hasMoreData,
  clearLoad,
  data,
  getData,
  enterprises,
  units,
}: Props) => {
  const { register, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });


  const [openFilter, setOpenFilter] = useState(false);

  /** компонент фильтра будет сообщать об изменениях. и как стартанет тоже сообщит */
  const [filterData, setFilterData] = useState<LocalJobDescriptionFilterType>({});

  /** подгрузка данных при скролле */
  const onLoadMoreData = (page: number) => {
    getData({
      ...filterData,
      page,
      'per-page': data.limit,
    }, true);
  };

  const onSearch = useCallback(({ search }: FormValues) => {
    setFilterData((prevState: LocalJobDescriptionFilterType) => ({ ...prevState, name: search }));
  }, []);

  /** начальная загрузка данных */
  useEffect(() => {
    const localFilterData: JobDescriptionsFilterType = {
      enterprise_id: filterData.enterprise ? filterData.enterprise.value : undefined,
      unit_id: filterData.unit ? filterData.unit.value : undefined,
      name: filterData.name,
    };
    getData({ ...localFilterData, 'per-page': data.limit });
  }, [filterData, getData, data.limit]);

  return (
    <>
      <UiModal isOpen={openFilter} toggle={() => setOpenFilter(false)} headerContent="Фильтр инструкций">
        <JobDescriptionFilter
          toggle={() => setOpenFilter(false)}
          enterprises={enterprises}
          units={units}
          setFilterData={setFilterData}
          filterData={filterData}
        />
      </UiModal>

      <div className="box job-descriptions">
        <InfiniteScrollbar
          hasMoreData={hasMoreData && !isLoading}
          onLoadData={onLoadMoreData}
          currentPage={data.page}
        >
          <section className="form">
            <div className="d-flex">
              <UiInputSearch
                register={register}
                name="search"
                handleSubmit={handleSubmit(onSearch)}
              />
              <div className="ml-3">
                <span className="filter" role="presentation" onClick={() => setOpenFilter(true)}>
                  <FilterIcon />
                </span>
              </div>
            </div>

            {!clearLoad && data.data.map((node) => (
              <ListElement
                key={node.id}
                text={node.name}
                endIcon={(
                  <UiOuterLink href={node.file.fullUrl} className="like-btn" type="button">
                    <DownloadIcon />
                  </UiOuterLink>
                )}
              >
                <div className="item">{node.position.unit.enterprise.name}</div>
                <div className="item">{node.position.unit.name}</div>
                <div className="item">{node.position.name}</div>
              </ListElement>
            ))}
            {(!isLoading && !data.data.length) && (
              <h3 className="text-center">
                Должностных инструкций не найдено
              </h3>
            )}
            {isLoading && <Loader className="mt-3" />}
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};
