import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import invariant from 'invariant';
import isNaN from 'lodash/isNaN';
import { useTranslation } from 'react-i18next';
import { projectBaseUrl } from 'utils/env';

import { ParamTypes } from 'models/common';
import { SystemPollsFormComponent } from '../../components/Polls/SystemPollsForm';
import {
  systemEnterprisesSelector,
  systemPollsQuestionTypesSelector,
  systemPollsStatusesSelector,
} from '../../store/selectors';
import { IPollsQuestionTypesModel } from '../../../models/Polls/PollsQuestionTypesModel';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import {
  createSystemPoll, getSystemPollById, getSystemPollUnvoteUsers, updateSystemPoll,
} from '../../api/poll';
import { handleErrors } from '../../../utils/errors';
import { ISystemPollFormModel, ISystemPollModel, SystemPollModel } from '../../../models/Polls/SystemPollModel';
import { Loader } from '../../../components/common/UI/Loaders';
import { usePromise } from '../../../utils/hooks/usePromise';
import { IPollsStatusModel } from '../../../models/Polls/PollsStatusesModel';
import { PollUnvoteUsersModel } from '../../../models/Polls/PollUnvoteUsersModel';
import { ICurrentUserModel } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';


export const SystemPollsForm = () => {
  const questionTypes: IPollsQuestionTypesModel = useSelector(systemPollsQuestionTypesSelector);
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const pollId = toNumber(id);

  invariant(!(id && isNaN(pollId)), 'ID опроса должен быть числом');

  const history = useHistory();

  const onSave = useCallback(async (modelId: number | null, modelToSave: ISystemPollFormModel) => {
    /** если есть modelId, значит это сценарий обновления */
    const trueOrError = modelId ? await updateSystemPoll(modelId, modelToSave) : await createSystemPoll(modelToSave);

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t(modelId ? 'polls.updated' : 'polls.created'));
        history.push('/system/polls/my');
      },
    );
  }, [t, history]);

  const [unvoteUsers, setUnvoteUsers] = useState(new PollUnvoteUsersModel());
  const [unvoteUsersLoading, setUnvoteUsersLoading] = useState(false);

  /** загрузка неголосовавших пользователей для оффлайн-голосования */
  const onLoadUnvoteUsers = useCallback(async () => {
    setUnvoteUsersLoading(true);
    handleErrors(
      await getSystemPollUnvoteUsers(pollId),
      'get',
      setUnvoteUsers,
      () => setUnvoteUsersLoading(false),
    );
  }, [pollId]);

  const [model, modelLoading] = usePromise<ISystemPollModel>(() => getSystemPollById(pollId), !isNaN(pollId), [pollId]);
  const pollStatuses: IPollsStatusModel[] = useSelector(systemPollsStatusesSelector);

  /** Выгрузка результата опроса */
  const linkToExport = projectBaseUrl ?
    `${projectBaseUrl.replace(/^http(s)?:/, '')}/api/system/poll/export-answers/${pollId}` :
    '/';

  /** ссылка на голосование внешней части (оффлайн ответы). + передать фио сотрудника */
  const onOfflineVote = useCallback((user: {fio: string; id: number}) => {
    history.push(`/polls/${pollId}/${user.id}`, { userName: user.fio });
  }, [pollId, history]);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);

  if (modelLoading) {
    return <Loader />;
  }
  return (
    <SystemPollsFormComponent
      questionTypes={questionTypes}
      enterprises={enterprises}
      onSave={onSave}
      pollStatuses={pollStatuses}
      model={model || new SystemPollModel({})}
      linkToExport={linkToExport}
      onLoadUnvoteUsers={onLoadUnvoteUsers}
      unvoteUsers={unvoteUsers}
      unvoteUsersLoading={unvoteUsersLoading}
      onOfflineVote={onOfflineVote}
      isCurrentuserAuthor={currentUser.id === model?.author.id}
      isCurrentuserAdmin={currentUser.hasRole('admin')}
    />
  );
};
