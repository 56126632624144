import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { getUserList } from 'api/Users';
import { UsersFilterType } from 'models/Users/UsersModel';
import { IUserList, IUserListUnit } from 'models/Users/interfaces';
import { appResponsible } from 'api/application';
import { ApplicationResponsibleListModel } from 'models/Application/Responsible/ApplicationResponsibleListModel';
import {
  IApplicationResponsibleFilterType,
  IApplicationResponsibleListModel,
} from 'models/Application/Responsible/interfaces';
import { IDepartmentItemModel } from 'models/References/DepartmentModel';
import { handleErrors } from 'utils/errors';
import { SystemFeedbackResponsibleComponent } from '../../components/Feedback/SystemFeedbackResponsible';
import { systemEnterprisesSelector, systemGlobalDepartmentsSelector } from '../../store/selectors';


export const SystemFeedbackResponsible = () => {
  const enterprises = useSelector(systemEnterprisesSelector);
  const departments: IDepartmentItemModel[] = useSelector(systemGlobalDepartmentsSelector);
  const [users, setUsers] = useState<IUserList[]>([]);

  const [data, setData] = useState<IApplicationResponsibleListModel>(new ApplicationResponsibleListModel());
  const [isLoading, setIsLoading] = useState(true);

  const getUsers = useCallback(async (filter: UsersFilterType) => {
    getUserList({ ...filter, is_register: true })
      .then((modelOrError) => {
        if (!(modelOrError instanceof Error)) {
          const newUsers = get(modelOrError.data, '[0].units', []).reduce((acc: IUserList[], val: IUserListUnit) => {
            acc.push(...val.users);
            return acc;
          }, []);

          setUsers(newUsers);
        }
      });
  }, []);

  const getData = useCallback(async (filter?: IApplicationResponsibleFilterType) => {
    setIsLoading(true);
    const modelOrError = await appResponsible(filter);

    handleErrors(
      modelOrError,
      'get',
      () => {
        if (!(modelOrError instanceof Error)) {
          setData(modelOrError);
        }
      },
    );

    setIsLoading(false);
  }, []);

  return (
    <SystemFeedbackResponsibleComponent
      data={data}
      enterprises={enterprises}
      departments={departments}
      users={users}
      isLoading={isLoading}
      getData={getData}
      getUsers={getUsers}
    />
  );
};
