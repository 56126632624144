import React from 'react';
import Scrollbar from 'react-scrollbars-custom';
// eslint-disable-next-line import/no-unresolved
import { ScrollState } from 'react-scrollbars-custom/dist/types/types';

const styles = { width: '100%', height: '100%' };

type Props = {
  hasMoreData: boolean;
  onLoadData: (page: number) => void;
  currentPage: number;
  children: React.ReactNode[] | React.ReactElement | string;
  getRef?: any;
}

/**
 * кастомный скролл (библиотека) + бесконечная прокрутка (минимальная реализация)
 * @param hasMoreData - есть ли еще данные, вызывать ли функцию onLoadData
 * @param onLoadData - функция, вызываемая при скролле вниз
 * @param currentPage - текущая страница
 * @param children - внутреннее содержимое
 * @param getRef - ссылка на дом элемент
 */
export const InfiniteScrollbar = ({
  hasMoreData, onLoadData, currentPage, children, getRef,
}: Props) => (
  <Scrollbar
    style={styles}
    ref={getRef}
    // @ts-ignore
    onScroll={({ contentScrollHeight, scrollTop, clientHeight }: ScrollState) => {
      if (hasMoreData && (contentScrollHeight - scrollTop - 1) <= clientHeight) {
        onLoadData(currentPage + 1);
      }
    }}
  >
    {children}
  </Scrollbar>
);
