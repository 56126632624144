import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { SystemPartnersFilter } from 'systemModule/components/Partners/Filter';
import { useDataPage } from 'utils/hooks/useDataPage';

import { CustomTable } from 'components/common/Table';
import { DefaultFilterType } from 'models/common';
import { ISystemPartnerListItemModel, ISystemPartnerListModel } from 'models/Partners/SystemPartnerListModel';


type Props = {
  canCreate: boolean;
  canDelete: boolean;
  data: ISystemPartnerListModel;
  isLoading: boolean;
  getData: (f: DefaultFilterType) => void;
  onDelete: (item: ISystemPartnerListItemModel) => void;
}

export const SystemPartnersListComponent = ({
  canCreate, canDelete, data, isLoading, getData, onDelete,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  return (
    <>
      <h3>{t('system.partners')}</h3>

      <SystemPartnersFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: 'ID' },
          { name: t('system.partners.form.name') },
          { name: t('system.partners.form.company_name') },
          { name: t('system.partners.form.categories') },
          { name: t('system.partners.form.enterprises') },
          { name: t('common.table.actions') },
        ]}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="partners/create">
            {t('system.partners.create')}
          </Button>
        )}
      >
        {data.data.map((item: ISystemPartnerListItemModel) => (
          <tr key={item.id}>
            <td>
              <Link to={`partners/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`partners/${item.id}`}>{item.title}</Link>
            </td>
            <td>
              {item.company_name}
            </td>
            <td>
              {item.categories.map((category) => category.name).join(', ')}
            </td>
            <td>
              {item.enterprises.map((ent) => ent.name).join(', ') || 'Холдинг'}
            </td>
            <td>
              {canDelete && (
                <Button type="button" color="danger" className="w-100" onClick={() => onDelete(item)}>
                  {t('common.form.delete')}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
