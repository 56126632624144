import { IReportApplicationEnterprise } from './interfaces';


export class ReportApplicationEnterprise implements IReportApplicationEnterprise {
  readonly application_count: number;
  readonly items: {
    application_count: number;
    id: number;
    name: string;
    types: {
      application_count: number;
      id: number;
      name: string;
      statuses: { application_count: number; id: number; name: string }[];
    } [];
  }[];

  constructor(params: any = {}) {
    this.application_count = params.application_count;
    this.items = params.items;
  }
}
