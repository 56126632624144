import React from 'react';

import { IReportSportEnterprise } from 'models/Reports/interfaces';
import { CardBlock } from '../common/CardBlock';
import { CollapseCardBlock } from '../common/CollapseCardBlock';


type Props = {
  model: IReportSportEnterprise;
}

export const SystemReportSportEnterpriseComponent = ({ model }: Props) => (
  <>
    {model.items.map((item) => (
      <React.Fragment key={item.id}>
        <CollapseCardBlock name={item.name} count={item.user_count}>
          {item.sports.map((sport) => (
            <CardBlock key={sport.id} name={sport.name} count={sport.user_count} />
          ))}
        </CollapseCardBlock>
      </React.Fragment>
    ))}
  </>
);
