import { AnyAction } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { baseSaga } from '../../../../store/baseSaga';
import { httpGet } from '../../../../utils/http';
import { systemGetSportsFailAction, systemGetSportsOkAction } from './actions';
import { SystemSportsModel } from '../../../../models/References/SportModel';
import { SYSTEM_REFERENCES_GET_SPORTS_START } from './constants';

function* systemGetSportsSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/references/sport', { sort: 'id', ...filter });

    yield put(systemGetSportsOkAction(new SystemSportsModel(response)));
  }, systemGetSportsFailAction);
}


export function* systemSportsSagas() {
  yield takeLatest(SYSTEM_REFERENCES_GET_SPORTS_START, systemGetSportsSaga);
}
