import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

import { CardBlock } from './CardBlock';


type Props = {
  name: string;
  count: number;
  children: React.ReactNode;
}

export const CollapseCardBlock = ({ name, count, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CardBlock name={name} count={count} onClick={() => setIsOpen(!isOpen)} />
      <Collapse isOpen={isOpen}>
        <div className="ml-3">
          {children}
        </div>
      </Collapse>
    </>
  );
};
