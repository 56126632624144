import React, { useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { defaultImgExtensions } from 'config/system';
import { projectBaseUrl } from 'utils/env';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiFileUploaderSystem } from 'components/common/FileUploader';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';
import {
  ISystemBonusProgramTagItemFormModel,
  ISystemBonusProgramTagItemModel,
} from 'models/BonusProgram/Tag/interfaces';

type FormValues = {
  title: string;
  icon: string;
  auxiliaryFieldForIcon: null | File;
}

type Props = {
  model: ISystemBonusProgramTagItemModel;
  onSave: (modelId: number | null, modelToSave: ISystemBonusProgramTagItemFormModel) => void;
  canUpdateTag: boolean;
  canCreateTag: boolean;
}

export const SystemBonusProgramTagItemFormComponent = ({
  model, onSave, canUpdateTag, canCreateTag,
}: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, errors, setValue,
    formState: { isSubmitting, dirtyFields, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      title: model.title,
      icon: model.icon,
    },
  });

  useEffect(() => {
    register('auxiliaryFieldForIcon');
  }, [register]);

  const onSubmit = async (data: FormValues) => {
    if ((model.id && !canUpdateTag) || (!model.id && !canCreateTag)) {
      return;
    }

    const modelToSave: ISystemBonusProgramTagItemFormModel = {
      title: data.title,
    };

    if (data.auxiliaryFieldForIcon) {
      if (data.auxiliaryFieldForIcon.name !== model?.icon) {
        /** загрузить и обновить */
        const icon = await new UploadFiles([data.auxiliaryFieldForIcon]).upload();

        if (icon instanceof Error) {
          toast.error(t('common.form.errors.save'));
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        modelToSave.icon = icon[0].file_name;
      } else {
        modelToSave.icon = data?.auxiliaryFieldForIcon.name;
      }
    } else {
      /** приходится в поле icon ставить пустую строку, чтобы действительно удалить иконку */
      modelToSave.icon = '';
    }

    onSave(model?.id, modelToSave);
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={!model.id}
      showButtons={canUpdateTag}
    >
      <h3>{t(model.id ? 'system.bonusProgram.form.update.tag' : 'system.bonusProgram.form.create.tag')}</h3>

      <UiSystemInput
        name="title"
        errors={errors}
        register={register}
        label={t('system.bonusProgram.tag.title.label')}
        required
      />

      <FormGroup>
        <strong>
          {t('system.bonusProgram.tag.icon.label')}
        </strong>
        <UiFileUploaderSystem
          initFileTypes={model.icon ?
            [{
              name: model.icon,
              url: `/api/upload/files/${model.icon}`,
              fullUrl: `${projectBaseUrl}/api/upload/files/${model.icon}`,
            }] : []}
          maxFilesCount={1}
          onChange={(files: File[]) => setValue('auxiliaryFieldForIcon', files[0])}
          accept={defaultImgExtensions}
        />
      </FormGroup>

    </UiSystemForm>
  );
};
