import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import {
  ConstructApplicationCategoryListItemModel,
} from 'models/ConstructApplication/ConstructApplicationCategoryListModel';
import { IConstructApplicationCategoryListItemModel } from 'models/ConstructApplication/interfaces';
import { Loader } from 'components/common/UI/Loaders';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  createConstructApplicationCategory,
  getConstructApplicationCategoryById, updateConstructApplicationCategory,
} from 'systemModule/api/construct-application';
import {
  ConstructApplicationCategoryFormComponent,
} from 'systemModule/components/ConstructApplication/ConstructApplicationCategoryForm';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';


export const ConstructApplicationCategoryForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const history = useHistory();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wApplication);

  const [model, modelLoading] = usePromise<IConstructApplicationCategoryListItemModel>(
    () => getConstructApplicationCategoryById(numberId),
    !isNaN(numberId),
    [numberId],
  );

  const onSave = useCallback(async (modelToSave: IConstructApplicationCategoryListItemModel) => {
    if (!canUpdate) {return;}

    const saveModel = modelToSave.isNew ? createConstructApplicationCategory : updateConstructApplicationCategory;

    handleErrors(
      await saveModel(modelToSave),
      'save',
      () => {
        toast.success(t(`system.construct_application.category.${modelToSave.isNew ? 'created' : 'updated'}`));
        history.push('/system/construct-application/category');
      },
    );
  }, [t, canUpdate, history]);

  if (modelLoading) {
    return <Loader />;
  }
  return (
    <ConstructApplicationCategoryFormComponent
      model={model || new ConstructApplicationCategoryListItemModel()}
      canUpdate={canUpdate}
      onSave={onSave}
    />
  );
};
