import React, { useCallback } from 'react';

import {
  PartnersCommonListComponent,
  PartnersSortedListComponent,
} from 'components/Partners/common';
import { IPartnersListModel } from 'models/Partners/PartnersListModel';


type Props = {
  model: IPartnersListModel
}

export const PartnersAllSortedListComponent = ({ model }: Props) => {
  /**
   * главное быстро, как обычно
   * 3 варианта сортировки:
   * 1) числа
   * 2) англ.буквы
   * 3) русские буквы
   * 4) все остальное (?)
   */
  const getSortedData = useCallback((): {
    numberData: { group: number; values: IPartnersListModel}[];
    engData: { group: string; values: IPartnersListModel}[];
    rusData: { group: string; values: IPartnersListModel}[];
    otherData: { group: string; values: IPartnersListModel}[];
  } => {
    /** числа по возрастанию */
    const numData = model.filter(({ company_name }) => company_name.match(/^\d/));

    const numberData = [];
    for (let i = 1; i < 10; i += 1) {
      const values = numData.filter(({ company_name }) => company_name.match(new RegExp(`^${i}`)));

      if (values.length) {
        numberData.push({
          group: i,
          values,
        });
      }
    }

    /** англ.буквы */
    const enData = model.filter(({ company_name }) => company_name.match(/^[a-zA-Z]/));

    const engData = [];
    for (let i = 97; i <= 122; i += 1) {
      const char = String.fromCharCode(i);
      const values = enData.filter(({ company_name }) => company_name.match(new RegExp(`^${char}`, 'i')));

      if (values.length) {
        engData.push({
          group: char.toUpperCase(),
          values,
        });
      }
    }

    /** рус.буквы */
    const ruData = model.filter(({ company_name }) => company_name.match(/^[а-яА-Я]/));

    const rusData = [];
    for (let i = 1072; i <= 1103; i += 1) {
      const char = String.fromCharCode(i);
      const values = ruData.filter(({ company_name }) => company_name.match(new RegExp(`^${char}`, 'i')));

      if (values.length) {
        rusData.push({
          group: char.toUpperCase(),
          values,
        });
      }
    }

    /** все остальное в куче */
    const otherData: IPartnersListModel = model.filter(({ company_name }) => !(
      company_name.match(/^[а-яА-Я]/) || company_name.match(/^[a-zA-Z]/) || company_name.match(/^\d/)));

    return {
      numberData,
      engData,
      rusData,
      otherData: [{ group: 'Прочее', values: otherData }],
    };
  }, [model]);

  const {
    numberData,
    engData,
    rusData,
    otherData,
  } = getSortedData();

  return (
    <PartnersCommonListComponent title="Все партнёры" className="partners-list">
      <PartnersSortedListComponent data={numberData} />
      <PartnersSortedListComponent data={engData} />
      <PartnersSortedListComponent data={rusData} />
      <PartnersSortedListComponent data={otherData} />
    </PartnersCommonListComponent>
  );
};
