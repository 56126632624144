import isEmpty from 'lodash/isEmpty';

import { IApplicationSickModel } from './interfaces';
import { IShortUser } from '../../User/interfaces';
import { FileType, prepareFiles } from '../../common';
import { CDate } from '../../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../../config/system';

export class ApplicationSickModel implements IApplicationSickModel {
  id: number;
  status: {id: number; name: string};
  type: {id: number; name: string};
  author: IShortUser;
  assign: IShortUser; // на кого заявка назначена в текущий момент
  comments: {
    id: number;
    content: string;
    author: {
      id: number;
      fio: string;
      phone: string;
    };
    created_at: string;
    files: FileType[];
  }[];
  responsible: {
    id: number;
    user: IShortUser;
    created_at: Date;
  }[]; // массив всех, на кого когда-либо назначалась заявка
  created_at: Date;
  updated_at: Date;
  date_start: Date;
  date_end: Date;
  enterprise: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.status = params.status;
    this.type = params.type;
    this.author = params.author;
    this.assign = params.assign;
    this.comments = params.comments.map((comment: any) => ({
      ...comment,
      files: comment.files.map((file: any) => (isEmpty(file) ? null : prepareFiles(file))),
    }));
    this.responsible = params.responsible.map((user: any) => ({
      ...user,
      created_at: CDate.parse(user.created_at, BACKEND_DATE_FORMAT),
    }));
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.updated_at = CDate.parse(params.updated_at, BACKEND_DATE_FORMAT);
    this.date_start = CDate.parse(params.date_start, BACKEND_DATE_FORMAT);
    this.date_end = CDate.parse(params.date_end, BACKEND_DATE_FORMAT);
    this.enterprise = params.enterprise;
  }
}
