import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import { SYSTEM_GET_PRIVILEGES_START } from './constants';
import { systemGetPrivilegesFailAction, systemGetPrivilegesOkAction } from './actions';
import { PrivilegeList } from '../../../models/Privilege/PrivilegeList';


function* systemGetPrivilegesSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/privilege', filter);

    yield put(systemGetPrivilegesOkAction(new PrivilegeList(response)));
  }, systemGetPrivilegesFailAction);
}


export function* systemPrivilegesSagas() {
  yield takeLatest(SYSTEM_GET_PRIVILEGES_START, systemGetPrivilegesSaga);
}
