import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { usePromise } from 'utils/hooks/usePromise';
import { getApplicationCategoryList } from 'api/application';
import { Loader } from 'components/common/UI/Loaders';
import { IApplicationConstructCategoryListWithRoot } from 'models/Application/Construct/interfaces';
import { UiBox } from 'components/common/UI/UiBox';
import { ListElementLink } from 'components/common/ListElement/ListElementLink';


export const ApplicationCreateList = () => {
  const { t } = useTranslation();

  const [model, modelIsLoading] = usePromise<IApplicationConstructCategoryListWithRoot>(
    () => getApplicationCategoryList(true),
    true,
  );

  if (modelIsLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="page-header">
        <Link className="back" to="/applications" />
        {t('application.create')}
      </div>

      <UiBox className="applications">
        <section className="form">
          <h4 className="mt-0">{t('application.categories')}</h4>
          {model && model.list.map((item) => (
            <ListElementLink key={item.id} text={item.name} to={`/applications/create/category/${item.id}`} />
          ))}

          <h4 className="mt-4">{t('application.type')}</h4>
          <ListElementLink text={t('application.sick.title')} to="/applications/create/sick" />
          {model && model.listRoot.map((item) => (
            <ListElementLink
              key={item.id}
              disabled={!item.is_enabled}
              text={item.name}
              to={`/applications/create/type/${item.id}`}
            />
          ))}
        </section>
      </UiBox>
    </>
  );
};
