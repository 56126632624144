import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { EVENT_GET_LIST_START } from './constants';
import { baseSaga } from '../../store/baseSaga';
import { httpGet } from '../../utils/http';
import { eventsGetListFailAction, eventsGetListOkAction } from './actions';
import { EventsModel } from '../../models/Events/EventModel';


function* eventGetListSaga({ payload: { filter, append } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/event', filter);

    yield put(eventsGetListOkAction(new EventsModel(response), append));
  }, eventsGetListFailAction);
}


export function* eventSagas() {
  yield takeLatest(EVENT_GET_LIST_START, eventGetListSaga);
}
