import { dataAction, dataErrorAction, dataSuccessAction } from '../../../../store/actions';
import {
  SYSTEM_REFERENCES_GET_UNITS_FAIL,
  SYSTEM_REFERENCES_GET_UNITS_OK,
  SYSTEM_REFERENCES_GET_UNITS_START,
} from './constants';
import { SystemUnitsModel } from '../../../../models/References/UnitsModel';

export const systemGetUnitsStartAction = (filter = {}) => dataAction(
  SYSTEM_REFERENCES_GET_UNITS_START,
)({ filter });
export const systemGetUnitsOkAction = (units: SystemUnitsModel) => dataSuccessAction(
  SYSTEM_REFERENCES_GET_UNITS_OK,
)({ units });
export const systemGetUnitsFailAction = dataErrorAction(SYSTEM_REFERENCES_GET_UNITS_FAIL);
