import React, { Component } from 'react';
import { captureException } from '@sentry/browser';

type Props = {
  children: React.ReactNode;
}


export class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    if (error.toString().indexOf('ChunkLoadError') > -1) {
      // eslint-disable-next-line no-console
      console.log('[ChunkLoadError] Reloading due to error');
      window.location.reload();
    } else {
      /** игнороровать отправку ошибки загрузки чанка */
      captureException(error);
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="something-broke-block mt-5 pl-4 pr-4">
          <h1 className="something-broke-block__title text-white">Что-то сломалось... Обновите страницу</h1>
          <div className="something-broke-block__action">
            <h2 className="something-broke-block__subtitle">
              <a className="something-broke-block__link" href="/">Перейти на главную</a>
            </h2>
          </div>
        </div>
      );
    }
    return children;
  }
}
