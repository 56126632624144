import { getAllPartnersCategory } from 'api/partner';
import { UiSystemInput } from 'components/common/UI/Input';
import { PartnerCategoryListItemModel } from 'models/Partners/PartnerCategoryListModel';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UiToolbar } from 'components/common/Toolbar';
import { UiSelect } from 'components/common/UI/Select';
import { convertModelToOptions } from 'utils/convertModelToOptions';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { SystemUsersFilterType } from 'models/Users/interfaces';
import { hasUnemptyValues } from 'utils/objects';
import { systemEnterprisesSelector } from '../../store/selectors';


type Props = {
  onSubmit: (data: SystemUsersFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  enterprise: { label: string; value: number }[];
  category: { label: string; value: number }[];
  title: string,
  company_name: string,
}

export const SystemPartnersFilter = ({ onSubmit, onReset }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, setValue, errors, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise: [],
      category: [],
      title: '',
      company_name: '',
    },
  });

  const [categories, setCategories] = useState<PartnerCategoryListItemModel[]>([]);
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const onLocalSubmit = (data: FormValues) => {
    const preparedFilterData = {
      enterprise: data.enterprise.length ? data.enterprise.map((ent) => ent.value) : undefined,
      category: data.category.length ? data.category.map((item) => item.value) : undefined,
      title: data.title ? data.title : undefined,
      company_name: data.company_name ? data.company_name : undefined,
    };

    if (hasUnemptyValues(preparedFilterData)) {
      onSubmit(preparedFilterData);
    }
  };

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const categoriesOptions = convertModelToOptions(categories);
  const enterprisesOptions = convertModelToOptions(enterprises);

  /** Получить список категорий для фильтра */
  const getCategories = useCallback(async () => {
    getAllPartnersCategory()
      .then((modelOrError) => {
        if (!(modelOrError instanceof Error)) {
          setCategories(modelOrError);
        }
      });
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="enterprise"
            errors={errors}
            options={enterprisesOptions}
            value={watch('enterprise')}
            label={t('common.enterprise')}
            multiple
            allowEmptyValue
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="category"
            errors={errors}
            options={categoriesOptions}
            value={watch('category')}
            label={t('system.partners.category')}
            multiple
            allowEmptyValue
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('system.partners.form.name')}
            name="title"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('system.partners.form.company_name')}
            name="company_name"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
