import { baseApiFn } from '../utils/baseApiFn';
import { httpGet, httpPut } from '../utils/http';
import {
  ISystemNotificationTemplateModel,
  SystemNotificationTemplateModel,
} from '../models/Notification/SystemNotificationTemplateModel';
import {
  SystemNotificationVariableFormType,
  SystemNotificationVariableModelsType,
} from '../models/Notification/SystemNotificationVariableModel';


/** получить шаблон уведомлений */
export const getNotificationTemplateById = async (
  id: number,
) => baseApiFn<ISystemNotificationTemplateModel>(async () => {
  const response = await httpGet(`/system/notifications/template/${id}`);
  return new SystemNotificationTemplateModel(response);
});

/** Список доступных переменных подстановок для шаблонов */
export const getNotificationVariableListByAction = async (
  action: string,
) => baseApiFn<SystemNotificationVariableModelsType>(httpGet(`/system/notifications/variable-list/${action}`));

/** сохранить шаблон уведомлений */
export const saveNotificationTemplate = async (
  id: number,
  data: SystemNotificationVariableFormType,
) => baseApiFn(httpPut(`/system/notifications/template/${id}`, data));
