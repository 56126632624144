import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import invariant from 'invariant';
import { toast } from 'react-toastify';
import SockJS from 'sockjs-client';
// @ts-ignore
import Centrifuge from 'centrifuge';
import {
  notificationsGetInitStartAction,
  onNewNotificationAction,
  onReadNotificationStartAction,
} from '../Notifications/actions';
import {
  getLastReadNotificationSelector,
  getUnreadInitNotificationsIsLoadingSelector,
  getUnreadInitNotificationsSelector,
} from '../Notifications/selectors';
import { ISocketNotification, SocketNotificationModel } from '../../models/Notification/SocketNotification';
import { SocketNotificationComponent } from '../../components/SocketNotification/SocketNotificationComponent';
import { CurrentUserModel } from '../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../store/currentUser/selectors';


export const SocketNotification = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(notificationsGetInitStartAction());
  }, [dispatch]);

  const notifications: ISocketNotification[] = useSelector(getUnreadInitNotificationsSelector);
  const notificationsIsLoading: boolean = useSelector(getUnreadInitNotificationsIsLoadingSelector);
  const lastReadNotification: ISocketNotification | null = useSelector(getLastReadNotificationSelector);

  const currentUser: CurrentUserModel = useSelector(getCurrentUserSelector);


  /**
   * закрыть и прочитать не блокирующее уведомление
   * при forceClose=true закрывает и блокирующее тоже
   */
  const onCloseNotification = useCallback((item: ISocketNotification, forceClose = false) => {
    if (forceClose || !item.is_blocked) {
      dispatch(onReadNotificationStartAction(item));
    }
  }, [dispatch]);

  /**
   * подключение центрифуги WS
   * зависимость только одна - смена пользователя, т.к. меняется канал
   */
  useEffect(() => {
    let centrifuge: any;
    invariant(process.env.REACT_APP_WSS_URL, 'Не указан url для подключения к вебсокету');

    if (process.env.REACT_APP_WSS_URL) {
      centrifuge = new Centrifuge({
        url: process.env.REACT_APP_WSS_URL,
        insecure: true,
        transports: ['websocket', 'xhr-streaming'],
        sockJS: SockJS,
      });

      centrifuge.connect();

      centrifuge.subscribe(`notify_${currentUser.id}`, (message: {
        uid: string;
        channel: string;
        data: any;
      }) => dispatch(onNewNotificationAction(new SocketNotificationModel(message.data))));
    } else {
      toast.error('Не указан url для подключения к вебсокету');
    }

    return () => {
      centrifuge.disconnect();
      centrifuge.disconnect();
    };
    // eslint-disable-next-line
  }, [currentUser.id]);


  if (notificationsIsLoading) {
    return null;
  }
  return (
    <SocketNotificationComponent
      notifications={notifications}
      onCloseNotification={onCloseNotification}
      lastReadNotification={lastReadNotification}
    />
  );
};
