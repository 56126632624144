import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { getModelFromModels } from 'utils/getModelFromModels';
import { usePromise } from 'utils/hooks/usePromise';
import {
  getNotificationTemplateById,
  getNotificationVariableListByAction,
  saveNotificationTemplate,
} from 'api/notificationTemplates';
import { Loader } from 'components/common/UI/Loaders';
import {
  ISystemNotificationTemplateModel,
  SystemNotificationTemplateModel,
} from 'models/Notification/SystemNotificationTemplateModel';
import {
  SystemNotificationVariableFormType,
  SystemNotificationVariableModelsType,
} from 'models/Notification/SystemNotificationVariableModel';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { handleErrors } from 'utils/errors';
import { HttpErrors } from 'utils/http';
import { EntityNotFound } from 'components/Errors/404';
import {
  SystemNotificationTemplateFormComponent,
} from 'systemModule/components/Notification/SystemNotificationTemplateForm';


export const SystemNotificationTemplateForm = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id, action } = useParams<ParamTypes>();
  const numberId = toNumber(id);
  const stringAction = action?.toString() || '';

  const [models, modelLoading] = usePromise<[
    ISystemNotificationTemplateModel | HttpErrors,
    SystemNotificationVariableModelsType | HttpErrors
  ]>(() => Promise.all([
    getNotificationTemplateById(numberId),
    getNotificationVariableListByAction(stringAction),
  ]),
  !isNaN(numberId) && stringAction.length > 0,
  [numberId]);

  const [notificationModel, variableListModel] = [getModelFromModels(models, 0), getModelFromModels(models, 1)];

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wNotifyTemplate);

  const [saveLoading, setSaveLoading] = useState(false);

  const onSave = useCallback(async (data: SystemNotificationVariableFormType) => {
    setSaveLoading(true);
    handleErrors(
      await saveNotificationTemplate(numberId, data),
      'save',
      () => {
        toast.success(t('notifications.templates.updated'));
        history.push('/system/notifications-templates');
      },
      () => setSaveLoading(false),
    );
  }, [t, numberId, history]);

  if (modelLoading) {
    return <Loader />;
  }
  if (notificationModel instanceof SystemNotificationTemplateModel) {
    return (
      <SystemNotificationTemplateFormComponent
        model={notificationModel}
        variableList={variableListModel}
        canUpdate={canUpdate}
        onSave={onSave}
        saveLoading={saveLoading}
      />
    );
  }
  return <EntityNotFound message="Не найдено" />;
};
