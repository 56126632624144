import { IPartnerCategoryListModel, PartnerCategoryListItemModel } from 'models/Partners/PartnerCategoryListModel';
import { PartnerItemModel } from 'models/Partners/PartnerItemModel';
import { IPartnersListModel, PartnersListItemModel } from 'models/Partners/PartnersListModel';
import { getAllListData } from 'utils/getAllListData';
import { baseApiFn } from 'utils/baseApiFn';
import { httpDelete, httpGet, httpPost } from 'utils/http';


const getPartners = async (limit?: number, params = {}) => baseApiFn<IPartnersListModel>(
  async () => {
    let response;

    if (limit) {
      response = await httpGet('/public/partner', { 'per-page': limit, ...params });
      return response.data.map((_: unknown) => new PartnersListItemModel(_));
    }
    response = await getAllListData('/public/partner', PartnersListItemModel, params);

    return response;
  },
);

/** получить все рекомендуемые акции */
export const getRecommendedPartners = async (limit?: number) => baseApiFn<IPartnersListModel>(
  getPartners(limit, { is_recommended: true }),
);

/** получить все акции */
export const getAllPartners = async (limit?: number) => baseApiFn<IPartnersListModel>(
  getPartners(limit),
);

/** получить все акции категории */
export const getAllPartnersByCategory = async (
  category: number | number[], limit?: number,
) => baseApiFn<IPartnersListModel>(
  getPartners(limit, { category: Array.isArray(category) ? category : [category] }),
);

/** получить все избранные акции */
export const getFavouritePartners = async (limit?: number) => baseApiFn<IPartnersListModel>(
  getPartners(limit, { is_favorite: true }),
);

/** получить все категории акций */
export const getAllPartnersCategory = async () => baseApiFn<IPartnerCategoryListModel>(
  getAllListData('/public/partner/category', PartnerCategoryListItemModel, { sort: 'priority' }),
);

/** получить акцию по id */
export const getPartnerById = async (id: number) => baseApiFn<PartnerItemModel>(async () => {
  const response = await httpGet(`/public/partner/${id}`);
  return new PartnerItemModel(response);
});

/** добавление/удаление акции в избранное */
export const setPartnerFavourite = async (id: number, add = true) => baseApiFn<true>(async () => {
  let response;

  if (add) {
    response = await httpPost(`/public/partner/favorite/${id}`);
  } else {
    response = await httpDelete(`/public/partner/favorite/${id}`);
  }

  return response;
});
