import {
  httpDelete,
  httpGet, httpPost, httpPut,
} from 'utils/http';
import {
  ISystemBusStopModel,
  ISystemBusStopModelForm,
  SystemBusStopModel,
} from 'models/References/BusStopsModel';
import { baseApiFn } from 'utils/baseApiFn';


export const createSystemRefBusStop = async (model: ISystemBusStopModelForm) => baseApiFn<true>(httpPost(
  'system/references/bus-stop', model,
));

export const getSystemRefBusStop = async (id: number) => baseApiFn<ISystemBusStopModel>(async () => {
  const response = await httpGet(`system/references/bus-stop/${id}`);
  return new SystemBusStopModel(response);
});

export const updateSystemRefBusStop = async (id: number, model: any) => baseApiFn<true>(httpPut(
  `system/references/bus-stop/${id}`, model,
));

export const deleteSystemRefBusStop = async (id: number) => baseApiFn<true>(httpDelete(
  `system/references/bus-stop/${id}`,
));
