import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { IShortUser } from '../User/interfaces';
import { ShortUserModel } from '../User/ShortUserModel';


export type PollUnvoteUsersModelType = IShortUser;


export interface IPollUnvoteUsersModel extends IWithPages {
  readonly data: PollUnvoteUsersModelType[];
}

export class PollUnvoteUsersModel extends WithPagesModel implements IPollUnvoteUsersModel {
  readonly data: PollUnvoteUsersModelType[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, ShortUserModel);
  }
}
