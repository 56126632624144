import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { customHistory } from 'customHistory';
import { IUserRightModel } from '../../../models/User/UserRightsModel';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { IPermission, IPermissions } from '../../../models/Roles/Permissions';
import { UiSystemCheckbox } from '../../../components/common/UI/Checkbox';
import { createSystemRbacRole, updateSystemRbacRole } from '../../api/rbac';
import { CreateRbacRoleType, UpdateRbacRoleType } from '../../../models/Roles/RolesModel';
import { handleErrors } from '../../../utils/errors';
import { UiSystemForm } from '../../../components/common/UI/Form';


const saveForm = async (createMode: boolean, data: FormValues, role: IUserRightModel) => {
  let model: CreateRbacRoleType | UpdateRbacRoleType;
  const permissionsToSave = map(pickBy(data.permissions, (value) => value), (_, key) => key);

  let trueOrError;

  if (createMode) {
    model = {
      name: data.name,
      description: data.description,
      permissions: permissionsToSave,
    };

    trueOrError = await createSystemRbacRole(model);
  } else {
    model = {
      description: data.description,
      permissions: permissionsToSave,
    };

    trueOrError = await updateSystemRbacRole(role.name, model);
  }

  handleErrors(
    trueOrError,
    'save',
    () => {
      toast.success(i18next.t(createMode ? 'rbac.role.created' : 'rbac.role.updated'));
      customHistory.push('/system/roles');
    },
  );
};


type Props = {
  role: IUserRightModel;
  permissions: IPermissions;
}

type FormValues = {
  name?: string;
  description: string;
  permissions: {[s: string]: boolean};
}

export const SystemRoleFormComponent = ({ role, permissions }: Props) => {
  const { t } = useTranslation();

  const createMode = !role.name.length;

  const {
    register, handleSubmit, errors, formState: { isValid, isSubmitting, dirtyFields },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: createMode ? role.name : undefined,
      description: role.description,
      permissions: role.permissions.reduce((acc: {[s: string]: boolean}, val: IPermission) => {
        acc[val.name] = true;
        return acc;
      }, {}),
    },
  });

  const onSubmit = (data: FormValues) => saveForm(createMode, data, role);

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
    >
      <h3>{createMode ? 'Создание' : 'Редактирование'} роли</h3>

      {createMode && (
        <UiSystemInput
          errors={errors}
          register={register}
          name="name"
          label={t('rbac.roles.name')}
          validation={{
            validate: {
              latin: (value: string) => (value.match(/^[0-9a-zA-Z_]+$/) ?
                true :
                'Имя может состоять только из латинских символов, цифр и "_"'
              ),
            },
          }}
          required
        />
      )}

      <UiSystemInput
        errors={errors}
        register={register}
        name="description"
        label={t('rbac.roles.description')}
        required
      />

      {permissions.map((permission: IPermission) => (
        <UiSystemCheckbox
          key={permission.name}
          register={register}
          name={`permissions.${permission.name}`}
          label={`${permission.description} (${permission.name})`}
        />
      ))}
    </UiSystemForm>
  );
};
