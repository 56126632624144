import React, { memo } from 'react';
import {
  ISystemBonusProgramEventHistoryFilter,
  ISystemBonusProgramEventHistoryListModel,
} from 'models/BonusProgram/EventHistory/interfaces';
import { EventHistoryListComponent } from 'components/common/BonusProgram/EventHistoryList';

type Props = {
  data: ISystemBonusProgramEventHistoryListModel;
  dataIsLoading: boolean;
  getData: (filter: ISystemBonusProgramEventHistoryFilter) => void;
}

export const SystemBonusProgramUserEventHistoryListComponent = memo(({ data, dataIsLoading, getData }: Props) => (
  <>
    <h4 className="mt-5">История начисления бонусов</h4>
    <EventHistoryListComponent data={data} dataIsLoading={dataIsLoading} getData={getData} />
  </>
));
