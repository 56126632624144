import React, { useCallback, useState } from 'react';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ParamTypes } from 'models/common';
import { ApplicationOneComponent } from '../../components/Applications/ApplicationOne';
import { usePromise } from '../../utils/hooks/usePromise';
import { appCancel, getApplicationByType } from '../../api/application';
import { Loader } from '../../components/common/UI/Loaders';
import { EntityNotFound } from '../../components/Errors/404';
import { ApplicationModel } from '../../models/Application/ApplicationModel';
import { ApplicationRequestType } from '../../models/Application/interfaces';
import { handleErrors } from '../../utils/errors';

export const ApplicationOne = () => {
  const history = useHistory();

  const { id, typeId } = useParams<ParamTypes>();
  const numberId = toNumber(id);
  const numberTypeId = toNumber(typeId);

  const { t } = useTranslation();

  const [model, modelLoading] = usePromise(
    () => getApplicationByType(numberId, numberTypeId),
    !isNaN(numberId) && !isNaN(numberTypeId),
    [numberId, numberTypeId],
  );

  const [cancelIsLoading, setCancelIsLoading] = useState(false);
  /** Отменить заявку */
  const onCancel = useCallback(async (data: ApplicationRequestType) => {
    setCancelIsLoading(true);
    const modelOrError = await appCancel(numberId, data);

    handleErrors(
      modelOrError,
      'save',
      () => {
        if (!(modelOrError instanceof Error)) {
          history.push('/applications');
        }
      },
    );

    setCancelIsLoading(false);
  }, [numberId, history]);


  if (model instanceof ApplicationModel) {
    return (
      <ApplicationOneComponent
        model={model}
        onCancel={onCancel}
        cancelIsLoading={cancelIsLoading}
      />
    );
  }
  return modelLoading ? <Loader /> : <EntityNotFound message={t('applications.404')} />;
};
