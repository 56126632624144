import i18next from 'i18next';
import React, { memo, Fragment } from 'react';
import { CDate } from 'utils/CDate';

import { IApplicationModelAdditionalFields } from 'models/Application/interfaces';
import { ApplicationConstructTypes } from 'models/ConstructApplication/interfaces';
import { projectBaseUrl } from 'utils/env';
import { UiOuterLink } from 'components/common/UI/OuterLink';


const prepareValue = (type: string, value: any) => {
  if (type === ApplicationConstructTypes.datetime && value) {
    return CDate.format(value, 'dd.MM.yyyy/HH:mm');
  }
  if (type === ApplicationConstructTypes.checkbox) {
    return i18next.t(`common.${value ? 'yes' : 'no'}`);
  }
  if (type === ApplicationConstructTypes.file && value) {
    return (
      <UiOuterLink className="d-block" href={`${projectBaseUrl}${value}`}>
        {value}
      </UiOuterLink>
    );
  }
  return value;
};

type Props = {
  downloadDocumentUrl: (id: number) => string;

  fields?: IApplicationModelAdditionalFields;
}

export const SystemApplicationViewConstructFields = memo(({ fields, downloadDocumentUrl }: Props) => {
  if (!fields) {
    return null;
  }
  return (
    <>
      {fields.map((block) => (
        <Fragment key={block.block_id}>
          {block.fields.map((field) => (
            <tr key={`${field.type}${field.value}${field.name}`}>
              <td>{field.name}</td>
              <td>{prepareValue(field.type, field.value)}</td>
            </tr>
          ))}
          {block.has_template && (
            <tr className="text-center">
              <td colSpan={2}>
                <UiOuterLink href={downloadDocumentUrl(block.block_id)} className="btn btn-primary">
                  Сгенерировать &quot;{block.name}&quot;
                </UiOuterLink>
              </td>
            </tr>
          )}
        </Fragment>
      ))}
    </>
  );
});
