import {
  ISystemBonusProgramTagItemModel,
  ISystemBonusProgramTagListModel,
  ISystemBonusProgramUserTagListModel,
} from 'models/BonusProgram/Tag/interfaces';
import { WithPagesModel } from 'models/WithPagesModel';

export class SystemBonusProgramUserTagListModel implements ISystemBonusProgramUserTagListModel {
  data: ISystemBonusProgramTagItemModel[];
  constructor(params: any = []) {
    this.data = params;
  }
}

export class SystemBonusProgramTagItemModel implements ISystemBonusProgramTagItemModel {
  id: number;
  title: string;
  icon: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.title = params.title;
    this.icon = params.icon;
  }
}

export class SystemBonusProgramTagListModel extends WithPagesModel implements ISystemBonusProgramTagListModel {
  data: ISystemBonusProgramTagItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemBonusProgramTagItemModel);
  }
}
