import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import isEmpty from 'lodash/isEmpty';
import { AuthSignInType } from 'models/Auth/interfaces';
import { PHONE_MASK, PHONE_PATTERN } from 'config/system';
import { getNumberPhoneWithoutMask } from 'utils/common';
import { UiInput } from '../common/UI/Input';
import { Header } from '../Main/Header';
import { UIInputMask } from '../common/UI/InputMask';


type Props = {
  signin: (data: AuthSignInType) => void;
  isSubmitting: boolean;
}

type FormValues = {
  phone: string;
  password: string;
}

export const LoginComponent = ({ signin, isSubmitting }: Props) => {
  const {
    register, handleSubmit, errors, getValues, watch, control, setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      password: '',
    },
  });
  const onSubmit = (data: FormValues) => {
    signin({
      phone: getNumberPhoneWithoutMask(data.phone),
      password: data.password,
    });
  };

  /** валидность формы это отсутствие ошибок (для автозаполненных форм)  */
  const isValidForm = isEmpty(errors);

  return (
    <>
      <Header showSlogan />
      <div className="box register">
        <section className="form-invert">
          <div className="container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <UIInputMask
                setValue={setValue}
                watch={watch}
                name="phone"
                control={control}
                inputMode="numeric"
                mask={PHONE_MASK}
                label="Номер телефона"
                validation={{
                  pattern: PHONE_PATTERN,
                }}
                isRequired
              />
              <UiInput
                value={getValues().password}
                label="Пароль"
                type="password"
                register={register}
                name="password"
                required
                errors={errors}
              />
              <div className="forgot">
                <Link to="/reset-password">Забыли пароль?</Link>
              </div>
              <div className="buttons-group buttons-group-responsive">
                <Button color="primary" type="submit" disabled={!isValidForm || isSubmitting}>
                  Войти
                </Button>
                <Link to="/register" className="btn btn-link">Регистрация</Link>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};
