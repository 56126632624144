import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CDate } from 'utils/CDate';
import { INewsItemModel } from 'models/News/NewsItemModel';
import { UiSimpleMultiSlider } from 'components/common/UI/SimpleMultiSlider';
import { NewsLikeComponent } from 'components/News/common/NewsLike';
import { NewsExternalResources } from 'components/News/common/NewsExternalResources';
import { UiBox } from 'components/common/UI/UiBox';

type Props = {
  model: INewsItemModel;
  onLikeClick: (id: number, liked: boolean) => void;
}

export const NewsOneComponent = ({ model, onLikeClick }: Props) => {
  const onLikeClickLocal = useCallback(() => {
    onLikeClick(model.id, model.is_liked);
  }, [onLikeClick, model.id, model.is_liked]);

  return (
    <>
      <div className="page-header">
        <Link to="/" className="back" />
        Новости холдинга
      </div>
      <UiBox className="newsone">
        <section className="form">
          <div className="articles">
            <div className="articles-list_item open">
              <div className="articles-list_item__wrapper">

                <div className="articles-list_item__box">
                  {model.main_image.fullUrl.length > 0 && (
                    <div className="articles-list_item__img">
                      <img src={model.main_image.fullUrl} alt={model.main_image.name} />
                    </div>
                  )}

                  <div className="articles-list_item__text">
                    <div className="articles-list_item__date">
                      {CDate.format(model.created_at, 'dd.MM.yyyy/HH:mm')}
                    </div>
                    <div className="articles-list_item__title">{model.title}</div>
                    <div className="pb-1 mb-3">
                      <div className="articles-list_item__line">
                        <div className="articles-list_item__tag-list">
                          <div className="articles-list_item__tag-list___wrapper">
                            {model.categories.concat(model.enterprises).map((tag) => (
                              <span key={`${tag.id}${tag.name}`} className="articles-list_item__tag">
                                {tag.name}
                              </span>
                            ))}
                          </div>
                        </div>
                        <NewsLikeComponent liked={model.is_liked} onLikeClick={onLikeClickLocal} />
                      </div>
                    </div>

                    {/* eslint-disable-next-line react/no-danger */}
                    <div className="articles-list_item__desc" dangerouslySetInnerHTML={{ __html: model.content }} />

                    <div className="mt-4">
                      <UiSimpleMultiSlider data={model.images} />

                      {model.ref_video.length > 0 && (
                        <div className={`videoWrapper${model.images.length === 1 ? ' mt-4' : ''}`}>
                          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                          <iframe
                            src={`https://www.youtube.com/embed/${model.ref_video.split('/').pop()}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      )}
                    </div>

                    {(model.socials.length > 0 || model.thirdPartyResources.length > 0) && (
                      <NewsExternalResources socialLinks={model.socials} thirdPartyLinks={model.thirdPartyResources} />
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </UiBox>
    </>
  );
};

NewsOneComponent.whyDidYouRender = true;
