import { FileType, prepareFiles } from 'models/common';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { ISystemPartnersCategoryListItemModel } from 'models/Partners/SystemPartnerCategoryListModel';
import { SelectOptionType } from 'utils/convertModelToOptions';


export type SystemPartnersFormValuesModelType = Omit<SystemPartnersModelType,
  'categories' | 'enterprises' | 'logo' | 'contacts_image' | 'barcode' | 'qr_code' | 'images'> & {
  logo: File | null,
  barcode: File | null,
  qr_code: File | null,
  contacts_image: File | null,
  images: File[] | null,
  categories: SelectOptionType[];
  enterprises: SelectOptionType[];
}

export type SystemPartnersModelType = Readonly<{
  id: number;
  title: string;
  company_name: string;
  description: string;
  is_active: boolean;
  is_recommended: boolean;
  logo: FileType | null;
  images: FileType[],
  barcode: FileType | null;
  qr_code: FileType | null;
  ref_video: string | null;
  ref_partner: string | null;
  ref_apply: string | null;
  contacts: {
    title: string;
    value: string;
  }[],
  contacts_image: FileType;
  categories: ISystemPartnersCategoryListItemModel[];
  enterprises: IEnterpriseModel[];
}>

export type SystemPartnersFormType =
  Omit<SystemPartnersModelType,
  'logo' | 'images' | 'barcode' | 'qr_code' | 'contacts_image' | 'categories' | 'enterprises'>
& {
  logo: string | null;
  images: string[];
  barcode: string | null;
  qr_code: string | null;
  contacts_image: string | null;
  categories: number[];
  enterprises: number[];
}


export class SystemPartnersFormModel implements SystemPartnersModelType {
  id: number;
  barcode: FileType | null;
  qr_code: FileType | null;
  categories: ISystemPartnersCategoryListItemModel[];
  company_name: string;
  contacts: { title: string; value: string }[];
  contacts_image: FileType;
  description: string;
  enterprises: IEnterpriseModel[];
  images: FileType[];
  is_active: boolean;
  is_recommended: boolean;
  logo: FileType | null;
  ref_apply: string | null;
  ref_partner: string | null;
  ref_video: string | null;
  title: string;

  constructor(params: any = {}) {
    this.id = params.id ? +params.id : 0;
    this.categories = params.categories;
    this.company_name = params.company_name;
    this.contacts = params.contacts;
    this.contacts_image = params.contacts_image;
    this.description = params.description;
    this.enterprises = params.enterprises;
    this.images = params.images.map(prepareFiles);
    this.is_active = params.is_active;
    this.is_recommended = params.is_recommended;
    this.logo = prepareFiles(params.logo);
    this.qr_code = prepareFiles(params.qr_code);
    this.barcode = prepareFiles(params.barcode);
    this.ref_apply = params.ref_apply;
    this.ref_partner = params.ref_partner;
    this.ref_video = params.ref_video;
    this.title = params.title;
  }
}


export const emptySystemPartnersFormModel = new SystemPartnersFormModel({
  title: '',
  company_name: '',
  description: '',
  is_active: true,
  is_recommended: false,
  logo: null,
  images: [],
  barcode: null,
  qr_code: null,
  ref_video: null,
  ref_partner: null,
  ref_apply: null,
  contacts: [],
  contacts_image: '',
  categories: [],
  enterprises: [],
});
