import React, { useCallback, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { MedicalComponent } from '../../components/Medical/Medical';
import { IMedicalFilterType, IMedicalModel, MedicalModel } from '../../models/Medical/MedicalModel';
import { handleErrors } from '../../utils/errors';
import { getMedicalList } from '../../api/medical';

export const Medical = () => {
  const [model, setModel] = useState<IMedicalModel>(new MedicalModel());
  const [isLoading, setIsLoading] = useState(true);

  const hasMoreData: boolean = model.amountOfPages > model.page;

  const getData = useCallback(async (filter?: IMedicalFilterType) => {
    setIsLoading(true);
    const modelOrError = await getMedicalList(filter);
    handleErrors(
      modelOrError,
      'get',
      () => {
        if (modelOrError instanceof MedicalModel) {
          setModel((prevState: IMedicalModel) => ({
            ...(modelOrError as IMedicalModel),
            data: cloneDeep(prevState.data).concat(modelOrError.data),
          }));
        }
      },
    );
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <MedicalComponent
      model={model}
      getData={getData}
      hasMoreData={hasMoreData}
      isLoading={isLoading}
    />
  );
};
