import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from 'store/baseSaga';
import { httpGet, httpPut } from 'utils/http';
import { INotificationModel, NotificationsModel } from 'models/Notification/Notifications';
import { maxValueToGetAllDataWithPagination } from 'config/system';
import { ISocketNotification, SocketNotificationModel } from 'models/Notification/SocketNotification';
import {
  notificationsGetListFailAction,
  notificationsGetListOkAction,
  notificationsGetInitOkAction,
  notificationsGetInitFailAction, onReadNotificationOkAction, onReadNotificationFailAction,
} from './actions';
import {
  NOTIFICATIONS_GET_LIST_START,
  NOTIFICATIONS_GET_UNREAD_INIT_START,
  NOTIFICATIONS_ON_READ_NOTIFICATION_START,
} from './constants';


function* notificationsGetListSaga({ payload: { filter, append } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/notifications', filter);

    yield put(notificationsGetListOkAction(new NotificationsModel(response), append));
  }, notificationsGetListFailAction);
}

/** Получить непрочитанные блокирующие уведомления. для показа при старте приложения */
function* getUnreadInitNotifications({ payload: { ids } }: AnyAction) {
  yield baseSaga(function* () {
    /** если указаны id, то запросить их безусловно */
    const params = (ids && Array.isArray(ids) && ids.length) ? {
      'per-page': maxValueToGetAllDataWithPagination,
      id: ids,
    } : {
      is_blocked: '1',
      is_read: '0',
      'per-page': maxValueToGetAllDataWithPagination,
    };

    const response = yield httpGet('/public/notifications', params);

    /** сокетные оповещения немного отличаются от уведомлений. привести к одному виду */
    const model = response.data.map((notif: INotificationModel) => new SocketNotificationModel({
      ...notif,
      action_code: notif.action.code,
    }));

    yield put(notificationsGetInitOkAction(model));
  }, notificationsGetInitFailAction);
}

/** прочесть оповещение-уведомление. затем уже удалить из списка */
function* onReadNotificationSaga(
  { payload: { notification } }: { type: string; payload: { notification: ISocketNotification } },
) {
  yield baseSaga(function* () {
    if (notification.id) {
      /** прочитать уведомление */
      yield httpPut(`/public/notifications/read/${notification.id}`);
      yield put(onReadNotificationOkAction(notification));
    }
  }, function* (e) {
    // eslint-disable-next-line no-console
    console.error(`Не удалось прочитать уведомление "${notification.subject}"`);
    yield put(onReadNotificationFailAction({ ...e, notification }));
  });
}


export function* notificationsSagas() {
  yield takeLatest(NOTIFICATIONS_GET_UNREAD_INIT_START, getUnreadInitNotifications);
  yield takeLatest(NOTIFICATIONS_GET_LIST_START, notificationsGetListSaga);
  yield takeLatest(NOTIFICATIONS_ON_READ_NOTIFICATION_START, onReadNotificationSaga);
}
