import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { CustomTable } from 'components/common/Table';
import { IAllApplicationFilter, IApplicationsListModel } from 'models/Application/interfaces';
import { SystemAssignApplicationsFilter } from 'systemModule/components/Application/Filter';
import { CDate } from 'utils/CDate';
import { useDataPage } from 'utils/hooks/useDataPage';


type Props = {
  data: IApplicationsListModel;
  isLoading: boolean;
  getData: (filter: IAllApplicationFilter) => void;
}


export const SystemAllApplicationsListComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage, onSubmitFilter, onResetFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  return (
    <>
      <h3>
        {t('applications.assign.all.menu')}
      </h3>

      <SystemAssignApplicationsFilter onSubmit={onSubmitFilter} onReset={onResetFilter} showAssign />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: t('application.number') },
          { name: t('application.type') },
          { name: t('application.author') },
          { name: t('Дата создания') },
          { name: t('application.status') },
          { name: t('application.enterprise') },
          { name: t('application.assign') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={`all-applications/${item.id}/${item.type.id}`}>{item.id}</Link>
            </td>
            <td>
              {item.type.name}
            </td>
            <td>
              {item.author.fio}
            </td>
            <td>
              {CDate.format(item.created_at, 'dd MMMM yyyy')}
            </td>
            <td>
              {item.status.name}
            </td>
            <td>
              {item.enterprise}
            </td>
            <td>
              {item.assign.fio}
            </td>
            <td>
              <Button color="primary" className="w-100" tag={Link} to={`all-applications/${item.id}/${item.type.id}`}>
                Подробнее
              </Button>
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
