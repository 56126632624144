import { AnyAction } from 'redux';
import cloneDeep from 'lodash/cloneDeep';

import {
  NEWS_GET_CATEGORIES_FAIL,
  NEWS_GET_CATEGORIES_OK,
  NEWS_GET_CATEGORIES_START,
  NEWS_GET_LIST_FAIL,
  NEWS_GET_LIST_OK,
  NEWS_GET_LIST_START,
} from './constants';
import { INewsModel, NewsModel } from '../../models/News/NewsModel';
import { onFail, onLoad, onLoading } from '../../utils/reducer';
import { INewsCategoryItemModel } from '../../models/News/NewsCategoryModel';

export type newsReducerType = {
  news: INewsModel;
  newsIsLoading: boolean;

  categories: INewsCategoryItemModel[];
  categoriesIsLoading: boolean;
}

const initialState: newsReducerType = {
  news: new NewsModel(),
  newsIsLoading: true,

  categories: [],
  categoriesIsLoading: true,
};

export const storeName = 'news';

export const newsReducer = {
  [storeName]: (state: newsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case NEWS_GET_LIST_START:
        return onLoading(state, 'news');
      case NEWS_GET_LIST_OK: {
        if (payload.append) {
          /** т.к. бесконечная прокрута, то новости добавлять в текущий массив */
          const stateNewsData = cloneDeep(state.news.data);

          const stateNews = {
            ...payload.news,
            data: stateNewsData.concat(payload.news.data),
          };

          return {
            ...state,
            news: stateNews,
            newsIsLoading: false,
          };
        }
        return onLoad(state, payload, 'news');
      }
      case NEWS_GET_LIST_FAIL:
        return onFail(state, 'news');

      case NEWS_GET_CATEGORIES_START:
        return onLoading(state, 'categories');
      case NEWS_GET_CATEGORIES_OK:
        return onLoad(state, payload, 'categories');
      case NEWS_GET_CATEGORIES_FAIL:
        return onFail(state, 'categories');

      default:
        return state;
    }
  },
};
