import React, { useEffect, useMemo } from 'react';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { customHistory } from 'customHistory';
import { globalOpenAppGuide, globalSetAppVersionAction } from 'store/actions';
import {
  getCurrentUserIsLoadingSelector,
  getCurrentUserSelector,
  getIsCurrentUserExistsSelector,
  getCurrentUserRightsSelector,
} from 'store/currentUser/selectors';
import { getGlobalEnvironmentSelector, getGlobalIsOPenAppGuide } from 'store/selectors';
import { systemRoutes } from './systemModule/routes';
import { CRoute, routes } from './routes';
import { Header } from './components/Main/Header';
import { Menu } from './components/Main/Menu';
import { ICurrentUserModel, UserAccessEnum } from './models/User/CurrentUserModel';
import { FullLoader } from './components/common/UI/Loaders';
import { ApplicationGuide } from './components/ApplicationGuide/ApplicationGuide';
import { systemNav } from './systemModule/_nav';
import { store } from './index';
import { systemSagas } from './systemModule/store/sagas';
import { systemGlobalDataLoadedSelector } from './systemModule/store/selectors';
import { SocketNotification } from './containers/SocketNotification/SocketNotification';
import {
  getActualClientRouteList,
  getIntersectionRights,
} from './utils/filteringClientRoutes';

const SystemLayout = React.lazy(() => import('./systemModule/components/Layout/SystemLayout'));

// eslint-disable-next-line max-len
export const AllRoutesRender = ({ allowSystem, currentUserRights }: { allowSystem: boolean, currentUserRights: string[] }) => {
  const intersectionRights = useMemo(() => getIntersectionRights(currentUserRights), [currentUserRights]);
  const clientRouteList = useMemo(() => getActualClientRouteList(intersectionRights), [intersectionRights]);

  const allRoutes = allowSystem ? systemRoutes.concat(clientRouteList) : clientRouteList;

  return (
    <Switch>
      {allRoutes.map((route: CRoute) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact !== false}
          component={route.component}
        />
      ))}
    </Switch>
  );
};

export const RouteChecker = () => {
  const { pathname, search } = useLocation();
  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const currentUserRights = useSelector(getCurrentUserRightsSelector);
  const currentUserIsLoading: boolean = useSelector(getCurrentUserIsLoadingSelector);
  const systemGlobalDataLoaded: boolean = useSelector(systemGlobalDataLoadedSelector);

  const currentUserExists: boolean = useSelector(getIsCurrentUserExistsSelector);

  const userCanSystem = currentUser.hasPermission(UserAccessEnum.systemModule);

  const appEnvironment = useSelector(getGlobalEnvironmentSelector);

  const isSystemRoute = pathname.includes('/system') && userCanSystem;

  const isOpenAppGuide = useSelector(getGlobalIsOPenAppGuide);
  const handleCloseAppGuide = () => {
    store.dispatch(globalOpenAppGuide(false));
  };

  /** взять все внешние роуты */
  const outerRoutes = routes.filter((route) => route.noLayout).map((route) => route.path);

  /** отслеживание входа в админскую часть */
  useEffect(() => {
    /** isSystemRoute=true - зашел в админку, false - вышел из админки/не в админке */
    if (isSystemRoute) {
      /** подключать саги админки */
      store.injectSaga('system', systemSagas);
    }
  }, [isSystemRoute]);

  useEffect(() => {
    if (currentUserExists && !currentUser.mobile_guide_is_completed) {
      store.dispatch(globalOpenAppGuide(true));
    }
  }, [currentUserExists, currentUser.mobile_guide_is_completed]);

  /** проверка текущего роута и прав. не подходит - редирект на главную(?) */
  useEffect(() => {
    if (currentUserExists && isSystemRoute) {
      const nav = systemNav.find((item) => item.url === pathname);
      if (nav && nav.right && !currentUser.hasPermission(nav.right)) {
        customHistory.push('/');
      }
    }
    // eslint-disable-next-line
  }, [currentUserExists, currentUser, isSystemRoute]);

  /** грузятся данные пользователя или глобальные данные админки */
  if (currentUserIsLoading || (userCanSystem && isSystemRoute && !systemGlobalDataLoaded)) {
    return <FullLoader />;
  }

  /** на внутренние роуты нельзя заходить неавторизованному */
  if (!currentUserExists && !outerRoutes.includes(pathname)) {
    const findToken = /Ntf_token=.+/.exec(pathname);
    const allowedSearch = findToken ? `?${findToken[0]}` : '';
    return <Redirect to={`/login${allowedSearch}`} />;
  }

  if (search) {
    /** если есть app_version - сохранить значение */
    const app_version = /app_version=(.+)/.exec(search);
    if (app_version) {
      store.dispatch(globalSetAppVersionAction({ app_version: app_version[1] }));
    }
  }

  /** роуты, у которых свой шаблон. рендерить отдельно */
  const noLayoutRoutes = routes.filter((route) => route.noLayout).map((route) => route.path);

  return (
    <>
      {appEnvironment === 'other' && (
        <span style={{
          position: 'absolute',
          color: 'black',
          left: 0,
          fontSize: '12px',
          background: 'white',
          border: '1px solid red',
          zIndex: 9999,
        }}
        >
          (Тестовое окружение)
        </span>
      )}

      {noLayoutRoutes.includes(pathname) ? (
        <AllRoutesRender allowSystem={userCanSystem} currentUserRights={currentUserRights} />
      ) : (
        <React.Suspense fallback={FullLoader()}>
          {!isSystemRoute && (
            <>
              {currentUserExists && <SocketNotification />}
              <Header />
              <AllRoutesRender allowSystem={userCanSystem} currentUserRights={currentUserRights} />
              <Menu />
            </>
          )}
          {isSystemRoute && (
            <SystemLayout>
              <AllRoutesRender allowSystem={userCanSystem} currentUserRights={currentUserRights} />
            </SystemLayout>
          )}
        </React.Suspense>
      )}
      {isOpenAppGuide && (
        <React.Suspense fallback={FullLoader()}>
          <ApplicationGuide
            isOpenGuide={isOpenAppGuide}
            closeGuide={handleCloseAppGuide}
          />
        </React.Suspense>
      )}
    </>
  );
};
