import { ISystemMedicalFilterType, ISystemMedicalModel } from '../../../models/Medical/SystemMedicalModel';
import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import { SYSTEM_GET_MEDICAL_FAIL, SYSTEM_GET_MEDICAL_OK, SYSTEM_GET_MEDICAL_START } from './constants';

export const systemGetMedicalStartAction = (filter: ISystemMedicalFilterType) => dataAction(
  SYSTEM_GET_MEDICAL_START,
)({ filter });
export const systemGetMedicalOkAction = (medicalList: ISystemMedicalModel) => dataSuccessAction(
  SYSTEM_GET_MEDICAL_OK,
)({ medicalList });
export const systemGetMedicalFailAction = dataErrorAction(SYSTEM_GET_MEDICAL_FAIL);
