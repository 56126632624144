import React from 'react';
import { UiSystemForm } from 'components/common/UI/Form';
import { IEnterpriseSettingsModel } from 'models/Settings/EnterpriseSettingsModel';
import { useForm } from 'react-hook-form';
import { ISystemEnterpriseModel } from 'models/References/EnterprisesModel';
import { UiSystemFormatNumber } from 'components/common/UI/FormatNumber';
import { UiSystemInput } from 'components/common/UI/Input';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { customHistory } from 'customHistory';
import { handleErrors } from 'utils/errors';
import { updateSystemEnterpriseSettings } from 'systemModule/api/setting';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { useSelector } from 'react-redux';
import { getCurrentUserSelector } from 'store/currentUser/selectors';

/* Обновление настроек */
const updateSettings = async (id: number, data: FormValues) => {
  handleErrors(
    await updateSystemEnterpriseSettings(id, data.day),
    'save',
    () => {
      toast.success(i18next.t('enterprise.settings.updated'));
      customHistory.push('/system/references/enterprise');
    },
  );
};

type Props = {
  item: ISystemEnterpriseModel;
  settings: IEnterpriseSettingsModel;
}

type FormValues = {
  name: string;
  day: number;
}

export const SystemEnterpriseItemPageComponent = ({ item, settings }: Props) => {
  const {
    control, register, watch, handleSubmit, errors, formState: { isValid, isSubmitting, dirtyFields },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: item.name,
      day: +settings.value,
    },
  });

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wSettingsEnterprise);

  const onSubmit = (data: FormValues) => (canUpdate ? updateSettings(settings.id, data) : Promise.reject());

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      loadingSubmit={isSubmitting}
      createMode={false}
      dirtyFieldsAmount={dirtyFields.size}
      disabledSubmit={!isValid}
    >
      {
        (item.id === undefined) ? <h3>Предприятие не найдено</h3> : (
          <>
            <h3>Редактирование настроек предприятия</h3>
            <UiSystemInput register={register} name="name" disabled label="Название предприятия" />
            <UiSystemFormatNumber
              errors={errors}
              control={control}
              watch={watch}
              name="day"
              label={settings.name}
              required
              min={1}
              max={31}
            />
          </>
        )}
    </UiSystemForm>
  );
};
