import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { createSelector } from 'reselect';
import { RootReducer } from 'store/reducers';
import { returnParams } from 'store/selectors';
import { storeName } from './reducer';

const getEnterprises = (state: RootReducer): IEnterpriseModel[] => state[storeName].enterprises;
const getEnterprisesIsLoading = (state: RootReducer) => state[storeName].enterprisesIsLoading;

export const getEnterprisesSelector = createSelector([getEnterprises], returnParams);
export const getEnterprisesIsLoadingSelector = createSelector([getEnterprisesIsLoading], returnParams);
