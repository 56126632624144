import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  AuthSignUpPhoneType,
  AuthSignUpSmsCodeType,
  AuthSignUpPasswordType,
  AuthSignUpActiveFormType, AuthSignUpReferralCodeType,
} from '../../models/Auth/interfaces';
import { Header } from '../../components/Main/Header';
import { ConfirmCodeForm } from '../../components/Auth/Signup/ConfirmCodeForm';
import { handleErrors, HttpError } from '../../utils/errors';
import {
  checkCode, checkPassword, checkPhone, checkReferralCode,
} from '../../api/Auth';
import { PasswordFormComponent } from '../../components/Auth/Signup/CreatePasswordForm';
import { PhoneFormComponent } from '../../components/Auth/Signup/PhoneForm';
import { ReferralCodeFormComponent } from '../../components/Auth/Signup/ReferralCodeForm';


export const Signup = () => {
  const history = useHistory();
  const [activeForm, setActiveForm] = useState<AuthSignUpActiveFormType>('referralCode');
  const [referralCode, setReferralCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();

  const checkPhoneSubmit = useCallback(async (data: AuthSignUpPhoneType) => {
    setIsSubmitting(true);

    handleErrors(
      await checkPhone({ ...data, referral_code: referralCode }),
      (e) => {
        /** номер зарегистрирован, но пароль не установлен. перекинуть на установку пароля */
        if (e instanceof HttpError) {
          if (e.code === 462) {
            setActiveForm('password');
          } else {
            toast.error(e.message);
          }
        } else {
          toast.error(t('common.form.errors.save'));
        }
      },
      () => {
        setActiveForm('code');
      },
    );
    setIsSubmitting(false);
  }, [t, referralCode]);

  const checkReferralCodeSubmit = useCallback(async (data: AuthSignUpReferralCodeType) => {
    setIsSubmitting(true);

    handleErrors(
      await checkReferralCode(data),
      (e) => {
        if (e instanceof HttpError) {
          toast.error(e.message);
        }
      },
      () => {
        setActiveForm('phone');
      },
    );
    setIsSubmitting(false);
  }, []);

  const checkCodeSubmit = useCallback(async (data: AuthSignUpSmsCodeType) => {
    setIsSubmitting(true);

    handleErrors(
      await checkCode(data),
      'save',
      () => {
        setActiveForm('password');
      },
    );

    setIsSubmitting(false);
  }, []);

  const createPasswordSubmit = useCallback(async (data: AuthSignUpPasswordType) => {
    setIsSubmitting(true);

    handleErrors(
      await checkPassword(data),
      'save',
      () => {
        setActiveForm(null);
        history.push('/enterprise-selection');
      },
    );

    setIsSubmitting(false);
  }, [history]);

  return (
    <>
      <Header showSlogan />
      <div className="box register">
        <section className="form-invert">
          <div className="container">
            {activeForm === 'code' && (
              <ConfirmCodeForm
                submit={checkCodeSubmit}
                isSubmitting={isSubmitting}
              />
            )}
            {activeForm === 'password' && (
              <PasswordFormComponent
                submit={createPasswordSubmit}
                isSubmitting={isSubmitting}
              />
            )}
            {activeForm === 'phone' && (
              <PhoneFormComponent
                submit={checkPhoneSubmit}
                isSubmitting={isSubmitting}
                setActiveForm={setActiveForm}
              />
            )}
            {activeForm === 'referralCode' && (
              <ReferralCodeFormComponent
                submit={checkReferralCodeSubmit}
                isSubmitting={isSubmitting}
                setReferralCode={setReferralCode}
                setActiveForm={setActiveForm}
              />
            )}
          </div>
        </section>
      </div>
    </>
  );
};
