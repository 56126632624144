import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import { SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_START } from './constants';
import { systemNotificationsGetTemplatesFailAction, systemNotificationsGetTemplatesOkAction } from './actions';
import {
  SystemNotificationTemplatesListModel,
} from '../../../models/Notification/SystemNotificationTemplatesListModel';


function* systemNotificationsGetTemplatesSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/notifications/template-list', { ...filter, sort: 'id' });

    yield put(systemNotificationsGetTemplatesOkAction(new SystemNotificationTemplatesListModel(response)));
  }, systemNotificationsGetTemplatesFailAction);
}


export function* systemNotificationSagas() {
  yield takeLatest(SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_START, systemNotificationsGetTemplatesSaga);
}
