import {
  httpDelete, httpGet, httpPost, httpPut,
} from '../../../utils/http';
import {
  ISystemNewsCategoryModel,
  ISystemNewsCategoryModelForm,
  SystemNewsCategoryModel,
} from '../../../models/References/NewsCategoriesModel';
import { baseApiFn } from '../../../utils/baseApiFn';


export const createSystemNewsCategory = async (model: ISystemNewsCategoryModelForm) => baseApiFn<true>(
  httpPost('system/news/category', model),
);

export const getSystemNewsCategory = async (id: number) => baseApiFn<ISystemNewsCategoryModel>(async () => {
  const response = await httpGet(`system/news/category/${id}`);
  return new SystemNewsCategoryModel(response);
});

export const updateSystemNewsCategory = async (id: number, model: any) => baseApiFn<true>(
  httpPut(`system/news/category/${id}`, model),
);

export const deleteSystemNewsCategory = async (id: number) => baseApiFn<true>(
  httpDelete(`system/news/category/${id}`),
);
