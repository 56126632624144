import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { DefaultFilterType } from '../common';

export interface EnterprisesFilterType extends DefaultFilterType {
  sort?: string;
  name?: string;
}

export interface ISystemEnterpriseModel {
  id: number;
  name: string;
}

export class SystemEnterpriseModel implements ISystemEnterpriseModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}


export interface ISystemEnterprisesModel extends IWithPages {
  data: ISystemEnterpriseModel[];
}

export class SystemUserEnterprisesModel extends WithPagesModel implements ISystemEnterprisesModel {
  data: ISystemEnterpriseModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = params;
  }
}

export class SystemEnterprisesModel extends WithPagesModel implements ISystemEnterprisesModel {
  data: ISystemEnterpriseModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemEnterpriseModel);
  }
}
