import React from 'react';
import { useTranslation } from 'react-i18next';

import { IReportInsurance, IReportInsuranceEnterprise } from '../../../../models/Reports/interfaces';
import { SystemReportHeader } from '../common/Header';
import { Loader } from '../../../../components/common/UI/Loaders';
import { EntityNotFound } from '../../../../components/Errors/404';
import { ReportInsurance } from '../../../../models/Reports/Insurance';
import { SystemReportInsuranceComponent } from './Insurance';
import { ReportInsuranceEnterprise } from '../../../../models/Reports/ReportInsuranceEnterprise';
import { SystemReportInsuranceEnterpriseComponent } from './InsuranceEnterprise';


type Props = {
  model: IReportInsurance | IReportInsuranceEnterprise | null;
  getData: (enterprise_ids?: number[]) => void;
  isLoading: boolean;
}

export const SystemReportInsuranceIndexComponent = ({
  model, isLoading, getData,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <SystemReportHeader
        getData={getData}
        totalCount={model?.user_count}
        message={t('reports.insurance.title')}
      />
      {isLoading ? <Loader /> : (
        <>
          {model instanceof ReportInsurance && (
            <SystemReportInsuranceComponent model={model} />
          )}
          {model instanceof ReportInsuranceEnterprise && (
            <SystemReportInsuranceEnterpriseComponent model={model} />
          )}
          {model === null && (<EntityNotFound message={t('reports.no_data')} />)}
        </>
      )}
    </>
  );
};
