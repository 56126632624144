import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { IInsurancesModel, IInsuranceModel } from '../../../../models/Insurances/InsurancesModel';
import { DefaultFilterType } from '../../../../models/common';
import { useDataPage } from '../../../../utils/hooks/useDataPage';
import { CustomTable } from '../../../../components/common/Table';
import { deleteSystemRefInsurance } from '../../../api/references/insurance';
import { ICurrentUserModel, UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import { getParamsFromPagesModel } from '../../../../models/WithPagesModel';
import { SystemInsuranceFilter } from './Filter';


type Props = {
  data: IInsurancesModel;
  isLoading: boolean;
  getData: (filter: DefaultFilterType) => void;
}

export const SystemRefInsuranceComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage, onSubmitFilter, onResetFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cReferenceInsurance);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dReferenceInsurance);

  const onDelete = (item: IInsuranceModel) => () => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(
      t('common.form.delete.question', { entity: 'страховую программу', entityName: item.name }),
    )) {
      deleteSystemRefInsurance(item.id).then((booleanOrError) => {
        if (booleanOrError instanceof Error) {
          toast.error(t('common.form.errors.delete'));
        } else {
          toast.success(t('system.references.insurance.deleted'));
          getData(getParamsFromPagesModel(data));
        }
      });
    }
  };

  return (
    <>
      <h3>Список страховых программ</h3>

      <SystemInsuranceFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="insurance/create">Создать страховую программу</Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: t('system.references.insurance.name') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={`insurance/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`insurance/${item.id}`}>{item.name}</Link>
            </td>
            <td>
              {canDelete && (
                <Button type="button" color="danger" onClick={onDelete(item)}>
                  {t('common.form.delete')}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
