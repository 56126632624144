import React, { useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';

/**
 * Хук для вызова функций при одиночном или двойном клике
 * @param onSingleClick - функция для одиночного клика
 * @param onDoubleClick - функция для двойного клика
 * @param delay - задержка между кликами
 */
export const useClicksDetect = (
  onSingleClick: (event: React.MouseEvent) => void,
  onDoubleClick: (event: React.MouseEvent) => void,
  delay = 300,
) => {
  const clickCount = useRef<number>(0);

  const checkClickCount = useCallback((event: React.MouseEvent) => {
    switch (clickCount.current) {
      case 1: {
        if (onSingleClick) {
          onSingleClick(event);
        }
        break;
      }
      case 2: {
        if (onDoubleClick) {
          onDoubleClick(event);
        }
        break;
      }
      default: break;
    }
    clickCount.current = 0;
  }, [onSingleClick, onDoubleClick]);

  const debounceCallback = useRef(debounce(checkClickCount, delay)).current;

  return useCallback((event: React.MouseEvent) => {
    clickCount.current += 1;
    debounceCallback(event);
  }, [clickCount, debounceCallback]);
};
