import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import { IUsersModel, UsersModel } from '../../../models/Users/UsersModel';
import { SYSTEM_GET_USERS_LIST_FAIL, SYSTEM_GET_USERS_LIST_OK, SYSTEM_GET_USERS_LIST_START } from './constants';


export type systemUsersReducerType = {
  users: IUsersModel;
  usersIsLoading: boolean;
}

const initialState: systemUsersReducerType = {
  users: new UsersModel(),
  usersIsLoading: true,
};

export const storeName = 'users';

export const systemUsersReducer = {
  [storeName]: (state: systemUsersReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_USERS_LIST_START:
        return onLoading(state, 'users');
      case SYSTEM_GET_USERS_LIST_OK:
        return onLoad(state, payload, 'users');
      case SYSTEM_GET_USERS_LIST_FAIL:
        return onFail(state, 'users');

      default:
        return state;
    }
  },
};
