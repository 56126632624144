import { DefaultFilterType } from 'models/common';
import { ISystemPartnerListModel } from 'models/Partners/SystemPartnerListModel';
import { ISystemPartnersCategoryListModel } from 'models/Partners/SystemPartnerCategoryListModel';
import { dataAction, dataErrorAction, dataSuccessAction } from 'store/actions';
import {
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_FAIL,
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_OK,
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_START,
  SYSTEM_GET_PARTNERS_LIST_FAIL,
  SYSTEM_GET_PARTNERS_LIST_OK,
  SYSTEM_GET_PARTNERS_LIST_START,
} from 'systemModule/containers/Partners/constants';


export const systemGetPartnersCategoryListStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_START,
)({ filter });
export const systemGetPartnersCategoryListOkAction = (
  categoryList: ISystemPartnersCategoryListModel,
) => dataSuccessAction(
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_OK,
)({ categoryList });
export const systemGetPartnersCategoryListFailAction = dataErrorAction(SYSTEM_GET_PARTNERS_CATEGORY_LIST_FAIL);

export const systemGetPartnersListStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_GET_PARTNERS_LIST_START,
)({ filter });
export const systemGetPartnersListOkAction = (partnersList: ISystemPartnerListModel) => dataSuccessAction(
  SYSTEM_GET_PARTNERS_LIST_OK,
)({ partnersList });
export const systemGetPartnersListFailAction = dataErrorAction(SYSTEM_GET_PARTNERS_LIST_FAIL);
