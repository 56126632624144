import React from 'react';

import { EducationItem } from 'containers/Education/EducationItem';
import { AddMenu } from 'components/Main/AddMenu';
import { UserAgreementPage } from 'containers/Auth/UserAgreement';
import { EducationList } from 'containers/Education/EducationList';
import { ApplicationConstructCreate } from 'containers/Applications/ApplicationConstructCreate';
import { ApplicationCreateCategoryList } from 'containers/Applications/ApplicationCreateCategoryList';
import { Login } from 'containers/Auth/Login';
import { EnterpriseSelection } from 'containers/Enterprises/EnterpriseSelection';
import { News } from 'containers/News/News';
import { Signup } from 'containers/Auth/Signup';
import { Logout } from 'containers/Auth/Logout';
import { ContactsContainer } from 'containers/Contacts/Contacts';
import { BusStops } from 'containers/BusStops/BusStops';
import { BusStopTimetable } from 'containers/BusStopTimetable/BusStopTimetable';
import { FeedbackForm } from 'containers/FeedbackForm/FeedbackForm';
import { NewsOne } from 'containers/News/NewsOne';
import { ResetPassword } from 'components/Auth/ResetPassword/ResetPassword';
import { JobDescriptions } from 'containers/JobDescriptions/JobDescriptions';
import { Events } from 'containers/Events/Events';
import { EventsOne } from 'containers/Events/EventsOne';
import { Notifications } from 'containers/Notifications/Notifications';
import { NotificationOne } from 'containers/Notifications/NotificationOne';
import { Polls } from 'containers/Polls/Polls';
import { PollsOne } from 'containers/Polls/PollsOne';
import { Medical } from 'containers/Medical/Medical';
import { PollsOfflineOne } from 'containers/Polls/PollsOfflineOne';
import { Applications } from 'containers/Applications/Applications';
import { ApplicationOne } from 'containers/Applications/ApplicationOne';
import { ApplicationCreateList } from 'containers/Applications/ApplicationCreateList';
import { ApplicationCreateSick } from 'containers/Applications/ApplicationCreateSick';
import { Test } from 'components/Test';
import { ApplicationPrivilegeList } from 'containers/Applications/Privilege/ApplicationPrivilegeList';
import { ApplicationPrivilegeItem } from 'containers/Applications/Privilege/ApplicationPrivilegeItem';
import { PartnersItem } from 'containers/Partners/item';
import { PartnersList } from 'containers/Partners/list';
import { PartnersFavouriteList } from 'containers/Partners/favouriteList';
import { PartnersRecommendedList } from 'containers/Partners/recommendedList';
import { PartnersCategoriesList } from 'containers/Partners/categoriesList';
import { PartnersAllSortedList } from 'containers/Partners/sortedList';
import { PartnersCategoryList } from 'containers/Partners/сategoryList';
import { UserAccessEnum } from 'models/User/CurrentUserModel';
import { Refresh } from 'containers/Refresh/Refresh';
import { ReferralProgram } from 'containers/ReferralProgram/ReferralProgram';
import Page404 from './components/Errors/404';


const Profile = React.lazy(() => import('./containers/Profile/Profile'));


/** некоторые роуты отдельно. для общего использования. например для генерации ссылки в оповещениях */
export enum RoutesPaths {
  newsOne = '/news/:id',
  pollsOne = '/polls/:id',
  eventsOne = '/events/:id',
  applicationOne = '/system/assign-applications/:id/:typeId',
  profile = '/profile',
  jobDescriptions = '/job-descriptions',
  medical = '/medical',
  education = '/education'
}


export type CRoute = {
  path: string;
  component: React.ComponentType;
  exact?: boolean; // false только если указано как false. иначе true
  name?: string;
  noLayout?: boolean;
  right?: string;
}

export type CRoutes = CRoute[];

/** роуты приложения */
export const routes: CRoutes = [
  { path: '/', component: News },
  { path: RoutesPaths.newsOne, component: NewsOne },

  { path: '/add-menu', component: AddMenu },

  { path: '/contacts/', component: FeedbackForm, right: UserAccessEnum.rAppSectionContacts },
  { path: '/contacts-all', component: ContactsContainer, right: UserAccessEnum.rAppSectionContacts },

  { path: '/bus-stops', component: BusStops, right: UserAccessEnum.rAppSectionBusStops },
  { path: '/bus-stops/:id', component: BusStopTimetable, right: UserAccessEnum.rAppSectionBusStops },

  { path: '/events', component: Events },
  { path: RoutesPaths.eventsOne, component: EventsOne },

  { path: '/notifications', component: Notifications },
  { path: '/notifications/:id', component: NotificationOne },

  { path: '/polls', component: Polls },
  { path: RoutesPaths.pollsOne, component: PollsOne },
  { path: '/polls/:id/:userId', component: PollsOfflineOne },

  { path: '/login', component: Login, noLayout: true },
  { path: '/logout', component: Logout, noLayout: true },
  { path: '/user-agreement', component: UserAgreementPage, noLayout: true },
  { path: '/enterprise-selection', component: EnterpriseSelection, noLayout: true },
  { path: '/register', component: Signup, noLayout: true },
  {
    path: '/profile', exact: false, component: Profile, right: UserAccessEnum.rAppSectionProfile,
  },
  { path: '/job-descriptions', component: JobDescriptions, right: UserAccessEnum.rAppSectionJobDescriptions },
  { path: '/reset-password', component: ResetPassword, noLayout: true },
  { path: '/medical', component: Medical, right: UserAccessEnum.rAppSectionMedical },

  {
    path: '/applications/create/sick',
    component: ApplicationCreateSick,
    right: UserAccessEnum.rAppSectionApplications,
  },
  { path: '/applications/create', component: ApplicationCreateList, right: UserAccessEnum.rAppSectionApplications },
  {
    path: '/applications/create/category/:id',
    component: ApplicationCreateCategoryList,
    right: UserAccessEnum.rAppSectionApplications,
  },
  {
    path: '/applications/create/type/:id',
    component: ApplicationConstructCreate,
    right: UserAccessEnum.rAppSectionApplications,
  },
  {
    path: '/applications/privilege',
    component: ApplicationPrivilegeList,
    right: UserAccessEnum.rAppSectionApplications,
  },
  {
    path: '/applications/privilege/:id/:enterprise_id',
    component: ApplicationPrivilegeItem,
    right: UserAccessEnum.rAppSectionApplications,
  },
  { path: '/applications/:id/:typeId', component: ApplicationOne, right: UserAccessEnum.rAppSectionApplications },
  { path: '/applications', component: Applications, right: UserAccessEnum.rAppSectionApplications },

  { path: '/partners', component: PartnersList, right: UserAccessEnum.rAppSectionPartners },
  { path: '/partners/:id', component: PartnersItem, right: UserAccessEnum.rAppSectionPartners },
  { path: '/partners-favourite', component: PartnersFavouriteList, right: UserAccessEnum.rAppSectionPartners },
  { path: '/partners-recommended', component: PartnersRecommendedList, right: UserAccessEnum.rAppSectionPartners },
  { path: '/partners-categories', component: PartnersCategoriesList, right: UserAccessEnum.rAppSectionPartners },
  { path: '/partners-category/:id', component: PartnersCategoryList, right: UserAccessEnum.rAppSectionPartners },
  { path: '/partners-all', component: PartnersAllSortedList, right: UserAccessEnum.rAppSectionPartners },

  { path: RoutesPaths.education, component: EducationList, right: UserAccessEnum.rAppSectionEducation },
  { path: `${RoutesPaths.education}/:id`, component: EducationItem, right: UserAccessEnum.rAppSectionEducation },

  { path: '/referral-program', component: ReferralProgram },

  // { path: '/bonus-program', component: BonusProgram },
  // { path: '/bonus-program/prize/:id', component: BonusProgramPrizeItem },

  { path: '/test', component: Test, noLayout: true },

  { path: '/refresh-app', component: Refresh, noLayout: true },

  { path: '*', component: Page404 },
];
