import { AnyAction } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { baseSaga } from '../../../../store/baseSaga';
import { httpGet } from '../../../../utils/http';
import { systemGetUnitsFailAction, systemGetUnitsOkAction } from './actions';
import { SystemUnitsModel } from '../../../../models/References/UnitsModel';
import { SYSTEM_REFERENCES_GET_UNITS_START } from './constants';


function* systemGetUnitsSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/references/unit', { sort: 'id', ...filter });

    yield put(systemGetUnitsOkAction(new SystemUnitsModel(response)));
  }, systemGetUnitsFailAction);
}


export function* systemUnitsSagas() {
  yield takeLatest(SYSTEM_REFERENCES_GET_UNITS_START, systemGetUnitsSaga);
}
