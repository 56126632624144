import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';


export const Refresh = () => {
  useEffect(() => {
    window.location.reload();
  }, []);
  return <Redirect to="/" />;
};
