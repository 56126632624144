import { baseApiFn } from '../../utils/baseApiFn';
import {
  httpDelete, httpGet, httpPost, httpPut,
} from '../../utils/http';
import { IEventFormModel } from '../../models/Events/IEventFormModel';
import { ISystemEvent, SystemEventModel } from '../../models/Events/SystemEvent';
import { ISystemEventPollResults, SystemEventPollResult } from '../../models/Events/SystemEventPollResult';


/** создание события */
export const createSystemEvent = async (model: IEventFormModel) => baseApiFn<true>(
  httpPost('system/event', model),
);

/** получение события */
export const getSystemEvent = async (id: number) => baseApiFn<ISystemEvent>(async () => {
  const response = await httpGet(`system/event/${id}`);
  return new SystemEventModel(response);
});

/** редактирование события */
export const updateSystemEvent = async (id: number, model: IEventFormModel) => baseApiFn<true>(
  httpPut(`system/event/${id}`, model),
);

/** удаление события */
export const deleteSystemEvent = async (id: number) => baseApiFn<true>(httpDelete(`system/event/${id}`));

/** Результат голосования по мероприятию */
export const getSystemEventPollResult = async (id: number) => baseApiFn<ISystemEventPollResults>(async () => {
  const response = await httpGet(`/system/event/${id}/poll`);
  return response.map((m: unknown) => new SystemEventPollResult(m));
});
