import { IShortUser } from 'models/User/interfaces';
import { WithPagesModel } from 'models/WithPagesModel';
import { CDate } from 'utils/CDate';
import { BACKEND_DATE_FORMAT } from 'config/system';
import {
  ISystemBonusProgramEventHistoryItemModel,
  ISystemBonusProgramEventHistoryListModel,
} from 'models/BonusProgram/EventHistory/interfaces';

export class SystemBonusProgramEventHistoryItemModel implements ISystemBonusProgramEventHistoryItemModel {
  id: number;
  user: IShortUser;
  event: {
    id: number;
    title: string;
    sdscoin: number;
    is_active: boolean;
  };
  sdsCoin: number;
  createdAt: Date;

  constructor(params: any = {}) {
    this.id = params.id;
    this.user = params.user;
    this.event = params.event;
    this.sdsCoin = params.sdscoin;
    this.createdAt = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
  }
}

// eslint-disable-next-line max-len
export class SystemBonusProgramEventHistoryListModel extends WithPagesModel implements ISystemBonusProgramEventHistoryListModel {
  data: ISystemBonusProgramEventHistoryItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemBonusProgramEventHistoryItemModel);
  }
}
