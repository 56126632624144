import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { getSystemUser, updateSystemUserSettings } from 'systemModule/api/user';
import { usePromise } from 'utils/hooks/usePromise';
import { IEditUSerSettings, ISystemUserModel } from 'models/User/interfaces';
import { EntityNotFound } from 'components/Errors/404';
import { Loader } from 'components/common/UI/Loaders';
import { handleErrors } from 'utils/errors';
import { SystemUserSettingsComponent } from '../../components/UsersPhone/SystemUserSettings';

export const SystemUserSettings = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [user, userLoading] = usePromise<ISystemUserModel>(
    () => getSystemUser(numberId),
    !isNaN(numberId),
    [numberId],
  );

  const onSave = useCallback(async (data: IEditUSerSettings) => {
    handleErrors(
      await updateSystemUserSettings(numberId, data),
      'save',
      () => toast.success(t('profile.settings.changed')),
    );
  }, [numberId, t]);


  if (userLoading) {
    return <Loader />;
  }
  if (isNaN(numberId) || !user) {
    return <EntityNotFound message={t('common.user.notFound')} />;
  }
  return <SystemUserSettingsComponent user={user} onSave={onSave} />;
};
