import React, { useEffect } from 'react';

import { getFavouritePartners, getAllPartnersCategory, getRecommendedPartners } from 'api/partner';
import { PartnersListComponent } from 'components/Partners/list';
import { IPartnersListModel } from 'models/Partners/PartnersListModel';
import { getModelFromModels } from 'utils/getModelFromModels';
import { usePromise } from 'utils/hooks/usePromise';
import { HttpErrors } from 'utils/http';
import { Loader } from 'components/common/UI/Loaders';
import { IPartnerCategoryListModel } from 'models/Partners/PartnerCategoryListModel';


export const PartnersList = () => {
  const [models, modelsLoading, onUnmount] = usePromise<[
    IPartnersListModel | HttpErrors,
    IPartnersListModel | HttpErrors,
    IPartnerCategoryListModel | HttpErrors
  ]>(
    () => Promise.all([
      getRecommendedPartners(31),
      getFavouritePartners(31),
      getAllPartnersCategory(),
    ]),
    true,
  );
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  const recommended = getModelFromModels(models, 0, []);
  const favourite = getModelFromModels(models, 1, []);
  const categories = getModelFromModels(models, 2, []);

  return modelsLoading ? <Loader /> : (
    <PartnersListComponent recommendedList={recommended} favouriteList={favourite} categoriesList={categories} />
  );
};
