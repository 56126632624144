import { baseApiFn } from '../utils/baseApiFn';
import { httpGet, httpPut } from '../utils/http';
import {
  INotificationSettings,
  INotificationSettingsFormType,
  NotificationSettings,
} from '../models/Notification/NotificationSettings';
import { INotificationModel, NotificationModel } from '../models/Notification/Notifications';


/** получить настройки уведомлений */
export const getNotificationSettings = async () => baseApiFn<INotificationSettings>(async () => {
  const response = await httpGet('/public/notifications/settings');
  return new NotificationSettings(response);
});

/** Редактирование настроек уведомлений пользователя */
export const saveNotificationSettings = async (
  data: INotificationSettingsFormType,
) => baseApiFn<INotificationSettings>(async () => {
  const response = await httpPut('/public/notifications/settings', data);
  return new NotificationSettings(response);
});

/** Запрос на подтверждение емейла */
export const confirmEmailRequest = async () => baseApiFn<true>(
  httpPut('/public/notifications/confirm-email-request'),
);

/** Подтверждение емейла */
export const setConfirmEmail = async (code: string) => baseApiFn<true>(
  httpPut('/public/notifications/confirm-email', { code }),
);

/** Уведомление пользователя */
export const getNotification = async (id: number) => baseApiFn<INotificationModel>(async () => {
  const response = await httpGet(`/public/notifications/${id}`);
  return new NotificationModel(response);
});

/** Отметить как прочитанное/непрочитанное, в зависимости от setRead */
export const setNotificationReadVal = async (id: number, setRead: boolean) => baseApiFn<true>(
  httpPut(`/public/notifications/${setRead ? 'read' : 'unread'}/${id}`),
);

/** Отметить все уведомления как прочитанные */
export const setAllNotificationsRead = async () => baseApiFn<true>(httpPut('/public/notifications/read-all'));
