import React from 'react';
import { ListElement } from '../common/ListElement/ListElement';
import { IUserListModel } from '../../models/Users/UsersModel';
import { IUserList, IUserListUnit } from '../../models/Users/interfaces';
import { ReactComponent as LikeIcon } from '../../assets/img/like.svg';

type Props = {
  userList: IUserListModel[];
  toggleFavorite: (index: number, unitId: number, userId: number, isFavorite: boolean) => void;
  onSelect: (data: { id: number; fio?: string; name?: string }) => void;
  changingFavoriteLoading: boolean;
}

export const ContactList = ({
  userList, toggleFavorite, onSelect, changingFavoriteLoading,
}: Props) => {
  const onChange = (e: React.MouseEvent, index: number, unitId: number, userId: number, isFavorite: boolean) => {
    e.stopPropagation();
    toggleFavorite(index, unitId, userId, isFavorite);
  };

  return (
    <>
      {userList.map((node: IUserListModel, index) => (
        <React.Fragment key={`${node.code}${node.units.map((u) => u.id).join(',')}`}>
          <h3 className="mt-4 mb-0">{node.name}</h3>
          {node.units.map((unit: IUserListUnit) => (
            <React.Fragment key={unit.id}>
              <h4>{unit.name}</h4>
              {unit.users.map((user: IUserList) => (
                <ListElement
                  text={user.fio}
                  isPointer
                  isTitle
                  className="mb-3"
                  key={user.personnel_number}
                  onClick={() => onSelect({ id: user.id, fio: user.fio })}
                  endIconClassName={`favorite${user.is_favorite ? ' active' : ''}`}
                  endIcon={(
                    <button
                      className={`list-element_icon like-btn favorite${user.is_favorite ? ' active' : ''}`}
                      onClick={(e) => onChange(e, index, unit.id, user.id, user.is_favorite)}
                      type="button"
                      disabled={changingFavoriteLoading}
                    >
                      <LikeIcon />
                    </button>
                  )}
                >
                  <div className="item status">{user.post}</div>
                  {user.phone && (
                    <div className="item status">
                      <a href={`tel: ${user.phone}`}>{user.phone}</a>
                    </div>
                  )}
                </ListElement>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};
