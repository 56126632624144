import React from 'react';
import { useSelector } from 'react-redux';

import { SystemNewsFormComponent } from '../../components/News/SystemNewsForm';
import { systemEnterprisesSelector, systemNewsCategoriesSelector } from '../../store/selectors';
import { INewsCategoryItemModel } from '../../../models/News/NewsCategoryModel';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';


export const SystemNewsCreate = () => {
  const categories: INewsCategoryItemModel[] = useSelector(systemNewsCategoriesSelector);
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  return (
    <SystemNewsFormComponent categories={categories} enterprises={enterprises} />
  );
};
