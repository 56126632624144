import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  systemBonusProgramGetEventListStartAction,
} from 'systemModule/containers/BonusProgram/actions';
import {
  ISystemBonusProgramEventFilter,
  ISystemBonusProgramEventListModel,
} from 'models/BonusProgram/Event/interfaces';
import {
  systemBonusProgramEventListLoadingSelector,
  systemBonusProgramEventListSelector,
} from 'systemModule/containers/BonusProgram/selectors';
import {
  SystemBonusProgramEventListComponent,
} from 'systemModule/components/BonusProgram/BonusProgramEvents/SystemBonusProgramEventList';

export const SystemBonusProgramEventList = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: ISystemBonusProgramEventFilter = {}) => {
    dispatch(systemBonusProgramGetEventListStartAction(filter));
  }, [dispatch]);

  const data: ISystemBonusProgramEventListModel = useSelector(systemBonusProgramEventListSelector);
  const isLoading: boolean = useSelector(systemBonusProgramEventListLoadingSelector);

  return (
    <SystemBonusProgramEventListComponent
      data={data}
      isLoading={isLoading}
      getData={getData}
    />
  );
};
