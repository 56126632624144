import { put, takeLatest } from 'redux-saga/effects';

import { baseSaga } from 'store/baseSaga';
import { httpGet } from 'utils/http';
import {
  systemGetTransportRoutesFailAction,
  systemGetTransportRoutesOkAction,
} from 'systemModule/containers/Transport/actions';
import { SystemRoutesListModel } from 'models/Transport/SystemRouteModel';
import { SYSTEM_TRANSPORT_GET_ROUTES_LIST_START } from './constants';

export function* systemGetRoutesListSaga() {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/transport/route');

    yield put(systemGetTransportRoutesOkAction(new SystemRoutesListModel(response)));
  }, systemGetTransportRoutesFailAction);
}

export function* systemTransportSagas() {
  yield takeLatest(SYSTEM_TRANSPORT_GET_ROUTES_LIST_START, systemGetRoutesListSaga);
}
