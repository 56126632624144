import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { systemReferralListSelector, systemReferralListLoadingSelector } from './selectors';
import { IReferralProgramFilter, ISystemReferralProgramListModel } from '../../../models/ReferralProgram/interfaces';
import { SystemReferralListComponent } from '../../components/ReferralProgram/SystemReferralList';
import { systemReferralGetListStartAction } from './actions';


export const SystemReferralList = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: IReferralProgramFilter = {}) => {
    dispatch(systemReferralGetListStartAction(filter));
  }, [dispatch]);

  const data: ISystemReferralProgramListModel = useSelector(systemReferralListSelector);
  const isLoading: boolean = useSelector(systemReferralListLoadingSelector);

  return <SystemReferralListComponent data={data} isLoading={isLoading} getData={getData} />;
};
