import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { systemNotificationsGetTemplatesStartAction } from './actions';
import { systemNotificationTemplatesIsLoadingSelector, systemNotificationTemplatesSelector } from './selectors';
import {
  ISystemNotificationTemplatesListModel,
} from '../../../models/Notification/SystemNotificationTemplatesListModel';
import { SystemNotificationTemplatesComponent } from '../../components/Notification/SystemNotificationTemplates';
import { DefaultFilterType } from '../../../models/common';


export const SystemNotificationTemplates = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemNotificationsGetTemplatesStartAction(filter));
  }, [dispatch]);

  const data: ISystemNotificationTemplatesListModel = useSelector(systemNotificationTemplatesSelector);
  const isLoading: boolean = useSelector(systemNotificationTemplatesIsLoadingSelector);

  return <SystemNotificationTemplatesComponent data={data} isLoading={isLoading} getData={getData} />;
};
