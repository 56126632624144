import React, { useCallback, useState } from 'react';

import { handleErrors } from '../../../utils/errors';
import { getSystemReportHousing } from '../../../api/reports';
import { IReportHousingList } from '../../../models/Reports/interfaces';
import { SystemReportHousingComponent } from '../../components/Reports/Housing';
import { ReportHousingList } from '../../../models/Reports/HousingList';
import { DefaultFilterType } from '../../../models/common';


export const SystemReportHousing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState<IReportHousingList>(new ReportHousingList());

  const getData = useCallback(async (filter: DefaultFilterType) => {
    setIsLoading(true);
    handleErrors(
      await getSystemReportHousing(filter),
      'get',
      setModel,
      () => setIsLoading(false),
    );
  }, []);

  return (
    <SystemReportHousingComponent data={model} isLoading={isLoading} getData={getData} />
  );
};
