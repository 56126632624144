import React from 'react';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { usePromise } from '../../../utils/hooks/usePromise';
import { getSystemReferralItem } from '../../api/referral';
import { Loader } from '../../../components/common/UI/Loaders';
import { EntityNotFound } from '../../../components/Errors/404';
import { ISystemReferralProgramModel } from '../../../models/ReferralProgram/interfaces';
import { SystemReferralItemComponent } from '../../components/ReferralProgram/SystemReferralItem';

export const SystemReferralItem = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [item, itemLoading] = usePromise<ISystemReferralProgramModel>(
    () => getSystemReferralItem(numberId),
    !isNaN(numberId),
    [numberId],
  );

  if (itemLoading) {
    return <Loader />;
  }
  if (isNaN(numberId) || !item) {
    return <EntityNotFound message="Не найдено" />;
  }
  return <SystemReferralItemComponent data={item} dataLoading={itemLoading} />;
};
