import { ApplicationConstructTypes } from 'models/ConstructApplication/interfaces';
import React from 'react';
import {
  Button, Col, FormGroup, Input, Row,
} from 'reactstrap';
import { FieldErrors } from 'react-hook-form';

import { UiSystemInput } from 'components/common/UI/Input';
import { ReactComponent as PlusIcon } from 'assets/img/plus-square.svg';
import { ReactComponent as TrashIcon } from 'assets/img/trash.svg';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';
import {
  FieldFormValues,
  MAX_FIELDS_AMOUNT_IN_BLOCK,
  typeOptions,
} from 'systemModule/components/ConstructApplication/Type/common';


type Props = {
  blockIndex: number;
  errors: FieldErrors<any>;
  register: () => any;
  showGenerateFields: boolean;
  fields: FieldFormValues[];
  appendField: () => void;
  removeField: (fieldId: string) => void;
}

export const PartFieldsComponent = ({
  fields, blockIndex, errors, register, showGenerateFields, appendField, removeField,
}: Props) => (
  <>
    <Row>
      <Col xs={4}>
        Название поля
      </Col>
      <Col xs={1}>
        Обяз. заполнения
      </Col>
      <Col xs={3}>
        Тип поля
      </Col>
      {showGenerateFields && (<Col xs={3}>Метка в шаблоне</Col>)}
      <Col xs={1} />
    </Row>

    {fields.map((field, fieldIndex) => (
      <Row key={`field${field.id}`}>
        <Col xs={4}>
          <UiSystemInput
            name={`blocks[${blockIndex}].fields[${fieldIndex}].name`}
            errors={errors}
            register={register}
            required
            defaultValue={field.name}
          />
        </Col>
        <Col xs={1}>
          <UiSystemCheckbox
            register={register}
            name={`blocks[${blockIndex}].fields[${fieldIndex}].required`}
            label="&nbsp;"
          />
        </Col>
        <Col xs={3}>
          <FormGroup>
            <Input
              type="select"
              defaultValue={field.type}
              innerRef={register()}
              name={`blocks[${blockIndex}].fields[${fieldIndex}].type`}
            >
              {typeOptions.map((option) => (
                <option key={`${option.label}_${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col xs={3}>
          {showGenerateFields && (
            <UiSystemInput
              name={`blocks[${blockIndex}].fields[${fieldIndex}].template_mark`}
              errors={errors}
              register={register}
              required={showGenerateFields}
              defaultValue={field.template_mark}
            />
          )}
        </Col>
        <Col xs={1}>
          {fields.filter((f) => f).length > 1 && (
            <Button
              type="button"
              color="danger"
              onClick={() => removeField(field.id)}
              className="construct-application-type-field--btn mr-2"
            >
              <TrashIcon />
            </Button>
          )}
          {(fields.filter((f) => f).length < MAX_FIELDS_AMOUNT_IN_BLOCK && fieldIndex === fields.length - 1) && (
            <Button
              type="button"
              color="success"
              onClick={appendField}
              className="construct-application-type-field--btn"
            >
              <PlusIcon />
            </Button>
          )}
        </Col>
        {field.type === ApplicationConstructTypes.select && (
          <Col xs={10}>
            <UiSystemInput
              label="Значения списка через ';'"
              name={`blocks[${blockIndex}].fields[${fieldIndex}].list`}
              errors={errors}
              register={register}
              required
              defaultValue={field.list}
            />
          </Col>
        )}
      </Row>
    ))}
  </>
);
