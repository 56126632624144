export interface IPollsStatusModel {
  key: number;
  value: string;
}

export class PollsStatusModel implements IPollsStatusModel {
  key: number;
  value: string;

  constructor(params: any = {}) {
    this.key = params.key;
    this.value = params.value;
  }
}


export enum PollStatusEnum {
  active = 5,
  stopped = 10,
  finished = 15,
}
