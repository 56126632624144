import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import split from 'lodash/split';
import isNaN from 'lodash/isNaN';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import toPairs from 'lodash/toPairs';

import { BACKEND_DATE_FORMAT_TO } from 'config/system';
import { ApplicationConstructFormBlockType } from 'models/Application/Construct/interfaces';
import { CDate } from 'utils/CDate';
import { ApplicationConstructTypes } from 'models/ConstructApplication/interfaces';


export const blockInfoSeparator = '___';

/** имя блока */
export const getBlockName = (
  block_id: number,
  field_uid: string,
  field_type: string,
): string => `blocks.${block_id}${blockInfoSeparator}${field_uid}${blockInfoSeparator}${field_type}`;


/**
 * подготовить значение блока по типу
 * если значения нет или не подходит, возвращать null, т.к. это будет пустое значение
 * типы бы пожестче
 */
export const getFieldValue = (type: string, value?: any) => {
  switch (type) {
    case ApplicationConstructTypes.number: {
      if (isNumber(value) && !isNaN(value)) {
        return value;
      }
      if (isString(value)) {
        return value.length ? Number(value) : null;
      }
      return null;
    }
    case ApplicationConstructTypes.checkbox: {
      if (isString(value)) {
        return value === 'true';
      }
      return Boolean(value);
    }
    case ApplicationConstructTypes.datetime:
      return value ? CDate.format(+value, BACKEND_DATE_FORMAT_TO) : null;
    case ApplicationConstructTypes.select:
      return value ? value.value : null;
    case ApplicationConstructTypes.string:
      return value.length ? value : null;
    case ApplicationConstructTypes.file: {
      return value instanceof File ? value : null;
    }
    default:
      return null;
  }
};

/** создать одиночный блок */
export const prepareBlock = ([blockInfo, blockValue]: [string, any]) => {
  const [block_id, uid, type] = split(blockInfo, blockInfoSeparator);

  const value = getFieldValue(type, blockValue);

  return {
    block_id: Number(block_id),
    fields: [{ uid, value }],
  };
};

/** мержить блоки, имеющие одинаковые block_id */
export const reduceBlocks = (blocks: ApplicationConstructFormBlockType[]) => reduce(blocks, (
  acc: ApplicationConstructFormBlockType[],
  block: ApplicationConstructFormBlockType,
): ApplicationConstructFormBlockType[] => {
  const index = acc.findIndex((b: any) => b.block_id === block.block_id);
  if (index === -1) {
    acc.push(block);
  } else {
    acc[index].fields = acc[index].fields.concat(block.fields);
  }
  return acc;
}, []);

/** подготовить блоки из внутренней структуры к сохранению */
export const getAdditionalFields = (blocks: Record<string, any>) => reduceBlocks(
  filter(
    map(
      toPairs(blocks),
      prepareBlock,
    ),
  ),
);
