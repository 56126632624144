import React from 'react';

import { ListElementBaseContent, ListElementBaseProps } from './ListElementBaseContent';

type Props = ListElementBaseProps & {
  isFollow?: boolean;
  isPointer?: boolean;
  className?: string;
  onClick?: () => void;
}

export const ListElement = ({
  isFollow,
  isPointer,
  className,
  onClick,
  ...props
}: Props) => {
  const listElementClassName = ['list-element'];
  if (className) {
    listElementClassName.push(className);
  }
  const handleClick = () => (onClick ? onClick() : null);

  return (
    <div
      role="presentation"
      className={listElementClassName.join(' ')}
      style={{ cursor: isFollow || isPointer ? 'pointer' : 'default' }}
      onClick={handleClick}
    >
      <ListElementBaseContent {...props} isFollow={isFollow} />
    </div>
  );
};
