import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { UiBox } from 'components/common/UI/UiBox';
import { LocalUsersFilterType } from 'models/Users/interfaces';
import { EnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { IUserListModel } from 'models/Users/UsersModel';
import { IDepartmentItemModel } from 'models/References/DepartmentModel';
import { ContactList } from './ContactList';
import { Loader } from '../common/UI/Loaders';
import { ContactSearch } from './Search';
import { ListElement } from '../common/ListElement/ListElement';

type Props = {
  usersIsLoading: boolean;
  departmentsIsLoading: boolean;
  changingFavoriteLoading: boolean;
  getUsers: (filter: LocalUsersFilterType, isFavorite?: boolean) => void;
  getDepartments: (name?: string) => void;
  toggleFavorite: (index: number, unitId: number, userId: number, isFavorite: boolean) => void;
  allEnterprises: EnterpriseModel[];
  currentEnterprise: EnterpriseModel[];
  users: IUserListModel[];
  departments: IDepartmentItemModel[];
  localStorageKey: string;
}

export const ContactsComponent = ({
  usersIsLoading,
  departmentsIsLoading,
  changingFavoriteLoading,
  getUsers,
  getDepartments,
  toggleFavorite,
  allEnterprises,
  currentEnterprise,
  users,
  departments,
  localStorageKey,
}: Props) => {
  const { state = {}, search: searchParams } = useLocation<{
    from?: string;
    dateStart?: string;
    dateEnd?: string;
  }>();
  const history = useHistory();

  const params = new URLSearchParams(searchParams);
  const isFavoriteTab = params.has('tab');
  /** если нужен список департаментов и не избранный список пользователей */
  const showDepartments = params.has('additional') && !isFavoriteTab;

  /** Выбор пользователя или департамента */
  const onSelect = useCallback(({
    id, fio = undefined, name = undefined, isDepartment = false,
  }) => {
    if (state.from) {
      history.push(state.from, {
        model: {
          id, fio, name, isDepartment,
        },
        dateStart: state.dateStart,
        dateEnd: state.dateEnd,
      });
    }
  }, [history, state]);

  /** Если есть куда возвращаться, то идти назад, инчае на главную  */
  const toBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <>
      <div className="page-header">
        <span role="presentation" className="back cursor-pointer" onClick={toBack} />
        {showDepartments ? 'Список департаментов и контактов' : 'Все контакты'}
      </div>

      <UiBox className="contacts">
        <section className="form">
          <ContactSearch
            allEnterprises={allEnterprises}
            currentEnterprise={currentEnterprise}
            getDepartments={getDepartments}
            getUsers={getUsers}
            localStorageKey={localStorageKey}
          />
          {(usersIsLoading || departmentsIsLoading) ?
            <Loader className="mt-3" /> : (
              <>
                {showDepartments && departments.map((item) => (
                  <ListElement
                    key={item.id}
                    text={item.name}
                    isFollow
                    onClick={() => onSelect({ id: item.id, name: item.name, isDepartment: true })}
                  />
                ))}

                {users.length > 0 && (
                  <ContactList
                    onSelect={onSelect}
                    userList={users}
                    toggleFavorite={toggleFavorite}
                    changingFavoriteLoading={changingFavoriteLoading}
                  />
                )}
                {(!users.length && !departments.length) && (
                  <div className="text-center mt-3">Контакты {showDepartments && 'и департаменты'} не найдены</div>
                )}
              </>
            )}
        </section>
      </UiBox>
    </>
  );
};

ContactsComponent.whyDidYouRender = true;
