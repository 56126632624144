import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getParamsFromPagesModel } from 'models/WithPagesModel';
import { DefaultFilterType } from 'models/common';
import {
  IConstructApplicationTemplateListItemModel,
  IConstructApplicationTemplateListModel,
} from 'models/ConstructApplication/interfaces';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { deleteConstructApplicationTemplate } from 'systemModule/api/construct-application';
import {
  ConstructApplicationTemplateListComponent,
} from 'systemModule/components/ConstructApplication/ConstructApplicationTemplateList';
import {
  systemGetConstructApplicationTemplateListStartAction,
} from 'systemModule/containers/ConstructApplication/actions';
import {
  systemConstructApplicationTemplateListIsLoadingSelector,
  systemConstructApplicationTemplateListSelector,
} from 'systemModule/containers/ConstructApplication/selectors';
import { handleErrors } from 'utils/errors';


export const ConstructApplicationTemplateList = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemGetConstructApplicationTemplateListStartAction(filter));
  }, [dispatch]);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cApplication);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dApplication);

  const data: IConstructApplicationTemplateListModel = useSelector(systemConstructApplicationTemplateListSelector);
  const isLoading: boolean = useSelector(systemConstructApplicationTemplateListIsLoadingSelector);

  const onDelete = async ({ id, name }: IConstructApplicationTemplateListItemModel) => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: 'шаблон', entityName: name }))) {
      handleErrors(
        await deleteConstructApplicationTemplate(id),
        'delete',
        () => {
          toast.success(t('system.construct_application.template.deleted'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  };

  return (
    <ConstructApplicationTemplateListComponent
      data={data}
      isLoading={isLoading}
      getData={getData}
      canCreate={canCreate}
      canDelete={canDelete}
      onDelete={onDelete}
    />
  );
};
