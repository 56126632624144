import { FileType, prepareFiles } from 'models/common';
import {
  IConstructApplicationTemplateListItemModel,
  IConstructApplicationTemplateListModel,
  IConstructApplicationTypeModel,
} from 'models/ConstructApplication/interfaces';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { WithPagesModel } from 'models/WithPagesModel';


export class ConstructApplicationTemplateListItemModel implements IConstructApplicationTemplateListItemModel {
  readonly id: number;
  readonly name: string;
  readonly can_delete: boolean;
  readonly enterprise: IEnterpriseModel;
  readonly file: FileType | null;

  constructor(params: any = {}) {
    this.id = params.id || 0;
    this.name = params.name || '';
    this.enterprise = params.enterprise || [];
    this.file = params.file ? prepareFiles(params.file) : null;
    this.can_delete = params.can_delete || false;
  }

  get isNew() {
    return this.id === 0;
  }
}


export class ConstructApplicationTemplateListModel extends WithPagesModel
  implements IConstructApplicationTemplateListModel {
  readonly data: IConstructApplicationTemplateListItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, ConstructApplicationTemplateListItemModel);
  }
}


export class ConstructApplicationTypeModel implements IConstructApplicationTypeModel {
  readonly additionalFields: {
    block_id: number;
    name: string;
    has_template: boolean;
    template_id: number;
    file: FileType;
    fields: { uid: string; name: string; type: string; required: boolean; list: string[]; template_mark: string }[];
  }[];
  readonly assignUsers: {
    id: number;
    fio: string; phone: string; unit: string; post: string;
  }[];
  readonly availability: {
    privilege_id: number;
    enable_all: boolean;
    enable_part_time: boolean;
    enable_married: boolean;
    enable_many_children: boolean;
    enable_yong: boolean;
    enable_president: boolean;
    enable_invite: boolean;
    enable_contract: boolean;
    enable_invalid_children: boolean;
    enable_children_count: number;
    enable_children_age: number;
  };
  readonly category: {
    id: number; name: string; can_delete: boolean;
  } | null;
  readonly created_at: string;
  readonly deadline: number;
  readonly enterprise: IEnterpriseModel | null;
  readonly id: number;
  readonly is_active: boolean;
  readonly name: string;
  readonly need_approval: boolean;
  readonly original_place: string;
  readonly schedule: string;
  readonly send_email: boolean;
  readonly updated_at: string;

  constructor(params: any = {}) {
    this.additionalFields = params.additionalFields ? params.additionalFields.map((field: Record<string, any>) => ({
      ...field,
      file: prepareFiles(field.file),
    })) : undefined;
    this.assignUsers = params.assignUsers || [];
    this.availability = params.availability;
    this.category = params.category || {};
    this.created_at = params.created_at;
    this.deadline = params.deadline;
    this.enterprise = params.enterprise;
    this.id = params.id || 0;
    this.is_active = params.is_active;
    this.name = params.name || '';
    this.need_approval = params.need_approval;
    this.original_place = params.original_place;
    this.schedule = params.schedule;
    this.send_email = params.send_email;
    this.updated_at = params.updated_at;
  }

  get isNew() {
    return this.id === 0;
  }
}
