import { createSelector } from 'reselect';

import { RootReducer } from './reducers';
import { storeName } from './globalReducer';

/** базовая функция сравнения для селекторов */
export const returnParams = (d: any) => d;


const showLoader = (state: RootReducer) => state[storeName].showLoader;
export const showLoaderSelector = createSelector([showLoader], returnParams);

const getGlobalUnreadInfo = (state: RootReducer) => state[storeName].unreadInfo;
export const getGlobalUnreadInfoSelector = createSelector([getGlobalUnreadInfo], returnParams);

export const getGlobalEnvironmentSelector = (state: RootReducer) => state[storeName].environment;

export const getGlobalIsOnline = (state: RootReducer) => state[storeName].isOnline;

export const getGlobalIsOPenAppGuide = (state: RootReducer) => state[storeName].isOpenApplicationGuide;
