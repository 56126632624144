import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import {
  SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_FAIL,
  SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_OK,
  SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_START,
} from './constants';
import {
  ISystemNotificationTemplatesListModel,
} from '../../../models/Notification/SystemNotificationTemplatesListModel';
import { DefaultFilterType } from '../../../models/common';


export const systemNotificationsGetTemplatesStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_START,
)({ filter });
export const systemNotificationsGetTemplatesOkAction = (
  templates: ISystemNotificationTemplatesListModel,
) => dataSuccessAction(
  SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_OK,
)({ templates });
export const systemNotificationsGetTemplatesFailAction = dataErrorAction(SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_FAIL);
