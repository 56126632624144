import React, { ComponentType, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { systemUsersIsLoadingSelector, systemUsersSelector } from 'systemModule/containers/Users/selectors';
import { systemGlobalGetRolesStartAction } from 'systemModule/store/actions';
import { ISystemUserListModel, SystemUsersFilterType } from 'models/Users/interfaces';
import { systemGetUsersListStartAction } from 'systemModule/containers/Users/actions';
import { maxValueToGetAllDataWithPagination } from 'config/system';

export interface WrappedComponentProps {
  data: ISystemUserListModel;
  isLoading: boolean;
  getData: (filter: SystemUsersFilterType) => void;
}

export function systemUsersWithHOC<P extends WrappedComponentProps>(
  WrappedComponent: ComponentType<P>, isNeedUserRoles: boolean,
): FC {
  return (props) => {
    const dispatch = useDispatch();

    const getData = useCallback((filter: SystemUsersFilterType = {}) => {
      dispatch(systemGetUsersListStartAction(filter));
      if (isNeedUserRoles) {
        dispatch(systemGlobalGetRolesStartAction({ ...filter, 'per-page': maxValueToGetAllDataWithPagination }));
      }
    }, [dispatch]);

    const data: ISystemUserListModel = useSelector(systemUsersSelector);
    const isLoading: boolean = useSelector(systemUsersIsLoadingSelector);

    return (
      <WrappedComponent
        data={data}
        isLoading={isLoading}
        getData={getData}
        {...props as any}
      />
    );
  };
}
