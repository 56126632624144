import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { convertModelToOptions, SelectOptionType, SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { hasUnemptyValues } from 'utils/objects';
import { IRolesModel } from 'models/Roles/RolesModel';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import {
  systemEnterprisesSelector,
  systemGlobalUnitsSelector,
  systemRbacRolesSelector,
} from 'systemModule/store/selectors';
import { ISystemUnitModel } from 'models/References/UnitsModel';
import { SystemUsersFilterType } from 'models/Users/interfaces';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiToolbar } from 'components/common/Toolbar';
import { UiSelect } from 'components/common/UI/Select';

type Props = {
  onSubmit: (data: SystemUsersFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  enterprise: { label: string; value: number }[];
  unit: { label: string; value: number }[];
  name: string;
  phone: string;
  roles: { label: string; value: string }[];
  isActive: SelectOptionType<boolean> | null;
}

const showActiveUsersOptions = [
  {
    label: 'Нет',
    value: false,
  },
  {
    label: 'Да',
    value: true,
  },
];

export const SystemUsersFilter = ({ onSubmit, onReset }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, setValue, errors, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise: [],
      unit: [],
      name: '',
      phone: '',
      roles: [],
      isActive: null,
    },
  });

  const units: ISystemUnitModel[] = useSelector(systemGlobalUnitsSelector);
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);
  const roles: IRolesModel = useSelector(systemRbacRolesSelector);

  const onLocalSubmit = (data: FormValues) => {
    const preparedFilterData = {
      enterprise: data.enterprise.length ?
        data.enterprise.map((ent: SimpleSelectOptionType<number>) => ent.value) :
        undefined,
      unit: data.unit.length ? data.unit.map((unit: SimpleSelectOptionType<number>) => unit.value) : undefined,
      name: data.name.length ? data.name : undefined,
      phone: data.phone.length ? data.phone : undefined,
      role: data.roles.length ? data.roles.map((r: SimpleSelectOptionType<string>) => r.value) : undefined,
      is_active: data.isActive ? data.isActive.value : undefined,
    };

    if (hasUnemptyValues(preparedFilterData)) {
      onSubmit(preparedFilterData);
    }
  };

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const unitsOptions = convertModelToOptions(units);
  const enterprisesOptions = convertModelToOptions(enterprises);
  const rolesOptions = roles.data.map((r) => ({ label: r.description, value: r.name }));

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="enterprise"
            errors={errors}
            options={enterprisesOptions}
            value={watch('enterprise')}
            label={t('common.enterprise')}
            multiple
            allowEmptyValue
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="unit"
            errors={errors}
            options={unitsOptions}
            value={watch('unit')}
            label={t('common.unit')}
            multiple
            allowEmptyValue
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('common.fullname')}
            name="name"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('common.phone')}
            name="phone"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="isActive"
            errors={errors}
            options={showActiveUsersOptions}
            value={watch('isActive')}
            label={t('common.showActiveUsers')}
            allowEmptyValue
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="roles"
            errors={errors}
            options={rolesOptions}
            value={watch('roles')}
            label={t('common.roles')}
            multiple
            allowEmptyValue
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
