import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { systemUnitByIdSelector } from './selectors';
import { ISystemUnitModel, SystemUnitModel } from '../../../../models/References/UnitsModel';
import { getSystemUnit } from '../../../api/references/unit';
import { SystemUnitItemPageComponent } from '../../../components/References/Units/SystemUnitItemPage';

export const SystemUnitItemPage = () => {
  const { id } = useParams<ParamTypes>();

  const [isLoading, setLoading] = useState(true);
  const [item, setItem] = useState<ISystemUnitModel>(new SystemUnitModel());
  const founditem: ISystemUnitModel | undefined = useSelector(systemUnitByIdSelector(id ? +id : 0));

  useEffect(() => {
    if (founditem) {
      setLoading(false);
      setItem(founditem);
    } else if (!isNaN(id)) {
      setItem(new SystemUnitModel());
      setLoading(false);
    } else if (id) {
      getSystemUnit(+id)
        .then((modelOrError) => {
          if (modelOrError instanceof Error) {
            toast.error('Ошибка загрузки данных');
            setLoading(false);
          } else {
            setItem(modelOrError);
            setLoading(false);
          }
        });
    } else {
      setItem(new SystemUnitModel({}));
      setLoading(false);
    }
  }, [founditem, id]);

  return <SystemUnitItemPageComponent item={item} isLoading={isLoading} />;
};
