import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { DefaultFilterType } from '../common';

export interface UnitsFilterType extends DefaultFilterType {
  sort?: string;
  name?: string;
  enterprise_id?: number;
}

export interface ISystemUnitModel {
  id: number;
  name: string;
  enterprise: {
    id: number;
    name: string;
  };
}

export class SystemUnitModel implements ISystemUnitModel {
  id: number;
  name: string;
  enterprise: {
    id: number;
    name: string;
  };

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
    this.enterprise = params.enterprise;
  }
}


export interface ISystemUnitsModel extends IWithPages {
  data: ISystemUnitModel[];
}

export class SystemUnitsModel extends WithPagesModel implements ISystemUnitsModel {
  data: ISystemUnitModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemUnitModel);
  }
}
