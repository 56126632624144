import { createSelector } from 'reselect';
import { RootReducer } from '../../store/reducers';
import { storeName } from './reducer';
import { returnParams } from '../../store/selectors';

const getBusStopTimetable = (state: RootReducer) => state[storeName].busStopTimetable;
const getBusStopTimetableIsLoading = (state: RootReducer) => state[storeName].busStopTimetableIsLoading;

export const getBusStopTimetableSelector = createSelector([getBusStopTimetable], returnParams);
export const getBusStopTimetableIsLoadingSelector = createSelector([getBusStopTimetableIsLoading], returnParams);
