import { DepartmentFilterType, DepartmentModel, IDepartmentModel } from '../models/References/DepartmentModel';
import { baseApiFn } from '../utils/baseApiFn';
import { httpGet } from '../utils/http';
import {
  IJobDescriptionsModel,
  JobDescriptionsModel,
  JobDescriptionsFilterType,
} from '../models/JobDescription/JobDescription';
import { EnterprisesFilterType } from '../models/References/EnterprisesModel';
import { ISystemUnitsModel, SystemUnitsModel, UnitsFilterType } from '../models/References/UnitsModel';
import { EnterpriseListModel, IEnterpriseListModel } from '../models/Enterprises/EnterpriseModel';

/* Подучить список департаментов */
export const getDepartmentList = async (filter?: DepartmentFilterType) => baseApiFn<IDepartmentModel>(async () => {
  const response = await httpGet('/public/references/department', filter);
  return new DepartmentModel(response);
});

/* Получить список должностных инструкций */
export const getJobDescriptions = async (filter?: JobDescriptionsFilterType) => baseApiFn<IJobDescriptionsModel>(
  async () => {
    const response = await httpGet('/public/job-description', filter);
    return new JobDescriptionsModel(response);
  },
);

/* Получить список предприятий */
export const getEnterprisesApi = async (filter?: EnterprisesFilterType) => baseApiFn<IEnterpriseListModel>(
  async () => {
    const response = await httpGet('/public/references/enterprise', filter);
    return new EnterpriseListModel(response);
  },
);

/* Получить список подразделений */
export const getUnitsApi = async (filter?: UnitsFilterType) => baseApiFn<ISystemUnitsModel>(
  async () => {
    const response = await httpGet('/public/references/unit', filter);
    return new SystemUnitsModel(response);
  },
);
