import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IPollsFilterType, IPollsModel } from '../../models/Polls/PollsModel';
import { InfiniteScrollbar } from '../common/InfiniteScrollbar';
import { CDate } from '../../utils/CDate';
import { PollsFilter } from './PollsFilter';


type Props = {
  getData: (filter?: IPollsFilterType, append?: boolean) => void;
  hasMoreData: boolean;
  model: IPollsModel;
  modelLoading: boolean;
}

export const PollsComponent = ({
  getData, hasMoreData, model, modelLoading,
}: Props) => {
  const [filterData, setFilterData] = useState<IPollsFilterType>({});

  const { t } = useTranslation();

  /** подгрузка данных при скролле */
  const onLoadMoreData = (page: number) => {
    getData({
      ...filterData,
      page,
    }, true);
  };

  /** запросить начальные данные и запрашивать при изменении фильтра */
  useEffect(() => getData(filterData), [getData, filterData]);

  const onFilterChanged = (data: IPollsFilterType) => setFilterData(data);

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/" />{t('polls.all')}
      </div>

      <div className="box polls-list">
        <InfiniteScrollbar
          currentPage={model.page}
          hasMoreData={hasMoreData && !modelLoading}
          onLoadData={onLoadMoreData}
        >
          <section className="form form-with-btn">
            <PollsFilter onChange={onFilterChanged} filterData={filterData} />

            {model.data.length === 0 && <div className="text-center">Нет данных</div>}
            {model.data.map((item) => (
              <Link key={item.id} className="list-element" to={`/polls/${item.id}`}>
                <div className="list-element_content">
                  <div className="line title">{item.title}</div>
                  <div className="line info">
                    <div className="item">
                      {CDate.format(item.created_at, 'dd.MM.yy/HH:mm')}
                    </div>
                    <div className={`item status ${item.is_passed ? 'complete' : 'new'}`}>
                      {item.is_passed ? t('polls.passed') : t('polls.not_passed')}
                    </div>
                  </div>
                </div>
                <div className="list-element_direction follow" />
              </Link>
            ))}
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};

PollsComponent.whyDidYouRender = true;
