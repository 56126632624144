import { CDate } from '../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../config/system';


export interface IPollModel {
  readonly id: number;
  readonly title: string;
  readonly is_critical: boolean;
  readonly is_anonymous: boolean;
  readonly status: number;
  readonly created_at: Date;
  readonly ended_at: Date | null;
  readonly enterprises: string[];
  readonly questions: {
    id: number;
    title: string;
    type: number;
    answers: null | { id: number; value: string }[];
  }[];
}

export class PollModel implements IPollModel {
  readonly created_at: Date;
  readonly ended_at: Date | null;
  readonly enterprises: string[];
  readonly id: number;
  readonly is_anonymous: boolean;
  readonly is_critical: boolean;
  readonly questions: {
    id: number;
    title: string;
    type: number;
    answers: null | { id: number; value: string }[];
  }[];
  readonly status: number;
  readonly title: string;

  constructor(params: any = {}) {
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.ended_at = params.ended_at ? CDate.parse(params.ended_at, BACKEND_DATE_FORMAT) : null;
    this.enterprises = Array.isArray(params.enterprises) ?
      params.enterprises.map((item: { id: number; name: string }) => item.id.toString()) :
      [];
    this.id = params.id;
    this.is_anonymous = params.is_anonymous || false;
    this.is_critical = params.is_critical || false;
    this.questions = Array.isArray(params.questions) ? params.questions.map(
      (item: {
        id: number;
        title: string;
        type: number;
        answers: null | { id: number; value: string }[];
      }) => ({
        id: item.id,
        type: item.type,
        title: item.title,
        answers: item.answers,
      }),
    ) : [];
    this.status = params.status;
    this.title = params.title || '';
  }
}
