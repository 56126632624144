import {
  httpGet,
} from 'utils/http';
import { ISystemSportModel, SystemSportModel } from 'models/References/SportModel';
import { baseApiFn } from 'utils/baseApiFn';

export const getSystemSport = async (id: number) => baseApiFn<ISystemSportModel>(async () => {
  const response = await httpGet(`system/references/sport/${id}`);
  return new SystemSportModel(response);
});
