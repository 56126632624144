import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'reactstrap';

import { UiFileUploaderSystem } from 'components/common/FileUploader';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import { SystemPartnerCategoryFormFilesModel } from 'models/Partners/SystemPartnerCategoryModel';
import { ISystemPartnersCategoryListItemModel } from 'models/Partners/SystemPartnerCategoryListModel';


type Props = {
  model: ISystemPartnersCategoryListItemModel
  canUpdate: boolean;
  onSave: (model: SystemPartnerCategoryFormFilesModel, isNew: boolean) => void;
}

type FormValues = {
  name: string;
  priority: number;
  logo: File;
}

export const SystemPartnersCategoryFormComponent = ({
  model,
  canUpdate,
  onSave,
}: Props) => {
  const { t } = useTranslation();

  const createMode = model.isNew;

  const {
    register, handleSubmit, errors, formState: { isSubmitting, dirtyFields, isValid }, setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
      priority: model.priority,
    },
  });

  /** регистрация полей, управляемых вручную */
  useEffect(() => register('logo'), [register]);

  const onSubmit = (data: FormValues) => onSave(data, model.isNew);

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
      showButtons={canUpdate}
    >
      <h3>
        {t(`system.partners.category.form.${createMode ? 'create' : 'update'}`)}
      </h3>

      <UiSystemInput
        name="name"
        errors={errors}
        register={register}
        label={t('system.partners.category.form.name')}
        required
      />

      <FormGroup>
        <strong>
          {t('system.partners.category.form.logo')}
        </strong>
        <UiFileUploaderSystem
          initFileTypes={model.logo ? [model.logo] : []}
          onChange={(files: File[]) => setValue('logo', files[0])}
        />
      </FormGroup>

      <UiSystemInput
        name="priority"
        errors={errors}
        register={register}
        label={t('system.partners.category.form.priority')}
        required
        type="number"
      />
    </UiSystemForm>
  );
};
