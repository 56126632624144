import { all } from 'redux-saga/effects';
import { authSagas } from '../containers/Auth/sagas';
import { newsSagas } from '../containers/News/sagas';
import { enterprisesSagas } from '../containers/Enterprises/sagas';
import { currentUserSagas } from './currentUser/sagas';
import { busStopsSaga } from '../containers/BusStops/sagas';
import { systemPollSagas } from '../systemModule/containers/Polls/sagas';
import { busStopTimetableSaga } from '../containers/BusStopTimetable/sagas';
import { referencesSagas } from './references/sagas';
import { eventSagas } from '../containers/Events/sagas';
import { notificationsSagas } from '../containers/Notifications/sagas';
import { globalSagas } from './globalSagas';
import { pollsSagas } from '../containers/Polls/sagas';
import { bonusProgramSagas } from '../containers/BonusProgram/sagas';

export function* rootSagas() {
  yield all([
    authSagas(),
    currentUserSagas(),
    enterprisesSagas(),
    newsSagas(),
    busStopsSaga(),
    systemPollSagas(),
    systemPollSagas(),
    busStopTimetableSaga(),
    referencesSagas(),
    eventSagas(),
    notificationsSagas(),
    pollsSagas(),
    bonusProgramSagas(),
    globalSagas(),
  ]);
}
