import { AnyAction } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { baseSaga } from 'store/baseSaga';
import { httpGet } from 'utils/http';
import { BonusProgramEventHistoryListModel } from 'models/BonusProgram/EventHistory/BonusProgramEventHistoryModel';
import { GET_EVENT_HISTORY_LIST_START } from 'containers/BonusProgram/constants';
import {
  getBonusProgramEventHistoryListFailAction,
  getBonusProgramEventHistoryListOkAction,
} from 'containers/BonusProgram/actions';

function* getBonusProgramEventHistoryList({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/bonus-program/event-history', filter);

    yield put(getBonusProgramEventHistoryListOkAction(new BonusProgramEventHistoryListModel(response)));
  }, getBonusProgramEventHistoryListFailAction);
}

export function* bonusProgramSagas() {
  yield takeLatest(GET_EVENT_HISTORY_LIST_START, getBonusProgramEventHistoryList);
}
