import { baseApiFn } from 'utils/baseApiFn';
import { httpGet, httpPut } from 'utils/http';
import { IPageBonusProgramModel, PageBonusProgramModel } from 'models/References/PageBonusProgramModel';


/** Получить текст бонусной программы */
export const getSystemBonusProgramText = async () => baseApiFn<IPageBonusProgramModel>(async () => {
  const response = await httpGet('/system/references/page/bonus-program');
  return new PageBonusProgramModel(response);
});

/** Сохранить текст бонусной программы */
export const saveSystemBonusProgramText = async (
  data: IPageBonusProgramModel,
) => baseApiFn<true>(httpPut('/system/references/page/bonus-program', data));
