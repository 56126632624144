export const SYSTEM_GET_NEWS_CATEGORY_OK = '[SYSTEM GLOBAL] get news category [ok]';
export const SYSTEM_GET_NEWS_CATEGORY_FAIL = '[SYSTEM GLOBAL] get news category [fail]';

export const SYSTEM_GET_ENTERPRISES_OK = '[SYSTEM GLOBAL] get enterprises reference [ok]';
export const SYSTEM_GET_ENTERPRISES_FAIL = '[SYSTEM GLOBAL] get enterprises reference [fail]';

export const SYSTEM_GET_TAGS_OK = '[SYSTEM GLOBAL] get tags [ok]';
export const SYSTEM_GET_TAGS_FAIL = '[SYSTEM GLOBAL] get tags [fail]';

export const SYSTEM_GLOBAL_GET_UNITS_OK = '[SYSTEM GLOBAL] get units reference [ok]';
export const SYSTEM_GLOBAL_GET_UNITS_FAIL = '[SYSTEM GLOBAL] get units reference [fail]';

export const SYSTEM_GLOBAL_GET_DEPARTMENTS_OK = '[SYSTEM GLOBAL] get departments reference [ok]';
export const SYSTEM_GLOBAL_GET_DEPARTMENTS_FAIL = '[SYSTEM GLOBAL] get departments reference [fail]';

export const SYSTEM_GET_PERMISSIONS_OK = '[SYSTEM GLOBAL] get rbac permissions [ok]';
export const SYSTEM_GET_PERMISSIONS_FAIL = '[SYSTEM GLOBAL] get rbac permissions [fail]';


export const SYSTEM_GET_ROLES_START = '[SYSTEM GLOBAL] get roles [start]';
export const SYSTEM_GET_ROLES_OK = '[SYSTEM GLOBAL] get roles [ok]';
export const SYSTEM_GET_ROLES_FAIL = '[SYSTEM GLOBAL] get roles [fail]';


export const SYSTEM_POLLS_GET_QUESTION_TYPES_OK = '[SYSTEM GLOBAL] get polls question types list [ok]';
export const SYSTEM_POLLS_GET_QUESTION_TYPES_FAIL = '[SYSTEM GLOBAL] get polls question types list [fail]';

export const SYSTEM_POLLS_GET_STATUSES_OK = '[SYSTEM GLOBAL] get polls statuses list [ok]';
export const SYSTEM_POLLS_GET_STATUSES_FAIL = '[SYSTEM GLOBAL] get polls statuses list [fail]';


export const SYSTEM_GLOBAL_DATA_LOADED = '[SYSTEM GLOBAL] all data [loaded]';
