import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';


const getEvents = (state: RootReducer) => state.system[storeName].events;
const getEventsIsLoading = (state: RootReducer) => state.system[storeName].eventsIsLoading;


export const systemEventsSelector = createSelector([getEvents], returnParams);
export const systemEventsIsLoadingSelector = createSelector([getEventsIsLoading], returnParams);
