import {
  httpDelete, httpGet, httpPost, httpPut,
} from '../../utils/http';
import { ISystemPollModel, ISystemPollFormModel, SystemPollModel } from '../../models/Polls/SystemPollModel';
import { baseApiFn } from '../../utils/baseApiFn';
import { maxValueToGetAllDataWithPagination } from '../../config/system';
import { IPollUnvoteUsersModel, PollUnvoteUsersModel } from '../../models/Polls/PollUnvoteUsersModel';


/** создание опроса/анкеты */
export const createSystemPoll = async (model: ISystemPollFormModel) => baseApiFn<true>(httpPost('system/poll', model));

/** получить опрос/анкету */
export const getSystemPollById = async (id: number) => baseApiFn<ISystemPollModel>(async () => {
  const response = await httpGet(`system/poll/${id}`);
  return new SystemPollModel(response);
});

/** редактирование опроса/анкеты */
export const updateSystemPoll = async (id: number, model: ISystemPollFormModel) => baseApiFn<true>(
  httpPut(`system/poll/${id}`, model),
);

/** удаление опроса/анкеты */
export const deleteSystemPoll = async (id: number) => baseApiFn<true>(httpDelete(`/system/poll/${id}`));

/** остановка опроса/анкеты */
export const stopSystemPoll = async (id: number) => baseApiFn<true>(httpPut(`/system/poll/stop/${id}`));

/** возобновление опроса/анкеты */
export const resumeSystemPoll = async (id: number) => baseApiFn<true>(httpPut(`/system/poll/activate/${id}`));

/** завершение опроса/анкеты */
export const finishSystemPoll = async (id: number) => baseApiFn<true>(httpPut(`/system/poll/finish/${id}`));

/** Список сотрудников для внесения оффлайн ответов */
export const getSystemPollUnvoteUsers = async (id: number) => baseApiFn<IPollUnvoteUsersModel>(async () => {
  const response = await httpGet('/system/poll/offline-answers-users-list', {
    poll_id: id,
    'per-page': maxValueToGetAllDataWithPagination,
  });
  return new PollUnvoteUsersModel(response);
});
