export const REFERENCES_GET_SPORT_LIST_START = '[REFERENCES] get sport list [START]';
export const REFERENCES_GET_SPORT_LIST_OK = '[REFERENCES] get sport list [OK]';
export const REFERENCES_GET_SPORT_LIST_FAIL = '[REFERENCES] get sport list [FAIL]';

export const REFERENCES_GET_INSURANCES_START = '[REFERENCES] get insurances [START]';
export const REFERENCES_GET_INSURANCES_OK = '[REFERENCES] get insurances [OK]';
export const REFERENCES_GET_INSURANCES_FAIL = '[REFERENCES] get insurances [FAIL]';

export const REFERENCES_GET_EDUCATION_TYPES_START = '[REFERENCES] get education types [start]';
export const REFERENCES_GET_EDUCATION_TYPES_OK = '[REFERENCES] get education types [ok]';
export const REFERENCES_GET_EDUCATION_TYPES_FAIL = '[REFERENCES] get education types [fail]';
