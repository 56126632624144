import { WithPagesModel } from 'models/WithPagesModel';
import {
  ISystemBonusProgramPrizeItemModel,
  ISystemBonusProgramPrizeListModel,
} from 'models/BonusProgram/Prize/interfaces';

export class SystemBonusProgramPrizeItemModel implements ISystemBonusProgramPrizeItemModel {
  id: number;
  title: string;
  description: string;
  priceSdsCoin: number;
  priceReal: number;
  priority: number;
  isActive: boolean;

  constructor(params: any = {}) {
    this.id = params.id;
    this.title = params.title;
    this.description = params.description;
    this.priceSdsCoin = params.price_sdscoin;
    this.priceReal = params.price_real;
    this.priority = params.priority;
    this.isActive = params.is_active;
  }
}

export class SystemBonusProgramPrizeListModel extends WithPagesModel implements ISystemBonusProgramPrizeListModel {
  data: ISystemBonusProgramPrizeItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemBonusProgramPrizeItemModel);
  }
}
