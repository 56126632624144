import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { IPrivilegeList, PrivilegeListFilterType } from '../../../models/Privilege/interfaces';
import { ListElementLink } from '../../common/ListElement/ListElementLink';
import { InfiniteScrollbar } from '../../common/InfiniteScrollbar';
import { Loader } from '../../common/UI/Loaders';
import { UiInputSearch } from '../../common/UI/InputSearch';


type Props = {
  data: IPrivilegeList;
  hasMoreData: boolean;
  isLoading: boolean;
  getData: (filter: PrivilegeListFilterType) => void;
  currentUserEnterpriseIds: number[];
}

type FormValues = {
  name: string;
}

export const ApplicationPrivilegeListComponent = ({
  data, hasMoreData, isLoading, getData, currentUserEnterpriseIds,
}: Props) => {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });

  const onSearch = useCallback(({ name }: FormValues) => {
    getData({ name, 'per-page': data.limit });
  }, [getData, data.limit]);

  /** начальная загрузка данных */
  useEffect(() => {
    getData({ 'per-page': data.limit });
  }, [getData, data.limit]);

  /** подгрузка данных при скролле */
  const onLoadMoreData = (page: number) => {
    getData({
      page,
      'per-page': data.limit,
    });
  };

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/applications" />
        {t('privilege.list.head')}
      </div>

      <div className="box applications">
        <InfiniteScrollbar
          hasMoreData={hasMoreData && !isLoading}
          onLoadData={onLoadMoreData}
          currentPage={data.page}
        >
          <section className="form">
            <div className="d-flex">
              <UiInputSearch
                register={register}
                name="name"
                handleSubmit={handleSubmit(onSearch)}
              />
            </div>

            <h4>{t('privilege.list.title')}</h4>
            {/** вывод льготы для каждого предприятия */}
            {data.data.map((item) => item.userEnterprises(currentUserEnterpriseIds).map((enterprise) => (
              <ListElementLink
                key={`${item.id}_${enterprise.id}`}
                text={item.name}
                to={`/applications/privilege/${item.id}/${enterprise.id}`}
                isFollow
              >
                <div className="item">{enterprise.name}</div>
              </ListElementLink>
            )))}

            {(!isLoading && data.data.length === 0) && <h3 className="text-center">{t('privilege.list.empty')}</h3>}
            {isLoading && <Loader className="mt-3" />}
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};
