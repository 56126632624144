import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleErrors } from 'utils/errors';
import { dislikeNews, likeNews } from 'api/news';
import { NewsComponent } from 'components/News/News';
import { INewsModel, NewsFilterType } from 'models/News/NewsModel';
import { INewsCategoryItemModel } from 'models/News/NewsCategoryModel';
import { CurrentUserModel } from 'models/User/CurrentUserModel';
import { getCurrentUserIsLoadingSelector, getCurrentUserSelector } from 'store/currentUser/selectors';
import { maxValueToGetAllDataWithPagination } from 'config/system';
import { Loader } from 'components/common/UI/Loaders';
import {
  getCategoriesIsLoadingSelector,
  getCategoriesSelector,
  getNewsIsLoadingSelector,
  getNewsSelector,
} from './selectors';
import { newsGetCategoriesStartAction, newsGetListStartAction } from './actions';
import { getEnterprisesListAction } from '../Enterprises/actions';
import { getEnterprisesIsLoadingSelector, getEnterprisesSelector } from '../Enterprises/selectors';
import { getItem } from '../../utils/localStorage';
import { useClonedEnterprises } from '../../utils/hooks/useClonedEnterprises';

const localStorageKeyCommonPart = 'newsFilterData_UserId';

export const News = () => {
  const dispatch = useDispatch();

  const [clearLoad, setClearLoad] = useState(false);

  useEffect(() => {
    dispatch(getEnterprisesListAction());
  }, [dispatch]);

  /** сами новости. список */
  const news: INewsModel = useSelector(getNewsSelector);
  const newsIsLoading: boolean = useSelector(getNewsIsLoadingSelector);

  /** категории новостей. для фильтра */
  const categories: INewsCategoryItemModel[] = useSelector(getCategoriesSelector);
  const categoriesIsLoading: boolean = useSelector(getCategoriesIsLoadingSelector);

  /** предприятия пользователя. для фильтра */
  const { enterprises: userEnterprises }: CurrentUserModel = useSelector(getCurrentUserSelector);
  const userEnterprisesIsLoading: boolean = useSelector(getCurrentUserIsLoadingSelector);

  /** все предприятия */
  const allEnterprises = useSelector(getEnterprisesSelector);
  const allEnterprisesLoading = useSelector(getEnterprisesIsLoadingSelector);

  const { id: currentUserId }: CurrentUserModel = useSelector(getCurrentUserSelector);
  const localStorageKey = `${localStorageKeyCommonPart}${currentUserId}`;

  /**
   * загрузка новостей
   * @param filter - фильтр новостей, не обязателен
   * @param append - объединить ли новости с уже имеющимися (true при бескочном скролле, false при сбросе фильтров и тд)
   */
  const getData = useCallback((filter?: NewsFilterType, append = false) => {
    const currentFilter = { ...filter, ...JSON.parse(getItem(localStorageKey)) };
    dispatch(newsGetListStartAction(append, currentFilter));

    if (!append) {
      setClearLoad(true);
    }
  }, [dispatch, localStorageKey]);

  /** отслеживать полную загрузку при загрузке без подгрузки(чистая загрузка) */
  useEffect(() => {
    if (!newsIsLoading && clearLoad) {
      setClearLoad(false);
    }
  }, [newsIsLoading, setClearLoad, clearLoad]);

  /** если кол-во страниц больше текущей, то есть еще данные */
  const hasMoreData: boolean = news.amountOfPages > news.page;

  /** получить первую порцию новостей отсюда */
  useEffect(() => {
    /** нужны все, без постраничной разбивки */
    dispatch(newsGetCategoriesStartAction({ 'per-page': maxValueToGetAllDataWithPagination, sort: 'id' }));
  }, [dispatch, getData]);

  const clonedEnterprises = useClonedEnterprises(allEnterprises);

  const [changeLikedIsLoading, setChangeLikedIsLoading] = useState(false);
  const changeLiked = useCallback(async (id: number, liked: boolean) => {
    if (changeLikedIsLoading) {
      return;
    }
    setChangeLikedIsLoading(true);
    const changeLikedFn = liked ? dislikeNews : likeNews;
    handleErrors(
      await changeLikedFn(id),
      'save',
      () => {
        const likedNewsIndex = news.data.findIndex((item) => item.id === id);

        if (likedNewsIndex > -1) {
          const oldCount = news.data[likedNewsIndex].like_count;
          news.data[likedNewsIndex].is_liked = !liked;
          news.data[likedNewsIndex].like_count = liked ? oldCount - 1 : oldCount + 1;
        }
      },
      () => setChangeLikedIsLoading(false),
    );
  }, [news.data, setChangeLikedIsLoading, changeLikedIsLoading]);

  if (categoriesIsLoading || userEnterprisesIsLoading || allEnterprisesLoading) {
    return <Loader />;
  }
  return (
    <NewsComponent
      getData={getData}
      hasMoreData={hasMoreData}
      news={news}
      newsIsLoading={newsIsLoading}
      categories={categories}
      userEnterprises={userEnterprises}
      allEnterprises={clonedEnterprises}
      clearLoad={clearLoad}
      changeLiked={changeLiked}
      localStorageKey={localStorageKey}
    />
  );
};
