import { UserAccessEnum } from 'models/User/CurrentUserModel';
import { getSystemPath } from 'systemModule/utils/getSystemUrl';


type SystemNavType = {
  name: string;
  url: string;
  icon: string;
  right?: string | UserAccessEnum;
  children?: SystemNavType[];
  variant?: string;
};


const settings = [
  {
    name: 'Настройки заявок',
    url: getSystemPath('/construct-application/type'),
    right: UserAccessEnum.rApplication,
    icon: 'fa fa-paperclip',
  },
  {
    name: 'Список ролей',
    url: getSystemPath('/roles'),
    right: UserAccessEnum.crudRbac,
    icon: 'fa fa-universal-access',
  },
  {
    name: 'Принудительное оповещение',
    url: getSystemPath('/notifications'),
    icon: 'fa fa-bell',
  },
  {
    name: 'feedback.menu',
    url: getSystemPath('/feedback-responsible'),
    icon: 'fa fa-users',
  },
  {
    name: 'notifications.templates.menu',
    url: getSystemPath('/notifications-templates'),
    right: UserAccessEnum.rNotifyTemplate,
    icon: 'fa fa-commenting-o',
  },
];

const systemMenu = [
  {
    name: 'Новости',
    url: getSystemPath('/news'),
    right: UserAccessEnum.rNews,
    icon: 'fa fa-newspaper-o',
  },
  {
    name: 'Список пользователей',
    url: getSystemPath('/users'),
    right: UserAccessEnum.usersView,
    icon: 'fa fa-users',
  },
  {
    name: 'system.transport.menu',
    url: getSystemPath('/transport'),
    right: UserAccessEnum.rTransport,
    icon: 'fa fa-bus',
  },
  {
    name: 'event.menu',
    url: getSystemPath('/events'),
    right: UserAccessEnum.rEvents,
    icon: 'fa fa-calendar-o',
  },
  {
    name: 'applications.assign.menu',
    url: getSystemPath('/assign-applications'),
    icon: 'fa fa-check-square-o',
  },
  {
    name: 'applications.assign.all.menu',
    url: getSystemPath('/all-applications'),
    right: UserAccessEnum.adminApplication,
    icon: 'fa fa-check-square-o',
  },
  {
    name: 'medical.menu',
    url: getSystemPath('/medical'),
    right: UserAccessEnum.rMedical,
    icon: 'fa fa-hospital-o',
  },
  {
    name: 'education.menu',
    url: getSystemPath('/education'),
    right: UserAccessEnum.rEducation,
    icon: 'fa fa-graduation-cap',
  },
  {
    name: 'privilege.menu',
    url: getSystemPath('/priveleges'),
    right: UserAccessEnum.cPrivileges,
    icon: 'fa fa-id-card',
  },
  {
    name: 'Реферальная программа',
    url: getSystemPath('/referral-program'),
    icon: 'fa fa-users',
    right: UserAccessEnum.rReferral,
  },
  {
    name: 'Бонусная программа',
    url: getSystemPath('/bonus-program'),
    icon: 'fa fa-gift',
    children: [
      {
        name: 'Список пользователей',
        url: getSystemPath('/bonus-program/users'),
        icon: 'fa fa-users',
        right: UserAccessEnum.rBonusProgramPrize,
      },
      {
        name: 'Список призов',
        url: getSystemPath('/bonus-program/prizes'),
        icon: 'fa fa-trophy',
        right: UserAccessEnum.rBonusProgramPrize,
      },
      {
        name: 'Начисления',
        url: getSystemPath('/bonus-program/events'),
        icon: 'fa fa-calculator',
        right: UserAccessEnum.rBonusProgramEvent,
      },
    ],
  },
  {
    name: 'Справочники системы',
    url: getSystemPath('/references'),
    icon: 'fa fa-list-alt',
    children: [
      {
        name: 'Категории заявок',
        url: getSystemPath('/construct-application/category'),
        icon: 'fa fa-list-alt',
        right: UserAccessEnum.rApplication,
      },
      {
        name: 'Шаблоны документов заявок',
        url: getSystemPath('/construct-application/template'),
        icon: 'fa fa-list-alt',
        right: UserAccessEnum.rApplication,
      },
      {
        name: 'Страховые программы',
        url: getSystemPath('/references/insurance'),
        icon: 'fa fa-list-alt',
        right: UserAccessEnum.rReferenceInsurance,
      },
      {
        name: 'Остановки общественного транспорта',
        url: getSystemPath('/references/busstop'),
        icon: 'fa fa-list-alt',
        right: UserAccessEnum.rReferenceBusStop,
      },
      {
        name: 'Предприятия',
        url: getSystemPath('/references/enterprise'),
        icon: 'fa fa-list-alt',
      },
      {
        name: 'Подразделения',
        url: getSystemPath('/references/unit'),
        icon: 'fa fa-list-alt',
      },
      {
        name: 'Виды спорта',
        url: getSystemPath('/references/sport'),
        icon: 'fa fa-list-alt',
      },
      {
        name: 'Рубрики новостей',
        url: getSystemPath('/references/newscategory'),
        icon: 'fa fa-list-alt',
        right: UserAccessEnum.rReferenceNewsCategory,
      },
      {
        name: 'Должностные инструкции',
        url: getSystemPath('/references/jobdescription'),
        icon: 'fa fa-list-alt',
      },
      {
        name: 'Пользовательское соглашение',
        url: getSystemPath('/references/page-agreement'),
        icon: 'fa fa-list-alt',
        right: UserAccessEnum.rPages,
      },
      {
        name: 'application.menu',
        url: getSystemPath('/references/application-statuses'),
        icon: 'fa fa-list-alt',
      },
      {
        name: 'Реферальная программа',
        url: getSystemPath('/references/referral-program'),
        icon: 'fa fa-list-alt',
        right: UserAccessEnum.rPages,
      },
      {
        name: 'Бонусная программа',
        url: getSystemPath('/references/bonus-program'),
        icon: 'fa fa-list-alt',
        right: UserAccessEnum.rPages,
      },
      {
        name: 'Теги бонусной программы',
        url: getSystemPath('/references/bonus-program-tags'),
        icon: 'fa fa-tags',
        right: UserAccessEnum.rBonusProgramTag,
      },
    ],
  },
  {
    name: 'reports.menu',
    url: getSystemPath('/reports'),
    right: UserAccessEnum.rReports,
    icon: 'fa fa-bars',
    children: [{
      name: 'reports.menu.application',
      url: getSystemPath('/reports/application'),
      icon: 'fa fa-bars',
    }, {
      name: 'reports.menu.insurance',
      url: getSystemPath('/reports/insurance'),
      icon: 'fa fa-bars',
    }, {
      name: 'reports.menu.polls',
      url: getSystemPath('/reports/polls'),
      icon: 'fa fa-bars',
    }, {
      name: 'reports.menu.sport',
      url: getSystemPath('/reports/sport'),
      icon: 'fa fa-bars',
    }, {
      name: 'reports.menu.housing',
      url: getSystemPath('/reports/housing'),
      icon: 'fa fa-bars',
    }],
  },
  {
    name: 'Настройки системы',
    url: getSystemPath('/notifications'),
    icon: 'fa fa-cog',
    children: settings,
  },
  {
    name: 'Анкеты и опросы',
    url: getSystemPath('/polls'),
    right: UserAccessEnum.crudPolls || UserAccessEnum.adminCrudPolls,
    icon: 'fa fa-sticky-note-o',
    children: [
      {
        name: 'polls.my_polls',
        url: getSystemPath('/polls/my'),
        icon: 'fa fa-sticky-note-o',
      },
      {
        name: 'polls.all_polls',
        url: getSystemPath('/polls/all'),
        icon: 'fa fa-sticky-note-o',
      },
    ],
  },
  {
    name: 'Партнеры',
    url: getSystemPath('/partners'),
    icon: 'fa fa-globe',
    right: UserAccessEnum.rPartnerCategory,
    children: [{
      name: 'system.partners.category',
      url: getSystemPath('/partners/category'),
      icon: 'fa fa-list-alt',
      right: UserAccessEnum.rPartnerCategory,
    }, {
      name: 'system.partners',
      url: getSystemPath('/partners'),
      icon: 'fa fa-list-alt',
      right: UserAccessEnum.rPartner,
    }],
  },
  {
    name: 'В публичную часть',
    url: '/',
    icon: 'fa fa-backward',
    variant: 'non-active',
  },
];

export const systemNav: SystemNavType[] = systemMenu;
