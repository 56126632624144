import { IPageAgreementModel, PageAgreementModel } from 'models/References/PageAgreement';
import { baseApiFn } from 'utils/baseApiFn';
import { httpGet } from 'utils/http';


/** получить настройки уведомлений */
export const getPageAgreement = async () => baseApiFn<IPageAgreementModel>(async () => {
  const response = await httpGet('/public/references/page/agreement');
  return new PageAgreementModel(response);
});
