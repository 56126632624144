import React from 'react';

import { IReportSport } from '../../../../models/Reports/interfaces';
import { CardBlock } from '../common/CardBlock';


type Props = {
  model: IReportSport;
}

export const SystemReportSportComponent = ({ model }: Props) => (
  <>
    {model.items.map((item) => (
      <React.Fragment key={item.id}>
        <CardBlock name={item.name} count={item.user_count} />
      </React.Fragment>
    ))}
  </>
);
