/** Код для получения этой настройки */
export const settlement_sheet_available_date = 'settlement_sheet_available_date';

export interface IEnterpriseSettingsModel {
  readonly id: number;
  readonly enterprise: {
    id: number,
    name: string
  };
  readonly name: string;
  readonly code: string;
  readonly type: string;
  readonly value: string;
  readonly created_at: string;
  readonly updated_at: string;
}

export class EnterpriseSettingsModel implements IEnterpriseSettingsModel {
  readonly id: number;
  readonly enterprise: {
    id: number,
    name: string
  };
  readonly name: string;
  readonly code: string;
  readonly type: string;
  readonly value: string;
  readonly created_at: string;
  readonly updated_at: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.enterprise = params.enterprise;
    this.name = params.name;
    this.code = params.code;
    this.type = params.type;
    this.value = params.value;
    this.created_at = params.created_at;
    this.updated_at = params.updated_at;
  }
}
