import { IReportHousingListItem } from './interfaces';


export class ReportHousingListItem implements IReportHousingListItem {
  readonly area: number;
  readonly fio: string;
  readonly from_sds: boolean;
  readonly id: number;
  readonly phone: string;

  constructor(params: any = {}) {
    this.area = params.area;
    this.fio = params.fio;
    this.from_sds = params.from_sds;
    this.id = params.id;
    this.phone = params.phone;
  }
}
