import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { DefaultFilterType } from '../common';


export type ISystemBusStopModelForm = {
  name: string;
}

export type BusStopsFilterType = DefaultFilterType & {
  sort?: string;
  name?: string;
}


export interface ISystemBusStopModel {
  id: number;
  name: string;
}

export class SystemBusStopModel implements ISystemBusStopModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}


export interface ISystemBusStopsModel extends IWithPages {
  data: ISystemBusStopModel[];
}

export class SystemBusStopsModel extends WithPagesModel implements ISystemBusStopsModel {
  data: ISystemBusStopModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemBusStopModel);
  }
}
