import { dataAction, dataErrorAction, dataSuccessAction } from '../../../../store/actions';
import {
  SYSTEM_REFERENCES_GET_ENTERPRISES_FAIL,
  SYSTEM_REFERENCES_GET_ENTERPRISES_OK,
  SYSTEM_REFERENCES_GET_ENTERPRISES_START,
} from './constants';
import { SystemEnterprisesModel } from '../../../../models/References/EnterprisesModel';

export const systemRefGetEnterprisesStartAction = (filter = {}) => dataAction(
  SYSTEM_REFERENCES_GET_ENTERPRISES_START,
)({ filter });
export const systemRefGetEnterprisesOkAction = (enterprises: SystemEnterprisesModel) => dataSuccessAction(
  SYSTEM_REFERENCES_GET_ENTERPRISES_OK,
)({ enterprises });
export const systemRefGetEnterprisesFailAction = dataErrorAction(SYSTEM_REFERENCES_GET_ENTERPRISES_FAIL);
