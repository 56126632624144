import { ISystemUserListModel, ISystemUserListModelItem } from './interfaces';
import { ISystemUnitModel } from '../References/UnitsModel';
import { IShortUser } from '../User/interfaces';
import { WithPagesModel } from '../WithPagesModel';

export class SystemUserListModelItem implements ISystemUserListModelItem {
  id: string;
  fio: string;
  phone: string;
  show_phone: boolean;
  isActive: boolean;
  isRegister: boolean;
  units: {
    unit: ISystemUnitModel;
    post: string | null;
    chief: IShortUser | null;
    dependents: IShortUser[];
  }[];
  roles: { description: string; name: string }[];
  sdsCoin: number;
  canCNotifyCreate: boolean;

  constructor(params: any = {}) {
    this.id = params.id;
    this.fio = params.fio;
    this.phone = params.phone;
    this.show_phone = params.show_phone;
    this.isActive = params.is_active;
    this.isRegister = params.is_register;
    this.units = params.units;
    this.roles = params.roles;
    this.sdsCoin = params.sdscoin;
    this.canCNotifyCreate = params.can_c_notify_create;
  }
}

export class SystemUserListModel extends WithPagesModel implements ISystemUserListModel {
  data: ISystemUserListModelItem[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemUserListModelItem);
  }
}
