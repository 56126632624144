import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { DefaultFilterType } from '../common';
import { ISystemUnitModel } from './UnitsModel';

export type PositionsFilterType = DefaultFilterType & {
  sort?: string;
  name?: string;
  enterprise_id?: string;
  unit_id?: string;
}

export interface IPositionsItemModel {
  id: number;
  name: string;
  unit: ISystemUnitModel;
}

export class PositionsItemModel implements IPositionsItemModel {
  id: number;
  name: string;
  unit: ISystemUnitModel;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
    this.unit = params.unit;
  }
}


export interface IPositionsModel extends IWithPages {
  data: IPositionsItemModel[];
}

export class PositionsModel extends WithPagesModel implements IPositionsModel {
  data: IPositionsItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, PositionsItemModel);
  }
}
