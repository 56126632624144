import React, { useEffect, useState } from 'react';
import {
  Button, Card, Col, Collapse, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { Link, useHistory } from 'react-router-dom';
import isNumber from 'lodash/isNumber';
import {
  IPollsQuestionTypesModel,
} from '../../../models/Polls/PollsQuestionTypesModel';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { convertModelToOptions, SelectOptionType } from '../../../utils/convertModelToOptions';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { UiSystemCheckbox } from '../../../components/common/UI/Checkbox';
import { UiDatePicker } from '../../../components/common/Dates/UiDatePicker';
import { CDate } from '../../../utils/CDate';
import { ISystemPollModel, ISystemPollFormModel } from '../../../models/Polls/SystemPollModel';
import { UiSystemForm } from '../../../components/common/UI/Form';
import { UiSelect } from '../../../components/common/UI/Select';
import { IPollsStatusModel, PollStatusEnum } from '../../../models/Polls/PollsStatusesModel';
import { IPollUnvoteUsersModel } from '../../../models/Polls/PollUnvoteUsersModel';
import { Loader } from '../../../components/common/UI/Loaders';
import { SystemPollAnswers } from './Answers';
import { SubmitLoaderButton } from '../../../components/common/SubmitLoaderButton';
import { PollActions } from './PollActions';


/** разбиение по ';', удаление пустых значений */
const prepareQuestionAnswers = (answers?: string): string[] | undefined => (
  answers ?
    answers
      .split(';')
      .map((item) => item.trim())
      .filter((item) => item.length) :
    undefined
);

/** проверка по типу ответа, нужно ли настроить варианты ответа */
const questionTypeNeedAnswers = (
  form: FormQuestionType[],
  index: number,
): boolean => get(form, `[${index}].type`, 16) <= 15;

/** пустой вопрос, при добавлении */
const defaultEmptyQuestion = (options: SelectOptionType[]): FormQuestionType => ({
  title: '',
  type: options[0].value,
  answers: '',
  answersInit: null,
});


type Props = {
  questionTypes: IPollsQuestionTypesModel;
  enterprises: IEnterpriseModel[];
  onSave: (id: number | null, model: ISystemPollFormModel) => void;
  pollStatuses: IPollsStatusModel[];
  model: ISystemPollModel;
  linkToExport: string;
  isCurrentuserAuthor: boolean;
  isCurrentuserAdmin: boolean;

  onLoadUnvoteUsers: () => void;
  unvoteUsers: IPollUnvoteUsersModel;
  unvoteUsersLoading: boolean;
  onOfflineVote: (user: { fio: string; id: number }) => void;
}

type FormQuestionType = {
  title: string;
  type: number; // rhf не умеет вложенные объекты хранить. поэтому простой тип а не {label: string; value: number}
  answers: string;
  answersInit: null | { id: number; value: string }[];
}

type FormValues = {
  title: string;
  enterprises: { label: string; value: number }[];
  is_critical: boolean;
  is_anonymous: boolean;
  ended_at: null | Date;
  questions: FormQuestionType[];

  offlineUserId: { label: string; value: number } | undefined;
}

export const SystemPollsFormComponent = ({
  questionTypes, enterprises, onSave, model, pollStatuses, linkToExport, isCurrentuserAuthor,
  onLoadUnvoteUsers, unvoteUsers, unvoteUsersLoading, onOfflineVote, isCurrentuserAdmin,
}: Props) => {
  const options = questionTypes.map((value) => ({
    label: value.value,
    value: value.key,
  }));
  const enterprisesOptions = convertModelToOptions(enterprises);

  const { t } = useTranslation();

  const history = useHistory();

  const {
    register, handleSubmit, watch, errors, setValue,
    formState: { isSubmitting, dirtyFields }, getValues, control, unregister,
  } = useForm<FormValues>({
    defaultValues: {
      title: model.title,
      enterprises: (() => {
        const existEnterprises = enterprisesOptions
          .filter((o) => model?.enterprises.map((ent) => +ent).includes(o.value));

        /** или выбранные в модели или все */
        return existEnterprises.length ? existEnterprises : enterprisesOptions;
      })(),
      is_critical: model.is_critical,
      is_anonymous: model.is_anonymous,
      ended_at: model.ended_at,
      questions: (() => {
        const existQuestions = model.questions.map((answer) => ({
          title: answer.title,
          type: answer.type || defaultEmptyQuestion(options).type,
          answers: answer.answers ? answer.answers.map((ans) => ans.value).join(';') : '',
          answersInit: answer.answers,
        }));

        return existQuestions.length ? existQuestions : [defaultEmptyQuestion(options)];
      })(),
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      onLoadUnvoteUsers();
    }
  }, [isOpen, onLoadUnvoteUsers]);
  const offlineUser = watch('offlineUserId');

  const { fields: dynamicQuestions, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const onSubmit = async (data: FormValues) => {
    const modelToSave: ISystemPollFormModel = {
      title: data.title,
      enterprises: data.enterprises.map((ent) => ent.value),
      is_critical: data.is_critical,
      is_anonymous: data.is_anonymous,
      ended_at: data.ended_at ? CDate.format(data.ended_at, 'dd.MM.yyyy') : undefined,
      questions: data.questions.map((question) => ({
        title: question.title,
        type: +question.type,
        answers: prepareQuestionAnswers(question.answers),
      })),
    };

    onSave(model?.id, modelToSave);
  };

  /** подписаться на questions, чтобы узнать, когда меняется значение в селекте */
  const watchQuestions = watch('questions');

  const canEdit = model.votedUsersAmount === 0 && model.status !== PollStatusEnum.finished;

  const toBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/system/polls/all');
    }
  };

  /** мин. дата окончания сбора ответов - завтра */
  const endedAtMinDate = new Date();
  endedAtMinDate.setDate(endedAtMinDate.getDate() + 1);

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      /** с динамическими инпутами не очень хорошо валидация формы дружит. поэтому кнопка активна всегда */
      disabledSubmit={false}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode
      useCustomButtons
    >
      <h3>{model.id ? 'Редактирование' : 'Создание'} опроса</h3>

      <UiSystemInput
        name="title"
        errors={errors}
        register={register}
        label={t('poll.title')}
        required
      />

      <UiSelect
        register={register}
        defaultValue={getValues('enterprises')}
        options={enterprisesOptions}
        name="enterprises"
        label="Предприятия"
        multiple
        required
        errors={errors}
        setValue={setValue}
      />

      <UiSystemCheckbox
        name="is_critical"
        register={register}
        label="Критичный опрос или анкета"
      />

      <UiSystemCheckbox
        name="is_anonymous"
        register={register}
        label="Анонимный опрос/анкета"
      />

      <UiDatePicker
        watch={watch}
        name="ended_at"
        setValue={setValue}
        register={register}
        minDate={endedAtMinDate}
        label={t('poll.ended_at')}
        placeholderText="Выберите дату"
        required
      />

      {model.id && (
        <>
          <p>
            <strong>{t('poll.status')}:</strong>&nbsp;
            {pollStatuses.find((pollStatus) => pollStatus.key === model.status)?.value || ''}
          </p>
          <p>
            <strong>{t('poll.voted.amount')}</strong>: {model.votedUsersAmount}
          </p>
          <p>
            <strong>{t('poll.author')}:</strong> {model.author.fio}
          </p>
          <p>
            <strong>{t('poll.created_at')}:</strong> {CDate.format(model.created_at, 'dd MMMM yyyy')}
          </p>

          {/** на пустые данные не стоит кидать, будет 404 */}
          {(model.status === PollStatusEnum.finished && model.votedUsersAmount > 0) && (
            <Button
              tag={Link}
              to={linkToExport}
              target="_blank"
              color="primary"
            >
              Выгрузка результатов опроса
            </Button>
          )}
          {/** статус Завершен и не анонимный и автор или админ */}
          {(model.status === PollStatusEnum.finished && !model.is_anonymous &&
            (isCurrentuserAuthor || isCurrentuserAdmin)) && (
            <Card body className="mt-3 w-25">
              <Button color="primary" onClick={() => setIsOpen(!isOpen)}>
                Внести оффлайн ответы
              </Button>
              <Collapse isOpen={isOpen}>
                {unvoteUsersLoading ? <Loader /> : (
                  <>
                    <UiSelect
                      className="mt-3"
                      register={register}
                      name="offlineUserId"
                      options={unvoteUsers.data.map((u) => ({ value: u.id, label: u.fio }))}
                      setValue={setValue}
                      allowEmptyValue={!isOpen}
                    />
                    <Button
                      className="w-100"
                      onClick={() => {
                        if (offlineUser?.value) {
                          onOfflineVote({ fio: offlineUser.label, id: offlineUser.value });
                        }
                      }}
                      color="primary"
                      disabled={!isNumber(offlineUser?.value)}
                    >
                      Перейти к голосованию
                    </Button>
                  </>
                )}
              </Collapse>
            </Card>
          )}
        </>
      )}

      <div className="mt-5">
        {dynamicQuestions.map((item, questionIndex) => (
          <Row key={item.id}>
            <Col xs={12} md={5} lg={5}>
              <Card body className="question-block mb-4">
                <UiSystemInput
                  name={`questions[${questionIndex}].title`}
                  value={item.title}
                  errors={errors}
                  register={register}
                  label="Название вопроса"
                  defaultValue={item.title}
                  required
                />

                {/** с UiSelect item.type скидывается в undefined... */}
                <FormGroup>
                  <Label htmlFor={item.id}>
                    Тип ответов на вопрос
                  </Label>
                  <Input
                    id={item.id}
                    type="select"
                    defaultValue={item.type}
                    innerRef={register()}
                    name={`questions[${questionIndex}].type`}
                  >
                    {options.map((option) => (
                      <option key={`${option.label}_${option.value}`} value={option.value}>{option.label}</option>
                    ))}
                  </Input>
                </FormGroup>

                {questionTypeNeedAnswers(watchQuestions, questionIndex) && (
                  <UiSystemInput
                    value={item.answers}
                    defaultValue={item.answers}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    name={`questions[${questionIndex}].answers`}
                    label="Ответы на вопрос через ';'"
                    required
                  />
                )}

                {(canEdit && dynamicQuestions.length > 1) && (
                  <Button type="button" color="danger" onClick={() => remove(questionIndex)}>
                    Удалить вопрос
                  </Button>
                )}
              </Card>
            </Col>

            {/** ответы опроса. только если ответы есть */}
            {(model.id && model.votedUsersAmount > 0 && model.results) && (
              <SystemPollAnswers
                results={model.results}
                is_anonymous={model.is_anonymous}
                answerTitle={item.title}
                questionIndex={questionIndex}
              />
            )}
          </Row>
        ))}
      </div>

      {(canEdit && dynamicQuestions.length < 10) && (
        <Button
          type="button"
          color="success"
          onClick={() => append(defaultEmptyQuestion(options))}
        >
          Добавить вопрос
        </Button>
      )}
      <Row>
        <Col xs="12" className="d-flex justify-content-end space-between-items">
          <Button type="button" color="secondary" onClick={toBack}>
            {t('common.cancel')}
          </Button>
          {model.id && <PollActions model={model} voterCount={model.votedUsersAmount} />}
          {canEdit && (
            <SubmitLoaderButton loading={isSubmitting} />
          )}
        </Col>
      </Row>
    </UiSystemForm>
  );
};
