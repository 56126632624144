import React from 'react';

import { PartnersCommonListComponent, PartnersItemInfoComponent } from 'components/Partners/common';
import { IPartnersListModel } from 'models/Partners/PartnersListModel';


type Props = {
  model: IPartnersListModel;
}

export const PartnersFavouriteListComponent = ({ model }: Props) => (
  <PartnersCommonListComponent title="Избранное">
    {model.map(({
      id, logo, company_name, title,
    }) => (
      <PartnersItemInfoComponent
        key={id}
        logo={logo}
        title={company_name}
        description={title}
        tag="link"
        to={`/partners/${id}`}
      />
    ))}
  </PartnersCommonListComponent>
);
