export interface IUserPermission {
  name: string;
  description: string;
}


export interface IUserRightModel {
  name: string;
  description: string;
  permissions: IUserPermission[];
}

export class UserRightModel implements IUserRightModel {
  description: string;
  name: string;
  permissions: IUserPermission[];

  constructor(params: any = {}) {
    this.description = params.description;
    this.name = params.name;
    this.permissions = params.permissions;
  }
}

export type IUserRightsModel = IUserRightModel[];
