import { DefaultFilterType } from '../common';
import { IWithPages, WithPagesModel } from '../WithPagesModel';

export interface DepartmentFilterType extends DefaultFilterType{
  sort?: string;
  name?: string;
}

export interface IDepartmentItemModel {
  id: number;
  name: string;
  system_name: string;
  created_at: string;
  updated_at: string;
}

export class DepartmentItemModel implements IDepartmentItemModel {
  id: number;
  name: string;
  system_name: string;
  created_at: string;
  updated_at: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
    this.system_name = params.system_name;
    this.created_at = params.created_at;
    this.updated_at = params.updated_at;
  }
}

export interface IDepartmentModel extends IWithPages {
  data: IDepartmentItemModel[];
}

export class DepartmentModel extends WithPagesModel implements IDepartmentModel {
  data: IDepartmentItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, DepartmentItemModel);
  }
}
