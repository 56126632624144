import { createSelector } from 'reselect';
import { RootReducer } from '../../store/reducers';
import { storeName } from './systemGlobalReducers';
import { returnParams } from '../../store/selectors';


const getNewsCategories = (state: RootReducer) => state.system[storeName].newsCategories;
const getEnterprises = (state: RootReducer) => state.system[storeName].enterprises;
const getTags = (state: RootReducer) => state.system[storeName].tags;
const getUnits = (state: RootReducer) => state.system[storeName].units;
const getDepartments = (state: RootReducer) => state.system[storeName].departments;

const getRbacRoles = (state: RootReducer) => state.system[storeName].rbac.roles;
const getRbacRolesIsLoading = (state: RootReducer) => state.system[storeName].rbac.rolesIsLoading;
const getRbacRoleByName = (rolename: string) => (state: RootReducer) => state.system[storeName].rbac.roles.data
  .find((role) => role.name === rolename);
const getRbacPermissions = (state: RootReducer) => state.system[storeName].rbac.permissions;
const getRbacPermissionsIsLoading = (state: RootReducer) => state.system[storeName].rbac.permissionsIsLoading;

const getSystemGlobalDataLoaded = (state: RootReducer) => state.system[storeName].allDataLoaded;

const getSystemPollsQuestionTypes = (state: RootReducer) => state.system[storeName].polls.questionTypes;
const getSystemPollsStatusesTypes = (state: RootReducer) => state.system[storeName].polls.statuses;


export const systemNewsCategoriesSelector = createSelector([getNewsCategories], returnParams);
export const systemEnterprisesSelector = createSelector([getEnterprises], returnParams);
export const systemTagsSelector = createSelector([getTags], returnParams);
export const systemGlobalUnitsSelector = createSelector([getUnits], returnParams);
export const systemGlobalDepartmentsSelector = createSelector([getDepartments], returnParams);

export const systemRbacRolesSelector = createSelector([getRbacRoles], returnParams);
export const systemRbacRoleSelector = (rolename: string) => createSelector([getRbacRoleByName(rolename)], returnParams);
export const systemRbacRolesIsLoadingSelector = createSelector([getRbacRolesIsLoading], returnParams);
export const systemRbacPermissionsSelector = createSelector([getRbacPermissions], returnParams);
export const systemRbacPermissionsIsLoadingSelector = createSelector([getRbacPermissionsIsLoading], returnParams);

export const systemGlobalDataLoadedSelector = createSelector([getSystemGlobalDataLoaded], returnParams);

export const systemPollsQuestionTypesSelector = createSelector([getSystemPollsQuestionTypes], returnParams);
export const systemPollsStatusesSelector = createSelector([getSystemPollsStatusesTypes], returnParams);
