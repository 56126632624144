import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';


const getPrivilegesList = (state: RootReducer) => state.system[storeName].privilegesList;
const getPrivilegesListIsLoading = (state: RootReducer) => state.system[storeName].privilegesListIsLoading;


export const systemPrivilegesListSelector = createSelector([getPrivilegesList], returnParams);
export const systemPrivilegesListIsLoadingSelector = createSelector([getPrivilegesListIsLoading], returnParams);
