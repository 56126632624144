import { createSelector } from 'reselect';
import { RootReducer } from '../../store/reducers';
import { storeName } from './reducer';
import { returnParams } from '../../store/selectors';

const getEvents = (state: RootReducer) => state[storeName].events;
const getEventsIsLoading = (state: RootReducer) => state[storeName].eventsIsLoading;


export const getEventsSelector = createSelector([getEvents], returnParams);
export const getEventsIsLoadingSelector = createSelector([getEventsIsLoading], returnParams);
