import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ApplicationRequestType } from 'models/Application/interfaces';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';
import { handleErrors } from 'utils/errors';
import { defaultFileExtensions } from 'config/system';
import { SubmitLoaderButton } from '../common/SubmitLoaderButton';
import { UiFileUploader } from '../common/FileUploader';
import { UiInput } from '../common/UI/Input';

type Props = {
  onSubmit: (data: ApplicationRequestType) => void;
  cancelIsLoading: boolean;
}

type FormValues = {
  comment: string;
  files: File[];
}

export const ApplicationOneCancelFormComponent = ({ onSubmit, cancelIsLoading }: Props) => {
  const {
    register, errors, handleSubmit, setValue, watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      comment: '',
      files: [],
    },
  });

  const { t } = useTranslation();

  const onLocalSubmit = async ({ comment, files }: FormValues) => {
    const saveForm: ApplicationRequestType = { comment: comment.length ? comment : null };

    if (files.length && comment.length === 0) {
      toast.warn(`Необходимо заполнить поле "${t('application.comment.cancel')}"`);
      return;
    }

    if (files.length) {
      const modelOrError = await new UploadFiles(files).upload();
      handleErrors(
        modelOrError,
        'save',
        (fileList) => {
          saveForm.files = fileList.map((f) => f.file_name);
        },
      );

      /** Если ошибка в файлах не отменять заявку */
      if (modelOrError instanceof Error) {
        return;
      }
    }

    onSubmit(saveForm);
  };

  useEffect(() => {
    /** регистрация поля, управляемого вручную */
    register('files');
  }, [register]);

  return (
    <form className="form-with-btn" onSubmit={handleSubmit(onLocalSubmit)}>

      <UiInput
        value={watch('comment')}
        register={register}
        name="comment"
        errors={errors}
        type="textarea"
        rows={2}
        maxLength={300}
        showLength
        placeholder={t('application.comment.cancel')}
      />

      <UiFileUploader
        onChange={(files: File[]) => setValue('files', files)}
        accept={defaultFileExtensions}
        maxFilesCount={5}
        disableUpload={cancelIsLoading}
      />

      <div className="form-wrapper_btn">
        <div className="buttons-group buttons-group-responsive">
          <SubmitLoaderButton
            disabled={cancelIsLoading}
            loading={cancelIsLoading}
            label={t('applications.btn.cancel')}
          />
        </div>
      </div>
    </form>
  );
};
