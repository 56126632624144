import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { convertModelToOptions, SimpleSelectOptionType } from '../../../utils/convertModelToOptions';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { ISystemUnitModel } from '../../../models/References/UnitsModel';
import { ISystemMedicalItemModel } from '../../../models/Medical/SystemMedicalModel';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { UiDatePicker } from '../../../components/common/Dates/UiDatePicker';
import { UiSelect } from '../../../components/common/UI/Select';
import { UiSystemForm } from '../../../components/common/UI/Form';
import { IMedicalFormModel } from '../../../models/Medical/interfaces';
import { CDate } from '../../../utils/CDate';
import { ICurrentUserModel, UserAccessEnum } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';

type Props = {
  enterpriseList: IEnterpriseModel[];
  unitList: ISystemUnitModel[];
  model: ISystemMedicalItemModel;
  onSave: (id: number | null, model: IMedicalFormModel) => void;
}

type FormValues = {
  enterprise: SimpleSelectOptionType;
  units: SimpleSelectOptionType[];
  date_start: Date | null;
  address: string;
  comment: string;
}

const minDate = new Date();

export const SystemMedicalFormComponent = ({
  enterpriseList,
  unitList,
  model,
  onSave,
}: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, watch, errors, setValue,
    formState: { isSubmitting, dirtyFields, isValid }, getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise: isEmpty(model.enterprise) ? undefined : convertModelToOptions([model.enterprise])[0],
      units: convertModelToOptions(model.units),
      date_start: model.date_start,
      address: model.address,
      comment: model.comment,
    },
  });

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cMedical);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wMedical);

  const onSubmit = async ({
    date_start, enterprise, units, address, comment,
  }: FormValues) => {
    if ((model.id && !canUpdate) || (!model.id && !canCreate)) {
      return;
    }

    const modelToSave: IMedicalFormModel = {
      date_start: CDate.format(date_start || new Date(), 'dd.MM.yyyy HH:mm'),
      enterprise: enterprise ? enterprise.value : undefined,
      units: units ? units.map((unit: SimpleSelectOptionType<number>) => unit.value) : undefined,
      address,
      comment,
    };

    onSave(model?.id || null, modelToSave);
  };

  const enterpriseWatch = watch('enterprise');

  const filteredUnitList = enterpriseWatch ?
    unitList.filter((unit) => enterpriseWatch.value === unit.enterprise.id) :
    [];

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={!model.id}
    >
      <h3>{t(model.id ? 'medical.form.update' : 'medical.form.create')}</h3>

      <UiSelect
        defaultValue={getValues('enterprise')}
        errors={errors}
        register={register}
        name="enterprise"
        label={t('common.enterprise')}
        setValue={setValue}
        options={convertModelToOptions(enterpriseList)}
        required
      />

      <UiSelect
        defaultValue={getValues('units')}
        errors={errors}
        register={register}
        name="units"
        label={t('common.units')}
        setValue={setValue}
        options={convertModelToOptions(filteredUnitList)}
        multiple
        required
      />

      <UiDatePicker
        watch={watch}
        name="date_start"
        setValue={setValue}
        register={register}
        label={t('medical.dates')}
        showTimeSelect
        required
        minDate={minDate}
      />


      <UiSystemInput
        name="address"
        errors={errors}
        register={register}
        label={t('medical.address')}
      />

      <UiSystemInput
        type="textarea"
        name="comment"
        errors={errors}
        register={register}
        label={t('medical.comment')}
      />
    </UiSystemForm>
  );
};
