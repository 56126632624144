import { DefaultFilterType } from '../common';
import { IEnterpriseModel } from '../Enterprises/EnterpriseModel';
import { CDate } from '../../utils/CDate';
import { IWithPages, WithPagesModel } from '../WithPagesModel';

export type ISystemMedicalFilterType = {
  sort?: string;
  date_from?: string; // Y-m-d
  date_to?: string; // Y-m-d
  enterprise_id?: number;
  units_ids?: number[];
} & DefaultFilterType;

export interface ISystemMedicalItemModel {
  readonly id: number;
  readonly date_start: Date | null;
  readonly address: string;
  readonly comment: string;
  readonly enterprise: IEnterpriseModel;
  readonly units: {
    id: number;
    name: string;
  }[];
}

export class SystemMedicalItemModel implements ISystemMedicalItemModel {
  readonly id: number;
  readonly date_start: Date | null;
  readonly address: string;
  readonly comment: string;
  readonly enterprise: IEnterpriseModel;
  readonly units: {
    id: number;
    name: string;
  }[];

  constructor(params: any = {}) {
    this.id = params.id;
    this.date_start = params.date_start ? CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm') : null;
    this.address = params.address || '';
    this.comment = params.comment || '';
    this.enterprise = params.enterprise || {};
    this.units = params.units || [];
  }
}

export interface ISystemMedicalModel extends IWithPages {
  data: SystemMedicalItemModel[];
}

export class SystemMedicalModel extends WithPagesModel implements ISystemMedicalModel {
  data: SystemMedicalItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemMedicalItemModel);
  }
}
