export const getItem = (key: string): any => localStorage.getItem(key);

export const setItem = (key: string, value: any): void => {
  let stringValue = value;

  if (typeof value !== typeof '') {
    try {
      stringValue = value.toString();
    } catch (e) {
      return;
    }
  }

  localStorage.setItem(key, stringValue);
};

export const removeItem = (key: string): void => localStorage.removeItem(key);
