import { baseApiFn } from 'utils/baseApiFn';
import { httpGet } from 'utils/http';
import { IPageReferralProgramModel, PageReferralProgramModel } from 'models/References/PageReferralProgramModel';


/** Получить текст реферальной программы */
export const getReferralProgramText = async () => baseApiFn<IPageReferralProgramModel>(async () => {
  const response = await httpGet('/public/references/page/referral-program');
  return new PageReferralProgramModel(response);
});
