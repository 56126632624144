import React from 'react';
import { Card } from 'reactstrap';
import { ISystemEventPollResult } from '../../../models/Events/SystemEventPollResult';


const InfoRow = ({ label, data }: { label: string; data: string}) => (
  <div>
    <strong>{label}:</strong> {data}
  </div>
);


type Props = {
  model: ISystemEventPollResult;
}

/** блок с подробной инфой голосования */
export const PollResultItem = ({ model }: Props) => (
  <Card body className="mt-3 mb-0">
    <InfoRow label="ФИО" data={model.fio} />
    <InfoRow label="Табельный номер" data={model.personnel_number} />
    <InfoRow label="Предприятие" data={model.enterprise} />
  </Card>
);
