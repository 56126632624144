import { getSystemNewsById } from '../../api/news';

/**
 *
 * содержимое этого файла является экспериментальной функцией и может сломаться со временем
 *
 * запрещается использовать в проекте какие-либо из нижеприведенных функций
 *
 */

const wrapPromise = (promise: Promise<any>) => {
  let status = 'pending';
  let result: any;
  const suspender = promise.then(
    (r: any) => {
      status = 'success';
      result = r;
    },
    (e: any) => {
      status = 'error';
      result = e;
    },
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      } else {
        return null;
      }
    },
  };
};


export const experimentalGetSystemNewsById = (id: number) => ({
  news: wrapPromise(getSystemNewsById(id)),
});
