import { baseApiFn } from 'utils/baseApiFn';
import { ISystemRouteDetailModel, SystemRouteDetailModel } from 'models/Transport/SystemRouteModel';
import {
  httpDelete,
  httpGet, httpPost, httpPut,
} from 'utils/http';
import { ISystemBusStopModel } from 'models/References/BusStopsModel';
import { IBusStopScheduleForm, IRouteFormModel } from 'models/Transport/interfaces';
import { maxValueToGetAllDataWithPagination } from 'config/system';

// Получить маршрут
export const getSystemTransportRoute = async (id: number) => baseApiFn<ISystemRouteDetailModel>(async () => {
  const response = await httpGet(`/system/transport/route/${id}`);
  return new SystemRouteDetailModel({ id, ...response });
});

// Создать маршрут
export const createSystemTransport = async (data: IRouteFormModel) => baseApiFn(
  httpPost('/system/transport/route', data),
);

// Редактировать маршрут
export const updateSystemTransport = async (id: number, data: IRouteFormModel) => baseApiFn(
  httpPut(`/system/transport/route/${id}`, data),
);

// Удалить маршрут
export const deleteSystemTransport = async (id: number) => baseApiFn(
  httpDelete(`/system/transport/route/${id}`),
);

// Редактировать расписание маршрута
export const editTransportSchedule = async (id: number, schedule: IBusStopScheduleForm[]) => baseApiFn(
  httpPut(`/system/transport/route/${id}/schedule`, { schedule }),
);

// Получить все остановки
export const getAllBusStops = async () => baseApiFn<ISystemBusStopModel[]>(
  async () => {
    const response = await httpGet('/system/references/bus-stop', { 'per-page': maxValueToGetAllDataWithPagination });
    return response.data;
  },
);
