import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import {
  SYSTEM_REFERRAL_GET_LIST_START,
  SYSTEM_REFERRAL_GET_LIST_OK,
  SYSTEM_REFERRAL_GET_LIST_FAIL,
} from './constants';
import { IReferralProgramFilter, ISystemReferralProgramListModel } from '../../../models/ReferralProgram/interfaces';


export const systemReferralGetListStartAction = (filter: IReferralProgramFilter) => dataAction(
  SYSTEM_REFERRAL_GET_LIST_START,
)({ filter });
export const systemReferralGetListOkAction = (referralList: ISystemReferralProgramListModel) => dataSuccessAction(
  SYSTEM_REFERRAL_GET_LIST_OK,
)({ referralList });
export const systemReferralGetListFailAction = dataErrorAction(SYSTEM_REFERRAL_GET_LIST_FAIL);
