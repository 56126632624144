import React, { useCallback, useEffect, useState } from 'react';

import { IReportInsurance, IReportInsuranceEnterprise } from '../../../models/Reports/interfaces';
import {
  getSystemReportInsurance, getSystemReportInsuranceEnterprise,
} from '../../../api/reports';
import { handleErrors } from '../../../utils/errors';
import { SystemReportInsuranceIndexComponent } from '../../components/Reports/Insurance';


export const SystemReportInsurance = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState<IReportInsurance | IReportInsuranceEnterprise | null>(null);

  const getData = useCallback(async (enterprise_ids?: number[]) => {
    setIsLoading(true);

    const apiFn = enterprise_ids ?
      () => getSystemReportInsuranceEnterprise(enterprise_ids) :
      getSystemReportInsurance;

    handleErrors(
      await apiFn(),
      'get',
      setModel,
      () => setIsLoading(false),
    );
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <SystemReportInsuranceIndexComponent
      model={model}
      isLoading={isLoading}
      getData={getData}
    />
  );
};
