export const GET_BUS_STOP_LIST_START = '[BUS_STOPS] get bus stop list [start]';
export const GET_BUS_STOP_LIST_OK = '[BUS_STOPS] get bus stop list [ok]';
export const GET_BUS_STOP_LIST_FAIL = '[BUS_STOPS] get bus stop list [fail]';

export const SET_BUS_STOP_FAVORITE_START = '[BUS_STOPS] set favorite bus stop [start]';
export const SET_BUS_STOP_FAVORITE_OK = '[BUS_STOPS] set favorite bus stop [ok]';
export const SET_BUS_STOP_FAVORITE_FAIL = '[BUS_STOPS] set favorite bus stop [fail]';

export const REMOVE_BUS_STOP_FAVORITE_START = '[BUS_STOPS] remove favorite bus stop [start]';
export const REMOVE_BUS_STOP_FAVORITE_OK = '[BUS_STOPS] remove favorite bus stop [ok]';
export const REMOVE_BUS_STOP_FAVORITE_FAIL = '[BUS_STOPS] remove favorite bus stop [fail]';
