import {
  httpDelete, httpGet, httpPost, httpPut,
} from '../../utils/http';
import { INewsItemModel, NewsItemModel } from '../../models/News/NewsItemModel';
import { INewsCreate } from '../../models/News/NewsCreate';
import { baseApiFn } from '../../utils/baseApiFn';


/** создание новости */
export const createNews = async (model: INewsCreate) => baseApiFn<true>(httpPost('system/news', model));

/** получить полную модель по ид */
export const getSystemNewsById = async (id: number) => baseApiFn<INewsItemModel>(async () => {
  const data = await httpGet(`system/news/${id}`);
  return new NewsItemModel(data);
});

/** редактирование новости */
export const updateNews = async (id: number, model: INewsCreate) => baseApiFn<true>(
  httpPut(`system/news/${id}`, model),
);

/** удаление новости */
export const deleteNews = async (id: number) => baseApiFn<true>(httpDelete(`system/news/${id}`));
