import find from 'lodash/find';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { replacePhoneMaskRegExp } from 'config/system';
import { CDate } from './CDate';

/** вернуть числовой телефон (без маски) */
export const getNumberPhoneWithoutMask = (str: string): number => +str.replace(replacePhoneMaskRegExp, '');

export const getDateOrUndefined = (date: Date | null) => (date ? CDate.format(date, 'Y-MM-dd') : undefined);


/** частоиспользуемый случай поиска значения */
export const findValue = (arr: SimpleSelectOptionType[], value?: number | string | null) => find(arr, ['value', value]);

/** проверить значение на null | undefined и заменить на другое */
export const checkReplace = (
  valueToCheck: any,
  valueToReplace: any,
) => (valueToCheck === null || valueToCheck === undefined ? valueToReplace : valueToCheck);
