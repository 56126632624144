import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../utils/reducer';
import { AUTH_SIGNIN_FAIL, AUTH_SIGNIN_OK, AUTH_SIGNIN_START } from './constants';

export type signInReducerType = {
  signInIsLoading: boolean;
}

const initialState: signInReducerType = {
  signInIsLoading: false,
};

export const storeName = 'signIn';

export const signInReducer = {
  [storeName]: (state: signInReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case AUTH_SIGNIN_START:
        return onLoading(state, 'signIn');
      case AUTH_SIGNIN_OK:
        return onLoad(state, payload, 'signIn');
      case AUTH_SIGNIN_FAIL:
        return onFail(state, 'signIn');

      default:
        return state;
    }
  },
};
