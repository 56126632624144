import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { CDate } from '../../utils/CDate';
import { DefaultFilterType } from '../common';


export type ISystemListEventFilterType = {
  date_from?: string; // Y-m-d
  date_to?: string; // Y-m-d
  search?: string;
} & DefaultFilterType;


export interface ISystemListEvent {
  readonly id: number;
  readonly name: string;
  readonly date_start: Date;
  readonly author: {
    id: number;
    fio: string;
  };
}

export class SystemListEvent implements ISystemListEvent {
  readonly author: { id: number; fio: string };
  readonly date_start: Date;
  readonly id: number;
  readonly name: string;

  constructor(params: any = {}) {
    this.author = params.author;
    this.date_start = CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm');
    this.id = params.id;
    this.name = params.name;
  }
}


export interface ISystemListEvents extends IWithPages {
  data: SystemListEvent[];
}

export class SystemListEventsModel extends WithPagesModel implements ISystemListEvents {
  data: SystemListEvent[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemListEvent);
  }
}
