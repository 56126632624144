import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import {
  emptySystemPartnersFormModel, SystemPartnersFormType, SystemPartnersFormValuesModelType,
  SystemPartnersModelType,
} from 'models/Partners/SystemPartnerFormModel';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  systemCreatePartner,
  systemGetAllPartnerCategories, systemGetPartnerById, systemUpdatePartner,
} from 'systemModule/api/partners';
import { SystemPartnersFormComponent } from 'systemModule/components/Partners/form';
import { systemEnterprisesSelector } from 'systemModule/store/selectors';
import { convertModelToOptions, SelectOptionType } from 'utils/convertModelToOptions';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';
import { ISystemPartnersCategoryListItemModel } from 'models/Partners/SystemPartnerCategoryListModel';
import { customHistory } from 'customHistory';
import { Loader } from 'components/common/UI/Loaders';


export const SystemPartnersForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wPartner);

  const [model, modelLoading] = usePromise<SystemPartnersModelType>(
    () => systemGetPartnerById(numberId),
    !isNaN(numberId),
    [numberId],
  );

  const [categories, categoriesLoading] = usePromise<ISystemPartnersCategoryListItemModel[]>(
    systemGetAllPartnerCategories, true,
  );

  /** использовать все доступные предпрятия */
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const [categoriesSelect, enterprisesSelect] = useMemo<[SelectOptionType[], SelectOptionType[]]>(() => {
    const enterpriseOptions = convertModelToOptions(enterprises);
    enterpriseOptions.unshift({ label: 'Холдинг', value: 0 });

    return [
      categories ? convertModelToOptions(categories) : [],
      enterpriseOptions,
    ];
  }, [categories, enterprises]);

  const [saving, setSaving] = useState(false);
  const onSave = useCallback(async (modelToSave: SystemPartnersFormValuesModelType) => {
    setSaving(true);

    // обновление и загрузка файлов. файлы необязательные, поэтому сразу загрузить проблемно
    const getFilestrOrNull = async (
      prop: 'logo' | 'barcode' | 'qr_code' | 'contacts_image',
    ): Promise<string | null> => {
      const fileOrNull: File | null = modelToSave[prop];
      const fileStr = fileOrNull ? await new UploadFiles([fileOrNull]).upload() : fileOrNull;
      return get(fileStr, '[0].file_name', null);
    };

    const logo = await getFilestrOrNull('logo');
    const barcode = await getFilestrOrNull('barcode');
    const qr_code = await getFilestrOrNull('qr_code');
    const contacts_image = await getFilestrOrNull('contacts_image');
    const images = modelToSave.images?.length ? await new UploadFiles(modelToSave.images).upload() : [];

    // подготовка модели
    const dataToSave: SystemPartnersFormType = {
      ...modelToSave,
      logo,
      barcode,
      qr_code,
      contacts_image,
      images: Array.isArray(images) ? images.map((_) => _.file_name) : [],
      categories: modelToSave.categories.map((item) => item.value),
      // для всего холдинга - ничего не передавать
      enterprises: modelToSave.enterprises.map((item) => item.value).filter((_) => _),
    };

    const isNew = dataToSave.id === 0;

    const fn = isNew ? () => systemCreatePartner(dataToSave) : () => systemUpdatePartner(dataToSave.id, dataToSave);

    handleErrors(
      await fn(),
      'save',
      () => {
        toast.success(t(`system.partners.form.${isNew ? 'created' : 'updated'}`));
        customHistory.push('/system/partners');
        setSaving(false);
      },
      () => {
        setSaving(false);
      },
    );
  }, [t, setSaving]);

  return (modelLoading || categoriesLoading) ? <Loader /> : (
    <SystemPartnersFormComponent
      model={model || emptySystemPartnersFormModel}
      canUpdate={canUpdate}
      categoriesOptions={categoriesSelect}
      enterprisesOptions={enterprisesSelect}
      onSave={onSave}
      saving={saving}
    />
  );
};
