import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getUsersOptions } from 'api/Users';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { ParamTypes } from 'models/common';
import { emptyOption } from 'components/common/UI/Select';
import { createApplicationConstruct, getApplicationConstructType } from 'api/application';
import {
  ApplicationConstructCreateFormComponent,
} from 'components/Applications/Construct/ApplicationConstructCreateForm';
import { Loader } from 'components/common/UI/Loaders';
import {
  ApplicationConstructFormBlockType,
  ApplicationConstructFormType,
  IApplicationConstructTypeModel,
} from 'models/Application/Construct/interfaces';
import { EntityNotFound } from 'components/Errors/404';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { handleErrors } from 'utils/errors';
import { getModelFromModels } from 'utils/getModelFromModels';
import { usePromise } from 'utils/hooks/usePromise';
import { HttpErrors } from 'utils/http';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';

// Надеюсь что временный хардкод категории
const HAVE_IDEA_ITEM_IDS = process.env.REACT_APP_HAVE_IDEA_ITEM_IDS?.split(',') ?? [];

const allOption = emptyOption('Все совместители');

export const ApplicationConstructCreate = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const history = useHistory();

  const [models, modelsLoading] = usePromise<[
    IApplicationConstructTypeModel | HttpErrors,
    SimpleSelectOptionType<number>[] | HttpErrors,
  ]>(
    () => Promise.all([
      getApplicationConstructType(numberId),
      getUsersOptions(),
    ]),
    !isNaN(numberId),
    [numberId],
  );

  const [model, usersOptions]: [
    IApplicationConstructTypeModel | HttpErrors | null,
      SimpleSelectOptionType<number>[] | HttpErrors | []
  ] = [
    getModelFromModels(models, 0, null),
    getModelFromModels(models, 1, null),
  ];

  const { mainEnterprise } = useSelector(getCurrentUserSelector);
  const { enterprises } = useSelector(getCurrentUserSelector);
  const enterpriseOptions = useMemo(
    () => [allOption].concat(enterprises.map((ent: IEnterpriseModel) => ({ value: ent.id, label: ent.name }))),
    [enterprises],
  );

  const onSave = useCallback(async (data: ApplicationConstructFormType) => {
    // Загрузка файлов
    const dataClone = cloneDeep(data);
    const fieldsWithFiles: { uid: string, value: any }[] = [];
    data.additionalFields.forEach((block: ApplicationConstructFormBlockType) => {
      block.fields.forEach((field) => {
        if (field.value instanceof File) {
          fieldsWithFiles.push(field);
        }
      });
    });
    const files = fieldsWithFiles.map((f) => f.value);
    const filesLoaded = await new UploadFiles(files).upload();

    if (filesLoaded instanceof Error) {
      toast.error('Не удалось загрузить файлы');
      return;
    }

    // Добавление имён загруженных файлов в структуру для отправки
    let fileIndex = 0;
    data.additionalFields.forEach((block: ApplicationConstructFormBlockType, i) => {
      block.fields.forEach((field, j) => {
        if (field.value instanceof File) {
          const cloneFields = dataClone.additionalFields[i].fields;
          cloneFields[j] = {
            ...cloneFields[j],
            value: filesLoaded[fileIndex].file_name,
          };
          fileIndex += 1;
        }
      });
    });

    handleErrors(
      await createApplicationConstruct(dataClone),
      'save',
      () => {
        toast.success(t('application.construct.created'));
        history.push('/applications');
      },
    );
  }, [history, t]);


  if (modelsLoading) {
    return <Loader />;
  }
  if (!mainEnterprise) {
    return <EntityNotFound message={t('common.no_main_enterprise')} />;
  }
  if (model && !(model instanceof Error) && !(usersOptions instanceof Error)) {
    return (
      <ApplicationConstructCreateFormComponent
        model={model}
        userEnterpriseId={mainEnterprise.id}
        enterpriseOptions={enterpriseOptions}
        onSave={onSave}
        usersOptions={usersOptions}
        isHaveIdea={HAVE_IDEA_ITEM_IDS.includes(id as string)}
      />
    );
  }
  return <EntityNotFound message={t('application.type.404')} />;
};

ApplicationConstructCreate.whyDidYouRender = true;
