import React, {
  useCallback, useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UiSystemForm } from 'components/common/UI/Form';
import { emptyOptionValue } from 'components/common/UI/Select';
import {
  ConstructApplicationTypeFormFieldsType, ConstructApplicationTypeModelAdditionalFieldsType,
} from 'models/ConstructApplication/interfaces';
import {
  defaultEmptyBlock,
  defaultEmptyField,
  FormValues,
} from 'systemModule/components/ConstructApplication/Type/common';
import { PartBlock } from 'systemModule/components/ConstructApplication/Type/ConstructApplicationTypeFields/_block';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import {
  prepareTypeFieldBlock,
} from 'systemModule/components/ConstructApplication/Type/ConstructApplicationTypeFields/func';


/** подготовить разрегистрируемые поля пОля */
const prepareRemoveField = (blockIndex: number, fieldIndex: number): string[] => [
  `blocks[${blockIndex}].fields[${fieldIndex}].id`,
  `blocks[${blockIndex}].fields[${fieldIndex}].name`,
  `blocks[${blockIndex}].fields[${fieldIndex}].type`,
  `blocks[${blockIndex}].fields[${fieldIndex}].required`,
  `blocks[${blockIndex}].fields[${fieldIndex}].template_mark`,
  `blocks[${blockIndex}].fields[${fieldIndex}].list`,
];
/** --- */


type Props = {
  model: ConstructApplicationTypeModelAdditionalFieldsType;
  templatesOptions: SimpleSelectOptionType<number>[];
  onSave: (id: number, m: ConstructApplicationTypeFormFieldsType[]) => void;
  modelId: number;
}

/**
 * динамическая форма в динамической форме
 * полное ручное управление - регистрация, разрегистрация, установка значений
 *
 * массивы формы могут быть пустыми (empty), т.к. сдвига при удалении не происходит
 * нужно фильтровать. сделать функцию единой проверки длины массива
 */
export const ConstructApplicationTypeFieldsComponent = ({
  model, templatesOptions, onSave, modelId,
}: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, errors, formState: { isSubmitting, dirtyFields, isValid },
    setValue, triggerValidation, getValues, unregister, watch, setError, clearError,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      blocks: (() => {
        if (model.length) {
          return model.map((block) => ({
            id: block.block_id,
            generate: block.has_template,
            name: block.name,
            file: null,
            fileType: block.file,
            template_id: block.template_id ? block.template_id : emptyOptionValue,
            fields: block.fields.map((field) => ({
              id: field.uid,
              name: field.name,
              type: field.type,
              required: field.required,
              template_mark: field.template_mark,
              list: field.list ? field.list.join(';') : undefined,
            })),
          }));
        }
        return [defaultEmptyBlock()];
      })(),
    },
  });

  const { blocks } = watch({ nest: true });

  const onSubmit = useCallback((data: FormValues) => onSave(modelId, data.blocks
    .filter((v) => v)
    .map(prepareTypeFieldBlock)), [modelId, onSave]);

  useEffect(() => {
    /** зарегистрировать ручные поля блоков при старте */
    getValues({ nest: true }).blocks
      .forEach((existBlock, existBlockIndex) => {
        register(`blocks[${existBlockIndex}].file`);
        register(`blocks[${existBlockIndex}].id`);

        existBlock.fields.forEach((_, existFieldIndex) => {
          register(`blocks[${existBlockIndex}].fields[${existFieldIndex}].id`);
        });
      });
  }, [register, getValues]);

  /** управление блоками */
  const appendBlock = useCallback(() => {
    const newBlockIndex = getValues({ nest: true }).blocks.length;

    /** зарегистрировать новый блок с 1 пустым полем */
    Object.entries(defaultEmptyBlock()).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((fieldObj) => {
          Object.entries(fieldObj).forEach(([fkey, fvalue]) => {
            register(`blocks[${newBlockIndex}].fields[0].${fkey}`);
            setValue(`blocks[${newBlockIndex}].fields[0].${fkey}`, fvalue);
          });
        });
      } else {
        register(`blocks[${newBlockIndex}].${key}`);
        setValue(`blocks[${newBlockIndex}].${key}`, value);
      }
    });
  }, [register, getValues, setValue]);

  const removeBlock = useCallback((blockId: number) => {
    const localBlocks = getValues({ nest: true }).blocks;
    const blockIndex = localBlocks.findIndex((b) => b?.id === blockId);

    /** разрегистрировать блок по ид и все его поля */
    if (blockIndex !== -1) {
      unregister([
        `blocks[${blockIndex}].generate`,
        `blocks[${blockIndex}].id`,
        `blocks[${blockIndex}].name`,
        `blocks[${blockIndex}].template_id`,
        `blocks[${blockIndex}].file`,
        `blocks[${blockIndex}].fileType`,
      ]);

      localBlocks[blockIndex].fields.forEach((_, fieldIndex) => unregister(prepareRemoveField(blockIndex, fieldIndex)));

      triggerValidation();
    }
  }, [unregister, getValues, triggerValidation]);
  /** end управление блоками */

  /** управление полями */
  const appendField = useCallback((blockId: number) => () => {
    const localBlocks = getValues({ nest: true }).blocks;
    const blockIndex = localBlocks.findIndex((b) => b?.id === blockId);

    if (blockIndex !== -1) {
      const newFieldIndex = localBlocks[blockIndex].fields.length;

      /** зарегистрировать 1 пустое поле */
      Object.entries(defaultEmptyField()).forEach(([key, value]) => {
        register(`blocks[${blockIndex}].fields[${newFieldIndex}].${key}`);
        setValue(`blocks[${blockIndex}].fields[${newFieldIndex}].${key}`, value);
      });
    }
  }, [register, setValue, getValues]);

  const removeField = useCallback((blockId: number) => (fieldId: string) => {
    const localBlocks = getValues({ nest: true }).blocks;
    const blockIndex = localBlocks.findIndex((b) => b?.id === blockId);

    if (blockIndex !== -1) {
      const fieldIndex = localBlocks[blockIndex].fields.findIndex((f) => f?.id === fieldId);

      if (fieldIndex !== -1) {
        unregister(prepareRemoveField(blockIndex, fieldIndex));

        triggerValidation();
      }
    }
  }, [getValues, unregister, triggerValidation]);
  /** end управление полями */

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={false}
    >
      <h3>
        {t('system.construct_application.type.step_fields')}
      </h3>

      {blocks.map((block, blockIndex) => (
        <PartBlock
          key={`block${block.id}`}
          block={block}
          blockAmount={blocks.filter((v) => v).length}
          last={blockIndex === blocks.length - 1}
          setValue={setValue}
          blockIndex={blockIndex}
          getValues={getValues}
          errors={errors}
          register={register}
          templatesOptions={templatesOptions}
          triggerValidation={triggerValidation}
          unregister={unregister}
          appendBlock={appendBlock}
          removeBlock={removeBlock}
          appendField={appendField(block.id)}
          removeField={removeField(block.id)}
          setError={setError}
          clearError={clearError}
        />
      ))}

    </UiSystemForm>
  );
};
