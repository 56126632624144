export const AUTH_SIGNIN_START = '[AUTH] sign in [start]';
export const AUTH_SIGNIN_OK = '[AUTH] sign in [ok]';
export const AUTH_SIGNIN_FAIL = '[AUTH] sign in [fail]';

export const AUTH_LOGOUT_START = '[AUTH] logout [start]';
export const AUTH_LOGOUT_OK = '[AUTH] logout [ok]';
export const AUTH_LOGOUT_FAIL = '[AUTH] logout [fail]';

export const AUTH_SUGNUP_PHONE_START = '[AUTH] signup phone [start]';
export const AUTH_SUGNUP_PHONE_OK = '[AUTH] signup phone [ok]';
export const AUTH_SUGNUP_PHONE_FAIL = '[AUTH] signup phone [fail]';

export const AUTH_SUGNUP_SMS_CODE_START = '[AUTH] signup sms code [start]';
export const AUTH_SUGNUP_SMS_CODE_OK = '[AUTH] signup sms code [ok]';
export const AUTH_SUGNUP_SMS_CODE_FAIL = '[AUTH] signup sms code [fail]';

export const AUTH_SUGNUP_PASSWORD_START = '[AUTH] signup password [start]';
export const AUTH_SUGNUP_PASSWORD_OK = '[AUTH] signup password [ok]';
export const AUTH_SUGNUP_PASSWORD_FAIL = '[AUTH] signup password [fail]';
