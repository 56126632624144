import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  ISystemMedicalFilterType,
  ISystemMedicalItemModel,
  ISystemMedicalModel,
} from '../../../models/Medical/SystemMedicalModel';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { SystemMedicalFilter } from './Filter';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { ISystemUnitModel } from '../../../models/References/UnitsModel';
import { CDate } from '../../../utils/CDate';
import { CustomTable } from '../../../components/common/Table';
import { ICurrentUserModel, UserAccessEnum } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';
import { handleErrors } from '../../../utils/errors';
import { deleteSystemMedical } from '../../api/medical';
import { getParamsFromPagesModel } from '../../../models/WithPagesModel';

type Props = {
  data: ISystemMedicalModel;
  enterpriseList: IEnterpriseModel[];
  unitList: ISystemUnitModel[];
  isLoading: boolean;
  getData: (filter: ISystemMedicalFilterType) => void;
}

export const SystemMedicalComponent = ({
  data, isLoading, getData, enterpriseList, unitList,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cMedical);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dMedical);

  const onDelete = ({ id, address }: ISystemMedicalItemModel) => async () => {
    const confirmOptions = { entity: 'медицинский осмотр по адресу', entityName: address };

    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', confirmOptions))) {
      handleErrors(
        await deleteSystemMedical(id),
        'delete',
        () => {
          toast.success(t('medical.deleted'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  };

  return (
    <>
      <h3>{t('medical.menu')}</h3>

      <SystemMedicalFilter
        onSubmit={onSubmitFilter}
        onReset={onResetFilter}
        enterpriseList={enterpriseList}
        unitList={unitList}
      />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="/system/medical/create">{t('medical.create')}</Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: t('common.enterprise') },
          { name: t('common.unit') },
          { name: t('medical.dates') },
          { name: t('medical.address') },
          { name: t('medical.comment') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item: ISystemMedicalItemModel) => (
          <tr key={item.id}>
            <td>
              <Link to={`/system/medical/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              {item.enterprise.name}
            </td>
            <td>
              {item.units.map((unit) => unit.name).join(', ')}
            </td>
            <td>
              {!!item.date_start && CDate.format(item.date_start, 'dd MMMM yyyy')}
            </td>
            <td>
              {item.address}
            </td>
            <td>
              {item.comment}
            </td>
            <td>
              {canDelete && (
                <Button type="button" color="danger" onClick={onDelete(item)}>{t('common.form.delete')}</Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>

    </>
  );
};
