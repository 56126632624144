import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';


const getTemplates = (state: RootReducer) => state.system[storeName].templates;
const getTemplatesIsLoading = (state: RootReducer) => state.system[storeName].templatesIsLoading;


export const systemNotificationTemplatesSelector = createSelector([getTemplates], returnParams);
export const systemNotificationTemplatesIsLoadingSelector = createSelector([getTemplatesIsLoading], returnParams);
