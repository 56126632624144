import i18next from 'i18next';


export enum SettingResponsibleTypeEnum {
  FEEDBACK = 'feedback',
  PERSONAL_DATA = 'personal_data',
  HOLIDAY = 'holiday'
}

export const getSettingResponsibleTypeSelect = () => [
  { label: i18next.t('feedback.responsible.type.feedback'), value: SettingResponsibleTypeEnum.FEEDBACK },
  { label: i18next.t('feedback.responsible.type.holiday'), value: SettingResponsibleTypeEnum.HOLIDAY },
  { label: i18next.t('feedback.responsible.type.personal_data'), value: SettingResponsibleTypeEnum.PERSONAL_DATA },
];
