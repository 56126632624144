import React, { memo, useEffect, useMemo } from 'react';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import { INewsCategoryItemModel } from 'models/News/NewsCategoryModel';
import { LocalNewsFilterType } from 'models/News/NewsModel';
import { usePrevious } from 'utils/hooks/usePrevious';
import { prepareFilterEntityByKey } from 'utils/prepareFilterEntityByKey';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { getDateOrUndefined } from 'utils/common';
import { setItem, getItem, removeItem } from 'utils/localStorage';
import { differenceEnterprises, getEnterpriseLikeAll } from 'utils/breakdownEnterprisesIntoGroups';
import { checkingIncludedEnterprisesID } from 'utils/checkingIncludedEnterprisesID';
import { DateRange } from '../common/Dates/DateRange';
import { UiCheckbox } from '../common/UI/Checkbox';
import { CompactEnterprisesFilter } from '../common/CompactEnterprisesFilter';


/** component utils */
const categoryPrefix = 'cat_';
const enterprisePrefix = 'ent_';

const getDefault = (
  data: LocalNewsFilterType,
  enterprises: IEnterpriseModel[],
  localStorageKey: string,
): NewsFormFormValues => {
  const allEnts = enterprises.filter(({ id }) => id).map(({ id }) => id);

  const localStorageData = getItem(localStorageKey);
  const dataFilter = localStorageData ? JSON.parse(localStorageData) : data;

  const obj = {
    categories: dataFilter.category.reduce((acc: {[s: string]: boolean}, catId: any) => {
      acc[`${categoryPrefix}${catId}`] = true;
      return acc;
    }, {}),
    enterprises: dataFilter.enterprise.reduce((acc: {[s: string]: boolean}, entId: any) => {
      acc[`${enterprisePrefix}${entId}`] = true;
      return acc;
    }, {}),
    date_from: dataFilter.date_from ? new Date(dataFilter.date_from) : null,
    date_to: dataFilter.date_to ? new Date(dataFilter.date_to) : null,
    holding: dataFilter.holding === 1,
  };

  if (isEqual(sortBy(allEnts), sortBy(dataFilter.enterprise))) {
    obj.enterprises[`${enterprisePrefix}0`] = true;
    obj.holding = true;
  }
  return obj;
};
/** end component utils */

/** тип формы */
export type NewsFormFormValues = {
  categories: {[s: string]: boolean};
  enterprises: {[s: string]: boolean};
  date_from: Date | null;
  date_to: Date | null;
  holding: boolean;
}


type Props = {
  filterData: LocalNewsFilterType;
  toggle: () => void;
  categories: INewsCategoryItemModel[];
  setFilterData: (filter: LocalNewsFilterType) => void;
  enterprises: IEnterpriseModel[];
  initialValues: () => LocalNewsFilterType;
  currentEnterprise: IEnterpriseModel[]
  localStorageKey: string;
}

const rangeNames = ['date_from', 'date_to'];

export const NewsFilter = memo(({
  filterData,
  toggle,
  categories,
  setFilterData,
  enterprises,
  initialValues,
  currentEnterprise,
  localStorageKey,
}: Props) => {
  const {
    register, handleSubmit, watch, reset, setValue,
  } = useForm<NewsFormFormValues>({
    mode: 'onChange',
    defaultValues: getDefault(filterData, enterprises, localStorageKey),
  });

  const { t } = useTranslation();

  /** подписаться на обновления формы */
  const watchCategoriesFields = watch('categories');
  const watchEnterprisesFields = watch('enterprises');
  const watchAll = watch();

  const otherEnterprise = differenceEnterprises(enterprises, currentEnterprise);
  const enterpriseLikeAll = getEnterpriseLikeAll(otherEnterprise);

  const prevWatchEnts = usePrevious(watchEnterprisesFields);

  useEffect(() => {
    if (watchEnterprisesFields.ent_0 && get(prevWatchEnts, 'ent_0', false) === false) {
      enterprises.forEach(({ id }) => {
        setValue(`enterprises.ent_${id}`, true);
      });
    }
  }, [watchEnterprisesFields, enterprises, setValue, prevWatchEnts]);

  /** все текущие актуальные данные фильтра брать отсюда */
  const getCurrentFilterData = (): LocalNewsFilterType => {
    const allEnts = enterprises.filter(({ id }) => id).map(({ id }) => id);

    return {
      category: prepareFilterEntityByKey(watchCategoriesFields, categoryPrefix),
      enterprise: prepareFilterEntityByKey(watchEnterprisesFields, enterprisePrefix),
      date_from: getDateOrUndefined(watchAll.date_from),
      date_to: getDateOrUndefined(watchAll.date_to),
      holding: (isEqual(sortBy(allEnts), sortBy(prepareFilterEntityByKey(watchEnterprisesFields, enterprisePrefix))) ?
        1 :
        0) || (watchEnterprisesFields.ent_0 ? 1 : 0),
    };
  };

  /** Логика для списка: раскрывать его, если есть выбранные предприятия, которые попадают в скрытые */
  const isCheckedEnterprises = useMemo(() => {
    const localStorageData = JSON.parse(getItem(localStorageKey));
    if (localStorageData) {
      return checkingIncludedEnterprisesID(otherEnterprise, localStorageData.enterprise);
    }
    return false;
  }, [otherEnterprise, localStorageKey]);

  const settingFilterData = (localStorageFilter: LocalNewsFilterType, filter: LocalNewsFilterType) => {
    if (localStorageFilter) {
      setFilterData(localStorageFilter);
    } else {
      setFilterData(filter);
    }
    toggle();
  };

  const onSubmit = () => {
    const filter = getCurrentFilterData();
    setItem(localStorageKey, JSON.stringify(filter));
    settingFilterData(JSON.parse(getItem(localStorageKey)), filter);
  };

  const clearFilter = () => {
    removeItem(localStorageKey);
    reset(getDefault(initialValues(), enterprises, localStorageKey));
  };

  return (
    <form autoComplete="off" className="modal-content" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-with-btn">
        <div className="form-wrapper_content">

          <h3>Рубрики</h3>
          <div className="section-block">
            {categories.map((category) => (
              <UiCheckbox
                key={category.name}
                label={category.name}
                register={register}
                name={`categories.cat_${category.id}`}
              />
            ))}
          </div>
          <h3>Предприятия</h3>
          <CompactEnterprisesFilter
            enterpriseLikeAll={enterpriseLikeAll}
            currentEnterprise={currentEnterprise}
            otherEnterprise={otherEnterprise}
            register={register}
            isCheckedEnterprises={isCheckedEnterprises}
          />

          <DateRange
            names={rangeNames}
            label="Период публикации новости"
            register={register}
            watch={watch}
            setValue={setValue}
          />

        </div>
        <div className="form-wrapper_btn">
          <div className="buttons-group buttons-group-responsive">
            <Button color="primary" type="submit">
              {t('common.filter.apply')}
            </Button>
            <Button
              color="primary"
              outline
              onClick={clearFilter}
              className="btn--clear-filter"
            >
              {t('common.form.clear_filter')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
});
