import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getAllPartners } from 'api/partner';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import { IPartnersListModel } from 'models/Partners/PartnersListModel';
import { PartnersAllSortedListComponent } from 'components/Partners/sortedList';
import { usePromise } from 'utils/hooks/usePromise';
import { HttpErrors } from 'utils/http';


export const PartnersAllSortedList = () => {
  const { t } = useTranslation();

  const [model, modelLoading, onUnmount] = usePromise<IPartnersListModel | HttpErrors>(
    getAllPartners,
    true,
  );
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  if (modelLoading) {
    return <Loader />;
  }
  if (model === null || model instanceof Error) {
    return <EntityNotFound message={t('partners.all.404')} />;
  }
  return (
    <PartnersAllSortedListComponent model={model} />
  );
};
