import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as HomeIcon } from 'assets/img/home.svg';
import { ReactComponent as MoreIcon } from 'assets/img/more.svg';
import { ReactComponent as GuideIcon } from 'assets/img/guide.svg';
import { CurrentUserModel } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';

import { filteringMenuItemsByRights } from 'utils/filteringMenuItemsByRights';
import { globalOpenAppGuide } from 'store/actions';
import { clientNav } from 'nav';
import { store } from 'index';
import { MenuItem } from './MenuItem';

export const Menu = () => {
  const currentUser: CurrentUserModel = useSelector(getCurrentUserSelector);
  const { t } = useTranslation();

  const menuList = useMemo(() => filteringMenuItemsByRights(clientNav, currentUser), [currentUser]);
  const menuListForAddMenu = useMemo(() => filteringMenuItemsByRights(clientNav, currentUser)
    .filter((menuItem) => !menuItem.isDisplayedOnMobileMenu), [currentUser]);

  const handleOpenAppGuide = () => {
    store.dispatch(globalOpenAppGuide(true));
  };

  return (
    <footer>
      <div className="bottom-menu">
        <div className="bottom-menu-wrapper">
          <MenuItem
            icon={<HomeIcon />}
            label={t('main.menu')}
            path="/"
            guideStep="step-2"
          />
          {
            menuList && (
              menuList.map((menuItem) => (
                <MenuItem
                  key={menuItem.name}
                  icon={<menuItem.icon />}
                  label={t(menuItem.name)}
                  path={menuItem.url}
                  exact={menuItem.exact}
                  className={menuItem.styles}
                  guideStep={menuItem.guideStep}
                />
              ))
            )
          }
          <button
            type="button"
            className="item item-button step-1 d-none d-flex-custom"
            onClick={handleOpenAppGuide}
          >
            <i className="icon"><GuideIcon /></i>
            <span className="text">{t('guide.menu')}</span>
          </button>
          {
            menuListForAddMenu.length > 0 ? (
              <MenuItem
                icon={<MoreIcon />}
                label={t('addMenu.menu')}
                path="/add-menu"
                exact
                className="d-none-custom"
                guideStep="step-10"
              />
            ) : ''
          }
        </div>
      </div>
    </footer>
  );
};

Menu.whyDidYouRender = true;
