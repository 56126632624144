import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  createSystemBonusProgramTagItem,
  getSystemBonusProgramTagItemById,
  updateSystemBonusProgramTagItem,
} from 'systemModule/api/bonusProgram';
import { handleErrors } from 'utils/errors';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import {
  ISystemBonusProgramTagItemFormModel,
  ISystemBonusProgramTagItemModel,
} from 'models/BonusProgram/Tag/interfaces';
import { SystemBonusProgramTagItemModel } from 'models/BonusProgram/Tag/SystemBonusProgramTagModel';
import { usePromise } from 'utils/hooks/usePromise';
import {
  SystemBonusProgramTagItemFormComponent,
} from 'systemModule/components/References/BonusProgram/SystemBonusProgramTagItemForm';
import { Loader } from 'components/common/UI/Loaders';

export const SystemBonusProgramTagItemForm = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const history = useHistory();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreateTag = currentUser.hasPermission(UserAccessEnum.cBonusProgramTag);
  const canUpdateTag = currentUser.hasPermission(UserAccessEnum.wBonusProgramTag);

  const onSave = (async (modelId: number | null, modelToSve: ISystemBonusProgramTagItemFormModel) => {
    const trueOrError = modelId ?
      await updateSystemBonusProgramTagItem(modelId, modelToSve) :
      await createSystemBonusProgramTagItem(modelToSve);

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t(modelId ? 'system.bonusProgram.updated.tag' : 'system.bonusProgram.created.tag'));
        history.push('/system/references/bonus-program-tags');
      },
    );
  });

  const [model, modelIsLoading] = usePromise<ISystemBonusProgramTagItemModel>(
    () => getSystemBonusProgramTagItemById(numberId),
    !isNaN(numberId),
  );

  if (modelIsLoading) {
    return <Loader />;
  }

  return (
    <SystemBonusProgramTagItemFormComponent
      model={model || new SystemBonusProgramTagItemModel()}
      onSave={onSave}
      canUpdateTag={canUpdateTag}
      canCreateTag={canCreateTag}
    />
  );
};
