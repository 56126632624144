import React from 'react';
import { useSelector } from 'react-redux';
import { ReferralProgramComponent } from 'components/ReferralProgram/ReferralProgram';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { usePromise } from 'utils/hooks/usePromise';
import { getReferralProgramText } from 'api/referralProgram';
import { IPageReferralProgramModel } from 'models/References/PageReferralProgramModel';

export const ReferralProgram = () => {
  const [model, loading] = usePromise<IPageReferralProgramModel>(getReferralProgramText, true);
  const { referral_code } = useSelector(getCurrentUserSelector);
  return (
    <ReferralProgramComponent referralCode={referral_code} model={model} loading={loading} />
  );
};
