import { createSelector } from 'reselect';

import { RootReducer } from '../../../store/reducers';
import { storeName } from './reducer';
import { returnParams } from '../../../store/selectors';

const getMedicalList = (state: RootReducer) => state.system[storeName].medicalList;
const getMedicalIsLoading = (state: RootReducer) => state.system[storeName].medicalListIsLoading;


export const systemMedicalListSelector = createSelector([getMedicalList], returnParams);
export const systemMedicalIsLoadingListSelector = createSelector([getMedicalIsLoading], returnParams);
