import { AnyAction } from 'redux';

import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { onFail, onLoad, onLoading } from 'utils/reducer';
import { ENTERPRISES_GET_LIST_START, ENTERPRISES_GET_LIST_OK, ENTERPRISES_GET_LIST_FAIL } from './constants';

export type enterprisesReducerType = {
  enterprises: IEnterpriseModel[];
  enterprisesIsLoading: boolean;
}

const initialState: enterprisesReducerType = {
  enterprises: [],
  enterprisesIsLoading: true,
};

export const storeName = 'enterprises';

export const enterprisesReducer = {
  [storeName]: (state: enterprisesReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case ENTERPRISES_GET_LIST_START:
        return onLoading(state, storeName);
      case ENTERPRISES_GET_LIST_OK:
        return onLoad(state, payload, storeName);
      case ENTERPRISES_GET_LIST_FAIL:
        return onFail(state, storeName);

      default:
        return state;
    }
  },
};
