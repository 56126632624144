import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ISystemListEvent,
  ISystemListEventFilterType,
  ISystemListEvents,
} from '../../../models/Events/SystemListEvents';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { ICurrentUserModel, UserAccessEnum } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';
import { CustomTable } from '../../../components/common/Table';
import { CDate } from '../../../utils/CDate';
import { deleteSystemEvent } from '../../api/events';
import { handleErrors } from '../../../utils/errors';
import { SystemEventsFilter } from './Filter';
import { getParamsFromPagesModel } from '../../../models/WithPagesModel';


type Props = {
  data: ISystemListEvents;
  isLoading: boolean;
  getData: (filter: ISystemListEventFilterType) => void;
}

export const SystemEventsComponent = ({
  data, isLoading, getData,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cEvents);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dEvents);

  const onDelete = ({ id, name }: ISystemListEvent) => async () => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: 'событие', entityName: name }))) {
      handleErrors(
        await deleteSystemEvent(id),
        'delete',
        () => {
          toast.success(t('event.deleted'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  };

  return (
    <>
      <h3>
        {t('event.menu')}
      </h3>

      <SystemEventsFilter onReset={onResetFilter} onSubmit={onSubmitFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="events/create">{t('event.create')}</Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: t('event.name') },
          { name: t('event.date_start') },
          { name: t('event.author') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item: ISystemListEvent) => (
          <tr key={item.id}>
            <td>
              <Link to={`events/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`events/${item.id}`}>{item.name}</Link>
            </td>
            <td>
              {CDate.format(item.date_start, 'dd MMMM yyyy')}
            </td>
            <td>
              {item.author.fio}
            </td>
            <td>
              {canDelete && (
                <Button type="button" color="danger" onClick={onDelete(item)}>{t('common.form.delete')}</Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
