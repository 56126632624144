import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import {
  ConstructApplicationTemplateListModel,
} from 'models/ConstructApplication/ConstructApplicationTemplateListModel';
import {
  ConstructApplicationCategoryListModel,
} from 'models/ConstructApplication/ConstructApplicationCategoryListModel';
import { baseSaga } from 'store/baseSaga';
import {
  systemGetConstructApplicationCategoryListFailAction,
  systemGetConstructApplicationCategoryListOkAction,
  systemGetConstructApplicationTemplateListFailAction,
  systemGetConstructApplicationTemplateListOkAction,
} from 'systemModule/containers/ConstructApplication/actions';
import { httpGet } from 'utils/http';
import {
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_START,
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_START,
} from './constants';


function* systemConstructApplicationCategoryGetListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/construct-application/category', filter);

    yield put(systemGetConstructApplicationCategoryListOkAction(new ConstructApplicationCategoryListModel(response)));
  }, systemGetConstructApplicationCategoryListFailAction);
}

function* systemConstructApplicationTemplatesGetListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/construct-application/template', filter);

    yield put(systemGetConstructApplicationTemplateListOkAction(new ConstructApplicationTemplateListModel(response)));
  }, systemGetConstructApplicationTemplateListFailAction);
}


export function* systemConstructApplicationSagas() {
  yield takeLatest(SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_START, systemConstructApplicationCategoryGetListSaga);
  yield takeLatest(SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_START,
    systemConstructApplicationTemplatesGetListSaga);
}
