import { DefaultFilterType } from 'models/common';
import {
  IConstructApplicationCategoryListModel,
  IConstructApplicationTemplateListModel,
} from 'models/ConstructApplication/interfaces';
import { dataAction, dataErrorAction, dataSuccessAction } from 'store/actions';
import {
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_START,
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_OK,
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_FAIL,
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_START,
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_OK,
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_FAIL,
} from './constants';


export const systemGetConstructApplicationCategoryListStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_START,
)({ filter });
export const systemGetConstructApplicationCategoryListOkAction = (
  categoryList: IConstructApplicationCategoryListModel,
) => dataSuccessAction(
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_OK,
)({ categoryList });
export const systemGetConstructApplicationCategoryListFailAction = dataErrorAction(
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_FAIL,
);


export const systemGetConstructApplicationTemplateListStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_START,
)({ filter });
export const systemGetConstructApplicationTemplateListOkAction = (
  templateList: IConstructApplicationTemplateListModel,
) => dataSuccessAction(
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_OK,
)({ templateList });
export const systemGetConstructApplicationTemplateListFailAction = dataErrorAction(
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_FAIL,
);
