import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { CDate } from '../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../config/system';


export type ISystemPollsFilterType = {
  title?: string;
  status?: number;
}


export interface ISystemPollsItemModel {
  readonly id: number;
  readonly title: string;
  readonly created_at: Date;
  readonly ended_at: Date | null;
  readonly status: number;
  readonly voterCount: number; // кол-во голосовавших
}

export class SystemPollsItemModel implements ISystemPollsItemModel {
  readonly created_at: Date;
  readonly ended_at: Date | null;
  readonly id: number;
  readonly status: number;
  readonly title: string;
  readonly voterCount: number;

  constructor(params: any = {}) {
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.ended_at = params.ended_at ? CDate.parse(params.ended_at, BACKEND_DATE_FORMAT) : params.ended_at;
    this.id = params.id;
    this.status = params.status;
    this.title = params.title;
    this.voterCount = params.voterCount;
  }
}


export interface ISystemPollsModel extends IWithPages {
  readonly data: ISystemPollsItemModel[];
}

export class SystemPollsModel extends WithPagesModel implements ISystemPollsModel {
  data: ISystemPollsItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemPollsItemModel);
  }
}
