import { maxValueToGetAllDataWithPagination } from 'config/system';
import { PrivilegeListItem } from 'models/Privilege/PrivilegeListItem';
import { WithPagesResponseType } from 'models/WithPagesModel';
import { baseApiFn } from '../utils/baseApiFn';
import {
  httpDelete, HttpErrors, httpGet, httpPost, httpPut,
} from '../utils/http';
import {
  IPrivilegeItem,
  IPrivilegeList, IPrivilegeListItem,
  ISystemPrivilegeItem,
  PrivilegeListFilterType, SystemPrivilegeFormType,
} from '../models/Privilege/interfaces';
import { PrivilegeList } from '../models/Privilege/PrivilegeList';
import { PrivilegeItem } from '../models/Privilege/PrivilegeItem';
import { SystemPrivilegeItem } from '../models/Privilege/SystemPrivilegeItem';


/** Список льгот */
export const getPrivilegeList = async (filterData: PrivilegeListFilterType) => baseApiFn<IPrivilegeList>(async () => {
  const response = await httpGet('/public/privilege', filterData);
  return new PrivilegeList(response);
});

/** Данные льготы */
export const getPrivilegeItem = async (id: number, idEnterprise: number) => baseApiFn<IPrivilegeItem>(async () => {
  const response = await httpGet(`/public/privilege/${id}/${idEnterprise}`);
  return new PrivilegeItem(response);
});


/** Получить все льготы (1 запрос, без проверки макс. кол-ва) */
export const getSystemPrivilegeListAll = async (
  enterprise_ids?: number[],
) => baseApiFn<IPrivilegeListItem[]>(async () => {
  const response: WithPagesResponseType | HttpErrors = await httpGet('/system/privilege', {
    enterprise_ids,
    'per-page': maxValueToGetAllDataWithPagination,
  });

  if (response instanceof Error) {
    return [];
  }
  return response.data.map((privilege) => new PrivilegeListItem(privilege));
});

/** Данные льготы */
export const getSystemPrivilegeItem = async (id: number) => baseApiFn<ISystemPrivilegeItem>(async () => {
  const response = await httpGet(`/system/privilege/${id}`);
  return new SystemPrivilegeItem(response);
});

/** Создание льготы */
export const createSystemPrivilege = async (
  model: SystemPrivilegeFormType,
) => baseApiFn<ISystemPrivilegeItem>(async () => {
  const response = await httpPost('/system/privilege', model);
  return new SystemPrivilegeItem(response);
});

/** Редактирование льготы */
export const updateSystemPrivilege = async (
  id: number,
  model: SystemPrivilegeFormType,
) => baseApiFn<ISystemPrivilegeItem>(async () => {
  const response = await httpPut(`/system/privilege/${id}`, model);
  return new SystemPrivilegeItem(response);
});

/** удаление льготы */
export const deleteSystemPrivilegeItem = async (id: number) => baseApiFn<true>(
  httpDelete(`/system/privilege/${id}`),
);
