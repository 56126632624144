/* eslint-disable max-len */
import { dataAction, dataErrorAction, dataSuccessAction } from 'store/actions';
import {
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_FAIL,
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_OK,
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_START, SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_FAIL,
  SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_OK,
  SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_START,
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_FAIL,
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_OK,
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_START,
} from 'systemModule/containers/BonusProgram/constants';
import {
  SYSTEM_REFERENCES_GET_TAG_LIST_FAIL,
  SYSTEM_REFERENCES_GET_TAG_LIST_OK,
  SYSTEM_REFERENCES_GET_TAG_LIST_START,
} from 'systemModule/containers/References/BonusProgram/constants';
import {
  ISystemBonusProgramPrizeFilter,
  ISystemBonusProgramPrizeListModel,
} from 'models/BonusProgram/Prize/interfaces';
import {
  ISystemBonusProgramTagFilter,
  ISystemBonusProgramTagListModel,
} from 'models/BonusProgram/Tag/interfaces';
import {
  ISystemBonusProgramEventHistoryFilter,
  ISystemBonusProgramEventHistoryListModel,
} from 'models/BonusProgram/EventHistory/interfaces';
import {
  ISystemBonusProgramEventFilter,
  ISystemBonusProgramEventListModel,
} from 'models/BonusProgram/Event/interfaces';


export const systemBonusProgramGetPrizeListStartAction = (filter: ISystemBonusProgramPrizeFilter) => dataAction(
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_START,
)({ filter });
export const systemBonusProgramGetPrizeListOkAction = (
  prizeList: ISystemBonusProgramPrizeListModel,
) => dataSuccessAction(
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_OK,
)({ prizeList });
export const systemBonusProgramGetPrizeListFailAction = dataErrorAction(SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_FAIL);

export const systemRefGetTagListStartAction = (filter: ISystemBonusProgramTagFilter) => dataAction(
  SYSTEM_REFERENCES_GET_TAG_LIST_START,
)({ filter });
export const systemRefGetTagListOkAction = (
  tagList: ISystemBonusProgramTagListModel,
) => dataSuccessAction(
  SYSTEM_REFERENCES_GET_TAG_LIST_OK,
)({ tagList });
export const systemRefGetTagListFailAction = dataErrorAction(SYSTEM_REFERENCES_GET_TAG_LIST_FAIL);

export const systemBonusProgramGetEventHistoryListStartAction = (
  filter: ISystemBonusProgramEventHistoryFilter,
) => dataAction(
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_START,
)({ filter });
export const systemBonusProgramGetEventHistoryListOkAction = (
  eventHistoryList: ISystemBonusProgramEventHistoryListModel,
) => dataSuccessAction(
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_OK,
)({ eventHistoryList });
export const systemBonusProgramGetEventHistoryListFailAction = dataErrorAction(SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_FAIL);

export const systemBonusProgramGetEventListStartAction = (
  filter: ISystemBonusProgramEventFilter,
) => dataAction(
  SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_START,
)({ filter });
export const systemBonusProgramGetEventListOkAction = (
  eventList: ISystemBonusProgramEventListModel,
) => dataSuccessAction(
  SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_OK,
)({ eventList });
export const systemBonusProgramGetEventListFailAction = dataErrorAction(SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_FAIL);
