import { CDate } from '../../utils/CDate';

export interface ISystemEventPollResult {
  fio: string;
  enterprise: string;
  personnel_number: string;
  result: boolean;
  date_poll: Date;
}

export type ISystemEventPollResults = ISystemEventPollResult[];


export class SystemEventPollResult implements ISystemEventPollResult {
  date_poll: Date;
  enterprise: string;
  fio: string;
  personnel_number: string;
  result: boolean;

  constructor(params: any = {}) {
    this.date_poll = CDate.parse(params.date_poll, 'yyyy-MM-dd HH:mm');
    this.enterprise = params.enterprise;
    this.fio = params.fio;
    this.personnel_number = params.personnel_number;
    this.result = params.result;
  }
}
