import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import { toast } from 'react-toastify';
import { ParamTypes } from 'models/common';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  ISystemBonusProgramEventItemFormModel,
  ISystemBonusProgramEventItemModel,
} from 'models/BonusProgram/Event/interfaces';
import { getSystemBonusProgramEventItemById, updateSystemBonusProgramEventItem } from 'systemModule/api/bonusProgram';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';
import { Loader } from 'components/common/UI/Loaders';
import {
  SystemBonusProgramEventItemFormComponent,
} from 'systemModule/components/BonusProgram/BonusProgramEvents/SystemBonusProgramEventItemForm';
import { EntityNotFound } from 'components/Errors/404';

export const SystemBonusProgramEventItemForm = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const history = useHistory();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canEditEvent = currentUser.hasPermission(UserAccessEnum.wBonusProgramEvent);

  const onSave = async (modelId: number, modelToSave: ISystemBonusProgramEventItemFormModel) => {
    const trueOrError = await updateSystemBonusProgramEventItem(modelId, modelToSave);

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t('system.bonusProgram.updated.event'));
        history.push('/system/bonus-program/events');
      },
    );
  };

  const [model, modelIsLoading] = usePromise<ISystemBonusProgramEventItemModel>(
    () => getSystemBonusProgramEventItemById(numberId),
    !isNaN(numberId),
  );

  if (modelIsLoading) {
    return <Loader />;
  }
  if (isNaN(numberId) || !model) {
    return <EntityNotFound message={t('system.bonusProgram.event.notFound')} />;
  }
  return (
    <SystemBonusProgramEventItemFormComponent
      model={model}
      onSave={onSave}
      canEditEvent={canEditEvent}
    />
  );
};
