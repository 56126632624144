import { SystemBusStopSchedule } from 'models/Transport/SystemShedule';
import { IEnterpriseModel } from '../Enterprises/EnterpriseModel';
import { IWithPages, WithPagesModel } from '../WithPagesModel';


export interface ISystemRouteModel {
  readonly id: number;
  readonly name: string;
  readonly enterprise: IEnterpriseModel;
}

export interface ISystemRouteDetailModel extends ISystemRouteModel {
  readonly id: number;
  readonly name: string;
  readonly enterprise: IEnterpriseModel;
  readonly schedule: SystemBusStopSchedule[];
}

export class SystemRouteModel implements ISystemRouteModel {
  readonly id: number;
  readonly name: string;
  readonly enterprise: IEnterpriseModel;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name || '';
    this.enterprise = params.enterprise || {};
  }
}

export class SystemRouteDetailModel implements ISystemRouteDetailModel {
  readonly id: number;
  readonly name: string;
  readonly enterprise: IEnterpriseModel;
  readonly schedule: SystemBusStopSchedule[] = [];

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name || '';
    this.enterprise = params.enterprise || {};

    if (params.schedule) {
      this.schedule = Object
        .keys(params.schedule)
        .map((item) => new SystemBusStopSchedule(params.schedule[item]));
    }
  }
}

export interface ISystemRoutesListModel extends IWithPages {
  data: SystemRouteModel[];
}

export class SystemRoutesListModel extends WithPagesModel implements ISystemRoutesListModel {
  data: SystemRouteModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemRouteModel);
  }
}
