import { put } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { Action } from 'redux';

/**
 * базовый генератор для выполнения большинства действий
 *
 *
 * @param logicFunction {Function} - обычная логика генератора - обращение к апи, вызов побочных эффектов и тд
 * @param errorFunction {Function} - функция, которая вызовется в случае неудачи функции logicFunction
 *        - при передаче генератора - запускает
 *        - при передаче функции(экшена) - диспатчит (put)
 *        пока возвращает только тип AxiosError, но в теории может быть любая ошибка
 */
export function* baseSaga(logicFunction: () => void, errorFunction: (e: AxiosError) => Action | Generator) {
  try {
    yield logicFunction();
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);

    const errorFunctionType = errorFunction.constructor.name;

    if (errorFunctionType === 'Function') {
      yield put(errorFunction(e) as Action);
    }
    /** если передана функция(на проде был случай) или генератор - запустить их */
    if (errorFunctionType === 'GeneratorFunction' || typeof errorFunction === 'function') {
      yield errorFunction(e);
    }
  }
}
