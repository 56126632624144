import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { usePromise } from '../../utils/hooks/usePromise';
import { getNotification, setNotificationReadVal } from '../../api/notification';
import { INotificationModel, NotificationModel } from '../../models/Notification/Notifications';
import { Loader } from '../../components/common/UI/Loaders';
import { EntityNotFound } from '../../components/Errors/404';
import { NotificationOneComponent } from '../../components/Notifications/NotificationOneComponent';
import { handleErrors } from '../../utils/errors';


export const NotificationOne = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [model, loading, onUnmount] = usePromise<INotificationModel>(
    () => getNotification(numberId),
    !isNaN(numberId),
    [numberId],
  );
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  const [readLoading, setReadLoading] = useState(false);

  const setReadVal = useCallback(async (readVal = true) => {
    setReadLoading(true);

    handleErrors(
      await setNotificationReadVal(numberId, readVal),
      'save',
      undefined,
      () => {
        if (model) {
          model.setRead(readVal);
        }
        setReadLoading(false);
      },
    );
  }, [numberId, model]);

  if (loading) {
    return <Loader />;
  }
  if (model instanceof NotificationModel) {
    return <NotificationOneComponent model={model} setReadVal={setReadVal} readLoading={readLoading} />;
  }
  return <EntityNotFound message="Уведомление не найдено" />;
};

NotificationOne.whyDidYouRender = true;
