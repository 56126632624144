import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { UiInputSearch } from '../common/UI/InputSearch';
import { ReactComponent as FilterIcon } from '../../assets/img/filter.svg';
import { IEventFilterType } from '../../models/Events/EventModel';
import { UiModal } from '../common/UI/Modal';
import { DateRange } from '../common/Dates/DateRange';
import { getDateOrUndefined } from '../../utils/common';


type Props = {
  onChange: (data: IEventFilterType) => void;
}

type FormValues = {
  name: string;
  date_from: Date | null;
  date_to: Date | null;
}

const rangeNames = ['date_from', 'date_to'];

export const EventsFilter = ({ onChange }: Props) => {
  const {
    register, handleSubmit, watch, setValue, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      date_from: null,
      date_to: null,
    },
  });

  const { t } = useTranslation();

  const [openFilter, setOpenFilter] = useState(false);

  const onSearch = useCallback((data: FormValues) => {
    onChange({
      name: data.name.length ? data.name : undefined,
      date_from: getDateOrUndefined(data.date_from),
      date_to: getDateOrUndefined(data.date_to),
    });
    setOpenFilter(false);
  }, [onChange]);

  return (
    <div className="d-flex">
      <UiInputSearch handleSubmit={handleSubmit(onSearch)} name="name" register={register} />
      <div className="ml-3">
        <span className="filter" role="presentation" onClick={() => setOpenFilter(true)}>
          <FilterIcon />
        </span>
      </div>
      <UiModal isOpen={openFilter} toggle={() => setOpenFilter(false)} headerContent="Фильтр">
        <form autoComplete="off" className="modal-content" onSubmit={handleSubmit(onSearch)}>
          <div className="form-with-btn">
            <div className="form-wrapper_content">
              <DateRange
                names={rangeNames}
                label="Дата проведения"
                register={register}
                watch={watch}
                setValue={setValue}
              />
            </div>

            <div className="form-wrapper_btn">
              <div className="buttons-group buttons-group-responsive">
                <Button color="primary" type="submit">
                  {t('common.filter.apply')}
                </Button>
                <Button
                  color="primary"
                  outline
                  onClick={() => reset()}
                  className="btn--clear-filter"
                >
                  {t('common.form.clear_filter')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </UiModal>
    </div>
  );
};
