import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

import { customHistory } from 'customHistory';
import { store } from 'index';
import { routes } from 'routes';
import { errorHttpParamsAction } from 'store/actions';
import { projectBaseUrl } from 'utils/env';
import { HttpError } from 'utils/errors';
import { getIsCurrentUserExists } from 'store/currentUser/selectors';
import { clearCurrentUserDataAction } from 'store/currentUser/actions';


axios.defaults.baseURL = `${projectBaseUrl}/api`;
axios.defaults.withCredentials = true;

axios.interceptors.response.use((data) => {
  /** если ответ содержит эти заголовки, то принять их как данные о пагинации */
  if (data.headers['x-pagination-current-page']) {
    return {
      page: +data.headers['x-pagination-current-page'], // Текущая страница (номер)
      amountOfPages: +data.headers['x-pagination-page-count'], // Общее количество страниц
      limit: +data.headers['x-pagination-per-page'], // Количество элементов на странице
      data: data.data, // данные из ответа
    };
  }
  return data.data;
},
(error: AxiosError) => {
  const customError = new HttpError(error);
  // eslint-disable-next-line no-console
  console.error('http Error:', error);

  /** проблемы с сетью. сообщить об этом. что делать - непонятно */
  if (error.toString() === 'Error: Network Error') {
    customError.message = 'Проблемы с сетевым подключением';
    toast.error(customError.message);
    return Promise.reject(customError);
  }

  if (error.response?.status === 401) {
    /** если есть пользователь - послать на очистку данных и страницу логина */
    if (getIsCurrentUserExists(store.getState())) {
      store.dispatch(clearCurrentUserDataAction());
    }

    const { pathname } = customHistory.location;
    const isOuterRoute = routes.filter((route) => route.noLayout).findIndex((route) => route.path === pathname);

    /** с внешних роутов можно не кидать на /login */
    if (isOuterRoute === -1) {
      /** сохранить в истории изначальный введенный путь */
      customHistory.push(
        { pathname: `/login${customHistory.location.search}`, prevPathname: customHistory.location.pathname },
      );
    }

    // все ошибки с авторизацией - игнорить
    return Promise.reject(customError);
  }

  if (error.response?.data) {
    /**
       *  ошибки в человеческом виде.
       *  460, 400 - код невалидных данных
       */
    if ([460, 400, 462].includes(error.response.data.code)) {
      return Promise.reject(customError);
    }
    store.dispatch(errorHttpParamsAction(error.response.data));
  }

  return Promise.reject(error);
});

/** возможные ошибки, связанные с http */
export type HttpErrors = Error | AxiosError | HttpError;


type httpGetType = <R = any>(path: string, params?: any, data?: AxiosRequestConfig) => Promise<R | HttpErrors>;
/**
 * API метод get
 * @param path
 * @param params
 * @param config
 */
export const httpGet: httpGetType = (path, params = {}, config = {}) => axios.get(
  path,
  {
    params,
    ...config,
  },
);

type httpPostType = <R = any>(path: string, data?: any, headers?: any) => Promise<R | HttpErrors>;
/**
 * API метод post
 * @param path
 * @param data
 * @param headers
 */
export const httpPost: httpPostType = (path, data = {}, headers = {}) => axios.post(
  path,
  data,
  {
    headers: { ...headers },
  },
);

/**
 * API метод delete
 * @param path
 * @param data
 */
export const httpDelete = (path: string, data = {}) => axios.delete(
  path,
  {
    data,
  },
);

/**
 * API метод put
 * @param path
 * @param data
 * @param headers
 */
export const httpPut = (path: string, data = {}, headers = {}) => axios.put(
  path,
  data,
  {
    headers: { ...headers },
  },
);

export const httpAll = (requests: any[]) => axios.all(requests);
