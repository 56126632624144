import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { checkReplace, findValue } from 'utils/common';
import { UiSelect } from '../../../components/common/UI/Select';
import { convertModelToOptions, SimpleSelectOptionType } from '../../../utils/convertModelToOptions';
import { UiSystemForm } from '../../../components/common/UI/Form';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { IDepartmentItemModel } from '../../../models/References/DepartmentModel';
import {
  IApplicationResponsibleFormModel,
  IApplicationResponsibleModel,
} from '../../../models/Application/Responsible/interfaces';
import { getSettingResponsibleTypeSelect } from '../../../models/Application/Responsible/enums';

type Props = {
  enterprises: IEnterpriseModel[];
  departments: IDepartmentItemModel[];
  users: SimpleSelectOptionType[];
  model: IApplicationResponsibleModel;
  onSave: (id: number | null, model: IApplicationResponsibleFormModel) => void;
}

type FormValues = {
  enterprise: SimpleSelectOptionType<number>;
  user: SimpleSelectOptionType<number>;
  department: SimpleSelectOptionType<number>;
  type: SimpleSelectOptionType<string>;
}

export const SystemFeedbackResponsibleFormComponent = ({
  enterprises,
  departments,
  users,
  model,
  onSave,
}: Props) => {
  const {
    register, handleSubmit, errors, setValue,
    formState: { isSubmitting, dirtyFields, isValid }, getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise: isEmpty(model.enterprise) ? undefined : convertModelToOptions([model.enterprise])[0],
      user: isEmpty(model.user) ? undefined : findValue(users, model.user.id),
      department: isEmpty(model.department) ? undefined : convertModelToOptions([model.department])[0],
      type: checkReplace(findValue(getSettingResponsibleTypeSelect(), model.type.code), undefined),
    },
  });

  const { t } = useTranslation();

  const onSubmit = async ({
    enterprise, department, user, type,
  }: FormValues) => {
    const modelToSave: IApplicationResponsibleFormModel = {
      enterprise_id: enterprise.value,
      department_id: department.value,
      user_id: user.value,
      type: type.value,
    };

    onSave(model?.id || null, modelToSave);
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={!model.id}
    >
      <h3>{t(model.id ? 'feedback.form.update' : 'feedback.form.create')}</h3>

      <UiSelect
        defaultValue={getValues('type')}
        errors={errors}
        register={register}
        name="type"
        label={t('common.type')}
        setValue={setValue}
        options={getSettingResponsibleTypeSelect()}
        required
      />

      <UiSelect
        defaultValue={getValues('enterprise')}
        errors={errors}
        register={register}
        name="enterprise"
        label={t('common.enterprise')}
        setValue={setValue}
        options={convertModelToOptions(enterprises)}
        required
      />

      <UiSelect
        defaultValue={getValues('department')}
        errors={errors}
        register={register}
        name="department"
        label={t('common.department')}
        setValue={setValue}
        options={convertModelToOptions(departments)}
        required
      />

      <UiSelect
        defaultValue={getValues('user')}
        errors={errors}
        register={register}
        name="user"
        label={t('common.user')}
        setValue={setValue}
        options={users}
        required
      />
    </UiSystemForm>
  );
};
