import React from 'react';

import { Link, LinkProps } from 'react-router-dom';
import { ListElementBaseContent, ListElementBaseProps } from './ListElementBaseContent';

type Props = ListElementBaseProps & LinkProps & {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  disabled?: boolean;
}

export const ListElementLink = ({
  to, className, disabled = false, ...props
}: Props) => ((disabled) ? (
  <span className={`list-element disabled${className ? ` ${className}` : ''}`}>
    <ListElementBaseContent {...props} />
  </span>
) : (
  <Link to={to} className={`list-element${className ? ` ${className}` : ''}`}>
    <ListElementBaseContent {...props} />
  </Link>
));
