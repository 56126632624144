import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { IPrivilegeItem } from 'models/Privilege/interfaces';
import { ListElement } from '../../common/ListElement/ListElement';
import { UiBox } from '../../common/UI/UiBox';


type Props = {
  model: IPrivilegeItem;
}

export const ApplicationPrivilegeItemComponent = ({ model }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const onBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/applications/privilege');
    }
  };

  return (
    <>
      <div className="page-header long-text-header">
        <span className="back cursor-pointer" onClick={onBack} role="presentation" />
        {t('privilege.item.head.name', { name: model.name })}
      </div>
      <UiBox className="applications">
        <section className="form">
          <ListElement text={t('privilege.item.name')} isTitle className="ws-pw">
            <div className="item">{model.name}</div>
          </ListElement>
          <ListElement text={t('privilege.item.documents')} isTitle className="ws-pw">
            <div className="item">{model.documents}</div>
          </ListElement>
          <ListElement text={t('privilege.item.requirement')} isTitle className="ws-pw">
            <div className="item">{model.requirement}</div>
          </ListElement>
          <ListElement text={t('privilege.item.contact')} isTitle className="ws-pw">
            <div className="item">{model.contact}</div>
          </ListElement>
        </section>
      </UiBox>
    </>
  );
};
