import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import { SYSTEM_GET_EVENTS_FAIL, SYSTEM_GET_EVENTS_OK, SYSTEM_GET_EVENTS_START } from './constants';
import { ISystemListEvents, SystemListEventsModel } from '../../../models/Events/SystemListEvents';


export type systemEventsReducerType = {
  events: ISystemListEvents;
  eventsIsLoading: boolean;
}

const initialState: systemEventsReducerType = {
  events: new SystemListEventsModel(),
  eventsIsLoading: true,
};

export const storeName = 'events';

export const systemEventsReducer = {
  [storeName]: (state: systemEventsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_EVENTS_START:
        return onLoading(state, 'events');
      case SYSTEM_GET_EVENTS_OK:
        return onLoad(state, payload, 'events');
      case SYSTEM_GET_EVENTS_FAIL:
        return onFail(state, 'events');

      default:
        return state;
    }
  },
};
