import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { systemSportByIdSelector } from './selectors';
import { ISystemSportModel, SystemSportModel } from '../../../../models/References/SportModel';
import { getSystemSport } from '../../../api/references/sport';
import { SystemSportItemPageComponent } from '../../../components/References/Sports/SystemSportItemPage';

export const SystemSportItemPage = () => {
  const { id } = useParams<ParamTypes>();

  const [isLoading, setLoading] = useState(true);
  const [item, setItem] = useState<ISystemSportModel>(new SystemSportModel());
  const founditem: ISystemSportModel | undefined = useSelector(systemSportByIdSelector(id ? +id : 0));

  useEffect(() => {
    if (founditem) {
      setLoading(false);
      setItem(founditem);
    } else if (!isNaN(id)) {
      setItem(new SystemSportModel());
      setLoading(false);
    } else if (id) {
      getSystemSport(+id)
        .then((modelOrError) => {
          if (modelOrError instanceof Error) {
            toast.error('Ошибка загрузки данных');
            setLoading(false);
          } else {
            setItem(modelOrError);
            setLoading(false);
          }
        });
    } else {
      setItem(new SystemSportModel({}));
      setLoading(false);
    }
  }, [founditem, id]);

  return <SystemSportItemPageComponent item={item} isLoading={isLoading} />;
};
