export const NOTIFICATIONS_GET_LIST_START = '[NOTIFICATIONS] get notifications list [start]';
export const NOTIFICATIONS_GET_LIST_OK = '[NOTIFICATIONS] get notifications list [ok]';
export const NOTIFICATIONS_GET_LIST_FAIL = '[NOTIFICATIONS] get notifications list [fail]';

export const NOTIFICATIONS_GET_UNREAD_INIT_START = '[NOTIFiCATIONS] get unread notifications at start [start]';
export const NOTIFICATIONS_GET_UNREAD_INIT_OK = '[NOTIFiCATIONS] get unread notifications at start [ok]';
export const NOTIFICATIONS_GET_UNREAD_INIT_FAIL = '[NOTIFiCATIONS] get unread notifications at start [fail]';

export const NOTIFICATIONS_ON_NEW_NOTIFICATION = '[NOTIFICATIONS] on new notification';

export const NOTIFICATIONS_ON_ALL_READ = '[NOTIFICATIONS] on all notification read';

export const NOTIFICATIONS_ON_READ_NOTIFICATION_START = '[NOTIFICATIONS] on read notification [START]';
export const NOTIFICATIONS_ON_READ_NOTIFICATION_OK = '[NOTIFICATIONS] on read notification [OK]';
export const NOTIFICATIONS_ON_READ_NOTIFICATION_FAIL = '[NOTIFICATIONS] on read notification [FAIL]';
