import { FileType, prepareFilesOrNull } from 'models/common';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { ISystemPartnersCategoryListItemModel } from 'models/Partners/SystemPartnerCategoryListModel';


export interface PartnerItemModel {
  id: number;
  barcode: FileType | null;
  categories: ISystemPartnersCategoryListItemModel[];
  company_name: string;
  contacts: {
    title: string;
    value: string;
  }[];
  contacts_image: FileType | null;
  description: string;
  enterprises: IEnterpriseModel[];
  images: FileType[];
  is_active: boolean;
  is_recommended: boolean;
  is_favorite: boolean;
  logo: FileType | null;
  qr_code: FileType | null;
  ref_apply: string | null;
  ref_partner: string | null;
  ref_video: string | null;
  title: string;
}

export class PartnerItemModel implements PartnerItemModel {
  barcode: FileType | null;
  categories: ISystemPartnersCategoryListItemModel[];
  company_name: string;
  contacts: { title: string; value: string }[];
  contacts_image: FileType | null;
  description: string;
  enterprises: IEnterpriseModel[];
  id: number;
  images: FileType[];
  is_active: boolean;
  is_recommended: boolean;
  is_favorite: boolean;
  logo: FileType | null;
  qr_code: FileType | null;
  ref_apply: string | null;
  ref_partner: string | null;
  ref_video: string | null;
  title: string;

  constructor(params: any = {}) {
    this.barcode = prepareFilesOrNull(params.barcode);
    this.contacts_image = prepareFilesOrNull(params.contacts_image);
    this.images = params.images.map(prepareFilesOrNull);
    this.logo = prepareFilesOrNull(params.logo);
    this.qr_code = prepareFilesOrNull(params.qr_code);
    this.categories = params.categories;
    this.company_name = params.company_name;
    this.contacts = params.contacts;
    this.description = params.description;
    this.enterprises = params.enterprises;
    this.id = params.id;
    this.is_active = params.is_active;
    this.is_recommended = params.is_recommended;
    this.is_favorite = params.is_favorite;
    this.ref_apply = params.ref_apply;
    this.ref_partner = params.ref_partner;
    this.ref_video = params.ref_video;
    this.title = params.title;
  }
}
