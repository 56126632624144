import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getAllPartnersCategory } from 'api/partner';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import { usePromise } from 'utils/hooks/usePromise';
import { PartnersCategoriesListComponent } from 'components/Partners/categoriesList';
import { HttpErrors } from 'utils/http';
import { IPartnerCategoryListModel } from 'models/Partners/PartnerCategoryListModel';


export const PartnersCategoriesList = () => {
  const { t } = useTranslation();

  const [model, modelLoading, onUnmount] = usePromise<IPartnerCategoryListModel | HttpErrors>(
    getAllPartnersCategory,
    true,
  );
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  if (modelLoading) {
    return <Loader />;
  }
  if (model === null || model instanceof Error) {
    return <EntityNotFound message={t('partners.categories.404')} />;
  }
  return <PartnersCategoriesListComponent model={model} />;
};
