import { IEditUSerSettings, ISystemUserModel } from 'models/User/interfaces';
import { SystemUserModel } from 'models/User/SystemUserModel';
import { ISystemUserListModel, SystemUsersFilterType } from 'models/Users/interfaces';
import { SystemUserListModel } from 'models/Users/SystemUserListModel';
import { baseApiFn } from 'utils/baseApiFn';
import { httpGet, httpPut } from 'utils/http';

export const getSystemUser = async (id: number) => baseApiFn<ISystemUserModel>(async () => {
  const response = await httpGet(`system/user/${id}`);

  return new SystemUserModel(response);
});

/** Изменить настройки пользователя */
export const updateSystemUserSettings = async (id: number, data: IEditUSerSettings) => baseApiFn<true>(
  httpPut(`system/user/settings/${id}`, data),
);

/** Список пользователей */
export const getSystemUsersList = async (
  filter: SystemUsersFilterType = {},
) => baseApiFn<ISystemUserListModel>(async () => {
  const response = await httpGet(`system/user/list`, filter);

  return new SystemUserListModel(response);
});
