import { createSelector } from 'reselect';
import { RootReducer } from '../../store/reducers';
import { storeName } from './reducer';
import { returnParams } from '../../store/selectors';

const getBusStops = (state: RootReducer) => state[storeName].busStops;
const getBusStopsIsLoading = (state: RootReducer) => state[storeName].busStopsIsLoading;

export const getBusStopsSelector = createSelector([getBusStops], returnParams);
export const getBusStopsIsLoadingSelector = createSelector([getBusStopsIsLoading], returnParams);
