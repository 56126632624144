import { dataAction, dataErrorAction, dataSuccessAction } from '../../store/actions';
import { EVENT_GET_LIST_FAIL, EVENT_GET_LIST_OK, EVENT_GET_LIST_START } from './constants';
import { DefaultFilterType } from '../../models/common';
import { IEventsModel } from '../../models/Events/EventModel';


export const eventsGetListStartAction = (append: boolean, filter?: DefaultFilterType) => dataAction(
  EVENT_GET_LIST_START,
)({ filter, append });
export const eventsGetListOkAction = (events: IEventsModel, append: boolean) => dataSuccessAction(
  EVENT_GET_LIST_OK,
)({ events, append });
export const eventsGetListFailAction = dataErrorAction(EVENT_GET_LIST_FAIL);
