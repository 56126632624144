import { AnyAction } from 'redux';

import { isProductionBuild } from 'utils/env';
import { onFail, onLoad, onLoading } from 'utils/reducer';
import {
  NOTIFICATIONS_ON_NEW_NOTIFICATION,
  NOTIFICATIONS_ON_READ_NOTIFICATION_OK,
} from 'containers/Notifications/constants';
import {
  GLOBAL_GET_UNREAD_INFO_FAIL,
  GLOBAL_GET_UNREAD_INFO_OK,
  GLOBAL_GET_UNREAD_INFO_START,
  GLOBAL_LOADER_HIDE,
  GLOBAL_LOADER_SHOW, GLOBAL_SET_APP_VERSION, GLOBAL_SET_ONLINE, GLOBAL_SET_DISPLAYING_APP_GUIDE,
} from './constants';


export type globalReducerType = {
  showLoader: boolean;

  unreadInfo: {
    notify: number;
    polls: number;
  };
  unreadInfoIsLoading: boolean;

  /** данные об окружении, в котором запущено приложение */
  environment: 'prod' | 'other';
  appVersion: string | null;

  /** состояние приложения */
  isOnline: boolean;

  isOpenApplicationGuide: boolean;
}


const initialState: globalReducerType = {
  showLoader: false,

  unreadInfo: {
    notify: 0,
    polls: 0,
  },
  unreadInfoIsLoading: true,

  environment: isProductionBuild ? 'prod' : 'other',
  appVersion: null,

  isOnline: true,

  isOpenApplicationGuide: false,
};

export const storeName = 'global';

export const globalReducer = {
  [storeName]: (state: globalReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case GLOBAL_LOADER_SHOW:
        return {
          ...state,
          showLoader: true,
        };
      case GLOBAL_LOADER_HIDE:
        return {
          ...state,
          showLoader: false,
        };

      case GLOBAL_GET_UNREAD_INFO_START:
        return onLoading(state, 'unreadInfo');
      case GLOBAL_GET_UNREAD_INFO_OK:
        return onLoad(state, payload, 'unreadInfo');
      case GLOBAL_GET_UNREAD_INFO_FAIL:
        return onFail(state, 'unreadInfo');

      /** кол-во уведомлений. добавить при новом, отнять при прочтении */
      case NOTIFICATIONS_ON_NEW_NOTIFICATION: {
        return {
          ...state,
          unreadInfo: {
            ...state.unreadInfo,
            notify: state.unreadInfo.notify + 1,
          },
        };
      }
      case NOTIFICATIONS_ON_READ_NOTIFICATION_OK: {
        const notifyAmount = state.unreadInfo.notify - 1;
        return {
          ...state,
          unreadInfo: {
            ...state.unreadInfo,
            notify: notifyAmount < 0 ? 0 : notifyAmount,
          },
        };
      }

      case GLOBAL_SET_APP_VERSION: {
        return {
          ...state,
          appVersion: payload.app_version,
        };
      }

      case GLOBAL_SET_ONLINE: {
        return {
          ...state,
          isOnline: payload.isOnline,
        };
      }

      case GLOBAL_SET_DISPLAYING_APP_GUIDE: {
        return {
          ...state,
          isOpenApplicationGuide: payload.isOpenApplicationGuide,
        };
      }

      default:
        return state;
    }
  },
};
