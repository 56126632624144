import { dataAction, dataErrorAction, dataSuccessAction } from 'store/actions';
import { INotificationsModel, NotificationsFilterType } from 'models/Notification/Notifications';
import { ISocketNotification } from 'models/Notification/SocketNotification';
import {
  NOTIFICATIONS_GET_LIST_FAIL,
  NOTIFICATIONS_GET_LIST_OK,
  NOTIFICATIONS_GET_LIST_START,
  NOTIFICATIONS_GET_UNREAD_INIT_FAIL,
  NOTIFICATIONS_GET_UNREAD_INIT_OK,
  NOTIFICATIONS_GET_UNREAD_INIT_START, NOTIFICATIONS_ON_ALL_READ,
  NOTIFICATIONS_ON_NEW_NOTIFICATION, NOTIFICATIONS_ON_READ_NOTIFICATION_FAIL, NOTIFICATIONS_ON_READ_NOTIFICATION_OK,
  NOTIFICATIONS_ON_READ_NOTIFICATION_START,
} from './constants';


export const notificationsGetListStartAction = (append: boolean, filter?: NotificationsFilterType) => dataAction(
  NOTIFICATIONS_GET_LIST_START,
)({ filter, append });
export const notificationsGetListOkAction = (notifications: INotificationsModel, append: boolean) => dataSuccessAction(
  NOTIFICATIONS_GET_LIST_OK,
)({ notifications, append });
export const notificationsGetListFailAction = dataErrorAction(NOTIFICATIONS_GET_LIST_FAIL);


export const notificationsGetInitStartAction = (ids?: number[]) => dataAction(
  NOTIFICATIONS_GET_UNREAD_INIT_START,
)({ ids });
export const notificationsGetInitOkAction = (unreadInitNotifications: ISocketNotification[]) => dataSuccessAction(
  NOTIFICATIONS_GET_UNREAD_INIT_OK,
)({ unreadInitNotifications });
export const notificationsGetInitFailAction = dataErrorAction(NOTIFICATIONS_GET_UNREAD_INIT_FAIL);


export const onNewNotificationAction = (notification: ISocketNotification) => dataAction(
  NOTIFICATIONS_ON_NEW_NOTIFICATION,
)({ notification });

export const onAllNotificationsReadAction = dataAction(NOTIFICATIONS_ON_ALL_READ);

export const onReadNotificationStartAction = (notification: ISocketNotification) => dataAction(
  NOTIFICATIONS_ON_READ_NOTIFICATION_START,
)({ notification });
export const onReadNotificationOkAction = (notification: ISocketNotification) => dataAction(
  NOTIFICATIONS_ON_READ_NOTIFICATION_OK,
)({ notification });
export const onReadNotificationFailAction = dataErrorAction(NOTIFICATIONS_ON_READ_NOTIFICATION_FAIL);
