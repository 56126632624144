import { IPollModel, PollModel } from './PollModel';


export interface ISystemPollModelQuestion {
  title: string;
  type: number;
  answers?: string[];
}

export interface ISystemPollFormModel {
  readonly title: string;
  readonly enterprises: number[];
  readonly is_critical: boolean;
  readonly is_anonymous: boolean;
  readonly ended_at?: string;
  readonly questions: ISystemPollModelQuestion[];
}


export interface ISystemPollModelResult {
  questions: string[];
  users: {
    username: string;
    answers: string[][] | string[];
  }[];
  statistics: {
    totalUsers: number;
    questions: {
      title: string;
      answers: {
        title: string;
        amount: number;
        percent: number;
      }[];
    }[];
  };
}


export interface ISystemPollModel extends IPollModel {
  readonly results: ISystemPollModelResult | null;
  readonly author: {id: number; fio: string};

  /** кол-во проголосовавших сотрудников */
  readonly votedUsersAmount: number;
}

export class SystemPollModel extends PollModel implements ISystemPollModel {
  readonly results: ISystemPollModelResult | null;
  readonly author: {id: number; fio: string};

  constructor(params: any = {}) {
    super(params);

    this.results = Array.isArray(params.results) ? null : params.results;
    this.author = params.author;
  }

  get votedUsersAmount() {
    return this.results?.statistics.totalUsers || 0;
  }
}
