import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDataPage } from 'utils/hooks/useDataPage';
import {
  ISystemBonusProgramPrizeItemModel,
  ISystemBonusProgramPrizeListModel,
  ISystemBonusProgramPrizeFilter,
} from 'models/BonusProgram/Prize/interfaces';
import { CustomTable } from 'components/common/Table';
import { SystemBonusProgramPrizeListFilter } from 'systemModule/components/BonusProgram/BonusProgramPrizes/Filter';

type Props = {
  data: ISystemBonusProgramPrizeListModel;
  isLoading: boolean;
  getData: (filter: ISystemBonusProgramPrizeFilter) => void;
  canCreatePrize: boolean;
  canDeletePrize: boolean;
  deletePrize: (model: ISystemBonusProgramPrizeItemModel) => () => void;
}

export const SystemBonusProgramPrizeListComponent = ({
  data, isLoading, getData, canCreatePrize, canDeletePrize, deletePrize,
}: Props) => {
  const { t } = useTranslation();

  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  return (
    <>
      <h3>Список призов</h3>

      <SystemBonusProgramPrizeListFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={canCreatePrize && (
          <Button
            tag={Link}
            color="primary"
            to="/system/bonus-program/prize/create"
          >
            {t('system.bonusProgram.create.prize')}
          </Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: 'Приз' },
          { name: 'Стоимость реальная' },
          { name: 'Стоимость в СДС-коинах' },
          { name: 'Действия' },
        ]}
      >
        {data.data.map((prizeItem) => (
          <tr key={prizeItem.id}>
            <td>
              <Link to={`/system/bonus-program/prize/${prizeItem.id}`}>{prizeItem.id}</Link>
            </td>
            <td>
              <Link to={`/system/bonus-program/prize/${prizeItem.id}`}>{prizeItem.title}</Link>
            </td>
            <td>{Number(prizeItem.priceReal)}</td>
            <td>{Number(prizeItem.priceSdsCoin)}</td>
            <td>
              {
                canDeletePrize && (
                  <Button
                    className="w-100 mb-4"
                    color="danger"
                    onClick={deletePrize(prizeItem)}
                  >
                    {t('common.form.delete')}
                  </Button>
                )
              }
              <Button
                className="w-100"
                color="primary"
                tag={Link}
                to={`/system/bonus-program/prize/${prizeItem.id}`}
              >
                Подробнее
              </Button>
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
