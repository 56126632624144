import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { ISystemEducationModel, SystemEducationFormType } from 'models/Education/interfaces';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiDatePicker } from 'components/common/Dates/UiDatePicker';
import { UiFileUploaderSystem } from 'components/common/FileUploader';
import { defaultImgExtensions } from 'config/system';
import { CDate } from 'utils/CDate';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';


type Props = {
  model: ISystemEducationModel;
  onSave: (d: SystemEducationFormType) => void;
  canUpdate: boolean;
}

type FormValues = {
  name: string;
  schedule: string | null;
  date_start: Date | null;
  info: string;
  place: string;
  image: null | File;
  contacts: string;
}

export const SystemEducationFormComponent = ({ model, onSave, canUpdate }: Props) => {
  const { t } = useTranslation();

  const createMode = !model.id;

  const {
    register, handleSubmit, watch, errors, setValue,
    formState: { isSubmitting, dirtyFields, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
      schedule: model.schedule,
      date_start: model.date_start,
      info: model.info,
      place: model.place,
      contacts: model.contacts,
    },
  });

  useEffect(() => {
    /** регистрация полей, управляемых вручную */
    register('image');
  }, [register]);

  const onSubmit = async (data: FormValues) => {
    if (!canUpdate) {
      return;
    }

    const modelToSave: SystemEducationFormType = {
      name: data.name,
      date_start: CDate.format(data.date_start ? data.date_start : new Date(), 'dd.MM.yyyy HH:mm'),
      place: data.place,
      contacts: data.contacts,
      schedule: data.schedule ? data.schedule : null,
      info: data.info ? data.info : null,
      image: null,
    };

    /** разобраться с image */
    if (data.image) {
      if (data.image.name !== model?.image.name) {
        /** загрузить и обновить */
        const main_image = await new UploadFiles([data.image]).upload();

        if (main_image instanceof Error) {
          toast.error(i18next.t('common.form.errors.save'));
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        modelToSave.image = main_image[0].file_name;
      } else {
        modelToSave.image = model?.image.name;
      }
    }

    onSave(modelToSave);
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode
      showButtons={canUpdate}
    >
      <h3>{t(createMode ? 'education.form.create' : 'education.form.update')}</h3>

      <UiDatePicker
        watch={watch}
        name="date_start"
        setValue={setValue}
        register={register}
        label={t('education.date_start')}
        placeholderText={t('education.date_start.short')}
        showTimeSelect
        required
      />

      <UiSystemInput
        type="textarea"
        name="schedule"
        errors={errors}
        register={register}
        label={t('education.schedule')}
      />

      <UiSystemInput
        name="name"
        errors={errors}
        register={register}
        label={t('education.name')}
        required
      />

      <UiSystemInput
        type="textarea"
        name="info"
        errors={errors}
        register={register}
        label={t('education.info')}
      />

      <UiSystemInput
        type="textarea"
        name="place"
        errors={errors}
        register={register}
        label={t('education.place')}
        required
      />

      <FormGroup>
        <strong>
          {t('education.image')}
        </strong>
        <UiFileUploaderSystem
          initFileTypes={model ? [model.image] : []}
          maxFilesCount={1}
          onChange={(files: File[]) => setValue('image', files[0])}
          accept={defaultImgExtensions}
        />
      </FormGroup>

      <UiSystemInput
        type="textarea"
        name="contacts"
        errors={errors}
        register={register}
        label={t('education.contacts')}
        required
      />

    </UiSystemForm>
  );
};
