import { IReportInsurance } from './interfaces';


export class ReportInsurance implements IReportInsurance {
  readonly user_count: number;
  readonly items: {
    user_count: number;
    id: number;
    name: string;
  }[];

  constructor(params: any = {}) {
    this.user_count = +params.user_count;
    this.items = params.items;
  }
}
