import { createSelector } from 'reselect';
import { RootReducer } from '../../store/reducers';
import { storeName } from './reducer';
import { returnParams } from '../../store/selectors';

const getNews = (state: RootReducer) => state[storeName].news;
const getNewsIsLoading = (state: RootReducer) => state[storeName].newsIsLoading;

const getCategories = (state: RootReducer) => state[storeName].categories;
const getCategoriesIsLoading = (state: RootReducer) => state[storeName].categoriesIsLoading;


export const getNewsSelector = createSelector([getNews], returnParams);
export const getNewsIsLoadingSelector = createSelector([getNewsIsLoading], returnParams);

export const getCategoriesSelector = createSelector([getCategories], returnParams);
export const getCategoriesIsLoadingSelector = createSelector([getCategoriesIsLoading], returnParams);
