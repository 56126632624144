import React from 'react';

type Props = {
  thisSectionName: string;
  children: JSX.Element;
}

export const SystemParticularApplicationWrapper = ({ thisSectionName, children }: Props) => (
  <>
    {React.cloneElement(children, { thisSectionName })}
  </>
);
