import { AnyAction } from 'redux';
import { onLoading, onFail, onLoad } from '../../utils/reducer';
import {
  GET_BUS_STOP_TIMETABLE_LIST_FAIL,
  GET_BUS_STOP_TIMETABLE_LIST_OK,
  GET_BUS_STOP_TIMETABLE_LIST_START,
} from './constants';
import {
  BusStopTimetableItemModel,
  IBusStopTimetableItem,
} from '../../models/BusStopTimetable/BusStopTimetable';

export type BusStopTimetableReducerType = {
  busStopTimetable: IBusStopTimetableItem;
  busStopTimetableIsLoading: boolean;
}

const initialState: BusStopTimetableReducerType = {
  busStopTimetable: new BusStopTimetableItemModel(),
  busStopTimetableIsLoading: true,
};

export const storeName = 'busStopTimetable';

export const busStopTimetableReducer = {
  [storeName]: (state: BusStopTimetableReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case GET_BUS_STOP_TIMETABLE_LIST_START:
        return onLoading(state, 'busStopTimetable');
      case GET_BUS_STOP_TIMETABLE_LIST_OK:
        return onLoad(state, payload, 'busStopTimetable');

      case GET_BUS_STOP_TIMETABLE_LIST_FAIL:
        return onFail(state, 'busStopTimetable');
      default:
        return state;
    }
  },
};
