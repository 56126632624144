/** true если сборка для прода */
export const isProductionBuild: boolean = process.env.REACT_APP_PROD === 'true';

/** true если сборка не для прода */
export const isNotProductionBuild = !isProductionBuild;

/** url проекта */
export const projectBaseUrl: string = process.env.REACT_APP_BASE_URL || '';


/**
 * проверка на очень обязательный параметр настройки
 * в тестах можно не проверять
 */
if (process.env.NODE_ENV !== 'test' && !process.env.REACT_APP_BASE_URL) {
  throw new Error('Базовый url проекта не установлен');
}
