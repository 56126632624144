import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import { ISystemEducationListModel } from '../../../models/Education/interfaces';
import { SystemEducationListModel } from '../../../models/Education/SystemEducationListModel';
import {
  SYSTEM_EDUCATION_GET_LIST_FAIL,
  SYSTEM_EDUCATION_GET_LIST_OK,
  SYSTEM_EDUCATION_GET_LIST_START,
} from './constants';


export type systemEducationReducerType = {
  educationList: ISystemEducationListModel;
  educationListIsLoading: boolean;
}

const initialState: systemEducationReducerType = {
  educationList: new SystemEducationListModel(),
  educationListIsLoading: true,
};

export const storeName = 'education';

export const systemEducationReducer = {
  [storeName]: (state: systemEducationReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_EDUCATION_GET_LIST_START:
        return onLoading(state, 'educationList');
      case SYSTEM_EDUCATION_GET_LIST_OK:
        return onLoad(state, payload, 'educationList');
      case SYSTEM_EDUCATION_GET_LIST_FAIL:
        return onFail(state, 'educationList');

      default:
        return state;
    }
  },
};
