import React from 'react';

import { IReportApplicationEnterprise } from '../../../../models/Reports/interfaces';
import { CollapseCardBlock } from '../common/CollapseCardBlock';
import { CardBlock } from '../common/CardBlock';


type Props = {
  model: IReportApplicationEnterprise;
}

export const SystemReportApplicationEnterpriseComponent = ({ model }: Props) => (
  <>
    {model.items.map((item) => (
      <React.Fragment key={item.id}>
        <CollapseCardBlock name={item.name} count={item.application_count}>
          {item.types.map((type) => (
            <CollapseCardBlock key={type.id} name={type.name} count={type.application_count}>
              {type.statuses.map((status) => (
                <CardBlock key={status.id} name={status.name} count={status.application_count} />
              ))}
            </CollapseCardBlock>
          ))}
        </CollapseCardBlock>
      </React.Fragment>
    ))}
  </>
);
