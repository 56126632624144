import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import { customHistory } from 'customHistory';
import { IInsuranceModel } from '../../../../models/Insurances/InsurancesModel';
import { UiSystemInput } from '../../../../components/common/UI/Input';
import { createSystemRefInsurance, updateSystemRefInsurance } from '../../../api/references/insurance';
import { HttpErrors } from '../../../../utils/http';
import { handleErrors } from '../../../../utils/errors';
import { ICurrentUserModel, UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import { UiSystemForm } from '../../../../components/common/UI/Form';


const saveForm = async (createMode: boolean, data: FormValues, item: IInsuranceModel) => {
  const trueOrError: true | HttpErrors = (createMode) ?
    await createSystemRefInsurance(data) :
    await updateSystemRefInsurance(item.id, data);

  handleErrors(
    trueOrError,
    'save',
    () => {
      toast.success(i18next.t(`system.references.insurance.${createMode ? 'created' : 'updated'}`));
      customHistory.push('/system/references/insurance');
    },
  );
};


type Props = {
  item: IInsuranceModel;
}

type FormValues = {
  name: string;
}

export const SystemRefInsuranceFormComponent = ({ item }: Props) => {
  const { t } = useTranslation();

  const createMode = !item.id;

  const {
    register, handleSubmit, errors, formState: { isValid, isSubmitting, dirtyFields },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: item.name,
    },
  });

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wReferenceInsurance);

  const onSubmit = (data: FormValues) => (canUpdate ? saveForm(createMode, data, item) : Promise.reject());

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
    >
      <h3>{createMode ? 'Создание' : 'Редактирование'} страховой программы</h3>

      <UiSystemInput
        errors={errors}
        register={register}
        name="name"
        label={t('system.references.insurance.name')}
        required
      />
    </UiSystemForm>
  );
};
