import { createSelector } from 'reselect';
import { RootReducer } from '../../../../store/reducers';
import { storeName } from './reducers';
import { returnParams } from '../../../../store/selectors';

const getSports = (state: RootReducer) => state.system[storeName].sports;
const getSportsIsLoading = (state: RootReducer) => state.system[storeName].sportsIsLoading;
const getSportById = (id: number) => (state: RootReducer) => state.system[storeName].sports.data
  .find((sport) => sport.id === id);

export const systemSportsSelector = createSelector([getSports], returnParams);
export const systemSportsIsLoadingSelector = createSelector([getSportsIsLoading], returnParams);
export const systemSportByIdSelector = createSelector([getSportById], returnParams);
