import React from 'react';

import { IApplicationMyListModel } from '../../models/Application/interfaces';
import { CDate } from '../../utils/CDate';
import { ListElementLink } from '../common/ListElement/ListElementLink';

type Props = {
  item: IApplicationMyListModel;
}

const getStatusType = (id: number) => {
  switch (id) {
    case 5: {
      return ' new';
    }
    case 15: case 10: {
      return ' inprogress';
    }
    case 25: case 20: {
      return ' rejected';
    }
    case 30: {
      return ' complete';
    }
    default: {
      return '';
    }
  }
};

export const ApplicationItemComponent = ({ item }: Props) => (
  <ListElementLink
    text={item.type.name}
    isTitle
    isFollow
    to={`/applications/${item.id}/${item.type.id}`}
  >
    <div className="item">&#8470;{item.id}</div>
    <div className="item">{CDate.format(item.created_at, 'dd.MM.yyyy/HH:mm')}</div>
    <div className={`item status${getStatusType(item.status.id)}`}>{item.status.name}</div>
  </ListElementLink>
);
