import { IWithPages, WithPagesModel } from '../WithPagesModel';

export interface IEnterpriseModel {
  readonly id: number;
  readonly name: string;
}

export class EnterpriseModel implements IEnterpriseModel {
  readonly id: number;
  readonly name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}


export interface IEnterpriseListModel extends IWithPages {
  data: IEnterpriseModel[];
}

export class EnterpriseListModel extends WithPagesModel implements IEnterpriseListModel {
  data: EnterpriseModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, EnterpriseModel);
  }
}
