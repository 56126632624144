import React, { CSSProperties, useMemo } from 'react';
import Scrollbar from 'react-scrollbars-custom';
// eslint-disable-next-line import/no-unresolved
import { ScrollState } from 'react-scrollbars-custom/dist/types/types';


const styles = { width: '100%', height: '100%' };


type Props = {
  children: React.ReactNode[] | React.ReactElement | string;

  className?: string;
  contentStyle?: CSSProperties;
  onScrollStop?: (s: ScrollState) => void;
  onScrollStart?: (s: ScrollState) => void;
}

export const UiBox = ({
  children, className = '', contentStyle, onScrollStop, onScrollStart,
}: Props) => {
  const contentProps = useMemo(() => ({ style: contentStyle }), [contentStyle]);

  return (
    <div className={`box${className ? ` ${className}` : ''}`}>
      <Scrollbar
        style={styles}
        contentProps={contentProps}
        onScrollStop={onScrollStop}
        onScrollStart={onScrollStart}
      >
        {children}
      </Scrollbar>
    </div>
  );
};
