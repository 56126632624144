import React, { FormEvent, useState } from 'react';
import Select, { ValueType } from 'react-select';

import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { convertModelToOptions, SelectOptionType } from 'utils/convertModelToOptions';
import { useTranslation } from 'react-i18next';
import { Header } from '../Main/Header';
import { Loader } from '../common/UI/Loaders';


type Props = {
  enterprises: IEnterpriseModel[];
  isLoading: boolean;
  onSubmit: (id: number) => void;
}

export const EnterpriseSelectionComponent = ({ enterprises, isLoading, onSubmit }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({ value: 0, label: t('ui.select.select_placeholder.without_main_enterprise') });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(value.value);
  };

  return (
    <>
      <Header showSlogan />
      <div className="box register">
        <section className="form-invert">
          <div className="container">
            {isLoading ?
              <Loader /> : (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="company">Выберите предприятие для входа</label>
                    <Select
                      classNamePrefix="enterprise-select"
                      value={(value as SelectOptionType)}
                      id="company"
                      options={convertModelToOptions(enterprises)}
                      onChange={(currentValue: ValueType<SelectOptionType, any>) => {
                        if (currentValue) {
                          setValue(currentValue as SelectOptionType);
                        }
                      }}
                      isSearchable={false}
                    />
                  </div>
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                  >
                    Продолжить
                  </button>
                </form>
              )}
          </div>
        </section>
      </div>
    </>
  );
};
