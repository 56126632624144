import { ISystemPartnerListModel, SystemPartnerListModel } from 'models/Partners/SystemPartnerListModel';
import {
  ISystemPartnersCategoryListModel,
  SystemPartnersCategoryListModel,
} from 'models/Partners/SystemPartnerCategoryListModel';
import { AnyAction } from 'redux';

import {
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_FAIL,
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_OK,
  SYSTEM_GET_PARTNERS_CATEGORY_LIST_START,
  SYSTEM_GET_PARTNERS_LIST_FAIL,
  SYSTEM_GET_PARTNERS_LIST_OK, SYSTEM_GET_PARTNERS_LIST_START,
} from 'systemModule/containers/Partners/constants';
import { onFail, onLoad, onLoading } from 'utils/reducer';


export type systemPartnersReducerType = {
  categoryList: ISystemPartnersCategoryListModel;
  categoryListIsLoading: boolean;

  partnersList: ISystemPartnerListModel;
  partnersListLoading: boolean;
}

const initialState: systemPartnersReducerType = {
  categoryList: new SystemPartnersCategoryListModel(),
  categoryListIsLoading: false,

  partnersList: new SystemPartnerListModel(),
  partnersListLoading: false,
};

export const storeName = 'partners';

export const systemPartnersReducer = {
  [storeName]: (state: systemPartnersReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_PARTNERS_CATEGORY_LIST_START:
        return onLoading(state, 'categoryList');
      case SYSTEM_GET_PARTNERS_CATEGORY_LIST_OK:
        return onLoad(state, payload, 'categoryList');
      case SYSTEM_GET_PARTNERS_CATEGORY_LIST_FAIL:
        return onFail(state, 'categoryList');

      case SYSTEM_GET_PARTNERS_LIST_START:
        return onLoading(state, 'partnersList');
      case SYSTEM_GET_PARTNERS_LIST_OK:
        return onLoad(state, payload, 'partnersList');
      case SYSTEM_GET_PARTNERS_LIST_FAIL:
        return onFail(state, 'partnersList');

      default:
        return state;
    }
  },
};
