import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';
import { customHistory } from 'customHistory';
import {
  ConstructApplicationTypeFormFieldsType, ConstructApplicationTypeSaveFieldsType,
  IConstructApplicationTemplateListItemModel,
  IConstructApplicationTypeModel,
} from 'models/ConstructApplication/interfaces';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  getConstructApplicationTemplateAll,
  getConstructApplicationTypeById, updateConstructApplicationFields,
} from 'systemModule/api/construct-application';
import {
  ConstructApplicationTypeFieldsComponent,
} from 'systemModule/components/ConstructApplication/Type/ConstructApplicationTypeFields';
import { convertModelToOptions } from 'utils/convertModelToOptions';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';
import { HttpErrors } from 'utils/http';


export const ConstructApplicationTypeFields = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wApplication);

  const [modelFields, setModelFields] = useState<IConstructApplicationTypeModel | null>(null);

  /** получить тип заявки по ид и список шаблонов */
  const [models, modelsLoading] = usePromise<[
      IConstructApplicationTypeModel | HttpErrors,
      IConstructApplicationTemplateListItemModel[] | HttpErrors
  ]>(() => Promise.all([
    getConstructApplicationTypeById(numberId),
    getConstructApplicationTemplateAll(),
  ]),
  !isNaN(numberId),
  [numberId]);

  useEffect(() => {
    if (models && !(models[0] instanceof Error)) {
      setModelFields(models[0]);
    }
  }, [models]);

  const templatesOptions = useMemo(
    () => ((models && !(models[1] instanceof Error)) ? convertModelToOptions(models[1]) : []),
    [models],
  );

  const onSave = useCallback(async (modelId: number, modelToSave: ConstructApplicationTypeFormFieldsType[]) => {
    const modelPreparedToSave: ConstructApplicationTypeSaveFieldsType[] = cloneDeep(modelToSave).map((item) => ({
      ...item,
      file: typeof item.file === 'string' ? item.file : null,
    }));

    /** подготовка к загрузке файлов */
    const filesMap: {index: number; file: File}[] = [];

    modelToSave.forEach((item, index) => {
      if (item.file instanceof File) {
        filesMap.push({ index, file: item.file });
      }
    });

    try {
      const files = filesMap.map((f) => f.file);
      const filesLoaded = await new UploadFiles(files).upload();

      if (filesLoaded instanceof Error) {
        toast.error('Не удалось загрузить файлы');
        return;
      }

      filesMap.forEach((fileInfo, index) => {
        modelPreparedToSave[fileInfo.index].file = filesLoaded[index].file_name;
      });
    } catch (e) {
      toast.error('Не удалось загрузить файлы');
    }
    /** end подготовка к загрузке файлов */

    handleErrors(
      await updateConstructApplicationFields(modelId, modelPreparedToSave),
      'get',
      (model) => {
        setModelFields(model);
        toast.success(t('system.construct_application.type.fields_saved'));
      },
    );
  }, [t, setModelFields]);

  if (!canUpdate) {
    customHistory.push('/system/construct-application/create');
  }


  if (modelsLoading) {
    return <Loader />;
  }
  if (modelFields) {
    return (
      <ConstructApplicationTypeFieldsComponent
        key={modelFields.additionalFields.map((block) => block.block_id).join()}
        model={modelFields.additionalFields}
        templatesOptions={templatesOptions}
        onSave={onSave}
        modelId={modelFields.id}
      />
    );
  }
  return <EntityNotFound message={t('system.construct_application.type.404')} />;
};
