import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { DefaultFilterType } from '../common';


export type ISystemNewsCategoryFilterType = DefaultFilterType & {
  name?: string;
}


export type ISystemNewsCategoryModelForm = {
  name: string;
}

export interface ISystemNewsCategoryModel {
  id: number;
  name: string;
}

export class SystemNewsCategoryModel implements ISystemNewsCategoryModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}


export interface ISystemNewsCategoriesModel extends IWithPages {
  data: ISystemNewsCategoryModel[];
}

export class SystemNewsCategoriesModel extends WithPagesModel implements ISystemNewsCategoriesModel {
  data: ISystemNewsCategoryModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemNewsCategoryModel);
  }
}
