import React, { useEffect, useState } from 'react';
import Tour from 'reactour';
import { toast } from 'react-toastify';
import { customHistory } from 'customHistory';
import { globalOpenAppGuide } from 'store/actions';
import { handleErrors } from 'utils/errors';
import { completeApplicationGuide } from 'api/Users';
import { store } from 'index';
import {
  applicationGuideStepsForDesktop,
  applicationGuideStepsForMobile,
} from 'applicationGuideStep';
import { useCheckWindowSize } from 'utils/hooks/useCheckWindowSize';
import { ApplicationGuideStepContent } from './ApplicationGuideStepContent';

type Props = {
  isOpenGuide: boolean,
  closeGuide: () => void,
}
const countStep = 11;
const initialTourState: boolean[] = Array(countStep);
initialTourState.fill(false);

export const ApplicationGuide = ({ isOpenGuide, closeGuide }: Props) => {
  const isMobileDevice = useCheckWindowSize(1050);
  const [stepStateTour, setStepStateTour] = useState(0);
  const [isVisibleStep, setIsVisibleStep] = useState(initialTourState); // триггер, когда получены нужные DOM-элементы

  const applicationGuideSteps = isMobileDevice ? applicationGuideStepsForMobile : applicationGuideStepsForDesktop;

  const guideSteps = applicationGuideSteps.map((applicationGuideStep) => ({
    selector: !isVisibleStep[stepStateTour] ? '' : applicationGuideStep.selector,
    content: <ApplicationGuideStepContent
      title={applicationGuideStep.title}
      text={applicationGuideStep.text}
    />,
    action: applicationGuideStep.action,
  }));

  const completeGuide = async () => {
    handleErrors(
      await completeApplicationGuide(),
      'save',
      () => toast.success('Вы прошли обучение'),
    );
    store.dispatch(globalOpenAppGuide(false));
  };


  useEffect(() => {
    const currentStepCssClass = `step-${stepStateTour + 1}`;

    if (!isVisibleStep[stepStateTour] && stepStateTour !== 7 && stepStateTour !== 6) {
      const getDomElementInterval = setInterval(() => {
        if (document.getElementsByClassName(currentStepCssClass)[0]) {
          clearInterval(getDomElementInterval);
          setIsVisibleStep({ ...initialTourState, [stepStateTour]: true });
        }
      }, 900);
    }
    // на 7 шаге появляется скролл, нужно дождаться когда он появится и только потом показываем модальное окно
    if (!isVisibleStep[stepStateTour] && stepStateTour === 7) {
      const getDomElementInterval = setInterval(() => {
        if (document.getElementsByClassName(currentStepCssClass)[0] &&
            document.getElementsByClassName('ScrollbarsCustom-Track')[0]) {
          clearInterval(getDomElementInterval);
          setIsVisibleStep({ ...initialTourState, [stepStateTour]: true });
        }
      }, 900);
    }
    // дополнительно на 6 шаге ждём загрузку данных
    if (!isVisibleStep[stepStateTour] && stepStateTour === 6) {
      const getDomElementInterval = setInterval(() => {
        if (document.getElementsByClassName(currentStepCssClass)[0] &&
            (document.getElementsByClassName('list-element')[0])) {
          clearInterval(getDomElementInterval);
          setIsVisibleStep({ ...initialTourState, [stepStateTour]: true });
        }
      }, 900);
    }
  }, [stepStateTour, isVisibleStep]);

  return (
    <div className="test">
      <Tour
        steps={guideSteps}
        isOpen={isOpenGuide}
        onRequestClose={closeGuide}
        rounded={10}
        accentColor="#F25C62"
        startAt={0}
        maskSpace={10}
        badgeContent={(current, total) => ` ${current} из ${total} `}
        lastStepNextButton={(
          <span
            role="presentation"
            className="btn btn-primary"
            onClick={completeGuide}
          >
            Закончить обучение
          </span>
        )}
        showNavigationNumber={false}
        getCurrentStep={(currentStep) => {
          setStepStateTour(currentStep);
        }}
        className={
          !isVisibleStep[stepStateTour] ?
            'app-guide-custom-none' :
            'app-guide-custom'}
        update={customHistory.location.pathname}
        updateDelay={900}
      />
    </div>
  );
};
