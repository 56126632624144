import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { UiInputSearch } from '../common/UI/InputSearch';
import { ReactComponent as FilterIcon } from '../../assets/img/filter.svg';
import { ContactsFilter } from './Filter';
import { EnterpriseModel } from '../../models/Enterprises/EnterpriseModel';
import { UiModal } from '../common/UI/Modal';
import { LocalUsersFilterType } from '../../models/Users/interfaces';
import { getItem } from '../../utils/localStorage';

const getDefaultFilter = (
  allEnterprises: EnterpriseModel[],
): LocalUsersFilterType => ({
  enterprise: allEnterprises.map((e) => e.id),
});

type Props = {
  getUsers: (filter: LocalUsersFilterType, isFavorite?: boolean) => void;
  getDepartments: (name?: string) => void;
  allEnterprises: EnterpriseModel[];
  currentEnterprise: EnterpriseModel[];
  localStorageKey: string;
}

type FormValues = {
  search: string;
}

export const ContactSearch = ({
  getDepartments,
  getUsers,
  currentEnterprise,
  allEnterprises,
  localStorageKey,
}: Props) => {
  const { register, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });

  const history = useHistory();
  const { pathname, search } = useLocation();

  const params = new URLSearchParams(search);
  const isFavoriteTab = params.has('tab');

  const [openFilter, setOpenFilter] = useState(false);
  /** компонент фильтра будет сообщать об изменениях. и как стартанет тоже сообщит */
  const [filterData, setFilterData] = useState<LocalUsersFilterType>(getDefaultFilter(currentEnterprise));

  const onSearch = useCallback((data: FormValues) => {
    setFilterData((prevState: LocalUsersFilterType) => ({ ...prevState, fio: data.search }));
    getDepartments(data.search);
  }, [getDepartments]);

  /** загрузка списка пользователей */
  useEffect(() => {
    const currentFilter = { ...filterData, ...JSON.parse(getItem(localStorageKey)) };
    getUsers(currentFilter, isFavoriteTab);
  }, [filterData, getUsers, isFavoriteTab, localStorageKey]);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  const switchTab = (toFavorite: boolean) => {
    if (toFavorite) {
      params.set('tab', 'favorite');
    } else {
      params.delete('tab');
    }
    history.push({
      pathname,
      search: params.toString(),
    });
  };

  return (
    <>
      <UiModal isOpen={openFilter} toggle={() => setOpenFilter(false)} headerContent="Фильтр контактов">
        <ContactsFilter
          toggle={() => setOpenFilter(false)}
          allEnterprises={allEnterprises}
          currentEnterprise={currentEnterprise}
          setFilterData={setFilterData}
          initialValues={() => getDefaultFilter(currentEnterprise)}
          filterData={filterData}
          localStorageKey={localStorageKey}
        />
      </UiModal>

      <div className="d-flex">
        <UiInputSearch
          register={register}
          name="search"
          handleSubmit={handleSubmit(onSearch)}
        />
        <div className="ml-3">
          <span className="filter" role="presentation" onClick={() => setOpenFilter(true)}>
            <FilterIcon />
          </span>
        </div>
      </div>

      <div className="tabs">
        <button
          type="button"
          onClick={() => switchTab(false)}
          className={`btn-outline-none item${isFavoriteTab ? '' : ' active'}`}
        >
          Все
        </button>
        <button
          type="button"
          onClick={() => switchTab(true)}
          className={`btn-outline-none item${isFavoriteTab ? ' active' : ''}`}
        >
          Избранные
        </button>
      </div>
    </>
  );
};
