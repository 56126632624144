import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { UiSystemInput } from '../../../../components/common/UI/Input';
import { UiToolbar } from '../../../../components/common/Toolbar';
import { UnitsFilterType } from '../../../../models/References/UnitsModel';
import { ISystemEnterpriseModel } from '../../../../models/References/EnterprisesModel';
import { emptyOption, UiSelect } from '../../../../components/common/UI/Select';
import { convertModelToOptions, SimpleSelectOptionType } from '../../../../utils/convertModelToOptions';


type Props = {
  onSubmit: (filter: UnitsFilterType) => void;
  onReset: () => void;
  enterprises: ISystemEnterpriseModel[];
}

type FormValues = {
  name: string;
  enterprise: SimpleSelectOptionType<number>;
}

export const SystemUnitsFilter = ({ onSubmit, onReset, enterprises }: Props) => {
  const {
    register, handleSubmit, setValue, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      enterprise: emptyOption(),
    },
  });

  const { t } = useTranslation();

  const onLocalSubmit = useCallback(({ name, enterprise }: FormValues) => {
    onSubmit({
      name,
      enterprise_id: enterprise.value > -1 ? enterprise.value : undefined,
    });
  }, [onSubmit]);

  const onLocalReset = useCallback(() => {
    reset();
    onReset();
  }, [onReset, reset]);

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row>
        <Col xs="12" sm="6" md="4">
          <UiSystemInput
            label="Название подразделения"
            name="name"
            register={register}
          />
        </Col>
        <Col xs="12" sm="6" md="4">
          <UiSelect
            label={t('common.enterprise')}
            register={register}
            setValue={setValue}
            name="enterprise"
            options={convertModelToOptions(enterprises)}
            allowEmptyValue
            value={watch('enterprise')}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
