import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemEnterprisesComponent } from '../../../components/References/Enterprises/SystemEnterprises';
import { EnterprisesFilterType, ISystemEnterprisesModel } from '../../../../models/References/EnterprisesModel';
import { systemEnterprisesIsLoadingSelector, systemEnterprisesSelector } from './selectors';
import { systemRefGetEnterprisesStartAction } from './actions';

export const SystemEnterprises = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: EnterprisesFilterType) => {
    dispatch(systemRefGetEnterprisesStartAction(filter));
  }, [dispatch]);

  const enterprises: ISystemEnterprisesModel = useSelector(systemEnterprisesSelector);
  const enterprisesIsLoading: boolean = useSelector(systemEnterprisesIsLoadingSelector);

  return (
    <SystemEnterprisesComponent
      enterprises={enterprises}
      enterprisesIsLoading={enterprisesIsLoading}
      getData={getData}
    />
  );
};
