import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { IUserListUnit } from './interfaces';

export interface UsersFilterType {
  fio?: string;
  enterprise?: number[];
  unit?: number[];
  is_register?: boolean;
  assigned_list?: boolean;
}

export interface IUserListModel {
  code: string;
  name: string; // название предприятия
  units: IUserListUnit[];
}

export class UserListModel implements IUserListModel {
  code: string;
  name: string;
  units: IUserListUnit[];

  constructor(params: any = {}) {
    this.code = params.code;
    this.name = params.name;
    this.units = params.units;
  }
}

export interface IUsersModel extends IWithPages {
  data: IUserListModel[];
}

export class UsersModel extends WithPagesModel implements IUsersModel {
  data: IUserListModel[];

  constructor(params: any = {}) {
    super(params);
    this.data = this.initializeData(params, UserListModel);
  }
}
