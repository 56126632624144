import i18next from 'i18next';
import React from 'react';
import { ApplicationConstructTypes } from 'models/ConstructApplication/interfaces';

import { ListElement } from 'components/common/ListElement/ListElement';
import { IApplicationModelAdditionalFields } from 'models/Application/interfaces';
import { CDate } from 'utils/CDate';
import { projectBaseUrl } from 'utils/env';
import { ReactComponent as DownloadIcon } from 'assets/img/download.svg';
import { UiOuterLink } from 'components/common/UI/OuterLink';


const prepareValue = (type: string, value: any) => {
  if (type === ApplicationConstructTypes.datetime && value) {
    return CDate.format(value, 'dd.MM.yyyy/HH:mm');
  }
  if (type === ApplicationConstructTypes.checkbox) {
    return i18next.t(`common.${value ? 'yes' : 'no'}`);
  }
  if (type === ApplicationConstructTypes.file && value) {
    return (
      <>
        <span className="item">{value}</span>
        <UiOuterLink href={`${projectBaseUrl}${value}`} className="item icon download">
          <DownloadIcon />
        </UiOuterLink>
      </>
    );
  }
  return value;
};


type Props = {
  fields?: IApplicationModelAdditionalFields;
}

export const ApplicationViewConstructFieldsComponent = ({ fields }: Props) => {
  if (!fields) {
    return null;
  }

  return (
    <>
      {fields.map((block) => block.fields.map((field) => (
        <ListElement key={`${field.type}${field.value}${field.name}`} text={field.name} isTitle>
          <div className="item">{prepareValue(field.type, field.value)}</div>
        </ListElement>
      )))}
    </>
  );
};
