import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { SYSTEM_REFERENCES_GET_INSURANCE_START } from './constants';
import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import { systemRefGetInsuranceFailAction, systemRefGetInsuranceOkAction } from './actions';
import { InsurancesModel } from '../../../models/Insurances/InsurancesModel';


function* systemReferencesGetInsuranceSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/references/insurance', { sort: 'id', ...filter });

    yield put(systemRefGetInsuranceOkAction(new InsurancesModel(response)));
  }, systemRefGetInsuranceFailAction);
}


export function* systemReferencesSagas() {
  yield takeLatest(SYSTEM_REFERENCES_GET_INSURANCE_START, systemReferencesGetInsuranceSaga);
}
