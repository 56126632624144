import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EventsComponent } from '../../components/Events/EventsComponent';
import { eventsGetListStartAction } from './actions';
import { getEventsIsLoadingSelector, getEventsSelector } from './selectors';
import { IEventFilterType, IEventsModel } from '../../models/Events/EventModel';


export const Events = () => {
  const dispatch = useDispatch();

  const model: IEventsModel = useSelector(getEventsSelector);
  const modelIsLoading: boolean = useSelector(getEventsIsLoadingSelector);

  const hasMoreData: boolean = model.amountOfPages > model.page;

  const getData = useCallback((filter?: IEventFilterType, append = false) => {
    dispatch(eventsGetListStartAction(append, filter));
  }, [dispatch]);

  useEffect(getData, [getData]);

  return (
    <EventsComponent getData={getData} hasMoreData={hasMoreData} model={model} modelIsLoading={modelIsLoading} />
  );
};

Events.whyDidYouRender = true;
