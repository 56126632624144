import { IUnreadNotificationAmount } from 'models/Notification/UnreadNotificationAmount';
import {
  ERROR_HTTP_PARAMETERS,
  ERROR_NETWORK, GLOBAL_GET_UNREAD_INFO_FAIL,
  GLOBAL_GET_UNREAD_INFO_OK,
  GLOBAL_GET_UNREAD_INFO_START,
  GLOBAL_LOADER_HIDE,
  GLOBAL_LOADER_SHOW, GLOBAL_SET_DISPLAYING_APP_GUIDE, GLOBAL_SET_APP_VERSION, GLOBAL_SET_ONLINE,
} from './constants';

/**
 *  общий базовый экшн для вызова методов. просто пропускает через себя данные
 *  не исключает возможности использовать свои экшны,
 *  эта функция всего лишь сокращение повторяющегося кода
 *
 *  payload как объект
 *  @param type {string} - константа для типа экшна
 *  @return function
 */
export const dataAction = (type: string) => (payload: any = {}) => ({
  type,
  payload,
});

// общий экшн для ошибок. основан на общем базовом
export const dataErrorAction = dataAction;

// общий экшн для успеха. основан на общем базовом
export const dataSuccessAction = dataAction;


// ошибка http
export const errorHttpParamsAction = (response: unknown) => dataAction(
  ERROR_HTTP_PARAMETERS,
)({ response });

// нет сетевого соединения (интернета)
export const errorNetworkAction = (response: unknown) => dataAction(
  ERROR_NETWORK,
)({ response });


export const globalLoaderShowAction = dataAction(GLOBAL_LOADER_SHOW);
export const globalLoaderHideAction = dataAction(GLOBAL_LOADER_HIDE);

export const globalGetUnreadInfoStartAction = dataAction(GLOBAL_GET_UNREAD_INFO_START);
export const globalGetUnreadInfoOkAction = (unreadInfo: IUnreadNotificationAmount) => dataSuccessAction(
  GLOBAL_GET_UNREAD_INFO_OK,
)({ unreadInfo });
export const globalGetUnreadInfoFailAction = dataErrorAction(GLOBAL_GET_UNREAD_INFO_FAIL);

export const globalSetAppVersionAction = dataAction(GLOBAL_SET_APP_VERSION);

export const globalSetOnlineAction = (isOnline: boolean) => dataAction(GLOBAL_SET_ONLINE)({ isOnline });

export const globalOpenAppGuide = (isOpenApplicationGuide: boolean) => dataAction(
  GLOBAL_SET_DISPLAYING_APP_GUIDE,
)({ isOpenApplicationGuide });
