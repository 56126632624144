import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { recoverPassword, resetPassword } from '../../../api/Auth';
import { Header } from '../../Main/Header';
import { UiInput } from '../../common/UI/Input';
import { handleErrors } from '../../../utils/errors';
import { HttpErrors } from '../../../utils/http';
import { PASSWORD_PATTERN, PHONE_MASK, PHONE_PATTERN } from '../../../config/system';
import { UIInputMask } from '../../common/UI/InputMask';
import { getNumberPhoneWithoutMask } from '../../../utils/common';


type FormValues = {
  phone: string;
  password: string;
  passwordConfirm: string;
  code: string;
}

export const ResetPassword = () => {
  const [mode, setMode] = useState<'recover' | 'reset'>('recover');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();
  const emptyPasswordMsg = t('auth.signup.empty_password');
  const passwordsNotEqualMsg = t('auth.signup.passwords_not_equal');

  const {
    register, handleSubmit, errors, formState, getValues, control, watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      password: '',
      passwordConfirm: '',
      code: '',
    },
  });

  const onSubmit = async (data: FormValues) => {
    if (mode === 'recover') {
      handleErrors(
        await recoverPassword({ phone: getNumberPhoneWithoutMask(data.phone) }),
        'save',
        () => {
          setMode('reset');
        },
      );
    } else {
      setLoading(true);

      handleErrors(
        await resetPassword({ phone: getNumberPhoneWithoutMask(data.phone), password: data.password, code: data.code }),
        (e: HttpErrors) => {
          toast.error(e.message);
        },
        () => {
          toast.success(t('auth.password.changed'));
          history.push('/login');
        },
        () => setLoading(false),
      );
    }
  };

  return (
    <>
      <Header showSlogan />
      <div className="box register">
        <section className="form-invert">
          <div className="container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <UIInputMask
                name="phone"
                control={control}
                label="Номер телефона"
                watch={watch}
                mask={PHONE_MASK}
                validation={{
                  pattern: PHONE_PATTERN,
                }}
                isRequired
              />
              {mode === 'reset' && (
                <>
                  <UiInput
                    value={getValues().code}
                    label="Код из смс"
                    register={register}
                    name="code"
                    required
                    errors={errors}
                    minLength={4}
                  />
                  <UiInput
                    value={getValues().password}
                    label="Новый пароль"
                    register={register}
                    name="password"
                    required
                    errors={errors}
                    minLength={8}
                    type="password"
                    validation={{
                      pattern: {
                        value: PASSWORD_PATTERN,
                        message: t('auth.password.restriction'),
                      },
                      validate: (value: string) => value.trim().length > 0 || emptyPasswordMsg,
                    }}
                  />
                  <UiInput
                    value={getValues().passwordConfirm}
                    label="Подтверждение пароля"
                    register={register}
                    validation={{
                      pattern: {
                        value: PASSWORD_PATTERN,
                        message: t('auth.password.restriction'),
                      },
                      validate: (value: string) => value === getValues().password || passwordsNotEqualMsg,
                    }}
                    name="passwordConfirm"
                    required
                    errors={errors}
                    minLength={8}
                    type="password"
                  />
                </>
              )}

              <div className="buttons-group buttons-group-responsive buttons-group-password">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => history.push('/login')}
                >
                  Назад
                </Button>
                <Button color="primary" type="submit" disabled={!formState.isValid || loading}>
                  {mode === 'recover' ? 'Запросить восстановление пароля' : 'Сменить пароль'}
                </Button>
              </div>
            </form>

          </div>
        </section>
      </div>
    </>
  );
};
