import queryString from 'query-string';

import { customHistory } from 'customHistory';


/** очистка get параметров */
export const clearSearchParam = (): void => {
  customHistory.replace({ ...customHistory.location, search: null });
};

/**
 * установить url get параметры
 * @param params {Object}
 */
export const setSearchParam = (params: { [name: string]: string | number }): void => {
  clearSearchParam();

  const search = queryString.parse(customHistory.location.search);

  // @ts-ignore
  const query = new URLSearchParams(search);

  Object.entries(params)
    .filter(([, v]) => v)
    .forEach((param: [string, string | number]) => query.set(param[0], param[1].toString()));

  customHistory.replace({ ...customHistory.location, search: query.toString() });
};

/**
 * получить все url get параметры
 */
export const getSearchFilterParams = (): Record<string, any> => queryString.parse(customHistory.location.search);
