import { clientNav } from '../nav';
import { routes } from '../routes';

// eslint-disable-next-line max-len
export const getIntersectionRights = (currentUserRights: string[]) => currentUserRights.filter((rightName) => (clientNav.some((clientNavItem) => rightName === clientNavItem.right)));
export const getActualClientRouteList = (intersectionPermissions: string[]) => routes
  .filter((route) => {
    if (route.right) {
      return intersectionPermissions.some((item) => item === route.right);
    }
    return true;
  });
