import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ParamTypes } from 'models/common';
import { getSystemRoleByName } from '../../api/rbac';
import {
  systemRbacPermissionsIsLoadingSelector,
  systemRbacPermissionsSelector,
  systemRbacRoleSelector,
} from '../../store/selectors';
import { IUserRightModel } from '../../../models/User/UserRightsModel';
import { Loader } from '../../../components/common/UI/Loaders';
import { SystemRoleFormComponent } from '../../components/Roles/SystemRoleForm';
import { IPermissions } from '../../../models/Roles/Permissions';


export const SystemRolesForm = () => {
  const { rolename } = useParams<ParamTypes>();

  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [role, setRole] = useState<IUserRightModel | null>(null);
  const selectRole = useSelector(systemRbacRoleSelector(rolename || ''));

  const permissions: IPermissions = useSelector(systemRbacPermissionsSelector);
  const permissionsIsLoading: boolean = useSelector(systemRbacPermissionsIsLoadingSelector);

  useEffect(() => {
    /** если в сторе есть - взять */
    if (selectRole) {
      setLoading(false);
      setRole(selectRole);
    } else if (rolename && rolename !== 'create') {
      /** если в сторе нет - запросить метод */
      getSystemRoleByName(rolename)
        .then((modelOrError) => {
          if (modelOrError instanceof Error) {
            toast.error(t('common.form.errors.get'));
          } else {
            setRole(modelOrError);
            setLoading(false);
          }
        });
    } else {
      /** создание роли */
      setRole({ name: '', description: '', permissions: [] });
      setLoading(false);
    }
  }, [selectRole, t, rolename]);

  if (isLoading || permissionsIsLoading || !role) {
    return <Loader />;
  }
  return <SystemRoleFormComponent role={role} permissions={permissions} />;
};
