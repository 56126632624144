import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getModelFromModels } from 'utils/getModelFromModels';

import { ParamTypes } from 'models/common';
import { PollsOneComponent } from '../../components/Polls/PollsOne';
import { usePromise } from '../../utils/hooks/usePromise';
import { getPollAnswers, getPollById, savePollAnswers } from '../../api/poll';
import { Loader } from '../../components/common/UI/Loaders';
import { EntityNotFound } from '../../components/Errors/404';
import { IPollModel, PollModel } from '../../models/Polls/PollModel';
import { ISavePollAnswers } from '../../models/Polls/PollsQuestionTypesModel';
import { handleErrors } from '../../utils/errors';
import { PollAnswersModelType } from '../../models/Polls/PollAnswersModel';
import { HttpErrors } from '../../utils/http';


export const PollsOne = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const history = useHistory();

  const { t } = useTranslation();

  const [models, loading] = usePromise<[IPollModel | HttpErrors, PollAnswersModelType | HttpErrors]>(() => Promise.all([
    getPollById(numberId),
    getPollAnswers(numberId),
  ]), !isNaN(numberId), [numberId]);

  const [pollModel, answersModel] = [getModelFromModels(models, 0), getModelFromModels(models, 1)];

  const [answersSaveLoading, setAnswersSaveLoading] = useState(false);

  /** сохранить ответы на вопросы */
  const onAnswerPoll = useCallback(async (data: ISavePollAnswers) => {
    setAnswersSaveLoading(true);
    handleErrors(
      await savePollAnswers(numberId, data),
      'save',
      () => {
        toast.success(t('poll.answers_saved'));
        history.push('/polls');
      },
      () => setAnswersSaveLoading(false),
    );
  }, [numberId, history, t]);

  if (loading) {
    return <Loader />;
  }
  if (pollModel instanceof PollModel) {
    return (
      <PollsOneComponent
        model={pollModel}
        answers={answersModel}
        onAnswerPoll={onAnswerPoll}
        answersSaveLoading={answersSaveLoading}
      />
    );
  }
  return <EntityNotFound message={t('polls.404')} />;
};
