import { AnyAction } from 'redux';
import { IInsurancesModel, InsurancesModel } from '../../models/Insurances/InsurancesModel';
import { ISportModel, SportModel } from '../../models/Sport/SportModel';
import { onFail, onLoad, onLoading } from '../../utils/reducer';
import * as CONSTANTS from './constants';
import { EducationsModel, IEducationsModel } from '../../models/References/EducationsModel';

export const storeName = 'references';

export type referencesReducerType = {
  sport: ISportModel;
  sportIsLoading: boolean;

  insurance: IInsurancesModel;
  insuranceIsLoading: boolean;

  educationTypes: IEducationsModel;
  educationTypesIsLoading: boolean;
}

const initialState: referencesReducerType = {
  sport: new SportModel(),
  sportIsLoading: false,

  insurance: new InsurancesModel(),
  insuranceIsLoading: false,

  educationTypes: new EducationsModel(),
  educationTypesIsLoading: true,
};

export const referencesReducer = {
  [storeName]: (state: referencesReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case CONSTANTS.REFERENCES_GET_SPORT_LIST_START:
        return onLoading(state, 'sport');
      case CONSTANTS.REFERENCES_GET_SPORT_LIST_OK:
        return onLoad(state, payload, 'sport');
      case CONSTANTS.REFERENCES_GET_SPORT_LIST_FAIL:
        return onFail(state, 'sport');

      case CONSTANTS.REFERENCES_GET_INSURANCES_START:
        return onLoading(state, 'insurance');
      case CONSTANTS.REFERENCES_GET_INSURANCES_OK:
        return onLoad(state, payload, 'insurance');
      case CONSTANTS.REFERENCES_GET_INSURANCES_FAIL:
        return onFail(state, 'insurance');

      case CONSTANTS.REFERENCES_GET_EDUCATION_TYPES_START:
        return onLoading(state, 'educationTypes');
      case CONSTANTS.REFERENCES_GET_EDUCATION_TYPES_OK:
        return onLoad(state, payload, 'educationTypes');
      case CONSTANTS.REFERENCES_GET_EDUCATION_TYPES_FAIL:
        return onFail(state, 'educationTypes');

      default:
        return state;
    }
  },
};
