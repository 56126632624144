import { createSelector } from 'reselect';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';
import { returnParams } from '../../../store/selectors';


const getAssignApplications = (state: RootReducer) => state.system[storeName].assignApplications;
const getAssignApplicationsIsLoading = (state: RootReducer) => state.system[storeName].assignApplicationsIsLoading;

const getApplicationStatuses = (state: RootReducer) => state.system[storeName].statuses;
const getApplicationStatusesIsLoading = (state: RootReducer) => state.system[storeName].statusesIsLoading;
const getApplicationTypes = (state: RootReducer) => state.system[storeName].types;
const getApplicationTypesIsLoading = (state: RootReducer) => state.system[storeName].typesIsLoading;


export const systemAssignApplicationsSelector = createSelector([getAssignApplications], returnParams);
export const systemAssignApplicationsIsLoadingSelector = createSelector([getAssignApplicationsIsLoading], returnParams);

export const systemApplicationStatusesSelector = createSelector([getApplicationStatuses], returnParams);
export const systemApplicationStatusesIsLoadingSelector = createSelector(
  [getApplicationStatusesIsLoading], returnParams,
);
export const systemApplicationTypesSelector = createSelector([getApplicationTypes], returnParams);
export const systemApplicationTypesIsLoadingSelector = createSelector([getApplicationTypesIsLoading], returnParams);
