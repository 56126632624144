import { maxValueToGetAllDataWithPagination } from 'config/system';
import { put, takeLatest, call } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { customHistory } from 'customHistory';
import { baseSaga } from 'store/baseSaga';
import { httpGet } from 'utils/http';
import { EnterpriseListModel, IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { setUserMainEnterprise } from 'api/Users';
import * as CONSTANTS from 'containers/Enterprises/constants';
import {
  getEnterprisesListFailAction,
  getEnterprisesListOkAction,
  setMainEnterpriseFailAction, setMainEnterpriseOkAction,
} from 'containers/Enterprises/actions';


/**
 * Получить список всех предприятии
 */
function* getEnterpriseListSaga() {
  yield baseSaga(function* () {
    const data: IEnterpriseModel[] = [];
    let response;
    let page = 1;

    do {
      response = yield httpGet('/public/references/enterprise',
        { 'per-page': maxValueToGetAllDataWithPagination, page });
      page += 1;
      data.push(...new EnterpriseListModel(response).data);
    } while (response.page < response.amountOfPages);

    yield put(getEnterprisesListOkAction(data));
  }, getEnterprisesListFailAction);
}

/**
 * Задать основное предприятие пользователя
 */
function* setMainEnterpriseSaga(action: AnyAction) {
  yield baseSaga(function* () {
    const response = yield setUserMainEnterprise(action.payload);

    yield call(customHistory.push, { pathname: '/' });

    yield put(setMainEnterpriseOkAction(response));
  }, setMainEnterpriseFailAction);
}

export function* enterprisesSagas() {
  yield takeLatest(CONSTANTS.ENTERPRISES_GET_LIST_START, getEnterpriseListSaga);
  yield takeLatest(CONSTANTS.SET_MAIN_ENTERPRISE, setMainEnterpriseSaga);
}
