import { isProductionBuild } from 'utils/env';

export const defaultSizePageTable = 10; // значение количества по-умолчанию для вывода в таблицы
export const defaultSizeByPageTable = [5, 10, 20]; // выбор кол-ва записей на странице в таблицы

/** телефоны. маска и регексп для проверки маски */
export const PHONE_MASK = (() => {
  const mask = ['8', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  /** полный номер телефона для продакшена */
  if (isProductionBuild) {
    mask.push(/\d/);
  }
  return mask;
})();

export const REFERRAL_CODE_MASK = [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
export const REFERRAL_CODE_PATTERN = new RegExp(`^[a-zA-Z0-9]{5}$`);

// eslint-disable-next-line max-len
export const PHONE_PATTERN = new RegExp(`[8][ ][(]([1-9][0-9]{2})[)][ ]([0-9]{3})[-]([0-9]{${isProductionBuild ? 4 : 3}})$`, 'm');
export const replacePhoneMaskRegExp = /[^\d]/gm;

export const BONUS_PROGRAM_FORM_ITEM_COST_PATTERN = /^(?:[1-9][0-9]*|0)(?:\.\d{0,2})?$/;

export const OMS_MASK =
  [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const PASSPORT_MASK =
  [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const maxValueToGetAllDataWithPagination = 2000000; // макс значение для запроса, когда нужно получить все записи
export const defaultFileExtensions =
  ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'zip', 'rar', 'avi', 'mov', 'mp4', 'jpg', 'jpeg'];
export const defaultImgExtensions = ['jpeg', 'jpg', 'png']; // без точек
export const defaultDocsExtensions = ['pdf', 'doc']; // без точек
export const BACKEND_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ssx'; // даты приходят в этом формате, для парсинга
export const BACKEND_DATE_FORMAT_TO = 'yyyy-MM-dd HH:mm:ss'; // бэк принимает даты в этом формате
export const BACKEND_DATE_ONLY_FORMAT = 'yyyy-MM-dd'; // даты в формате бэка без времени_
export const BACKEND_TIME_ONLY_FORMAT = 'HH:mm'; // время в формате бэка без даты
export const avatarExtensions = ['jpg', 'png', 'jpeg'];
export const OMS_PATTERN = /([0-9]{6}) ([0-9]{10})$/m;
export const PASSPORT_PATTERN = /([0-9]{4}) ([0-9]{6})$/m;
export const EMAIL_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*([.][\w]{2,3})+$/m;
export const PASSWORD_PATTERN = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/m;
export const AVATAR = 'https://avatars.mds.yandex.net/get-yapic/0/0-0/islands-retina-middle';

export const fontsPdf = [
  { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu5mxP.ttf' },
  { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfABc9.ttf', fontWeight: 700 },
];
export const MAX_AVATAR_FILE_SIZE = 2 * 1024 * 1024;

/** url которые можно игнорить, не слать в сентри */
export const sentryIgnoreUrls: { url: string; status?: number; backendCode?: number }[] = [
  { url: '', status: 403 }, // ошибка авторизации
  { url: '', status: 401 }, // ошибка авторизации (везде)
  { url: '/api/auth/signup', backendCode: 400 }, // ошибка несуществующего пользователя при регистрации
  // ошибка несуществующего пользователя при восстановлении пароля
  { url: '/api/auth/signup', backendCode: 460 }, // ошибка "Пользователь уже зарегистрирован" при регистрации
  { url: '/api/public/referral/check-code', backendCode: 460 }, // ошибка "Реферальный код не найден" при регистрации
  { url: '/api/public/application/construct', backendCode: 400 }, // ошибка при создании заявки,
  // если сотрудник без руководителя
  { url: '/api/public/user', backendCode: 401 },
  { url: '/api/auth/password-recovery-request', backendCode: 400 },
  { url: '/api/auth/password-recovery-request', backendCode: 500 },
  { url: '/api/auth/signin', backendCode: 460 }, // неверный логин/пароль
  { url: '/api/auth/signin', backendCode: 400 }, // ошибка несуществующего пользователя при авторизации
  { url: '/api/auth/password-recovery-set', backendCode: 460 }, // Неверный код сброса пароля
  { url: '/api/settlement-sheet/enterprise', backendCode: 500 }, // ошибка при запросе расчетных листов для предприятия
];
