import { IPageAgreementModel, PageAgreementModel } from 'models/References/PageAgreement';
import { baseApiFn } from 'utils/baseApiFn';
import { httpGet, httpPut } from 'utils/http';


/** Получить пользовательское соглашение */
export const getSystemPageAgreement = async () => baseApiFn<IPageAgreementModel>(async () => {
  const response = await httpGet('/system/references/page/agreement');
  return new PageAgreementModel(response);
});

/** Сохранить пользовательское соглашение */
export const saveSystemPageAgreement = async (
  data: IPageAgreementModel,
) => baseApiFn<true>(httpPut('/system/references/page/agreement', data));
