import { defaultSizePageTable } from 'config/system';
import { DefaultFilterType } from './common';


/** базовый тип получаемых с бэка данных с постраничной разбивкой */
export type WithPagesResponseType<T = any> = {
  amountOfPages: number;
  limit: number;
  page: number;
  data: T[];
}

/** базовый интерфейс для данных с постраничной навигацией */
export type IWithPages<T = any> = Omit<WithPagesResponseType, 'data'> & {
  initializeData: (params: any, ItemModel: any) => T[];
}

/** базовый класс для данных с постраничной навигацией */
export class WithPagesModel implements IWithPages {
  amountOfPages: number;
  limit: number;
  page: number;

  constructor(params: any) {
    // задание начальных свойств
    this.amountOfPages = params.amountOfPages || 0;
    this.limit = params.limit || defaultSizePageTable;
    this.page = params.page || 1;
  }

  // eslint-disable-next-line class-methods-use-this
  initializeData(params: any, ItemModel: any) {
    if (Array.isArray(params)) {
      return params.map((item: any) => new ItemModel(item));
    }
    return [];
  }
}

/** выдернуть текущую страницу и лимит, чтобы загрузить данные той же страницы */
export const getParamsFromPagesModel = (
  model: IWithPages,
): DefaultFilterType => ({ 'per-page': model.limit, page: model.page });
