import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { NEWS_GET_CATEGORIES_START, NEWS_GET_LIST_START } from './constants';
import { baseSaga } from '../../store/baseSaga';
import { httpGet } from '../../utils/http';
import {
  newsGetCategoriesFailAction,
  newsGetCategoriesOkAction,
  newsGetListFailAction,
  newsGetListOkAction,
} from './actions';
import { NewsModel } from '../../models/News/NewsModel';
import { NewsCategoryItemModel } from '../../models/News/NewsCategoryModel';


function* newsGetListSaga({ payload: { filter, append } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/news', filter);

    yield put(newsGetListOkAction(new NewsModel(response), append));
  }, newsGetListFailAction);
}

function* newsGetCategoriesSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/news/category', filter);

    if (response.data) {
      const model = response.data.map(
        (category: any) => new NewsCategoryItemModel(category),
      );

      yield put(newsGetCategoriesOkAction(model));
    }
  }, newsGetCategoriesFailAction);
}


export function* newsSagas() {
  yield takeLatest(NEWS_GET_LIST_START, newsGetListSaga);
  yield takeLatest(NEWS_GET_CATEGORIES_START, newsGetCategoriesSaga);
}
