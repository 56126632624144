import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ISystemRouteModel, ISystemRoutesListModel } from 'models/Transport/SystemRouteModel';
import { CustomTable } from 'components/common/Table';
import { useDataPage } from 'utils/hooks/useDataPage';

import { DefaultFilterType } from 'models/common';


type Props = {
  data: ISystemRoutesListModel;
  isLoading: boolean;
  getData: (filter: DefaultFilterType) => void;
  onDelete: (item: ISystemRouteModel) => any;
  canCreate: boolean;
  canDelete: boolean;
}

export const SystemTransportComponent = ({
  data, isLoading, getData, onDelete, canCreate, canDelete,
}: Props) => {
  const {
    setLimit, setPage,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  return (
    <>
      <h3>{t('system.transport.menu')}</h3>

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="/system/transport/create">{t('system.transport.route.create')}</Button>
        )}
        tableHeaders={[
          { name: 'ID' },
          { name: t('system.transport.route.name') },
          { name: t('common.enterprise') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item: ISystemRouteModel) => (
          <tr key={item.id}>
            <td>
              <Link to={`/system/transport/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              {item.name}
            </td>
            <td>
              {item.enterprise.name}
            </td>
            <td>
              {canDelete && (
                <Button type="button" color="danger" onClick={onDelete(item)}>{t('common.form.delete')}</Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>

    </>
  );
};
