import { DefaultFilterType } from '../common';
import { CDate } from '../../utils/CDate';
import { IWithPages, WithPagesModel } from '../WithPagesModel';

export type IMedicalFilterType = {
  date_from?: string; // Y-m-d
  date_to?: string; // Y-m-d
} & DefaultFilterType;

export interface IMedicalItemModel {
  readonly id: number;
  readonly date_start: Date | null;
  readonly address: string;
  readonly comment: string;
}

export class MedicalItemModel implements IMedicalItemModel {
  readonly id: number;
  readonly date_start: Date | null;
  readonly address: string;
  readonly comment: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.date_start = params.date_start ? CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm') : null;
    this.address = params.address || '';
    this.comment = params.comment || '';
  }
}

export interface IMedicalModel extends IWithPages {
  data: IMedicalItemModel[];
}

export class MedicalModel extends WithPagesModel implements IMedicalModel {
  data: IMedicalItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, MedicalItemModel);
  }
}
