import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import {
  SYSTEM_GET_PRIVILEGES_START,
  SYSTEM_GET_PRIVILEGES_OK,
  SYSTEM_GET_PRIVILEGES_FAIL,
} from './constants';
import { IPrivilegeList, PrivilegeListFilterType } from '../../../models/Privilege/interfaces';


export const systemGetPrivilegesStartAction = (filter: PrivilegeListFilterType) => dataAction(
  SYSTEM_GET_PRIVILEGES_START,
)({ filter });
export const systemGetPrivilegesOkAction = (privilegesList: IPrivilegeList) => dataSuccessAction(
  SYSTEM_GET_PRIVILEGES_OK,
)({ privilegesList });
export const systemGetPrivilegesFailAction = dataErrorAction(SYSTEM_GET_PRIVILEGES_FAIL);
