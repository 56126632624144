import {
  ISystemBonusProgramEventItemModel,
  ISystemBonusProgramEventListModel,
} from 'models/BonusProgram/Event/interfaces';
import { WithPagesModel } from 'models/WithPagesModel';

export class SystemBonusProgramEventItemModel implements ISystemBonusProgramEventItemModel {
  id: number;
  title: string;
  sdsCoin: number;
  isActive: boolean;

  constructor(params: any = {}) {
    this.id = params.id;
    this.title = params.title;
    this.sdsCoin = params.sdscoin;
    this.isActive = params.is_active;
  }
}

export class SystemBonusProgramEventListModel extends WithPagesModel implements ISystemBonusProgramEventListModel {
  data: ISystemBonusProgramEventItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemBonusProgramEventItemModel);
  }
}
