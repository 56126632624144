import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';


import {
  ApplicationDepartmentEnum,
  ApplicationStatusEnum,
  ApplicationTypeEnum,
  IApplicationModel,
} from 'models/Application/interfaces';
import { FileType } from 'models/common';
import { useIncludedStatus } from 'utils/hooks/useIncludedStatus';
import { useAvailableAccrualBonusesForApplication } from 'utils/hooks/useAvailableAccrualBonusesForApplication';
import { CDate } from 'utils/CDate';
import {
  SystemApplicationViewConstructFields,
} from 'systemModule/components/Application/ApplicationViewConstructFields';
import { ApplicationItemForm } from 'systemModule/components/Application/ApplicationItemForm';
import { UiOuterLink } from 'components/common/UI/OuterLink';

type Props = {
  model: IApplicationModel;
  downloadDocumentUrl: (id: number) => string;
  thisSectionName: string;
}

const listOfStatuses = [
  ApplicationStatusEnum.new,
  ApplicationStatusEnum.assign,
  ApplicationStatusEnum.work,
  ApplicationStatusEnum.approval,
];
const listOfApplicationTypes = [ApplicationTypeEnum.feedback];
const listOfApplicationDepartments = [ApplicationDepartmentEnum.IT];

export const SystemApplicationComponent = ({ model, downloadDocumentUrl, thisSectionName }: Props) => {
  const { t } = useTranslation();

  const filesResponsible = model.comments.reduce((acc: FileType[], comment) => {
    if (comment.files.length) {
      acc.push(...comment.files);
    }
    return acc;
  }, []);

  const isCanChanged = useIncludedStatus(model.status.id, listOfStatuses);
  const isAvailableAccrualsBonuses = useAvailableAccrualBonusesForApplication(
    model.department?.system_name,
    listOfApplicationDepartments,
    model.type.id,
    listOfApplicationTypes,
  );

  return (
    <>
      <h3>{t('applications.item_id', { appId: model.id })}</h3>

      <Table responsive striped hover>
        <tbody>
          <tr>
            <td>Тип заявки</td>
            <td>{model.type.name}</td>
          </tr>
          <tr>
            <td>Номер заявки</td>
            <td>{model.id}</td>
          </tr>
          <tr>
            <td>Автор заявки</td>
            <td>{model.author.fio}</td>
          </tr>
          <tr>
            <td>Статус заявки</td>
            <td>{model.status.name}</td>
          </tr>
          <tr>
            <td>Дата создания</td>
            <td>{CDate.format(model.created_at, 'dd.MM.yyyy HH:mm')}</td>
          </tr>
          {(model.date_start && model.date_end && model.type.id === -3) && (
            <tr>
              <td>Период больничного</td>
              <td>
                с {CDate.format(model.date_start, 'dd.MM.yyyy')} по {CDate.format(model.date_end, 'dd.MM.yyyy')}
              </td>
            </tr>
          )}
          <tr>
            <td>Прикрепленные файлы автора</td>
            <td>
              {model.file && (
                <UiOuterLink href={model.file.fullUrl}>{model.file.name}</UiOuterLink>
              )}
            </td>
          </tr>
          <tr>
            <td>Ответственный</td>
            <td>{model.assign.fio}</td>
          </tr>
          <tr>
            <td>Комментарии</td>
            <td>{model.comments.map((comment) => comment.content).join(', ')}</td>
          </tr>
          <tr>
            <td>Прикрепленные файлы ответственного</td>
            <td className="flex-column">
              {filesResponsible.map((file: FileType) => (
                <UiOuterLink className="d-block" key={Math.random()} href={file.fullUrl}>
                  {file.name}
                </UiOuterLink>
              ))}
            </td>
          </tr>
          <tr>
            <td>Описание</td>
            <td className="ws-pw">{model.description}</td>
          </tr>
          {
            isAvailableAccrualsBonuses && (
              <tr>
                <td>Начисление бонусов по этой заявке</td>
                <td className="ws-pw">{model.addSdsCoin ? 'Да' : 'Нет'}</td>
              </tr>
            )
          }

          <SystemApplicationViewConstructFields
            fields={model.additionalFields}
            downloadDocumentUrl={downloadDocumentUrl}
          />
        </tbody>
      </Table>

      {/** заявки со статусами: 20, 25, 30 (отменена, отклонена, выполнена) - нельзя изменить */}
      {isCanChanged && (
        <ApplicationItemForm
          thisSectionName={thisSectionName}
          status={model.status.id}
          id={model.id}
          isAddedSdsCoin={model.addSdsCoin}
          isAvailableAccrualsBonuses={isAvailableAccrualsBonuses}
        />
      )}
    </>
  );
};
