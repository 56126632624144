import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { IRolesModel } from '../../../models/Roles/RolesModel';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { CustomTable } from '../../../components/common/Table';
import { DefaultFilterType } from '../../../models/common';
import { deleteSystemRbacRole } from '../../api/rbac';
import { getParamsFromPagesModel } from '../../../models/WithPagesModel';

type Props = {
  data: IRolesModel;
  isLoading: boolean;
  getData: (filter: DefaultFilterType) => void;
}

export const SystemRolesComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const onDelete = (roleName: string) => () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(t('common.form.delete.question', { entity: 'роль', entityName: roleName }))) {
      deleteSystemRbacRole(roleName).then((booleanOrError) => {
        if (booleanOrError instanceof Error) {
          toast.error(t('common.form.errors.delete'));
        } else {
          toast.success(t('rbac.role.deleted'));
          getData(getParamsFromPagesModel(data));
        }
      });
    }
  };

  return (
    <>
      <h3>Список ролей</h3>
      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={(
          <Button tag={Link} color="primary" to="roles/create">Создать роль</Button>
        )}
        tableHeaders={[
          { name: t('rbac.roles.description') },
          { name: t('rbac.roles.name') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((role) => (
          <tr key={role.name}>
            <td>
              <Link to={`roles/${role.name}`}>{role.description}</Link>
            </td>
            <td>
              {role.name}
            </td>
            <td>
              <Button type="button" color="danger" className="w-100" onClick={onDelete(role.name)}>
                {t('common.form.delete')}
              </Button>
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
