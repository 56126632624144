import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/common/UI/Loaders';
import { getRecommendedPartners } from 'api/partner';
import { IPartnersListModel } from 'models/Partners/PartnersListModel';
import { usePromise } from 'utils/hooks/usePromise';
import { HttpErrors } from 'utils/http';
import { EntityNotFound } from 'components/Errors/404';
import { PartnersRecommendedListComponent } from 'components/Partners/recommendedList';


export const PartnersRecommendedList = () => {
  const { t } = useTranslation();

  const [model, modelLoading, onUnmount] = usePromise<IPartnersListModel | HttpErrors>(
    getRecommendedPartners,
    true,
  );
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  if (modelLoading) {
    return <Loader />;
  }
  if (model === null || model instanceof Error) {
    return <EntityNotFound message={t('partners.recommended.404')} />;
  }
  return <PartnersRecommendedListComponent model={model} />;
};
