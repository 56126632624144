import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { ParamTypes } from 'models/common';
import { EntityNotFound } from 'components/Errors/404';
import { IApplicationConstructCategoryTypeList } from 'models/Application/Construct/interfaces';
import { ListElementLink } from 'components/common/ListElement/ListElementLink';
import { UiBox } from 'components/common/UI/UiBox';
import { getApplicationCategoryTypeList } from 'api/application';
import { usePromise } from 'utils/hooks/usePromise';
import { Loader } from 'components/common/UI/Loaders';

// Надеюсь что временный хардкод категории
const HAVE_IDEA_ID = process.env.REACT_APP_HAVE_IDEA_ID;

export const ApplicationCreateCategoryList = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [model, modelIsLoading] = usePromise<IApplicationConstructCategoryTypeList>(
    () => getApplicationCategoryTypeList(numberId),
    !isNaN(numberId),
    [numberId],
  );
  const applicationList = model?.filter((application) => application.is_enabled);

  const { t } = useTranslation();

  if (modelIsLoading) {
    return <Loader />;
  }
  if (isNaN(numberId)) {
    return <EntityNotFound message={t('application.category.404')} />;
  }

  if (HAVE_IDEA_ID && +HAVE_IDEA_ID === numberId) {
    applicationList?.sort(({ id: idA }, { id: idB }) => idA - idB);
  }

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/applications/create" />
        {t('application.create')}
      </div>

      <UiBox className="applications">
        <section className="form">
          {(applicationList?.length) ? (
            <>
              <h4>{t('application.type')}</h4>
              {applicationList.map((application) => (
                <ListElementLink
                  key={application.id}
                  text={application.name}
                  to={`/applications/create/type/${application.id}`}
                />
              ))}
            </>
          ) : t('application.categories.empty_type_list')}
        </section>
      </UiBox>
    </>
  );
};
