import React, { useCallback, useEffect, useState } from 'react';

import { getSystemReportApplication, getSystemReportApplicationEnterprise } from '../../../api/reports';
import { IReportApplication, IReportApplicationEnterprise } from '../../../models/Reports/interfaces';
import { handleErrors } from '../../../utils/errors';
import { SystemReportApplicationIndexComponent } from '../../components/Reports/Application';


export const SystemReportApplication = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState<IReportApplication | IReportApplicationEnterprise | null>(null);

  const getData = useCallback(async (enterprise_ids?: number[]) => {
    setIsLoading(true);

    const apiFn = enterprise_ids ?
      () => getSystemReportApplicationEnterprise(enterprise_ids) :
      getSystemReportApplication;

    handleErrors(
      await apiFn(),
      'get',
      setModel,
      () => setIsLoading(false),
    );
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <SystemReportApplicationIndexComponent
      model={model}
      getData={getData}
      isLoading={isLoading}
    />
  );
};
