import { createSelector } from 'reselect';
import { storeName } from './reducer';
import { RootReducer } from '../../store/reducers';
import { returnParams } from '../../store/selectors';

const getNotifications = (state: RootReducer) => state[storeName].notifications;
const getNotificationsIsLoading = (state: RootReducer) => state[storeName].notificationsIsLoading;

const getUnreadInitNotifications = (state: RootReducer) => state[storeName].unreadInitNotifications;
const getUnreadInitNotificationsIsLoading = (
  state: RootReducer,
) => state[storeName].unreadInitNotificationsIsLoading;

const getLastReadNotification = (state: RootReducer) => state[storeName].lastReadNotification;


export const getNotificationsSelector = createSelector([getNotifications], returnParams);
export const getNotificationsIsLoadingSelector = createSelector(
  [getNotificationsIsLoading], returnParams,
);

export const getUnreadInitNotificationsSelector = createSelector(
  [getUnreadInitNotifications], returnParams,
);
export const getUnreadInitNotificationsIsLoadingSelector = createSelector(
  [getUnreadInitNotificationsIsLoading], returnParams,
);

export const getLastReadNotificationSelector = createSelector([getLastReadNotification], returnParams);
