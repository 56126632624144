import React from 'react';
import { useForm } from 'react-hook-form';

import { IUserRightModel } from 'models/User/UserRightsModel';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSelect } from 'components/common/UI/Select';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { UiSystemInput } from 'components/common/UI/Input';
import { TrbacUserRights } from 'models/Roles/RolesModel';
import { ISystemUserModel } from 'models/User/interfaces';


type Props = {
  roles: IUserRightModel[];
  user: ISystemUserModel;
  onSave: (data: TrbacUserRights) => void;
  userRoles: string[];
}

type FormValues = {
  roles: SimpleSelectOptionType<string>[];
  readonly userdata: string;
}

export const SystemUserRoleComponent = ({
  roles, user, onSave, userRoles,
}: Props) => {
  const rolesOption = roles.map((r) => ({ label: r.description, value: r.name }));

  const {
    register, handleSubmit, errors, setValue,
    formState: { isSubmitting, isValid }, getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      userdata: `ID: ${user.id}, ФИО: "${user.fio}"`,
      roles: rolesOption.filter((option) => userRoles.includes(option.value)),
    },
  });

  const onSubmit = async (data: FormValues) => onSave({ roles: data.roles.map((r) => r.value) });

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={0}
      createMode
    >
      <h3>Назначение роли пользователю</h3>

      <UiSystemInput
        name="userdata"
        errors={errors}
        register={register}
        label="Пользователь"
        disabled
      />

      <UiSelect
        defaultValue={getValues('roles')}
        errors={errors}
        register={register}
        name="roles"
        label="Роли"
        setValue={setValue}
        options={rolesOption}
        multiple
      />
    </UiSystemForm>
  );
};
