import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import { SYSTEM_GET_EVENTS_FAIL, SYSTEM_GET_EVENTS_OK, SYSTEM_GET_EVENTS_START } from './constants';
import { ISystemListEvents } from '../../../models/Events/SystemListEvents';
import { DefaultFilterType } from '../../../models/common';


export const systemGetEventsStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_GET_EVENTS_START,
)({ filter });
export const systemGetEventsOkAction = (events: ISystemListEvents) => dataSuccessAction(
  SYSTEM_GET_EVENTS_OK,
)({ events });
export const systemGetEventsFailAction = dataErrorAction(SYSTEM_GET_EVENTS_FAIL);
