import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { setHours, setMinutes } from 'date-fns';
import { convertModelToOptions } from '../../../utils/convertModelToOptions';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { UiSystemForm } from '../../../components/common/UI/Form';
import { UiDatePicker } from '../../../components/common/Dates/UiDatePicker';
import { CDate } from '../../../utils/CDate';
import { BACKEND_DATE_FORMAT_TO } from '../../../config/system';
import { ISystemNotification, SystemNotificationModel } from '../../../models/Notification/SystemNotification';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { UiSystemCheckbox } from '../../../components/common/UI/Checkbox';
import { emptyOption, emptyOptionValue, UiSelect } from '../../../components/common/UI/Select';
import { ISystemUnitModel } from '../../../models/References/UnitsModel';


type FormValues = {
  subject: string;
  text: string;
  internal_link: string;
  is_blocked: boolean;
  enterprises: {
    label: string;
    value: number;
  }[];
  units: {
    label: string;
    value: number;
  }[];
  ended_at: Date | null;
}

type Props = {
  enterprises: IEnterpriseModel[] | undefined;
  units: ISystemUnitModel[];
  onSubmit: (model: ISystemNotification, reset: (v: Record<string, any>) => void, v: Record<string, any>) => void;
  loading: boolean;
}

const allEnterprisesOption = emptyOption('Все доступные предприятия');
const allUnitsOption = emptyOption('Все доступные подразделения');

const defaultValuesForm = {
  subject: '',
  text: '',
  internal_link: '',
  is_blocked: false,
  enterprises: [allEnterprisesOption],
  units: [allUnitsOption],
  ended_at: null,
};

export const SystemNotificationComponent = ({
  enterprises, units, loading, onSubmit,
}: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, watch, setValue, errors, reset,
    formState: { dirtyFields, isValid }, getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: defaultValuesForm,
  });

  /** фильтрация подразделений в зависимости от выбранных предприятий */
  const checkedEnterpriseIds = watch('enterprises').map((ent) => +ent.value);
  const filteredUnitsByEnterprises = units.filter((unit) => checkedEnterpriseIds.includes(unit.enterprise.id));

  const onLocalSubmit = async (data: FormValues) => {
    const model = new SystemNotificationModel({
      subject: data.subject,
      text: data.text,
      internal_link: data.internal_link,
      is_blocked: data.is_blocked,
      date_blocked: data.ended_at ? CDate.format(data.ended_at, BACKEND_DATE_FORMAT_TO) : undefined,
      enterprises: data.enterprises
        .filter((ent) => ent.value !== emptyOptionValue)
        .map((ent) => ({
          id: +ent.value,
          units: data.units.length ? data.units
            .filter((unit) => unit.value !== emptyOptionValue)
            .map((unit) => +unit.value) : undefined,
        })),
    });

    onSubmit(model, reset, defaultValuesForm);
  };

  const enterprisesOptions = convertModelToOptions(enterprises !== undefined ? enterprises : []);
  if (getValues('enterprises').length === 0) {
    enterprisesOptions.unshift(allEnterprisesOption);
  }

  const unitsOptions = convertModelToOptions(filteredUnitsByEnterprises);
  if (getValues('units').length === 0) {
    unitsOptions.unshift(allUnitsOption);
  }

  /** валидация времени оповещения */
  const currentDate = new Date();
  const minTime = (
    currentDate.getDate() === watch('ended_at')?.getDate() || !watch('ended_at')
  ) ? currentDate : undefined;
  const maxTime = (
    currentDate.getDate() === watch('ended_at')?.getDate() || !watch('ended_at')
  ) ? setHours(setMinutes(currentDate, 30), 23) : undefined;

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onLocalSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={loading}
      dirtyFieldsAmount={dirtyFields.size}
      createMode
    >
      <h3>{t('system.notification.form.create')}</h3>

      <UiSystemInput
        errors={errors}
        register={register}
        name="subject"
        label={t('system.notification.form.subject')}
        required
      />

      <UiSystemInput
        errors={errors}
        register={register}
        name="text"
        label={t('system.notification.form.text')}
        required
        type="textarea"
      />

      <UiSystemInput
        errors={errors}
        register={register}
        name="internal_link"
        label={t('system.notification.form.internal_link')}
        required // для ручных оповещений обязательно должен быть указана ссылка
      />

      <UiSystemCheckbox
        register={register}
        name="is_blocked"
        label={t('system.notification.form.is_blocked')}
      />

      <UiSelect
        value={watch('enterprises')}
        name="enterprises"
        options={enterprisesOptions}
        register={register}
        setValue={setValue}
        errors={errors}
        label={t('system.notification.form.enterprises')}
        multiple
      />

      <UiSelect
        value={watch('units')}
        name="units"
        options={unitsOptions}
        register={register}
        setValue={setValue}
        errors={errors}
        label={t('system.notification.form.units')}
        multiple
      />

      <UiDatePicker
        watch={watch}
        name="ended_at"
        setValue={setValue}
        register={register}
        label={t('system.notification.form.ended_at')}
        required={getValues('is_blocked')}
        showTimeSelect
        minDate={currentDate}
        minTime={minTime}
        maxTime={maxTime}
      />
    </UiSystemForm>
  );
};
