import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { getSystemUser } from 'systemModule/api/user';
import {
  getSystemBonusProgramAvailablePrizeList,
  getSystemBonusProgramTagList,
  getSystemBonusProgramUserTagList,
  givePrizeToUserSystemBonusProgram, updateSystemBonusProgramUserTags,
} from 'systemModule/api/bonusProgram';
import { ISystemUserModel } from 'models/User/interfaces';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { usePromise } from 'utils/hooks/usePromise';
import { getModelFromModels } from 'utils/getModelFromModels';
import { handleErrors } from 'utils/errors';
import { HttpErrors } from 'utils/http';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import {
  SystemBonusProgramUserComponent,
} from 'systemModule/components/BonusProgram/BonusProgramUsers/SystemBonusProgramUser';
import {
  ISystemBonusProgramTagListModel,
  ISystemBonusProgramUserTagListModel, ISystemBonusProgramUserTagsFormModel,
} from 'models/BonusProgram/Tag/interfaces';
import { ISystemBonusProgramOrderFormModel } from 'models/BonusProgram/Order/interfaces';
import { ISystemBonusProgramPrizeListModel } from 'models/BonusProgram/Prize/interfaces';
import {
  ISystemBonusProgramEventHistoryFilter,
  ISystemBonusProgramEventHistoryListModel,
} from 'models/BonusProgram/EventHistory/interfaces';
import { systemBonusProgramGetEventHistoryListStartAction } from 'systemModule/containers/BonusProgram/actions';
import {
  systemBonusProgramEventHistoryListLoadingSelector,
  systemBonusProgramEventHistoryListSelector,
} from 'systemModule/containers/BonusProgram/selectors';

export const SystemBonusProgramUser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const getBonusProgramEventHistoryData = useCallback((filter: ISystemBonusProgramEventHistoryFilter) => {
    dispatch(systemBonusProgramGetEventHistoryListStartAction({ ...filter, user_id: numberId }));
  }, [dispatch, numberId]);

  const bonusProgramEventHistoryData: ISystemBonusProgramEventHistoryListModel = useSelector(
    systemBonusProgramEventHistoryListSelector,
  );
  const isLoadingBonusProgramEventHistoryData: boolean = useSelector(systemBonusProgramEventHistoryListLoadingSelector);

  const [models, modelIsLoading] = usePromise<[
      ISystemUserModel | HttpErrors,
      ISystemBonusProgramPrizeListModel | HttpErrors,
      ISystemBonusProgramUserTagListModel | HttpErrors,
      ISystemBonusProgramTagListModel | HttpErrors,
  ]>(
    () => Promise.all([
      getSystemUser(numberId),
      getSystemBonusProgramAvailablePrizeList(numberId),
      getSystemBonusProgramUserTagList(numberId),
      getSystemBonusProgramTagList(),
    ]),
    !isNaN(numberId),
    [numberId],
  );
  const [userData, availablePrizeList, userTagList, bonusProgramTags]: [
    ISystemUserModel | null,
      ISystemBonusProgramPrizeListModel | null,
      ISystemBonusProgramUserTagListModel | null,
      ISystemBonusProgramTagListModel | null,
  ] = [
    getModelFromModels(models, 0, null),
    getModelFromModels(models, 1, null),
    getModelFromModels(models, 2, null),
    getModelFromModels(models, 3, null),
  ];

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canGivePrizeToUser = currentUser.hasPermission(UserAccessEnum.cBonusProgramOrder);
  const canUpdateUserTags = currentUser.hasPermission(UserAccessEnum.wBonusProgramUserTag);
  const canShowEventHistory = currentUser.hasPermission(UserAccessEnum.rBonusProgramEventHistory);

  const givePrizeToUser = useCallback((model: ISystemBonusProgramOrderFormModel) => async () => {
    if (canGivePrizeToUser) {
      handleErrors(
        await givePrizeToUserSystemBonusProgram(model),
        () => toast.error(t('system.bonusProgram.give.prize.fail')),
        () => {
          toast.success(t('system.bonusProgram.give.prize.success'));
          history.push('/system/bonus-program/users');
        },
      );
    }
  }, [canGivePrizeToUser, t, history]);

  const onSaveUserTags = useCallback(async (userId: number, model: ISystemBonusProgramUserTagsFormModel) => {
    if (canUpdateUserTags) {
      handleErrors(
        await updateSystemBonusProgramUserTags(userId, model),
        'save',
        () => {
          toast.success(t('system.bonusProgram.update.user.tag.success'));
        },
      );
    }
  }, [canUpdateUserTags, t]);

  if (modelIsLoading) {
    return <Loader />;
  }
  if (isNaN(numberId) || !userData || !availablePrizeList || !userTagList || !bonusProgramTags) {
    return <EntityNotFound message={t('common.user.notFound')} />;
  }
  return (
    <SystemBonusProgramUserComponent
      userInfo={userData}
      prizeList={availablePrizeList}
      givePrizeToUser={givePrizeToUser}
      canGivePrizeToUser={canGivePrizeToUser}
      bonusProgramTags={bonusProgramTags}
      userTagList={userTagList}
      onSaveUserTags={onSaveUserTags}
      canUpdateUserTags={canUpdateUserTags}
      bonusProgramEventHistoryData={bonusProgramEventHistoryData}
      isLoadingBonusProgramEventHistoryData={isLoadingBonusProgramEventHistoryData}
      getBonusProgramEventHistoryData={getBonusProgramEventHistoryData}
      canShowEventHistory={canShowEventHistory}
    />
  );
};
