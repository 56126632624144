import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import get from 'lodash/get';
import { captureException, withScope } from '@sentry/browser';
import { sentryIgnoreUrls } from 'config/system';
import { HttpErrors } from './http';


type formatErrorsResponseType = { code: number; errorData: {[s: string]: string} | string; message: string };

/** + локализация ошибок любого формата */
export const formatErrors = (response?: formatErrorsResponseType): string => {
  if (response) {
    if (response.errorData) {
      // в errorData может прийти просто строка
      if (typeof response.errorData === 'string') {
        return `${response.errorData}`;
      }
      return Object.values(response.errorData).join(', ');
    }
    // в message постоянно один текст "Ошибка валидации данных"
    return `${response.message}`;
  }
  return 'error with no response';
};


/** ошибка с подробным объяснением от бэка, например о невалидных данных */
export class HttpError extends Error {
  name = 'HttpError';
  errorData: {[s: string]: string}; // здесь подробно все, что приходит от бэка
  code: number; // код ошибки. не http статус

  private error: AxiosError;

  constructor(error: AxiosError) {
    super();

    this.error = error;

    this.message = formatErrors(error.response?.data);
    this.errorData = error.response?.data.errorData;
    this.code = error.response?.data.code;

    /** проверить ошибки и проверить, нужно ли ее отправлять */
    const reportError: boolean = sentryIgnoreUrls
      .findIndex((item) => {
        if (error.request?.responseURL.match(new RegExp(`${item.url}$`))) {
          if (item?.status && item?.backendCode) {
            return item.status === error.request?.status && item.backendCode === this.code;
          }
          if (item?.status) {
            return item.status === error.request?.status;
          }
          if (item?.backendCode) {
            return item.backendCode === this.code;
          }
          return false;
        }
        return false;
      }) === -1;

    if (reportError) {
      /** отсылать сообщение здесь */
      withScope((scope) => {
        scope.setExtra('request info', this.fullMessage);
        captureException(this);
      });
    }
  }

  private get fullMessage(): string {
    return `
      url: ${get(this, 'error.request.responseURL', '')},
      method: ${this.error.config.method},    
      status: ${get(this, 'error.request.status', '')},
      requestData: ${this.error.config.data || ''},
      backendCode: ${this.code}
    `;
  }
}

/**
 * обработка ошибок в проекте
 * @param anyOrError          - результат выполнения http запроса
 * @param errorFuncOrMessage  - функция, которая будет вызвана или константа для вызова стандартной ошибки.
 *                              в нее передается ошибка.
 *                              при использовании аргумента как функции, можно полностью управлять обработкой ошибки
 * @param rightFunc           - функция, в которой нет ошибок. успешное выполнение
 * @param finallyFunc         - функция, которая выполнится независимо от результата. аналог в try/catch или Promise
 *
 * @example
 *    handleErrors(
        trueOrError, // await http...
        'save', // вывод сообщения об ошибке
        () => {
           ...успешная логика...
         },
      );

 * @example
 *    handleErrors(
        trueOrError, // await http...
          (e) => {... обработка ошибки ....},
          () => {
           ...успешная логика...
          },
          () => setLoading(false),
 );
 */
type ThisType = <T>(
  anyOrError: T | HttpErrors,
  errorFuncOrMessage: ((error: HttpErrors) => void) | 'save' | 'get' | 'delete',
  rightFunc?: (model: T) => void,
  finallyFunc?: () => void,
) => void;

export const handleErrors: ThisType = (
  anyOrError,
  errorFuncOrMessage,
  rightFunc?,
  finallyFunc?,
) => {
  /** здесь обработка только ошибки */
  if (anyOrError instanceof Error) {
    if (typeof errorFuncOrMessage === 'function') {
      errorFuncOrMessage(anyOrError);
    } else if (anyOrError instanceof HttpError) {
      toast.error(anyOrError.message);
    } else {
      toast.error(i18next.t(`common.form.errors.${errorFuncOrMessage}`));
    }
  /** здесь обработка только успешного случая, и то, если функция передана */
  } else if (typeof rightFunc === 'function') {
    rightFunc(anyOrError);
  }

  if (finallyFunc && typeof finallyFunc === 'function') {
    finallyFunc();
  }
};
