import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from 'react-hook-form';
import {
  defaultEmptyWeekDayField,
  MAX_TRANSPORT_FIELDS_AMOUNT, prepareRemoveWeekDaysField,
  WeekDayFormValues,
} from 'systemModule/components/Transport/common';
import { Button, Col, Row } from 'reactstrap';

import { ReactComponent as TrashIcon } from 'assets/img/trash.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus-square.svg';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiWeekDaysSwitcher } from 'components/common/UI/UiWeekDaysSwitcher';


type Props = {
  blockIndex: number;
  errors: FieldErrors<any>;
  register: (s?: any) => any;
  unregister: (s: any) => any;
  triggerValidation: () => any;
  fields: WeekDayFormValues[];
  getValues: (s?: any) => any;
  setValue: (s: string, v: any, sv?: boolean) => any;
}

export function ScheduleFields({
  fields, blockIndex, errors, register, unregister,
  getValues, setValue, triggerValidation,
}: Props) {
  const { t } = useTranslation();
  const [dayWeek, setDay] = useState(1);

  /** управление полями */
  const appendField = useCallback(() => {
    const localBlocks = getValues({ nest: true }).blocks;

    /** зарегистрировать 1 пустое поле */
    const newFieldIndex = localBlocks[blockIndex].fields?.length || 0;
    Object.entries(defaultEmptyWeekDayField(dayWeek)).forEach(([key, value]) => {
      register(`blocks[${blockIndex}].fields[${newFieldIndex}].${key}`);
      setValue(`blocks[${blockIndex}].fields[${newFieldIndex}].${key}`, value);
    });
  }, [register, setValue, getValues, dayWeek, blockIndex]);

  const removeField = useCallback((fieldIndex: number) => {
    unregister(prepareRemoveWeekDaysField(blockIndex, fieldIndex));
    triggerValidation();
  }, [unregister, blockIndex, triggerValidation]);
  /** end управление полями */

  return (
    <>
      <UiWeekDaysSwitcher dayWeek={dayWeek} setDay={setDay} />
      <div className="mb-2">
        {t('system.transport.schedule.text')}
      </div>
      <Row>
        {fields.map((field, fieldIndex) => (
          <Col
            key={`field${field.id}`}
            className={field.day !== dayWeek ? 'd-none' : undefined}
            xs={12}
            sm={6}
            md={4}
            xl={3}
          >
            <Row>
              <Col xs={9}>
                <UiSystemInput
                  register={register}
                  name={`blocks[${blockIndex}].fields[${fieldIndex}].time`}
                  errors={errors}
                  type="time"
                  required
                />
              </Col>
              <Col xs={3} className="pl-0">
                <Button
                  type="button"
                  color="danger"
                  onClick={() => removeField(fieldIndex)}
                  className="construct-application-type-field--btn mr-2"
                >
                  <TrashIcon />
                </Button>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      {(fields.filter((f) => f).length < MAX_TRANSPORT_FIELDS_AMOUNT) && (
        <Row>
          <Col>
            <Button
              type="button"
              color="success"
              onClick={appendField}
              className="construct-application-type-field--btn"
            >
              <PlusIcon />
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}
