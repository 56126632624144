import { ISystemReferralProgramListItemModel, ISystemReferralProgramListModel } from './interfaces';
import { IShortUser } from '../User/interfaces';
import { WithPagesModel } from '../WithPagesModel';

export class SystemReferralProgramListItemModel implements ISystemReferralProgramListItemModel {
  id: number;
  code: string;
  user: IShortUser;
  activations: {
    id: number;
    user: IShortUser;
  }[];

  constructor(params: any = {}) {
    this.id = params.id;
    this.code = params.code;
    this.user = params.user;
    this.activations = params.activations;
  }
}

export class SystemReferralProgramListModel extends WithPagesModel implements ISystemReferralProgramListModel {
  data: ISystemReferralProgramListItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemReferralProgramListItemModel);
  }
}
