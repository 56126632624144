import React from 'react';
import { SystemApplication } from '../Application/SystemApplication';
import { SystemParticularApplicationWrapper } from '../Application/SystemParticularApplicationWrapper';
import { SystemParticularApplication } from '../Application/SystemParticularApplication';

const thisSectionName = 'all-applications';

export const SystemOneOfThemAllApplication = () => (
  <SystemParticularApplicationWrapper thisSectionName={thisSectionName}>
    <SystemParticularApplication>
      <SystemApplication />
    </SystemParticularApplication>
  </SystemParticularApplicationWrapper>
);
