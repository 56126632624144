import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';


const getInsurances = (state: RootReducer) => state.system[storeName].insurances;
const getInsurancesIsLoading = (state: RootReducer) => state.system[storeName].insurancesIsLoading;
const getInsuranceById = (id: number) => (state: RootReducer) => state.system[storeName].insurances.data
  .find((insurance) => insurance.id === id);


export const systemInsurancesSelector = createSelector([getInsurances], returnParams);
export const systemInsurancesIsLoadingSelector = createSelector([getInsurancesIsLoading], returnParams);
export const systemInsuranceByidSelector = createSelector([getInsuranceById], returnParams);
