import React, { memo } from 'react';
import {
  Modal, ModalBody, ModalFooter,
} from 'reactstrap';

type Props = {
  isOpen: boolean; // нужно передавать для плавного закрытия
  toggle: () => void;
  children: React.ReactNode | React.ReactNode[];
  headerContent?: string | React.ReactNode | React.ReactNode[];
  footerContent?: string | React.ReactNode | React.ReactNode[];
  footerClassName?: string;
  size?: string;
}

export const UiModal = memo(({
  isOpen, toggle, headerContent, children, footerContent,
  footerClassName = 'justify-content-center', size = 'md',
}: Props) => (
  <Modal isOpen={isOpen} toggle={toggle} size={size}>
    {headerContent && (
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggle}>
          <span aria-hidden="true" />
        </button>
        {headerContent}
      </div>
    )}
    <ModalBody className="d-flex">
      {children}
    </ModalBody>
    {footerContent && (
      <ModalFooter className={footerClassName}>
        {footerContent}
      </ModalFooter>
    )}
  </Modal>
));
