import React from 'react';
import { SystemApplicationComponent } from '../../components/Application/SystemApplication';

export const SystemApplication = ({ model, downloadDocumentUrl, thisSectionName }: any) => (
  <SystemApplicationComponent
    thisSectionName={thisSectionName}
    model={model}
    downloadDocumentUrl={downloadDocumentUrl}
  />
);
