import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { authLogoutStartAction } from './actions';


export const Logout = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();

  useEffect(() => {
    const findToken = /Ntf_token=([^&]+)/.exec(search);

    dispatch(authLogoutStartAction({ ntf_token: findToken ? findToken[1] : undefined }));
  }, [dispatch, search]);

  return null;
};
