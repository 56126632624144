import forEach from 'lodash/forEach';

type entityType = 'cat_' | 'ent_' | 'status_' | 'type_';

export const prepareFilterEntityByKey = (entity: {[s: string]: boolean}, entityKey: entityType): number[] => {
  const formValues: number[] = [];

  forEach(entity, (value, key) => {
    /** 0 не стоит использовать */
    const keyValue = +(key.replace(entityKey, ''));
    if (value && keyValue) {
      formValues.push(keyValue);
    }
  });

  return formValues;
};
