import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from '../../store/baseSaga';
import { httpGet } from '../../utils/http';
import { POLLS_GET_LIST_START } from './constants';
import {
  pollsGetListFailAction,
  pollsGetListOkAction,
} from './actions';
import { PollsModel } from '../../models/Polls/PollsModel';


function* pollsGetListStartSaga({ payload: { filter, append } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/poll', filter);

    yield put(pollsGetListOkAction(new PollsModel(response), append));
  }, pollsGetListFailAction);
}


export function* pollsSagas() {
  yield takeLatest(POLLS_GET_LIST_START, pollsGetListStartSaga);
}
