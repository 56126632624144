import { EMAIL_PATTERN } from 'config/system';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';

import { UiInput } from 'components/common/UI/Input';


type Props = {
  onSubmit: (d: string | null) => void;
  defaultValue: string | null;
}

export const PartEmailForm = ({ onSubmit, defaultValue }: Props) => {
  const {
    handleSubmit, formState, register, errors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: defaultValue,
    },
  });

  const onSubmitLocal = useCallback(({ value }) => onSubmit(value), [onSubmit]);

  return (
    <form className="form-with-btn" onSubmit={handleSubmit(onSubmitLocal)}>
      <div className="form-wrapper_content">
        <UiInput
          name="value"
          register={register}
          errors={errors}
          validation={{
            pattern: {
              value: EMAIL_PATTERN,
              message: 'Некорректный email',
            },
          }}
        />
      </div>
      <div className="form-wrapper_btn">
        <div className="buttons-group buttons-group-responsive">
          <Button
            color="primary"
            type="submit"
            disabled={!formState.isValid}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </form>
  );
};
