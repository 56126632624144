import { NewsCategoryFilter } from '../../../models/News/NewsCategoryModel';
import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import { SYSTEM_GET_NEWS_FAIL, SYSTEM_GET_NEWS_OK, SYSTEM_GET_NEWS_START } from './constants';
import { INewsModel } from '../../../models/News/NewsModel';


export const systemGetNewsStartAction = (filter: NewsCategoryFilter) => dataAction(
  SYSTEM_GET_NEWS_START,
)({ filter });
export const systemGetNewsOkAction = (news: INewsModel) => dataSuccessAction(
  SYSTEM_GET_NEWS_OK,
)({ news });
export const systemGetNewsFailAction = dataErrorAction(SYSTEM_GET_NEWS_FAIL);
