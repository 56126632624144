import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { InfiniteScrollbar } from '../common/InfiniteScrollbar';
import { Loader } from '../common/UI/Loaders';
import { IEventFilterType, IEventsModel } from '../../models/Events/EventModel';
import { EventListItem } from './EventListItem';
import { EventsFilter } from './EventsFilter';


type Props = {
  model: IEventsModel;
  modelIsLoading: boolean;
  getData: (filter?: IEventFilterType, append?: boolean) => void;
  hasMoreData: boolean;
}

export const EventsComponent = ({
  model, modelIsLoading, getData, hasMoreData,
}: Props) => {
  const { t } = useTranslation();

  const onLoadMoreData = (page: number) => {
    getData({
      page,
    }, true);
  };

  const onFilterChanged = useCallback((data: IEventFilterType) => getData(data), [getData]);

  return (
    <>
      <div className="page-header">
        <Link to="/" className="back" />
        {t('event.events.header')}
      </div>
      <div className="box events">
        <InfiniteScrollbar
          currentPage={model.page}
          hasMoreData={hasMoreData && !modelIsLoading}
          onLoadData={onLoadMoreData}
        >
          <section className="form">
            <EventsFilter onChange={onFilterChanged} />
            <div className="articles">
              {(!modelIsLoading && model.data.length === 0) && (
                <h3 className="text-center">{t('event.no_events')}</h3>
              )}
              {model.data.map((item) => (
                <EventListItem key={item.id} item={item} />
              ))}
              {modelIsLoading && <Loader className="mt-3" />}
            </div>
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};

EventsComponent.whyDidYouRender = true;
