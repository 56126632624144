import { IReportHousingList, IReportHousingListItem } from './interfaces';
import { WithPagesModel } from '../WithPagesModel';
import { ReportHousingListItem } from './HousingListItem';


export class ReportHousingList extends WithPagesModel implements IReportHousingList {
  readonly data: IReportHousingListItem[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, ReportHousingListItem);
  }
}
