import { SimpleSelectOptionType } from './convertModelToOptions';
import { IApplicationAssignUser } from '../models/Application/Construct/interfaces';

export const preparedAssignedUsers = (
  usersOptions: SimpleSelectOptionType<number>[],
  assignUsers: IApplicationAssignUser[],
) => {
  if (assignUsers.length > 1) {
    return usersOptions
      .filter((userOption) => assignUsers
        .find((assignUser) => assignUser.id === userOption.value));
  }
  return usersOptions;
};
