import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import { DefaultFilterType } from '../../../models/common';
import {
  SYSTEM_EDUCATION_GET_LIST_FAIL,
  SYSTEM_EDUCATION_GET_LIST_OK,
  SYSTEM_EDUCATION_GET_LIST_START,
} from './constants';
import { ISystemEducationListModel } from '../../../models/Education/interfaces';


export const systemEducationGetListStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_EDUCATION_GET_LIST_START,
)({ filter });
export const systemEducationGetListOkAction = (educationList: ISystemEducationListModel) => dataSuccessAction(
  SYSTEM_EDUCATION_GET_LIST_OK,
)({ educationList });
export const systemEducationGetListFailAction = dataErrorAction(SYSTEM_EDUCATION_GET_LIST_FAIL);
