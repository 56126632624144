import React from 'react';
import { UserAccessEnum } from 'models/User/CurrentUserModel';

import { ReactComponent as LayersIcon } from 'assets/img/layers.svg';
import { ReactComponent as ContactsIcon } from 'assets/img/book-open.svg';
import { ReactComponent as BusIcon } from 'assets/img/bus.svg';
import { ReactComponent as SettingsIcon } from 'assets/img/settings.svg';
import { ReactComponent as FileTextIcon } from 'assets/img/file-text.svg';
import { ReactComponent as ManualIcon } from 'assets/img/manual.svg';
import { ReactComponent as StudentIcon } from 'assets/img/student.svg';
import { ReactComponent as DiscountIcon } from 'assets/img/discount.svg';
import { ReactComponent as FilterIcon } from 'assets/img/filter.svg';
import { ReactComponent as ReferralIcon } from 'assets/img/referral.svg';

export type ClientNav = {
  name: string;
  url: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  right?: string;
  exact?: boolean;
  styles?: string;
  guideStep?: string;
  isDisplayedOnMobileMenu?: boolean;
}

const clientMenu = [
  {
    name: 'applications.menu',
    url: '/applications',
    icon: LayersIcon,
    right: UserAccessEnum.rAppSectionApplications,
    exact: false,
    isDisplayedOnMobileMenu: true,
    guideStep: 'step-4',
  },
  {
    name: 'partners.menu',
    url: '/partners',
    icon: DiscountIcon,
    right: UserAccessEnum.rAppSectionPartners,
    exact: false,
    isDisplayedOnMobileMenu: true,
    guideStep: 'step-6',
  },
  {
    name: 'contacts.menu',
    url: '/contacts',
    icon: ContactsIcon,
    right: UserAccessEnum.rAppSectionContacts,
    exact: false,
    styles: 'd-none d-flex-custom',
    isDisplayedOnMobileMenu: false,
  },
  {
    name: 'busStops.menu',
    url: '/bus-stops',
    icon: BusIcon,
    right: UserAccessEnum.rAppSectionBusStops,
    exact: false,
    styles: 'd-none d-flex-custom',
    isDisplayedOnMobileMenu: false,
  },
  {
    name: 'profile.menu',
    url: '/profile',
    icon: SettingsIcon,
    right: UserAccessEnum.rAppSectionProfile,
    exact: false,
    isDisplayedOnMobileMenu: true,
    guideStep: 'step-7',
  },
  {
    name: 'jobDescriptions.menu',
    url: '/job-descriptions',
    icon: ManualIcon,
    right: UserAccessEnum.rAppSectionJobDescriptions,
    styles: 'd-none d-flex-custom',
    isDisplayedOnMobileMenu: false,
  },
  {
    name: 'medical.menu',
    url: '/medical',
    icon: FileTextIcon,
    right: UserAccessEnum.rAppSectionMedical,
    styles: 'd-none d-flex-custom',
    isDisplayedOnMobileMenu: false,
  },
  {
    name: 'education.menu',
    url: '/education',
    icon: StudentIcon,
    right: UserAccessEnum.rAppSectionEducation,
    styles: 'd-none d-flex-custom',
    isDisplayedOnMobileMenu: false,
  },
  {
    name: 'referral.menu',
    url: '/referral-program',
    icon: ReferralIcon,
    styles: 'd-none d-flex-custom',
    guideStep: 'step-11',
    isDisplayedOnMobileMenu: false,
  },
  /* {
    name: 'bonusProgram.menu',
    url: '/bonus-program',
    icon: GiftIcon,
    styles: 'd-none d-flex-custom',
    isDisplayedOnMobileMenu: false,
  },*/
  {
    name: 'admin.menu',
    url: '/system',
    icon: FilterIcon,
    right: UserAccessEnum.systemModule,
    styles: 'd-none d-flex-custom',
    isDisplayedOnMobileMenu: false,
  },
];

export const clientNav: ClientNav[] = clientMenu;
