import React from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { InsuranceFilterType } from '../../../../models/Insurances/InsurancesModel';
import { UiToolbar } from '../../../../components/common/Toolbar';
import { UiSystemInput } from '../../../../components/common/UI/Input';

type Props = {
  onSubmit: (filter: InsuranceFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  name: string;
}

export const SystemInsuranceFilter = ({ onSubmit, onReset }: Props) => {
  const { register, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });

  return (
    <UiToolbar onSubmitForm={handleSubmit(onSubmit)} onResetForm={onReset}>
      <Row>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label="Название страховой программы"
            name="name"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
