/* eslint-disable max-len */
export const SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_START = '[SYSTEM BONUS PROGRAM] get bonus program prize list [start]';
export const SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_OK = '[SYSTEM BONUS PROGRAM] get bonus program prize list [ok]';
export const SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_FAIL = '[SYSTEM BONUS PROGRAM] get bonus program prize list [fail]';

export const SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_START = '[SYSTEM BONUS PROGRAM] get bonus program event history list [start]';
export const SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_OK = '[SYSTEM BONUS PROGRAM] get bonus program event history list [ok]';
export const SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_FAIL = '[SYSTEM BONUS PROGRAM] get bonus program event history list [fail]';

export const SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_START = '[SYSTEM BONUS PROGRAM] get bonus program event list [start]';
export const SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_OK = '[SYSTEM BONUS PROGRAM] get bonus program event list [ok]';
export const SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_FAIL = '[SYSTEM BONUS PROGRAM] get bonus program event list [fail]';
