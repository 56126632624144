import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { EntityNotFound } from 'components/Errors/404';
import { Loader } from 'components/common/UI/Loaders';
import { IPageAgreementModel } from 'models/References/PageAgreement';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getSystemPageAgreement, saveSystemPageAgreement } from 'systemModule/api/references/page';
import { SystemPageAgreementComponent } from 'systemModule/components/PageAgreement';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';


export const SystemPageAgreement = () => {
  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wPages);

  const [model, loading] = usePromise<IPageAgreementModel>(getSystemPageAgreement, true);

  const onSave = useCallback(async (model: IPageAgreementModel) => {
    handleErrors(
      await saveSystemPageAgreement(model),
      'save',
      () => toast.success(t('system.references.page.agreement.saved')),
    );
  }, [t]);


  if (loading) {
    return <Loader />;
  }
  if (model) {
    return <SystemPageAgreementComponent canUpdate={canUpdate} model={model} onSave={onSave} />;
  }
  return <EntityNotFound message={t('system.references.page.agreement.404')} />;
};
