import { IWithPages } from '../WithPagesModel';
import { DefaultFilterType, FileType } from '../common';
import { IShortUser } from '../User/interfaces';
import { IDepartmentItemModel } from '../References/DepartmentModel';


export type IApplicationFilter = DefaultFilterType & {
  status_id?: number;
  type_id?: number;
  search?: string;
  enterprise_id?: number[];
}

export type IAllApplicationFilter = IApplicationFilter & {
  assign_id?: number;
  colleague?: number;
}

export type IApplicationMyListFilter = DefaultFilterType & {
  status_id?: number[];
  type_id?: number[];
  search?: string;
}

export type IApplicationMyListModel = {
  id: number;
  status: {id: number; name: string};
  type: {id: number; name: string};
  created_at: Date;
  updated_at: Date;
  author: IShortUser;
  assign: IShortUser;
}

export interface IApplicationsMyListModel extends IWithPages {
  data: IApplicationMyListModel[];
}

export type IApplicationListModel = {
  id: number;
  status: {id: number; name: string};
  type: {id: number; name: string};
  created_at: Date;
  updated_at: Date;
  author: {id: number; fio: string; phone: string};
  assign: {id: number; fio: string; phone: string};
  enterprise: string;
};


export interface IApplicationsListModel extends IWithPages {
  data: IApplicationListModel[];
}


export type IApplicationModelAdditionalFields = {
  readonly block_id: number;
  readonly fields: {
    name: string;
    type: string;
    value: string | null | number;
  }[];
  readonly has_template: boolean;
  readonly name: string;
}[]

export interface IApplicationModel {
  id: number;
  status: {id: number; name: string};
  type: {id: number; name: string};
  addSdsCoin?: boolean;
  author: IShortUser;
  assign: IShortUser; // на кого заявка назначена в текущий момент
  comments: {
    id: number;
    content: string;
    author: {
      id: number;
      fio: string;
      phone: string;
    };
    created_at: Date;
    files: FileType[];
  }[];
  department?: IDepartmentItemModel;
  responsible: {
    id: number;
    user: IShortUser;
    created_at: Date;
  }[]; // массив всех, на кого когда-либо назначалась заявка
  created_at: Date;
  updated_at: Date;
  enterprise: string;
  description: string;
  file: FileType | null;
  additionalFields?: IApplicationModelAdditionalFields;

  /** даты, в больничном например */
  date_start?: Date;
  date_end?: Date;
}

/** данные действий с заявками */
export type ApplicationRequestType = {
  assign_id?: number;
  comment?: string | null;
  files?: string[];
  add_sdscoin?: boolean
} | undefined;

/** статусы заявок */
export enum ApplicationStatusEnum {
  'new' = 5, // новая
  'assign' = 10, // назначена другому
  'work' = 15, // в работе
  'cancel' = 20, // отменена
  'rejected' = 25, // отклонена
  'done' = 30, // выполнена
  'approval' = 35 // требуется согласование
}

/** типы заявок, которым доступно начисление бонусов */
export enum ApplicationTypeEnum {
  'feedback' = -4 // обратная связь
}

/** отделы, которым доступно начисление бонусов */
export enum ApplicationDepartmentEnum {
  IT = 'it' // it-отдел
}

export interface LocalApplicationFilterType {
  status_id: number[];
  type_id: number[];
  search?: string;
}
