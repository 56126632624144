import React from 'react';

import { ISystemBusStopModel } from 'models/References/BusStopsModel';
import { useTranslation } from 'react-i18next';
import {
  Button, Card, Col, Row,
} from 'reactstrap';
import {
  FormBlockType,
  FormValues,
  MAX_TRANSPORT_BLOCKS_AMOUNT,
} from 'systemModule/components/Transport/common';
import { UiSelect } from 'components/common/UI/Select';
import { convertModelToOptions } from 'utils/convertModelToOptions';
import { FieldErrors } from 'react-hook-form';
import { ScheduleFields } from 'systemModule/components/Transport/SheduleFields';
import { ScheduleHolidays } from 'systemModule/components/Transport/SheduleHolidays';

type Props = {
  blockIndex: number;
  getValues: (s?: string) => any;
  errors: FieldErrors<FormValues>;
  register: (s?: any) => any;
  unregister: (s?: any) => any;
  triggerValidation: () => any;
  busStops: ISystemBusStopModel[];
  block: FormBlockType;
  setValue: (s: string, v: any, sv?: boolean) => any;
  appendBlock: () => void;
  blockAmount: number;
  removeBlock: (id: number) => void;
  last: boolean;
}

export function ScheduleBlock({
  blockIndex, getValues, errors, register, busStops, block, setValue, unregister,
  appendBlock, blockAmount, removeBlock, last, triggerValidation,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card body>
      <Row>
        <Col>
          <UiSelect
            defaultValue={getValues(`blocks[${blockIndex}].busStop`)}
            register={register}
            setValue={setValue}
            errors={errors}
            name={`blocks[${blockIndex}].busStop`}
            label={t('transport.form.busStop')}
            options={convertModelToOptions(busStops)}
            required
          />
        </Col>
      </Row>

      <ScheduleFields
        blockIndex={blockIndex}
        errors={errors}
        register={register}
        unregister={unregister}
        fields={block.fields || []}
        setValue={setValue}
        getValues={getValues}
        triggerValidation={triggerValidation}
      />

      <ScheduleHolidays
        blockIndex={blockIndex}
        errors={errors}
        register={register}
        unregister={unregister}
        fields={block.holidaysFields || []}
        dates={block.holidaysDates || []}
        setValue={setValue}
        getValues={getValues}
        triggerValidation={triggerValidation}
      />

      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-end">
          {blockAmount > 1 && (
            <Button
              type="button"
              color="danger"
              onClick={() => removeBlock(block.id)}
              className="mr-3"
            >
              {t('system.transport.route.delBusStop')}
            </Button>
          )}
          {(last && blockAmount < MAX_TRANSPORT_BLOCKS_AMOUNT) && (
            <Button
              type="button"
              color="success"
              onClick={appendBlock}
            >
              {t('system.transport.route.addBusStop')}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
}
