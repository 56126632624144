import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';
import { customHistory } from 'customHistory';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { Loader } from 'components/common/UI/Loaders';
import {
  ConstructApplicationTemplateListItemModel,
} from 'models/ConstructApplication/ConstructApplicationTemplateListModel';
import {
  ConstructApplicationTemplateFormRawFileType,
  IConstructApplicationTemplateListItemModel,
} from 'models/ConstructApplication/interfaces';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  createConstructApplicationTemplate, getConstructApplicationTemplateById, updateConstructApplicationTemplate,
} from 'systemModule/api/construct-application';
import {
  ConstructApplicationTemplateFormComponent,
} from 'systemModule/components/ConstructApplication/ConstructApplicationTemplateForm';
import { systemEnterprisesSelector } from 'systemModule/store/selectors';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';


export const ConstructApplicationTemplateForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wApplication);

  const [model, modelLoading] = usePromise<IConstructApplicationTemplateListItemModel>(
    () => getConstructApplicationTemplateById(numberId),
    !isNaN(numberId),
    [numberId, numberId],
  );

  const onSave = useCallback(async (modelToSave: ConstructApplicationTemplateFormRawFileType) => {
    setLoading(true);

    try {
      const file = await new UploadFiles([modelToSave.file]).upload();

      if (Array.isArray(file)) {
        const saveModel = modelToSave.isNew ? createConstructApplicationTemplate : updateConstructApplicationTemplate;

        handleErrors(
          await saveModel({ ...modelToSave, file: file[0].file_name }),
          'save',
          () => {
            toast.success(t(`system.construct_application.template.${modelToSave.isNew ? 'created' : 'updated'}`));
            customHistory.push('/system/construct-application/template');
          },
        );
      }
    } catch (e) {
      toast.error('Ошибка сохранения шаблона');
    } finally {
      setLoading(false);
    }
  }, [t]);

  if (modelLoading) {
    return <Loader />;
  }
  return (
    <ConstructApplicationTemplateFormComponent
      model={model || new ConstructApplicationTemplateListItemModel()}
      canUpdate={canUpdate}
      enterprises={enterprises}
      onSave={onSave}
      loading={loading}
    />
  );
};
