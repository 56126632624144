import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ISystemMedicalFilterType, ISystemMedicalModel } from '../../../models/Medical/SystemMedicalModel';
import { systemGetMedicalStartAction } from './actions';
import { SystemMedicalComponent } from '../../components/Medical/SystemMedical';
import { systemMedicalIsLoadingListSelector, systemMedicalListSelector } from './selectors';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { systemEnterprisesSelector, systemGlobalUnitsSelector } from '../../store/selectors';
import { ISystemUnitModel } from '../../../models/References/UnitsModel';

export const SystemMedical = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: ISystemMedicalFilterType) => {
    dispatch(systemGetMedicalStartAction(filter));
  }, [dispatch]);

  const data: ISystemMedicalModel = useSelector(systemMedicalListSelector);
  const isLoading: boolean = useSelector(systemMedicalIsLoadingListSelector);
  const enterpriseList: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);
  const unitList: ISystemUnitModel[] = useSelector(systemGlobalUnitsSelector);

  return (
    <SystemMedicalComponent
      getData={getData}
      data={data}
      isLoading={isLoading}
      enterpriseList={enterpriseList}
      unitList={unitList}
    />
  );
};
