import React from 'react';

import { getPageAgreement } from 'api/pageAgreement';
import { UserAgreementComponent } from 'components/Auth/UserAgreement';
import { IPageAgreementModel } from 'models/References/PageAgreement';
import { usePromise } from 'utils/hooks/usePromise';


export const UserAgreementPage = () => {
  const [model, loading] = usePromise<IPageAgreementModel>(getPageAgreement, true);

  return <UserAgreementComponent model={model} loading={loading} />;
};
