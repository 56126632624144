import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { UserAccessEnum } from 'models/User/CurrentUserModel';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';
import { UiSystemForm } from 'components/common/UI/Form';
import { useSelector } from 'react-redux';
import { hasRight } from 'store/currentUser/selectors';
import {
  ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationLink, Row,
} from 'reactstrap';
import chunkFn from 'lodash/chunk';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { ReduceDefaultValueType } from '../../containers/UserEnterprises/SystemUserEnterpriseSettings';
import { defaultSizeByPageTable } from '../../../config/system';

export type FormValues = Record<string, boolean>;

type Props = {
  enterpriseList: IEnterpriseModel[];
  onSave: (data: Record<string, string[]>) => void;
  defaultValue: ReduceDefaultValueType;
}

export const SystemUserEnterpriseSettingsComponent = ({
  enterpriseList, onSave, defaultValue,
}: Props) => {
  const hasEditRight = useSelector(hasRight(UserAccessEnum.w_notify_user_enterprises));
  const {
    register, handleSubmit, reset,
    formState: { isSubmitting, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { t } = useTranslation();
  const perPageText = t('common.table.showPerPage');
  const [isOpenSpp, setIsOpenSpp] = useState(false);

  const initialPage = 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [countPostsPage, setCountPostsPage] = useState(20);
  const enterprisesChunk: IEnterpriseModel[][] = chunkFn(enterpriseList, countPostsPage);

  const onSubmit = (data: FormValues) => {
    const keys = Object.keys(data);
    const arrResult: string[] = defaultValue ? Object.keys(defaultValue) : [];
    keys.forEach((key: string) => {
      if (data[key] && !arrResult.includes(key)) {
        arrResult.push(key);
      }
      if (!data[key] && arrResult.includes(key)) {
        arrResult.splice(arrResult.indexOf(key), 1);
      }
    });
    onSave({ enterprises: arrResult });
  };

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue, reset]);

  return (
    <>
      <UiSystemForm
        onSubmit={handleSubmit(onSubmit)}
        disabledSubmit={!isValid}
        loadingSubmit={isSubmitting}
        dirtyFieldsAmount={0}
        showButtons={hasEditRight}
      >
        <h3>Настройки предприятий для оповещений</h3>

        <Col className="col-user-enterprises">
          {perPageText}
          <ButtonDropdown className="ml-1" isOpen={isOpenSpp} toggle={() => setIsOpenSpp(!isOpenSpp)}>
            <DropdownToggle caret color="secondary">
              {countPostsPage}
            </DropdownToggle>
            <DropdownMenu>
              {defaultSizeByPageTable.map((i: number) => (
                <DropdownItem
                  key={i}
                  onClick={() => {
                    setCurrentPage(initialPage);
                    setCountPostsPage(i);
                  }}
                >
                  {i}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        {
          (enterprisesChunk[currentPage - 1].map((item: IEnterpriseModel) => (

            <UiSystemCheckbox
              key={item.id}
              name={(item.id).toString()}
              register={register}
              label={item.name}
              isDisabled={!hasEditRight}
            />
          )))
        }
        {
          // не выводить, если только 1 страница
          (enterprisesChunk.length > 1) && (
            <Row>
              <Col className="d-flex justify-content-center">
                <Pagination>
                  <ReactPaginate
                    previousLabel={currentPage === 1 ? null : <PaginationLink previous />}
                    nextLabel={enterprisesChunk.length === currentPage ? null : <PaginationLink next />}
                    breakLabel={<PaginationLink>...</PaginationLink>}
                    initialPage={currentPage - 1}
                    forcePage={currentPage - 1}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    pageCount={enterprisesChunk.length}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={({ selected }: { selected: number }) => setCurrentPage(selected + 1)}
                    containerClassName="pagination custom-pagination"
                    activeClassName="active"
                  />
                </Pagination>
              </Col>
            </Row>
          )
        }
      </UiSystemForm>
    </>
  );
};
