import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';

import { UiRadioGroup } from 'components/common/UI/RadioGroup';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';


type Props = {
  onSubmit: (d: number) => void;
  defaultValue: number;
  enterpriseOptions: SimpleSelectOptionType<number>[];
}

export const PartEnterpriseForm = ({ onSubmit, defaultValue, enterpriseOptions }: Props) => {
  const {
    handleSubmit, formState, register,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: `${defaultValue}`,
    },
  });

  const preparedOptions = enterpriseOptions.map((opt) => ({ value: opt.value.toString(), label: opt.label }));

  const onSubmitLocal = useCallback(({ value }) => onSubmit(+value), [onSubmit]);

  return (
    <form className="form-with-btn" onSubmit={handleSubmit(onSubmitLocal)}>
      <div className="form-wrapper_content">
        <div className="form-group">
          <UiRadioGroup name="value" options={preparedOptions} register={register} />
        </div>
      </div>
      <div className="form-wrapper_btn">
        <div className="buttons-group buttons-group-responsive">
          <Button
            color="primary"
            type="submit"
            disabled={!formState.isValid}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </form>
  );
};
