import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormGroup } from 'reactstrap';
import React from 'react';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiWISWYGeditor } from 'components/common/UiWISWYGeditor';
import { IPageReferralProgramModel } from 'models/References/PageReferralProgramModel';

type Props = {
  canUpdate:boolean;
  model: IPageReferralProgramModel;
  onSave: (model: IPageReferralProgramModel) => void;
}

type FormValues = {
  text: string;
}

export const SystemPageReferralProgramComponent = ({ canUpdate, model, onSave }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, watch, setValue, formState: { isValid, isSubmitting, dirtyFields },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      text: model.text,
    },
  });

  const onSubmit = (data: FormValues) => onSave({
    name: model.name,
    text: data.text,
  });

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      showButtons={canUpdate}
    >
      <h3>{t('system.references.page.referralProgram.form.title')}</h3>

      <FormGroup>
        <strong>
          {t('system.references.page.referralProgram.form.text')}
        </strong>
        <UiWISWYGeditor register={register} watch={watch} setValue={setValue} name="text" />
      </FormGroup>
    </UiSystemForm>
  );
};
