import React, { useCallback, useEffect, useState } from 'react';

import { getSystemReportSport, getSystemReportSportEnterprise } from '../../../api/reports';
import { handleErrors } from '../../../utils/errors';
import { IReportSport, IReportSportEnterprise } from '../../../models/Reports/interfaces';
import { SystemReportSportIndexComponent } from '../../components/Reports/Sport';


export const SystemReportSport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState<IReportSport | IReportSportEnterprise | null>(null);

  const getData = useCallback(async (enterprise_ids?: number[]) => {
    setIsLoading(true);

    const apiFn = enterprise_ids ?
      () => getSystemReportSportEnterprise(enterprise_ids) :
      getSystemReportSport;

    handleErrors(
      await apiFn(),
      'get',
      setModel,
      () => setIsLoading(false),
    );
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <SystemReportSportIndexComponent
      model={model}
      isLoading={isLoading}
      getData={getData}
    />
  );
};
