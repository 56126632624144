import {
  IConstructApplicationCategoryListItemModel, IConstructApplicationCategoryListModel,
} from 'models/ConstructApplication/interfaces';
import { WithPagesModel } from 'models/WithPagesModel';


export class ConstructApplicationCategoryListItemModel implements IConstructApplicationCategoryListItemModel {
  readonly id: number;
  readonly name: string;
  readonly can_delete: boolean;

  constructor(params: any = {}) {
    this.id = params.id || 0;
    this.name = params.name || '';
    this.can_delete = params.can_delete || false;
  }

  get isNew() {
    return this.id === 0;
  }
}


export class ConstructApplicationCategoryListModel extends WithPagesModel
  implements IConstructApplicationCategoryListModel {
  readonly data: IConstructApplicationCategoryListItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, ConstructApplicationCategoryListItemModel);
  }
}
