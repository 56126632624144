import { baseApiFn } from '../utils/baseApiFn';
import { httpGet } from '../utils/http';
import { ReportApplication } from '../models/Reports/Application';
import {
  IReportApplication,
  IReportApplicationEnterprise, IReportHousingList,
  IReportInsurance, IReportInsuranceEnterprise,
  IReportSport, IReportSportEnterprise,
} from '../models/Reports/interfaces';
import { ReportInsurance } from '../models/Reports/Insurance';
import { ReportSport } from '../models/Reports/Sport';
import { ReportApplicationEnterprise } from '../models/Reports/ApplicationEnterprise';
import { ReportInsuranceEnterprise } from '../models/Reports/ReportInsuranceEnterprise';
import { ReportSportEnterprise } from '../models/Reports/SportEnterprise';
import { ReportHousingList } from '../models/Reports/HousingList';
import { DefaultFilterType } from '../models/common';


/** Статистика по заявкам */
export const getSystemReportApplication = async () => baseApiFn<IReportApplication>(async () => {
  const response = await httpGet('/system/reports/application-statistic');
  return new ReportApplication(response);
});

/** Статистика по заявкам с группировкой по предприятиям */
export const getSystemReportApplicationEnterprise = async (
  enterprise_ids?: number[],
) => baseApiFn<IReportApplicationEnterprise>(async () => {
  const response = await httpGet('/system/reports/application-enterprise-statistic', { enterprise_ids });
  return new ReportApplicationEnterprise(response);
});


/** Статистика по страхованиям */
export const getSystemReportInsurance = async () => baseApiFn<IReportInsurance>(async () => {
  const response = await httpGet('/system/reports/insurance-statistic');
  return new ReportInsurance(response);
});

/** Статистика по страхованиям с группировкой по предприятиям */
export const getSystemReportInsuranceEnterprise = async (
  enterprise_ids?: number[],
) => baseApiFn<IReportInsuranceEnterprise>(async () => {
  const response = await httpGet('/system/reports/insurance-enterprise-statistic', { enterprise_ids });
  return new ReportInsuranceEnterprise(response);
});


/** Статистика по состоянию жилья */
export const getSystemReportHousing = async (filter: DefaultFilterType) => baseApiFn<IReportHousingList>(async () => {
  const response = await httpGet('/system/reports/housing', filter);
  return new ReportHousingList(response);
});


/** Статистика по видам спорта */
export const getSystemReportSport = async () => baseApiFn<IReportSport>(async () => {
  const response = await httpGet('/system/reports/sport-statistic');
  return new ReportSport(response);
});

/** Статистика по видам спорта с группировкой по предприятиям */
export const getSystemReportSportEnterprise = async (
  enterprise_ids?: number[],
) => baseApiFn<IReportSportEnterprise>(async () => {
  const response = await httpGet('/system/reports/sport-enterprise-statistic', { enterprise_ids });
  return new ReportSportEnterprise(response);
});
