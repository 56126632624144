import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-react';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
import Base64Upload from '../../utils/CKEditor/base64upload';
import { Loader } from './UI/Loaders';


type Props = {
  register: (s: string) => void;
  name: string;
  watch: (s: string) => any;
  setValue: (s: string, v: any) => void;
}

export const UiWISWYGeditor = ({
  watch, setValue, register, name,
}: Props) => {
  const [loading, setLoading] = useState(true);

  const ref = useRef<any>();

  useEffect(() => {
    (async () => {
      const editor = await import('@ckeditor/ckeditor5-build-classic');
      ref.current = editor.default;
      setLoading(false);
    })();

    register(name);
  }, [register, name]);

  if (loading) {
    return <Loader />;
  }
  return (
    <CKEditor
      config={{ language: 'ru', extraPlugins: [Base64Upload] }}
      editor={ref.current}
      data={watch(name)}
      onChange={(event: unknown, editor: any) => {
        const data = editor.getData();
        setValue(name, data);
      }}
    />
  );
};
