import get from 'lodash/get';
import React, { useCallback, useEffect } from 'react';
import { FieldErrors } from 'react-hook-form';
import {
  Button, Card, Col, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { UiFileUploaderSystem } from 'components/common/FileUploader';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';
import { UiSystemInput } from 'components/common/UI/Input';
import { emptyOptionValue } from 'components/common/UI/Select';
import { FormBlockType, FormValues, MAX_BLOCKS_AMOUNT } from 'systemModule/components/ConstructApplication/Type/common';
import {
  PartFieldsComponent,
} from 'systemModule/components/ConstructApplication/Type/ConstructApplicationTypeFields/_fields';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';


const allowedFileTypes = ['docx'];


type Props = {
  blockIndex: number;
  getValues: (s?: string) => FormValues;
  errors: FieldErrors<FormValues>;
  register: (s?: any) => any;
  templatesOptions: SimpleSelectOptionType<number>[];
  block: FormBlockType;
  setValue: (s: string, v: any, sv?: boolean) => any;
  triggerValidation: any;
  unregister: (s?: any) => any;
  appendBlock: () => void;
  blockAmount: number;
  removeBlock: (id: number) => void;
  appendField: () => void;
  removeField: (fieldIndex: string) => void;
  last: boolean;
  setError: (n: string, m: string) => void;
  clearError: (n: string) => void;
}

export const PartBlock = ({
  blockIndex, getValues, errors, register, templatesOptions, block, setValue, triggerValidation, unregister,
  appendBlock, blockAmount, removeBlock, appendField, removeField, last, setError, clearError,
}: Props) => {
  const generateOn = getValues(`blocks[${blockIndex}].generate`);

  /** подписаться на изменения галочки и менять логику формы */
  useEffect(() => {
    if (generateOn) {
      unregister(`blocks[${blockIndex}].template_id`);
    } else {
      setValue(`blocks[${blockIndex}].file`, null, true);
    }
  }, [generateOn, blockIndex, setValue, triggerValidation, unregister]);

  const template_id = getValues(`blocks[${blockIndex}].template_id`);
  const file = getValues(`blocks[${blockIndex}].file`);

  /** проверять наличие файла или шаблона при вкл галочке */
  useEffect(() => {
    if (generateOn) {
      if (+template_id === emptyOptionValue && !(file instanceof File)) {
        setError(`blocks[${blockIndex}].noFileOrTemplate`, 'noFileOrTemplate');
      } else {
        clearError(`blocks[${blockIndex}].noFileOrTemplate`);
      }
    } else {
      clearError(`blocks[${blockIndex}].noFileOrTemplate`);
    }
  }, [
    generateOn, clearError, setError, blockIndex,
    template_id, file,
  ]);

  const onFileChange = useCallback(
    (files: File[]) => setValue(`blocks[${blockIndex}].file`, files[0], true),
    [blockIndex, setValue],
  );

  return (
    <Card body className="construct-application-type-field">
      <Row>
        <Col xs={4}>
          <UiSystemInput
            name={`blocks[${blockIndex}].name`}
            label="Название блока"
            errors={errors}
            register={register}
            defaultValue={block.name}
            required
          />
        </Col>
        <Col>
          <UiSystemCheckbox
            id={`${blockIndex}-1`}
            register={register}
            name={`blocks[${blockIndex}].generate`}
            label="Поля для генерации документа"
            className="construct-application-type-field--generate-checkbox"
          />
        </Col>
      </Row>

      {generateOn && (
        <Row>
          {get(errors, `blocks[${blockIndex}].noFileOrTemplate`, false) && (
            <Col xl={9} lg={7} className="mb-3">
              <FormGroup className="has-danger">
                <div className="form-control-feedback text-center">
                  Выберите шаблон или файл
                </div>
              </FormGroup>
            </Col>
          )}
          <Col xl={4} lg={6}>
            <FormGroup
              className={get(errors, `blocks[${blockIndex}].template_id`, false) ? 'has-danger' : undefined}
            >
              <Label htmlFor={`${blockIndex}`}>
                Выберите или загрузите шаблон файла с метками
              </Label>
              <Input
                id={`${blockIndex}`}
                type="select"
                defaultValue={block.template_id}
                name={`blocks[${blockIndex}].template_id`}
                innerRef={register()}
              >
                <option value={emptyOptionValue}>Не выбрано</option>
                {templatesOptions.map((option: SimpleSelectOptionType) => (
                  <option key={`${option.label}_${option.value}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>
              {get(errors, `blocks[${blockIndex}].template_id`, false) && (
                <div className="form-control-feedback m-0 form-error-block">
                  Обязательное поле
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xl={4} lg={6}>
            <FormGroup>
              <UiFileUploaderSystem
                initFileTypes={block.fileType ? [block.fileType] : []}
                maxFilesCount={1}
                onChange={onFileChange}
                accept={allowedFileTypes}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      <hr className="input-hr mb-3" />

      <PartFieldsComponent
        fields={block.fields}
        blockIndex={blockIndex}
        errors={errors}
        register={register}
        showGenerateFields={Boolean(generateOn)}
        appendField={appendField}
        removeField={removeField}
      />

      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-end">
          {blockAmount > 1 && (
            <Button
              type="button"
              color="danger"
              onClick={() => removeBlock(block.id)}
              className="mr-3"
            >
              Удалить блок
            </Button>
          )}
          {(last && blockAmount < MAX_BLOCKS_AMOUNT) && (
            <Button
              type="button"
              color="success"
              onClick={appendBlock}
            >
              Добавить блок
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};
