import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import {
  IConstructApplicationCategoryListItemModel,
  IConstructApplicationCategoryListModel,
} from 'models/ConstructApplication/interfaces';
import { CustomTable } from 'components/common/Table';
import { DefaultFilterType } from 'models/common';
import { useDataPage } from 'utils/hooks/useDataPage';


type Props = {
  data: IConstructApplicationCategoryListModel;
  isLoading: boolean;
  getData: (filter: DefaultFilterType) => void;
  canCreate: boolean;
  canDelete: boolean;
  onDelete: (item: IConstructApplicationCategoryListItemModel) => void;
}

export const ConstructApplicationCategoryListComponent = ({
  data, isLoading, getData, canCreate, canDelete, onDelete,
}: Props) => {
  const {
    setLimit, setPage,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  return (
    <>
      <h3>{t('system.construct_application.category.list')}</h3>
      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: 'ID' },
          { name: t('system.construct_application.category.name') },
          { name: t('system.construct_application.can_delete') },
          { name: t('common.table.actions') },
        ]}
        customButtons={canCreate && (
          <Button tag={Link} color="primary" to="category/create">
            {t('system.construct_application.category.create')}
          </Button>
        )}
      >
        {data.data.map((item: IConstructApplicationCategoryListItemModel) => (
          <tr key={item.id}>
            <td>
              <Link to={`category/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              <Link to={`category/${item.id}`}>{item.name}</Link>
            </td>
            <td>
              {t(item.can_delete ? 'common.yes' : 'common.no')}
            </td>
            <td>
              {canDelete && (
                <Button type="button" color="danger" onClick={() => onDelete(item)}>
                  {t('common.form.delete')}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
