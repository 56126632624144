import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SystemEducationsComponent } from '../../components/Education/SystemEducations';
import { DefaultFilterType } from '../../../models/common';
import { systemEducationGetListStartAction } from './actions';
import { systemEducationListIsLoadingSelector, systemEducationListSelector } from './selectors';
import { ISystemEducationListModel } from '../../../models/Education/interfaces';


export const SystemEducationsList = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemEducationGetListStartAction(filter));
  }, [dispatch]);

  const data: ISystemEducationListModel = useSelector(systemEducationListSelector);
  const isLoading: boolean = useSelector(systemEducationListIsLoadingSelector);

  return <SystemEducationsComponent data={data} isLoading={isLoading} getData={getData} />;
};
