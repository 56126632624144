import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EducationListItemComponent } from 'components/Education/EducationListItem';
import { Loader } from 'components/common/UI/Loaders';
import { InfiniteScrollbar } from 'components/common/InfiniteScrollbar';
import { UiInputSearch } from 'components/common/UI/InputSearch';
import { EducationListFtilerType, IEducationListModel } from 'models/Education/interfaces';


type Props = {
  data: IEducationListModel;
  isLoading: boolean;
  clearLoad: boolean;
  hasMoreData: boolean;
  getData: (filter?: EducationListFtilerType, append?: boolean) => void;
}

type FormValues = {
  search: string;
}

export const EducationListComponent = ({
  data, isLoading, clearLoad, hasMoreData, getData,
}: Props) => {
  const { register, handleSubmit, getValues } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  const { t } = useTranslation();

  /** подгрузка данных при скролле */
  const onLoadMoreData = (page: number) => {
    getData({
      search: getValues('search'),
      page,
      'per-page': data.limit,
    }, true);
  };

  const onSubmit = useCallback(({ search }: FormValues) => {
    getData({ search, 'per-page': data.limit });
  }, [data.limit, getData]);

  /** начальная загрузка данных */
  useEffect(() => {
    getData({ search: getValues('search'), 'per-page': data.limit });
  }, [getValues, getData, data.limit]);

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/" />
        {t('education.menu')}
      </div>

      <div className="box education">
        <InfiniteScrollbar
          hasMoreData={hasMoreData && !isLoading}
          onLoadData={onLoadMoreData}
          currentPage={data.page}
        >
          <section className="form">
            <div className="d-flex">
              <UiInputSearch
                register={register}
                name="search"
                handleSubmit={handleSubmit(onSubmit)}
              />
            </div>

            {!clearLoad && data.data.map((item) => (
              <EducationListItemComponent key={item.id} item={item} />
            ))}

            {(!isLoading && data.data.length === 0) && <h3 className="text-center">Нет обучений</h3>}
            {isLoading && <Loader className="mt-3" />}
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};
