import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { SYSTEM_GET_POLLS_START } from './constants';
import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import {
  systemGetPollsFailAction,
  systemGetPollsOkAction,
} from './actions';
import { SystemPollsModel } from '../../../models/Polls/SystemPollsModel';


function* systemGetPollsSaga({ payload: { filter, all } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet(all ? '/system/poll/all' : '/system/poll', filter);

    yield put(systemGetPollsOkAction(new SystemPollsModel(response)));
  }, systemGetPollsFailAction);
}

export function* systemPollSagas() {
  yield takeLatest(SYSTEM_GET_POLLS_START, systemGetPollsSaga);
}
