import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import 'slick-carousel/slick/slick.css';

import { App } from 'App';
import { configureStore } from 'utils/configureStore';
import { customHistory } from 'customHistory';

import 'utils/i18n';
import { subscribeOnOffline } from 'utils/offline';
import { subscribeOnGetNotificationsByIds } from 'utils/outerFunctions';

import { YandexMetrika } from 'components/YandexMetrika';

import 'array-flat-polyfill';

require('es7-object-polyfill'); // полифилы object.values и object.entries

export const store = configureStore(customHistory);

subscribeOnGetNotificationsByIds();

subscribeOnOffline();

// лог ошибок в продакшене
if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_RELEASE_VERSION,
    // integrations: [new Integrations.BrowserTracing()], // логирует все подряд
    tracesSampleRate: 1.0, // Be sure to lower this in production
  });
}

ReactDOM.render(
  <Provider store={store}>
    <YandexMetrika />
    <App />
  </Provider>,
  document.getElementById('root'),
);
