import React from 'react';
import { TFunctionResult } from 'i18next';

export type ListElementBaseProps = {
  text: string | number | TFunctionResult;

  info?: string;
  children?: React.ReactNode | React.ReactNode[];
  isTitle?: boolean;
  isPrimary?: boolean;
  isFollow?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  endIconClassName?: string;
}

export const ListElementBaseContent = ({
  info,
  children,
  text,
  isTitle,
  isPrimary,
  isFollow,
  startIcon,
  endIcon,
  endIconClassName,
}: ListElementBaseProps) => {
  const titleClassName = ['line'];
  if (isTitle) {
    titleClassName.push('title');
  }
  if (isPrimary) {
    titleClassName.push('text-primary');
  }

  const endIconClass = ['list-element_icon'];
  if (endIconClassName) {
    endIconClass.push(endIconClassName);
  }

  return (
    <>
      {startIcon && (
        <div className="list-element_icon">
          {startIcon}
        </div>
      )}
      <div className="list-element_content">
        {info && (
          <div className="line info">
            <div className={`item ${text && text.toString().length ? 'small' : 'big'}`}>{info}</div>
          </div>
        )}
        {(text && !!text.toString().length) && (
          <div className={titleClassName.join(' ')}>{text}</div>
        )}
        {children && (
          <div className="line info">
            {children}
          </div>
        )}
      </div>
      {endIcon && (
        <div className={endIconClass.join(' ')}>
          {endIcon}
        </div>
      )}
      {isFollow && (
        <div className="list-element_direction follow" />
      )}
    </>
  );
};
