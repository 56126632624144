import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  ISystemBonusProgramPrizeItemModel,
  ISystemBonusProgramPrizeListModel,
  ISystemBonusProgramPrizeFilter,
} from 'models/BonusProgram/Prize/interfaces';
import {
  SystemBonusProgramPrizeListComponent,
} from 'systemModule/components/BonusProgram/BonusProgramPrizes/SystemBonusProgramPrizeList';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getParamsFromPagesModel } from 'models/WithPagesModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { handleErrors } from 'utils/errors';
import { deleteSystemBonusProgramPrizeItem } from 'systemModule/api/bonusProgram';
import { systemBonusProgramGetPrizeListStartAction } from 'systemModule/containers/BonusProgram/actions';
import {
  systemBonusProgramPrizeListLoadingSelector,
  systemBonusProgramPrizeListSelector,
} from 'systemModule/containers/BonusProgram/selectors';

export const SystemBonusProgramPrizeList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getData = useCallback((filter: ISystemBonusProgramPrizeFilter = {}) => {
    dispatch(systemBonusProgramGetPrizeListStartAction(filter));
  }, [dispatch]);

  const data: ISystemBonusProgramPrizeListModel = useSelector(systemBonusProgramPrizeListSelector);
  const isLoading: boolean = useSelector(systemBonusProgramPrizeListLoadingSelector);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreatePrize = currentUser.hasPermission(UserAccessEnum.cBonusProgramPrize);
  const canDeletePrize = currentUser.hasPermission(UserAccessEnum.dBonusProgramPrize);

  const deletePrize = useCallback(({ id, title }: ISystemBonusProgramPrizeItemModel) => async () => {
    const confirmOptions = { entity: 'приз', entityName: title };

    // eslint-disable-next-line no-restricted-globals
    if (canDeletePrize && confirm(t('common.form.delete.question', confirmOptions))) {
      handleErrors(
        await deleteSystemBonusProgramPrizeItem(id),
        'delete',
        () => {
          toast.success(t('system.bonusProgram.deleted.prize'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  }, [canDeletePrize, data, getData, t]);

  return (
    <SystemBonusProgramPrizeListComponent
      data={data}
      isLoading={isLoading}
      getData={getData}
      canCreatePrize={canCreatePrize}
      canDeletePrize={canDeletePrize}
      deletePrize={deletePrize}
    />
  );
};
