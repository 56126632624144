import React, { memo, useEffect, useMemo } from 'react';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { differenceEnterprises, getEnterpriseLikeAll } from 'utils/breakdownEnterprisesIntoGroups';
import { setItem, getItem, removeItem } from 'utils/localStorage';
import { checkingIncludedEnterprisesID } from 'utils/checkingIncludedEnterprisesID';
import { EnterpriseModel, IEnterpriseModel } from '../../models/Enterprises/EnterpriseModel';
import { prepareFilterEntityByKey } from '../../utils/prepareFilterEntityByKey';
import { LocalUsersFilterType } from '../../models/Users/interfaces';
import { usePrevious } from '../../utils/hooks/usePrevious';
import { CompactEnterprisesFilter } from '../common/CompactEnterprisesFilter';


const enterprisePrefix = 'ent_';

const getDefault = (
  data: LocalUsersFilterType,
  enterprises: IEnterpriseModel[],
  localStorageKey: string,
): FormValues => {
  const allEnts = enterprises.filter(({ id }) => id).map(({ id }) => id);

  const localStorageData = getItem(localStorageKey);
  const dataFilter = localStorageData ? JSON.parse(localStorageData) : data;

  const obj = {
    enterprises: dataFilter.enterprise.reduce((acc: {[s: string]: boolean}, typeId: any) => {
      acc[`${enterprisePrefix}${typeId}`] = true;
      return acc;
    }, {}),
  };
  if (isEqual(sortBy(allEnts), sortBy(dataFilter.enterprise))) {
    obj.enterprises[`${enterprisePrefix}0`] = true;
  }
  return obj;
};

type Props = {
  toggle: () => void;
  setFilterData: (filter: any) => void;
  initialValues: () => LocalUsersFilterType;
  allEnterprises: EnterpriseModel[];
  currentEnterprise: EnterpriseModel[];
  filterData: LocalUsersFilterType;
  localStorageKey: string;
}

type FormValues = {
  enterprises: {[s: string]: boolean};
}

export const ContactsFilter = memo(({
  toggle,
  setFilterData,
  initialValues,
  allEnterprises,
  currentEnterprise,
  filterData,
  localStorageKey,
}: Props) => {
  const {
    register, handleSubmit, watch, reset, setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: getDefault(filterData, allEnterprises, localStorageKey),
  });

  const { t } = useTranslation();

  const otherEnterprise = differenceEnterprises(allEnterprises, currentEnterprise);
  const enterpriseLikeAll = getEnterpriseLikeAll(otherEnterprise);

  /** подписаться на обновления формы */
  const watchEnterprisesFields = watch('enterprises');
  const prevWatchEnts = usePrevious(watchEnterprisesFields);

  useEffect(() => {
    if (watchEnterprisesFields.ent_0 && get(prevWatchEnts, 'ent_0', false) === false) {
      allEnterprises.forEach(({ id }) => {
        setValue(`enterprises.ent_${id}`, true);
      });
    }
  }, [watchEnterprisesFields, allEnterprises, setValue, prevWatchEnts]);

  /** все текущие актуальные данные фильтра брать отсюда */
  const getCurrentFilterData = (): LocalUsersFilterType => ({
    enterprise: prepareFilterEntityByKey(watchEnterprisesFields, enterprisePrefix),
  });

  /** Логика для списка: раскрывать его, если есть выбранные предприятия, которые попадают в скрытые */
  const isCheckedEnterprises = useMemo(() => {
    const localStorageData = JSON.parse(getItem(localStorageKey));
    if (localStorageData) {
      return checkingIncludedEnterprisesID(otherEnterprise, localStorageData.enterprise);
    }
    return false;
  }, [otherEnterprise, localStorageKey]);

  const settingFilterData = (localStorageFilter: LocalUsersFilterType, filter: LocalUsersFilterType) => {
    if (localStorageFilter) {
      setFilterData(localStorageFilter);
    } else {
      setFilterData(filter);
    }
    toggle();
  };

  const onSubmit = () => {
    const filter = getCurrentFilterData();
    setItem(localStorageKey, JSON.stringify(filter));
    settingFilterData(JSON.parse(getItem(localStorageKey)), filter);
  };

  const clearFilter = () => {
    removeItem(localStorageKey);
    reset(getDefault(initialValues(), allEnterprises, localStorageKey));
  };

  return (
    <form autoComplete="off" className="modal-content" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-with-btn">
        <div className="form-wrapper_content">
          <h3>Предприятия</h3>
          <CompactEnterprisesFilter
            enterpriseLikeAll={enterpriseLikeAll}
            currentEnterprise={currentEnterprise}
            otherEnterprise={otherEnterprise}
            register={register}
            isCheckedEnterprises={isCheckedEnterprises}
          />
        </div>
        <div className="form-wrapper_btn">
          <div className="buttons-group buttons-group-responsive">
            <Button color="primary" type="submit">
              {t('common.filter.apply')}
            </Button>
            <Button
              color="primary"
              outline
              onClick={clearFilter}
              className="btn--clear-filter"
            >
              {t('common.form.clear_filter')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
});
