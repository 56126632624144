import React from 'react';

type Props = {
  text: string;
  name: string;
  id: string;
  onChange: (name: string, checked: boolean) => void;
  checked: boolean;
}

export const ListElementSwitch = ({
  text,
  name,
  id,
  onChange,
  checked,
}: Props) => (
  <div className="list-element">
    <label className="list-element_content" htmlFor={id}>
      <div className="line">{text}</div>
    </label>
    <div className="list-element_direction form-check switch">
      <input
        type="checkbox"
        name={name}
        id={id}
        onChange={({ target }) => onChange(name, target.checked)}
        checked={checked}
      />
      <label htmlFor={id} />
    </div>
  </div>
);
