import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UiSystemCheckbox } from 'components/common/UI/Checkbox';
import {
  ConstructApplicationTypeFormType, ConstructApplicationTypeModelAvailableType,
  IConstructApplicationCategoryListItemModel, IConstructApplicationTypeModel,
} from 'models/ConstructApplication/interfaces';
import { UiSystemInput } from 'components/common/UI/Input';
import { emptyOption, emptyOptionValue, UiSelect } from 'components/common/UI/Select';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { UiSystemForm } from 'components/common/UI/Form';
import { checkReplace, findValue } from 'utils/common';
import { convertModelToOptions, SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { updateConstructApplicationAvailable } from '../../../api/construct-application';


type Props = {
  enterpriseList: IEnterpriseModel[];
  categoriesList: IConstructApplicationCategoryListItemModel[];
  canUpdate: boolean;
  onSave: (m: ConstructApplicationTypeFormType) => void;
  model: IConstructApplicationTypeModel;
  getUsers: (enterpriseId: number) => void;
  usersOptions: SimpleSelectOptionType<number>[];
  usersOptionsLoading: boolean;
}

type FormValues = {
  name: string;
  category_id: SimpleSelectOptionType<number>;
  enterprise_id: SimpleSelectOptionType<number>;
  assign_user_ids: SimpleSelectOptionType<number>[] | null;
  deadline: string | number;
  need_approval: boolean;
  original_place: string;
  schedule: string;
  send_email: boolean;
}

const allEnterprisesOption = (t: TFunction) => emptyOption(t('common.allEnterprises'));

export const ConstructApplicationTypeMainComponent = ({
  canUpdate, enterpriseList, categoriesList, onSave, model, getUsers, usersOptions, usersOptionsLoading,
}: Props) => {
  const { t } = useTranslation();

  /** кэширование значений предприятий для селекта */
  const enterprisesOptions = useMemo(() => [
    allEnterprisesOption(t),
    ...convertModelToOptions(enterpriseList),
  ], [enterpriseList, t]);

  /** кэширование значений категорий для селекта */
  const categoriesOptions = useMemo(() => {
    const options = convertModelToOptions(categoriesList);
    options.unshift(emptyOption('Корневая'));
    return options;
  }, [categoriesList]);

  const createMode = model.isNew;

  const {
    register, handleSubmit, errors, formState: {
      isSubmitting, dirtyFields, isValid,
    }, setValue, watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
      enterprise_id: checkReplace(findValue(enterprisesOptions, model.enterprise?.id), allEnterprisesOption(t)),
      category_id: (() => {
        if (createMode) {
          return categoriesOptions[0];
        }
        if (model.category) {
          return checkReplace(findValue(categoriesOptions, model.category.id), categoriesOptions[0]);
        }
        return null;
      })(),
      deadline: model.deadline,
      need_approval: model.need_approval,
      original_place: model.original_place,
      schedule: model.schedule,
      send_email: model.send_email,
    },
  });

  /**
   * отследить первую загрузку и выбрать сотрудников, если есть
   * при изменении списка пользователей проверять вхождение выбранного
   */
  useEffect(() => {
    const userIds = model.assignUsers.map((assignUser) => assignUser.id);

    if (!userIds.length || usersOptionsLoading) {
      return;
    }

    const assign_user_ids = userIds
      .map((userId) => findValue(usersOptions, userId))
      .filter((assignUser) => assignUser);

    setValue('assign_user_ids', assign_user_ids, true);
  }, [usersOptions, usersOptionsLoading, setValue, model.assignUsers]);

  /** для изменения состояния чекбокса "enable_part_time" при выборе предприятия */
  const observerForEnablePartTimeCheckbox = useCallback(async (
    isAllEnterprise: boolean,
    availability: ConstructApplicationTypeModelAvailableType,
  ) => {
    if (model.availability) {
      const params = isAllEnterprise ? { ...availability, enable_part_time: true } : availability;
      await updateConstructApplicationAvailable(model.id, params);
    }
  }, [model.availability, model.id]);

  /** следить за предприятиями и при смене запрашивать список сотрудников */
  const watchEnterpise = watch('enterprise_id');
  useEffect(() => {
    getUsers(watchEnterpise.value);

    const isAllEnterprise = watchEnterpise.value === allEnterprisesOption(t).value;
    observerForEnablePartTimeCheckbox(isAllEnterprise, model.availability);
  }, [watchEnterpise, getUsers, setValue, t, observerForEnablePartTimeCheckbox, model.availability]);

  const onSubmit = (data: FormValues) => {
    if (canUpdate) {
      onSave({
        id: model.id,
        name: data.name,
        category_id: data.category_id.value === emptyOptionValue ? null : data.category_id.value,
        enterprise_id: data.enterprise_id.value === emptyOptionValue ? null : data.enterprise_id.value,
        assign_user_ids: data.assign_user_ids ?
          data.assign_user_ids.map((assignUser: SimpleSelectOptionType<number>) => assignUser.value) :
          null,
        deadline: +data.deadline ? +data.deadline : null,
        need_approval: data.need_approval,
        original_place: data.original_place.length ? data.original_place : null,
        schedule: data.schedule.length ? data.schedule : null,
        send_email: data.send_email,
        isNew: model.isNew,
      });
    }
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
      showButtons={canUpdate}
    >
      <h3>
        {t(`system.construct_application.type.form.${createMode ? 'create' : 'update'}`)}
      </h3>

      <UiSystemInput
        name="name"
        errors={errors}
        register={register}
        label={t('system.construct_application.type.name')}
        required
      />

      <UiSelect
        label={t('system.construct_application.type.category')}
        register={register}
        setValue={setValue}
        name="category_id"
        options={categoriesOptions}
        allowEmptyValue
        value={watch('category_id')}
        isClearable
      />

      <UiSelect
        label={t('system.construct_application.type.enterprise')}
        register={register}
        setValue={setValue}
        name="enterprise_id"
        options={enterprisesOptions}
        allowEmptyValue
        value={watch('enterprise_id')}
      />

      <UiSelect
        label={t('system.construct_application.type.assignUser')}
        register={register}
        setValue={setValue}
        name="assign_user_ids"
        options={usersOptions}
        value={watch('assign_user_ids')}
        isLoading={usersOptionsLoading}
        required
        multiple
      />

      <UiSystemInput
        type="number"
        name="deadline"
        errors={errors}
        register={register}
        label={t('system.construct_application.type.deadline')}
        appendText="рабочих дней"
      />

      <UiSystemInput
        name="original_place"
        errors={errors}
        register={register}
        label={t('system.construct_application.type.originalPlace')}
      />

      <UiSystemInput
        type="textarea"
        rows={3}
        name="schedule"
        errors={errors}
        register={register}
        label={t('system.construct_application.type.schedule')}
      />

      <UiSystemCheckbox
        register={register}
        name="send_email"
        label={t('system.construct_application.type.sendEmail')}
      />

      <UiSystemCheckbox
        register={register}
        name="need_approval"
        label={t('system.construct_application.type.needApproval')}
      />

    </UiSystemForm>
  );
};
