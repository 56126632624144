import { customHistory } from 'customHistory';

type ApplicationGuideStep = {
  selector: string,
  title: string,
  text: string,
  action?: () => void,
}

export const applicationGuideStepsForMobile: ApplicationGuideStep[] = [
  {
    selector: '.step-1',
    title: 'guide.start.title',
    text: 'guide.start.text',
    action: () => {
      if (customHistory.location.pathname !== '/add-menu') {
        customHistory.push('/add-menu');
      }
    },
  },
  {
    selector: '.step-2',
    title: 'guide.news.title',
    text: 'guide.news.text',
    action: () => {
      if (customHistory.location.pathname !== '/') {
        customHistory.push('/');
      }
    },
  },
  {
    selector: '.step-3',
    title: 'guide.notifications.title',
    text: 'guide.notifications.text',
    action: () => {
      if (customHistory.location.pathname !== '/') {
        customHistory.push('/');
      }
    },
  },
  {
    selector: '.step-4',
    title: 'guide.applications.title',
    text: 'guide.applications.text',
    action: () => {
      if (customHistory.location.pathname !== '/applications') {
        customHistory.push('/applications');
      }
    },
  },
  {
    selector: '.step-5',
    title: 'guide.applications.privileges.title',
    text: 'guide.applications.privileges.text',
    action: () => {
      if (customHistory.location.pathname !== '/applications') {
        customHistory.push('/applications');
      }
    },
  },
  {
    selector: '.step-6',
    title: 'guide.partners.title',
    text: 'guide.partners.text',
    action: () => {
      if (customHistory.location.pathname !== '/applications') {
        customHistory.push('/applications');
      }
    },
  },
  {
    selector: '.step-7',
    title: 'guide.profile.title',
    text: 'guide.profile.text',
    action: () => {
      if (customHistory.location.pathname !== '/profile') {
        customHistory.push('/profile');
      }
    },
  },
  {
    selector: '.step-8',
    title: 'guide.payslip.title',
    text: 'guide.payslip.text',
    action: () => {
      if (customHistory.location.pathname !== '/profile') {
        customHistory.push('/profile');
      }
    },
  },
  {
    selector: '.step-9',
    title: 'guide.polls.title',
    text: 'guide.polls.text',
    action: () => {
      if (customHistory.location.pathname !== '/') {
        customHistory.push('/');
      }
    },
  },
  {
    selector: '.step-10',
    title: 'guide.addMenu.title',
    text: 'guide.addMenu.text',
    action: () => {
      if (customHistory.location.pathname !== '/') {
        customHistory.push('/');
      }
    },
  },
  {
    selector: '.step-11',
    title: 'guide.referralProgram.title',
    text: 'guide.referralProgram.text',
    action: () => {
      if (customHistory.location.pathname !== '/add-menu') {
        customHistory.push('/add-menu');
      }
    },
  },
];

export const applicationGuideStepsForDesktop: ApplicationGuideStep[] = [
  {
    selector: '.step-1',
    title: 'guide.start.title',
    text: 'guide.start.text',
  },
  {
    selector: '.step-2',
    title: 'guide.news.title',
    text: 'guide.news.text',
    action: () => {
      if (customHistory.location.pathname !== '/') {
        customHistory.push('/');
      }
    },
  },
  {
    selector: '.step-3',
    title: 'guide.notifications.title',
    text: 'guide.notifications.text',
    action: () => {
      if (customHistory.location.pathname !== '/') {
        customHistory.push('/');
      }
    },
  },
  {
    selector: '.step-4',
    title: 'guide.applications.title',
    text: 'guide.applications.text',
    action: () => {
      if (customHistory.location.pathname !== '/applications') {
        customHistory.push('/applications');
      }
    },
  },
  {
    selector: '.step-5',
    title: 'guide.applications.privileges.title',
    text: 'guide.applications.privileges.text',
    action: () => {
      if (customHistory.location.pathname !== '/applications') {
        customHistory.push('/applications');
      }
    },
  },
  {
    selector: '.step-6',
    title: 'guide.partners.title',
    text: 'guide.partners.text',
    action: () => {
      if (customHistory.location.pathname !== '/applications') {
        customHistory.push('/applications');
      }
    },
  },
  {
    selector: '.step-7',
    title: 'guide.profile.title',
    text: 'guide.profile.text',
    action: () => {
      if (customHistory.location.pathname !== '/profile') {
        customHistory.push('/profile');
      }
    },
  },
  {
    selector: '.step-8',
    title: 'guide.payslip.title',
    text: 'guide.payslip.text',
    action: () => {
      if (customHistory.location.pathname !== '/profile') {
        customHistory.push('/profile');
      }
    },
  },
  {
    selector: '.step-9',
    title: 'guide.polls.title',
    text: 'guide.polls.text',
    action: () => {
      if (customHistory.location.pathname !== '/') {
        customHistory.push('/');
      }
    },
  },
  {
    selector: '.step-10',
    title: 'guide.addMenu.title',
    text: 'guide.addMenu.text',
    action: () => {
      if (customHistory.location.pathname !== '/') {
        customHistory.push('/');
      }
    },
  },
  {
    selector: '.step-11',
    title: 'guide.referralProgram.title',
    text: 'guide.referralProgram.text',
  },
];
