import { dataAction, dataErrorAction, dataSuccessAction } from '../../../../store/actions';
import {
  SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_FAIL,
  SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_OK,
  SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_START,
} from './constants';
import { IJobDescriptionsModel, JobDescriptionsFilterType } from '../../../../models/JobDescription/JobDescription';

export const systemRefGetJobDescriptionsStartAction = (filter?: JobDescriptionsFilterType) => dataAction(
  SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_START,
)({ filter });
export const systemRefGetJobDescriptionsOkAction = (jobDescriptions: IJobDescriptionsModel) => dataSuccessAction(
  SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_OK,
)({ jobDescriptions });
export const systemRefGetJobDescriptionsFailAction = dataErrorAction(SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_FAIL);
