import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from 'store/baseSaga';
import { httpGet } from 'utils/http';
import {
  SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_START, SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_START,
  SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_START,
} from 'systemModule/containers/BonusProgram/constants';
import {
  systemBonusProgramGetPrizeListOkAction,
  systemBonusProgramGetPrizeListFailAction,
  systemRefGetTagListOkAction,
  systemRefGetTagListFailAction,
  systemBonusProgramGetEventHistoryListOkAction,
  systemBonusProgramGetEventHistoryListFailAction,
  systemBonusProgramGetEventListOkAction,
  systemBonusProgramGetEventListFailAction,
} from 'systemModule/containers/BonusProgram/actions';
import { SYSTEM_REFERENCES_GET_TAG_LIST_START } from 'systemModule/containers/References/BonusProgram/constants';
import { SystemBonusProgramTagListModel } from 'models/BonusProgram/Tag/SystemBonusProgramTagModel';
import { SystemBonusProgramPrizeListModel } from 'models/BonusProgram/Prize/SystemBonusProgramPrizeModel';
import {
  SystemBonusProgramEventHistoryListModel,
} from 'models/BonusProgram/EventHistory/SystemBonusProgramEventHistoryModel';
import { SystemBonusProgramEventListModel } from 'models/BonusProgram/Event/SystemBonusProgramEventModel';


function* systemBonusProgramGetPrizeListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/bonus-program/prize', filter);
    yield put(systemBonusProgramGetPrizeListOkAction(new SystemBonusProgramPrizeListModel(response)));
  }, systemBonusProgramGetPrizeListFailAction);
}

function* systemBonusProgramGetTagListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/bonus-program/tag', { sort: 'id', ...filter });
    yield put(systemRefGetTagListOkAction(new SystemBonusProgramTagListModel(response)));
  }, systemRefGetTagListFailAction);
}

function* systemBonusProgramGetEventHistoryListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/bonus-program/event-history', filter);
    yield put(systemBonusProgramGetEventHistoryListOkAction(new SystemBonusProgramEventHistoryListModel(response)));
  }, systemBonusProgramGetEventHistoryListFailAction);
}

function* systemBonusProgramGetEventListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/bonus-program/event', filter);
    yield put(systemBonusProgramGetEventListOkAction(new SystemBonusProgramEventListModel(response)));
  }, systemBonusProgramGetEventListFailAction);
}

export function* systemBonusProgramSagas() {
  yield takeLatest(SYSTEM_BONUS_PROGRAM_GET_PRIZE_LIST_START, systemBonusProgramGetPrizeListSaga);
  yield takeLatest(SYSTEM_REFERENCES_GET_TAG_LIST_START, systemBonusProgramGetTagListSaga);
  yield takeLatest(SYSTEM_BONUS_PROGRAM_GET_EVENT_HISTORY_LIST_START, systemBonusProgramGetEventHistoryListSaga);
  yield takeLatest(SYSTEM_BONUS_PROGRAM_GET_EVENT_LIST_START, systemBonusProgramGetEventListSaga);
}
