import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import invariant from 'invariant';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';

import { ParamTypes } from 'models/common';
import { SystemNewsFormComponent } from '../../components/News/SystemNewsForm';
import { systemEnterprisesSelector, systemNewsCategoriesSelector } from '../../store/selectors';
import { INewsCategoryItemModel } from '../../../models/News/NewsCategoryModel';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { Loader } from '../../../components/common/UI/Loaders';
import { experimentalGetSystemNewsById } from './experimentalApi';
import { INewsItemModel } from '../../../models/News/NewsItemModel';
import { EntityNotFound } from '../../../components/Errors/404';
import { HttpErrors } from '../../../utils/http';


export const SystemNewsUpdate = () => {
  const { id } = useParams<ParamTypes>();
  const newsId = toNumber(id);

  invariant(!isNaN(newsId), 'ID новости должен быть числом');
  if (isNaN(newsId)) {
    return <EntityNotFound message="Новость не найдена" />;
  }

  const newsResource = experimentalGetSystemNewsById(newsId);

  return (
    <Suspense fallback={<Loader />}>
      <SystemNewsUpdateContainer resource={newsResource} />
    </Suspense>
  );
};


const SystemNewsUpdateContainer = ({ resource }: any) => {
  const newsModel: INewsItemModel | HttpErrors = resource.news.read();

  const categories: INewsCategoryItemModel[] = useSelector(systemNewsCategoriesSelector);
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  if (newsModel instanceof Error) {
    return <EntityNotFound message="Новость не найдена" />;
  }
  return <SystemNewsFormComponent newsModel={newsModel} categories={categories} enterprises={enterprises} />;
};
