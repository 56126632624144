import React from 'react';
import { SystemApplication } from './SystemApplication';
import { SystemParticularApplicationWrapper } from './SystemParticularApplicationWrapper';
import { SystemParticularApplication } from './SystemParticularApplication';

const thisSectionName = 'assign-applications';

export const SystemOneOfThemAssignApplication = () => (
  <SystemParticularApplicationWrapper thisSectionName={thisSectionName}>
    <SystemParticularApplication>
      <SystemApplication />
    </SystemParticularApplication>
  </SystemParticularApplicationWrapper>
);
