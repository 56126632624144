import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { AuthSignUpActiveFormType, AuthSignUpPhoneType } from 'models/Auth/interfaces';
import { PHONE_MASK, PHONE_PATTERN } from 'config/system';
import { getNumberPhoneWithoutMask } from 'utils/common';
import { UiCheckbox } from 'components/common/UI/Checkbox';
import { UIInputMask } from 'components/common/UI/InputMask';


const UserAgreementLink = () => (
  <>
    Соглашаюсь с условиями&nbsp;
    <Link className="agree-link" to="/user-agreement">Пользовательского соглашения</Link>
  </>
);

type Props = {
  submit: (data: AuthSignUpPhoneType) => void;
  isSubmitting: boolean;
  setActiveForm: (value: AuthSignUpActiveFormType) => void;
}

type FormValues = {
  phone: string;
  isAgree: boolean;
  isShowPhone: boolean;
}

const validatePhone = (value: string) => PHONE_PATTERN.test(value);

export const PhoneFormComponent = ({
  submit, isSubmitting, setActiveForm,
}: Props) => {
  const {
    handleSubmit, watch, control, register,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const onSubmit = (data: FormValues) => submit({
    phone: getNumberPhoneWithoutMask(data.phone),
    show_phone: data.isShowPhone,
  });
  const backToReferralCode = () => {
    setActiveForm('referralCode');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UIInputMask
        name="phone"
        control={control}
        type="text"
        label="Номер телефона"
        watch={watch}
        mask={PHONE_MASK}
      />
      <UiCheckbox
        label={<UserAgreementLink />}
        register={register}
        name="isAgree"
      />
      <UiCheckbox
        label="Отображать мой контактный номер другим сотрудникам Холдинга СДС"
        register={register}
        name="isShowPhone"
      />
      <div className="buttons-group buttons-group-responsive justify-content-between">
        <Button
          color="primary"
          type="submit"
          onClick={backToReferralCode}
        >
          Назад
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={!validatePhone(watch('phone')) || !watch('isAgree') || isSubmitting}
        >
          Получить код для входа
        </Button>
      </div>
    </form>
  );
};
