import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { ParamTypes } from 'models/common';
import { EntityNotFound } from 'components/Errors/404';
import { Loader } from 'components/common/UI/Loaders';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  SystemUserEnterpriseSettingsComponent,
} from '../../components/UserEnterprises/SystemUserEnterpriseSettings';
import { systemEnterprisesSelector } from '../../store/selectors';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { getSystemUserEnterprises, getSystemUserEnterprisesUpdate } from '../../api/userEnterprises';
import { ISystemEnterpriseModel } from '../../../models/References/EnterprisesModel';
import { handleErrors } from '../../../utils/errors';

export type ReduceDefaultValueType = Record<string, boolean> | undefined;

export const SystemUserEnterpriseSettings = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const userId = toNumber(id);

  /** использовать все доступные предпрятия */
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const [userEnterprises, setUserEnterprises] = useState<ISystemEnterpriseModel[]>();

  const onSave = useCallback(async (data: Record<string, string[]>) => {
    handleErrors(
      await getSystemUserEnterprisesUpdate(userId, data),
      'save',
      () => toast.success(t('profile.enterprises.settings.changed')),
    );
    getSystemUserEnterprises(userId).then((userEnterprises) => {
      if (!(userEnterprises instanceof Error)) {
        setUserEnterprises(userEnterprises.data);
      }
    });
  }, [userId, t]);

  useEffect(() => {
    getSystemUserEnterprises(userId).then((userEnterprises) => {
      if (!(userEnterprises instanceof Error)) {
        setUserEnterprises(userEnterprises.data);
      }
    });
  }, [userId]);

  const defaultValue = userEnterprises?.reduce<ReduceDefaultValueType>((acc, item: ISystemEnterpriseModel) => (
    { ...acc, [item.id]: true }), {});

  if (isNaN(userId) || !enterprises) {
    return <EntityNotFound message={t('common.user.notFound')} />;
  }
  if (enterprises && userEnterprises) {
    return (
      <SystemUserEnterpriseSettingsComponent
        enterpriseList={enterprises}
        onSave={onSave}
        defaultValue={defaultValue}
      />
    );
  }
  return <Loader />;
};
