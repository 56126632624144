import { RoutesPaths } from '../../routes';


export class NotificationBase {
  id_entity: number | null;
  internal_link: string | undefined;
  subject: string;
  text: string;
  typeApplication: number | null;

  private front_url = process.env.REACT_APP_FRONT_URL;

  constructor(params: any = {}) {
    this.id_entity = params.id_entity;
    this.internal_link = params.internal_link;
    this.subject = params.subject;
    this.text = params.text;
    this.typeApplication = params.typeApplication;
  }

  /**
   * разный шаблон в зависимости от типа
   * в subject и text приходит заголовок и текст оповещения соответственно. их просто вывести нужно
   */
  notification_data(action_code: string) {
    let linkUrl: string | null = null;
    /**
     * собрать ссылку, если возможно
     * у остальных нет ссылки (не сделано в публичной части)
     */
    switch (action_code) {
      case 'news': { // новость
        if (this.id_entity) {
          linkUrl = RoutesPaths.newsOne.replace(':id', this.id_entity.toString());
        }
        break;
      }
      case 'poll': { // опрос
        if (this.id_entity) {
          linkUrl = RoutesPaths.pollsOne.replace(':id', this.id_entity.toString());
        }
        break;
      }
      case 'manual': {
        /** для ручных оповещений обязательно должна быть указана ссылка */
        if (this.internal_link) {
          /** замена абсолютных ссылок на это приложение относительными (без домена) */
          if (this.front_url && this.internal_link.includes(this.front_url)) {
            linkUrl = this.internal_link.replace(this.front_url, '');
          } else {
            linkUrl = this.internal_link;
          }
        }
        break;
      }
      case 'event': { // событие/мероприятие
        if (this.id_entity) {
          linkUrl = RoutesPaths.eventsOne.replace(':id', this.id_entity.toString());
        }
        break;
      }
      case 'new_application': { // Заявки
        if (this.id_entity && this.typeApplication) {
          linkUrl = RoutesPaths.applicationOne
            .replace(':id', this.id_entity.toString())
            .replace(':typeId', this.typeApplication.toString());
        }
        break;
      }
      default: break;
    }

    return {
      message: [this.subject, this.text].filter((t) => t).join('\n'),
      link: linkUrl,
    };
  }
}
