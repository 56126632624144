import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReportApplication } from '../../../../models/Reports/Application';
import { SystemReportApplicationComponent } from './Application';
import { ReportApplicationEnterprise } from '../../../../models/Reports/ApplicationEnterprise';
import { SystemReportApplicationEnterpriseComponent } from './ApplicationEnterprise';
import { IReportApplication, IReportApplicationEnterprise } from '../../../../models/Reports/interfaces';
import { Loader } from '../../../../components/common/UI/Loaders';
import { EntityNotFound } from '../../../../components/Errors/404';
import { SystemReportHeader } from '../common/Header';


type Props = {
  model: IReportApplication | IReportApplicationEnterprise | null;
  getData: (enterprise_ids?: number[]) => void;
  isLoading: boolean;
}

export const SystemReportApplicationIndexComponent = ({
  model, getData, isLoading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <SystemReportHeader
        getData={getData}
        totalCount={model?.application_count}
        message={t('reports.application.title')}
      />

      {isLoading ? <Loader /> : (
        <>
          {model instanceof ReportApplication && (
            <SystemReportApplicationComponent model={model} />
          )}
          {model instanceof ReportApplicationEnterprise && (
            <SystemReportApplicationEnterpriseComponent model={model} />
          )}
          {model === null && (<EntityNotFound message={t('reports.no_data')} />)}
        </>
      )}
    </>
  );
};
