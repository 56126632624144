import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { getUsersOptions } from 'api/Users';
import { emptyOptionValue } from 'components/common/UI/Select';
import { ConstructApplicationTypeModel } from 'models/ConstructApplication/ConstructApplicationTemplateListModel';
import { Loader } from 'components/common/UI/Loaders';
import {
  ConstructApplicationTypeFormType,
  IConstructApplicationCategoryListItemModel, IConstructApplicationTypeModel,
} from 'models/ConstructApplication/interfaces';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  createConstructApplicationType,
  getConstructApplicationCategoryAll,
  getConstructApplicationTypeById,
  updateConstructApplicationType,
} from 'systemModule/api/construct-application';
import {
  ConstructApplicationTypeMainComponent,
} from 'systemModule/components/ConstructApplication/Type/ConstructApplicationTypeMain';
import { systemEnterprisesSelector } from 'systemModule/store/selectors';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';


export const ConstructApplicationTypeMain = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [usersOptions, setUsersOptions] = useState<SimpleSelectOptionType<number>[]>([]);
  const [usersOptionsLoading, setUsersOptionsLoading] = useState(true);

  const { t } = useTranslation();

  const history = useHistory();

  const enterpriseList: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wApplication);

  /** получить список категорий */
  const [categoriesList, categoriesListLoading] = usePromise<IConstructApplicationCategoryListItemModel[]>(
    getConstructApplicationCategoryAll,
    true,
  );

  const onSave = useCallback(async (modelToSave: ConstructApplicationTypeFormType) => {
    const saveModel = modelToSave.isNew ? createConstructApplicationType : updateConstructApplicationType;

    handleErrors(
      await saveModel(modelToSave),
      'save',
      (modelId) => {
        toast.success(t(`system.construct_application.type.${modelToSave.isNew ? 'created' : 'updated'}`));

        if (modelToSave.isNew) {
          /** переадресация на ид созданного типа */
          history.push(`/system/construct-application/type/${modelId}`);
        }
      },
    );
  }, [t, history]);

  /** получить тип заявки по ид */
  const [model, modelLoading] = usePromise<IConstructApplicationTypeModel>(
    () => getConstructApplicationTypeById(numberId),
    !isNaN(numberId),
    [numberId],
  );

  /** получение и подготовка списка пользователей */
  const getUsers = useCallback(async (enterpriseId: number) => {
    setUsersOptionsLoading(true);

    handleErrors(
      await getUsersOptions({
        enterprise: enterpriseId === emptyOptionValue ? undefined : [enterpriseId],
        assigned_list: true,
      }),
      'get',
      setUsersOptions,
      () => setUsersOptionsLoading(false),
    );
  }, []);


  if (categoriesListLoading || modelLoading) {
    return <Loader />;
  }
  return (
    <ConstructApplicationTypeMainComponent
      model={model || new ConstructApplicationTypeModel()}
      enterpriseList={enterpriseList}
      canUpdate={canUpdate}
      categoriesList={categoriesList || []}
      onSave={onSave}
      getUsers={getUsers}
      usersOptions={usersOptions}
      usersOptionsLoading={usersOptionsLoading}
    />
  );
};
