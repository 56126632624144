import { createSelector } from 'reselect';
import { RootReducer } from '../../../../store/reducers';
import { storeName } from './reducers';
import { returnParams } from '../../../../store/selectors';

const getBusStops = (state: RootReducer) => state.system[storeName].busStops;
const getBusStopsIsLoading = (state: RootReducer) => state.system[storeName].busStopsIsLoading;
const getBusStopById = (id: number) => (state: RootReducer) => state.system[storeName].busStops.data
  .find((busStop) => busStop.id === id);

export const systemBusStopsSelector = createSelector([getBusStops], returnParams);
export const systemBusStopsIsLoadingSelector = createSelector([getBusStopsIsLoading], returnParams);
export const systemBusStopByidSelector = createSelector([getBusStopById], returnParams);
