import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CustomTable } from '../../../components/common/Table';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import {
  IApplicationResponsibleFilterType,
  IApplicationResponsibleListModel,
  IApplicationResponsibleModel,
} from '../../../models/Application/Responsible/interfaces';
import { useDataPage } from '../../../utils/hooks/useDataPage';
import { IDepartmentItemModel } from '../../../models/References/DepartmentModel';
import { IUserList } from '../../../models/Users/interfaces';
import { handleErrors } from '../../../utils/errors';
import { UsersFilterType } from '../../../models/Users/UsersModel';
import { SystemFeedbackResponsibleFilter } from './Filter';
import { deleteAppResponsible } from '../../../api/application';

type Props = {
  data: IApplicationResponsibleListModel;
  enterprises: IEnterpriseModel[];
  departments: IDepartmentItemModel[];
  users: IUserList[];
  isLoading: boolean;
  getData: (filter?: IApplicationResponsibleFilterType) => void;
  getUsers: (filter: UsersFilterType) => void;
}

export const SystemFeedbackResponsibleComponent = ({
  data,
  enterprises,
  departments,
  users,
  isLoading,
  getData,
  getUsers,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  const { t } = useTranslation();

  const onDelete = ({ id, user: { fio } }: IApplicationResponsibleModel) => async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(t('common.form.delete.question', { entity: 'ответственного', entityName: fio }))) {
      handleErrors(
        await deleteAppResponsible(id),
        'delete',
        () => {
          toast.success(t('medical.deleted'));
          getData();
        },
      );
    }
  };

  return (
    <>
      <h3>{t('feedback.menu')}</h3>

      <SystemFeedbackResponsibleFilter
        onSubmit={onSubmitFilter}
        onReset={onResetFilter}
        enterprises={enterprises}
        departments={departments}
        users={users}
        getUsers={getUsers}
      />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        customButtons={
          <Button tag={Link} color="primary" to="feedback-responsible/create">{t('feedback.create')}</Button>
        }
        tableHeaders={[
          { name: 'ID' },
          { name: t('application.type') },
          { name: t('common.fullname') },
          { name: t('common.enterprise') },
          { name: t('common.department') },
          { name: t('common.table.actions') },
        ]}
      >
        {data.data.map((item: IApplicationResponsibleModel) => (
          <tr key={item.id}>
            <td>
              <Link to={`feedback-responsible/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              {t(`feedback.responsible.type.${item.type.code}`)}
            </td>
            <td>
              {item.user.fio}
            </td>
            <td>
              {item.enterprise.name}
            </td>
            <td>
              {item.department.name}
            </td>
            <td>
              <Button
                type="button"
                color="danger"
                className="w-100"
                onClick={onDelete(item)}
              >
                {t('common.form.delete')}
              </Button>
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
