import { useMemo } from 'react';

export const useAvailableAccrualBonusesForApplication = (
  applicationDepartmentName: string | undefined,
  departments: string[],
  applicationTypeId: number,
  types: number[],
) => useMemo(() => departments
  .some((type) => type === applicationDepartmentName) &&
  types.some((type) => type === applicationTypeId), [applicationDepartmentName, departments, applicationTypeId, types]);
