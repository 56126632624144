import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BusStopComponent } from 'components/BusStop/BusStop';
import { BusStopsParamsType } from 'models/BusStops/BusStops';
import { getBusStopsSelector, getBusStopsIsLoadingSelector } from './selectors';
import { removeBusStopFavoriteStartAction, setBusStopFavoriteStartAction, getBusStopListStartAction } from './actions';

export const BusStops = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const busStops = useSelector(getBusStopsSelector);
  const busStopsIsLoading = useSelector(getBusStopsIsLoadingSelector);

  const isFavoriteTab: number = location && location.search.length;
  const hasMoreData: boolean = busStops.amountOfPages > busStops.page;

  const toggleFavoriteBusStop = useCallback((id: number, isFavorite: boolean) => {
    if (isFavorite) {
      dispatch(removeBusStopFavoriteStartAction(id));
    } else {
      dispatch(setBusStopFavoriteStartAction(id));
    }
  }, [dispatch]);

  const getData = useCallback((append: boolean, params?: BusStopsParamsType) => {
    dispatch(getBusStopListStartAction(append, isFavoriteTab ? { ...params, favorite: 1 } : params));
  }, [dispatch, isFavoriteTab]);

  useEffect(() => {
    getData(false);
  }, [isFavoriteTab, getData]);

  return (
    <BusStopComponent
      isFavoriteTab={Boolean(isFavoriteTab)}
      busStops={busStops}
      busStopsIsLoading={busStopsIsLoading}
      toggleFavoriteBusStop={toggleFavoriteBusStop}
      getData={getData}
      hasMoreData={hasMoreData}
    />
  );
};
