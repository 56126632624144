import React, {
  memo, useCallback, useRef,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import TimeAgo from 'react-timeago';
// @ts-ignore
import russianStrings from 'react-timeago/lib/language-strings/ru';
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

import { ISimpleNewsItemModel } from 'models/News/NewsModel';
import { NewsLikeComponent } from 'components/News/common/NewsLike';
import { useClicksDetect } from 'utils/hooks/useClicksDetect';

const formatter = buildFormatter(russianStrings);

type Props = {
  item: ISimpleNewsItemModel;
  onLikeClick: (id: number, liked: boolean) => void;
}


export const NewsItemComponent = memo(({ item, onLikeClick }: Props) => {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const onLikeClickLocal = useCallback(() => {
    onLikeClick(item.id, item.is_liked);
  }, [onLikeClick, item.id, item.is_liked]);


  const history = useHistory();

  const onSingleClickImg = useCallback(() => {
    history.push(`/news/${item.id}`);
  }, [item.id, history]);

  const onDoubleClickImg = useCallback(() => {
    onLikeClickLocal();
  }, [onLikeClickLocal]);

  const handleClick = useClicksDetect(onSingleClickImg, onDoubleClickImg);

  return (
    <div className="news-feed__item">
      {item.main_image.fullUrl.length > 0 && (
        <div
          role="presentation"
          onClick={handleClick}
          className="news-feed__img cursor-pointer"
          style={{ backgroundImage: `url(${item.main_image.fullUrl})` }}
        />
      )}
      <Link className="news-feed__title" to={`/news/${item.id}`}>{item.title}</Link>
      <div className="news-feed__desc show">
        <span ref={descriptionRef}>{item.short_description}</span>
      </div>
      <Link className="news-feed__more" to={`/news/${item.id}`}>
        Показать полностью
      </Link>
      <div className="news-feed__footer">
        <div className="news-feed__count">Нравится:<span>{item.like_count}</span></div>
        <div className="news-feed__date">
          <TimeAgo date={item.created_at} formatter={formatter} />
        </div>
        <NewsLikeComponent liked={item.is_liked} onLikeClick={onLikeClickLocal} short />
      </div>
    </div>
  );
});
