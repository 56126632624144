import React from 'react';
import { Button } from 'reactstrap';
import { Loader } from './UI/Loaders';

type Props = {
  loading: boolean;
  disabled?: boolean;
  label?: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  className?: string;
}

/** кнопка с лоадером (например при нажатии и сохранении формы) */
export const SubmitLoaderButton = ({
  loading, onClick, type = 'submit', disabled = false, label = 'Сохранить', className,
}: Props) => (
  <Button
    color="primary"
    type={type}
    disabled={disabled || loading}
    onClick={() => (onClick ? onClick() : null)}
    className={className}
  >
    <div className="d-flex justify-content-center">
      {label}
      {loading && <Loader className="ml-3" />}
    </div>
  </Button>
);
