import { IPrivilegeListItem } from './interfaces';
import { IEnterpriseModel } from '../Enterprises/EnterpriseModel';
import { CDate } from '../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../config/system';


export class PrivilegeListItem implements IPrivilegeListItem {
  readonly created_at: Date;
  readonly enterprises: IEnterpriseModel[];
  readonly id: number;
  readonly name: string;

  constructor(params: any = {}) {
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.enterprises = params.enterprises;
    this.id = params.id;
    this.name = params.name;
  }

  get enterprisesNames() {
    return this.enterprises.map((ent) => ent.name).join(', ');
  }

  userEnterprises(userEnterpriseIds: number[]) {
    return this.enterprises.filter((ent) => userEnterpriseIds.includes(ent.id));
  }
}
