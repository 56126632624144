import React, { memo, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { Control, Controller, ValidationOptions } from 'react-hook-form';

type Props = {
  control: Control;
  watch: (v: string) => any;
  name: string;
  errors: {[s: string]: any};
  label?: string | React.ReactNode;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  validation?: ValidationOptions;
  initClassName?: string;
  required?: boolean; // сокращение от validation={{ required: true }}
  thousandSeparator?: string;
  min?: number | boolean; // сокращение от validation={{ min }}
  max?: number; // максимальное конкретное значение. проверяется при вводе
  maxLength?: number; // максимальная длина числа до точки(целое). проверяется при вводе
  allowNegative?: boolean;
  allowDecimals?: boolean; // разрешать ввод дробных значений
  decimalScale?: number; // кол-во знаков после запятой
  system?: boolean;
}

const UiFormatNumberComponent = ({
  control,
  watch,
  name,
  errors,
  label = null,
  id,
  disabled = false,
  placeholder,
  className,
  validation = {},
  initClassName = '',
  required = false,
  thousandSeparator = ' ',
  min = false,
  max,
  maxLength = 6,
  allowNegative = false,
  allowDecimals = true,
  decimalScale = 2,
  system,
}: Props) => {
  const [active, setActive] = useState(false);
  const error = errors[name];

  /** определение/сбор класса */
  const formGroupClassName = [initClassName];
  if (active || watch(`${name}`)) {
    formGroupClassName.push('value');
  }
  if (className) {
    formGroupClassName.push(className);
  }
  if (!isEmpty(error)) {
    formGroupClassName.push('has-danger');
  }

  const localValidation = { ...validation };
  if (required) {
    localValidation.required = 'Обязательное поле';
    formGroupClassName.push('required');
  }

  if (isNumber(min)) {
    localValidation.min = {
      value: min,
      message: `Значение должно быть минимум ${min}`,
    };
  }

  return (
    <>
      <FormGroup className={formGroupClassName.filter((cName) => cName).join(' ')}>
        {label && (
          <Label htmlFor={id}>
            {label}
          </Label>
        )}
        <Controller
          control={control}
          decimalScale={decimalScale}
          thousandSeparator={thousandSeparator}
          allowNegative={allowNegative}
          as={(
            <NumberFormat
              isAllowed={({ floatValue, value: val }) => {
                const allowedErrors = [];
                if (!allowDecimals && val.indexOf('.') !== -1) {
                  return false;
                }
                if (maxLength && val.split('.')[0].length > maxLength) {
                  allowedErrors.push('maxLength');
                }
                if (max && floatValue && (max && floatValue > max)) {
                  allowedErrors.push('max');
                }
                return !allowedErrors.length;
              }}
            />
          )}
          name={name}
          rules={localValidation}
          placeholder={placeholder}
          id={id}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          required={required}
          disabled={disabled}
          className="form-control"
        />
        {!system && (
          <hr className="input-hr" />
        )}
        {error && (
          <div className={`form-control-feedback m-0${system ? ' form-error-block' : ''}`}>
            {error.message}
          </div>
        )}
      </FormGroup>
    </>
  );
};

/** для публичной части */
export const UiFormatNumber = memo((props: Props) => (
  <UiFormatNumberComponent {...props} initClassName="form-material no-border" />
));

/** для админской части */
export const UiSystemFormatNumber = memo((props: Props) => (
  <UiFormatNumberComponent {...props} system initClassName="system-formgroup" />
));
