import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IApplicationFilter, IApplicationsListModel } from 'models/Application/interfaces';
import { SystemAssignApplicationsComponent } from '../../components/Application/SystemAssignApplications';
import { systemGetAssignApplicationStartAction, systemGetReferencesApplicationStartAction } from './actions';
import {
  systemAssignApplicationsIsLoadingSelector,
  systemAssignApplicationsSelector,
} from './selectors';


export const SystemAssignApplications = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(systemGetReferencesApplicationStartAction());
  }, [dispatch]);

  const getData = useCallback((filter: IApplicationFilter) => {
    dispatch(systemGetAssignApplicationStartAction(filter));
  }, [dispatch]);

  const data: IApplicationsListModel = useSelector(systemAssignApplicationsSelector);
  const isLoading: boolean = useSelector(systemAssignApplicationsIsLoadingSelector);

  return <SystemAssignApplicationsComponent data={data} isLoading={isLoading} getData={getData} />;
};
