import { IBusStopsModel, BusStopsParamsType } from '../../models/BusStops/BusStops';
import { dataAction, dataSuccessAction, dataErrorAction } from '../../store/actions';
import {
  SET_BUS_STOP_FAVORITE_FAIL,
  SET_BUS_STOP_FAVORITE_OK,
  SET_BUS_STOP_FAVORITE_START,
  GET_BUS_STOP_LIST_FAIL,
  GET_BUS_STOP_LIST_OK,
  GET_BUS_STOP_LIST_START,
  REMOVE_BUS_STOP_FAVORITE_FAIL,
  REMOVE_BUS_STOP_FAVORITE_OK,
  REMOVE_BUS_STOP_FAVORITE_START,
} from './constants';

export const getBusStopListStartAction = (append: boolean, params?: BusStopsParamsType) => dataAction(
  GET_BUS_STOP_LIST_START,
)({ params, append });
export const getBusStopListOkAction = (busStops: IBusStopsModel, append: boolean) => dataSuccessAction(
  GET_BUS_STOP_LIST_OK,
)({ busStops, append });
export const getBusStopListFailAction = dataErrorAction(GET_BUS_STOP_LIST_FAIL);

export const setBusStopFavoriteStartAction = (id: number) => dataAction(
  SET_BUS_STOP_FAVORITE_START,
)({ id });
export const setBusStopFavoriteOkAction = (id: number) => dataSuccessAction(
  SET_BUS_STOP_FAVORITE_OK,
)({ id });
export const setBusStopFavoriteFailAction = dataErrorAction(SET_BUS_STOP_FAVORITE_FAIL);

export const removeBusStopFavoriteStartAction = (id: number) => dataAction(
  REMOVE_BUS_STOP_FAVORITE_START,
)({ id });
export const removeBusStopFavoriteOkAction = (id: number) => dataSuccessAction(
  REMOVE_BUS_STOP_FAVORITE_OK,
)({ id });
export const removeBusStopFavoriteFailAction = dataErrorAction(REMOVE_BUS_STOP_FAVORITE_FAIL);
