import React from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { ReactComponent as SearchIcon } from '../../assets/img/search.svg';
import { UiInput } from '../common/UI/Input';
import { BusStopsParamsType } from '../../models/BusStops/BusStops';

type Props = {
  submit: (append: boolean, params?: BusStopsParamsType) => void;
}

type FormValues = {
  name: string;
}

export const BusStopSearch = ({ submit }: Props) => {
  const { register, handleSubmit, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });

  const onSubmit = (data: FormValues) => submit(false, data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Row>
        <Col>
          <UiInput
            name="name"
            label="Введите название остановки"
            id="searchInput"
            register={register}
            value={getValues().name}
          />
        </Col>
        <Col xs={1} className="d-flex align-items-start justify-content-end">
          <button className="search-form_btn" type="submit">
            <SearchIcon />
          </button>
        </Col>
      </Row>
    </form>
  );
};
