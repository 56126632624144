import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { customHistory } from 'customHistory';
import { UploadFiles } from 'models/UploadFiles/UploadFiles';
import { Loader } from 'components/common/UI/Loaders';
import {
  ISystemPartnersCategoryListItemModel,
  SystemPartnersCategoryListItemModel,
} from 'models/Partners/SystemPartnerCategoryListModel';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  systemCreatePartnerCategory,
  systemGetPartnerCategoryById,
  systemUpdatePartnerCategory,
} from 'systemModule/api/partners';
import { SystemPartnersCategoryFormComponent } from 'systemModule/components/Partners/Category/form';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';
import { SystemPartnerCategoryFormFilesModel } from 'models/Partners/SystemPartnerCategoryModel';


export const SystemPartnersCategoryForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wPartnerCategory);

  const [model, modelLoading] = usePromise<ISystemPartnersCategoryListItemModel>(
    () => systemGetPartnerCategoryById(numberId),
    !isNaN(numberId),
    [numberId],
  );

  const onSave = async (model: SystemPartnerCategoryFormFilesModel, isNew: boolean) => {
    const logo = model.logo ? await new UploadFiles([model.logo]).upload() : [{ file_name: undefined }];

    if (Array.isArray(logo)) {
      const modelToSave = { ...model, logo: logo[0].file_name };
      const saveModel = isNew ?
        () => systemCreatePartnerCategory(modelToSave) :
        () => systemUpdatePartnerCategory(numberId, modelToSave);

      handleErrors(
        await saveModel(),
        'save',
        () => {
          toast.success(t(`system.partners.category.form.${isNew ? 'created' : 'updated'}`));
          customHistory.push('/system/partners/category');
        },
      );
    }
  };

  return modelLoading ? <Loader /> : (
    <SystemPartnersCategoryFormComponent
      model={model || new SystemPartnersCategoryListItemModel()}
      canUpdate={canUpdate}
      onSave={onSave}
    />
  );
};
