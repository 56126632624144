import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { getSystemUser } from 'systemModule/api/user';
import { usePromise } from 'utils/hooks/usePromise';
import { ISystemUserModel } from 'models/User/interfaces';
import { IRolesModel, TrbacUserRights } from 'models/Roles/RolesModel';
import { EntityNotFound } from 'components/Errors/404';
import { Loader } from 'components/common/UI/Loaders';
import { handleErrors } from 'utils/errors';
import { getUserRbac, saveUserRbac } from 'api/rbac';
import { SystemUserRoleComponent } from '../../components/Users/SystemUserRole';
import { systemRbacRolesIsLoadingSelector, systemRbacRolesSelector } from '../../store/selectors';
import { systemGetUserAndRolesStartAction } from './actions';


export const SystemUserRole = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const dispatch = useDispatch();

  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [userRolesLoading, setUserRolesLoading] = useState(false);

  useEffect(() => {
    if (!isNaN(numberId)) {
      dispatch(systemGetUserAndRolesStartAction(numberId));

      setUserRolesLoading(true);
      getUserRbac(numberId)
        .then((modelOrError) => {
          if (!(modelOrError instanceof Error)) {
            setUserRoles(Object.values(modelOrError).map((r) => r.name));
          }
          setUserRolesLoading(false);
        });
    }
  }, [numberId, dispatch]);

  const roles: IRolesModel = useSelector(systemRbacRolesSelector);
  const rolesLoading: boolean = useSelector(systemRbacRolesIsLoadingSelector);

  const [user, userLoading, onUnmount] = usePromise<ISystemUserModel>(
    () => getSystemUser(numberId),
    !isNaN(numberId),
    [numberId],
  );
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  const onSave = useCallback(async (data: TrbacUserRights) => {
    handleErrors(
      await saveUserRbac(numberId, data),
      'save',
      () => toast.success('Роли сохранены'),
    );
  }, [numberId]);


  if (rolesLoading || userLoading || userRolesLoading) {
    return <Loader />;
  }
  if (isNaN(numberId) || !user) {
    return <EntityNotFound message={t('common.user.notFound')} />;
  }
  return <SystemUserRoleComponent roles={roles.data} user={user} onSave={onSave} userRoles={userRoles} />;
};
