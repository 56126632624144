import { put, takeLatest, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { SYSTEM_GET_ASSIGN_APPLICATIONS_START, SYSTEM_GET_REFERENCES_APPLICATION_START } from './constants';
import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import {
  systemGetAssignApplicationFailAction,
  systemGetAssignApplicationOkAction,
  systemGetReferencesApplicationFailAction, systemGetReferencesApplicationOkAction,
} from './actions';
import { ApplicationStatusModel } from '../../../models/Application/Status/AppllicationStatusModel';
import { AppllicationTypeModel } from '../../../models/Application/Type/AppllicationTypeModel';
import { ApplicationsListModel } from '../../../models/Application/ApplicationListModel';


function* systemGetAssignApplicationsSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/application/list-assign', filter);

    yield put(systemGetAssignApplicationOkAction(new ApplicationsListModel(response)));
  }, systemGetAssignApplicationFailAction);
}

function* systemGetReferencesApplicationSaga() {
  yield baseSaga(function* (): any {
    const [statuses, types] = yield all([
      httpGet('/public/application/status'),
      httpGet('/public/application/type'),
    ]);

    const statusesModel = Object.entries(statuses)
      .map(([id, name]) => new ApplicationStatusModel({ id, name }));
    const typesModel = Object.entries(types)
      .map(([id, name]) => new AppllicationTypeModel({ id, name }));

    yield put(systemGetReferencesApplicationOkAction(statusesModel, typesModel));
  }, systemGetReferencesApplicationFailAction);
}


export function* systemApplicationSagas() {
  yield takeLatest(SYSTEM_GET_ASSIGN_APPLICATIONS_START, systemGetAssignApplicationsSaga);
  yield takeLatest(SYSTEM_GET_REFERENCES_APPLICATION_START, systemGetReferencesApplicationSaga);
}
