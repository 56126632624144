import React from 'react';


type Props = {
  name: string;
  count: number;
  onClick?: () => void;
}

export const CardBlock = ({ name, count, onClick }: Props) => (
  <div className={`card-block${onClick ? ' clickable' : ''}`} role="presentation" onClick={onClick}>
    {name}: <strong>{count}</strong>
  </div>
);
