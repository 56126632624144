import { ISystemMedicalItemModel, SystemMedicalItemModel } from '../../models/Medical/SystemMedicalModel';
import { baseApiFn } from '../../utils/baseApiFn';
import {
  httpDelete, httpGet, httpPost, httpPut,
} from '../../utils/http';
import { IMedicalFormModel } from '../../models/Medical/interfaces';

// Получить медицинский осмотр
export const getSystemMedical = async (id: number) => baseApiFn<ISystemMedicalItemModel>(async () => {
  const response = await httpGet(`/system/medical/${id}`);
  return new SystemMedicalItemModel(response);
});

// Удалить медицинский осмотр
export const deleteSystemMedical = async (id: number) => baseApiFn<true>(httpDelete(`/system/medical/${id}`));

// Редактировать медицинский осмотр
export const updateSystemMedical = async (id: number, model: IMedicalFormModel) => baseApiFn<ISystemMedicalItemModel>(
  httpPut(`/system/medical/${id}`, model),
);

// Создать медицинский осмотр
export const createSystemMedical = async (model: IMedicalFormModel) => baseApiFn<ISystemMedicalItemModel>(
  httpPost('/system/medical', model),
);
