import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import isEmpty from 'lodash/isEmpty';
import { IPollModel } from '../../models/Polls/PollModel';
import { UiBox } from '../common/UI/UiBox';
import { PollQuestionRenderByType } from './PollQuestionRenderByType';
import { SubmitLoaderButton } from '../common/SubmitLoaderButton';
import { ISavePollAnswers, PollsQuestionTypesEnum } from '../../models/Polls/PollsQuestionTypesModel';
import { PollAnswersModelType } from '../../models/Polls/PollAnswersModel';
import { CDate } from '../../utils/CDate';
import { PollStatusEnum } from '../../models/Polls/PollsStatusesModel';


type Props = {
  model: IPollModel;
  onAnswerPoll: (data: ISavePollAnswers) => void;
  answersSaveLoading: boolean;
  answers?: PollAnswersModelType;

  offlineVoteMode?: boolean; // true если внесение оффлайн ответов
  offlineUsername?: string; // фио сотрудника оффлайн голосования. чтоб не запрашивать методом
}

export const PollsOneComponent = ({
  model, onAnswerPoll, answersSaveLoading, answers, offlineVoteMode = false, offlineUsername = '',
}: Props) => {
  const { t } = useTranslation();

  /** форма очень динамическая, полностью зависит от questions(тип) */
  const formObject = useForm({
    mode: 'onChange',
    /**
     * подготовка имеющихся значений. надо совместить тип из вопроса и ответ по ид вопроса-ответа
     * актуально для просмотра пройденных анкет
     * выполняется только при наличии answers. иначе - {}
     */
    defaultValues: answers ? model.questions.reduce((acc: Record<string, any>, questionItem) => {
      const propName = `${questionItem.id}_${questionItem.type}`;
      const findAnswer = answers.find((a) => a.question_id === questionItem.id);
      let answer = findAnswer ? findAnswer.answer : null;

      if (answer) {
        /** ответ типа дата привести к дате */
        if (questionItem.type === PollsQuestionTypesEnum.datepicker) {
          answer = CDate.parse(answer.toString(), 'dd.MM.yyyy HH:mm');
        /** для radio value привести к строке */
        } else if (questionItem.type === PollsQuestionTypesEnum.radio) {
          answer = answer.toString();
        /** вообще, для чекбоксов должен быть массив строк, но и строку тоже принимает */
        } else if (questionItem.type === PollsQuestionTypesEnum.checkbox) {
          // @ts-ignore не совсем понятна проблема ts2349
          answer = Array.isArray(answer) ? answer.map((a: string) => a.toString()) : answer.toString();
        /** для select привести в selectOption */
        } else if (questionItem.type === PollsQuestionTypesEnum.select) {
          const optionSelected = questionItem.answers?.find((selectOption) => selectOption.id === Number(answer));
          if (optionSelected) {
            answer = { label: optionSelected.value, value: optionSelected.id.toString() };
          }
        }
        acc[propName] = answer;
      }
      return acc;
    }, {}) : {},
  });

  /** для своевременного обновления измененных данных формы */
  formObject.watch();

  /** надо чтобы на все вопросы были ответы. все имеющиеся поля обязательны к заполнению */
  const onSubmit = (data: any) => {
    /** подготовить данные. распарсить и взять нужные значения */
    const toSave = Object.entries(data).map(([name, value]: [string, any]) => {
      const id = +name.split('_')[0];
      const type = +name.split('_')[1];

      /** у нестроковых инпутов взять числовой id */
      let valueToSave = value;
      if (type === PollsQuestionTypesEnum.select) {
        valueToSave = +value.value;
      } else if (type === PollsQuestionTypesEnum.checkbox) {
        valueToSave = value.map((v: string) => +v);
      } else if (type === PollsQuestionTypesEnum.radio) {
        valueToSave = +value;
      } else if (type === PollsQuestionTypesEnum.datepicker) {
        /** дата для бэкэнда, без таймзона. просто строка */
        valueToSave = CDate.format(value, 'dd.MM.yyyy HH:mm');
      }

      return {
        question_id: id,
        value: valueToSave,
      };
    });

    onAnswerPoll({ answers: toSave });
  };

  return (
    <>
      <div className="page-header">
        <Link to="/polls" className="back" />
        {t('polls.one')}
      </div>
      <UiBox className="pollone">
        <section className="form">
          <div className="articles-list_item__text">
            {offlineVoteMode && (
              <div className="articles-list_item__title h3 mb-2 border-bottom">
                {t('poll.offline.fio', { username: offlineUsername })}
              </div>
            )}
            <div className="articles-list_item__title h3 mb-2">
              {model.title}
            </div>
          </div>
          <form className="form-with-btn" autoComplete="off" onSubmit={formObject.handleSubmit(onSubmit)}>
            <div className="poll-list">
              {model.questions.map((question) => (
                <PollQuestionRenderByType
                  key={question.id}
                  id={question.id}
                  type={question.type}
                  title={question.title}
                  answers={question.answers}
                  formObject={formObject}
                />
              ))}
            </div>

            {(model.status === PollStatusEnum.active || offlineVoteMode) && (
              <div className="form-wrapper_btn">
                <div className="buttons-group buttons-group-responsive">
                  <SubmitLoaderButton
                    disabled={!formObject.formState.isValid}
                    loading={answersSaveLoading}
                    label={t(isEmpty(answers) ? 'poll.save_answers.btn' : 'poll.save_answers_again.btn')}
                    className="mt-3"
                  />
                </div>
              </div>
            )}
          </form>

        </section>
      </UiBox>
    </>
  );
};

PollsOneComponent.whyDidYouRender = true;
