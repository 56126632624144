import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { useSelector } from 'react-redux';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { usePromise } from 'utils/hooks/usePromise';
import { handleErrors } from 'utils/errors';

import { toast } from 'react-toastify';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import { IPageReferralProgramModel } from 'models/References/PageReferralProgramModel';
import {
  SystemPageReferralProgramComponent,
} from 'systemModule/components/References/ReferralProgram/SystemPageReferralProgram';
import {
  getSystemReferralProgramText,
  saveSystemReferralProgramText,
} from 'systemModule/api/references/referralProgram';

export const SystemPageReferralProgram = () => {
  const { t } = useTranslation();
  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wPages);

  const [model, loading] = usePromise<IPageReferralProgramModel>(getSystemReferralProgramText, true);

  const onSave = async (model: IPageReferralProgramModel) => {
    handleErrors(
      await saveSystemReferralProgramText(model),
      'save',
      () => toast.success(t('system.references.page.referralProgram.saved')),
    );
  };

  if (loading) {
    return <Loader />;
  }
  if (model) {
    return <SystemPageReferralProgramComponent canUpdate={canUpdate} model={model} onSave={onSave} />;
  }
  return <EntityNotFound message={t('system.references.page.referralProgram.404')} />;
};
