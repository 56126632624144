import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import { Loader } from '../../components/common/UI/Loaders';
import {
  deleteUserFavorite, getFavoriteUserList, getUserListByFavorite, setUserFavorite,
} from '../../api/Users';
import { IUserListModel, UsersFilterType } from '../../models/Users/UsersModel';
import { handleErrors } from '../../utils/errors';
import { getEnterprisesIsLoadingSelector, getEnterprisesSelector } from '../Enterprises/selectors';
import { getEnterprisesListAction } from '../Enterprises/actions';
import { ContactsComponent } from '../../components/Contacts/Contacts';
import { IDepartmentItemModel } from '../../models/References/DepartmentModel';
import { getDepartmentList } from '../../api/references';
import { maxValueToGetAllDataWithPagination } from '../../config/system';
import { CurrentUserModel } from '../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../store/currentUser/selectors';
import { useClonedEnterprises } from '../../utils/hooks/useClonedEnterprises';

const localStorageKeyCommonPart = 'contactFilterData_UserId';

export const ContactsContainer = () => {
  const dispatch = useDispatch();

  const [usersIsLoading, setUsersIsLoading] = useState(false);
  const [users, setUsers] = useState<IUserListModel[]>([]);

  const [departmentsIsLoading, setDepartmentsIsLoading] = useState(false);
  const [departments, setDepartments] = useState<IDepartmentItemModel[]>([]);

  const [changingFavoriteLoading, setChangingFavoriteLoading] = useState(false);

  /** предприятия пользователя. для фильтра */
  const { enterprises: userEnterprises }: CurrentUserModel = useSelector(getCurrentUserSelector);

  /** все предприятия */
  const allEnterprises = useSelector(getEnterprisesSelector);
  const allEnterprisesLoading = useSelector(getEnterprisesIsLoadingSelector);

  const { id: currentUserId }: CurrentUserModel = useSelector(getCurrentUserSelector);
  const localStorageKey = `${localStorageKeyCommonPart}${currentUserId}`;
  /**
   * Получить список пользователей
   * @param filter - фильтр для поиска
   * @param isFavorite - если true, список только избранных пользователей, иначе - всех, но избранные вначале
   */
  const getUsers = useCallback(async (filter: UsersFilterType, isFavorite = false) => {
    setUsersIsLoading(true);
    const apiFunc = isFavorite ? getFavoriteUserList : getUserListByFavorite;

    handleErrors(
      await apiFunc({
        ...filter,
        is_register: true,
      }),
      'get',
      (contactsModel) => setUsers(contactsModel.data),
    );
    setUsersIsLoading(false);
  }, []);

  const getDepartments = useCallback(async (name?: string) => {
    setDepartmentsIsLoading(true);

    handleErrors(
      await getDepartmentList({ name, 'per-page': maxValueToGetAllDataWithPagination }),
      'get',
      (model) => setDepartments(model.data),
    );

    setDepartmentsIsLoading(false);
  }, []);

  useEffect(() => {
    dispatch(getEnterprisesListAction());
  }, [dispatch]);

  const clonedEnterprises = useClonedEnterprises(allEnterprises);

  /** Изменить значение избранного пользователя в массиве */
  const replaceFavoriteContact = useCallback((index: number, unitId: number, userId: number, isFavorite: boolean) => {
    const newContacts = users.slice();
    const foundUnit = find(newContacts[index].units, { id: unitId });
    const foundUser = foundUnit && find(foundUnit.users, { id: userId });

    if (foundUser) {
      foundUser.is_favorite = isFavorite;
      setUsers(newContacts);
    }
  }, [users]);

  /** Добавить/удалить избранного пользователя */
  const toggleFavorite = useCallback(async (index: number, unitId: number, userId: number, isFavorite: boolean) => {
    setChangingFavoriteLoading(true);
    const modelOrError = isFavorite ? await deleteUserFavorite(userId, unitId) : await setUserFavorite(userId, unitId);

    handleErrors(
      modelOrError,
      'save',
      () => {
        replaceFavoriteContact(index, unitId, userId, !isFavorite);
      },
    );

    setChangingFavoriteLoading(false);
  }, [replaceFavoriteContact]);

  if (allEnterprisesLoading) {
    return <Loader />;
  }

  return (
    <ContactsComponent
      usersIsLoading={usersIsLoading}
      departmentsIsLoading={departmentsIsLoading}
      changingFavoriteLoading={changingFavoriteLoading}
      getUsers={getUsers}
      getDepartments={getDepartments}
      toggleFavorite={toggleFavorite}
      allEnterprises={clonedEnterprises}
      currentEnterprise={userEnterprises}
      users={users}
      departments={departments}
      localStorageKey={localStorageKey}
    />
  );
};
