import { IPrivilegeItem } from './interfaces';


export class PrivilegeItem implements IPrivilegeItem {
  contact: string;
  documents: string;
  id: number;
  name: string;
  requirement: string;

  constructor(params: any = {}) {
    this.contact = params.contact;
    this.documents = params.documents;
    this.id = params.id;
    this.name = params.name;
    this.requirement = params.requirement;
  }
}
