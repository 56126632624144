export type INotificationSettingsFormType = {
  enable_push: boolean;
  enable_email: boolean;
  email: string;
}


export interface INotificationSettings {
  enable_push: boolean;
  enable_email: boolean;
  email: string;
  email_checked: boolean;
}

export class NotificationSettings implements INotificationSettings {
  email: string;
  email_checked: boolean;
  enable_email: boolean;
  enable_push: boolean;

  constructor(params: any = {}) {
    this.email = params.email || '';
    this.email_checked = params.email_checked;
    this.enable_email = params.enable_email;
    this.enable_push = params.enable_push;
  }
}
