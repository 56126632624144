export interface IPageBonusProgramModel {
  name: string;
  text: string;
}

export class PageBonusProgramModel implements IPageBonusProgramModel {
  name: string;
  text: string;

  constructor(params: any = {}) {
    this.name = params.name;
    this.text = params.text;
  }
}
