import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SystemTransportComponent } from 'systemModule/components/Transport/SystemTransport';
import { selRoutesListIsLoading, selRoutesListList } from 'systemModule/containers/Transport/selectors';
import { systemGetTransportRoutesStartAction } from 'systemModule/containers/Transport/actions';
import { DefaultFilterType } from 'models/common';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { handleErrors } from 'utils/errors';
import { deleteSystemTransport } from 'systemModule/api/transport';
import { toast } from 'react-toastify';
import { getParamsFromPagesModel } from 'models/WithPagesModel';
import { useTranslation } from 'react-i18next';
import { ISystemRouteModel } from 'models/Transport/SystemRouteModel';

export const SystemTransportList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemGetTransportRoutesStartAction(filter));
  }, [dispatch]);

  const data = useSelector(selRoutesListList);
  const isLoading: boolean = useSelector(selRoutesListIsLoading);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cTransport);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dTransport);

  const onDelete = useCallback((item: ISystemRouteModel) => async () => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', {
      entity: t('system.transport.route'),
      entityName: item.name,
    }))) {
      handleErrors(
        await deleteSystemTransport(item.id),
        'delete',
        () => {
          toast.success(t('system.transport.route.deleted'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  }, [canDelete, data, getData, t]);

  return (
    <SystemTransportComponent
      getData={getData}
      data={data}
      isLoading={isLoading}
      onDelete={onDelete}
      canCreate={canCreate}
      canDelete={canDelete}
    />
  );
};
