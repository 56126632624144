import React from 'react';
import { Button } from 'reactstrap';


type Props = {
  dayWeek: number;
  setDay: (day: number) => void;
}


const days = [{
  number: 1,
  day: 'Пн',
}, {
  number: 2,
  day: 'Вт',
}, {
  number: 3,
  day: 'Ср',
}, {
  number: 4,
  day: 'Чт',
}, {
  number: 5,
  day: 'Пт',
}, {
  number: 6,
  day: 'Сб',
}, {
  number: 7,
  day: 'Вс',
}];


export const UiWeekDaysSwitcher = ({ dayWeek, setDay }: Props) => (
  <div className="tabs">
    {
      days.map((item) => (
        <Button
          key={item.number}
          onClick={() => setDay(item.number)}
          className={(dayWeek === item.number) ? 'item active' : 'item'}
        >{item.day}
        </Button>
      ))
    }
  </div>
);
