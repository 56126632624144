import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { IEventModel } from '../../models/Events/EventModel';
import { CDate } from '../../utils/CDate';


type Props = {
  item: IEventModel;
}

export const EventListItem = memo(({ item }: Props) => (
  <div className="articles-list_item">
    <div className="articles-list_item__wrapper">
      <Link className="articles-list_item__box" to={`/events/${item.id}`}>
        {item.image && (
          <div className="articles-list_item__img">
            <div className="img" style={{ backgroundImage: `url(${item.image.fullUrl})` }} />
          </div>
        )}
        <div className="articles-list_item__text">
          <div className="articles-list_item__date">
            {CDate.format(item.date_start, 'dd.MM.yyyy/HH:mm')}
          </div>
          <div className="articles-list_item__title">{item.name}</div>
          <div className="articles-list_item__info">{item.place}</div>
        </div>
        <div className="articles-list_item__direction" />
      </Link>
    </div>
  </div>
));
