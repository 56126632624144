import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { BONUS_PROGRAM_FORM_ITEM_COST_PATTERN } from 'config/system';
import {
  ISystemBonusProgramPrizeItemModel,
  ISystemBonusProgramPrizeItemFormModel,
} from 'models/BonusProgram/Prize/interfaces';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';

type FormValues = {
  title: string;
  description: string;
  priceReal: number | string;
  priceSdsCoin: number | string;
  isActive: boolean;
  priority: number;
}

type Props = {
  model: ISystemBonusProgramPrizeItemModel
  onSave: (modelId: number | null, modelToSave: ISystemBonusProgramPrizeItemFormModel) => void;
  canUpdatePrize: boolean;
  canCreatePrize: boolean;
}

export const SystemBonusProgramPrizeItemFormComponent = ({
  model, onSave, canUpdatePrize, canCreatePrize,
}: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, errors,
    formState: { isSubmitting, dirtyFields, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      title: model.title,
      description: model.description,
      priceReal: Number(model.priceReal) || '',
      priceSdsCoin: Number(model.priceSdsCoin) || '',
      isActive: model.isActive,
      priority: model.priority,
    },
  });

  const onSubmit = async (data: FormValues) => {
    if ((model.id && !canUpdatePrize) || (!model.id && !canCreatePrize)) {
      return;
    }

    const modelToSave: ISystemBonusProgramPrizeItemFormModel = {
      title: data.title,
      description: data.description,
      price_real: data.priceReal,
      price_sdscoin: data.priceSdsCoin,
      is_active: data.isActive,
      priority: data.priority,
    };

    onSave(model?.id || null, modelToSave);
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={!model.id}
      showButtons={canUpdatePrize}
    >
      <h3>{t(model.id ? 'system.bonusProgram.form.update.prize' : 'system.bonusProgram.form.create.prize')}</h3>

      <UiSystemInput
        name="title"
        errors={errors}
        register={register}
        label={t('system.bonusProgram.prize.title.label')}
        required
      />

      <UiSystemInput
        name="description"
        type="textarea"
        errors={errors}
        register={register}
        label={t('system.bonusProgram.prize.description.label')}
      />

      <UiSystemInput
        name="priceReal"
        errors={errors}
        register={register}
        label={t('system.bonusProgram.prize.priceReal.label')}
        validation={{
          pattern: BONUS_PROGRAM_FORM_ITEM_COST_PATTERN,
        }}
      />

      <UiSystemInput
        name="priceSdsCoin"
        errors={errors}
        register={register}
        label={t('system.bonusProgram.prize.priceSdsCoin.label')}
        required
        validation={{
          pattern: BONUS_PROGRAM_FORM_ITEM_COST_PATTERN,
        }}
      />

      <UiSystemInput
        name="priority"
        errors={errors}
        register={register}
        label={t('system.bonusProgram.prize.priority.label')}
        required
        type="number"
      />

      <UiSystemCheckbox
        register={register}
        name="isActive"
        label={t('system.bonusProgram.prize.isActive.label')}
      />

    </UiSystemForm>
  );
};
