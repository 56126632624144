import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import {
  SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_FAIL,
  SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_OK,
  SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_START,
} from './constants';
import {
  ISystemNotificationTemplatesListModel,
  SystemNotificationTemplatesListModel,
} from '../../../models/Notification/SystemNotificationTemplatesListModel';


export type systemNotificationsReducerType = {
  templates: ISystemNotificationTemplatesListModel;
  templatesIsLoading: boolean;
}

const initialState: systemNotificationsReducerType = {
  templates: new SystemNotificationTemplatesListModel(),
  templatesIsLoading: true,
};

export const storeName = 'notifications';

export const systemNotificationsReducer = {
  [storeName]: (state: systemNotificationsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_START:
        return onLoading(state, 'templates');
      case SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_OK:
        return onLoad(state, payload, 'templates');
      case SYSTEM_NOTIFICATION_TEMPLATES_GET_LIST_FAIL:
        return onFail(state, 'templates');

      default:
        return state;
    }
  },
};
