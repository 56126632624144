import React, { useCallback, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ParamTypes } from 'models/common';
import { PollsOneComponent } from '../../components/Polls/PollsOne';
import { usePromise } from '../../utils/hooks/usePromise';
import { getOfflinePollById, saveOfflinePollAnswers } from '../../api/poll';
import { Loader } from '../../components/common/UI/Loaders';
import { EntityNotFound } from '../../components/Errors/404';
import { IPollModel, PollModel } from '../../models/Polls/PollModel';
import { ISavePollAnswers } from '../../models/Polls/PollsQuestionTypesModel';
import { handleErrors } from '../../utils/errors';

interface LocationState {
  userName: string;
}

/**
 * форма для внесения оффлайн-ответов, аналогичная обычной форме опроса
 * методы используются системные
 */
export const PollsOfflineOne = () => {
  const { id, userId } = useParams<ParamTypes>();
  const numberId = toNumber(id);
  const numberUserId = toNumber(userId);

  const { state: { userName = '' } = {} } = useLocation<LocationState>();

  const { t } = useTranslation();

  const history = useHistory();

  const [model, loading] = usePromise<IPollModel>(
    () => getOfflinePollById(numberId),
    !isNaN(numberId) && !isNaN(numberUserId),
    [numberId],
  );

  const [answersSaveLoading, setAnswersSaveLoading] = useState(false);

  /** сохранить ответы на вопросы */
  const onAnswerPoll = useCallback(async (data: ISavePollAnswers) => {
    setAnswersSaveLoading(true);
    handleErrors(
      await saveOfflinePollAnswers(numberId, {
        user_id: numberUserId,
        ...data,
      }),
      'save',
      () => {
        toast.success(t('poll.offline.answers_saved'));
        history.push(`/system/polls/${numberId}`);
      },
      () => setAnswersSaveLoading(false),
    );
  }, [numberId, numberUserId, history, t]);

  if (loading) {
    return <Loader />;
  }
  if (model instanceof PollModel && numberUserId) {
    return (
      <PollsOneComponent
        model={model}
        onAnswerPoll={onAnswerPoll}
        answersSaveLoading={answersSaveLoading}
        offlineVoteMode={!isNaN(numberUserId)}
        offlineUsername={userName}
      />
    );
  }
  return <EntityNotFound message={t('polls.404')} />;
};
