import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ISystemReferralProgramModel } from '../../../models/ReferralProgram/interfaces';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { SimpleTable } from '../../../components/common/Table/SimpleTable';

type FormValues = {
  userData: any;
  code: string;
}

type Props = {
  data: ISystemReferralProgramModel;
  dataLoading: boolean;
}
export const SystemReferralItemComponent = ({ data, dataLoading }: Props) => {
  const { t } = useTranslation();

  const {
    register, errors,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      userData: `ID: ${data.user.id}, ФИО: "${data.user.fio}"`,
      code: data.code,
    },
  });

  return (
    <>
      <h3>Подробная информация</h3>
      <UiSystemInput
        name="userData"
        errors={errors}
        register={register}
        label="Пользователь"
        disabled
      />
      <UiSystemInput
        name="code"
        errors={errors}
        register={register}
        label="Реферальный код пользователя"
        disabled
      />
      <h4 className="mt-5">Пользователи, которые активировали данный код</h4>
      <SimpleTable
        tableHeaders={[
          { name: 'ID' },
          { name: 'ФИО' },
        ]}
        isLoading={dataLoading}
      >
        {data.activations.map((item) => (
          <tr key={item.id}>
            <td>{item.user.id}</td>
            <td>{item.user.fio}</td>
          </tr>
        ))}
      </SimpleTable>
      <div className="buttons-group justify-content-start buttons-group-responsive">
        <Link to="/system/referral-program" className="btn btn-secondary">
          {t('common.cancel')}
        </Link>
      </div>
    </>
  );
};
