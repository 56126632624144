import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import isNull from 'lodash/isNull';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { getSystemPrivilegeListAll } from 'api/privilege';
import {
  ConstructApplicationTypeModelAvailableType,
  IConstructApplicationTypeModel,
} from 'models/ConstructApplication/interfaces';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import { customHistory } from 'customHistory';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  getConstructApplicationTypeById,
  updateConstructApplicationAvailable,
} from 'systemModule/api/construct-application';
import {
  ConstructApplicationTypeAvailableComponent,
} from 'systemModule/components/ConstructApplication/Type/ConstructApplicationTypeAvailableForm';
import { convertModelToOptions, SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';


export const ConstructApplicationTypeAvailable = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wApplication);

  /** получить тип заявки по ид */
  const [model, modelLoading] = usePromise<IConstructApplicationTypeModel>(
    () => getConstructApplicationTypeById(numberId),
    !isNaN(numberId),
    [numberId],
  );

  const onSave = useCallback(async (modelToSave: ConstructApplicationTypeModelAvailableType) => {
    if (!model) {
      return;
    }

    handleErrors(
      await updateConstructApplicationAvailable(model.id, modelToSave),
      'save',
      () => {
        toast.success(t('system.construct_application.type.available_saved'));
      },
    );
  }, [t, model]);


  const [privilegeList, setPrivilegeList] = useState<SimpleSelectOptionType<number>[]>([]);
  const [privilegeListLoading, setPrivilegeListLoading] = useState(true);

  const isAllEnterprise = model?.enterprise === null;

  /** дождаться загрузки модели и загрузить список льгот, в зависимости от предприятия(й) */
  useEffect(() => {
    if (!isNull(model)) {
      setPrivilegeListLoading(true);

      const enterprise_ids = model.enterprise ? [model.enterprise.id] : undefined;

      getSystemPrivilegeListAll(enterprise_ids)
        .then((privilegeModel) => {
          if (!(privilegeModel instanceof Error)) {
            setPrivilegeList(convertModelToOptions(privilegeModel));
          }
        })
        .finally(() => setPrivilegeListLoading(false));
    }
  }, [model]);


  if (!canUpdate) {
    customHistory.push('/system/construct-application/create');
  }

  if (modelLoading || privilegeListLoading) {
    return <Loader />;
  }
  if (model) {
    return (
      <ConstructApplicationTypeAvailableComponent
        model={model.availability}
        onSave={onSave}
        privilegeList={privilegeList}
        isAllEnterprise={isAllEnterprise}
      />
    );
  }
  return <EntityNotFound message={t('system.construct_application.type.404')} />;
};
