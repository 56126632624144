import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ParamTypes } from 'models/common';
import { SystemPrivilegesFormComponent } from 'systemModule/components/Priveleges/SystemPrivilegesForm';
import { usePromise } from 'utils/hooks/usePromise';
import { createSystemPrivilege, getSystemPrivilegeItem, updateSystemPrivilege } from 'api/privilege';
import { ISystemPrivilegeItem, SystemPrivilegeFormType } from 'models/Privilege/interfaces';
import { Loader } from 'components/common/UI/Loaders';
import { SystemPrivilegeItem } from 'models/Privilege/SystemPrivilegeItem';
import { handleErrors } from 'utils/errors';


export const SystemPrivilegesForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const history = useHistory();

  const [model, isLoading] = usePromise<ISystemPrivilegeItem>(
    () => getSystemPrivilegeItem(numberId), !isNaN(numberId), [numberId],
  );

  const [loading, setLoading] = useState(false);

  const onSave = useCallback(async (formModel: SystemPrivilegeFormType) => {
    setLoading(true);

    /** определить - обновить или создать */
    const apiFn = model?.id ? () => updateSystemPrivilege(model.id, formModel) : () => createSystemPrivilege(formModel);

    handleErrors(
      await apiFn(),
      'save',
      () => {
        history.push('/system/priveleges');
        toast.success(t(`system.privilege.${model?.id ? 'updated' : 'created'}`));
      },
    );
  }, [model, t, history]);

  if (isLoading) {
    return <Loader />;
  }
  return <SystemPrivilegesFormComponent model={model || new SystemPrivilegeItem()} onSave={onSave} loading={loading} />;
};
