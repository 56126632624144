import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { IBusStopScheduleForm } from 'models/Transport/interfaces';
import { sub } from 'date-fns';

export type FormBlockType = {
  id: number;

  busStop: SimpleSelectOptionType | null;
  haveHolidaySchedule: boolean;
  fields?: WeekDayFormValues[];
  holidaysFields?: HolidayFormValues[];
  holidaysDates?: DatesFormValues[];
}

export type DatesFormValues = {
  id: string;
  date: string;
};

export type HolidayFormValues = {
  id: string;
  time: string
  date: string;
}

export type WeekDayFormValues = {
  id: string;
  time: string;
  day: number;
}

export type FormValues = {
  name: string;
  enterprise: SimpleSelectOptionType | null;
  blocks: FormBlockType[];
}

/** макс. возможное кол-во блоков в форме */
export const MAX_TRANSPORT_BLOCKS_AMOUNT = 25;

/** макс. возможное кол-во полей в форме */
export const MAX_TRANSPORT_FIELDS_AMOUNT = 100;

/** 1 пустое поле внутри блока */
export const defaultEmptyWeekDayField = (day = 1): WeekDayFormValues => ({
  id: Math.random().toString(),
  time: '',
  day,
});

export const defaultEmptyHolidayField = (date = ''): HolidayFormValues => ({
  id: Math.random().toString(),
  time: '',
  date,
});

export const defaultEmptyHolidayDates = (date = '', id = Math.random().toString()): DatesFormValues => ({
  id,
  date,
});

/** 1 пустой блок */
export const defaultEmptyBlock = (): FormBlockType => ({
  id: Math.random(),
  busStop: null,
  haveHolidaySchedule: false,
  fields: [],
  holidaysFields: [],
  holidaysDates: [],
});

/** подготовить разрегистрируемые поля пОля */
export const prepareRemoveWeekDaysField = (blockIndex: number, fieldIndex: number): string[] => [
  `blocks[${blockIndex}].fields[${fieldIndex}].id`,
  `blocks[${blockIndex}].fields[${fieldIndex}].time`,
  `blocks[${blockIndex}].fields[${fieldIndex}].day`,
];

export const prepareRemoveHolidaysField = (blockIndex: number, fieldIndex: number): string[] => [
  `blocks[${blockIndex}].holidaysFields[${fieldIndex}].id`,
  `blocks[${blockIndex}].holidaysFields[${fieldIndex}].time`,
  `blocks[${blockIndex}].holidaysFields[${fieldIndex}].date`,
];

export const prepareRemoveHolidaysDates = (blockIndex: number, fieldIndex: number): string[] => [
  `blocks[${blockIndex}].holidaysDates[${fieldIndex}].id`,
  `blocks[${blockIndex}].holidaysDates[${fieldIndex}].date`,
];


export const prepareTypeFieldBlock = (block: FormBlockType): IBusStopScheduleForm => {
  const weekDays = new Array(7)
    .fill(null)
    .map((_, i) => ({
      day_week: i + 1,
      times: block.fields ? block.fields
        .filter((item) => item.day === i + 1)
        .map((item) => item.time) : [],
    }));

  const holidays = (block.haveHolidaySchedule && block.holidaysDates) ?
    block.holidaysDates
      .filter((item) => new Date(item.date) > sub(new Date(), { days: 1 }))
      .map((itemDate) => ({
        date: itemDate.date,
        times: block.holidaysFields ? block.holidaysFields
          .filter((item) => item.date === itemDate.date)
          .map((item) => item.time) : [],
      })) : [];

  return {
    bus_stop_id: block.busStop?.value || 0,
    weekDays,
    holidays,
  };
};

export const prepareSchedule = (blocks: FormBlockType[]): IBusStopScheduleForm[] => blocks
  .map((block) => prepareTypeFieldBlock(block));
