import { IApplicationConstructTypeModel } from 'models/Application/Construct/interfaces';


export const ApplicationConstructTypeModel = (data: any = {}): IApplicationConstructTypeModel => ({
  additionalFields: data.additionalFields,
  assignUsers: data.assignUsers,
  availability: data.availability,
  category: data.category,
  created_at: data.created_at,
  deadline: data.deadline,
  enterprise: data.enterprise || null,
  id: data.id,
  is_active: data.is_active,
  name: data.name,
  original_place: data.original_place,
  schedule: data.schedule,
  send_email: data.send_email,
  updated_at: data.updated_at,
});
