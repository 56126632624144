import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { BACKEND_TIME_ONLY_FORMAT } from 'config/system';
import { IBusStopsItem } from '../../models/BusStops/BusStops';
import { ReactComponent as LikeIcon } from '../../assets/img/like.svg';

type Props = {
  item: IBusStopsItem;
  toggleFavoriteBusStop: (id: number, isFavorite: boolean) => void;
  busStopsIsLoading: boolean;
}

export const BusStopItem = ({
  item, toggleFavoriteBusStop, busStopsIsLoading,
}: Props) => {
  const onToggleFavorite = () => {
    toggleFavoriteBusStop(item.id, item.favorite);
  };

  // Получить ближайшее время больше текущего
  const nowTime = format(new Date(), BACKEND_TIME_ONLY_FORMAT);
  const soon = item.times?.find((time) => time >= nowTime);

  return (
    <div className="list-element">
      <Link className="list-element_content" to={`/bus-stops/${item.id}`}>
        <div className="line title">{item.name}</div>
        {soon && (
          <div className="line info">
            <div className="item">{ soon }</div>
          </div>
        )}

      </Link>
      <button
        className={`list-element_icon like-btn favorite${item.favorite ? ' active' : ''}`}
        onClick={onToggleFavorite}
        type="button"
        disabled={busStopsIsLoading}
      >
        <LikeIcon />
      </button>
    </div>
  );
};
