import { maxValueToGetAllDataWithPagination } from 'config/system';
import { httpGet } from 'utils/http';

/**
 * получить все данные из списковых методов
 */
export const getAllListData = async (path: string, DataModel: any, addParams = {}) => {
  const data: any[] = [];
  let response;
  let page = 1;

  do {
    // eslint-disable-next-line no-await-in-loop
    response = await httpGet(path, {
      'per-page': maxValueToGetAllDataWithPagination,
      page,
      ...addParams,
    });

    page += 1;
    const models = response.data.map((model: unknown) => new DataModel(model));

    data.push(...models);
  } while (response.page < response.amountOfPages);

  return data;
};
