import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isNaN from 'lodash/isNaN';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { customHistory } from 'customHistory';
import { Loader } from 'components/common/UI/Loaders';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { IEventFormModel } from 'models/Events/IEventFormModel';
import { projectBaseUrl } from 'utils/env';
import { handleErrors } from 'utils/errors';
import { ISystemEvent, SystemEventModel } from 'models/Events/SystemEvent';
import { usePromise } from 'utils/hooks/usePromise';
import { ISystemEventPollResults } from 'models/Events/SystemEventPollResult';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import {
  createSystemEvent, getSystemEvent, getSystemEventPollResult, updateSystemEvent,
} from '../../api/events';
import { systemEnterprisesSelector } from '../../store/selectors';
import { SystemEventsFormComponent } from '../../components/Events/SystemEventsForm';


export const SystemEventsForm = () => {
  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [pollResultsModel, setPollResultsModel] = useState<ISystemEventPollResults | null>(null);
  const [pollResultsModelLoading, setPollResultsModelLoading] = useState(true);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wEvents);

  const onSave = useCallback(async (modelId: number | null, modelToSave: IEventFormModel) => {
    /** если есть modelId, значит это сценарий обновления */
    const trueOrError = modelId ? await updateSystemEvent(modelId, modelToSave) : await createSystemEvent(modelToSave);

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t(modelId ? 'event.updated' : 'event.created'));
        customHistory.push('/system/events');
      },
    );
  }, [t]);

  /** получить результаты голосования. только если еще не загружены */
  const getPollResults = useCallback(async () => {
    if (!pollResultsModel) {
      setPollResultsModelLoading(true);
      handleErrors(
        await getSystemEventPollResult(numberId),
        'get',
        (model) => setPollResultsModel(model),
        () => setPollResultsModelLoading(false),
      );
    }
  }, [pollResultsModel, numberId]);

  /** получить событие */
  const [model, modelLoading] = usePromise<ISystemEvent>(() => getSystemEvent(numberId), !isNaN(numberId));

  /** Выгрузка результата голосования по мероприятию */
  const linkToExport = projectBaseUrl ?
    `${projectBaseUrl.replace(/^http(s)?:/, '')}/api/system/event/${numberId}/export-poll` :
    '/';

  if (modelLoading) {
    return <Loader />;
  }
  return (
    <SystemEventsFormComponent
      model={model || new SystemEventModel()}
      enterprises={enterprises}
      onSave={onSave}
      getPollResults={getPollResults}
      pollResultsModel={pollResultsModel}
      pollResultsModelLoading={pollResultsModelLoading}
      linkToExport={linkToExport}
      canUpdate={canUpdate}
    />
  );
};
