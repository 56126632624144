import { httpGet } from '../../../utils/http';

import {
  EnterprisesFilterType,
  ISystemEnterpriseModel,
  ISystemEnterprisesModel,
  SystemEnterpriseModel,
  SystemEnterprisesModel,
} from '../../../models/References/EnterprisesModel';
import { baseApiFn } from '../../../utils/baseApiFn';

export const getSystemEnterprise = async (id: number) => baseApiFn<ISystemEnterpriseModel>(async () => {
  const response = await httpGet(`system/references/enterprise/${id}`);
  return new SystemEnterpriseModel(response);
});

export const getSystemEnterprises = async (filter?: EnterprisesFilterType) => baseApiFn<ISystemEnterprisesModel>(
  async () => {
    const response = await httpGet('system/references/enterprise', filter);
    return new SystemEnterprisesModel(response);
  },
);
