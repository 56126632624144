import React, { memo } from 'react';
import { UiCheckbox } from './Checkbox';

type Props = {
  name: string;
  answers: {value: string; label: string}[];
  register: any;
  required?: boolean;
}

export const UiCheckboxGroupComponent = ({
  name, answers, register, required,
}: Props) => (
  <>
    {answers.map((answer) => (
      <UiCheckbox
        key={answer.value}
        name={name}
        register={register}
        label={answer.label}
        value={answer.value}
        id={`${answer.label}_${answer.value}`}
        required={required}
      />
    ))}
  </>
);

export const UiCheckBoxGroup = memo(UiCheckboxGroupComponent);
