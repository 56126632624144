import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import {
  SYSTEM_GET_POLLS_FAIL,
  SYSTEM_GET_POLLS_OK,
  SYSTEM_GET_POLLS_START,
} from './constants';
import { ISystemPollsModel } from '../../../models/Polls/SystemPollsModel';
import { DefaultFilterType } from '../../../models/common';


export const systemGetPollsStartAction = (filter: DefaultFilterType = {}, all: boolean) => dataAction(
  SYSTEM_GET_POLLS_START,
)({ filter, all });
export const systemGetPollsOkAction = (polls: ISystemPollsModel) => dataSuccessAction(
  SYSTEM_GET_POLLS_OK,
)({ polls });
export const systemGetPollsFailAction = dataErrorAction(SYSTEM_GET_POLLS_FAIL);
