import { CDate } from 'utils/CDate';
import { ISystemEducationModel } from './interfaces';
import { FileType, prepareFiles } from '../common';


export class SystemEducationModel implements ISystemEducationModel {
  readonly contacts: string;
  readonly date_start: Date | null;
  readonly id: number;
  readonly image: FileType;
  readonly info: string;
  readonly name: string;
  readonly place: string;
  readonly schedule: string;

  constructor(params: any = {}) {
    this.contacts = params.contacts || '';
    this.date_start = params.date_start ? CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm') : null;
    this.id = params.id;
    this.image = prepareFiles(params.image);
    this.info = params.info || '';
    this.name = params.name || '';
    this.place = params.place || '';
    this.schedule = params.schedule || '';
  }
}
