import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { customHistory } from 'customHistory';
import { IJobDescriptionsItemModel } from '../../../../models/JobDescription/JobDescription';
import { ISystemUnitModel, UnitsFilterType } from '../../../../models/References/UnitsModel';
import { IEnterpriseModel } from '../../../../models/Enterprises/EnterpriseModel';
import { IPositionsItemModel, PositionsFilterType } from '../../../../models/References/Positions';
import { UiSystemForm } from '../../../../components/common/UI/Form';
import { convertModelToOptions, SelectOptionType } from '../../../../utils/convertModelToOptions';
import { UiSystemInput } from '../../../../components/common/UI/Input';
import { UiSelect } from '../../../../components/common/UI/Select';
import { defaultDocsExtensions } from '../../../../config/system';
import { UiFileUploaderSystem } from '../../../../components/common/FileUploader';
import { CreateJobDescriptionType } from '../../../../models/JobDescription/interfaces';
import { UploadFiles } from '../../../../models/UploadFiles/UploadFiles';
import { handleErrors } from '../../../../utils/errors';
import { createJobDescription, updateJobDescription } from '../../../api/references/jobDescriptions';

type FormValues = {
  name: string;
  enterprise: SelectOptionType | null;
  unit: SelectOptionType | null;
  position: SelectOptionType | null;
  file: File | null;
}

const toSelectOptionType = ({ name, id }: any) => ({
  value: id,
  label: name,
});

const defaultValues = (jobDescription: IJobDescriptionsItemModel | null): FormValues => ({
  name: jobDescription ? jobDescription.name : '',
  enterprise: jobDescription ? toSelectOptionType(jobDescription.position.unit.enterprise) : null,
  unit: jobDescription ? toSelectOptionType(jobDescription.position.unit) : null,
  position: jobDescription ? toSelectOptionType(jobDescription.position) : null,
  file: null,
});

type Props = {
  jobDescription: IJobDescriptionsItemModel | null;
  units: ISystemUnitModel[];
  enterprises: IEnterpriseModel[];
  positions: IPositionsItemModel[];
  canUpdate: boolean;
  canCreate: boolean;
  getPositions: (filter?: PositionsFilterType) => void;
  getUnits: (filter?: UnitsFilterType) => void;
}

export const JobDescriptionsFormComponent = ({
  jobDescription,
  units,
  enterprises,
  positions,
  canUpdate,
  canCreate,
  getPositions,
  getUnits,
}: Props) => {
  const { t } = useTranslation();
  const createMode = !jobDescription;

  const {
    register, handleSubmit, watch, errors, formState: { isSubmitting, dirtyFields }, setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: defaultValues(jobDescription),
  });

  useEffect(() => {
    /** регистрация полей, управляемых вручную */
    register({ name: 'file', type: 'custom' });
  }, [register]);

  const watchEnterprise = watch('enterprise');
  const watchUnit = watch('unit');
  const watchPosition = watch('position');
  const watchName = watch('name');

  useEffect(() => {
    if (watchEnterprise && watchUnit) {
      getPositions({
        enterprise_id: watchEnterprise.value,
        unit_id: watchUnit.value,
      });
    }
  }, [getPositions, watchEnterprise, watchUnit]);

  useEffect(() => {
    if (watchEnterprise) {
      getUnits({
        enterprise_id: watchEnterprise.value,
      });
    }
  }, [getUnits, watchEnterprise]);

  const disabledSubmit = !watchEnterprise || !watchUnit || !watchName || !watchPosition;

  const onSubmit = async (data: FormValues) => {
    if (!data.file) {
      toast.error('Файл инструкций обязателен');
      return;
    }

    if ((createMode && !canCreate) || (!createMode && !canUpdate)) {
      return;
    }
    const jobDescriptionToSave: CreateJobDescriptionType = {
      enterprise_id: data.enterprise?.value,
      position_id: data.position?.value,
      unit_id: data.unit?.value,
      name: data.name,
      file: '',
    };

    if (data.file.name !== jobDescription?.file.name) {
      const file = await new UploadFiles([data.file]).upload();

      handleErrors(
        file,
        'save',
        () => {
          if (!(file instanceof Error)) {
            // eslint-disable-next-line prefer-destructuring
            jobDescriptionToSave.file = file[0].file_name;
          }
        },
      );
    } else {
      jobDescriptionToSave.file = jobDescription?.file.name;
    }

    if (createMode) {
      handleErrors(
        await createJobDescription(jobDescriptionToSave),
        'save',
        () => {
          toast.success(i18next.t('jobDescriptions.created'));
          customHistory.push('/system/references/jobdescription');
        },
      );
    } else if (jobDescription) {
      handleErrors(
        await updateJobDescription(jobDescriptionToSave, jobDescription.id),
        'save',
        () => {
          toast.success(i18next.t('jobDescriptions.updated'));
          customHistory.push('/system/references/jobdescription');
        },
      );
    }
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={disabledSubmit}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
    >
      <h3>{createMode ? 'Создание' : 'Редактирование'} должностной инструкции</h3>

      <UiSystemInput
        errors={errors}
        register={register}
        name="name"
        label={t('jobDescriptions.name')}
        required
      />

      <UiSelect
        register={register}
        setValue={setValue}
        name="enterprise"
        errors={errors}
        options={convertModelToOptions(enterprises)}
        label={t('common.enterprise')}
        required
        defaultValue={watchEnterprise}
      />

      {!!watchEnterprise && (
        <UiSelect
          register={register}
          setValue={setValue}
          name="unit"
          errors={errors}
          options={convertModelToOptions(units)}
          label={t('common.unit')}
          required
          defaultValue={watchUnit}
        />
      )}

      {!!watchUnit && (
        <UiSelect
          register={register}
          setValue={setValue}
          name="position"
          errors={errors}
          options={convertModelToOptions(positions)}
          label={t('jobDescriptions.position')}
          required
          defaultValue={watchPosition}
        />
      )}

      <FormGroup>
        <strong>
          Инструкция*
        </strong>
        <UiFileUploaderSystem
          initFileTypes={jobDescription ? [jobDescription.file] : []}
          maxFilesCount={1}
          onChange={(files: File[]) => setValue('file', files[0])}
          accept={defaultDocsExtensions}
          maxSizeFile={5}
        />
      </FormGroup>
    </UiSystemForm>
  );
};
