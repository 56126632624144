import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UiSelect } from '../../../../components/common/UI/Select';
import { convertModelToOptions, SelectOptionType } from '../../../../utils/convertModelToOptions';
import { getCurrentUserEnterprisesSelector } from '../../../../store/currentUser/selectors';


type Props = {
  getData: (enterprise_ids?: number[]) => void;
  message: string;

  totalCount?: number;
}

type FormValues = {
  enterprise_ids: SelectOptionType<number>[];
}

export const SystemReportHeader = ({
  getData, totalCount, message,
}: Props) => {
  const { t } = useTranslation();

  const { register, setValue } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise_ids: [],
    },
  });

  /** использовать доступные пользователю предпрятия */
  const userEnterprises = useSelector(getCurrentUserEnterprisesSelector);

  const options = convertModelToOptions(userEnterprises);

  return (
    <>
      <h3>{message}</h3>

      <UiSelect
        register={register}
        name="enterprise_ids"
        placeholder={t('ui.select.select_placeholder.enterprise')}
        options={options}
        setValue={setValue}
        multiple
        allowEmptyValue
        onChange={(optionsValue: SelectOptionType<number>[]) => {
          getData(optionsValue.length ? optionsValue.map((ent) => ent.value) : undefined);
        }}
      />

      {totalCount !== undefined && <h4 className="mb-4">Общее количество: <strong>{totalCount}</strong></h4>}
    </>
  );
};
