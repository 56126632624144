import { AnyAction } from 'redux';

import { SYSTEM_GET_MEDICAL_FAIL, SYSTEM_GET_MEDICAL_OK, SYSTEM_GET_MEDICAL_START } from './constants';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import { ISystemMedicalModel, SystemMedicalModel } from '../../../models/Medical/SystemMedicalModel';

export type systemMedicalReducerType = {
  medicalList: ISystemMedicalModel;
  medicalListIsLoading: boolean;
}

const initialState: systemMedicalReducerType = {
  medicalList: new SystemMedicalModel(),
  medicalListIsLoading: true,
};

export const storeName = 'medical';

export const systemMedicalReducer = {
  [storeName]: (state: systemMedicalReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_MEDICAL_START:
        return onLoading(state, 'medicalList');
      case SYSTEM_GET_MEDICAL_OK:
        return onLoad(state, payload, 'medicalList');
      case SYSTEM_GET_MEDICAL_FAIL:
        return onFail(state, 'medicalList');

      default:
        return state;
    }
  },
};
