import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UiSystemInput } from '../../../components/common/UI/Input';
import { UiToolbar } from '../../../components/common/Toolbar';
import { UiSelect } from '../../../components/common/UI/Select';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { systemEnterprisesSelector } from '../../store/selectors';
import { hasUnemptyValues } from '../../../utils/objects';
import { convertModelToOptions, SelectOptionType } from '../../../utils/convertModelToOptions';
import { IReferralProgramFilter } from '../../../models/ReferralProgram/interfaces';


type Props = {
  onSubmit: (data: IReferralProgramFilter) => void;
  onReset: () => void;
}

type FormValues = {
  enterprise_id: SelectOptionType<number> | null;
  code: string;
}

export const SystemReferralFilter = ({ onSubmit, onReset }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, setValue, errors, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      enterprise_id: null,
      code: '',
    },
  });

  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const onLocalSubmit = (data: FormValues) => {
    const preparedFilterData = {
      enterprise_id: data.enterprise_id?.value,
      code: data.code.length ? data.code : undefined,
    };

    if (hasUnemptyValues(preparedFilterData)) {
      onSubmit(preparedFilterData);
    }
  };

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const enterprisesOptions = convertModelToOptions(enterprises);

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row className="align-items-end">
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="enterprise_id"
            errors={errors}
            options={enterprisesOptions}
            value={watch('enterprise_id')}
            label={t('common.enterprise')}
            allowEmptyValue
            isClearable
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('common.referralCode')}
            name="code"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
