import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { getSystemBonusProgramText, saveSystemBonusProgramText } from 'systemModule/api/references/bonusProgram';
import { handleErrors } from 'utils/errors';
import { usePromise } from 'utils/hooks/usePromise';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { IPageBonusProgramModel } from 'models/References/PageBonusProgramModel';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import {
  SystemPageBonusProgramComponent,
} from 'systemModule/components/References/BonusProgram/SystemPageBonusProgram';

export const SystemPageBonusProgram = () => {
  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wPages);

  const [model, modelIsLoading] = usePromise<IPageBonusProgramModel>(getSystemBonusProgramText, true);

  const onSave = async (model: IPageBonusProgramModel) => {
    handleErrors(
      await saveSystemBonusProgramText(model),
      'save',
      () => toast.success(t('system.references.page.bonusProgram.saved')),
    );
  };

  if (modelIsLoading) {
    return <Loader />;
  }
  if (model) {
    return <SystemPageBonusProgramComponent canUpdate={canUpdate} model={model} onSave={onSave} />;
  }
  return <EntityNotFound message={t('system.references.page.bonusProgram.404')} />;
};
