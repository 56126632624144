import React, { CSSProperties, memo, useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select, { InputActionMeta, ValueType } from 'react-select';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { ValidationOptions } from 'react-hook-form';
import i18next from 'i18next';

import { SelectOptionType } from 'utils/convertModelToOptions';


type Props = {
  register: (s: any, v: ValidationOptions) => void;
  name: string;
  options: SelectOptionType[] | { label: string; options: SelectOptionType[] }[];
  setValue: (name: string, value: SelectOptionType | [], shouldValidate?: boolean) => void;

  defaultValue?: SelectOptionType | SelectOptionType[] | null;
  value?: SelectOptionType | SelectOptionType[] | null;
  errors?: {[s: string]: any}; // объект с ошибками
  label?: string;
  loadOptions?: (value: string) => void;
  required?: boolean;
  className?: string;
  multiple?: boolean;
  allowEmptyValue?: boolean; // разрешать ли пустые значения []
  onChange?: (v: SelectOptionType[]) => void; // текущие выбранные значения или []
  placeholder?: string;
  isLoading?: boolean; // загружаются ли options
  isClearable?: boolean; // можно ли очистить выбранное значение
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  isDisabled?: boolean;
}


const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};
const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);


export const UiSelectComponent = ({
  register, defaultValue, name, errors, options, label, setValue, onChange, placeholder,
  className = '',
  required = false,
  multiple = false,
  loadOptions, value,
  allowEmptyValue = false,
  isLoading = false,
  isClearable = false,
  noOptionsMessage,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();

  const error = get(errors, name, false);

  /** определение/сбор класса */
  const formgroupClassName = [];
  if (className) {
    formgroupClassName.push(className);
  }
  if (error !== false) {
    formgroupClassName.push('has-danger');
  }
  if (required) {
    formgroupClassName.push('required');
  }

  const onInputChange = (newValue: string, { action }: InputActionMeta) => {
    if (loadOptions && action === 'input-change') {
      loadOptions(newValue);
    }
  };

  /** валидация из простых параметров */
  const localValidation: ValidationOptions = {};
  if (required) {
    localValidation.required = true;
  }
  if (!allowEmptyValue) {
    localValidation.validate = (
      v: SelectOptionType | SelectOptionType[],
    ) => {
      if (Array.isArray(v)) {
        return v.length > 0 ? true : 'Обязательное поле';
      }

      return v && v.value ? true : 'Обязательное поле';
    };
  }

  useEffect(() => {
    register({ name }, localValidation);
    // localValidation динамична, поэтому будет всегда новым объектом
    // eslint-disable-next-line
  }, [register, name]);

  return (
    <FormGroup className={formgroupClassName.join(' ')}>
      {label && (
        <Label htmlFor={name}>
          {label}
        </Label>
      )}
      <Select
        className="form-select-control"
        classNamePrefix="react-select"
        id={name}
        isMulti={multiple}
        defaultValue={defaultValue}
        value={value}
        onChange={(optionValue: ValueType<SelectOptionType, any>) => {
          setValue(name, optionValue ? optionValue as SelectOptionType : [], true);

          if (typeof onChange === 'function') {
            onChange(optionValue ? optionValue as SelectOptionType[] : []);
          }
        }}
        placeholder={placeholder || t('ui.select.select_placeholder')}
        options={options}
        /** noOptionsMessage должен быть функцией */
        noOptionsMessage={noOptionsMessage || (() => t('common.select.no_options'))}
        onInputChange={onInputChange}
        formatGroupLabel={formatGroupLabel}
        isLoading={isLoading}
        loadingMessage={() => t('common.select.loadingMessage')}
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
      {error && <div className="form-control-feedback form-error-block">{error.message}</div>}
    </FormGroup>
  );
};

export const UiSelect = memo(UiSelectComponent);

/** пустое значение для селекта */
export const emptyOptionValue = -100;
export const emptyOption = (label?: string): {label: string; value: number} => ({
  label: label || i18next.t('ui.select.select_placeholder'),
  value: emptyOptionValue,
});
