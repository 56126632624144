import React from 'react';
import { useHistory } from 'react-router-dom';

import { PartnerItemModel } from 'models/Partners/PartnerItemModel';
import { UiBox } from 'components/common/UI/UiBox';
import { PartnerFavouriteButton } from 'components/Partners/PartnerFavouriteButton';
import { UiSimpleMultiSlider } from 'components/common/UI/SimpleMultiSlider';
import { UiImgViewer } from 'components/common/UI/ImgViewer';
import { ReactComponent as ExternalIcon } from 'assets/img/external.svg';
import { UiOuterLink } from 'components/common/UI/OuterLink';
import { PartnersItemInfoComponent } from 'components/Partners/common';


type Props = {
  model: PartnerItemModel;
}

export const PartnersItemComponent = ({ model }: Props) => {
  const history = useHistory();

  const toBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className="page-header">
        <span role="presentation" className="back cursor-pointer" onClick={toBack} />
        {model.company_name}
      </div>

      <UiBox className="partners">
        <section className="form">
          <div className="articles">
            <div className="articles-list_item open">
              <div className="articles-list_item__wrapper">
                <div className="articles-list_item__box">

                  <div className="discounts-list wide my-3">
                    <PartnersItemInfoComponent
                      single
                      logo={model.logo}
                      title={model.company_name}
                      description={model.title}
                    />
                  </div>

                  <div className="articles-list_item__text">
                    <div className="articles-list_item__date">{model.company_name}</div>
                    <div className="articles-list_item__title">{model.title}</div>
                    <div className="pb-1 mb-3">
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <div className="articles-list_item__tag-list list-element_content p-0 h-auto">
                          <div className="articles-list_item__tag-list___wrapper p-0">
                            {model.categories.map(({ id, name }) => (
                              <span key={id} className="articles-list_item__tag m-0 mr-2 cursor-pointer">{name}</span>
                            ))}
                          </div>
                        </div>
                        <PartnerFavouriteButton id={model.id} is_favorite={model.is_favorite} />
                      </div>
                    </div>
                  </div>

                  {/* eslint-disable-next-line react/no-danger*/}
                  {model.description && <div dangerouslySetInnerHTML={{ __html: model.description }} />}

                  <div className="mt-4">
                    <UiSimpleMultiSlider data={model.images} />

                    {model.ref_video && model.ref_video.length > 0 && (
                      <div className={`videoWrapper${model.images.length === 1 ? ' mt-4' : ''}`}>
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe
                          src={`https://www.youtube.com/embed/${model.ref_video.split('/').pop()}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    )}
                  </div>

                  {(model.barcode || model.qr_code) && (
                    <div className="mt-4">
                      {model.barcode && (
                        <>
                          <div className="articles-list_item__date">Штрих-код</div>
                          <div className="text-center py-3 mb-4">
                            <UiImgViewer className="img-responsive" src={model.barcode.fullUrl} alt="Штрих-код" />
                          </div>
                        </>
                      )}
                      {model.qr_code && (
                        <>
                          <div className="articles-list_item__date">QR-код</div>
                          <div className="text-center py-3 mb-4">
                            <UiImgViewer className="img-responsive" src={model.qr_code.fullUrl} alt="QR-код" />
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {model.ref_partner && (
                    <div className="mb-4">
                      <div className="list-element">
                        <div className="list-element_content">
                          <div className="line info">
                            <div className="item"><small>Ссылка на сайт партнёра</small></div>
                          </div>
                          <div className="line title">
                            <UiOuterLink className="text-primary text-clip" href={model.ref_partner}>
                              {model.ref_partner}
                            </UiOuterLink>
                          </div>
                        </div>
                        <UiOuterLink className="list-element_icon" href={model.ref_partner}>
                          <ExternalIcon />
                        </UiOuterLink>
                      </div>
                    </div>
                  )}

                  {model.ref_apply && (
                    <div className="mb-4">
                      <UiOuterLink className="btn btn-primary btn-block" href={model.ref_apply}>
                        Подать заявку
                      </UiOuterLink>
                    </div>
                  )}

                  {model.contacts.length > 0 && (
                    <>
                      <div className="mb-3">
                        <div className="articles-list_item__date">Контакты</div>
                      </div>
                      {model.contacts.map(({ value, title }) => (
                        <div className="mb-3" key={`${value}${title}`}>
                          <p className="mb-0"><b>{title}</b></p>
                          <div className="articles-list_item__date">{value}</div>
                        </div>
                      ))}
                    </>
                  )}

                  {model.contacts_image && (
                    <div className="mt-4">
                      <div className="article-slider slick-slider">
                        <img src={model.contacts_image.fullUrl} alt="" />
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </UiBox>
    </>
  );
};
