import React from 'react';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { UiInput } from '../../common/UI/Input';
import { AuthSignUpSmsCodeType } from '../../../models/Auth/interfaces';

type Props = {
  submit: (data: AuthSignUpSmsCodeType) => void;
  isSubmitting: boolean;
}

export const ConfirmCodeForm = ({ submit, isSubmitting }: Props) => {
  const {
    handleSubmit, errors, register, formState, getValues,
  } = useForm({
    mode: 'onChange',
  });
  const onSubmit = async (data: any) => {
    submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UiInput
        name="code"
        register={register}
        label="Введите код из СМС"
        errors={errors}
        value={getValues().code}
        required
      />
      <Button
        color="primary"
        type="submit"
        className="btn-block"
        disabled={!formState.isValid || isSubmitting}
      >
        Далее
      </Button>
    </form>
  );
};
