import { FileType, prepareFiles } from 'models/common';
import {
  IEducationListItemModel, IEducationListModel,
} from 'models/Education/interfaces';
import { WithPagesModel } from 'models/WithPagesModel';
import { CDate } from 'utils/CDate';


export class EducationListItemModel implements IEducationListItemModel {
  readonly date_start: Date;
  readonly id: number;
  readonly image: FileType;
  readonly name: string;
  readonly place: string;

  constructor(params: any = {}) {
    this.date_start = CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm');
    this.id = params.id;
    this.image = prepareFiles(params.image);
    this.name = params.name || '';
    this.place = params.place || '';
  }
}

export class EducationListModel extends WithPagesModel implements IEducationListModel {
  data: IEducationListItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, EducationListItemModel);
  }
}
