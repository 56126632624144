import { combineReducers } from 'redux';
import { newsReducer, newsReducerType } from '../containers/News/reducer';
import { enterprisesReducer, enterprisesReducerType } from '../containers/Enterprises/reducer';
import { currentUserReducer, currentUserReducerType } from './currentUser/reducer';
import { SystemReducer, SystemReducerType } from '../systemModule/store/reducers';
import { globalReducer, globalReducerType } from './globalReducer';
import { busStopsReducer, BusStopsReducerType } from '../containers/BusStops/reducer';
import { busStopTimetableReducer, BusStopTimetableReducerType } from '../containers/BusStopTimetable/reducer';
import { referencesReducer, referencesReducerType } from './references/reducer';
import { signInReducer, signInReducerType } from '../containers/Auth/reducer';
import { eventsReducer, eventsReducerType } from '../containers/Events/reducer';
import { notificationsReducer, notificationsReducerType } from '../containers/Notifications/reducer';
import { pollsReducer, pollsReducerType } from '../containers/Polls/reducer';
import { bonusProgramReducer, BonusProgramReducerType } from '../containers/BonusProgram/reducer';


export interface AppReducer {
  signIn: signInReducerType;
  news: newsReducerType;
  enterprises: enterprisesReducerType;
  currentUser: currentUserReducerType;
  global: globalReducerType;
  busStops: BusStopsReducerType;
  busStopTimetable: BusStopTimetableReducerType;
  references: referencesReducerType;
  events: eventsReducerType;
  notifications: notificationsReducerType;
  polls: pollsReducerType;
  bonusProgram: BonusProgramReducerType;
}

export interface RootReducer extends AppReducer {
  system: SystemReducerType;
}

const appReducer: { [K in keyof AppReducer]: any } = {
  ...signInReducer,
  ...newsReducer,
  ...enterprisesReducer,
  ...currentUserReducer,
  ...globalReducer,
  ...busStopsReducer,
  ...busStopTimetableReducer,
  ...referencesReducer,
  ...eventsReducer,
  ...notificationsReducer,
  ...pollsReducer,
  ...bonusProgramReducer,
};

export const reducers = (state: any, action: any) => combineReducers(
  { ...appReducer, system: combineReducers(SystemReducer) },
)(state, action);
