import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import {
  SYSTEM_REFERRAL_GET_LIST_START,
  SYSTEM_REFERRAL_GET_LIST_OK,
  SYSTEM_REFERRAL_GET_LIST_FAIL,
} from './constants';
import { ISystemReferralProgramListModel } from '../../../models/ReferralProgram/interfaces';
import { SystemReferralProgramListModel } from '../../../models/ReferralProgram/SystemReferralProgramListModel';

export type systemReferralProgramReducerType = {
  referralList: ISystemReferralProgramListModel;
  referralListIsLoading: boolean;
}

const initialState: systemReferralProgramReducerType = {
  referralList: new SystemReferralProgramListModel(),
  referralListIsLoading: true,
};

export const storeName = 'referral';

export const systemReferralProgramReducer = {
  [storeName]: (state: systemReferralProgramReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_REFERRAL_GET_LIST_START:
        return onLoading(state, 'referralList');
      case SYSTEM_REFERRAL_GET_LIST_OK:
        return onLoad(state, payload, 'referralList');
      case SYSTEM_REFERRAL_GET_LIST_FAIL:
        return onFail(state, 'referralList');

      default:
        return state;
    }
  },
};
