import get from 'lodash/get';
import {
  ICurrentUserChildren, ICurrentUserQualification, ICurrentUserWork, ICurrentUserEducation,
} from './interfaces';

export interface ICurrentUserSdsInfoModel {
  sex: boolean;
  birthday: string;
  fio: string;
  passport: {
    series: string;
    number: string;
  };
  military_number: string;
  driver_number: string;
  inn: string;
  snils: string;
  married: boolean;
  childrens: ICurrentUserChildren[];
  contacts: {
    phones: string[];
    emails: string[];
  };
  qualifications: ICurrentUserQualification[];
  work: ICurrentUserWork[];
  educations: ICurrentUserEducation[];

  name: string;
  surname: string;
  patronymic: string;
}


export class CurrentUserSdsInfoModel implements ICurrentUserSdsInfoModel {
  sex: boolean;
  birthday: string;
  fio: string;
  passport: {
    series: string;
    number: string;
  };
  military_number: string;
  driver_number: string;
  inn: string;
  snils: string;
  married: boolean;
  childrens: ICurrentUserChildren[];
  contacts: {
    phones: string[];
    emails: string[];
  };
  qualifications: ICurrentUserQualification[];
  work: ICurrentUserWork[];
  educations: ICurrentUserEducation[];

  readonly name: string;
  readonly surname: string;
  readonly patronymic: string;

  constructor(params: any = {}) {
    this.sex = params.sex;
    this.birthday = params.birthday ? new Date(params.birthday).toISOString() : params.birthday;
    this.fio = params.fio || '';
    this.passport = params.passport;
    this.military_number = params.military_number;
    this.driver_number = params.driver_number;
    this.inn = params.inn;
    this.snils = params.snils;
    this.married = params.married;
    this.childrens = (params.childrens || []).map((child: ICurrentUserChildren) => ({
      ...child,
      birthday: new Date(child.birthday).toISOString(),
    }));
    this.contacts = params.contacts;
    /** если нет данных, надо обеспечить пустые значения */
    this.qualifications = params.qualifications?.length ? params.qualifications : [{
      name: '',
      duration: '',
      year: '',
    }];
    this.work = params.work;
    this.educations = params.educations ? params.educations.map((education: unknown) => ({
      type: {
        code: get(education, 'type.code', ''),
        name: get(education, 'type.name', ''),
      },
      institute: get(education, 'institute', ''),
      year: get(education, 'year', ''),
    })) : {
      type: {
        code: '',
        name: '',
      },
      institute: '',
      year: '',
    };

    /** все после 2 пробела - отчество */
    const [surname, name, ...patronymic] = this.fio.split(' ');
    this.name = name || '';
    this.surname = surname || '';
    this.patronymic = patronymic.join(' ') || '';
  }
}
