import { enUS, ru } from 'date-fns/locale';
import i18next from 'i18next';

/** поддерживаемые локали в приложении */
export const appLocales: Record<string, Locale> = {
  en: enUS,
  ru,
};

/**
 * текущая локаль в зависимости от языка
 */
export const getCurrentLocale = (): Locale => appLocales[i18next.language];
