import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';


const getEducationList = (state: RootReducer) => state.system[storeName].educationList;
const getEducationListIsLoading = (state: RootReducer) => state.system[storeName].educationListIsLoading;


export const systemEducationListSelector = createSelector([getEducationList], returnParams);
export const systemEducationListIsLoadingSelector = createSelector([getEducationListIsLoading], returnParams);
