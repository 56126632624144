import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { useDataPage } from 'utils/hooks/useDataPage';
import { SystemUsersFilter } from 'systemModule/components/Users/Filter';
import { CustomTable } from 'components/common/Table';
import { ISystemUserListModel, SystemUsersFilterType } from 'models/Users/interfaces';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';

type Props = {
  data: ISystemUserListModel;
  isLoading: boolean;
  getData: (filter: SystemUsersFilterType) => void;
}

export const SystemUsersComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const isCanChangeVisibilityUsersPhone = currentUser.hasPermission(UserAccessEnum.wUserPhoneDisplay);
  const isCanChangeShowCompanyNotification = currentUser.hasPermission(UserAccessEnum.r_notify_user_enterprises);

  return (
    <>
      <h3>Список пользователей</h3>

      <SystemUsersFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: 'ID' },
          { name: 'ФИО' },
          { name: 'Активный' },
          { name: 'Регистрация завершена' },
          { name: 'Телефон' },
          { name: 'Подразделение' },
          { name: 'Предприятие' },
          { name: 'Роли' },
          { name: 'Действия' },
        ]}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              {item.id}
            </td>
            <td>
              {item.fio}
            </td>
            <td>
              {item.isActive ? 'Да' : 'Нет'}
            </td>
            <td>
              {item.isRegister ? 'Да' : 'Нет'}
            </td>
            <td>
              {item.phone} <br />
              {item.show_phone ? 'Виден' : 'Не виден'}
            </td>
            <td>
              {item.units.map((unit) => get(unit, 'unit.name')).filter((unit) => unit).join(', ')}
            </td>
            <td>
              {item.units.map((unit) => get(unit, 'unit.enterprise.name')).filter((ent) => ent).join(', ')}
            </td>
            <td>
              {item.roles.map((role) => role.description).join(', ')}
            </td>
            <td className="table-action-column">
              <Button
                className="w-100 btn-table"
                color="primary"
                tag={Link}
                to={`user-role/${item.id}`}
              >
                Задать роль
              </Button>
              {
                isCanChangeVisibilityUsersPhone && (
                  <Button
                    className="w-100 mt-4 btn-table"
                    color="primary"
                    tag={Link}
                    to={`user-settings/${item.id}`}
                  >
                    Настройки
                  </Button>
                )
              }
              {
                isCanChangeShowCompanyNotification && item.canCNotifyCreate && (
                  <Button
                    className="w-100 mt-4 btn-table"
                    color="primary"
                    tag={Link}
                    to={`user-enterprises-settings/${item.id}`}
                  >
                    Предприятия&nbsp;для&nbsp;оповещения
                  </Button>
                )
              }
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
