import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import {
  SYSTEM_GET_POLLS_FAIL,
  SYSTEM_GET_POLLS_OK,
  SYSTEM_GET_POLLS_START,
} from './constants';
import { ISystemPollsModel, SystemPollsModel } from '../../../models/Polls/SystemPollsModel';


export type systemPollsReducerType = {
  polls: ISystemPollsModel;
  pollsIsLoading: boolean;
}

const initialState: systemPollsReducerType = {
  polls: new SystemPollsModel(),
  pollsIsLoading: true,
};

export const storeName = 'polls';

export const systemPollsReducer = {
  [storeName]: (state: systemPollsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_POLLS_START:
        return onLoading(state, 'polls');
      case SYSTEM_GET_POLLS_OK:
        return onLoad(state, payload, 'polls');
      case SYSTEM_GET_POLLS_FAIL:
        return onFail(state, 'polls');

      default:
        return state;
    }
  },
};
