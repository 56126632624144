import { createSelector } from 'reselect';
import { RootReducer } from '../../../../store/reducers';
import { storeName } from './reducers';
import { returnParams } from '../../../../store/selectors';

const getEnterprises = (state: RootReducer) => state.system[storeName].enterprises;
const getEnterprisesIsLoading = (state: RootReducer) => state.system[storeName].enterprisesIsLoading;
const getEnterpriseById = (id: number) => (state: RootReducer) => state.system[storeName].enterprises.data
  .find((enterprise) => enterprise.id === id);

export const systemEnterprisesSelector = createSelector([getEnterprises], returnParams);
export const systemEnterprisesIsLoadingSelector = createSelector([getEnterprisesIsLoading], returnParams);
export const systemEnterpriseByIdSelector = createSelector([getEnterpriseById], returnParams);
