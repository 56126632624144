import { AnyAction } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { baseSaga } from '../../store/baseSaga';
import { httpGet } from '../../utils/http';
import { getBusStopTimetableListFailAction, getBusStopTimetableListOkAction } from './actions';
import { BusStopTimetableItemModel } from '../../models/BusStopTimetable/BusStopTimetable';
import { GET_BUS_STOP_TIMETABLE_LIST_START } from './constants';
import { maxValueToGetAllDataWithPagination } from '../../config/system';

function* getBusStopTimetableListSaga({ payload: { id } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet(`/public/transport/bus-stop/${id}`, {
      'per-page': maxValueToGetAllDataWithPagination,
    });

    yield put(getBusStopTimetableListOkAction(new BusStopTimetableItemModel(response)));
  }, getBusStopTimetableListFailAction);
}

export function* busStopTimetableSaga() {
  yield takeLatest(GET_BUS_STOP_TIMETABLE_LIST_START, getBusStopTimetableListSaga);
}
