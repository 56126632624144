import { baseApiFn } from 'utils/baseApiFn';
import {
  httpDelete, httpGet, httpPost, httpPut,
} from 'utils/http';
import { IInsuranceModel, IInsuranceModelForm, InsuranceModel } from 'models/Insurances/InsurancesModel';


/** создание страховой программы */
export const createSystemRefInsurance = async (
  model: IInsuranceModelForm,
) => baseApiFn<true>(httpPost('system/references/insurance', model));

/** получить страховую программу */
export const getSystemRefInsurance = async (id: number) => baseApiFn<IInsuranceModel>(async () => {
  const response = await httpGet(`system/references/insurance/${id}`);
  return new InsuranceModel(response);
});

/** редактирование страховой программы */
export const updateSystemRefInsurance = async (
  id: number, model: any,
) => baseApiFn<true>(httpPut(`system/references/insurance/${id}`, model));

/** удалить страховую программу */
export const deleteSystemRefInsurance = async (
  id: number,
) => baseApiFn<true>(httpDelete(`system/references/insurance/${id}`));
