import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UiCheckbox } from '../common/UI/Checkbox';
import { prepareFilterEntityByKey } from '../../utils/prepareFilterEntityByKey';
import { IApplicationTypesModel } from '../../models/Application/Type/interfaces';
import { IApplicationStatusesModel } from '../../models/Application/Status/interfaces';
import { LocalApplicationFilterType } from '../../models/Application/interfaces';


const statusPrefix = 'status_';
const typePrefix = 'type_';

const getDefault = (data: LocalApplicationFilterType): FormValues => ({
  statuses: data.status_id.reduce((acc: {[s: string]: boolean}, statusId) => {
    acc[`${statusPrefix}${statusId}`] = true;
    return acc;
  }, {}),
  types: data.type_id.reduce((acc: {[s: string]: boolean}, typeId) => {
    acc[`${typePrefix}${typeId}`] = true;
    return acc;
  }, {}),
});

type Props = {
  toggle: () => void;
  setFilterData: (filter: any) => void;
  initialValues: () => LocalApplicationFilterType;
  statuses: IApplicationStatusesModel;
  types: IApplicationTypesModel;
  filterData: LocalApplicationFilterType;
}

type FormValues = {
  statuses: {[s: string]: boolean};
  types: {[s: string]: boolean};
}

export const ApplicationsFilterComponent = memo(({
  toggle,
  setFilterData,
  initialValues,
  statuses,
  types,
  filterData,
}: Props) => {
  const {
    register, handleSubmit, watch, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: getDefault(filterData),
  });

  const { t } = useTranslation();

  /** подписаться на обновления формы */
  const watchStatusesFields = watch('statuses');
  const watchTypesFields = watch('types');

  /** все текущие актуальные данные фильтра брать отсюда */
  const getCurrentFilterData = (): LocalApplicationFilterType => ({
    status_id: prepareFilterEntityByKey(watchStatusesFields, statusPrefix),
    type_id: prepareFilterEntityByKey(watchTypesFields, typePrefix),
  });

  const onSubmit = () => {
    const filter = getCurrentFilterData();
    setFilterData((prevState: LocalApplicationFilterType) => ({ ...prevState, ...filter }));
    toggle();
  };

  return (
    <form autoComplete="off" className="modal-content" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-with-btn">
        <div className="form-wrapper_content">
          <h3>Статус заявки</h3>
          <div className="section-block">
            {statuses.map((item) => (
              <UiCheckbox
                key={item.id}
                label={item.name}
                register={register}
                name={`statuses.status_${item.id}`}
              />
            ))}
          </div>
          <h3>Тип заявки</h3>
          <div className="section-block">
            {types.map((item) => (
              <UiCheckbox
                key={item.id}
                label={item.name}
                register={register}
                name={`types.type_${item.id}`}
              />
            ))}
          </div>
        </div>
        <div className="form-wrapper_btn">
          <div className="buttons-group buttons-group-responsive">
            <Button color="primary" type="submit">
              {t('common.filter.apply')}
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => reset(getDefault(initialValues()))}
              className="btn--clear-filter"
            >
              {t('common.form.clear_filter')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
});
