import React from 'react';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UiInput } from '../../common/UI/Input';
import { AuthSignUpPasswordType } from '../../../models/Auth/interfaces';
import { PASSWORD_PATTERN } from '../../../config/system';

type Props = {
  submit: (data: AuthSignUpPasswordType) => void;
  isSubmitting: boolean;
}

export const PasswordFormComponent = ({ submit, isSubmitting }: Props) => {
  const {
    handleSubmit, errors, register, formState, getValues,
  } = useForm({
    mode: 'onChange',
  });

  const { t } = useTranslation();
  const emptyPasswordMsg = t('auth.signup.empty_password');
  const passwordsNotEqualMsg = t('auth.signup.passwords_not_equal');

  const onSubmit = async (data: any) => submit(data);
  const disableSubmit = !formState.isValid || isSubmitting;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UiInput
        name="password"
        register={register}
        label="Введите постоянный пароль"
        value={getValues().password}
        errors={errors}
        type="password"
        validation={{
          pattern: {
            value: PASSWORD_PATTERN,
            message: t('auth.password.restriction'),
          },
          validate: (value: string) => value.trim().length > 0 || emptyPasswordMsg,
        }}
        minLength={8}
        required
      />
      <UiInput
        name="confirmPassword"
        register={register}
        label="Повторите пароль"
        value={getValues().confirmPassword}
        errors={errors}
        type="password"
        validation={{
          pattern: {
            value: PASSWORD_PATTERN,
            message: t('auth.password.restriction'),
          },
          validate: (value: string) => value === getValues().password || passwordsNotEqualMsg,
        }}
        minLength={8}
        required
      />
      <Button
        color="primary"
        type="submit"
        className="btn-block"
        disabled={disableSubmit}
      >
        Далее
      </Button>
    </form>
  );
};
