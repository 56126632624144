import { FileType, prepareFiles } from 'models/common';
import { IWithPages, WithPagesModel } from 'models/WithPagesModel';


export interface ISystemPartnersCategoryListItemModel {
  readonly id: number,
  readonly name: string,
  readonly priority: number,
  readonly logo: FileType;

  readonly isNew: boolean;
}


export interface ISystemPartnersCategoryListModel extends IWithPages {
  data: ISystemPartnersCategoryListItemModel[];
}


export class SystemPartnersCategoryListItemModel implements ISystemPartnersCategoryListItemModel {
  readonly id: number;
  readonly logo: FileType;
  readonly name: string;
  readonly priority: number;

  constructor(params: any = {}) {
    this.id = params.id || 0;
    this.logo = prepareFiles(params.logo);
    this.name = params.name;
    this.priority = params.priority;
  }

  get isNew() {
    return this.id === 0;
  }
}


export class SystemPartnersCategoryListModel extends WithPagesModel implements ISystemPartnersCategoryListModel {
  data: ISystemPartnersCategoryListItemModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, SystemPartnersCategoryListItemModel);
  }
}
