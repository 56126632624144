import { all } from 'redux-saga/effects';
import { systemConstructApplicationSagas } from 'systemModule/containers/ConstructApplication/sagas';
import { systemPartnersSagas } from 'systemModule/containers/Partners/sagas';
import { systemTransportSagas } from 'systemModule/containers/Transport/sagas';
import { systemNewsSagas } from '../containers/News/sagas';
import { systemGlobalSaga } from './systemGlobalSaga';
import { systemUsersSagas } from '../containers/Users/sagas';
import { systemReferencesSagas } from '../containers/References/sagas';
import { systemBusStopsSagas } from '../containers/References/BusStops/sagas';
import { systemEnterprisesSagas } from '../containers/References/Enterprises/sagas';
import { systemUnitsSagas } from '../containers/References/Units/sagas';
import { systemSportsSagas } from '../containers/References/Sports/sagas';
import { systemNewsCategoriesSagas } from '../containers/References/NewsCategories/sagas';
import { systemJobDescriptionsSagas } from '../containers/References/JobDescriprion/sagas';
import { systemEventsSagas } from '../containers/Events/sagas';
import { systemApplicationSagas } from '../containers/Application/sagas';
import { systemMedicalSagas } from '../containers/Medical/sagas';
import { systemEducationSagas } from '../containers/Education/sagas';
import { systemNotificationSagas } from '../containers/Notification/sagas';
import { systemPrivilegesSagas } from '../containers/Priveleges/sagas';
import { systemReferralProgramSagas } from '../containers/ReferralProgram/sagas';
import { systemBonusProgramSagas } from '../containers/BonusProgram/sagas';

export function* systemSagas() {
  yield all([
    systemNewsSagas(),
    systemGlobalSaga(),
    systemUsersSagas(),
    systemReferencesSagas(),
    systemBusStopsSagas(),
    systemEnterprisesSagas(),
    systemUnitsSagas(),
    systemSportsSagas(),
    systemNewsCategoriesSagas(),
    systemJobDescriptionsSagas(),
    systemEventsSagas(),
    systemApplicationSagas(),
    systemMedicalSagas(),
    systemEducationSagas(),
    systemNotificationSagas(),
    systemPrivilegesSagas(),
    systemConstructApplicationSagas(),
    systemTransportSagas(),
    systemPartnersSagas(),
    systemReferralProgramSagas(),
    systemBonusProgramSagas(),
  ]);
}
