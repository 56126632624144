import { AnyAction } from 'redux';
import { IJobDescriptionsModel, JobDescriptionsModel } from '../../../../models/JobDescription/JobDescription';
import {
  SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_FAIL,
  SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_OK,
  SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_START,
} from './constants';
import { onFail, onLoad, onLoading } from '../../../../utils/reducer';

export type systemJobDescriptionsReduceType = {
  jobDescriptions: IJobDescriptionsModel;
  jobDescriptionsIsLoading: boolean;
}

const initialState: systemJobDescriptionsReduceType = {
  jobDescriptions: new JobDescriptionsModel(),
  jobDescriptionsIsLoading: true,
};

export const storeName = 'jobDescriptions';

export const systemJobDescriptionsReducer = {
  [storeName]: (state: systemJobDescriptionsReduceType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_START:
        return onLoading(state, storeName);
      case SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_OK:
        return onLoad(state, payload, storeName);
      case SYSTEM_REFERENCE_GET_JOB_DESCRIPTIONS_FAIL:
        return onFail(state, storeName);

      default:
        return state;
    }
  },
};
