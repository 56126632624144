import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button, Card, Col, FormGroup, Input, Label, Row,
} from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';

import { UiSystemForm } from '../../../components/common/UI/Form';
import { ICurrentUserModel, UserAccessEnum } from '../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../store/currentUser/selectors';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { ISystemPrivilegeItem, SystemPrivilegeFormType } from '../../../models/Privilege/interfaces';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { systemEnterprisesSelector } from '../../store/selectors';
import { convertModelToOptions } from '../../../utils/convertModelToOptions';


type Props = {
  model: ISystemPrivilegeItem;
  onSave: (formModel: SystemPrivilegeFormType) => void;
  loading: boolean;
}

type FormValues = {
  name: string;
  documents: string;
  requirement: string;
  contact: string;
  enterprises: {
    enterprise_id: number;
    documents?: string;
    requirement?: string;
    contact?: string;
  }[];
}

export const SystemPrivilegesFormComponent = ({ model, onSave, loading }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, formState: { isValid, isSubmitting, dirtyFields }, errors, control,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
      documents: model.documents,
      requirement: model.requirement,
      contact: model.contact,
      enterprises: model.enterprises.map((item) => ({ ...item, enterprise_id: item.id })),
    },
  });

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wPrivileges);

  const enterprises: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);
  const enterprisesOptions = convertModelToOptions(enterprises);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'enterprises',
  });

  const onSubmit = async (form: FormValues) => {
    if (canUpdate) {
      onSave(form);
    }
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting || loading}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={!model.id}
    >
      <h3>{model.id ? 'Редактирование' : 'Создание'} льготы</h3>

      <UiSystemInput
        errors={errors}
        register={register}
        name="name"
        label={t('system.privilege.item.name')}
        required
        maxLength={150}
      />
      <UiSystemInput
        type="textarea"
        errors={errors}
        register={register}
        name="documents"
        label={t('system.privilege.item.documents')}
        required
        maxLength={4000}
      />
      <UiSystemInput
        type="textarea"
        errors={errors}
        register={register}
        name="requirement"
        label={t('system.privilege.item.requirement')}
        required
        maxLength={3000}
      />
      <UiSystemInput
        type="textarea"
        errors={errors}
        register={register}
        name="contact"
        label={t('system.privilege.item.contact')}
        required
        maxLength={300}
      />

      <hr />
      {fields.map((item, itemIndex) => {
        /** минимальная реализация отсеивания использованных ид предприятий */
        /** найти уже используемые ид предприятий */
        const usedIds = fields
          .slice(0, itemIndex)
          .map((ent) => +ent.enterprise_id);

        /** собрать массив из неиспользованных ид предприятий */
        const localEnterpriseOptions = cloneDeep(enterprisesOptions)
          .filter((ent) => !usedIds.includes(ent.value));

        return (
          <Row key={item.id}>
            <Col>
              <Card body>
                <FormGroup>
                  <Label htmlFor={item.id}>
                    {t('applications.filter.enterprises')}
                  </Label>
                  <Input
                    id={item.id}
                    type="select"
                    defaultValue={item.type}
                    innerRef={register()}
                    name={`enterprises[${itemIndex}].enterprise_id`}
                  >
                    {localEnterpriseOptions.map((option) => (
                      <option key={`${option.label}_${option.value}`} value={option.value}>{option.label}</option>
                    ))}
                  </Input>
                </FormGroup>
                <UiSystemInput
                  type="textarea"
                  errors={errors}
                  register={register}
                  name={`enterprises[${itemIndex}].documents`}
                  label={t('system.privilege.item.documents')}
                  maxLength={4000}
                />
                <UiSystemInput
                  type="textarea"
                  errors={errors}
                  register={register}
                  name={`enterprises[${itemIndex}].requirement`}
                  label={t('system.privilege.item.requirement')}
                  maxLength={3000}
                />
                <UiSystemInput
                  type="textarea"
                  errors={errors}
                  register={register}
                  name={`enterprises[${itemIndex}].contact`}
                  label={t('system.privilege.item.contact')}
                  maxLength={300}
                />

                {(canUpdate && fields.length > 1) && (
                  <Button type="button" color="danger" onClick={() => remove(itemIndex)}>
                    Удалить предприятие
                  </Button>
                )}
              </Card>
            </Col>
          </Row>
        );
      })}

      {(canUpdate && fields.length < enterprisesOptions.length) && (
        <Button
          type="button"
          color="success"
          onClick={() => append({
            enterprise_id: '',
            documents: '',
            requirement: '',
            contact: '',
          })}
        >
          Добавить предприятие
        </Button>
      )}
    </UiSystemForm>
  );
};
