import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';

const getReferralList = (state: RootReducer) => state.system[storeName].referralList;
const getReferralListLoading = (state: RootReducer) => state.system[storeName].referralListIsLoading;

export const systemReferralListSelector = createSelector([getReferralList], returnParams);
export const systemReferralListLoadingSelector = createSelector([getReferralListLoading], returnParams);
