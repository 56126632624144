import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { InfiniteScrollbar } from '../common/InfiniteScrollbar';
import { IMedicalFilterType, IMedicalItemModel, IMedicalModel } from '../../models/Medical/MedicalModel';
import { Loader } from '../common/UI/Loaders';
import { CDate } from '../../utils/CDate';

type Props = {
  model: IMedicalModel;
  getData: (filter: IMedicalFilterType) => void;
  hasMoreData: boolean;
  isLoading: boolean;
}

export const MedicalComponent = ({
  model,
  getData,
  hasMoreData,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  const onLoadMoreData = useCallback(
    (page: number) => getData({ page, 'per-page': model.limit }),
    [getData, model.limit],
  );

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/" />
        {t('medical.menu')}
      </div>

      <div className="box medical">
        <InfiniteScrollbar
          hasMoreData={hasMoreData && !isLoading}
          onLoadData={onLoadMoreData}
          currentPage={model.page}
        >
          <section className="form">
            {model.data.map((item: IMedicalItemModel) => (
              <div className="list-element mb-3" key={item.id}>
                <div className="list-element_content">
                  {item.date_start && (
                    <div className="line title">
                      Медосмотр {CDate.format(item.date_start, 'dd-MM-yy HH:mm')}
                    </div>
                  )}
                  <div className="line info">
                    Место сбора {item.address ? item.address : 'уточните у своего руководителя'}
                  </div>
                  {item.comment && (
                    <div className="line info">
                      {item.comment}
                    </div>
                  )}
                </div>
              </div>
            ))}
            {(!isLoading && !model.data.length) && (
              <h3 className="text-center">Медосмотр не запланирован</h3>
            )}
            {isLoading && (
              <Loader className="mt-3" />
            )}
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};
