import get from 'lodash/get';
import { projectBaseUrl } from 'utils/env';

/** тип загруженных файлов */
export type FileType = { // если нет, то придет {name: "", url: "/upload/files"}
  name: string;
  url: string;
  fullUrl: string; // полный путь к файлу
}
/** подготовка приходящих файлов */
export const prepareFiles = (file: any): FileType => ({
  ...file,
  fullUrl: get(file, 'name.length', 0) ? `${projectBaseUrl}${file.url}` : '',
});

export const prepareFilesOrNull = (data: any): FileType | null => (data?.name?.length > 0 ? prepareFiles(data) : null);

/** базовый фильтр для всех сущностей. пагинация, страница */
export type DefaultFilterType = {
  'per-page'?: number;
  page?: number;
}

export type ParamTypes = Partial<Record<'id' | 'typeId' | 'enterprise_id' | 'userId' | 'action' | 'rolename', string>>;
