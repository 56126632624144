import { TFunction } from 'i18next';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PartAssignUserForm } from 'components/Applications/Construct/forms/PartAssignUserForm';
import { getAdditionalFields, getBlockName } from 'components/Applications/Construct/func';
import { ApplicationConstructFieldRenderByType } from 'components/Applications/Construct/dynamic/RenderByType';
import { PartEnterpriseForm } from 'components/Applications/Construct/forms/PartEnterpriseForm';
import { ListElementLink } from 'components/common/ListElement/ListElementLink';
import { PartDateForm } from 'components/Applications/Construct/forms/PartDateForm';
import { PartEmailForm } from 'components/Applications/Construct/forms/PartEmailForm';
import { PartCountCopiesForm } from 'components/Applications/Construct/forms/PartCountCopiesForm';
import { ListElement } from 'components/common/ListElement/ListElement';
import { UiForm } from 'components/common/UI/Form';
import { UiModal } from 'components/common/UI/Modal';
import { ListElementSwitch } from 'components/common/ListElement/ListElementSwitch';
import { SubmitLoaderButton } from 'components/common/SubmitLoaderButton';
import { UiBox } from 'components/common/UI/UiBox';
import {
  ApplicationConstructFormType,
  IApplicationConstructTypeModel,
} from 'models/Application/Construct/interfaces';
import { findValue } from 'utils/common';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { preparedAssignedUsers } from 'utils/preparedAssignedUsers';


/** данные для модалки по типу поля */
type PossibleReturnType = number | Date | string | null;

const useModalDataByType = ({
  name,
  t,
  setValue,
  closeModal,
  getValues,
  enterpriseOptions,
  assignedUsersOptions,
}: {
  name: FormFields | null;
  t: TFunction;
  setValue: (n: string, v: PossibleReturnType) => void;
  closeModal: () => void;
  getValues: (a: string) => any;
  enterpriseOptions: SimpleSelectOptionType<number>[];
  assignedUsersOptions: SimpleSelectOptionType<number>[];
}) => {
  if (name === null) {
    return [null, null];
  }

  const onSubmit = (value: PossibleReturnType) => {
    setValue(name, value);
    closeModal();
  };

  const propsToComponent = {
    defaultValue: getValues(name),
    onSubmit,
  };

  const title = t(`application.type.${name}`);

  switch (name) {
    case 'count_copies':
      return [
        title,
        <PartCountCopiesForm key={name} {...propsToComponent} />,
      ];
    case 'date':
      return [
        title,
        <PartDateForm key={name} {...propsToComponent} />,
      ];
    case 'email':
      return [
        title,
        <PartEmailForm key={name} {...propsToComponent} />,
      ];
    case 'enterprise_id':
      return [
        title,
        <PartEnterpriseForm key={name} {...propsToComponent} enterpriseOptions={enterpriseOptions} />,
      ];
    case 'assign_id':
      return [
        title,
        <PartAssignUserForm key={name} {...propsToComponent} assignedUsersOptions={assignedUsersOptions} />,
      ];
    default:
      return [null, null];
  }
};
/** ---- */

type Props = {
  model: IApplicationConstructTypeModel;
  userEnterpriseId: number;
  enterpriseOptions: SimpleSelectOptionType<number>[];
  onSave: (data: ApplicationConstructFormType) => void;
  usersOptions: SimpleSelectOptionType<number>[];
  isHaveIdea: boolean; // Заявка типа: "У меня есть идея предложения по улучшению!"
}

type FormValues = {
  need_original: boolean;
  count_copies: number;
  send_email: boolean;
  email: string | null;
  enterprise_id: number;
  assign_id: number | null;
  blocks: Record<string, any>; // динамические поля по имени "идблока___идполя__типполя"
}

type FormFields = 'count_copies' | 'date' | 'email' | 'enterprise_id' | 'assign_id';

export const ApplicationConstructCreateFormComponent = ({
  model, userEnterpriseId, enterpriseOptions, onSave, usersOptions, isHaveIdea,
}: Props) => {
  const { t } = useTranslation();

  const {
    register, setValue, formState: { isValid, isSubmitting, dirtyFields }, handleSubmit, getValues, watch,
    setError, clearError,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      need_original: true,
      count_copies: 1,
      send_email: false,
      email: null,
      enterprise_id: userEnterpriseId,
      assign_id: model.assignUsers.length === 1 ? model.assignUsers[0].id : null,
      blocks: {},
    },
  });

  /** все регистрируется и управляется вручную */
  useEffect(() => {
    // eslint-disable-next-line max-len
    ['need_original', 'count_copies', 'send_email', 'email', 'enterprise_id', 'assign_id'].forEach((name) => {
      register(name);
    });
  }, [register]);

  const [modalType, setModalType] = useState<FormFields | null>(null);
  const closeModal = useCallback(() => setModalType(null), []);
  const onChangeValue = (name: FormFields) => () => setModalType(name);

  const isCheckedNeedOriginal = getValues('need_original');

  const isEnableEmail = getValues('send_email');

  const selectedResponsibleUser = findValue(usersOptions, getValues('assign_id'));
  // eslint-disable-next-line max-len
  const isDisplayedResponsibleUser = useMemo(() => model.assignUsers.length === 1 || selectedResponsibleUser, [model.assignUsers, selectedResponsibleUser]);

  const assignedUsersOptions = preparedAssignedUsers(usersOptions, model.assignUsers);

  const [modalTitle, modalChildren] = useModalDataByType({
    name: modalType, t, setValue, closeModal, getValues, enterpriseOptions, assignedUsersOptions,
  });

  /** собрать все данные, обращая внимание на вложенные динамические блоки и поля */
  const onSubmit = useCallback((data: FormValues) => {
    onSave({
      type_id: model.id,
      assign_id: data.assign_id || undefined,
      enterprise_id: data.enterprise_id,
      need_original: data.need_original,
      count_copies: data.need_original ? data.count_copies : null,
      send_email: data.send_email,
      email: data.email,
      additionalFields: getAdditionalFields(data.blocks),
    });
  },
  [model.id, onSave]);

  return (
    <>
      <div className="page-header">
        <Link className="back" to={`/applications/create/${model.category ? `category/${model.category.id}` : ''}`} />
        {model.name}
      </div>
      <UiBox className="applications">
        <section className="form">
          <UiForm
            onSubmit={handleSubmit(onSubmit)}
            disabledSubmit={false}
            loadingSubmit={isSubmitting}
            dirtyFieldsAmount={dirtyFields.size}
            useCustomButtons
            createMode
            className="form-with-btn"
          >
            {model.availability.privilege_id && !isHaveIdea && (
              <ListElementLink
                text={t('application.privilege')}
                to={`/applications/privilege/${model.availability.privilege_id}/${model.enterprise?.id}`}
                isFollow
              />
            )}

            {!isHaveIdea && (
              <ListElement text={t('application.type.assign_id')} isFollow onClick={onChangeValue('assign_id')}>
                <div className="item">
                  <div className="item">
                    {isDisplayedResponsibleUser ?
                      selectedResponsibleUser?.label :
                      t('application.type.assign_id.placeholder')}
                  </div>
                </div>
              </ListElement>
            )}

            {!isHaveIdea && (
              <ListElementSwitch
                text="Нужен оригинал"
                name="need_original"
                id="need_original"
                onChange={setValue}
                checked={isCheckedNeedOriginal}
              />
            )}

            {isCheckedNeedOriginal && !isHaveIdea && (
              <ListElement text={t('application.type.count_copies')} isFollow onClick={onChangeValue('count_copies')}>
                <div className="item">
                  {getValues('count_copies')}
                </div>
              </ListElement>
            )}

            {model.send_email && !isHaveIdea && (
              <>
                <ListElementSwitch
                  text="Отправить копию на почту"
                  name="send_email"
                  id="send_email"
                  onChange={setValue}
                  checked={isEnableEmail}
                />
                {isEnableEmail && (
                  <ListElement text={t('application.type.email')} isFollow onClick={onChangeValue('email')}>
                    <div className="item">
                      {getValues('email')}
                    </div>
                  </ListElement>
                )}
              </>
            )}

            {!isHaveIdea && (
              <ListElement text={t('application.type.enterprise_id')} isFollow onClick={onChangeValue('enterprise_id')}>
                <div className="item">
                  {findValue(enterpriseOptions, getValues('enterprise_id'))?.label}
                </div>
              </ListElement>
            )}

            {/** разделение на блоки не нужно. выводить сразу поля друг под другом, без разделения */}
            {model.additionalFields.map((block) => block.fields.map((field) => {
              const inputName = getBlockName(block.block_id, field.uid, field.type);
              return (
                <ApplicationConstructFieldRenderByType
                  key={inputName}
                  inputName={inputName}
                  type={field.type}
                  title={field.name}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                  clearError={clearError}
                  required={field.required}
                  options={field.list?.map((listValue) => ({ value: listValue, label: listValue }))}
                />
              );
            }))}

            <div className="form-wrapper_btn">
              <div className="buttons-group buttons-group-responsive">
                <SubmitLoaderButton
                  disabled={!isValid}
                  loading={isSubmitting}
                  label={t('applications.btn.create')}
                />
              </div>
            </div>

          </UiForm>
        </section>
      </UiBox>

      <UiModal
        isOpen={modalChildren !== null}
        toggle={closeModal}
        headerContent={<span className="text-center">{modalTitle}</span>}
      >
        {modalChildren}
      </UiModal>
    </>
  );
};
