import { baseApiFn } from '../../../utils/baseApiFn';
import { httpGet } from '../../../utils/http';
import { IPositionsModel, PositionsFilterType, PositionsModel } from '../../../models/References/Positions';

export const getSystemPositions = async (filter?: PositionsFilterType) => baseApiFn<IPositionsModel>(
  async () => {
    const response = await httpGet('system/references/position', filter);
    return new PositionsModel(response);
  },
);
