import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { showLoaderSelector } from 'store/selectors';

type Props = {
  className?: string;
}

/**
 * стандартный базовый лоадер
 */
export const Loader = memo(({ className }: Props) => (
  <div className={`d-flex justify-content-center${className ? ` ${className}` : ''}`}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
));


const FullLoaderStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  position: 'fixed' as const, // ts беспредел
  top: 0,
  left: 0,
  zIndex: 1300,
  background: 'rgba(0, 0, 0, 0.33)',
};
/**
 * лоадер на всю контентную область, не блокирующий верхнее и левое меню
 */
export const FullLoader = () => (
  <div style={FullLoaderStyle}>
    <Loader />
  </div>
);


/**
 * глобальный лоадер, блокирующий экран
 * использовать в самых редких случаях
 * например когда нужно заставить пользователя ждать без возможности что-то сделать в интерфейсе
 */
export const GlobalLoader = memo(() => {
  const showLoader: boolean = useSelector(showLoaderSelector);
  return showLoader ? <FullLoader /> : null;
});
