import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ParamTypes } from 'models/common';
import { getAllPartnersByCategory } from 'api/partner';
import { Loader } from 'components/common/UI/Loaders';
import { EntityNotFound } from 'components/Errors/404';
import { usePromise } from 'utils/hooks/usePromise';
import { HttpErrors } from 'utils/http';
import { IPartnersListModel } from 'models/Partners/PartnersListModel';
import { PartnersCategoryListComponent } from 'components/Partners/сategoryList';


export const PartnersCategoryList = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [model, modelLoading, onUnmount] = usePromise<IPartnersListModel | HttpErrors>(
    () => getAllPartnersByCategory(numberId), !isNaN(numberId), [numberId],
  );
  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  if (modelLoading) {
    return <Loader />;
  }
  if (model === null || model instanceof Error || model.length === 0) {
    return <EntityNotFound message={t('partners.category.404')} />;
  }
  return (
    <PartnersCategoryListComponent
      model={model}
      // название категории взять у любой найденной акции
      title={model[0].categories.find(({ id }) => id === numberId)?.name}
    />
  );
};
