import React, { useState } from 'react';
// @ts-ignore
import ImgsViewer from 'react-images-viewer';

type Props = {
  src: string;
  className?: string;
  alt?: string;
}

/**
 * простой полноэкранный просмотр изображения при клике
 */
export const UiImgViewer = ({ src, className, alt }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <img role="presentation" className={className} src={src} onClick={() => setOpen(true)} alt={alt} />
      <ImgsViewer
        imgs={[{ src }]}
        currImg={0}
        isOpen={open}
        onClose={() => setOpen(false)}
        backdropCloseable
        showImgCount={false}
      />
    </>
  );
};
