import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';


const getUsers = (state: RootReducer) => state.system[storeName].users;
const getUsersIsLoading = (state: RootReducer) => state.system[storeName].usersIsLoading;


export const systemUsersSelector = createSelector([getUsers], returnParams);
export const systemUsersIsLoadingSelector = createSelector([getUsersIsLoading], returnParams);
