import { IDepartmentItemModel } from 'models/References/DepartmentModel';
import { AnyAction } from 'redux';

import { INewsCategoryItemModel } from '../../models/News/NewsCategoryModel';
import {
  SYSTEM_GET_ENTERPRISES_FAIL,
  SYSTEM_GET_ENTERPRISES_OK,
  SYSTEM_GET_NEWS_CATEGORY_FAIL,
  SYSTEM_GET_NEWS_CATEGORY_OK,
  SYSTEM_GET_PERMISSIONS_FAIL,
  SYSTEM_GET_PERMISSIONS_OK,
  SYSTEM_GET_ROLES_FAIL,
  SYSTEM_GET_ROLES_OK,
  SYSTEM_GET_ROLES_START, SYSTEM_GET_TAGS_FAIL, SYSTEM_GET_TAGS_OK,
  SYSTEM_GLOBAL_DATA_LOADED,
  SYSTEM_GLOBAL_GET_DEPARTMENTS_FAIL,
  SYSTEM_GLOBAL_GET_DEPARTMENTS_OK,
  SYSTEM_GLOBAL_GET_UNITS_FAIL,
  SYSTEM_GLOBAL_GET_UNITS_OK,
  SYSTEM_POLLS_GET_QUESTION_TYPES_FAIL,
  SYSTEM_POLLS_GET_QUESTION_TYPES_OK,
  SYSTEM_POLLS_GET_STATUSES_FAIL,
  SYSTEM_POLLS_GET_STATUSES_OK,
} from './constants';
import {
  deepOnFail, deepOnLoad, deepOnLoading, onFail, onLoad,
} from '../../utils/reducer';
import { IEnterpriseModel } from '../../models/Enterprises/EnterpriseModel';
import { IRolesModel, RolesModel } from '../../models/Roles/RolesModel';
import { IPermissions } from '../../models/Roles/Permissions';
import { IPollsQuestionTypesModel } from '../../models/Polls/PollsQuestionTypesModel';
import { IPollsStatusModel } from '../../models/Polls/PollsStatusesModel';
import { ISystemUnitModel } from '../../models/References/UnitsModel';
import { ISystemBonusProgramTagItemModel } from '../../models/BonusProgram/Tag/interfaces';

export type SystemGlobalReducerType = {
  newsCategories: INewsCategoryItemModel[];
  newsCategoriesIsLoading: boolean;

  enterprises: IEnterpriseModel[];
  enterprisesIsLoading: boolean;

  tags: ISystemBonusProgramTagItemModel[];
  tagsIsLoading: boolean;

  units: ISystemUnitModel[];
  unitsIsLoading: boolean;

  departments: IDepartmentItemModel[];
  departmentsIsLoading: boolean;

  rbac: {
    permissions: IPermissions;
    permissionsIsLoading: boolean;

    roles: IRolesModel;
    rolesIsLoading: boolean;
  };

  polls: {
    questionTypes: IPollsQuestionTypesModel;
    questionTypesIsLoading: boolean;

    statuses: IPollsStatusModel[];
    statusesIsLoading: boolean;
  };

  allDataLoaded: boolean;
}

const initialState: SystemGlobalReducerType = {
  newsCategories: [],
  newsCategoriesIsLoading: true,

  enterprises: [],
  enterprisesIsLoading: true,

  tags: [],
  tagsIsLoading: true,

  units: [],
  unitsIsLoading: true,

  departments: [],
  departmentsIsLoading: true,

  rbac: {
    permissions: [],
    permissionsIsLoading: true,

    roles: new RolesModel({}),
    rolesIsLoading: true,
  },

  polls: {
    questionTypes: [],
    questionTypesIsLoading: true,

    statuses: [],
    statusesIsLoading: true,
  },

  allDataLoaded: false,
};

export const storeName = 'global';

export const systemGlobalReducer = {
  [storeName]: (state: SystemGlobalReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GLOBAL_DATA_LOADED:
        return {
          ...state,
          allDataLoaded: true,
        };

      case SYSTEM_GET_NEWS_CATEGORY_OK:
        return onLoad(state, payload, 'newsCategories');
      case SYSTEM_GET_NEWS_CATEGORY_FAIL:
        return onFail(state, 'newsCategories');

      case SYSTEM_GET_ENTERPRISES_OK:
        return onLoad(state, payload, 'enterprises');
      case SYSTEM_GET_ENTERPRISES_FAIL:
        return onFail(state, 'enterprises');

      case SYSTEM_GET_TAGS_OK:
        return onLoad(state, payload, 'tags');
      case SYSTEM_GET_TAGS_FAIL:
        return onFail(state, 'tags');

      case SYSTEM_GLOBAL_GET_UNITS_OK:
        return onLoad(state, payload, 'units');
      case SYSTEM_GLOBAL_GET_UNITS_FAIL:
        return onFail(state, 'units');

      case SYSTEM_GLOBAL_GET_DEPARTMENTS_OK:
        return onLoad(state, payload, 'departments');
      case SYSTEM_GLOBAL_GET_DEPARTMENTS_FAIL:
        return onFail(state, 'departments');

      case SYSTEM_GET_PERMISSIONS_OK:
        return deepOnLoad(state, payload, 'rbac.permissions');
      case SYSTEM_GET_PERMISSIONS_FAIL:
        return deepOnFail(state, 'rbac.permissions');

      case SYSTEM_GET_ROLES_START:
        return deepOnLoading(state, 'rbac.roles');
      case SYSTEM_GET_ROLES_OK:
        return deepOnLoad(state, payload, 'rbac.roles');
      case SYSTEM_GET_ROLES_FAIL:
        return deepOnFail(state, 'rbac.roles');

      case SYSTEM_POLLS_GET_QUESTION_TYPES_OK:
        return deepOnLoad(state, payload, 'polls.questionTypes');
      case SYSTEM_POLLS_GET_QUESTION_TYPES_FAIL:
        return deepOnFail(state, 'polls.questionTypes');

      case SYSTEM_POLLS_GET_STATUSES_OK:
        return deepOnLoad(state, payload, 'polls.statuses');
      case SYSTEM_POLLS_GET_STATUSES_FAIL:
        return deepOnFail(state, 'polls.statuses');

      default:
        return state;
    }
  },
};
