import { AnyAction } from 'redux';

import {
  ConstructApplicationTemplateListModel,
} from 'models/ConstructApplication/ConstructApplicationTemplateListModel';
import {
  ConstructApplicationCategoryListModel,
} from 'models/ConstructApplication/ConstructApplicationCategoryListModel';
import {
  IConstructApplicationCategoryListModel,
  IConstructApplicationTemplateListModel,
} from 'models/ConstructApplication/interfaces';
import {
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_FAIL,
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_OK,
  SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_START,
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_FAIL,
  SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_OK, SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_START,
} from 'systemModule/containers/ConstructApplication/constants';
import { onFail, onLoad, onLoading } from 'utils/reducer';


export type systemConstructApplicationReducerType = {
  categoryList: IConstructApplicationCategoryListModel;
  categoryListIsLoading: boolean;

  templateList: IConstructApplicationTemplateListModel;
  templateListIsLoading: boolean;
}

const initialState: systemConstructApplicationReducerType = {
  categoryList: new ConstructApplicationCategoryListModel(),
  categoryListIsLoading: true,

  templateList: new ConstructApplicationTemplateListModel(),
  templateListIsLoading: true,
};

export const storeName = 'construct-application';

export const systemConstructApplicationReducer = {
  [storeName]: (state: systemConstructApplicationReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_START:
        return onLoading(state, 'categoryList');
      case SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_OK:
        return onLoad(state, payload, 'categoryList');
      case SYSTEM_GET_CONSTRUCT_APPLICATION_CATEGORY_LIST_FAIL:
        return onFail(state, 'categoryList');

      case SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_START:
        return onLoading(state, 'templateList');
      case SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_OK:
        return onLoad(state, payload, 'templateList');
      case SYSTEM_GET_CONSTRUCT_APPLICATION_TEMPLATE_LIST_FAIL:
        return onFail(state, 'templateList');

      default:
        return state;
    }
  },
};
