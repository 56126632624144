import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useState } from 'react';

import { getEducationList } from 'api/education';
import { EducationListModel } from 'models/Education/EducationListModel';
import { EducationListFtilerType, IEducationListModel } from 'models/Education/interfaces';
import { handleErrors } from 'utils/errors';
import { EducationListComponent } from 'components/Education/EducationList';


export const EducationList = () => {
  const [model, setModel] = useState<IEducationListModel>(new EducationListModel());
  const [modelLoading, setModelLoading] = useState(true);
  const [clearLoad, setClearLoad] = useState(false);

  const getData = useCallback(async (filter?: EducationListFtilerType, append = false) => {
    setModelLoading(true);

    if (!append) {
      setClearLoad(true);
    }

    handleErrors(
      await getEducationList(filter),
      'get',
      (model) => {
        if (append) {
          setModel((prevState) => ({
            ...model,
            data: cloneDeep(prevState.data).concat(model.data),
          }));
        } else {
          setModel(model);
        }
      },
    );

    setModelLoading(false);
  }, []);

  /** отслеживать полную загрузку при загрузке без подгрузки(чистая загрузка) */
  useEffect(() => {
    if (!modelLoading && clearLoad) {
      setClearLoad(false);
    }
  }, [modelLoading, setClearLoad, clearLoad]);

  return (
    <EducationListComponent
      getData={getData}
      data={model}
      isLoading={modelLoading}
      hasMoreData={false}
      clearLoad={clearLoad}
    />
  );
};
