import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import { IInsurancesModel, InsurancesModel } from '../../../models/Insurances/InsurancesModel';
import {
  SYSTEM_REFERENCES_GET_INSURANCE_FAIL,
  SYSTEM_REFERENCES_GET_INSURANCE_OK,
  SYSTEM_REFERENCES_GET_INSURANCE_START,
} from './constants';


export type systemReferencesReducerType = {
  insurances: IInsurancesModel;
  insurancesIsLoading: boolean;
}

const initialState: systemReferencesReducerType = {
  insurances: new InsurancesModel(),
  insurancesIsLoading: true,
};

export const storeName = 'references';

export const systemReferencesReducer = {
  [storeName]: (state: systemReferencesReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_REFERENCES_GET_INSURANCE_START:
        return onLoading(state, 'insurances');
      case SYSTEM_REFERENCES_GET_INSURANCE_OK:
        return onLoad(state, payload, 'insurances');
      case SYSTEM_REFERENCES_GET_INSURANCE_FAIL:
        return onFail(state, 'insurances');

      default:
        return state;
    }
  },
};
