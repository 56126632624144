import { DefaultFilterType, FileType, prepareFiles } from '../common';
import { CDate } from '../../utils/CDate';
import { IWithPages, WithPagesModel } from '../WithPagesModel';


export interface IEventFilterType extends DefaultFilterType {
  name?: string;
  date_from?: string;
  date_to?: string;
}


export interface IEventModel {
  readonly id: number;
  readonly name: string;
  readonly date_start: Date;
  readonly place: string;
  readonly contacts: string;
  readonly description: string;
  readonly image: FileType | null;
  readonly ask_question: boolean;
  readonly has_answer: boolean;
}

export class EventModel implements IEventModel {
  readonly date_start: Date;
  readonly id: number;
  readonly image: FileType | null;
  readonly name: string;
  readonly place: string;
  readonly contacts: string;
  readonly description: string;
  readonly ask_question: boolean;
  readonly has_answer: boolean;

  constructor(params: any = {}) {
    this.date_start = params.date_start ? CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm') : new Date();
    this.id = params.id;
    this.image = params.image ? prepareFiles(params.image) : null;
    this.name = params.name;
    this.place = params.place;
    this.contacts = params.contacts || '';
    this.description = params.description;
    this.ask_question = params.ask_question;
    this.has_answer = params.has_answer;
  }
}


export interface IEventsModel extends IWithPages {
  data: IEventModel[];
}

export class EventsModel extends WithPagesModel implements IEventsModel {
  data: IEventModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, EventModel);
  }
}
