import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../../utils/reducer';
import { ISystemEnterprisesModel, SystemEnterprisesModel } from '../../../../models/References/EnterprisesModel';
import {
  SYSTEM_REFERENCES_GET_ENTERPRISES_FAIL,
  SYSTEM_REFERENCES_GET_ENTERPRISES_OK,
  SYSTEM_REFERENCES_GET_ENTERPRISES_START,
} from './constants';

export type systemEnterprisesReducerType = {
  enterprises: ISystemEnterprisesModel;
  enterprisesIsLoading: boolean;
}

const initialState: systemEnterprisesReducerType = {
  enterprises: new SystemEnterprisesModel(),
  enterprisesIsLoading: true,
};

export const storeName = 'enterprises';

export const systemEnterprisesReducer = {
  [storeName]: (state: systemEnterprisesReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_REFERENCES_GET_ENTERPRISES_START:
        return onLoading(state, 'enterprises');
      case SYSTEM_REFERENCES_GET_ENTERPRISES_OK:
        return onLoad(state, payload, 'enterprises');
      case SYSTEM_REFERENCES_GET_ENTERPRISES_FAIL:
        return onFail(state, 'enterprises');

      default:
        return state;
    }
  },
};
