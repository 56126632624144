import { createSelector } from 'reselect';
import { returnParams } from '../../../store/selectors';
import { storeName } from './reducers';
import { RootReducer } from '../../../store/reducers';


const getPolls = (state: RootReducer) => state.system[storeName].polls;
const getPollsIsLoading = (state: RootReducer) => state.system[storeName].pollsIsLoading;


export const systemPollsSelector = createSelector([getPolls], returnParams);
export const systemPollsIsLoadingSelector = createSelector([getPollsIsLoading], returnParams);
