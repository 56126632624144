import { ClientNav } from '../nav';
import { CurrentUserModel } from '../models/User/CurrentUserModel';

export const filteringMenuItemsByRights = ((clientNav: ClientNav[], currentUser: CurrentUserModel) => clientNav
  .filter((menuItem) => {
    if (menuItem.right) {
      return currentUser.hasPermission(menuItem.right);
    }
    return true;
  }));
