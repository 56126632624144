import { AnyAction } from 'redux';
import { ISystemBusStopsModel, SystemBusStopsModel } from '../../../../models/References/BusStopsModel';
import {
  SYSTEM_REFERENCES_GET_BUS_STOPS_FAIL,
  SYSTEM_REFERENCES_GET_BUS_STOPS_OK,
  SYSTEM_REFERENCES_GET_BUS_STOPS_START,
} from './constants';
import { onFail, onLoad, onLoading } from '../../../../utils/reducer';

export type systemBusStopsReducerType = {
  busStops: ISystemBusStopsModel;
  busStopsIsLoading: boolean;
}

const initialState: systemBusStopsReducerType = {
  busStops: new SystemBusStopsModel(),
  busStopsIsLoading: true,
};

export const storeName = 'busStops';

export const systemBusStopsReducer = {
  [storeName]: (state: systemBusStopsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_REFERENCES_GET_BUS_STOPS_START:
        return onLoading(state, 'busStops');
      case SYSTEM_REFERENCES_GET_BUS_STOPS_OK:
        return onLoad(state, payload, 'busStops');
      case SYSTEM_REFERENCES_GET_BUS_STOPS_FAIL:
        return onFail(state, 'busStops');

      default:
        return state;
    }
  },
};
