import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ParamTypes } from 'models/common';
import { EducationModel } from 'models/Education/EducationModel';
import { getEducationById } from 'api/education';
import { Loader } from 'components/common/UI/Loaders';
import { EducationItemComponent } from 'components/Education/EducationItem';
import { EntityNotFound } from 'components/Errors/404';
import { usePromise } from 'utils/hooks/usePromise';


export const EducationItem = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const [model, modelLoading] = usePromise(
    () => getEducationById(numberId),
    !isNaN(numberId),
    [numberId],
  );

  if (model instanceof EducationModel) {
    return <EducationItemComponent model={model} />;
  }
  return modelLoading ? <Loader /> : <EntityNotFound message={t('education.404')} />;
};
