import { baseApiFn } from 'utils/baseApiFn';
import { httpGet, httpPut } from 'utils/http';
import {
  IPageReferralProgramModel,
  PageReferralProgramModel,
} from 'models/References/PageReferralProgramModel';


/** Получить текст реферальной программы */
export const getSystemReferralProgramText = async () => baseApiFn<IPageReferralProgramModel>(async () => {
  const response = await httpGet('/system/references/page/referral-program');
  return new PageReferralProgramModel(response);
});

/** Сохранить текст реферальной программы */
export const saveSystemReferralProgramText = async (
  data: IPageReferralProgramModel,
) => baseApiFn<true>(httpPut('/system/references/page/referral-program', data));
