import React, { useState } from 'react';
import { Button, Collapse } from 'reactstrap';


const allowedTextLength = 50;

type Props = {
  text: string;
}

export const MaybeLongAnswer = ({ text }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  if (text.length <= allowedTextLength) {
    return <>{text}</>;
  }
  return (
    <>
      <Button size="sm" color="primary" className="collapse-btn" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Скрыть' : 'Показать'} ответ
      </Button>
      <Collapse isOpen={isOpen}>
        {text}
      </Collapse>
    </>
  );
};
