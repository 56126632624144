import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { handleErrors } from 'utils/errors';
import { getSystemEnterpriseSettings } from 'systemModule/api/setting';
import {
  EnterpriseSettingsModel,
  IEnterpriseSettingsModel,
  settlement_sheet_available_date,
} from 'models/Settings/EnterpriseSettingsModel';
import { ISystemEnterpriseModel, SystemEnterpriseModel } from 'models/References/EnterprisesModel';
import { Loader } from 'components/common/UI/Loaders';
import { SystemEnterpriseItemPageComponent } from '../../../components/References/Enterprises/SystemEnterpriseItemPage';
import { systemEnterpriseByIdSelector } from './selectors';
import { getSystemEnterprise } from '../../../api/references/Enterprises';


export const SystemEnterpriseItemPage = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [isLoading, setLoading] = useState({
    item: true,
    settings: true,
  });

  const [item, setItem] = useState<ISystemEnterpriseModel>(new SystemEnterpriseModel());
  const foundItem: ISystemEnterpriseModel | undefined = useSelector(systemEnterpriseByIdSelector(id ? numberId : 0));

  const [settings, setSettings] = useState<IEnterpriseSettingsModel>(new EnterpriseSettingsModel());

  const getSettings = useCallback(async () => {
    handleErrors(
      await getSystemEnterpriseSettings(numberId, settlement_sheet_available_date),
      'get',
      (model) => {
        setSettings(model);
      },
      () => {
        setLoading((prevState) => ({ ...prevState, settings: false }));
      },
    );
  }, [numberId]);

  const endLoadingItem = useCallback(() => {
    setLoading((prevState) => ({ ...prevState, item: false }));
  }, []);

  useEffect(() => {
    if (foundItem) {
      endLoadingItem();
      setItem(foundItem);
      getSettings();
    } else if (!isNaN(numberId)) {
      getSystemEnterprise(numberId)
        .then((modelOrError) => {
          if (modelOrError instanceof Error) {
            toast.error('Ошибка загрузки данных');
          } else {
            setItem(modelOrError);
          }
          endLoadingItem();
        });

      getSettings();
    } else {
      setItem(new SystemEnterpriseModel({}));
      setLoading({ item: false, settings: false });
    }
  }, [foundItem, numberId, endLoadingItem, getSettings]);

  if (isLoading.settings || isLoading.item) {
    return <Loader />;
  }

  return (
    <SystemEnterpriseItemPageComponent
      item={item}
      settings={settings}
    />
  );
};
