import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FeedbackFormComponent } from '../../components/FeedbackForm/FeedbackForm';
import { UploadFiles } from '../../models/UploadFiles/UploadFiles';
import { handleErrors } from '../../utils/errors';
import { createFeedback } from '../../api/application';
import { FeedbackFormType } from '../../models/Application/Feedback/interfaces';

export const FeedbackForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const onSubmit = useCallback(async ({
    id, description, file, isDepartment,
  }: FeedbackFormType) => {
    if (!id) {
      toast.error('Выберите адресата');
      return;
    }
    setIsLoading(true);

    let fileLink = '';
    /** Если есть файл в форме то сначала загрузит его и вернет ссылку */
    if (file) {
      const responseFile = await new UploadFiles([file]).upload();
      if (responseFile instanceof Error) {
        toast.error('Ошибка загрузки файла');
      } else {
        fileLink = responseFile[0].file_name;
      }
    }

    const formData = {
      description,
      file: fileLink,
    };
    const type = isDepartment ? 'department' : 'user';

    handleErrors(
      await createFeedback({
        ...formData,
        [type]: id,
      }, type),
      'save',
      () => {
        toast.success('Обращение отправлено');
        history.push('/');
      },
    );
    setIsLoading(false);
  }, [history]);

  return <FeedbackFormComponent onSubmit={onSubmit} isLoading={isLoading} />;
};
