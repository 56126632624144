import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { baseApiFn } from 'utils/baseApiFn';
import {
  httpDelete, httpGet, httpPut,
} from 'utils/http';
import {
  IUsersModel, UsersFilterType, UsersModel,
} from 'models/Users/UsersModel';
import { CurrentUserModel, ICurrentUserModel } from 'models/User/CurrentUserModel';
import { ICurrentUserEditShowPhone, ISetChief } from 'models/User/interfaces';


/** Получить список пользователей */
export const getUserList = async (filter?: UsersFilterType) => baseApiFn<IUsersModel>(async () => {
  const response = await httpGet('/public/user/list', filter);
  return new UsersModel(response);
});

/** Получить СДС-коины пользователя */
export const getUserSdsCoin = async () => baseApiFn<number>(
  httpGet('/public/user/sdscoin'),
);

/**
 * получить список пользователей в виде SimpleSelectOptionType[]
 * подготовка, вытаскивание пользователей из метода getUserList
 */
export const getUsersOptions = async (
  filter?: UsersFilterType,
) => baseApiFn<SimpleSelectOptionType<number>[]>(async () => {
  const response = await getUserList({ ...filter, is_register: true });

  if (response instanceof Error) {
    return response;
  }
  return response.data
    .map((ent) => ent.units
      .map((unit) => ({
        options: unit.users
          .filter((user) => user.id)
          .map((value) => ({
            label: `${value.fio} (${unit.name})`, // форматирование "ФИО (подразделение)"
            value: value.id,
          })),
      }))
      .filter((unit) => unit.options.length))
    .flat(1)
    .reduce((acc: SimpleSelectOptionType<number>[], opt) => acc.concat(opt.options), []);
});

/** Получить список избранных пользователей */
export const getFavoriteUserList = async (filter?: UsersFilterType) => baseApiFn<IUsersModel>(async () => {
  const response = await httpGet('/public/user/favorite', filter);
  return new UsersModel(response);
});

/** Получить список пользователей, избранные вначале */
export const getUserListByFavorite = async (filter?: UsersFilterType) => baseApiFn<IUsersModel>(async () => {
  const response = await httpGet('/public/user/list-sort-favorite', filter);
  return new UsersModel(response);
});

/** Добавить пользователя в избранные */
export const setUserFavorite = async (userId: number, unitId: number) => baseApiFn<IUsersModel>(
  httpPut(
    '/public/user/favorite',
    {
      favorite_id: userId,
      unit_id: unitId,
    },
  ),
);

/** Установить главное предприятие пользователя */
export const setUserMainEnterprise = async (main_enterprise_id: number) => baseApiFn<ICurrentUserModel>(async () => {
  const response = await httpPut('/public/user/main-enterprise', { main_enterprise_id });
  return new CurrentUserModel(response);
});

/** Удалить пользователя из избранных */
export const deleteUserFavorite = async (userId: number, unitId: number) => baseApiFn<IUsersModel>(
  httpDelete(
    '/public/user/favorite',
    {
      favorite_id: userId,
      unit_id: unitId,
    },
  ),
);

/** Создание заявки на изменение кадровых данных */
export const createPersonnelDataOrder = async (description: string) => baseApiFn<true>(
  httpPut('/public/user/update-data', { description }),
);

/** Установить начальника отдела */
export const setChief = async (data: ISetChief) => baseApiFn<ICurrentUserModel>(httpPut('/public/user/chief', data));

/** изменение видимости контактного номера */
export const setUserPhoneVisibility = async (data: ICurrentUserEditShowPhone) => baseApiFn<true>(
  httpPut('/public/user/show-phone', data),
);

/** Пройти гид по мобильному приложению */
export const completeApplicationGuide = async () => baseApiFn<true>(
  httpPut('/public/user/complete-mobile-guide'),
);
