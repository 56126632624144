import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { UiSystemInput } from '../../../components/common/UI/Input';
import { UiToolbar } from '../../../components/common/Toolbar';
import { DateRange } from '../../../components/common/Dates/DateRange';
import { CDate } from '../../../utils/CDate';
import { ISystemListEventFilterType } from '../../../models/Events/SystemListEvents';


type Props = {
  onSubmit: (data: ISystemListEventFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  search: string;
  date_from: Date;
  date_to: Date;
}

const rangeNames = ['date_from', 'date_to'];

export const SystemEventsFilter = ({ onSubmit, onReset }: Props) => {
  const {
    register, handleSubmit, watch, setValue, reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      search: '',
      date_from: undefined,
      date_to: undefined,
    },
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onLocalSubmit = (data: FormValues) => {
    onSubmit({
      search: data.search.length ? data.search : undefined,
      date_from: data.date_from ? CDate.format(data.date_from, 'yyyy-MM-dd') : undefined,
      date_to: data.date_to ? CDate.format(data.date_to, 'yyyy-MM-dd') : undefined,
    });
  };

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row>
        <Col xs="12" sm="6" md="3">
          <DateRange
            names={rangeNames}
            label="Дата и время события"
            register={register}
            watch={watch}
            setValue={setValue}
            className="system-formgroup"
            system
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label="Поиск по событиям"
            name="search"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
