import React from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { customHistory } from 'customHistory';
import { ISystemBusStopModel } from '../../../../models/References/BusStopsModel';
import { HttpErrors } from '../../../../utils/http';
import { createSystemRefBusStop, updateSystemRefBusStop } from '../../../api/references/busStops';
import { handleErrors } from '../../../../utils/errors';
import { ICurrentUserModel, UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import { UiSystemInput } from '../../../../components/common/UI/Input';
import { Loader } from '../../../../components/common/UI/Loaders';
import { UiSystemForm } from '../../../../components/common/UI/Form';

const saveForm = async (createMode: boolean, data: FormValues, item: ISystemBusStopModel) => {
  const trueOrError: true | HttpErrors = (createMode) ?
    await createSystemRefBusStop(data) :
    await updateSystemRefBusStop(item.id, data);

  handleErrors(
    trueOrError,
    'save',
    () => {
      toast.success(createMode ? 'Остановка успешно создана' : 'Остановка успешно обновлена');
      customHistory.push('/system/references/busstop');
    },
  );
};


type Props = {
  item: ISystemBusStopModel;
  isLoading: boolean;
}

type FormValues = {
  name: string;
}

export const SystemBusStopFormComponent = ({ item, isLoading }: Props) => {
  const createMode = customHistory.location.pathname === '/system/references/busstop/create';

  const {
    register, handleSubmit, errors, formState: { isValid, isSubmitting, dirtyFields },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: item.name,
    },
  });

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wReferenceBusStop);

  const onSubmit = (data: FormValues) => (canUpdate ? saveForm(createMode, data, item) : Promise.reject());

  return isLoading ? <Loader /> : (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={createMode}
    >
      {
        (item.id === undefined && !createMode) ? <h3>Остановка не найдена</h3> : (
          <>
            <h3>{createMode ? 'Создание' : 'Редактирование'} остановки</h3>
            <UiSystemInput
              errors={errors}
              register={register}
              name="name"
              label="Название остановки"
              required
            />
          </>
        )}
    </UiSystemForm>
  );
};
