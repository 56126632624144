import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationViewConstructFieldsComponent } from 'components/Applications/ApplicationViewConstructFields';
import { CDate } from 'utils/CDate';
import { IApplicationModel } from 'models/Application/interfaces';
import { FileType } from 'models/common';
import { UiOuterLink } from 'components/common/UI/OuterLink';
import { ReactComponent as DownloadIcon } from '../../assets/img/download.svg';
import { ListElement } from '../common/ListElement/ListElement';


type Props = {
  model: IApplicationModel;
}

export const ApplicationOneFields = ({ model }: Props) => {
  const { t } = useTranslation();

  const commentFiles = model.comments.reduce((acc: FileType[], comment) => {
    if (comment.files.length) {
      acc.push(...comment.files);
    }
    return acc;
  }, []);

  return (
    <>
      <ListElement text={t('application.number')} isTitle>
        <div className="item">{model.id}</div>
      </ListElement>
      <ListElement text={t('application.type')} isTitle>
        <div className="item">{model.type.name}</div>
      </ListElement>
      <ListElement text={t('application.author')} isTitle>
        <div className="item">{model.author.fio}</div>
      </ListElement>
      <ListElement text={t('application.date.created')} isTitle>
        <div className="item">{CDate.format(model.created_at, 'dd.MM.yyyy/HH:mm')}</div>
      </ListElement>
      <ListElement text={t('application.status')} isTitle>
        <div className="item">{model.status.name}</div>
      </ListElement>
      <ListElement text={t('application.enterprise')} isTitle>
        <div className="item">{model.enterprise}</div>
      </ListElement>
      <ListElement text={t('application.assign')} isTitle>
        <div className="item">{model.assign.fio}</div>
      </ListElement>
      <ListElement text={t('application.description')} isTitle>
        <div className="item ws-pw">{model.description}</div>
      </ListElement>
      <ListElement text={t('application.comment')} isTitle>
        <div className="flex-column">
          <div className="text-black-50 mb-2">
            {model.comments.length ? model.comments.map((comment, cindex) => (
              <React.Fragment key={comment.author.id}>
                <p className={`mb-2${cindex > 0 ? ' border-top mt-2 pt-1' : ''}`}>
                  {comment.content}
                </p>
                <p className="mb-1">
                  Автор: {comment.author.fio}
                </p>
                <p className="mb-0">
                  Дата/время: {CDate.format(comment.created_at, 'dd.MM.yyyy/HH:mm')}
                </p>
              </React.Fragment>
            )) : 'Нет'}
          </div>
          {!!commentFiles.length && commentFiles.map((item) => (
            <div key={Math.random()}>
              <span className="item">{item.name}</span>
              <UiOuterLink href={item.fullUrl} className="item icon download">
                <DownloadIcon />
              </UiOuterLink>
            </div>
          ))}
        </div>
      </ListElement>
      {model.file && (
        <ListElement text={t('application.files')} isTitle>
          <div className="item">{model.file.name}</div>
          <UiOuterLink href={model.file?.fullUrl || ''} className="item icon download">
            <DownloadIcon />
          </UiOuterLink>
        </ListElement>
      )}
      <ListElement text={t('application.compensation.amount')} isTitle />
      <ListElement text={t('application.compensation.period')} isTitle />
      <ListElement text={t('application.history')} isTitle>
        <div className="flex-column">
          {model.responsible.map((item) => (
            <div className="text-black-50" key={item.id}>{t('application.assign')}: {item.user.fio}</div>
          ))}
        </div>
      </ListElement>

      <ApplicationViewConstructFieldsComponent fields={model.additionalFields} />
    </>
  );
};
