import React from 'react';
import { Redirect } from 'react-router-dom';

import { ConstructApplicationPage } from 'systemModule/components/ConstructApplication/Type/ConstructApplicationPage';
import { CRoutes } from 'routes';
import { SystemAllApplicationsList } from 'systemModule/containers/AllAplications/SystemAllApplicationList';
import {
  ConstructApplicationCategoryForm,
} from 'systemModule/containers/ConstructApplication/ConstructApplicationCategoryForm';
import {
  ConstructApplicationCategoryList,
} from 'systemModule/containers/ConstructApplication/ConstructApplicationCategoryList';
import {
  ConstructApplicationTemplateForm,
} from 'systemModule/containers/ConstructApplication/ConstructApplicationTemplateForm';
import {
  ConstructApplicationTemplateList,
} from 'systemModule/containers/ConstructApplication/ConstructApplicationTemplateList';
import {
  ConstructApplicationTypeList,
} from 'systemModule/containers/ConstructApplication/Type/ConstructApplicationTypeList';
import { SystemPageAgreement } from 'systemModule/containers/PageAgreement';
import { SystemPartnersCategoryForm } from 'systemModule/containers/Partners/Category/form';
import { SystemPartnersCategoryList } from 'systemModule/containers/Partners/Category/list';
import { SystemPartnersForm } from 'systemModule/containers/Partners/form';
import { SystemPartnersList } from 'systemModule/containers/Partners/list';
import { SystemTransportList } from 'systemModule/containers/Transport/SystemTransportList';
import { SystemTransportForm } from 'systemModule/containers/Transport/SystemTransportForm';
import { getSystemPath } from 'systemModule/utils/getSystemUrl';
import { SystemUserSettings } from 'systemModule/containers/UsersPhone/SystemUserSettings';
import { SystemNews } from './containers/News/SystemNews';
import { SystemNewsCreate } from './containers/News/SystemNewsCreate';
import { SystemNewsUpdate } from './containers/News/SystemNewsUpdate';
import { SystemUsers } from './containers/Users/SystemUsers';
import { SystemRoles } from './containers/Roles/SystemRoles';
import { SystemRolesForm } from './containers/Roles/SystemRolesForm';
import { SystemRefInsurance } from './containers/References/Insurance/SystemRefInsurance';
import { SystemRefInsuranceForm } from './containers/References/Insurance/SystemRefInsuranceForm';
import { SystemPolls } from './containers/Polls/SystemPollls';
import { SystemPollsForm } from './containers/Polls/SystemPollsForm';
import { SystemBusStops } from './containers/References/BusStops/SystemBusStops';
import { SystemBusStopForm } from './containers/References/BusStops/SystemBusStopsForm';
import { SystemEnterprises } from './containers/References/Enterprises/SystemEnterprises';
import { SystemUnits } from './containers/References/Units/SystemUnits';
import { SystemSports } from './containers/References/Sports/SystemSports';
import { SystemEnterpriseItemPage } from './containers/References/Enterprises/SystemEnterpriseItemPage';
import { SystemSportItemPage } from './containers/References/Sports/SystemSportItemPage';
import { SystemUnitItemPage } from './containers/References/Units/SystemUnitItemPage';
import { SystemNewsCategories } from './containers/References/NewsCategories/SystemNewsCategories';
import { SystemNewsCategoryForm } from './containers/References/NewsCategories/SystemNewsCategoriesForm';
import { SystemNotification } from './containers/Notification/SystemNotification';
import { SystemJobDescriptions } from './containers/References/JobDescriprion/SystemJobDescription';
import { SystemJobDescriptionForm } from './containers/References/JobDescriprion/SystemJobDescriptionForm';
import { SystemEvents } from './containers/Events/SystemEvents';
import { SystemEventsForm } from './containers/Events/SystemEventsForm';
import { SystemAssignApplications } from './containers/Application/SystemAssignApplications';
import { SystemOneOfThemAllApplication } from './containers/AllAplications/SystemOneOfThemAllApplication';
import { SystemOneOfThemAssignApplication } from './containers/Application/SystemOneOfThemAssignApplication';
import { SystemMedical } from './containers/Medical/SystemMedical';
import { SystemMedicalForm } from './containers/Medical/SystemMedicalForm';
import { SystemApplicationStatuses } from './containers/References/ApplicationStatuses/SystemApplicationStatuses';
import { SystemEducationsList } from './containers/Education/SystemEducationsList';
import { SystemEducationForm } from './containers/Education/SystemEducationForm';
import { SystemFeedbackResponsible } from './containers/Feedback/SystemFeedbackResponsible';
import { SystemFeedbackResponsibleForm } from './containers/Feedback/SystemFeedbackResponsibleForm';
import { SystemNotificationTemplates } from './containers/Notification/SystemNotificationTemplates';
import { SystemNotificationTemplateForm } from './containers/Notification/SystemNotificationTemplateForm';
import { SystemUserRole } from './containers/Users/SystemUserRole';
import Page404 from '../components/Errors/404';
import { SystemPrivilegesList } from './containers/Priveleges/SystemPrivilegesList';
import { SystemPrivilegesForm } from './containers/Priveleges/SystemPrivilegesForm';
import { SystemReportApplication } from './containers/Reports/Application';
import { SystemReportInsurance } from './containers/Reports/Insurance';
import { SystemReportSport } from './containers/Reports/Sport';
import { SystemReportHousing } from './containers/Reports/Housing';
import { SystemReferralList } from './containers/ReferralProgram/SystemReferralList';
import { SystemReferralItem } from './containers/ReferralProgram/SystemReferralItem';
import { SystemPageReferralProgram } from './containers/References/ReferralProgram/SystemPageReferralProgram';
import { SystemBonusProgramUsers } from './containers/BonusProgram/BonusProgramUsers/SystemBonusProgramUsers';
import { SystemBonusProgramUser } from './containers/BonusProgram/BonusProgramUsers/SystemBonusProgramUser';
import { SystemBonusProgramPrizeList } from './containers/BonusProgram/BonusProgramPrizes/SystemBonusProgramPrizeList';
import {
  SystemBonusProgramPrizeItemForm,
} from './containers/BonusProgram/BonusProgramPrizes/SystemBonusProgramPrizeItemForm';
import { SystemPageBonusProgram } from './containers/References/BonusProgram/SystemPageBonusProgram';
import {
  SystemBonusProgramTagList,
} from './containers/References/BonusProgram/SystemBonusProgramTagList';
import { SystemBonusProgramTagItemForm } from './containers/References/BonusProgram/SystemBonusProgramTagItemForm';
import { SystemBonusProgramEventList } from './containers/BonusProgram/BonusProgramEvents/SystemBonusProgramEventList';
import {
  SystemBonusProgramEventItemForm,
} from './containers/BonusProgram/BonusProgramEvents/SystemBonusProgramEventItemForm';
import { SystemUserEnterpriseSettings } from './containers/UserEnterprises/SystemUserEnterpriseSettings';

export const system404PageRoute = getSystemPath('/*');

/** роуты админской части. для настройки боковой панели иди в _nav.ts */
export const systemRoutes: CRoutes = [
  { path: getSystemPath(), component: () => <Redirect to={getSystemPath('/news')} /> },

  { path: getSystemPath('/news'), component: SystemNews },
  { path: getSystemPath('/news/create'), component: SystemNewsCreate },
  { path: getSystemPath('/news/:id'), component: SystemNewsUpdate },

  { path: getSystemPath('/references/newscategory'), component: SystemNewsCategories },
  { path: getSystemPath('/references/newscategory/:id'), component: SystemNewsCategoryForm },

  { path: getSystemPath('/users'), component: SystemUsers },
  { path: getSystemPath('/user-role/:id'), component: SystemUserRole },
  { path: getSystemPath('/user-settings/:id'), component: SystemUserSettings },
  { path: getSystemPath('/user-enterprises-settings/:id'), component: SystemUserEnterpriseSettings },

  { path: getSystemPath('/references/insurance'), component: SystemRefInsurance },
  { path: getSystemPath('/references/insurance/:id'), component: SystemRefInsuranceForm },

  { path: getSystemPath('/references/busstop'), component: SystemBusStops },
  { path: getSystemPath('/references/busstop/:id'), component: SystemBusStopForm },

  { path: getSystemPath('/references/enterprise'), component: SystemEnterprises },
  { path: getSystemPath('/references/enterprise/:id'), component: SystemEnterpriseItemPage },

  { path: getSystemPath('/references/unit'), component: SystemUnits },
  { path: getSystemPath('/references/unit/:id'), component: SystemUnitItemPage },

  { path: getSystemPath('/references/sport'), component: SystemSports },
  { path: getSystemPath('/references/sport/:id'), component: SystemSportItemPage },

  { path: getSystemPath('/references/jobdescription'), component: SystemJobDescriptions },
  { path: getSystemPath('/references/jobdescription/:id'), component: SystemJobDescriptionForm },

  { path: getSystemPath('/references/application-statuses'), component: SystemApplicationStatuses },
  { path: getSystemPath('/references/page-agreement'), component: SystemPageAgreement },

  { path: getSystemPath('/references/referral-program'), component: SystemPageReferralProgram },

  { path: getSystemPath('/references/bonus-program'), component: SystemPageBonusProgram },
  { path: getSystemPath('/references/bonus-program-tags'), component: SystemBonusProgramTagList },
  { path: getSystemPath('/references/bonus-program-tags/:id'), component: SystemBonusProgramTagItemForm },

  { path: getSystemPath('/roles'), component: SystemRoles },
  { path: getSystemPath('/roles/:rolename'), component: SystemRolesForm },

  { path: getSystemPath('/polls/all'), component: () => <SystemPolls all /> },
  { path: getSystemPath('/polls/my'), component: () => <SystemPolls /> },
  { path: getSystemPath('/polls/create'), component: SystemPollsForm },
  { path: getSystemPath('/polls/:id'), component: SystemPollsForm },

  { path: getSystemPath('/notifications'), component: SystemNotification },
  { path: getSystemPath('/notifications-templates'), component: SystemNotificationTemplates },
  { path: getSystemPath('/notifications-templates/:id/:action'), component: SystemNotificationTemplateForm },

  { path: getSystemPath('/events'), component: SystemEvents },
  { path: getSystemPath('/events/:id'), component: SystemEventsForm },

  { path: getSystemPath('/assign-applications'), component: SystemAssignApplications },
  { path: getSystemPath('/assign-applications/:id/:typeId'), component: SystemOneOfThemAssignApplication },

  { path: getSystemPath('/all-applications'), component: SystemAllApplicationsList },
  { path: getSystemPath('/all-applications/:id/:typeId'), component: SystemOneOfThemAllApplication },

  { path: getSystemPath('/medical'), component: SystemMedical },
  { path: getSystemPath('/medical/:id'), component: SystemMedicalForm },

  { path: getSystemPath('/education'), component: SystemEducationsList },
  { path: getSystemPath('/education/:id'), component: SystemEducationForm },

  { path: getSystemPath('/priveleges'), component: SystemPrivilegesList },
  { path: getSystemPath('/priveleges/:id'), component: SystemPrivilegesForm },

  { path: getSystemPath('/reports/application'), component: SystemReportApplication },
  { path: getSystemPath('/reports/insurance'), component: SystemReportInsurance },
  { path: getSystemPath('/reports/polls'), component: () => <SystemPolls all report /> },
  { path: getSystemPath('/reports/sport'), component: SystemReportSport },
  { path: getSystemPath('/reports/housing'), component: SystemReportHousing },

  { path: getSystemPath('/feedback-responsible'), component: SystemFeedbackResponsible },
  { path: getSystemPath('/feedback-responsible/:id'), component: SystemFeedbackResponsibleForm },

  { path: getSystemPath('/construct-application/category'), component: ConstructApplicationCategoryList },
  { path: getSystemPath('/construct-application/category/:id'), component: ConstructApplicationCategoryForm },
  { path: getSystemPath('/construct-application/template'), component: ConstructApplicationTemplateList },
  { path: getSystemPath('/construct-application/template/:id'), component: ConstructApplicationTemplateForm },
  { path: getSystemPath('/construct-application/type'), component: ConstructApplicationTypeList },
  { path: getSystemPath('/construct-application/type/:id'), component: ConstructApplicationPage },

  { path: getSystemPath('/transport'), component: SystemTransportList },
  { path: getSystemPath('/transport/:id'), component: SystemTransportForm },

  { path: getSystemPath('/partners/category'), component: SystemPartnersCategoryList },
  { path: getSystemPath('/partners/category/:id?'), component: SystemPartnersCategoryForm },
  { path: getSystemPath('/partners'), component: SystemPartnersList },
  { path: getSystemPath('/partners/:id?'), component: SystemPartnersForm },

  { path: getSystemPath('/referral-program'), component: SystemReferralList },
  { path: getSystemPath('/referral-program/:id'), component: SystemReferralItem },

  { path: getSystemPath('/bonus-program/users'), component: SystemBonusProgramUsers },
  { path: getSystemPath('/bonus-program/user/:id'), component: SystemBonusProgramUser },
  { path: getSystemPath('/bonus-program/prizes'), component: SystemBonusProgramPrizeList },
  { path: getSystemPath('/bonus-program/prize/:id'), component: SystemBonusProgramPrizeItemForm },
  { path: getSystemPath('/bonus-program/events'), component: SystemBonusProgramEventList },
  { path: getSystemPath('/bonus-program/event/:id'), component: SystemBonusProgramEventItemForm },

  { path: system404PageRoute, component: Page404 },
];
