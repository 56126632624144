import { baseApiFn } from '../utils/baseApiFn';
import { httpGet, httpPut } from '../utils/http';
import { IPollModel, PollModel } from '../models/Polls/PollModel';
import { ISaveOfflinePollAnswers, ISavePollAnswers } from '../models/Polls/PollsQuestionTypesModel';
import { PollAnswersModelType } from '../models/Polls/PollAnswersModel';


/** получить опрос */
export const getPollById = async (id: number) => baseApiFn<IPollModel>(async () => {
  const response = await httpGet(`/public/poll/${id}`);
  return new PollModel(response);
});

/** получить опрос. системный метод */
export const getOfflinePollById = async (id: number) => baseApiFn<IPollModel>(async () => {
  const response = await httpGet(`/system/poll/${id}`);
  return new PollModel(response);
});

/** сохранить ответы на опрос */
export const savePollAnswers = async (id: number, data: ISavePollAnswers) => baseApiFn<true>(
  httpPut(`/public/poll/user-answers/${id}`, data),
);

/** сохранить оффлайн-ответы на опрос */
export const saveOfflinePollAnswers = async (id: number, data: ISaveOfflinePollAnswers) => baseApiFn<true>(
  httpPut(`/system/poll/offline-user-answers/${id}`, data),
);

/** получить ответы на опрос. без модели, т.к. массив объектов */
export const getPollAnswers = async (id: number) => baseApiFn<PollAnswersModelType>(
  httpGet(`/public/poll/user-answers/${id}`),
);
