import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import invariant from 'invariant';

export const onLoading = (state: any, name: string) => ({
  ...state,
  [`${name}IsLoading`]: true,
});

/** апи запрос успешен. данные внести, загрузка завершена */
export const onLoad = (state: any, payload: any, name: string) => {
  invariant(!!payload[name], `некорректное значение редюсера. ожидается "${name}"`);

  return {
    ...state,
    [name]: payload[name],
    [`${name}IsLoading`]: false,
  };
};

/** при ошибке апи. данные не трогать, загрузка завершена */
export const onFail = (state: any, name: string) => ({
  ...state,
  [`${name}IsLoading`]: false,
});


/**
 * name - вложенность через точку (obj.attr.innerAttr.value)
 * при error|fail - не передавать payload (null)
 */
const baseDeepOn = (state: any, payload: any, name: string, loading = false) => {
  const stateAttrName: string | undefined = name.split('.').pop();
  if (stateAttrName) {
    const newState = cloneDeep(state);
    if (payload) {
      set(newState, name, payload[stateAttrName]);
    }
    set(newState, `${name}IsLoading`, loading);
    return newState;
  }
  return state;
};

/**
 * для вложенных объектов
 * @see onLoading
 */
export const deepOnLoading = (state: any, name: string) => baseDeepOn(state, null, name, true);

/**
 * для вложенных объектов
 * @see onLoad
 */
export const deepOnLoad = (state: any, payload: any, name: string) => baseDeepOn(state, payload, name);

/**
 * для вложенных объектов
 * @see onFail
 */
export const deepOnFail = (state: any, name: string) => baseDeepOn(state, null, name);
