import React, { useEffect } from 'react';

import { UiFileUploader } from 'components/common/FileUploader';
import { defaultFileExtensions } from 'config/system';

type Props = {
  inputName: string;
  title: string;
  required: boolean;
  register: (s?: string) => void;
  setValue: any;
  getValues: (s?: any) => any;
  setError: (s?: any, e?: any) => any;
  clearError: (s?: string) => any;
}


export const RequiredFileUploader = ({
  title, getValues, setValue, inputName, setError, required, register, clearError,
}: Props) => {
  const file = getValues(inputName);
  useEffect(() => {
    register(inputName);
  }, [register, inputName]);

  useEffect(() => {
    if (required && !(file instanceof File)) {
      setError(inputName, 'noFile');
    } else {
      clearError(inputName);
    }
  }, [getValues, setValue, inputName, setError, required, clearError, file]);

  return (
    <div className="pt-3">
      {title}
      <UiFileUploader
        onChange={(files: File[]) => setValue(inputName, files[0])}
        accept={defaultFileExtensions}
        maxFilesCount={1}
      />
    </div>
  );
};
