import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import { SYSTEM_EDUCATION_GET_LIST_START } from './constants';
import { systemEducationGetListFailAction, systemEducationGetListOkAction } from './actions';
import { SystemEducationListModel } from '../../../models/Education/SystemEducationListModel';


function* systemEducationGetListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/education', filter);

    yield put(systemEducationGetListOkAction(new SystemEducationListModel(response)));
  }, systemEducationGetListFailAction);
}


export function* systemEducationSagas() {
  yield takeLatest(SYSTEM_EDUCATION_GET_LIST_START, systemEducationGetListSaga);
}
