import { CDate } from '../../utils/CDate';
import { BACKEND_DATE_FORMAT } from '../../config/system';
import { IWithPages, WithPagesModel } from '../WithPagesModel';
import { DefaultFilterType } from '../common';
import { NotificationBase } from './NotificationBase';


export interface INotificationModel {
  readonly id: number;
  readonly action: {code: string; name: string};
  readonly subject: string;
  readonly text: string;
  readonly created_at: Date;
  readonly date_blocked: Date | null;
  readonly is_blocked: boolean;
  is_read: boolean;
  readonly id_entity: number | null;
  readonly typeApplication: number;

  setRead: (v: boolean) => void;
  readonly notify_text: { message: string; link: string | null };
}


export class NotificationModel extends NotificationBase implements INotificationModel {
  readonly action: { code: string; name: string };
  readonly created_at: Date;
  readonly date_blocked: Date | null;
  readonly id: number;
  readonly id_entity: number | null;
  readonly typeApplication: number;
  readonly is_blocked: boolean;
  is_read: boolean;
  readonly subject: string;
  readonly text: string;

  constructor(params: any = {}) {
    super(params);

    this.id = params.id;
    this.action = params.action;
    this.subject = params.subject;
    this.text = params.text;
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.date_blocked = params.date_blocked ? CDate.parse(params.date_blocked, 'yyyy-MM-dd HH:mm') : null;
    this.is_blocked = params.is_blocked;
    this.is_read = params.is_read;
    this.id_entity = params.id_entity;
    this.typeApplication = params.typeApplication;
  }

  setRead(readVal: boolean) {
    this.is_read = readVal;
  }

  get notify_text() {
    return this.notification_data(this.action.code);
  }
}


export type NotificationsFilterType = {
  is_read?: '0' | '1';
  is_blocked?: boolean;
} & DefaultFilterType;


export interface INotificationsModel extends IWithPages {
  data: INotificationModel[];
}


export class NotificationsModel extends WithPagesModel implements INotificationsModel {
  data: INotificationModel[];

  constructor(params: any = {}) {
    super(params);

    this.data = this.initializeData(params.data, NotificationModel);
  }
}
