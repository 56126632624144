import { DefaultFilterType } from '../common';

export interface NewsCategoryFilter extends DefaultFilterType {
  sort?: string; // через , просто названия полей
  name?: string;
}


export interface INewsCategoryItemModel {
  id: number;
  name: string;
}

export class NewsCategoryItemModel implements INewsCategoryItemModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
  }
}
