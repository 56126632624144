import React, { memo } from 'react';
import { ValidationOptions } from 'react-hook-form';
import get from 'lodash/get';


type Props = {
  name: string;
  options: { value: string; label: string }[]; // value: string - все равно к строке приведется. лучше явно задать
  register: (s: any) => any;

  errors?: {[s: string]: any};
  required?: boolean;
}

export const UiRadioGroupComponent = ({
  name, options, register, required = false, errors,
}: Props) => {
  const localValidation: ValidationOptions = {};
  if (required) {
    localValidation.required = 'Обязательное поле';
  }

  const error = get(errors, name, false);

  return (
    <>
      {options.map((option, index) => (
        <div className="form-check" key={option.label}>
          <input
            className="form-check-input"
            type="radio"
            name={name}
            id={`radio-${name}-${index}`}
            ref={register(localValidation)}
            value={option.value}
          />
          <label className="form-check-label" htmlFor={`radio-${name}-${index}`}>{option.label}</label>
        </div>
      ))}
      {error && <div className="form-control-feedback form-error-block">{error.message}</div>}
    </>
  );
};

export const UiRadioGroup = memo(UiRadioGroupComponent);
