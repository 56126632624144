import {
  httpDelete, httpGet, httpPost, httpPut,
} from '../../utils/http';
import { IUserRightModel, UserRightModel } from '../../models/User/UserRightsModel';
import { UpdateRbacRoleType } from '../../models/Roles/RolesModel';
import { baseApiFn } from '../../utils/baseApiFn';


/** создание роли */
export const createSystemRbacRole = async (model: UpdateRbacRoleType) => baseApiFn<true>(
  httpPost('system/rbac/role', model),
);

/** получить роль по имени (role.name) */
export const getSystemRoleByName = async (name: string) => baseApiFn<IUserRightModel>(async () => {
  const response = await httpGet(`system/rbac/role/${name}`);
  return new UserRightModel(response);
});

/** изменение роли */
export const updateSystemRbacRole = async (name: string, model: UpdateRbacRoleType) => baseApiFn<true>(
  httpPut(`system/rbac/role/${name}`, model),
);

/** удаление роли */
export const deleteSystemRbacRole = async (roleName: string) => baseApiFn<true>(
  httpDelete(`system/rbac/role/${roleName}`),
);
