import {
  httpDelete, httpGet, httpPost, httpPut,
} from 'utils/http';
import { baseApiFn } from 'utils/baseApiFn';
import { maxValueToGetAllDataWithPagination } from 'config/system';
import {
  ISystemBonusProgramPrizeItemFormModel, ISystemBonusProgramPrizeItemModel,
  ISystemBonusProgramPrizeListModel,
} from 'models/BonusProgram/Prize/interfaces';
import {
  SystemBonusProgramPrizeItemModel,
  SystemBonusProgramPrizeListModel,
} from 'models/BonusProgram/Prize/SystemBonusProgramPrizeModel';
import {
  ISystemBonusProgramOrderFormModel,
  ISystemBonusProgramOrderItemModel,
} from 'models/BonusProgram/Order/interfaces';
import {
  ISystemBonusProgramTagItemFormModel,
  ISystemBonusProgramTagItemModel, ISystemBonusProgramTagListModel, ISystemBonusProgramUserTagListModel,
  ISystemBonusProgramUserTagsFormModel,
} from 'models/BonusProgram/Tag/interfaces';
import {
  SystemBonusProgramTagListModel,
  SystemBonusProgramUserTagListModel, SystemBonusProgramTagItemModel,
} from 'models/BonusProgram/Tag/SystemBonusProgramTagModel';
import { SystemBonusProgramEventItemModel } from 'models/BonusProgram/Event/SystemBonusProgramEventModel';
import {
  ISystemBonusProgramEventItemFormModel,
  ISystemBonusProgramEventItemModel,
} from 'models/BonusProgram/Event/interfaces';

/** Получить список призов доступных пользователю по цене */
export const getSystemBonusProgramAvailablePrizeList = async (
  user_id: number,
) => baseApiFn<ISystemBonusProgramPrizeListModel>(
  async () => {
    const response = await httpGet(`/system/bonus-program/prize/available/${user_id}`, {
      'per-page': maxValueToGetAllDataWithPagination,
      sort: 'priority',
    });
    return new SystemBonusProgramPrizeListModel(response);
  },
);

/** Выдать приз пользователю */
export const givePrizeToUserSystemBonusProgram = async (
  model: ISystemBonusProgramOrderFormModel,
) => baseApiFn<ISystemBonusProgramOrderItemModel>(
  httpPost(`/system/bonus-program/order`, model),
);

/** Получить приз по его id */
export const getSystemBonusProgramPrizeItemById = async (
  id: number,
) => baseApiFn<ISystemBonusProgramPrizeItemModel>(
  async () => {
    const response = await httpGet(`/system/bonus-program/prize/${id}`);
    return new SystemBonusProgramPrizeItemModel(response);
  },
);

/** Удалить приз */
export const deleteSystemBonusProgramPrizeItem = async (
  id: number,
) => baseApiFn<true>(httpDelete(`/system/bonus-program/prize/${id}`));

/** Создать приз */
export const createSystemBonusProgramPrizeItem = async (
  model: ISystemBonusProgramPrizeItemFormModel,
) => baseApiFn<ISystemBonusProgramPrizeItemModel>(
  httpPost('/system/bonus-program/prize', model),
);

/** Редактировать приз */
export const updateSystemBonusProgramPrizeItem = async (
  id: number,
  model: ISystemBonusProgramPrizeItemFormModel,
) => baseApiFn<ISystemBonusProgramPrizeItemModel>(
  httpPut(`/system/bonus-program/prize/${id}`, model),
);

/** Получить список всех тегов */
export const getSystemBonusProgramTagList = async () => baseApiFn<ISystemBonusProgramTagListModel>(
  async () => {
    const response = await httpGet('/system/bonus-program/tag', {
      'per-page': maxValueToGetAllDataWithPagination,
      sort: 'id',
    });
    return new SystemBonusProgramTagListModel(response);
  },
);

/** Получить список тегов пользователя */
export const getSystemBonusProgramUserTagList = async (
  userId: number,
) => baseApiFn<ISystemBonusProgramUserTagListModel >(
  async () => {
    const response = await httpGet(`/system/bonus-program/tag/user/${userId}`);
    return new SystemBonusProgramUserTagListModel(response);
  },
);

/** Получить тег по его id */
export const getSystemBonusProgramTagItemById = async (
  id: number,
) => baseApiFn<ISystemBonusProgramTagItemModel>(
  async () => {
    const response = await httpGet(`/system/bonus-program/tag/${id}`);
    return new SystemBonusProgramTagItemModel(response);
  },
);

/** Удалить тег */
export const deleteSystemBonusProgramTagItem = async (
  id: number,
) => baseApiFn<true>(httpDelete(`/system/bonus-program/tag/${id}`));

/** Создать тег */
export const createSystemBonusProgramTagItem = async (
  model: ISystemBonusProgramTagItemFormModel,
) => baseApiFn<ISystemBonusProgramTagItemModel>(
  httpPost('/system/bonus-program/tag', model),
);

/** Редактировать тег */
export const updateSystemBonusProgramTagItem = async (
  id: number,
  model: ISystemBonusProgramTagItemFormModel,
) => baseApiFn<ISystemBonusProgramTagItemModel>(
  httpPut(`/system/bonus-program/tag/${id}`, model),
);

/** Редактирование тегов пользователя */
export const updateSystemBonusProgramUserTags = async (
  userId: number,
  model: ISystemBonusProgramUserTagsFormModel,
) => baseApiFn<ISystemBonusProgramTagItemModel>(
  httpPut(`/system/bonus-program/tag/user/${userId}`, model),
);

/** Получить событие по его id */
export const getSystemBonusProgramEventItemById = async (
  id: number,
) => baseApiFn<ISystemBonusProgramEventItemModel>(
  async () => {
    const response = await httpGet(`/system/bonus-program/event/${id}`);
    return new SystemBonusProgramEventItemModel(response);
  },
);

/** Редактировать событие */
export const updateSystemBonusProgramEventItem = async (
  id: number,
  model: ISystemBonusProgramEventItemFormModel,
) => baseApiFn<ISystemBonusProgramEventItemModel>(
  httpPut(`/system/bonus-program/event/${id}`, model),
);
