import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDataPage } from 'utils/hooks/useDataPage';
import { CustomTable } from 'components/common/Table';
import { ISystemUserListModel, SystemUsersFilterType } from 'models/Users/interfaces';
import { SystemBonusProgramUsersFilter } from 'systemModule/components/BonusProgram/BonusProgramUsers/Filter';

type Props = {
  data: ISystemUserListModel;
  isLoading: boolean;
  getData: (filter: SystemUsersFilterType) => void;
}

export const SystemBonusProgramUsersComponent = ({ data, isLoading, getData }: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(data.page, data.limit, getData);

  return (
    <>
      <h3>Список пользователей</h3>

      <SystemBonusProgramUsersFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

      <CustomTable
        onChangePage={setPage}
        currentPage={data.page}
        sizePerPage={data.limit}
        amountOfPages={data.amountOfPages}
        setSizePerPage={[setLimit, setPage]}
        isLoading={isLoading}
        tableHeaders={[
          { name: 'ID' },
          { name: 'ФИО' },
          { name: 'Телефон' },
          { name: 'Действия' },
        ]}
      >
        {data.data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={`user/${item.id}`}>{item.id}</Link>
            </td>
            <td>
              {item.fio}
            </td>
            <td>
              {item.phone}
            </td>
            <td>
              <Button
                className="w-100"
                color="primary"
                tag={Link}
                to={`user/${item.id}`}
              >
                Выбрать
              </Button>
            </td>
          </tr>
        ))}
      </CustomTable>
    </>
  );
};
