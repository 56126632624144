import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CustomTable } from '../../../../components/common/Table';
import { IBusStopsModel } from '../../../../models/BusStops/BusStops';
import { useDataPage } from '../../../../utils/hooks/useDataPage';
import { ICurrentUserModel, UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import { BusStopsFilterType, ISystemBusStopModel } from '../../../../models/References/BusStopsModel';
import { SystemBusStopsFilter } from './Filter';


type Props = {
  busStops: IBusStopsModel;
  busStopsIsLoading: boolean;
  getData: (filter: BusStopsFilterType) => void;
  onDelete: (item: ISystemBusStopModel) => void;
}

export const SystemBusStopsComponent = ({
  busStops, busStopsIsLoading, getData, onDelete,
}: Props) => {
  const {
    setLimit, setPage, onResetFilter, onSubmitFilter,
  } = useDataPage(busStops.page, busStops.limit, getData);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cReferenceBusStop);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dReferenceBusStop);

  return (
    <div>
      <Card>
        <CardBody>
          <h3>Список остановок служебного транспорта</h3>

          <SystemBusStopsFilter onSubmit={onSubmitFilter} onReset={onResetFilter} />

          <CustomTable
            onChangePage={setPage}
            currentPage={busStops.page}
            sizePerPage={busStops.limit}
            amountOfPages={busStops.amountOfPages}
            setSizePerPage={[setLimit, setPage]}
            isLoading={busStopsIsLoading}
            customButtons={canCreate && (
              <Button tag={Link} color="primary" to="busstop/create">Создать остановку</Button>
            )}
            tableHeaders={[
              { name: 'ID' },
              { name: 'Название остановки' },
              { name: 'Действия' },
            ]}
          >
            {busStops.data.map((item) => (
              <tr key={item.id}>
                <td>
                  <Link to={`busstop/${item.id}`}>{item.id}</Link>
                </td>
                <td>
                  <Link to={`busstop/${item.id}`}>{item.name}</Link>
                </td>
                <td>
                  {canDelete && (
                    <Button type="button" color="danger" onClick={() => onDelete(item)}>
                      {t('common.form.delete')}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </CustomTable>
        </CardBody>
      </Card>
    </div>
  );
};
