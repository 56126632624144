import React, { memo, useState } from 'react';
import { Button } from 'reactstrap';
import { UiCheckbox } from './UI/Checkbox';
import { IEnterpriseModel } from '../../models/Enterprises/EnterpriseModel';

type Props = {
  enterpriseLikeAll: IEnterpriseModel[];
  currentEnterprise: IEnterpriseModel[];
  otherEnterprise: IEnterpriseModel[];
  register: () => void;
  isCheckedEnterprises: boolean;
}

export const CompactEnterprisesFilter = memo(({
  enterpriseLikeAll, currentEnterprise, otherEnterprise, register, isCheckedEnterprises,
}: Props) => {
  const [visibleOtherEnterprises, setVisibleOtherEnterprises] = useState(false);
  const handleClickButton = () => {
    setVisibleOtherEnterprises(true);
  };

  return (
    <div className="section-block">
      {
        enterpriseLikeAll.map((field) => (
          <UiCheckbox
            key={field.name}
            label={field.name}
            register={register}
            name={`enterprises.ent_${field.id}`}
          />
        ))
      }
      {
        currentEnterprise.map((field) => (
          <UiCheckbox
            key={field.name}
            label={field.name}
            register={register}
            name={`enterprises.ent_${field.id}`}
          />
        ))
      }
      {
        visibleOtherEnterprises || isCheckedEnterprises ? (
          otherEnterprise.map((field) => (
            <UiCheckbox
              key={field.name}
              label={field.name}
              register={register}
              name={`enterprises.ent_${field.id}`}
            />
          ))
        ) : (
          <div className="buttons-group buttons-group-responsive">
            <Button color="primary" onClick={handleClickButton}>
              Остальные
            </Button>
          </div>
        )
      }
    </div>
  );
});
