import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { InsurancesModel } from '../../models/Insurances/InsurancesModel';
import { SportModel } from '../../models/Sport/SportModel';
import { httpGet } from '../../utils/http';

import { baseSaga } from '../baseSaga';
import {
  refGetEducationFailAction, refGetEducationOkAction,
  refGetInsurancesFailAction,
  refGetInsurancesOkAction,
  refGetSportListFailAction,
  refGetSportListOkAction,
} from './actions';
import * as CONSTANTS from './constants';
import { EducationsModel } from '../../models/References/EducationsModel';
import { maxValueToGetAllDataWithPagination } from '../../config/system';

export function* referencesGetSportListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/references/sport', filter);

    yield put(refGetSportListOkAction(new SportModel(response)));
  }, refGetSportListFailAction);
}

export function* referencesGetInsuranceListSaga() {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/references/insurance');

    yield put(refGetInsurancesOkAction(new InsurancesModel(response)));
  }, refGetInsurancesFailAction);
}

export function* referencesGetEducationTypesSaga() {
  yield baseSaga(function* () {
    const response = yield httpGet('/public/references/type-education', {
      'per-page': maxValueToGetAllDataWithPagination,
    });

    yield put(refGetEducationOkAction(new EducationsModel(response)));
  }, refGetEducationFailAction);
}


export function* referencesSagas() {
  yield takeLatest(CONSTANTS.REFERENCES_GET_SPORT_LIST_START, referencesGetSportListSaga);
  yield takeLatest(CONSTANTS.REFERENCES_GET_INSURANCES_START, referencesGetInsuranceListSaga);
  yield takeLatest(CONSTANTS.REFERENCES_GET_EDUCATION_TYPES_START, referencesGetEducationTypesSaga);
}
