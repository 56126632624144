import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';

import { UiDatePicker } from 'components/common/Dates/UiDatePicker';


type Props = {
  onSubmit: (d: Date) => void;
  defaultValue: Date;
}

export const PartDateForm = ({ onSubmit, defaultValue }: Props) => {
  const {
    watch, handleSubmit, formState, setValue, register,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: defaultValue,
    },
  });

  const onSubmitLocal = useCallback(({ value }) => onSubmit(value), [onSubmit]);

  return (
    <form className="form-with-btn" onSubmit={handleSubmit(onSubmitLocal)}>
      <div className="form-wrapper_content">
        <UiDatePicker watch={watch} name="value" setValue={setValue} register={register} />
      </div>
      <div className="form-wrapper_btn">
        <div className="buttons-group buttons-group-responsive">
          <Button
            color="primary"
            type="submit"
            disabled={!formState.isValid}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </form>
  );
};
