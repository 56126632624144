import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../../utils/reducer';
import { ISystemSportsModel, SystemSportsModel } from '../../../../models/References/SportModel';
import {
  SYSTEM_REFERENCES_GET_SPORTS_FAIL,
  SYSTEM_REFERENCES_GET_SPORTS_OK,
  SYSTEM_REFERENCES_GET_SPORTS_START,
} from './constants';

export type systemSportsReducerType = {
  sports: ISystemSportsModel;
  sportsIsLoading: boolean;
}

const initialState: systemSportsReducerType = {
  sports: new SystemSportsModel(),
  sportsIsLoading: true,
};

export const storeName = 'sports';

export const systemSportsReducer = {
  [storeName]: (state: systemSportsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_REFERENCES_GET_SPORTS_START:
        return onLoading(state, 'sports');
      case SYSTEM_REFERENCES_GET_SPORTS_OK:
        return onLoad(state, payload, 'sports');
      case SYSTEM_REFERENCES_GET_SPORTS_FAIL:
        return onFail(state, 'sports');

      default:
        return state;
    }
  },
};
