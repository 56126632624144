import React from 'react';
import { useTranslation } from 'react-i18next';

import { IApplicationStatusesModel, IApplicationStatusModel } from '../../../../models/Application/Status/interfaces';
import { SimpleTable } from '../../../../components/common/Table/SimpleTable';

type Props = {
  data: IApplicationStatusesModel;
  isLoading: boolean;
}

export const ApplicationStatusesComponent = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <SimpleTable
      tableHeaders={[
        { name: 'ID' },
        { name: t('application.table.status') },
      ]}
      isLoading={isLoading}
    >
      {data.map((items: IApplicationStatusModel) => (
        <tr key={items.id}>
          <td>{items.id}</td>
          <td>{items.name}</td>
        </tr>
      ))}
    </SimpleTable>
  );
};
