import {
  systemConstructApplicationReducer,
  systemConstructApplicationReducerType,
} from 'systemModule/containers/ConstructApplication/reducers';
import { systemPartnersReducer, systemPartnersReducerType } from 'systemModule/containers/Partners/reducers';
import { systemTransportReducer, systemTransportReducerType } from 'systemModule/containers/Transport/reducer';
import { systemNewsReducer, systemNewsReducerType } from '../containers/News/reducers';
import { systemGlobalReducer, SystemGlobalReducerType } from './systemGlobalReducers';
import { systemUsersReducer, systemUsersReducerType } from '../containers/Users/reducers';
import { systemReferencesReducer, systemReferencesReducerType } from '../containers/References/reducers';
import { systemPollsReducer, systemPollsReducerType } from '../containers/Polls/reducers';
import { systemBusStopsReducer, systemBusStopsReducerType } from '../containers/References/BusStops/reducers';
import { systemEnterprisesReducer, systemEnterprisesReducerType } from '../containers/References/Enterprises/reducers';
import { systemUnitsReducer, systemUnitsReducerType } from '../containers/References/Units/reducers';
import { systemSportsReducer, systemSportsReducerType } from '../containers/References/Sports/reducers';
import {
  systemNewsCategoriesReducer,
  systemNewsCategoriesReducerType,
} from '../containers/References/NewsCategories/reducers';
import {
  systemJobDescriptionsReducer,
  systemJobDescriptionsReduceType,
} from '../containers/References/JobDescriprion/reducer';
import { systemEventsReducer, systemEventsReducerType } from '../containers/Events/reducers';
import { systemApplicationReducer, systemApplicationReducerType } from '../containers/Application/reducers';
import { systemMedicalReducer, systemMedicalReducerType } from '../containers/Medical/reducer';
import { systemEducationReducer, systemEducationReducerType } from '../containers/Education/reducers';
import { systemNotificationsReducer, systemNotificationsReducerType } from '../containers/Notification/reducers';
import { systemPrivilegesReducer, systemPrivilegesReducerType } from '../containers/Priveleges/reducers';
import { systemReferralProgramReducer, systemReferralProgramReducerType } from '../containers/ReferralProgram/reducers';
import { systemBonusProgramReducer, systemBonusProgramReducerType } from '../containers/BonusProgram/reducers';

export type SystemReducerType = {
  news: systemNewsReducerType;
  global: SystemGlobalReducerType;
  users: systemUsersReducerType;
  references: systemReferencesReducerType;
  polls: systemPollsReducerType;
  busStops: systemBusStopsReducerType;
  enterprises: systemEnterprisesReducerType;
  units: systemUnitsReducerType;
  sports: systemSportsReducerType;
  newsCategories: systemNewsCategoriesReducerType;
  jobDescriptions: systemJobDescriptionsReduceType;
  events: systemEventsReducerType;
  application: systemApplicationReducerType;
  medical: systemMedicalReducerType;
  education: systemEducationReducerType;
  notifications: systemNotificationsReducerType;
  privileges: systemPrivilegesReducerType;
  'construct-application': systemConstructApplicationReducerType;
  transport: systemTransportReducerType;
  partners: systemPartnersReducerType;
  referral: systemReferralProgramReducerType;
  bonusProgram: systemBonusProgramReducerType,
}

export const SystemReducer: { [K in keyof SystemReducerType]: any } = {
  ...systemNewsReducer,
  ...systemGlobalReducer,
  ...systemUsersReducer,
  ...systemReferencesReducer,
  ...systemPollsReducer,
  ...systemBusStopsReducer,
  ...systemEnterprisesReducer,
  ...systemUnitsReducer,
  ...systemSportsReducer,
  ...systemNewsCategoriesReducer,
  ...systemJobDescriptionsReducer,
  ...systemEventsReducer,
  ...systemApplicationReducer,
  ...systemMedicalReducer,
  ...systemEducationReducer,
  ...systemNotificationsReducer,
  ...systemPrivilegesReducer,
  ...systemConstructApplicationReducer,
  ...systemTransportReducer,
  ...systemPartnersReducer,
  ...systemReferralProgramReducer,
  ...systemBonusProgramReducer,
};
