import { useEffect, useState } from 'react';

/**
 * Отслеживает изменение ширины экрана браузера
 * @param maxWidth {number} - максимальная ширина, относительно которой происходит сравнение
 */
export const useCheckWindowSize = (maxWidth: number) => {
  const [state, setState] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setState(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return state <= maxWidth;
};
