import { FileType, prepareFilesOrNull } from 'models/common';
import { ISystemPartnersCategoryListItemModel } from 'models/Partners/SystemPartnerCategoryListModel';


export interface PartnersListItemModel {
  id: number;
  company_name: string;
  title: string;
  logo: FileType | null;
  categories: ISystemPartnersCategoryListItemModel[];
}

export type IPartnersListModel = PartnersListItemModel[]


export class PartnersListItemModel implements PartnersListItemModel {
  categories: ISystemPartnersCategoryListItemModel[];
  company_name: string;
  id: number;
  logo: FileType | null;
  title: string;

  constructor(params: any = {}) {
    this.categories = params.categories;
    this.company_name = params.company_name;
    this.id = params.id;
    this.logo = prepareFilesOrNull(params.logo);
    this.title = params.title;
  }
}
