import {
  IApplicationConstructCategoryList,
  IApplicationConstructCategoryTypeList,
} from 'models/Application/Construct/interfaces';


export const ApplicationConstructCategoryListModel = (
  data: Record<string, string>,
): IApplicationConstructCategoryList => Object.entries(data).map(([id, name]) => ({
  id: +id,
  name,
}));

export const ApplicationConstructCategoryTypeListModel = (
  data: IApplicationConstructCategoryTypeList,
): IApplicationConstructCategoryTypeList => data.map((type) => ({
  id: type.id,
  name: type.name,
  enterprise: type.enterprise,
  is_enabled: type.is_enabled,
}));
