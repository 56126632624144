import React, { useCallback, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import {
  IJobDescriptionsModel,
  JobDescriptionsFilterType,
  JobDescriptionsModel,
} from '../../models/JobDescription/JobDescription';
import { getEnterprisesApi, getJobDescriptions, getUnitsApi } from '../../api/references';
import { handleErrors } from '../../utils/errors';
import { JobDescriptionsComponent } from '../../components/JobDescriptions/JobDescriptions';
import { Loader } from '../../components/common/UI/Loaders';
import { usePromise } from '../../utils/hooks/usePromise';
import { IApplicationStatusesModel } from '../../models/Application/Status/interfaces';
import { IApplicationTypesModel } from '../../models/Application/Type/interfaces';
import { maxValueToGetAllDataWithPagination } from '../../config/system';

export const JobDescriptions = () => {
  // @ts-ignore
  const [models, modelsIsLoading] = usePromise<IApplicationStatusesModel, IApplicationTypesModel>(() => Promise.all([
    getEnterprisesApi({ 'per-page': maxValueToGetAllDataWithPagination }),
    getUnitsApi({ 'per-page': maxValueToGetAllDataWithPagination }),
  ]), true);

  const [enterpriseModel, unitModel] = [get(models, '[0]', []), get(models, '[1]', [])];

  const [data, setData] = useState<IJobDescriptionsModel>(new JobDescriptionsModel());
  const [isLoading, setIsLoading] = useState(false);
  const [clearLoad, setClearLoad] = useState(false);

  /** если кол-во страниц больше текущей, то есть еще данные */
  const hasMoreData: boolean = data.amountOfPages > data.page;

  const getData = useCallback(async (filter?: JobDescriptionsFilterType, append = false) => {
    setIsLoading(true);
    if (!append) {
      setClearLoad(true);
    }

    handleErrors(
      await getJobDescriptions(filter),
      'get',
      (model) => {
        if (append) {
          setData((prevState) => ({
            ...prevState,
            data: cloneDeep(prevState.data).concat(model.data),
          }));
        } else {
          setData(model);
        }
      },
    );

    setIsLoading(false);
  }, []);

  /** отслеживать полную загрузку при загрузке без подгрузки(чистая загрузка) */
  useEffect(() => {
    if (!isLoading && clearLoad) {
      setClearLoad(false);
    }
  }, [isLoading, setClearLoad, clearLoad]);

  if (modelsIsLoading) {
    return <Loader />;
  }

  return (
    <JobDescriptionsComponent
      isLoading={isLoading}
      hasMoreData={hasMoreData}
      clearLoad={clearLoad}
      data={data}
      getData={getData}
      enterprises={enterpriseModel.data}
      units={unitModel.data}
    />
  );
};
