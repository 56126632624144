import React from 'react';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { useTranslation } from 'react-i18next';

import { ParamTypes } from 'models/common';
import { usePromise } from '../../../utils/hooks/usePromise';
import { getPrivilegeItem } from '../../../api/privilege';
import { IPrivilegeItem } from '../../../models/Privilege/interfaces';
import { Loader } from '../../../components/common/UI/Loaders';
import { PrivilegeItem } from '../../../models/Privilege/PrivilegeItem';
import { EntityNotFound } from '../../../components/Errors/404';
import { ApplicationPrivilegeItemComponent } from '../../../components/Applications/Privilege/ApplicationPrivilegeItem';


export const ApplicationPrivilegeItem = () => {
  const { id, enterprise_id } = useParams<ParamTypes>();
  const numberId = toNumber(id);
  const numberEnterpriseId = toNumber(enterprise_id);

  const { t } = useTranslation();

  const [model, modelLoading] = usePromise<IPrivilegeItem>(
    () => getPrivilegeItem(numberId, numberEnterpriseId),
    !isNaN(numberId) && !isNaN(numberEnterpriseId),
    [numberId, numberEnterpriseId],
  );

  if (modelLoading) {
    return <Loader />;
  }
  if (model instanceof PrivilegeItem) {
    return <ApplicationPrivilegeItemComponent model={model} />;
  }
  return <EntityNotFound message={t('privilege.404')} />;
};
