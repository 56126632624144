import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { convertModelToOptions, SimpleSelectOptionType } from '../../utils/convertModelToOptions';
import { UiSelect } from '../common/UI/Select';
import { ISystemUnitModel } from '../../models/References/UnitsModel';
import { IEnterpriseModel } from '../../models/Enterprises/EnterpriseModel';
import { LocalJobDescriptionFilterType } from '../../models/JobDescription/interfaces';

const initialValues = { enterprise: null, unit: null };

type Props = {
  toggle: () => void;
  setFilterData: (filter: any) => void;
  enterprises: IEnterpriseModel[];
  units: ISystemUnitModel[];
  filterData: LocalJobDescriptionFilterType;
}

type FormValues = {
  enterprise: SimpleSelectOptionType<number> | null;
  unit: SimpleSelectOptionType<number> | null;
}


export const JobDescriptionFilter = memo(({
  toggle,
  setFilterData,
  enterprises,
  units,
  filterData,
}: Props) => {
  const {
    register, handleSubmit, watch, reset, setValue, errors,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: filterData,
  });

  const { t } = useTranslation();

  const watchEnterprise = watch('enterprise');

  const filteredUnits = watchEnterprise ? units.filter((unit) => unit.enterprise.id === watchEnterprise.value) : [];

  const onSubmit = ({ enterprise, unit }: FormValues) => {
    const filter: LocalJobDescriptionFilterType = {
      enterprise: enterprise || undefined,
      unit: unit || undefined,
    };
    setFilterData((prevState: LocalJobDescriptionFilterType) => ({ ...prevState, ...filter }));
    toggle();
  };

  return (
    <form autoComplete="off" className="modal-content" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-with-btn">
        <div className="form-wrapper_content">
          <h3>Предприятия</h3>
          <div className="section-block">
            <UiSelect
              register={register}
              name="enterprise"
              options={convertModelToOptions(enterprises)}
              errors={errors}
              setValue={setValue}
              allowEmptyValue
              value={watchEnterprise}
            />
          </div>

          {(!!watchEnterprise && watchEnterprise.value) && (
            <>
              <h3>Подразделения</h3>
              <div className="section-block">
                <UiSelect
                  register={register}
                  setValue={setValue}
                  name="unit"
                  errors={errors}
                  options={convertModelToOptions(filteredUnits)}
                  allowEmptyValue
                  value={watch('unit')}
                />
              </div>
            </>
          )}
        </div>
        <div className="form-wrapper_btn">
          <div className="buttons-group buttons-group-responsive">
            <Button color="primary" type="submit">
              {t('common.filter.apply')}
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => reset(initialValues)}
              className="btn--clear-filter"
            >
              {t('common.form.clear_filter')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
});
