import { IInsurancesModel, InsuranceFilterType } from '../../models/Insurances/InsurancesModel';
import { ISportModel, SportFilterType } from '../../models/Sport/SportModel';
import { dataAction, dataErrorAction, dataSuccessAction } from '../actions';
import * as CONSTANTS from './constants';
import { DefaultFilterType } from '../../models/common';
import { IEducationsModel } from '../../models/References/EducationsModel';


export const refGetSportListStartAction = (filter?: SportFilterType) => dataAction(
  CONSTANTS.REFERENCES_GET_SPORT_LIST_START,
)({ filter });
export const refGetSportListOkAction = (sport: ISportModel) => dataSuccessAction(
  CONSTANTS.REFERENCES_GET_SPORT_LIST_OK,
)({ sport });
export const refGetSportListFailAction = dataErrorAction(CONSTANTS.REFERENCES_GET_SPORT_LIST_FAIL);


export const refGetInsurancesStartAction = (filter?: InsuranceFilterType) => dataAction(
  CONSTANTS.REFERENCES_GET_INSURANCES_START,
)({ filter });
export const refGetInsurancesOkAction = (insurance: IInsurancesModel) => dataSuccessAction(
  CONSTANTS.REFERENCES_GET_INSURANCES_OK,
)({ insurance });
export const refGetInsurancesFailAction = dataErrorAction(CONSTANTS.REFERENCES_GET_INSURANCES_FAIL);


export const refGetEducationStartAction = (filter?: DefaultFilterType) => dataAction(
  CONSTANTS.REFERENCES_GET_EDUCATION_TYPES_START,
)({ filter });
export const refGetEducationOkAction = (educationTypes: IEducationsModel) => dataSuccessAction(
  CONSTANTS.REFERENCES_GET_EDUCATION_TYPES_OK,
)({ educationTypes });
export const refGetEducationFailAction = dataErrorAction(CONSTANTS.REFERENCES_GET_EDUCATION_TYPES_FAIL);
