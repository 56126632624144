import { AnyAction } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { baseSaga } from '../../../../store/baseSaga';
import { httpGet } from '../../../../utils/http';
import { SystemNewsCategoriesModel } from '../../../../models/References/NewsCategoriesModel';
import { systemGetNewsCategoriesFailAction, systemGetNewsCategoriesOkAction } from './actions';
import { SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_START } from './constants';

function* systemGetNewsCategoriesSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/news/category', { sort: 'id', ...filter });

    yield put(systemGetNewsCategoriesOkAction(new SystemNewsCategoriesModel(response)));
  }, systemGetNewsCategoriesFailAction);
}


export function* systemNewsCategoriesSagas() {
  yield takeLatest(SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_START, systemGetNewsCategoriesSaga);
}
