import React from 'react';
import { UiInput } from '../common/UI/Input';
import { UiDatePicker } from '../common/Dates/UiDatePicker';
import { UiSelect } from '../common/UI/Select';
import { UiCheckBoxGroup } from '../common/UI/CheckboxGroup';
import { UiRadioGroup } from '../common/UI/RadioGroup';
import { PollsQuestionTypesEnum } from '../../models/Polls/PollsQuestionTypesModel';


type Props = {
  id: number | string;
  type: number | string; // может и строка, иногда
  title: string;
  answers: null | { id: number; value: string }[];
  formObject: {
    register: () => void;
    getValues: () => any;
    setValue: any;
    watch: any;
    errors: {[s: string]: any};
  };
}

/**
 * компонент для рендера вопросов опроса/анкеты
 */
export const PollQuestionRenderByType = ({
  type, title, answers, id,
  formObject = {
    register: () => null,
    getValues: () => ({}),
    setValue: () => ({}),
    watch: () => undefined,
    errors: {},
  },
}: Props) => {
  /** могут быть одного типа в форме, поэтому имя должно быть уникально */
  const inputName = `${id}_${type}`;
  const options = answers ? answers.map((answer) => ({ value: answer.id.toString(), label: answer.value })) : [];

  switch (+type) {
    case PollsQuestionTypesEnum.radio: // Один из списка - input type="radio"
      return (
        <div className="list-element">
          <div className="list-element_content">
            <div className="line">
              {title}
            </div>
            <div className="line info">
              <div className="item w-100">
                <UiRadioGroup
                  register={formObject.register}
                  name={inputName}
                  options={options}
                  errors={formObject.errors}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      );
    case PollsQuestionTypesEnum.checkbox: // Несколько из списка - input type="checkbox"
      return (
        <div className="list-element">
          <div className="list-element_content">
            <div className="line">
              {title}
            </div>
            <div className="line info">
              <div className="item w-100">
                <UiCheckBoxGroup
                  register={formObject.register}
                  name={inputName}
                  answers={answers ? answers.map((answer) => ({ label: answer.value, value: `${answer.id}` })) : []}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      );
    case PollsQuestionTypesEnum.select: { // Раскрывающийся список - input type="select"
      return (
        <div className="list-element list-element_custom overflow-initial">
          <UiSelect
            label={title}
            className="w-100 mb-0"
            name={inputName}
            options={options}
            register={formObject.register}
            setValue={formObject.setValue}
            value={formObject.watch(inputName)}
            required
          />
        </div>
      );
    }
    case PollsQuestionTypesEnum.datepicker: // Дата и время - библиотечный датапикер
      return (
        <div className="list-element">
          <div className="list-element_content">
            <UiDatePicker
              label={title}
              placeholderText=" "
              className="form-material line"
              register={formObject.register}
              name={inputName}
              setValue={formObject.setValue}
              watch={formObject.watch}
              showTimeSelect
              required
            />
          </div>
        </div>
      );
    case PollsQuestionTypesEnum.text: // Текст (строка) - input type="text"
      return (
        <div className="list-element">
          <div className="list-element_content">
            <UiInput
              label={title}
              className="form-material line"
              inputClassName="form-control"
              register={formObject.register}
              name={inputName}
              value={formObject.watch(inputName)}
              required
            />
          </div>
        </div>
      );
    case PollsQuestionTypesEnum.textarea: // Текст (абзац) - input type="textarea"
      return (
        <div className="list-element_custom">
          <UiInput
            label={title}
            className="form-group form-material mb-0"
            type="textarea"
            register={formObject.register}
            name={inputName}
            value={formObject.watch(inputName) || ''}
            required
            showLength
          />
        </div>
      );
    default:
      return <h3>неизвестно</h3>;
  }
};
