import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UiSystemInput } from '../../../components/common/UI/Input';
import { UiToolbar } from '../../../components/common/Toolbar';
import { ISystemPollsFilterType } from '../../../models/Polls/SystemPollsModel';
import { emptyOption, emptyOptionValue, UiSelect } from '../../../components/common/UI/Select';
import { IPollsStatusModel } from '../../../models/Polls/PollsStatusesModel';
import { systemPollsStatusesSelector } from '../../store/selectors';
import { hasUnemptyValues } from '../../../utils/objects';


type Props = {
  onSubmit: (data: ISystemPollsFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  title: string;
  status: { label: string; value: number };
}

export const SystemPollsFilter = ({ onSubmit, onReset }: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, setValue, reset, watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      status: emptyOption(),
    },
  });

  const onLocalSubmit = (data: FormValues) => {
    const preparedFilterData = {
      title: data.title.length ? data.title : undefined,
      status: data.status.value === emptyOptionValue ? undefined : data.status.value,
    };

    if (hasUnemptyValues(preparedFilterData)) {
      onSubmit(preparedFilterData);
    }
  };

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const pollStatuses: IPollsStatusModel[] = useSelector(systemPollsStatusesSelector);
  const statusOptions = pollStatuses.map((p) => ({
    label: p.value,
    value: p.key,
  }));

  return (
    <UiToolbar onSubmitForm={handleSubmit(onLocalSubmit)} onResetForm={onLocalReset}>
      <Row>
        <Col xs="12" sm="6" md="3">
          <UiSelect
            register={register}
            setValue={setValue}
            name="status"
            value={watch('status')}
            options={statusOptions}
            label={t('polls.filter.status')}
          />
        </Col>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label={t('polls.filter.title')}
            name="title"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
