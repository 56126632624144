import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ParamTypes } from 'models/common';
import { systemInsuranceByidSelector } from '../selectors';
import { Loader } from '../../../../components/common/UI/Loaders';
import { IInsuranceModel, InsuranceModel } from '../../../../models/Insurances/InsurancesModel';
import { getSystemRefInsurance } from '../../../api/references/insurance';
import { SystemRefInsuranceFormComponent } from '../../../components/References/Insurance/SystemRefInsuranceForm';


export const SystemRefInsuranceForm = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [item, setItem] = useState<IInsuranceModel | null>(null);
  const founditem: IInsuranceModel | undefined = useSelector(systemInsuranceByidSelector(id ? +id : 0));


  useEffect(() => {
    /** если в сторе есть - взять */
    if (founditem) {
      setLoading(false);
      setItem(founditem);
    } else if (!isNaN(numberId)) {
      /** если в сторе нет - запросить метод */
      getSystemRefInsurance(numberId)
        .then((modelOrError) => {
          if (modelOrError instanceof Error) {
            toast.error(t('common.form.errors.get'));
          } else {
            setItem(modelOrError);
            setLoading(false);
          }
        });
    } else {
      setItem(new InsuranceModel({}));
      setLoading(false);
    }
  }, [founditem, t, numberId]);

  if (isLoading || !item) {
    return <Loader />;
  }
  return <SystemRefInsuranceFormComponent item={item} />;
};
