import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { SystemTransportFormComponent } from 'systemModule/components/Transport/SystemTransportForm';
import { usePromise } from 'utils/hooks/usePromise';
import { Loader } from 'components/common/UI/Loaders';
import { ISystemRouteDetailModel, SystemRouteDetailModel } from 'models/Transport/SystemRouteModel';
import {
  createSystemTransport, editTransportSchedule,
  getAllBusStops,
  getSystemTransportRoute,
  updateSystemTransport,
} from 'systemModule/api/transport';
import { IEnterpriseModel } from 'models/Enterprises/EnterpriseModel';
import { systemEnterprisesSelector } from 'systemModule/store/selectors';
import { ISystemBusStopModel } from 'models/References/BusStopsModel';
import { handleErrors } from 'utils/errors';
import { IBusStopScheduleForm, IRouteFormModel } from 'models/Transport/interfaces';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';

export const SystemTransportForm = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = Number(id);

  const history = useHistory();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cTransport);
  const canUpdate = currentUser.hasPermission(UserAccessEnum.wTransport);

  const enterpriseList: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);

  const [model, modelLoading] = usePromise<ISystemRouteDetailModel>(
    () => getSystemTransportRoute(numberId),
    !Number.isNaN(numberId),
    [numberId],
  );

  const [busStops, busStopsLoading] = usePromise<ISystemBusStopModel[]>(
    () => getAllBusStops(), true,
  );

  const onSave = useCallback(async (
    modelToSave: IRouteFormModel,
    schedule: IBusStopScheduleForm[],
  ) => {
    if ((model?.id && !canUpdate) || (!model?.id && !canCreate)) {
      return;
    }

    let trueOrError = model?.id ?
      await updateSystemTransport(model.id, modelToSave) :
      await createSystemTransport(modelToSave);

    if (trueOrError.id) {
      trueOrError = await editTransportSchedule(trueOrError.id, schedule);
    }

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t(model?.id ? 'system.transport.route.updated' : 'system.transport.route.created'));
        history.push('/system/transport');
      },
    );
  }, [history, t, model, canCreate, canUpdate]);

  if (modelLoading || busStopsLoading) {
    return <Loader />;
  }

  return (
    <SystemTransportFormComponent
      busStops={busStops || []}
      enterpriseList={enterpriseList}
      model={model || new SystemRouteDetailModel()}
      onSave={onSave}
    />
  );
};
