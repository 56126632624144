import { DatesFormValues, HolidayFormValues, WeekDayFormValues } from 'systemModule/components/Transport/common';

export interface IBusStopSchedule {
  id: number;
  name: string;
  weekDays: {
    // Ключ - день недели.
    [key: string]: string[];
  };
  holidays: {
    // Ключ - дата праздника, например 2020-02-23
    [key: string]: string[];
  };
}

// Такой ответ приходит с бэкенда
export interface ISystemSchedule {
  // Ключ - id остановки
  [key: string]: IBusStopSchedule;
}

export interface ISystemBusStopSchedule {
  readonly id: number;
  readonly name: string;
  readonly times: WeekDayFormValues[];
  readonly holidayTimes: HolidayFormValues[];
  readonly holidayDates: DatesFormValues[];
}

export class SystemBusStopSchedule implements ISystemBusStopSchedule {
  readonly id: number;
  readonly name: string;
  readonly times: WeekDayFormValues[] = [];
  readonly holidayTimes: HolidayFormValues[] = [];
  readonly holidayDates: DatesFormValues[] = [];

  constructor(params: IBusStopSchedule) {
    this.id = params.id;
    this.name = params.name;

    Object
      .keys(params.weekDays)
      .forEach((day: string) => {
        params.weekDays[day].forEach((time: string) => this.times.push({
          id: Math.random().toString(),
          day: +day,
          time,
        }));
      });

    Object
      .keys(params.holidays)
      .forEach((holiday: string) => {
        this.holidayDates.push({
          id: Math.random().toString(),
          date: holiday,
        });
        params.holidays[holiday].forEach((time: string) => this.holidayTimes.push({
          id: Math.random().toString(),
          date: holiday,
          time,
        }));
      });
  }
}
