import { HttpErrors } from './http';

/** принимаемые типы данных */
type promise = Promise<any>;
type asyncFunctionPromise = () => promise;

/** fn: Promise<any> - т.к. надо еще в самом аксиосе уточнять. а так тип будет норм */
type baseApiFnType = <R = any>(fn: promise | asyncFunctionPromise) => Promise<R | HttpErrors>;

/**
 * Единое место для http запросов с try/catch
 * Принимает функцию (сагу или обычную), которая будет выполнена (http) и возвращен ответ или ошибка.
 * Ошибки обрабатывать не здесь, а там, где ожидается ответ. То есть прокидывать до того, кто вызвал.
 *
 * @example промис
 *    baseApiFn<true>(httpDelete(...));
 *
 * @example функция с промисом
 *    baseApiFn(async () => {
 *      const response = baseApiFn<true>(httpGet(...));
 *      return new model(response);
 *    })
 *
 */
export const baseApiFn: baseApiFnType = async (fn) => {
  try {
    /** здесь будет ответ */
    let response;

    /** выбор - если промис, то ждать его, если функция, то вызвать ее и ждать */
    if (fn instanceof Promise) {
      response = await fn;
    } else if (typeof fn === 'function') {
      response = await fn();
    } else {
      return new Error('unknow argument');
    }

    /** можно не возвращать значение, тогда вернется true (когда функция просто успешно выполнена) */
    return response || true;
  } catch (e) {
    return e;
  }
};
