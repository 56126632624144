import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  ISystemPartnersCategoryListItemModel,
  ISystemPartnersCategoryListModel,
} from 'models/Partners/SystemPartnerCategoryListModel';
import { getParamsFromPagesModel } from 'models/WithPagesModel';
import { DefaultFilterType } from 'models/common';
import { ICurrentUserModel, UserAccessEnum } from 'models/User/CurrentUserModel';
import { getCurrentUserSelector } from 'store/currentUser/selectors';
import { systemDeletePartnerCategory } from 'systemModule/api/partners';
import { SystemPartnersCategoryListComponent } from 'systemModule/components/Partners/Category/list';
import { systemGetPartnersCategoryListStartAction } from 'systemModule/containers/Partners/actions';
import {
  systemPartnerCategoryListLoadingSelector,
  systemPartnerCategoryListSelector,
} from 'systemModule/containers/Partners/selectors';
import { handleErrors } from 'utils/errors';


export const SystemPartnersCategoryList = () => {
  const dispatch = useDispatch();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemGetPartnersCategoryListStartAction(filter));
  }, [dispatch]);

  const { t } = useTranslation();

  const currentUser: ICurrentUserModel = useSelector(getCurrentUserSelector);
  const canCreate = currentUser.hasPermission(UserAccessEnum.cPartnerCategory);
  const canDelete = currentUser.hasPermission(UserAccessEnum.dPartnerCategory);

  const data: ISystemPartnersCategoryListModel = useSelector(systemPartnerCategoryListSelector);
  const isLoading: boolean = useSelector(systemPartnerCategoryListLoadingSelector);

  const onDelete = async ({ id, name }: ISystemPartnersCategoryListItemModel) => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: 'категорию', entityName: name }))) {
      handleErrors(
        await systemDeletePartnerCategory(id),
        'delete',
        () => {
          toast.success(t('system.partners.category.deleted'));
          getData(getParamsFromPagesModel(data));
        },
      );
    }
  };

  return (
    <SystemPartnersCategoryListComponent
      data={data}
      isLoading={isLoading}
      getData={getData}
      canCreate={canCreate}
      canDelete={canDelete}
      onDelete={onDelete}
    />
  );
};
