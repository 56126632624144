import React from 'react';
import { CDate } from 'utils/CDate';
import { useDataPage } from 'utils/hooks/useDataPage';
import {
  IBonusProgramEventHistoryListModel, ISystemBonusProgramEventHistoryFilter,
  ISystemBonusProgramEventHistoryListModel,
} from 'models/BonusProgram/EventHistory/interfaces';
import { DefaultFilterType } from 'models/common';
import { CustomTable } from 'components/common/Table';

type Props = {
  data: IBonusProgramEventHistoryListModel | ISystemBonusProgramEventHistoryListModel;
  getData: (filter: DefaultFilterType | ISystemBonusProgramEventHistoryFilter) => void;
  dataIsLoading: boolean;
}

export const EventHistoryListComponent = ({ data, getData, dataIsLoading }: Props) => {
  const { setLimit, setPage } = useDataPage(data.page, data.limit, getData);

  return (
    <CustomTable
      onChangePage={setPage}
      currentPage={data.page}
      sizePerPage={data.limit}
      amountOfPages={data.amountOfPages}
      setSizePerPage={[setLimit, setPage]}
      isLoading={dataIsLoading}
      tableHeaders={[
        { name: 'Дата' },
        { name: 'Событие' },
        { name: 'Начислено баллов' },
      ]}
    >
      {data.data.map((item) => (
        <tr key={item.id}>
          <td>
            {CDate.format(item.createdAt, 'dd.MM.yyyy/HH:mm')}
          </td>
          <td>
            <span>{item.event.title}</span>
          </td>
          <td>
            <span>{Number(item.sdsCoin)}</span>
          </td>
        </tr>
      ))}
    </CustomTable>
  );
};

EventHistoryListComponent.whyDidYouRender = true;
