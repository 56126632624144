import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IEventModel } from '../../models/Events/EventModel';
import { UiBox } from '../common/UI/UiBox';
import { CDate } from '../../utils/CDate';
import { SubmitLoaderButton } from '../common/SubmitLoaderButton';


type Props = {
  model: IEventModel;
  onVote: (val: boolean) => void;
  loadingVote: boolean;
  showVoteBtns: boolean;
}

export const EventOneComponent = ({
  model, onVote, loadingVote, showVoteBtns,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-header">
        <Link to="/events" className="back" />
        {t('event.event.header')}
      </div>
      <UiBox className="newsone">
        <section className="form">
          <div className="articles">
            <div className="articles-list_item open">
              <div className="articles-list_item__wrapper">
                <div className="articles-list_item__box">

                  {model.image && (
                    <div className="articles-list_item__img">
                      <img src={model.image.fullUrl} alt={model.image.name} />
                    </div>
                  )}

                  <div className="articles-list_item__text">
                    <div className="articles-list_item__date">
                      {CDate.format(model.date_start, 'dd.MM.yyyy/HH:mm')}
                    </div>
                    <div className="articles-list_item__title">
                      {model.name}
                    </div>
                    <span className="articles-list_item__desc">
                      {model.place}
                    </span>
                    <div className="articles-list_item__desc ws-pw">
                      {model.description}
                    </div>

                    {model.contacts.length > 0 && (
                      <div className="mt-4">
                        <div className="articles-list_item__date">
                          {t('event.contacts.public')}
                        </div>
                        <div className="articles-list_item__desc ws-pw">
                          {model.contacts}
                        </div>
                      </div>
                    )}
                  </div>

                </div>

                {/** если есть голосование и не голосовал и время не вышло */}
                {(showVoteBtns && model.ask_question && !model.has_answer && model.date_start > new Date()) && (
                  <div className="buttons-group buttons-group-responsive mt-1">
                    <SubmitLoaderButton
                      onClick={() => onVote(true)}
                      loading={loadingVote}
                      label={t('event.event.vote.true')}
                    />
                    <SubmitLoaderButton
                      onClick={() => onVote(false)}
                      loading={loadingVote}
                      label={t('event.event.vote.false')}
                    />
                  </div>
                )}

              </div>
            </div>
          </div>
        </section>
      </UiBox>
    </>
  );
};

EventOneComponent.whyDidYouRender = true;
