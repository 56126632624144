import { put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { baseSaga } from '../../../store/baseSaga';
import { httpGet } from '../../../utils/http';
import { SYSTEM_REFERRAL_GET_LIST_START } from './constants';
import { systemReferralGetListFailAction, systemReferralGetListOkAction } from './actions';
import { SystemReferralProgramListModel } from '../../../models/ReferralProgram/SystemReferralProgramListModel';


function* systemReferralGetListSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/referral/code', filter);

    yield put(systemReferralGetListOkAction(new SystemReferralProgramListModel(response)));
  }, systemReferralGetListFailAction);
}


export function* systemReferralProgramSagas() {
  yield takeLatest(SYSTEM_REFERRAL_GET_LIST_START, systemReferralGetListSaga);
}
