import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { InfiniteScrollbar } from '../common/InfiniteScrollbar';
import { INotificationsModel, NotificationsFilterType } from '../../models/Notification/Notifications';
import { NotificationsFilter } from './NotificationsFilter';
import { NotificationItem } from './NotificationItem';
import { SubmitLoaderButton } from '../common/SubmitLoaderButton';


type Props = {
  getData: (filter?: NotificationsFilterType, append?: boolean) => void;
  hasMoreData: boolean;
  model: INotificationsModel;
  modelLoading: boolean;
  onReadAll: () => void;
  readAllLoading: boolean;
}

export const NotificationsComponent = ({
  getData, hasMoreData, model, modelLoading, onReadAll, readAllLoading,
}: Props) => {
  /** компонент фильтра будет сообщать об изменениях */
  const [filterData, setFilterData] = useState<NotificationsFilterType>({});

  const { t } = useTranslation();

  /** подгрузка данных при скролле */
  const onLoadMoreData = (page: number) => {
    getData({
      ...filterData,
      page,
    }, true);
  };

  /** запросить начальные данные и запрашивать при изменении фильтра */
  useEffect(() => getData(filterData), [getData, filterData]);

  const onFilterChanged = (data: NotificationsFilterType) => setFilterData(data);

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/" />{t('notifications.all')}
      </div>
      <div className="box notifications-list">
        <InfiniteScrollbar
          currentPage={model.page}
          hasMoreData={hasMoreData && !modelLoading}
          onLoadData={onLoadMoreData}
        >
          <section className="form form-with-btn">
            <NotificationsFilter onChange={onFilterChanged} filterData={filterData} />
            <div className="notifications">
              {model.data.map((item) => (
                <Link
                  key={item.id}
                  to={`/notifications/${item.id}`}
                  className={`list-element${!item.is_read ? ' new' : ''}`}
                >
                  <NotificationItem model={item} />
                  <div className="list-element_direction follow" />
                </Link>
              ))}
            </div>
            {model.data.length === 0 && <div className="text-center">Нет данных</div>}
            {/** хоть 1 непрочитанное есть - выводить кнопку */}
            {model.data.find((n) => !n.is_read) && (
              <div className="form-wrapper_btn">
                <div className="buttons-group buttons-group-responsive">
                  <SubmitLoaderButton
                    onClick={onReadAll}
                    loading={readAllLoading}
                    label={t('notifications.read_all')}
                  />
                </div>
              </div>
            )}
          </section>
        </InfiniteScrollbar>
      </div>
    </>
  );
};

NotificationsComponent.whyDidYouRender = true;
