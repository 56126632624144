import React from 'react';
import { Link } from 'react-router-dom';

import { IEducationListItemModel } from 'models/Education/interfaces';
import { RoutesPaths } from 'routes';
import { CDate } from 'utils/CDate';


type Props = {
  item: IEducationListItemModel;
}

export const EducationListItemComponent = ({ item }: Props) => (
  <div className="articles-list_item">
    <div className="articles-list_item__wrapper">
      <Link className="articles-list_item__box" to={`${RoutesPaths.education}/${item.id}`}>
        {item.image.fullUrl.length > 0 && (
          <div className="articles-list_item__img">
            <div className="img" style={{ backgroundImage: `url(${item.image.fullUrl})` }} />
          </div>
        )}
        <div className="articles-list_item__text">
          <div className="articles-list_item__date">
            {CDate.format(item.date_start, 'd MMMM yyyy HH:mm')}
          </div>
          <div className="articles-list_item__title">
            {item.name}
          </div>
          <div className="articles-list_item__title mt-2">
            {item.place}
          </div>
        </div>
      </Link>
    </div>
  </div>
);
