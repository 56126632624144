import {
  AuthSignUpPasswordType,
  AuthSignUpPhoneType,
  AuthSignUpReferralCodeType,
  AuthSignUpSmsCodeType,
} from '../models/Auth/interfaces';
import { baseApiFn } from '../utils/baseApiFn';
import { httpPost } from '../utils/http';

export const checkPhone = async (data: AuthSignUpPhoneType) => baseApiFn<boolean>(
  httpPost('/auth/signup', data),
);

export const checkReferralCode = async (data: AuthSignUpReferralCodeType) => baseApiFn<boolean>(
  httpPost('/public/referral/check-code', data),
);

export const checkCode = async (data: AuthSignUpSmsCodeType) => baseApiFn<boolean>(
  httpPost('/auth/confirm-sms-code', data),
);

export const checkPassword = async (data: AuthSignUpPasswordType) => baseApiFn<boolean>(
  httpPost('/auth/set-password', data),
);

/** Запрос на восстановление пароля (неавторизован) */
export const recoverPassword = async (data: { phone: number }) => baseApiFn<boolean>(
  httpPost('/auth/password-recovery-request', data),
);
/** Установление нового пароля (неавторизован) */
export const resetPassword = async (data: { phone: number; password: string; code: string }) => baseApiFn<boolean>(
  httpPost('/auth/password-recovery-set', data),
);

/** Запрос на восстановление пароля (авторизован) */
export const requestChangePassword = async () => baseApiFn<boolean>(
  httpPost('/auth/password-change-request'),
);
/** Установление нового пароля (авторизован) */
export const setNewPassword = async (data: { phone: number; password: string; code: string }) => baseApiFn<boolean>(
  httpPost('/auth/password-change-set', data),
);
