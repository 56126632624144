import { AnyAction } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import { EventsModel, IEventsModel } from '../../models/Events/EventModel';
import { EVENT_GET_LIST_FAIL, EVENT_GET_LIST_OK, EVENT_GET_LIST_START } from './constants';
import { onFail, onLoad, onLoading } from '../../utils/reducer';


export type eventsReducerType = {
  events: IEventsModel;
  eventsIsLoading: boolean;
}

const initialState: eventsReducerType = {
  events: new EventsModel(),
  eventsIsLoading: true,
};

export const storeName = 'events';

export const eventsReducer = {
  [storeName]: (state: eventsReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case EVENT_GET_LIST_START:
        return onLoading(state, 'events');
      case EVENT_GET_LIST_OK: {
        if (payload.append) {
          /** т.к. бесконечная прокрута, то новости добавлять в текущий массив */
          const stateData = cloneDeep(state.events.data);

          const stateNew = {
            ...payload.events,
            data: stateData.concat(payload.events.data),
          };

          return {
            ...state,
            events: stateNew,
            eventsIsLoading: false,
          };
        }
        return onLoad(state, payload, 'events');
      }
      case EVENT_GET_LIST_FAIL:
        return onFail(state, 'events');

      default:
        return state;
    }
  },
};
