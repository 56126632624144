import { FileType, prepareFiles } from 'models/common';
import { IEducationModel } from 'models/Education/interfaces';
import { CDate } from 'utils/CDate';


export class EducationModel implements IEducationModel {
  readonly contacts: string;
  readonly date_start: Date;
  readonly id: number;
  readonly image: FileType;
  readonly info: string;
  readonly name: string;
  readonly place: string;
  readonly schedule: string;

  constructor(params: any = {}) {
    this.contacts = params.contacts;
    this.date_start = CDate.parse(params.date_start, 'yyyy-MM-dd HH:mm');
    this.id = params.id;
    this.image = prepareFiles(params.image);
    this.info = params.info || '';
    this.name = params.name || '';
    this.place = params.place || '';
    this.schedule = params.schedule || '';
  }
}
