import isEmpty from 'lodash/isEmpty';

import { CDate } from 'utils/CDate';
import { BACKEND_DATE_FORMAT, BACKEND_DATE_FORMAT_TO } from 'config/system';
import { IApplicationModel, IApplicationModelAdditionalFields } from './interfaces';
import { IShortUser } from '../User/interfaces';
import { FileType, prepareFiles } from '../common';
import { IDepartmentItemModel } from '../References/DepartmentModel';


export class ApplicationModel implements IApplicationModel {
  addSdsCoin?: boolean;
  assign: IShortUser;
  author: IShortUser;
  comments: {
    id: number;
    content: string;
    author: {
      id: number;
      fio: string;
      phone: string;
    };
    created_at: Date;
    files: FileType[];
  }[];
  created_at: Date;
  description: string;
  enterprise: string;
  file: FileType | null;
  id: number;
  department?: IDepartmentItemModel;
  responsible: { id: number; user: IShortUser; created_at: Date }[];
  status: { id: number; name: string };
  type: { id: number; name: string };
  updated_at: Date;

  date_start?: Date;
  date_end?: Date;
  additionalFields?: IApplicationModelAdditionalFields;

  constructor(params: any = {}) {
    this.addSdsCoin = params.add_sdscoin;
    this.assign = params.assign;
    this.author = params.author;
    this.comments = params.comments.map((comment: any) => ({
      ...comment,
      created_at: CDate.parse(comment.created_at, BACKEND_DATE_FORMAT),
      files: comment.files.map((file: any) => (isEmpty(file) ? null : prepareFiles(file))),
    }));
    this.created_at = CDate.parse(params.created_at, BACKEND_DATE_FORMAT);
    this.description = params.description;
    this.enterprise = params.enterprise.name ? params.enterprise.name : params.enterprise;
    this.file = isEmpty(params.file) ? null : prepareFiles(params.file);
    this.id = params.id;
    this.department = params.department;
    this.responsible = params.responsible.map((user: any) => ({
      ...user,
      created_at: CDate.parse(user.created_at, BACKEND_DATE_FORMAT),
    }));
    this.status = params.status;
    this.type = params.type;
    this.updated_at = CDate.parse(params.updated_at, BACKEND_DATE_FORMAT);

    this.date_start = params.date_start ? CDate.parse(params.date_start, BACKEND_DATE_FORMAT) : undefined;
    this.date_end = params.date_end ? CDate.parse(params.date_end, BACKEND_DATE_FORMAT) : undefined;

    this.additionalFields = params.additionalFields ? params.additionalFields.map((block: any) => ({
      ...block,
      fields: block.fields.map((field: any) => ({
        ...field,
        value: (field.type === 'datetime' && field.value) ?
          CDate.parse(field.value, BACKEND_DATE_FORMAT_TO) : field.value,
      })),
    })) : undefined;
  }
}
