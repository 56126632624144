import React, { memo, useEffect } from 'react';
import {
  Col, FormGroup, InputGroup, Label, Row,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { CalendarContainer } from './CalendarContainer';


type Props = {
  label: string;

  names: string[];
  watch: (s: string) => any;
  register: (s: any) => void;
  setValue: (s: string, v: any) => void;
  className?: string;
  system?: boolean; // для использования в админке
  minDate?: Date;
}

/**
 * выбор периода дат
 */
export const DateRange = memo(({
  label,
  register,
  setValue,
  watch,
  names,
  className,
  system = false,
  minDate,
}: Props) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    register({ name: names[0] });
    register({ name: names[1] });
  }, [register, names]);

  const dateMin = watch(names[0]);
  const dateMax = watch(names[1]);

  return (
    <>
      {!system && <h3>{label}</h3>}

      <div className="section-block mb-0 pb-0">
        <FormGroup className={className} id={names[0]}>
          {system && (
            <Label htmlFor={names[0]}>
              {label}
            </Label>
          )}
          <Row>
            <Col>
              <InputGroup>
                <DatePicker
                  className="form-control"
                  selected={dateMin}
                  onChange={(d: Date) => setValue(names[0], d)}
                  selectsStart
                  startDate={dateMin}
                  endDate={dateMax}
                  dateFormat="dd.MM.yyyy"
                  maxDate={dateMax}
                  locale={i18n.language}
                  popperContainer={CalendarContainer}
                  placeholderText={t('common.daterange.datefrom')}
                  minDate={minDate}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <DatePicker
                  className="form-control"
                  selected={dateMax}
                  onChange={(d: Date) => setValue(names[1], d)}
                  selectsEnd
                  startDate={dateMin}
                  endDate={dateMax}
                  dateFormat="dd.MM.yyyy"
                  minDate={dateMin}
                  locale={i18n.language}
                  popperContainer={CalendarContainer}
                  placeholderText={t('common.daterange.dateto')}
                  popperPlacement="top-end" // чтобы влезало на мелких экранах
                />
              </InputGroup>
            </Col>
            {/** эта иконка ничего не делает. убрана до востребования */}
            {/* <Col xs={1} className="list-element_icon mr-3"> */}
            {/*  <CalendarIcon /> */}
            {/* </Col> */}
          </Row>
        </FormGroup>
      </div>
    </>
  );
});
