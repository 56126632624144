import React, { memo } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/img/search.svg';

type Props = {
  register: () => any;
  name: string;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  initClassName?: string;
  defaultValue?: string;
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

export const UiInputSearchComponent = ({
  name,
  register,
  placeholder = 'Введите поисковый запрос',
  id,
  className,
  disabled = false,
  initClassName = '',
  defaultValue,
  handleSubmit,
}: Props) => {
  /** определение/сбор класса */
  const formgroupClassName = [initClassName, 'search-form', 'pt-0'];
  if (className) {
    formgroupClassName.push(className);
  }

  return (
    <div className="search-box page">
      <form
        className={formgroupClassName.filter((cName) => cName).join(' ')}
        onSubmit={handleSubmit}
      >
        <input
          ref={register()}
          name={name}
          disabled={disabled}
          type="text"
          id={id}
          placeholder={placeholder}
          className="search-form_input"
          defaultValue={defaultValue}
          inputMode="search"
        />
        <button className="search-form_btn" type="submit">
          <SearchIcon />
        </button>
      </form>
    </div>
  );
};

export const UiInputSearch = memo((props: Props) => (
  <UiInputSearchComponent {...props} initClassName="form-material" />
));
