import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import { ApplicationsListModel } from 'models/Application/ApplicationListModel';
import { IAllApplicationFilter, IApplicationsListModel } from 'models/Application/interfaces';
import { systemGetAllApplicationsList } from 'systemModule/api/application';
import { SystemAllApplicationsListComponent } from 'systemModule/components/AllApplications/SystemAllApplicationsList';
import { systemGetReferencesApplicationStartAction } from 'systemModule/containers/Application/actions';
import { handleErrors } from 'utils/errors';


export const SystemAllApplicationsList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(systemGetReferencesApplicationStartAction());
  }, [dispatch]);

  const [data, setData] = useState<IApplicationsListModel>(new ApplicationsListModel());
  const [loading, setLoading] = useState(true);

  const getData = useCallback(async (filter: IAllApplicationFilter) => {
    setLoading(true);

    handleErrors(
      await systemGetAllApplicationsList(filter),
      'get',
      setData,
      () => {
        setLoading(false);
      },
    );
  }, []);

  return <SystemAllApplicationsListComponent data={data} isLoading={loading} getData={getData} />;
};
