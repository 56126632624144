import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { ISystemNotificationTemplateModel } from '../../../models/Notification/SystemNotificationTemplateModel';
import {
  SystemNotificationVariableFormType,
  SystemNotificationVariableModelsType,
} from '../../../models/Notification/SystemNotificationVariableModel';
import { UiSystemForm } from '../../../components/common/UI/Form';
import { UiSystemInput } from '../../../components/common/UI/Input';


type Props = {
  model: ISystemNotificationTemplateModel;
  variableList: SystemNotificationVariableModelsType;
  canUpdate: boolean;
  onSave: (data: SystemNotificationVariableFormType) => void;
  saveLoading: boolean;
}

type FormValues = {
  subject: string;
  text: string;
  internal_link: string;
}

export const SystemNotificationTemplateFormComponent = ({
  model, variableList, canUpdate, onSave, saveLoading,
}: Props) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, errors,
    formState: { isSubmitting, dirtyFields, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      subject: model.subject,
      text: model.text,
      internal_link: model.internal_link,
    },
  });

  const onSubmit = (data: FormValues) => {
    onSave({
      subject: data.subject.length ? data.subject.trim() : undefined,
      text: data.text.length ? data.text.trim() : undefined,
      internal_link: data.internal_link.length ? data.internal_link.trim() : undefined,
    });
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting || saveLoading}
      dirtyFieldsAmount={dirtyFields.size}
      createMode
      showButtons={canUpdate}
    >
      <h3>{t('notifications.templates.form')}</h3>

      <Row>
        <Col xs={6}>
          <UiSystemInput
            type="textarea"
            name="subject"
            errors={errors}
            register={register}
            label={t('notifications.templates.subject')}
          />
          <UiSystemInput
            type="textarea"
            name="text"
            errors={errors}
            register={register}
            label={t('notifications.templates.text')}
          />
          <UiSystemInput
            type="textarea"
            name="internal_link"
            errors={errors}
            register={register}
            label={t('notifications.templates.internal_link')}
          />
        </Col>
        <Col xs={6}>
          <h4>{t('notifications.templates.variables_description')}</h4>

          {variableList.map((variable) => (
            <p key={variable.code}>
              {variable.code} - {variable.description}
            </p>
          ))}
        </Col>
      </Row>
    </UiSystemForm>
  );
};
