import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';

import { REFERRAL_CODE_MASK, REFERRAL_CODE_PATTERN } from 'config/system';
import { UIInputMask } from 'components/common/UI/InputMask';
import { useTranslation } from 'react-i18next';
import { AuthSignUpActiveFormType, AuthSignUpReferralCodeType } from 'models/Auth/interfaces';
import { Link } from 'react-router-dom';


type Props = {
  submit: (data: AuthSignUpReferralCodeType) => void;
  isSubmitting: boolean;
  setReferralCode: (value: string) => void;
  setActiveForm: (value: AuthSignUpActiveFormType) => void;
}

type FormValues = {
  referral_code: string;
}

const validateReferralCode = (value: string) => REFERRAL_CODE_PATTERN.test(value);

export const ReferralCodeFormComponent = ({
  isSubmitting, setReferralCode, setActiveForm, submit,
}: Props) => {
  const { t } = useTranslation();
  const {
    handleSubmit, watch, control,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const onSubmit = (data: FormValues) => {
    setReferralCode(data.referral_code);
    submit({
      referral_code: data.referral_code,
    });
  };

  const skipReferralCode = () => {
    setReferralCode('');
    setActiveForm('phone');
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          {t('auth.signup.referral_code.title')}
        </p>
        <UIInputMask
          name="referral_code"
          control={control}
          type="text"
          label="Реферальный код"
          watch={watch}
          mask={REFERRAL_CODE_MASK}
          validation={{
            pattern: REFERRAL_CODE_PATTERN,
          }}
        />
        <div className="buttons-group buttons-group-responsive justify-content-between">
          <Button
            color="primary"
            type="submit"
            onClick={skipReferralCode}
          >
            Нет кода, пропустить
          </Button>

          <Button
            color="primary"
            type="submit"
            disabled={!validateReferralCode(watch('referral_code')) || isSubmitting}
          >
            Далее
          </Button>
        </div>
        <div className="login-link">
          <Link to="/login">Уже регистрировались в приложении? Войдите со своим номером телефона</Link>
        </div>
      </form>
    </>
  );
};
