import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { BONUS_PROGRAM_FORM_ITEM_COST_PATTERN } from 'config/system';
import {
  ISystemBonusProgramEventItemFormModel,
  ISystemBonusProgramEventItemModel,
} from 'models/BonusProgram/Event/interfaces';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';

type Props = {
  model: ISystemBonusProgramEventItemModel
  onSave: (modelId: number, modelToSave: ISystemBonusProgramEventItemFormModel) => void;
  canEditEvent: boolean;
}

type FormValues = {
  title: string;
  sdsCoin: number | string;
  isActive: boolean;
}

export const SystemBonusProgramEventItemFormComponent = (
  {
    model,
    onSave,
    canEditEvent,
  }: Props,
) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, errors,
    formState: { isSubmitting, dirtyFields, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      title: model.title,
      sdsCoin: Number(model.sdsCoin),
      isActive: model.isActive,
    },
  });

  const onSubmit = async (data: FormValues) => {
    if (model.id && !canEditEvent) {
      return;
    }

    const modelToSave: ISystemBonusProgramEventItemFormModel = {
      title: data.title,
      sdscoin: data.sdsCoin,
      is_active: data.isActive,
    };

    onSave(model.id, modelToSave);
  };

  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={!model.id}
      showButtons={canEditEvent}
    >
      <h3>{t('system.bonusProgram.form.update.event')}</h3>

      <UiSystemInput
        name="title"
        errors={errors}
        register={register}
        label={t('system.bonusProgram.event.title.label')}
        required
      />

      <UiSystemInput
        name="sdsCoin"
        errors={errors}
        register={register}
        label={t('system.bonusProgram.event.sdsCoin.label')}
        validation={{
          pattern: BONUS_PROGRAM_FORM_ITEM_COST_PATTERN,
        }}
      />

      <UiSystemCheckbox
        register={register}
        name="isActive"
        label={t('system.bonusProgram.event.isActive.label')}
      />

    </UiSystemForm>
  );
};
