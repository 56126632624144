import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DefaultFilterType } from '../../../../models/common';
import {
  systemNewsCategoriesIsLoadingSelector,
  systemNewsCategoriesSelector,
} from './selectors';
import { UserAccessEnum } from '../../../../models/User/CurrentUserModel';
import { getCurrentUserSelector } from '../../../../store/currentUser/selectors';
import { systemGetNewsCategoriesStartAction } from './actions';
import {
  ISystemNewsCategoriesModel,
  ISystemNewsCategoryModel,
} from '../../../../models/References/NewsCategoriesModel';
import { deleteSystemNewsCategory } from '../../../api/references/newsCategories';
import { SystemNewsCategoriesComponent } from '../../../components/References/NewsCategories/SystemNewsCategories';

export const SystemNewsCategories = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getData = useCallback((filter: DefaultFilterType) => {
    dispatch(systemGetNewsCategoriesStartAction(filter));
  }, [dispatch]);

  const newsCategories: ISystemNewsCategoriesModel = useSelector(systemNewsCategoriesSelector);
  const newsCategoriesIsLoading: boolean = useSelector(systemNewsCategoriesIsLoadingSelector);

  const canDelete = useSelector(getCurrentUserSelector).hasPermission(UserAccessEnum.dReferenceInsurance);

  const onDelete = (item: ISystemNewsCategoryModel) => {
    // eslint-disable-next-line no-restricted-globals
    if (canDelete && confirm(t('common.form.delete.question', { entity: 'категорию', entityName: item.name }))) {
      deleteSystemNewsCategory(item.id).then((booleanOrError) => {
        if (booleanOrError instanceof Error) {
          toast.error('Ошибка при удалении');
        } else {
          toast.success('Удаление прошло успешно');
          getData({});
        }
      });
    }
  };

  return (
    <SystemNewsCategoriesComponent
      newsCategories={newsCategories}
      newsCategoriesIsLoading={newsCategoriesIsLoading}
      getData={getData}
      onDelete={onDelete}
    />
  );
};
