import { IShortUser } from './interfaces';


export class ShortUserModel implements IShortUser {
  readonly fio: string;
  readonly id: number;
  readonly phone: string;
  readonly unit: string;
  readonly post: string;

  constructor(params: any = {}) {
    this.fio = params.fio;
    this.id = params.id;
    this.phone = params.phone;
    this.unit = params.unit;
    this.post = params.post;
  }
}
