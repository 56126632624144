import { IApplicationStatusModel } from './interfaces';


export class ApplicationStatusModel implements IApplicationStatusModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = +params.id;
    this.name = params.name;
  }
}
