import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { ISystemBonusProgramPrizeListModel } from 'models/BonusProgram/Prize/interfaces';
import { SimpleTable } from 'components/common/Table/SimpleTable';
import { ISystemBonusProgramOrderFormModel } from 'models/BonusProgram/Order/interfaces';

type Props = {
  data: ISystemBonusProgramPrizeListModel;
  canGivePrizeToUser: boolean;
  givePrizeToUser: (model: ISystemBonusProgramOrderFormModel) => () => void;
  userId: number;
}

export const SystemBonusProgramUserPrizeListComponent = memo(({
  data, canGivePrizeToUser, givePrizeToUser, userId,
}: Props) => (
  <>
    <h4 className="mt-5">Список доступных призов</h4>
    <SimpleTable
      tableHeaders={[
        { name: 'ID' },
        { name: 'Приз' },
        { name: 'Стоимость реальная' },
        { name: 'Стоимость в СДС-коинах' },
        { name: 'Действие' },
      ]}
    >
      {data.data.map((prizeItem) => (
        <tr key={prizeItem.id}>
          <td>{prizeItem.id}</td>
          <td>{prizeItem.title}</td>
          <td>{Number(prizeItem.priceReal)}</td>
          <td>{Number(prizeItem.priceSdsCoin)}</td>
          <td>
            {
              canGivePrizeToUser && (
                <Button
                  className="w-100"
                  color="primary"
                  tag={Button}
                  type="button"
                  onClick={givePrizeToUser({ user_id: userId, prize_id: prizeItem.id })}
                >
                  Выдать
                </Button>
              )
            }
          </td>
        </tr>
      ))}
    </SimpleTable>
  </>
));
