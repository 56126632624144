import { dataAction, dataErrorAction, dataSuccessAction } from '../../../../store/actions';
import {
  SYSTEM_REFERENCES_GET_SPORTS_FAIL,
  SYSTEM_REFERENCES_GET_SPORTS_OK,
  SYSTEM_REFERENCES_GET_SPORTS_START,
} from './constants';
import { SystemSportsModel } from '../../../../models/References/SportModel';

export const systemGetSportsStartAction = (filter = {}) => dataAction(
  SYSTEM_REFERENCES_GET_SPORTS_START,
)({ filter });
export const systemGetSportsOkAction = (sports: SystemSportsModel) => dataSuccessAction(
  SYSTEM_REFERENCES_GET_SPORTS_OK,
)({ sports });
export const systemGetSportsFailAction = dataErrorAction(SYSTEM_REFERENCES_GET_SPORTS_FAIL);
