import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isNaN from 'lodash/isNaN';
import { useHistory, useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ParamTypes } from 'models/common';
import { SystemMedicalFormComponent } from '../../components/Medical/SystemMedicalForm';
import { IEnterpriseModel } from '../../../models/Enterprises/EnterpriseModel';
import { systemEnterprisesSelector, systemGlobalUnitsSelector } from '../../store/selectors';
import { ISystemUnitModel } from '../../../models/References/UnitsModel';
import { usePromise } from '../../../utils/hooks/usePromise';
import { ISystemMedicalItemModel, SystemMedicalItemModel } from '../../../models/Medical/SystemMedicalModel';
import { Loader } from '../../../components/common/UI/Loaders';
import { createSystemMedical, getSystemMedical, updateSystemMedical } from '../../api/medical';
import { handleErrors } from '../../../utils/errors';
import { IMedicalFormModel } from '../../../models/Medical/interfaces';

export const SystemMedicalForm = () => {
  const { t } = useTranslation();

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const history = useHistory();

  const enterpriseList: IEnterpriseModel[] = useSelector(systemEnterprisesSelector);
  const unitList: ISystemUnitModel[] = useSelector(systemGlobalUnitsSelector);

  const onSave = useCallback(async (modelId: number | null, modelToSave: IMedicalFormModel) => {
    const trueOrError = modelId ?
      await updateSystemMedical(modelId, modelToSave) :
      await createSystemMedical(modelToSave);

    handleErrors(
      trueOrError,
      'save',
      () => {
        toast.success(t(modelId ? 'medical.updated' : 'medical.created'));
        history.push('/system/medical');
      },
    );
  }, [history, t]);

  // получить медицинский осмотр
  const [model, modelLoading] = usePromise<ISystemMedicalItemModel>(() => getSystemMedical(numberId), !isNaN(numberId));

  if (modelLoading) {
    return <Loader />;
  }

  return (
    <SystemMedicalFormComponent
      enterpriseList={enterpriseList}
      unitList={unitList}
      model={model || new SystemMedicalItemModel()}
      onSave={onSave}
    />
  );
};
