import { AnyAction } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { baseSaga } from '../../../../store/baseSaga';
import { httpGet } from '../../../../utils/http';
import { systemRefGetBusStopsFailAction, systemRefGetBusStopsOkAction } from './actions';
import { SystemBusStopsModel } from '../../../../models/References/BusStopsModel';
import { SYSTEM_REFERENCES_GET_BUS_STOPS_START } from './constants';

function* systemGetBusStopsSaga({ payload: { filter } }: AnyAction) {
  yield baseSaga(function* () {
    const response = yield httpGet('/system/references/bus-stop', { sort: 'id', ...filter });

    yield put(systemRefGetBusStopsOkAction(new SystemBusStopsModel(response)));
  }, systemRefGetBusStopsFailAction);
}


export function* systemBusStopsSagas() {
  yield takeLatest(SYSTEM_REFERENCES_GET_BUS_STOPS_START, systemGetBusStopsSaga);
}
