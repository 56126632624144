import { IApplicationTypeModel } from './interfaces';


export class AppllicationTypeModel implements IApplicationTypeModel {
  id: number;
  name: string;

  constructor(params: any = {}) {
    this.id = +params.id;
    this.name = params.name;
  }
}
