import { dataAction, dataErrorAction, dataSuccessAction } from '../../../store/actions';
import {
  SYSTEM_GET_ASSIGN_APPLICATIONS_FAIL,
  SYSTEM_GET_ASSIGN_APPLICATIONS_OK,
  SYSTEM_GET_ASSIGN_APPLICATIONS_START,
  SYSTEM_GET_REFERENCES_APPLICATION_START,
  SYSTEM_GET_REFERENCES_APPLICATION_OK,
  SYSTEM_GET_REFERENCES_APPLICATION_FAIL,
} from './constants';
import { IApplicationFilter, IApplicationsListModel } from '../../../models/Application/interfaces';
import { IApplicationStatusesModel } from '../../../models/Application/Status/interfaces';
import { IApplicationTypesModel } from '../../../models/Application/Type/interfaces';


export const systemGetAssignApplicationStartAction = (filter: IApplicationFilter) => dataAction(
  SYSTEM_GET_ASSIGN_APPLICATIONS_START,
)({ filter });
export const systemGetAssignApplicationOkAction = (assignApplications: IApplicationsListModel) => dataSuccessAction(
  SYSTEM_GET_ASSIGN_APPLICATIONS_OK,
)({ assignApplications });
export const systemGetAssignApplicationFailAction = dataErrorAction(SYSTEM_GET_ASSIGN_APPLICATIONS_FAIL);


export const systemGetReferencesApplicationStartAction = dataAction(SYSTEM_GET_REFERENCES_APPLICATION_START);
export const systemGetReferencesApplicationOkAction = (
  statuses: IApplicationStatusesModel,
  types: IApplicationTypesModel,
) => dataSuccessAction(
  SYSTEM_GET_REFERENCES_APPLICATION_OK,
)({ statuses, types });
export const systemGetReferencesApplicationFailAction = dataErrorAction(SYSTEM_GET_REFERENCES_APPLICATION_FAIL);
