import { baseApiFn } from '../utils/baseApiFn';
import { httpGet, httpPost } from '../utils/http';
import { EventModel, IEventModel } from '../models/Events/EventModel';


/** Получить событие/мероприятие */
export const getEventByid = async (id: number) => baseApiFn<IEventModel>(async () => {
  const response = await httpGet(`/public/event/${id}`);
  return new EventModel(response);
});

/** Голосование по событию/мероприятию */
export const voteEventPoll = async (id: number, result: boolean) => baseApiFn<true>(
  httpPost(`/public/event/${id}/poll`, { result }),
);
