import React from 'react';
import { Button } from 'reactstrap';

export const Test = () => {
  const generateLink = () => {
    const tempLink = window.document.createElement('a');

    tempLink.href = 'https://sds.y.freematiq.com/api/upload/files/000/000/30a/5f9932a1a9efc.jpg';
    tempLink.click();
  };

  return (
    <>
      <div style={{ padding: '3rem' }}>
        <a href="https://sds.y.freematiq.com/api/upload/files/000/000/30a/5f9932a1a9efc.jpg">Ссылка на доку</a><br />
        <br />
        <button type="button" onClick={generateLink}>Ткнуть как пдф</button>
        <br />
        <br />
        <Button
          onClick={() => {
            /** решение для разных браузеров */
            const tempLink = window.document.createElement('a');
            tempLink.href = 'https://sds.y.freematiq.com/api/upload/files/000/000/325/5fbe2066db9b3.jpg';
            tempLink.click();
          }}
        >
          img like pdf
        </Button>
      </div>
    </>
  );
};
