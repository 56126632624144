import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { getPrivilegeList } from '../../../api/privilege';
import { ApplicationPrivilegeListComponent } from '../../../components/Applications/Privilege/ApplicationPrivilegeList';
import { IPrivilegeList } from '../../../models/Privilege/interfaces';
import { handleErrors } from '../../../utils/errors';
import { PrivilegeList } from '../../../models/Privilege/PrivilegeList';
import { DefaultFilterType } from '../../../models/common';
import { getCurrentUserEnterpriseIdsSelector } from '../../../store/currentUser/selectors';


export const ApplicationPrivilegeList = () => {
  const [data, setData] = useState<IPrivilegeList>(new PrivilegeList());
  const [isLoading, setIsLoading] = useState(true);

  const currentUserEnterpriseIds = useSelector(getCurrentUserEnterpriseIdsSelector);

  const getData = useCallback(async (filterData: DefaultFilterType) => {
    setIsLoading(true);
    handleErrors(
      await getPrivilegeList({ ...filterData, enterprise_ids: currentUserEnterpriseIds }),
      'get',
      (model) => setData(model),
      () => setIsLoading(false),
    );
  }, [currentUserEnterpriseIds]);

  /** если кол-во страниц больше текущей, то есть еще данные */
  const hasMoreData: boolean = data.amountOfPages > data.page;

  return (
    <ApplicationPrivilegeListComponent
      data={data}
      hasMoreData={hasMoreData}
      isLoading={isLoading}
      getData={getData}
      currentUserEnterpriseIds={currentUserEnterpriseIds}
    />
  );
};
