import { dataAction, dataErrorAction, dataSuccessAction } from 'store/actions';
import { ISystemRoutesListModel } from 'models/Transport/SystemRouteModel';
import { DefaultFilterType } from 'models/common';

import {
  SYSTEM_TRANSPORT_GET_ROUTES_LIST_FAIL,
  SYSTEM_TRANSPORT_GET_ROUTES_LIST_OK,
  SYSTEM_TRANSPORT_GET_ROUTES_LIST_START,
} from './constants';

export const systemGetTransportRoutesStartAction = (filter: DefaultFilterType) => dataAction(
  SYSTEM_TRANSPORT_GET_ROUTES_LIST_START,
)({ filter });
export const systemGetTransportRoutesOkAction = (routesList: ISystemRoutesListModel) => dataSuccessAction(
  SYSTEM_TRANSPORT_GET_ROUTES_LIST_OK,
)({ routesList });
export const systemGetTransportRoutesFailAction = dataErrorAction(SYSTEM_TRANSPORT_GET_ROUTES_LIST_FAIL);
