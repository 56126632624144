import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IPartnerCategoryListModel } from 'models/Partners/PartnerCategoryListModel';
import { IPartnersListModel } from 'models/Partners/PartnersListModel';
import { PartnersSliderBlockComponent } from 'components/Partners/common';
import { UiBox } from 'components/common/UI/UiBox';


type Props = {
  recommendedList: IPartnersListModel;
  favouriteList: IPartnersListModel;
  categoriesList: IPartnerCategoryListModel;
}

export const PartnersListComponent = ({ recommendedList, favouriteList, categoriesList }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/" />
        {t('partners.menu')}
      </div>

      <UiBox className="partners">
        <section className="form">
          <PartnersSliderBlockComponent
            list={recommendedList.map((listItem) => ({
              ...listItem,
              topStr: listItem.company_name,
              bottomStr: listItem.title,
            }))}
            title="Рекомендованные"
            to="/partners-recommended"
          />

          <PartnersSliderBlockComponent
            list={favouriteList.map((listItem) => ({
              ...listItem,
              topStr: listItem.company_name,
              bottomStr: listItem.title,
            }))}
            title="Избранное"
            to="/partners-favourite"
          />

          <PartnersSliderBlockComponent
            list={categoriesList.map((listItem) => ({
              ...listItem,
              topStr: listItem.name,
            }))}
            title="Категории"
            to="/partners-categories"
            itemTo="/partners-category"
            clearfix={false}
            categoryType
          />

        </section>
      </UiBox>
    </>
  );
};
