import {
  ApplicationConstructCategoryListModel,
  ApplicationConstructCategoryTypeListModel,
} from 'models/Application/Construct/ApplicationConstructCategoryList';
import { ApplicationConstructTypeModel } from 'models/Application/Construct/ApplicationConstructTypeModel';
import {
  ApplicationConstructFormType,
  IApplicationConstructCategoryListWithRoot,
  IApplicationConstructCategoryTypeList, IApplicationConstructTypeModel,
} from 'models/Application/Construct/interfaces';
import {
  httpDelete, httpGet, httpPost, httpPut,
} from '../utils/http';
import { baseApiFn } from '../utils/baseApiFn';
import { CreateFeedbackType } from '../models/Application/Feedback/interfaces';
import {
  ApplicationRequestType,
  IApplicationModel, IApplicationMyListFilter, IApplicationsMyListModel,
} from '../models/Application/interfaces';
import { ApplicationModel } from '../models/Application/ApplicationModel';
import {
  IApplicationResponsibleFilterType, IApplicationResponsibleFormModel,
  IApplicationResponsibleListModel, IApplicationResponsibleModel,
} from '../models/Application/Responsible/interfaces';
import {
  ApplicationResponsibleListModel,
  ApplicationResponsibleModel,
} from '../models/Application/Responsible/ApplicationResponsibleListModel';
import { IApplicationStatusesModel } from '../models/Application/Status/interfaces';
import { ApplicationStatusModel } from '../models/Application/Status/AppllicationStatusModel';
import { IApplicationTypesModel } from '../models/Application/Type/interfaces';
import { AppllicationTypeModel } from '../models/Application/Type/AppllicationTypeModel';
import { ApplicationsMyListModel } from '../models/Application/ApplicationMyListModel';
import { ApplicationSickModel } from '../models/Application/Sick/ApplicationSickModel';
import { ApplicationSickRequestType, IApplicationSickModel } from '../models/Application/Sick/interfaces';


/** Создание заявки обратной связи, с назначением по департаменту или пользователю */
export const createFeedback = async (data: CreateFeedbackType, type: 'department' | 'user') => baseApiFn<true>(
  httpPost(`/public/application/feedback/${type}`, data),
);

/** Создание заявки на изменение персональных данных */
export const changePersonalData = async (description: string) => baseApiFn<true>(
  httpPost('/public/application/personal-data', { description }),
);

/** Получить заявку. определяется по типу */
export const getApplicationByType = async (id: number, type: number) => baseApiFn<false | IApplicationModel>(
  async () => {
    let urlType;
    switch (type) {
      case -1:
        urlType = 'personal-data';
        break;
      case -2:
        urlType = 'support';
        break;
      case -3:
        urlType = 'sick';
        break;
      case -4:
        urlType = 'feedback';
        break;
      default:
        urlType = 'construct';
    }

    const response = await httpGet(`/public/application/${urlType}/${id}`);
    return new ApplicationModel(response);
  },
);

/**
 * Действия с заявками
 */

/** Взять в работу */
export const appProcess = async (id: number, data: ApplicationRequestType) => baseApiFn(
  httpPut(`/public/application/process/${id}`, data),
);

/** Отклонение заявки */
export const appReject = async (id: number, data: ApplicationRequestType) => baseApiFn(
  httpPut(`/public/application/reject/${id}`, data),
);

/** Отмена заявки */
export const appCancel = async (id: number, data: ApplicationRequestType) => baseApiFn(
  httpPut(`/public/application/cancel/${id}`, data),
);

/** Выполнить заявку */
export const appComplete = async (id: number, data: ApplicationRequestType) => baseApiFn(
  httpPut(`/public/application/complete/${id}`, data),
);

/** Переназначить заявку */
export const appAssign = async (id: number, data: ApplicationRequestType) => baseApiFn(
  httpPut(`/public/application/assign/${id}`, data),
);

/** Согласовать заявку */
export const appApproval = async (id: number) => baseApiFn(
  httpPut(`/public/application/approve/${id}`),
);

/** Список доступных ответственных для перевода заявок */
export const appAvailableResponsible = async (
  filter?: IApplicationResponsibleFilterType,
  applicationId?: number,
) => baseApiFn<IApplicationResponsibleListModel>(async () => {
  const response = await httpGet(`/public/application/available-responsible/${applicationId}`, filter);
  return new ApplicationResponsibleListModel(response);
});

/** Список ответственных */
export const appResponsible = async (
  filter?: IApplicationResponsibleFilterType,
) => baseApiFn<IApplicationResponsibleListModel>(async () => {
  const response = await httpGet('/system/setting/responsible', filter);
  return new ApplicationResponsibleListModel(response);
});

/** Получение ответственного */
export const appResponsibleById = async (id: number) => baseApiFn<IApplicationResponsibleModel>(async () => {
  const response = await httpGet(`/system/setting/responsible/${id}`);
  return new ApplicationResponsibleModel(response);
});

/** Создание ответственного */
export const createAppResponsible = async (
  model: IApplicationResponsibleFormModel,
) => baseApiFn<IApplicationResponsibleModel>(async () => {
  const response = await httpPost('/system/setting/responsible', model);
  return new ApplicationResponsibleModel(response);
});

/** Обновление ответственного */
export const updateAppResponsible = async (
  id: number,
  model: IApplicationResponsibleFormModel,
) => baseApiFn<IApplicationResponsibleModel>(async () => {
  const response = await httpPut(`/system/setting/responsible/${id}`, model);
  return new ApplicationResponsibleModel(response);
});

/** Удаление ответственного */
export const deleteAppResponsible = async (id: number) => baseApiFn<true>(
  httpDelete(`/system/setting/responsible/${id}`),
);

/** Получить список статусов заявок */
export const getAppStatuses = async () => baseApiFn<IApplicationStatusesModel>(async () => {
  const response = await httpGet('/public/application/status');
  return Object.entries(response)
    .map(([id, name]) => new ApplicationStatusModel({ id, name }));
});

/** Получить список всех типов заявок */
export const getAppTypes = async () => baseApiFn<IApplicationTypesModel>(async () => {
  const response = await httpGet('/public/application/type');
  return Object.entries(response)
    .map(([id, name]) => new AppllicationTypeModel({ id, name }));
});

export const getAppMyList = async (
  filter?: IApplicationMyListFilter,
) => baseApiFn<IApplicationsMyListModel>(async () => {
  const response = await httpGet('/public/application/list-my', filter);
  return new ApplicationsMyListModel(response);
});

/** Создание заявки на больничный */
export const createAppSick = async (data: ApplicationSickRequestType) => baseApiFn<IApplicationSickModel>(async () => {
  const response = await httpPost('/public/application/sick', data);
  return new ApplicationSickModel(response);
});


/**
 * Список категорий типов заявок
 * @param withRoot {boolean} - добавить ли корневые категории
 */
export const getApplicationCategoryList = async (
  withRoot = false,
) => baseApiFn<IApplicationConstructCategoryListWithRoot>(async () => {
  const response = await httpGet('/public/application/construct/category');
  let rootCategories: IApplicationConstructCategoryTypeList = [];

  if (withRoot) {
    const rootResponse = await getApplicationCategoryTypeList();
    if (!(rootResponse instanceof Error)) {
      rootCategories = rootResponse;
    }
  }

  return { list: ApplicationConstructCategoryListModel(response), listRoot: rootCategories };
});

/** Список типов заявок в категории */
export const getApplicationCategoryTypeList = async (
  category_id?: number,
) => baseApiFn<IApplicationConstructCategoryTypeList>(async () => {
  const response = await httpGet('/public/application/construct/type', { category_id });
  return ApplicationConstructCategoryTypeListModel(response);
});

/** Тип заявки */
export const getApplicationConstructType = async (
  id: number,
) => baseApiFn<IApplicationConstructTypeModel>(async () => {
  const response = await httpGet(`/public/application/construct/type/${id}`);
  return ApplicationConstructTypeModel(response);
});


/** Создание заявки конструктор */
export const createApplicationConstruct = async (data: ApplicationConstructFormType) => baseApiFn(
  httpPost('/public/application/construct', data),
);
