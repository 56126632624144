import { getAppStatuses } from 'api/application';
import React from 'react';

import { IApplicationStatusesModel } from '../../../../models/Application/Status/interfaces';
import { ApplicationStatusesComponent } from '../../../components/References/ApplicationStatuses/ApplicationStatuses';
import { usePromise } from '../../../../utils/hooks/usePromise';

export const SystemApplicationStatuses = () => {
  const [data, isLoading] = usePromise<IApplicationStatusesModel>(getAppStatuses, true);

  return <ApplicationStatusesComponent data={data || []} isLoading={isLoading} />;
};
