import React, {
  memo, useEffect, useState,
} from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Control, Controller, ValidationOptions } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import MaskedInput, { Mask, conformToMask } from 'react-text-mask';

type InputTypes = 'text' | 'tel' | 'url' | 'password' | 'search';
type InputModes = 'none' | 'text' | 'decimal' | 'email' | 'url' | 'tel' | 'search' | 'numeric'

type Props = {
  watch: (s: string) => any;
  name: string;
  control: Control;
  mask: (string | RegExp)[] | ((value: string) => Mask);
  validation?: ValidationOptions;
  label?: string | React.ReactNode;
  type?: InputTypes;
  inputMode?: InputModes;
  id?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  className?: string;
  errors?: {[s: string]: any};
  setValue?: any;
}

export const UIInputMaskComponent = ({
  name, watch, errors = {},
  placeholder, id, className,
  type = 'text',
  label = null,
  isRequired = false,
  isDisabled = false,
  control,
  inputMode = 'text', // вроде никто не заботился об этом
  mask,
  validation,
  setValue,
}: Props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    /** если есть начальные данные - внести их в стейт */
    if (watch(`${name}`) && watch(`${name}`).length && setValue) {
      setValue(name, conformToMask(watch(`${name}`), mask, undefined).conformedValue, true);
    }
    // eslint-disable-next-line
  }, []);

  /** определение/сбор класса */
  const formgroupClassName = ['form-material'];
  if (active || watch(`${name}`)) {
    formgroupClassName.push('value');
  }
  if (className) {
    formgroupClassName.push(className);
  }
  if (!isEmpty(errors)) {
    formgroupClassName.push('has-danger');
  }

  const localValidation: ValidationOptions = { ...validation };
  if (isRequired) {
    localValidation.required = 'Обязательное поле';
    formgroupClassName.push('required');
  }

  return (
    <FormGroup className={formgroupClassName.join(' ')}>
      <Controller
        control={control}
        name={name}
        className="form-control"
        disabled={isDisabled}
        type={type}
        id={id}
        placeholder={placeholder}
        rules={localValidation}
        inputMode={inputMode}
        onBlur={() => setActive(false)}
        as={(
          <MaskedInput
            mask={mask}
            onFocus={() => setActive(true)}
          />
        )}
      />
      {label && (
        <Label htmlFor={id}>
          {label}
        </Label>
      )}
      {errors[name] && <div className="form-control-feedback">{errors[name].message}</div>}
    </FormGroup>
  );
};

export const UIInputMask = memo(UIInputMaskComponent);
