import { dataAction, dataErrorAction, dataSuccessAction } from '../../store/actions';
import {
  NEWS_GET_CATEGORIES_FAIL,
  NEWS_GET_CATEGORIES_OK,
  NEWS_GET_CATEGORIES_START,
  NEWS_GET_LIST_FAIL,
  NEWS_GET_LIST_OK,
  NEWS_GET_LIST_START,
} from './constants';
import { INewsModel, NewsFilterType } from '../../models/News/NewsModel';
import { INewsCategoryItemModel, NewsCategoryFilter } from '../../models/News/NewsCategoryModel';


export const newsGetListStartAction = (append: boolean, filter?: NewsFilterType) => dataAction(
  NEWS_GET_LIST_START,
)({ filter, append });
export const newsGetListOkAction = (news: INewsModel, append: boolean) => dataSuccessAction(
  NEWS_GET_LIST_OK,
)({ news, append });
export const newsGetListFailAction = dataErrorAction(NEWS_GET_LIST_FAIL);


export const newsGetCategoriesStartAction = (filter: NewsCategoryFilter) => dataAction(
  NEWS_GET_CATEGORIES_START,
)({ filter });
export const newsGetCategoriesOkAction = (categories: INewsCategoryItemModel) => dataSuccessAction(
  NEWS_GET_CATEGORIES_OK,
)({ categories });
export const newsGetCategoriesFailAction = dataErrorAction(NEWS_GET_CATEGORIES_FAIL);
