import React, { memo } from 'react';
import {
  Table,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Loader } from '../UI/Loaders';

export interface TableHeaderInterface {
  name: string;
  width?: string;
}

type Props = {
  tableHeaders: Array<TableHeaderInterface>;
  children: React.ReactNode[] | React.ReactNode;
  isLoading?: boolean;
}

// еще пошлифуется
export const SimpleTable = memo(({
  tableHeaders, children, isLoading = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Table responsive striped bordered hover>
      <thead>
        <tr>
          {tableHeaders.map((header: TableHeaderInterface) => (
            <th key={header.name} style={{ width: header.width, verticalAlign: 'top' }}>
              {header.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(isLoading || React.Children.count(children) === 0) ? (
          <tr>
            <td className="text-center" colSpan={50}>
              {isLoading ? <Loader /> : t('common.table.no_data')}
            </td>
          </tr>
        ) : children}
      </tbody>
    </Table>
  );
});
