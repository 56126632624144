import React, { useState } from 'react';
import { Button, Card, Collapse } from 'reactstrap';
import { MaybeLongAnswer } from './MaybeLongAnswer';


type Props = {
  answersInfo: {
    username: string;
    answers: string[] | string;
  }[];
  is_anonymous: boolean;
}

/**
 * вывод ответов на открытые вопросы (не анонимные)
 */
export const SystemPollPublicAnswers = ({ answersInfo, is_anonymous }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card body className="system-answers-card">
      <Button color="primary" className="collapse-btn" onClick={() => setIsOpen(!isOpen)}>
        Посмотреть ответы
      </Button>
      <Collapse isOpen={isOpen}>
        <div className="mt-3" />
        <ul>
          {answersInfo.map((answerInfo) => (
            <li className="mb-1" key={answerInfo.username || Math.random()}>
              {!is_anonymous && <><strong>{answerInfo.username}:</strong>&nbsp;</>}
              <MaybeLongAnswer
                text={Array.isArray(answerInfo.answers) ? answerInfo.answers.join(', ') : answerInfo.answers}
              />
            </li>
          ))}
        </ul>
      </Collapse>
    </Card>
  );
};
