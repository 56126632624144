import { SystemUserListModel } from 'models/Users/SystemUserListModel';
import { useCallback, useState } from 'react';
import { ISystemUserListModelItem, SystemUsersFilterType } from 'models/Users/interfaces';
import { getSystemUsersList } from 'systemModule/api/user';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { handleErrors } from 'utils/errors';

const toSelectOptionType = ({ fio, id }: ISystemUserListModelItem) => ({
  value: id,
  label: fio,
});

/** Запрос на получение пользователей по фильтру */
export const useSystemUsers = () => {
  const [data, setData] = useState<ISystemUserListModelItem[]>([]);
  const [options, setOptions] = useState<SimpleSelectOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const getData = useCallback(async (filter?: SystemUsersFilterType) => {
    setLoading(true);
    const modelOrErrors = await getSystemUsersList(filter);
    handleErrors(
      modelOrErrors,
      'get',
      () => {
        if (modelOrErrors instanceof SystemUserListModel) {
          setData(modelOrErrors.data);
          setOptions(modelOrErrors.data.map(toSelectOptionType));
        }
      },
    );
    setLoading(false);
  }, []);

  return {
    data, getData, loading, options,
  };
};
