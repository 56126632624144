import { AnyAction } from 'redux';

import { CurrentUserModel, ICurrentUserModel } from '../../models/User/CurrentUserModel';
import * as CONSTANTS from './constants';
import { onFail, onLoad, onLoading } from '../../utils/reducer';
import { AUTH_LOGOUT_OK } from '../../containers/Auth/constants';

export const storeName = 'currentUser';

export type currentUserReducerType = {
  currentUser: ICurrentUserModel;
  currentUserIsLoading: boolean;
  currentUserProfileIsLoading: boolean;
  currentUserUpdateIsLoading: boolean;
}

const initialState: currentUserReducerType = {
  currentUser: new CurrentUserModel(),
  currentUserIsLoading: true,
  currentUserProfileIsLoading: false,
  currentUserUpdateIsLoading: false,
};

export const currentUserReducer = {
  [storeName]: (state: currentUserReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case CONSTANTS.GET_CURRENT_USER:
        return onLoading(state, storeName);
      case CONSTANTS.GET_CURRENT_USER_OK:
        return onLoad(state, payload, storeName);
      case CONSTANTS.GET_CURRENT_USER_FAIL:
        return onFail(state, storeName);

      /** обновление данных текущего пользователя. без прав, только инфа */
      case CONSTANTS.CURRENT_USER_UPDATE: {
        return {
          ...state,
          currentUser: new CurrentUserModel(payload.currentUserToUpdate, state.currentUser.rights),
        };
      }

      case CONSTANTS.CURRENT_USER_UPDATE_START:
        return onLoading(state, 'currentUserUpdate');
      case CONSTANTS.CURRENT_USER_UPDATE_OK:
        return {
          ...state,
          currentUser: new CurrentUserModel(payload.currentUser, state.currentUser.rights),
          currentUserUpdateIsLoading: false,
        };
      case CONSTANTS.CURRENT_USER_UPDATE_FAIL:
        return onFail(state, 'currentUserUpdate');

      case CONSTANTS.EDIT_CURRENT_USER_START:
        return onLoading(state, 'currentUserProfile');
      case CONSTANTS.EDIT_CURRENT_USER_OK:
        return {
          ...state,
          currentUser: new CurrentUserModel(payload.currentUser, state.currentUser.rights),
          currentUserProfileIsLoading: false,
        };
      case CONSTANTS.EDIT_CURRENT_USER_FAIL:
        return onFail(state, 'currentUserProfile');

      case AUTH_LOGOUT_OK:
      case CONSTANTS.CURRENT_USER_CLEAR_DATA:
        return {
          ...initialState,
          currentUserIsLoading: false,
        };

      default:
        return state;
    }
  },
};
