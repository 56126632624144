import React from 'react';
import { CDate } from '../../utils/CDate';
import { INotificationModel } from '../../models/Notification/Notifications';


type Props = {
  model: INotificationModel;
}

export const NotificationItem = ({ model }: Props) => (
  <div className="list-element_content">
    <div className="line title wb-ba">{model.subject}</div>
    <div className="line info wb-ba">
      {model.text}
    </div>
    <div className="line info">
      <div className="item">
        {CDate.format(model.created_at, 'dd.MM.yy/HH:mm')}
      </div>
    </div>
  </div>
);
