import React from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';

import { UiSystemInput } from '../../../../components/common/UI/Input';
import { UiToolbar } from '../../../../components/common/Toolbar';
import { BusStopsFilterType } from '../../../../models/References/BusStopsModel';


type Props = {
  onSubmit: (filter: BusStopsFilterType) => void;
  onReset: () => void;
}

type FormValues = {
  name: string;
}

export const SystemBusStopsFilter = ({ onSubmit, onReset }: Props) => {
  const { register, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });

  return (
    <UiToolbar onSubmitForm={handleSubmit(onSubmit)} onResetForm={onReset}>
      <Row>
        <Col xs="12" sm="6" md="3">
          <UiSystemInput
            label="Название остановки"
            name="name"
            register={register}
          />
        </Col>
      </Row>
    </UiToolbar>
  );
};
