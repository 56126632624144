import { emptyOptionValue } from 'components/common/UI/Select';
import { FileType } from 'models/common';
import { ApplicationConstructTypes } from 'models/ConstructApplication/interfaces';

/** макс. возможное кол-во блоков в форме */
export const MAX_BLOCKS_AMOUNT = 10;

/** макс. возможное кол-во полей в блоке */
export const MAX_FIELDS_AMOUNT_IN_BLOCK = 10;

/** типы полей формы */
export const typeOptions = [
  { label: 'Текст (строка)', value: ApplicationConstructTypes.string },
  { label: 'Число', value: ApplicationConstructTypes.number },
  { label: 'Раскрывающийся список', value: ApplicationConstructTypes.select },
  { label: 'Переключатель', value: ApplicationConstructTypes.checkbox },
  { label: 'Файл', value: ApplicationConstructTypes.file },
  { label: 'Дата и время', value: ApplicationConstructTypes.datetime },
];

/** 1 пустое поле внутри блока */
export const defaultEmptyField = (): FieldFormValues => ({
  id: Math.random().toString(),
  name: '',
  type: typeOptions[0].value,
  required: false,
  template_mark: '',
});

/** 1 пустой блок */
export const defaultEmptyBlock = (): FormBlockType => ({
  id: Math.random(),
  generate: false,
  name: '',
  file: null,
  template_id: emptyOptionValue,
  fileType: { name: '', fullUrl: '', url: '' },
  fields: [defaultEmptyField()],
});


export type FormBlockType = {
  generate: boolean | string;
  id: number;

  name: string;
  fields: FieldFormValues[];
  template_id?: number | string;
  file: File | string | null;
  fileType: FileType;
}
export type FieldFormValues = {
  id: string;

  name: string;
  type: string;
  required: boolean | string;
  template_mark: string;
  list?: string; // для значений селектов
}

export type FormValues = {
  blocks: FormBlockType[];
}
