import { baseApiFn } from 'utils/baseApiFn';
import {
  httpDelete, httpGet, httpPost, httpPut,
} from 'utils/http';
import { ISystemEducationModel, SystemEducationFormType } from 'models/Education/interfaces';
import { SystemEducationModel } from 'models/Education/SystemEducationModel';


/** Получить обучение сотрудников */
export const systemGetEducationByid = async (id: number) => baseApiFn<ISystemEducationModel>(async () => {
  const response = await httpGet(`/system/education/${id}`);
  return new SystemEducationModel(response);
});

/** создать обучение */
export const createSystemEducation = async (model: SystemEducationFormType) => baseApiFn<true>(
  httpPost('/system/education', model),
);

/** редактировать обучение */
export const updateSystemEducation = async (id: number, model: SystemEducationFormType) => baseApiFn<true>(
  httpPut(`/system/education/${id}`, model),
);

/** удалить обучение */
export const deleteSystemEducation = async (id: number) => baseApiFn<true>(httpDelete(`/system/education/${id}`));
