import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { EnterpriseModel } from '../../models/Enterprises/EnterpriseModel';

export const useClonedEnterprises = (allEnterprises: EnterpriseModel[]) => useMemo(() => {
  const arr = cloneDeep(allEnterprises);
  /** внести холдинг сразу (id=0) */
  arr.unshift(new EnterpriseModel({ id: 0, name: 'Холдинг' }));

  return arr;
}, [allEnterprises]);
