import { httpDelete, httpGet, httpPost } from 'utils/http';
import { INewsItemModel, NewsItemModel } from 'models/News/NewsItemModel';
import { baseApiFn } from 'utils/baseApiFn';


/** получить новость */
export const getNewsByid = async (id: number) => baseApiFn<INewsItemModel>(async () => {
  const response = await httpGet(`/public/news/${id}`);
  return new NewsItemModel(response);
});

// Лайк новости
export const likeNews = async (id: number) => baseApiFn<true>(httpPost(`/public/news/like/${id}`));
// Дислайк новости
export const dislikeNews = async (id: number) => baseApiFn<true>(httpDelete(`/public/news/like/${id}`));
