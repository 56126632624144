import React, { memo, useEffect, useState } from 'react';
import invariant from 'invariant';
import {
  FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label,
} from 'reactstrap';
import { ValidationOptions } from 'react-hook-form';
import isObject from 'lodash/isObject';
import get from 'lodash/get';


export type InputTypes = 'text' | 'number' | 'textarea' | 'password' | 'time';

/** html валидация для текстовых инпутов */
const getDefaultHtmlMaxLength = (type: InputTypes): number => {
  switch (type) {
    case 'time':
      return 5;
    case 'text':
    case 'number':
    case 'password':
      return 300;
    case 'textarea':
      return 1000;
    default: return 0;
  }
};

/** значения по-умолчанию для инпута */
const getDefaultValidationByType = (type: InputTypes) => ({
  maxLength: {
    value: getDefaultHtmlMaxLength(type),
    message: 'Слишком большая длина',
  },
});


type Props = {
  register: any;
  name: string;

  value?: any; // только факт наличия значения, для стилей внутренних
  errors?: {[s: string]: any}; // объект с ошибками
  maxLength?: number;
  minLength?: number;
  required?: boolean; // сокращение от validation={{ required: true }}
  label?: string | React.ReactNode;
  type?: InputTypes;
  id?: string;
  showLength?: boolean;
  validation?: ValidationOptions;
  defaultValue?: string | number | string[];
  min?: number; // мин. значение для type="number"
  max?: number; // макс. значение для type="number"

  disabled?: boolean;
  placeholder?: string;
  className?: string; // класс для formgroup
  inputClassName?: string; // класс для инпута
  rows?: number;
  unregister?: (s: string) => void; // если компонент стоит в условии - передавать

  initClassName?: string;
  system?: boolean;
  appendText?: string;
}

export const UiInputComponent = ({
  name, register, errors, min, max,
  placeholder, id, className,
  rows = 5,
  type = 'text',
  label = null,
  disabled = false,
  validation = {},
  initClassName = '',
  inputClassName = '',
  required = false,
  value,
  unregister,
  defaultValue,
  maxLength,
  minLength,
  showLength = false,
  system = false,
  appendText,
}: Props) => {
  const [active, setActive] = useState(false);
  const error = get(errors, name, false);

  /** если передан unregister - разрегистрировать */
  useEffect(() => () => {
    if (unregister) {
      unregister(name);
    }
    // eslint-disable-next-line
  }, []);

  /** проверка параметров showLength и value. showLength без value не имеет смысла */
  useEffect(() => {
    invariant(!(showLength && value === undefined), 'showLength требует указания value');
  }, [showLength, value]);

  /** определение/сбор класса */
  const formgroupClassName = [initClassName];
  if (active || value) {
    formgroupClassName.push('value');
  }
  if (className) {
    formgroupClassName.push(className);
  }
  if (error !== false) {
    formgroupClassName.push('has-danger');
  }

  /** если явно не указаны важные параметры валидации - слить с дефолтными */
  const localValidation: ValidationOptions = { ...getDefaultValidationByType(type), ...validation };
  if (required) {
    localValidation.required = 'Обязательное поле';

    /** доп.проверка для текстовых инпутов на пустоту */
    if (['text', 'textarea', 'password'].includes(type)) {
      /** добавить в объект новый валидатор. не использовать функцию, т.к. перетирает предыдущее значение */
      localValidation.validate = {
        ...localValidation.validate,
        emptyStrNotAllowed: (text) => (text.trim().length === 0 ? 'Обязательное поле' : true),
      };
    }

    formgroupClassName.push('required');
  }
  if (maxLength) {
    localValidation.maxLength = {
      value: maxLength,
      message: 'Слишком большая длина',
    };
  }
  if (minLength) {
    localValidation.minLength = {
      value: minLength,
      message: 'Слишком маленькая длина',
    };
  }

  const showMaxLength = isObject(localValidation.maxLength) ?
    localValidation.maxLength.value :
    localValidation.maxLength;

  const INPUT = (
    <Input
      innerRef={register(localValidation)}
      name={name}
      disabled={disabled}
      type={type}
      id={id}
      min={min}
      max={max}
      defaultValue={defaultValue}
      rows={rows}
      className={inputClassName}
      // required={required} // убрано в пользу подсветки ошибки
      placeholder={placeholder}
      maxLength={get(localValidation, 'maxLength.value', getDefaultHtmlMaxLength(type))}
      minLength={get(localValidation, 'minLength.value', 0)}
      style={type === 'textarea' ? { resize: 'none' } : {}}
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
    />
  );

  return (
    <FormGroup className={formgroupClassName.filter((cName) => cName).join(' ')}>
      {(system && label) && (
        <Label htmlFor={id}>
          {label}
        </Label>
      )}

      {appendText ? (
        <InputGroup>
          {INPUT}
          <InputGroupAddon addonType="append">
            <InputGroupText>
              {appendText}
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      ) : INPUT}

      {(!system && label) && (
        <Label htmlFor={id}>
          {label}
        </Label>
      )}
      {(type !== 'password' && showLength && value !== undefined) && (
        <div className="desc mt-2">
          <small className="d-block">
            {`${value ? value.length : 0}/${showMaxLength}`} символов
          </small>
        </div>
      )}
      {!system && (
        <hr className="input-hr" />
      )}
      {error && <div className={`form-control-feedback m-0${system ? ' form-error-block' : ''}`}>{error.message}</div>}
    </FormGroup>
  );
};

/** для публичной части */
export const UiInput = memo((props: Props) => <UiInputComponent {...props} initClassName="form-material no-border" />);

/** для админской части. memo не использовать, возникают проблемы в сложных случаях */
export const UiSystemInput = (props: Props) => <UiInputComponent {...props} system initClassName="system-formgroup" />;
