import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';

import { UiFormatNumber } from 'components/common/UI/FormatNumber';


type Props = {
  onSubmit: (d: number) => void;
  defaultValue: number;
}

export const PartCountCopiesForm = ({
  onSubmit, defaultValue,
}: Props) => {
  const {
    watch, control, errors, handleSubmit, formState,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: defaultValue,
    },
  });

  const onSubmitLocal = useCallback(({ value }) => onSubmit(+value), [onSubmit]);

  return (
    <form className="form-with-btn" onSubmit={handleSubmit(onSubmitLocal)}>
      <div className="form-wrapper_content">
        <UiFormatNumber
          control={control}
          watch={watch}
          name="value"
          errors={errors}
          max={100}
          min={1}
          required
        />
      </div>
      <div className="form-wrapper_btn">
        <div className="buttons-group buttons-group-responsive">
          <Button
            color="primary"
            type="submit"
            disabled={!formState.isValid}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </form>
  );
};
