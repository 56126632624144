import { createSelector } from 'reselect';
import { RootReducer } from '../../../../store/reducers';
import { storeName } from './reducers';
import { returnParams } from '../../../../store/selectors';

const getUnits = (state: RootReducer) => state.system[storeName].units;
const getUnitsIsLoading = (state: RootReducer) => state.system[storeName].unitsIsLoading;
const getUnitById = (id: number) => (state: RootReducer) => state.system[storeName].units.data
  .find((unit) => unit.id === id);

export const systemUnitsSelector = createSelector([getUnits], returnParams);
export const systemUnitsIsLoadingSelector = createSelector([getUnitsIsLoading], returnParams);
export const systemUnitByIdSelector = createSelector([getUnitById], returnParams);
