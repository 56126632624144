export const SYSTEM_TRANSPORT_GET_ROUTES_LIST_START = '[SYSTEM TRANSPORT] get routes list [start]';
export const SYSTEM_TRANSPORT_GET_ROUTES_LIST_OK = '[SYSTEM TRANSPORT] get routes list [ok]';
export const SYSTEM_TRANSPORT_GET_ROUTES_LIST_FAIL = '[SYSTEM TRANSPORT] get routes list [fail]';

export const SYSTEM_TRANSPORT_POST_ROUTE_START = '[SYSTEM TRANSPORT] post route [start]';
export const SYSTEM_TRANSPORT_POST_ROUTE_OK = '[SYSTEM TRANSPORT] post route [ok]';
export const SYSTEM_TRANSPORT_POST_ROUTE_FAIL = '[SYSTEM TRANSPORT] post route [fail]';

export const SYSTEM_TRANSPORT_DELETE_ROUTE_START = '[SYSTEM TRANSPORT] delete route [start]';
export const SYSTEM_TRANSPORT_DELETE_ROUTE_OK = '[SYSTEM TRANSPORT] delete route [ok]';
export const SYSTEM_TRANSPORT_DELETE_ROUTE_FAIL = '[SYSTEM TRANSPORT] delete route [fail]';
