import { dataAction, dataErrorAction, dataSuccessAction } from '../../store/actions';
import { DefaultFilterType } from '../../models/common';
import {
  POLLS_GET_LIST_FAIL,
  POLLS_GET_LIST_OK,
  POLLS_GET_LIST_START,
} from './constants';
import { IPollsModel } from '../../models/Polls/PollsModel';


export const pollsGetListStartAction = (append: boolean, filter?: DefaultFilterType) => dataAction(
  POLLS_GET_LIST_START,
)({ filter, append });
export const pollsGetListOkAction = (polls: IPollsModel, append: boolean) => dataSuccessAction(
  POLLS_GET_LIST_OK,
)({ polls, append });
export const pollsGetListFailAction = dataErrorAction(POLLS_GET_LIST_FAIL);
