import React from 'react';

import { UiDatePicker } from 'components/common/Dates/UiDatePicker';
import { ListElementSwitch } from 'components/common/ListElement/ListElementSwitch';
import { UiInput } from 'components/common/UI/Input';
import { UiSelect } from 'components/common/UI/Select';
import { ApplicationConstructTypes } from 'models/ConstructApplication/interfaces';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';
import { RequiredFileUploader } from './RequiredFileUploader';


type Props = {
  type: string;
  inputName: string;
  options?: SimpleSelectOptionType[];

  title: string;
  required: boolean;

  register: (s?: string) => void;
  watch: (s: string) => any;
  setValue: any;
  getValues: (s?: any) => any;
  setError: (s?: any, e?: any) => any;
  clearError: (s?: string) => any;
}

export const ApplicationConstructFieldRenderByType = ({
  type, inputName, title, required, register, watch, setValue, options = [], setError, clearError, getValues,
}: Props) => {
  switch (type) {
    case ApplicationConstructTypes.string: // Текст (строка) - input type="text"
      return (
        <div className="list-element">
          <div className="list-element_content">
            <UiInput
              label={title}
              className="form-material line"
              inputClassName="form-control"
              register={register}
              name={inputName}
              value={watch(inputName)}
              required={required}
            />
          </div>
        </div>
      );
    case ApplicationConstructTypes.number: // Числовое поле. input type="number"
      return (
        <div className="list-element">
          <div className="list-element_content">
            <UiInput
              label={title}
              type="number"
              className="form-material line"
              inputClassName="form-control"
              register={register}
              name={inputName}
              value={watch(inputName)}
              required={required}
              max={9e7}
            />
          </div>
        </div>
      );
    case ApplicationConstructTypes.select: // Выпадающий список (select)
      return (
        <div className="list-element list-element_custom overflow-initial">
          <UiSelect
            label={title}
            className="w-100 mb-0"
            name={inputName}
            options={options}
            register={register}
            setValue={setValue}
            value={watch(inputName)}
            required={required}
            allowEmptyValue={!required}
            isClearable={!required}
          />
        </div>
      );
    case ApplicationConstructTypes.datetime: // Дата и время (датапикер)
      return (
        <div className="list-element">
          <div className="list-element_content">
            <UiDatePicker
              label={title}
              placeholderText=" "
              className="form-material line"
              register={register}
              name={inputName}
              setValue={setValue}
              watch={watch}
              showTimeSelect
              required={required}
            />
          </div>
        </div>
      );
    case ApplicationConstructTypes.checkbox: { // Переключатель (checkbox)
      register(inputName);

      return (
        <ListElementSwitch
          text={title}
          name={inputName}
          id={inputName}
          onChange={setValue}
          checked={watch(inputName)}
        />
      );
    }

    case ApplicationConstructTypes.file: {
      return (
        <RequiredFileUploader
          inputName={inputName}
          title={title}
          required={required}
          register={register}
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          clearError={clearError}
        />
      );
    }

    default:
      return <h3>неизвестно</h3>;
  }
};
