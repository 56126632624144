import React, { useCallback, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { getModelFromModels } from 'utils/getModelFromModels';

import { HttpErrors } from 'utils/http';
import { ApplicationsComponent } from 'components/Applications/Applications';
import { usePromise } from 'utils/hooks/usePromise';
import { getAppMyList, getAppStatuses, getAppTypes } from 'api/application';
import { IApplicationTypesModel } from 'models/Application/Type/interfaces';
import { IApplicationStatusesModel } from 'models/Application/Status/interfaces';
import { Loader } from 'components/common/UI/Loaders';
import { IApplicationMyListFilter, IApplicationsMyListModel } from 'models/Application/interfaces';
import { ApplicationsMyListModel } from 'models/Application/ApplicationMyListModel';
import { handleErrors } from 'utils/errors';


export const Applications = () => {
  /** запрос всех необходимых данных */
  const [models, modelsIsLoading] = usePromise<[
      IApplicationStatusesModel | HttpErrors,
      IApplicationTypesModel | HttpErrors,
    ]>(() => Promise.all([
      getAppStatuses(),
      getAppTypes(),
    ]), true);

  /** Статусы и типы заявок */
  const [statuses, typesAll] = [getModelFromModels(models, 0), getModelFromModels(models, 1)];

  const [data, setData] = useState<IApplicationsMyListModel>(new ApplicationsMyListModel());
  const [isLoading, setIsLoading] = useState(true);
  const [clearLoad, setClearLoad] = useState(false);

  /** если кол-во страниц больше текущей, то есть еще данные */
  const hasMoreData: boolean = data.amountOfPages > data.page;

  /** Получение списка заявок */
  const getData = useCallback(async (filter?: IApplicationMyListFilter, append = false) => {
    setIsLoading(true);
    const modelOrError = await getAppMyList(filter);
    if (!append) {
      setClearLoad(true);
    }

    handleErrors(
      modelOrError,
      'get',
      () => {
        if (!(modelOrError instanceof Error)) {
          if (append) {
            setData((prevState) => ({
              ...modelOrError,
              data: cloneDeep(prevState.data).concat(modelOrError.data),
            }));
          } else {
            setData(modelOrError);
          }
        }
      },
    );

    setIsLoading(false);
  }, []);

  /** отслеживать полную загрузку при загрузке без подгрузки(чистая загрузка) */
  useEffect(() => {
    if (!isLoading && clearLoad) {
      setClearLoad(false);
    }
  }, [isLoading, setClearLoad, clearLoad]);

  if (modelsIsLoading) {
    return <Loader />;
  }

  return (
    <ApplicationsComponent
      statuses={statuses}
      typesAll={typesAll}
      getData={getData}
      data={data}
      isLoading={isLoading}
      hasMoreData={hasMoreData}
      clearLoad={clearLoad}
    />
  );
};

Applications.whyDidYouRender = true;
