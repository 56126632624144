import React, { memo, useEffect, useState } from 'react';

import { setPartnerFavourite } from 'api/partner';
import { ReactComponent as LikeIcon } from 'assets/img/like.svg';
import { handleErrors } from 'utils/errors';


type Props = {
  id: number;
  is_favorite: boolean;
}

let mounted = false;

export const PartnerFavouriteButton = memo(({ id, is_favorite }: Props) => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  });

  const [isFavourite, setIsFavourite] = useState(is_favorite);

  const onAddFavourite = async () => {
    handleErrors(
      await setPartnerFavourite(id, !isFavourite),
      'save',
      () => {
        if (mounted) {
          setIsFavourite(!isFavourite);
        }
      },
    );
  };

  return (
    <span
      role="presentation"
      className={`list-element_icon favorite cursor-pointer${isFavourite ? ' active' : ''}`}
      onClick={onAddFavourite}
    >
      <span className="text-gray mr-2">В избранное</span>
      <LikeIcon />
    </span>
  );
});
