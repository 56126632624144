import React, { useCallback, useEffect, useState } from 'react';
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { FileType } from 'models/common';
import { LoadFiles } from 'models/UploadFiles/UploadFiles';
import { Loader } from '../UI/Loaders';


const fileExtToType = (exts: string[]): string => {
  const arr: string[] = [];

  exts.forEach((e: string) => {
    switch (e) {
      case 'doc':
        arr.push('.doc', '.docx');
        break;
      case 'pdf':
        arr.push('application/pdf');
        break;
      case 'xls':
        arr.push(
          '.xls',
          '.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/excel',
          'application/vnd.ms-excel',
          'application/x-excel',
          'application/x-msexcel',
        );
        break;
      default:
        arr.push(`.${e}`);
    }
  });

  return arr.join(', ');
};


export type FileUploaderBaseProps = {
  onChange: (files: File[]) => void;

  maxSizeFile?: number; // размер одного файла, в мегабайтах
  maxFilesCount?: number; // общее кол-во файлов
  accept?: string[]; // только расширения файлов. без точки
  initFileTypes?: FileType[]; // массив файлоподобных объектов. может быть даже с пустым именем
  withPreview?: boolean; // показывать загружаемое изображение
}

export type FileUploaderBaseRenderProps = {
  myFiles: File[];
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
  isSingleFile: boolean;
  removeFile: (file: File) => any;
  accept: string[];
  maxSizeFile: number;
  maxFilesCount: number;
}


type Props = FileUploaderBaseProps & {
  render: (o: any) => any;
}

/** не использовать напрямую */
export const FileUploaderBase = ({
  maxFilesCount = 1,
  maxSizeFile = 3, // 3мб. согласовано
  onChange,
  accept = [],
  initFileTypes = [],
  render,
}: Props) => {
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [loadInit, setLoadInit] = useState<boolean>(false);

  const maxSizeOneFileKB = maxSizeFile * 1024 * 1024;

  const { t } = useTranslation();

  /** загрузка самих файлов. компонент временно недоступен */
  useEffect(() => {
    let isActive = true;

    /** отсеять пустые файлы */
    const filteredInitFileTypes = initFileTypes.filter((item: FileType) => get(item, 'name.length', 0) !== 0);

    if (filteredInitFileTypes.length) {
      setLoadInit(true);

      new LoadFiles(filteredInitFileTypes)
        .load()
        .then((files: File[] | false) => {
          if (isActive && files) {
            setMyFiles(files);
          }
          setLoadInit(false);
        });
    }
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line
  }, []);

  /** оповещать об изменениях */
  useEffect(() => onChange(myFiles), [onChange, myFiles]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const errors: string[] = [];

    const files = acceptedFiles
      /** фильтрануть неподходящие по размеру + добавить ошибку */
      .filter((loadedFile) => {
        if (loadedFile.size >= maxSizeOneFileKB) {
          errors.push(t('common.files.error.size', { fileName: loadedFile.name }));
          return false;
        }
        return true;
      })
      /** проверка на кол-во файлов. игнорировать остальные */
      .slice(0, maxFilesCount - myFiles.length);

    /** новый массив с существующими файлами и добавленными */
    setMyFiles(myFiles.concat(files));

    if (errors.length) {
      toast.error(errors.join('\n'));
    }
  }, [myFiles, maxFilesCount, t, maxSizeOneFileKB]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileExtToType(accept),
    /** макс. размер вычисляется как размер 1 файла на кол-во файлов */
    /** убрать здесь ограничение, чтобы показывать ошибку превышения размера */
    // maxSize: maxFilesCount * maxSizeOneFileKB,
    onDrop,
  });

  const removeFile = (file: File) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  /** загрузчик для единственного файла? */
  const isSingleFile = maxFilesCount === 1;

  if (loadInit) {
    return <Loader />;
  }

  return render({
    myFiles,
    setMyFiles,
    getRootProps,
    getInputProps,
    isSingleFile,
    removeFile,
    accept,
    maxSizeFile,
    maxFilesCount,
  });
};
