import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ApplicationRequestType, ApplicationStatusEnum, IApplicationModel } from 'models/Application/interfaces';
import { UiBox } from '../common/UI/UiBox';
import { ApplicationOneFields } from './ApplicationOneFields';
import { ApplicationOneCancelFormComponent } from './ApplicationOneCancelForm';
import { useIncludedStatus } from '../../utils/hooks/useIncludedStatus';

type Props = {
  model: IApplicationModel;
  onCancel: (data: ApplicationRequestType) => void;
  cancelIsLoading: boolean;
}

const listOfStatuses = [ApplicationStatusEnum.new, ApplicationStatusEnum.approval];

export const ApplicationOneComponent = ({ model, onCancel, cancelIsLoading }: Props) => {
  const { t } = useTranslation();

  const isCanCancel = useIncludedStatus(model.status.id, listOfStatuses);

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/applications" />
        {t('applications.item_id', {
          appId: model.id,
        })}
      </div>

      <UiBox className="applications">
        <section className="form">
          <ApplicationOneFields model={model} />
          {isCanCancel && (
            <ApplicationOneCancelFormComponent
              onSubmit={onCancel}
              cancelIsLoading={cancelIsLoading}
            />
          )}
        </section>
      </UiBox>
    </>
  );
};
