import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from '../../../utils/reducer';
import { IPrivilegeList } from '../../../models/Privilege/interfaces';
import { PrivilegeList } from '../../../models/Privilege/PrivilegeList';
import { SYSTEM_GET_PRIVILEGES_FAIL, SYSTEM_GET_PRIVILEGES_OK, SYSTEM_GET_PRIVILEGES_START } from './constants';


export type systemPrivilegesReducerType = {
  privilegesList: IPrivilegeList;
  privilegesListIsLoading: boolean;
}

const initialState: systemPrivilegesReducerType = {
  privilegesList: new PrivilegeList(),
  privilegesListIsLoading: true,
};

export const storeName = 'privileges';

export const systemPrivilegesReducer = {
  [storeName]: (state: systemPrivilegesReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case SYSTEM_GET_PRIVILEGES_START:
        return onLoading(state, 'privilegesList');
      case SYSTEM_GET_PRIVILEGES_OK:
        return onLoad(state, payload, 'privilegesList');
      case SYSTEM_GET_PRIVILEGES_FAIL:
        return onFail(state, 'privilegesList');

      default:
        return state;
    }
  },
};
