import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CalendarIcon } from '../../assets/img/calendar.svg';
import { ReactComponent as IdeaIcon } from '../../assets/img/idea.svg';
import { ReactComponent as FileMinusIcon } from '../../assets/img/file-minus.svg';
import { ReactComponent as BellIcon } from '../../assets/img/bell.svg';
import { MenuItem } from './MenuItem';
import { IUnreadNotificationAmount } from '../../models/Notification/UnreadNotificationAmount';
import { globalGetUnreadInfoStartAction } from '../../store/actions';
import { getGlobalUnreadInfoSelector } from '../../store/selectors';

// Надеюсь что временный хардкод категории
const HAVE_IDEA_ID = process.env.REACT_APP_HAVE_IDEA_ID;

export const TopMenu = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalGetUnreadInfoStartAction());
  }, [dispatch]);

  const unreadInfo: IUnreadNotificationAmount = useSelector(getGlobalUnreadInfoSelector);

  const { t } = useTranslation();

  return (
    <div className="top-menu">
      <div className="top-menu-wrapper">
        <MenuItem
          icon={<CalendarIcon />}
          label={<>События<span className="d-none d-inline-custom"> и мероприятия</span></>}
          path="/events"
        />
        {!!HAVE_IDEA_ID && (
          <MenuItem
            icon={<IdeaIcon />}
            label={<span className="text-nowrap">Есть идея!</span>}
            path={`/applications/create/category/${HAVE_IDEA_ID}`}
          />
        )}
        <MenuItem
          icon={<FileMinusIcon />}
          label="Опросы"
          path="/polls"
          countEvents={unreadInfo.polls}
          className="step-9"
        />
        <MenuItem
          icon={<BellIcon />}
          label={t('notifications.all')}
          path="/notifications"
          countEvents={unreadInfo.notify}
          className="step-3"
        />
      </div>
    </div>
  );
});
