import { DefaultFilterType } from '../common';

export type BusStopTimetableParamsType = DefaultFilterType & {
  sort?: string;
  name?: string;
  favorite?: 0 | 1;
}

export interface IBusStopTimetableItem {
  id: number;
  name: string;
  favorite: boolean;
  schedule: {
    date: string;
    week_day: string;
    routes: {
      id: number;
      name: string;
      times: string[];
    }[];
  }[];
}

export class BusStopTimetableItemModel implements IBusStopTimetableItem {
  id: number;
  name: string;
  favorite: boolean;
  schedule: {
    date: string;
    week_day: string;
    routes: {
      id: number;
      name: string;
      times: string[];
    }[];
  }[];

  constructor(params: any = {}) {
    this.id = params.id;
    this.name = params.name;
    this.favorite = params.favorite;
    this.schedule = params.schedule;
  }
}
