import { IBonusProgramEventHistoryListModel } from 'models/BonusProgram/EventHistory/interfaces';
import { BonusProgramEventHistoryListModel } from 'models/BonusProgram/EventHistory/BonusProgramEventHistoryModel';
import { AnyAction } from 'redux';
import { onFail, onLoad, onLoading } from 'utils/reducer';
import {
  GET_EVENT_HISTORY_LIST_FAIL,
  GET_EVENT_HISTORY_LIST_OK,
  GET_EVENT_HISTORY_LIST_START,
} from 'containers/BonusProgram/constants';

export type BonusProgramReducerType = {
  eventHistoryList: IBonusProgramEventHistoryListModel;
  eventHistoryListIsLoading: boolean;
}

const initialState: BonusProgramReducerType = {
  eventHistoryList: new BonusProgramEventHistoryListModel(),
  eventHistoryListIsLoading: true,
};

export const storeName = 'bonusProgram';

export const bonusProgramReducer = {
  [storeName]: (state: BonusProgramReducerType = initialState, { type, payload }: AnyAction) => {
    switch (type) {
      case GET_EVENT_HISTORY_LIST_START:
        return onLoading(state, 'eventHistoryList');
      case GET_EVENT_HISTORY_LIST_OK:
        return onLoad(state, payload, 'eventHistoryList');
      case GET_EVENT_HISTORY_LIST_FAIL:
        return onFail(state, 'eventHistoryList');

      default:
        return state;
    }
  },
};
