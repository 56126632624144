import React from 'react';
import { UiOuterLink } from 'components/common/UI/OuterLink';
import { INewsItemExternalResource } from 'models/News/NewsItemModel';
import { ReactComponent as FbIcon } from 'assets/img/fb.svg';
import { ReactComponent as InstIcon } from 'assets/img/inst.svg';
import { ReactComponent as OkIcon } from 'assets/img/ok.svg';
import { ReactComponent as TwitterIcon } from 'assets/img/tw.svg';
import { ReactComponent as VKIcon } from 'assets/img/vk.svg';
import { ReactComponent as ExternalIcon } from 'assets/img/external.svg';

const ExternalLink = ({ name, urlName, url }: { name: string; urlName?: string; url: string }) => {
  let icon;
  switch (name) {
    case 'vk':
      icon = <VKIcon />;
      break;
    case 'fb':
      icon = <FbIcon />;
      break;
    case 'inst':
      icon = <InstIcon />;
      break;
    case 'ok':
      icon = <OkIcon />;
      break;
    case 'twitter':
      icon = <TwitterIcon />;
      break;
    default:
      icon = '';
  }

  return (
    <div className="list-element">
      <div className="list-element_icon mr-2">
        {icon}
      </div>
      <div className="list-element_content custom-lec">
        <div className="line title">
          <UiOuterLink href={url} className="text-primary text-clip">
            {urlName || url}
          </UiOuterLink>
        </div>
      </div>
      <UiOuterLink href={url} className="list-element_icon">
        <ExternalIcon />
      </UiOuterLink>
    </div>
  );
};

type Props = {
  socialLinks: INewsItemExternalResource[];
  thirdPartyLinks: INewsItemExternalResource[];
}

export const NewsExternalResources = ({ socialLinks, thirdPartyLinks }: Props) => (
  <div className="mt-3">
    {socialLinks.map(({ url, name }) => (
      <ExternalLink key={`${url}${name}`} name={name} url={url} />
    ))}
    {thirdPartyLinks.map(({ url, name }) => (
      <div key={`${url}${name}`} className="list-element">
        <div className="list-element_content">
          <div className="line info">
            <div className="item"><small>Внешняя ссылка</small></div>
          </div>
          <div className="line title">
            <UiOuterLink href={url} className="text-primary text-clip">
              {name}
            </UiOuterLink>
          </div>
        </div>
        <UiOuterLink href={url} className="list-element_icon">
          <ExternalIcon />
        </UiOuterLink>
      </div>
    ))}
  </div>
);
