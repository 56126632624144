import { dataAction, dataErrorAction, dataSuccessAction } from '../../../../store/actions';
import {
  SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_FAIL,
  SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_OK,
  SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_START,
} from './constants';
import { SystemNewsCategoriesModel } from '../../../../models/References/NewsCategoriesModel';

export const systemGetNewsCategoriesStartAction = (filter = {}) => dataAction(
  SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_START,
)({ filter });
export const systemGetNewsCategoriesOkAction = (newsCategories: SystemNewsCategoriesModel) => dataSuccessAction(
  SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_OK,
)({ newsCategories });
export const systemGetNewsCategoriesFailAction = dataErrorAction(SYSTEM_REFERENCES_GET_NEWS_CATEGORIES_FAIL);
