import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

import { ParamTypes } from 'models/common';
import { handleErrors } from 'utils/errors';
import { EntityNotFound } from 'components/Errors/404';
import { dislikeNews, getNewsByid, likeNews } from 'api/news';
import { NewsOneComponent } from 'components/News/NewsOne';
import { Loader } from 'components/common/UI/Loaders';
import { INewsItemModel, NewsItemModel } from 'models/News/NewsItemModel';
import { usePromise } from 'utils/hooks/usePromise';


export const NewsOne = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [model, loading] = usePromise<INewsItemModel>(() => getNewsByid(numberId), !isNaN(numberId), [numberId]);

  const [changeLikedIsLoading, setChangeLikedIsLoading] = useState(false);
  const changeLiked = useCallback(async (id: number, liked: boolean) => {
    if (changeLikedIsLoading) {
      return;
    }
    setChangeLikedIsLoading(true);
    const changeLikedFn = liked ? dislikeNews : likeNews;
    handleErrors(
      await changeLikedFn(id),
      'save',
      () => {
        if (model) {
          model.is_liked = !liked;
          model.like_count = liked ? model.like_count - 1 : model.like_count + 1;
        }
      },
      () => setChangeLikedIsLoading(false),
    );
  }, [model, setChangeLikedIsLoading, changeLikedIsLoading]);

  if (loading) {
    return <Loader />;
  }
  if (model instanceof NewsItemModel) {
    return <NewsOneComponent model={model} onLikeClick={changeLiked} />;
  }
  return <EntityNotFound message="Новость не найдена" />;
};

NewsOne.whyDidYouRender = true;
