export interface IPollsQuestionTypesItemModel {
  key: number;
  value: string;
}

export class PollsQuestionTypesItemModel implements IPollsQuestionTypesItemModel {
  key: number;
  value: string;

  constructor(params: any = {}) {
    this.key = params.key;
    this.value = params.value;
  }
}


export type IPollsQuestionTypesModel = PollsQuestionTypesItemModel[];

/** типы ответов на опрос */
export enum PollsQuestionTypesEnum {
  radio = 5,
  checkbox = 10,
  select = 15,
  datepicker = 20,
  text = 25,
  textarea = 30,
}


export type ISavePollAnswers = {
  answers: {
    question_id: number; // Идентификатор вопроса
    value: string; // ID ответа или произвольный текст
  }[];
}

export type ISaveOfflinePollAnswers = ISavePollAnswers & {
  user_id: number;
}
