import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

export const clearEmptyValues = (obj: Record<string, any>) => {
  const clonedObj = cloneDeep(obj);
  forEach(clonedObj, (value, key) => {
    /**
     * явные условия
     * пустые строки, null, undefined и пустые массивы(объекты) - удалять
     */
    if (value === '' || value === null || value === undefined || (typeof value === 'object' && isEmpty(value))) {
      delete clonedObj[key];
    }
  });

  return clonedObj;
};

/**
 * имеет ли объект непустые значения (не равные undefined и null)
 *
 * @example
 * hasUnemptyValues({ value: undefined, 1: null }) === false
 *
 * @example
 * hasUnemptyValues({ value: undefined, 1: null, 2: 'val' }) === true
 */
export const hasUnemptyValues = (obj: Record<string, any>): boolean => Object
  .values(obj)
  .some((value) => !(value === undefined || value === null));
