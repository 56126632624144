import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { UiSelect } from 'components/common/UI/Select';
import { UiSystemCheckbox } from 'components/common/UI/Checkbox';
import { UiSystemForm } from 'components/common/UI/Form';
import { UiSystemInput } from 'components/common/UI/Input';
import {
  ConstructApplicationTypeModelAvailableType,
} from 'models/ConstructApplication/interfaces';
import { checkReplace, findValue } from 'utils/common';
import { SimpleSelectOptionType } from 'utils/convertModelToOptions';


type Props = {
  model: ConstructApplicationTypeModelAvailableType;
  onSave: (m: ConstructApplicationTypeModelAvailableType) => void;
  privilegeList: SimpleSelectOptionType<number>[];
  isAllEnterprise: boolean;
}

type FormValues = {
  privilege_id: SimpleSelectOptionType<number> | null;
  enable_all: boolean;
  enable_part_time: boolean;
  enable_married: boolean;
  enable_many_children: boolean;
  enable_yong: boolean;
  enable_president: boolean;
  enable_invite: boolean;
  enable_contract: boolean;
  enable_invalid_children: boolean;
  enable_children_count: number | string;
  enable_children_age: number | string;
}

export const ConstructApplicationTypeAvailableComponent = ({
  model,
  onSave,
  privilegeList,
  isAllEnterprise,
}: Props) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    errors,
    formState: {
      isSubmitting,
      dirtyFields,
      isValid,
    },
    setValue,
    getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      privilege_id: checkReplace(findValue(privilegeList, model.privilege_id), null),
      enable_all: model.enable_all,
      enable_part_time: model.enable_part_time,
      enable_married: model.enable_married,
      enable_many_children: model.enable_many_children,
      enable_yong: model.enable_yong,
      enable_president: model.enable_president,
      enable_invite: model.enable_invite,
      enable_contract: model.enable_contract,
      enable_invalid_children: model.enable_invalid_children,
      enable_children_count: model.enable_children_count || '',
      enable_children_age: model.enable_children_age || '',
    },
  });

  const onSubmit = (data: FormValues) => onSave({
    ...data,
    privilege_id: data.privilege_id ? data.privilege_id.value : null,
    enable_children_count: +data.enable_children_count || null,
    enable_children_age: +data.enable_children_age || null,
  });


  return (
    <UiSystemForm
      onSubmit={handleSubmit(onSubmit)}
      disabledSubmit={!isValid}
      loadingSubmit={isSubmitting}
      dirtyFieldsAmount={dirtyFields.size}
      createMode={false}
    >
      <h3>
        {t('system.construct_application.type.step_available')}
      </h3>

      <UiSelect
        label="Льгота"
        register={register}
        name="privilege_id"
        options={privilegeList}
        setValue={setValue}
        value={getValues('privilege_id')}
        allowEmptyValue
        isClearable
      />

      <UiSystemCheckbox
        name="enable_part_time"
        register={register}
        label="Доступен совместителям"
        isDisabled={isAllEnterprise}
      />
      <UiSystemCheckbox
        name="enable_all"
        register={register}
        label="Доступен всем"
      />

      <h3>Обязательное наличие подтвержденных статусов</h3>

      <UiSystemCheckbox
        name="enable_many_children"
        register={register}
        label="Многодетный отец/мать"
      />
      <UiSystemCheckbox
        name="enable_yong"
        register={register}
        label="Молодой специалист"
      />
      <UiSystemCheckbox
        name="enable_president"
        register={register}
        label="Участник Президентского кадрового резерва"
      />
      <UiSystemCheckbox
        name="enable_invite"
        register={register}
        label="Приглашенный специалист из отдаленных населенных пунктов"
      />
      <UiSystemCheckbox
        name="enable_contract"
        register={register}
        label="Бессрочный трудовой договор с ООО ХК 'СДС-Энерго'"
      />
      <UiSystemCheckbox
        name="enable_married"
        register={register}
        label="Первый брак"
      />
      <UiSystemCheckbox
        name="enable_invalid_children"
        register={register}
        label="Семья с ребенком-инвалидом"
      />

      <h3>Условия по детям</h3>

      <Row>
        <Col xs={3}>
          <UiSystemInput
            name="enable_children_count"
            type="number"
            min={0}
            max={40}
            maxLength={2}
            label="Минимальное количество детей"
            errors={errors}
            register={register}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <UiSystemInput
            name="enable_children_age"
            type="number"
            min={0}
            max={40}
            maxLength={2}
            label="Максимальный возраст детей"
            errors={errors}
            register={register}
          />
        </Col>
      </Row>
    </UiSystemForm>
  );
};
