import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UiWeekDaysSwitcher } from 'components/common/UI/UiWeekDaysSwitcher';
import { IBusStopTimetableItem } from '../../models/BusStopTimetable/BusStopTimetable';
import { BusStopTimetableItem } from './BusStopTimetableItem';
import { Loader } from '../common/UI/Loaders';

type Props = {
  busStopTimetable: IBusStopTimetableItem;
  busStopTimetableIsLoading: boolean;
}

export const BusStopTimetableComponent = ({ busStopTimetable, busStopTimetableIsLoading }: Props) => {
  const currentDayOfWeek = new Date().getDay();
  const [day, setDay] = useState(currentDayOfWeek);
  const noScheduleToDay = !busStopTimetable.schedule?.[day]?.routes?.length || false;

  return (
    <>
      <div className="page-header">
        <Link className="back" to="/bus-stops" />{busStopTimetable.name}
      </div>
      <section className="form">
        {
          busStopTimetableIsLoading ?
            <Loader className="mt-3" /> : (
              <>
                {busStopTimetable.schedule === undefined ?
                  <h4>Остановка не найдена</h4> : (
                    <>
                      <UiWeekDaysSwitcher dayWeek={day} setDay={setDay} />
                      <h4>Расписание</h4>
                      <div>
                        <div className="tab-content_item active" id="tab1">
                          {noScheduleToDay ? (
                            <div>
                              В этот день маршрутов нет
                            </div>
                          ) :
                            busStopTimetable.schedule[day].routes.map((route) => (
                              <BusStopTimetableItem
                                key={route.id}
                                route={route}
                              />
                            ))
                          }
                        </div>
                      </div>
                    </>
                  )}
              </>
            )}
      </section>
    </>
  );
};
